import React, { Component } from 'react'
import { Container, Grid, Feed, Segment, Divider, Placeholder, Card, Label, Form, Message } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { handleContainer, handleHeaderItems, setRuntime } from '../../actions/global';
import config from '../../config/config';
import ProgressiveImager from '../../components/helpers/progressive-imager';
import ImageWrapper from '../../components/helpers/image-wrapper';
import { fetchNotificationList, resetNotificationList } from '../../actions/notification';
import { InfiniteScroll } from '../../components/helpers/InfiniteScroll'
import './dashboard.scss';
import Moment from 'react-moment';
import 'moment/locale/de'
import 'moment-timezone';

const calendarStrings = {
    lastDay : '[Gestern um] LT',
    sameDay : '[Heute um] LT',
    sameElse : 'LL',
}

class FollowerMobile extends Component {

    constructor(props){
        super(props);

        this.events = [config.NTFCN_FAVORITE_NEW];
        this.loadMore = this.loadMore.bind(this);
        this.loadMoreForce = this.loadMoreForce.bind(this);
    }

    componentDidMount = () => {
        const { dispatch } = this.props;
        dispatch(handleHeaderItems("dashboard"));
        dispatch(resetNotificationList(this.events, true));
        dispatch(fetchNotificationList(this.events, true));
        window.scrollTo(0, 0);
    }

    loadMore() {
        const { dispatch, page, isFailed } = this.props;

        if (!isFailed) {
            dispatch(fetchNotificationList(this.events, true, (page ? page+1 : 1)));
        }
    }

    loadMoreForce() {
        const { dispatch, page } = this.props;

        dispatch(fetchNotificationList(this.events, true, (page ? page+1 : 1)));
    }

    placeholderItem() {
        return (
            <div className="comments-dashboard">
                <Card className="comments-dashboard-placeholder">
                    <Card.Content>
                        <Placeholder className="comments-dashboard-placeholder-img">
                            <Placeholder.Image/>
                        </Placeholder>
                        <Placeholder className="comments-dashabord-placeholder-content">
                            <Placeholder.Line length="short"/>
                            <Placeholder.Line length="long"/>
                            <Placeholder.Line length="very long"/>
                        </Placeholder>
                    </Card.Content>
                </Card>
            </div>
        );
    }

    render() {
        const { listitems, isFetching, isFailed, hasMore } = this.props;

        if ((!listitems || !listitems.length) && isFetching) {
            return (<>
                {this.placeholderItem()}
                {this.placeholderItem()}
                {this.placeholderItem()}
           </> )
        }

        else if (!listitems || !listitems.length) {
            return (
                <Container text>
                    <Divider hidden/>
                    <Message color="teal">Es ist noch kein Eintrag vorhanden.</Message>
                </Container>
            )
        }

        else if (!isFailed) {

            return (
                <div>
                    {listitems.map((follower, index) =>
                        <Segment className="followers">
                            {/*Fetch data*/}
                                <Grid>
                                    <Grid.Column width={16}>
                                        <Feed as={"a"} href={follower.link}>
                                            <Feed.Event>
                                                <Feed.Label>
                                                    <Link to={`/user/${follower.userAuthorData.urlslug}/${follower.userAuthor}`}>
                                                        <ImageWrapper circular avatar path={follower.userAuthorData.mediaavatarPath}>
                                                            <ProgressiveImager
                                                                placeholder="avatar"
                                                                circular
                                                                src={config.getImageUrl('avatar', follower.userAuthorData.mediaavatarPath)}
                                                                floated='left'
                                                                size='mini'
                                                            />
                                                        </ImageWrapper>
                                                    </Link>
                                                </Feed.Label>
                                                <Feed.Content>
                                                    <Feed.Summary>
                                                        <Link to={`/user/${follower.userAuthorData.urlslug}/${follower.userAuthor}`}>
                                                            {follower.userAuthorData.name}
                                                        </Link>
                                                    </Feed.Summary>
                                                    <Feed.Meta>
                                                        folgt deinem

                                                        {/* Project */}
                                                        {follower.event === config.NTFCN_FAVORITE_NEW &&
                                                            <Label color="orange">Projekt</Label>
                                                        }

                                                        {/* Story */}
                                                        {follower.projectPart &&
                                                            <Label color="yellow">Story</Label>
                                                        }

                                                    </Feed.Meta>
                                                    <Feed.Meta className="follower-title">
                                                        {follower.project || follower.projectPart || follower.feedPost ?

                                                                follower.teaser

                                                            :
                                                            <>
                                                                {follower.teaser } - Gelöscht
                                                                {/* <Label color="red">Gelöscht</Label> */}
                                                            </>
                                                        }
                                                    </Feed.Meta>
                                                    <Feed.Meta className="follower-date">
                                                        <Moment format="DD.MM.YYYY" calendar={calendarStrings} unix >{follower.date}</Moment>
                                                    </Feed.Meta>

                                                    {follower.new &&
                                                        <div className="new-notification">
                                                            <div className="new-notification-label"/>
                                                        </div>
                                                    }
                                                </Feed.Content>
                                            </Feed.Event>
                                        </Feed>
                                    </Grid.Column>
                                </Grid>
                        </Segment>
                    )}

                    {isFetching && hasMore &&
                        <>
                            {this.placeholderItem()}
                        </>
                    }

                    {isFailed &&
                        <>
                            <Message negative>Beim Abrufen der Daten ist ein Fehler aufgetreten.</Message>
                            <Grid>
                                <Grid.Column width={16} textAlign="center">
                                    <Form.Button
                                        onClick={this.loadMoreForce}
                                        color="red"
                                    >
                                        Nochmals versuchen
                                    </Form.Button>
                                </Grid.Column>
                            </Grid>
                        </>
                    }

                    <InfiniteScroll
                        onLoadMore={this.loadMore}
                        loading={isFetching}
                        hasNextPage={hasMore}
                    />

                    {/*Workaround for InfiniteScroll*/}
                    &nbsp;
                </div>

            )
        }

        else {
            return (
                <Container text>
                    <Divider hidden/>
                    <Message negative>Es ist ein Fehler aufgetreten.</Message>
                </Container>
            )
        }
    }
}

class Follower extends Component {

    componentDidMount = () => {
        const { dispatch } = this.props;
        dispatch(handleContainer("secondary", "Follower", true));
        dispatch(handleHeaderItems("dashboard"));
        dispatch(setRuntime({page: 'be-follower'}));
    }

    render() {
        return (
            <FollowerMobile />
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { globalByComponent,listByEntity, notificationById } = state;
    const { type, headerTitle, headerActiveItem } = globalByComponent[
        'Container'
    ] || {

    }

    const { list, isFetching, isFailed, hasMore, page } = listByEntity[
        'NTFCN' + "e" + config.NTFCN_FAVORITE_NEW+ "d1"
    ] || {
        list: {},
        isFetching: true,
    }

    var listitems = [];
    if (list) {
        for (let value of Object.values(list)) {
            listitems.push(notificationById[value].item);
        }
        listitems = [].concat(listitems).sort((a, b) => a.order > b.order ? 1 : -1);
    }

    return {
        type,
        headerTitle,
        headerActiveItem,
        listitems,
        isFetching,
        isFailed,
        hasMore,
        page,
    }
}

Follower = connect(mapStateToProps)(withRouter(Follower));
FollowerMobile = connect(mapStateToProps)(withRouter(FollowerMobile));

export default {
    Follower,
    FollowerMobile,
}
