import React, { Component } from 'react'
import { Responsive } from 'semantic-ui-react';
import './FlexGallery.scss';

class FlexGallery extends Component {
    render() {

        const { images } = this.props;

        return (
            <div className="flex-gallery-wrapper">
                <div className="flex-gallery">

                    {images && 
                    <>
                        <Responsive {...Responsive.onlyMobile} className="flex-gallery-items">
                            {images.slice(0, 3).map((image, index) => 
                                <div className="flex-gallery-image" key={index}>
                                    <img src={image.img} alt={image.alt} loading="lazy"/>
                                </div>
                            )}
                        </Responsive>

                        <Responsive minWidth={Responsive.onlyTablet.minWidth} className="flex-gallery-items">
                            {images.slice(0, 4).map((image, index) => 
                                <div className="flex-gallery-image" key={index}>
                                    <img src={image.img} alt={image.alt} loading="lazy"/>
                                </div>
                            )}
                        </Responsive>

                    </>
                    }

                    {images && images.length > 3 && 
                    <>
                        <Responsive {...Responsive.onlyMobile} className="flex-gallery-items flex-gallery-items-wrapper">
                            <>
                            {images.slice(3, 4).map((image, index) => 
                                <div className="flex-gallery-image flex-gallery-big-image" key={index}>
                                    <img src={image.img} alt={image.alt} loading="lazy"/>
                                </div>
                            )}
                            <div className="flex-gallery-image flex-gallery-vertical">
                                {images.slice(4, 6).map((image, index) => 
                                    <img src={image.img} alt={image.alt} key={index} loading="lazy"/>
                                )}
                            </div>
                            </>
                        </Responsive>

                        <Responsive minWidth={Responsive.onlyTablet.minWidth} className="flex-gallery-items flex-gallery-items-wrapper">
                            {images.slice(4, 5).map((image, index) => 
                                <div className="flex-gallery-image flex-gallery-big-image" key={index}>
                                    <img src={image.img} alt={image.alt} loading="lazy"/>
                                </div>
                            )}
                            <div className="flex-gallery-image flex-gallery-vertical">
                                {images.slice(5, 6).map((image, index) => 
                                    <img src={image.img} alt={image.alt} loading="lazy" key={index}/>
                                )}
                                <div className="flex-gallery-vertical-two-items">
                                    {images.slice(6, 8).map((image, index) => 
                                        <img src={image.img} alt={image.alt} loading="lazy" key={index}/>
                                    )}
                                </div>
                            </div>
                        </Responsive>

                    </>
                    }

                    {images && images.length > 6 &&
                    <>
                        <Responsive {...Responsive.onlyMobile} className="flex-gallery-items">
                            {images.slice(6, 9).map((image, index) => 
                                <div className="flex-gallery-image" key={index}>
                                    <img src={image.img} alt={image.alt} loading="lazy"/>
                                </div>
                            )}
                        </Responsive>

                        <Responsive minWidth={Responsive.onlyTablet.minWidth} className="flex-gallery-items">
                            {images.slice(8, 12).map((image, index) => 
                                <div className="flex-gallery-image" key={index}>
                                    <img src={image.img} alt={image.alt} loading="lazy"/>
                                </div>
                            )}
                        </Responsive>

                    </>
                    }

                    {images && images.length > 9 &&
                    <>
                        <Responsive {...Responsive.onlyMobile} className="flex-gallery-items">
                            {images.slice(9, 12).map((image, index) => 
                                <div className="flex-gallery-image" key={index}>
                                    <img src={image.img} alt={image.alt} loading="lazy"/>
                                </div>
                            )}
                        </Responsive>

                        <Responsive minWidth={Responsive.onlyTablet.minWidth} className="flex-gallery-items last-row">
                            {images.slice(12, 14).map((image, index) => 
                                <div className="flex-gallery-image" key={index}>
                                    <img src={image.img} alt={image.alt} loading="lazy"/>
                                </div>
                            )}

                            {images.slice(14, 15).map((image, index) => 
                                <div className="flex-gallery-image last" key={index}>
                                    <img src={image.img} alt={image.alt} loading="lazy"/>
                                </div>
                            )}
                        </Responsive>
                    </>
                    } 

                </div>
            </div>
        )
    }
}

export default FlexGallery;
