import { FETCH_BRAND_ALL } from '../actions/global';
import { RESET_STORE } from '../actions/account'

export default function brandBySlug(state = {}, action) {
    
    try {

        if (action.type === RESET_STORE) {
            return {};
        }

        if (action.type === FETCH_BRAND_ALL) {
            action.payload.brand.map(item => {
                state = {
                    ...state,
                    [item.slug]: {
                        ...state[item.slug],
                        ...item
                    }
                }
            });
            return state;
        }

        return state

    }
    catch (e) {

        console.log(e);
        return {
            ...state,
            fatalError: true,
        };

    }
}