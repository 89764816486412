import React, { Component } from 'react';
import { Card as CardSemanticUI, Grid, Responsive, Header, Image } from 'semantic-ui-react';
import ProgressiveImager from '../helpers/progressive-imager';
import config from '../../config/config';
import Truncate from 'react-truncate';
import closeIcon from '../../images/icons/close-cross-white.svg'
import './Card.scss';

class Card extends Component {
    render() {


        const { className, images, key, title, button, description, descriptionMeta, imageMeta, logo, mainImage, altText } = this.props;
   
        return (
            <CardSemanticUI key={key} className={"primary " + className}>
                <Grid>

                    <Grid.Column mobile={16} tablet={8} computer={8}>

                        <div className="card-images">
                            {/* Mobile images */}
                            <Responsive {...Responsive.onlyMobile} className="main-image full">
                                 <ProgressiveImager
                                    src={config.getImageUrl('halfwidth', mainImage)}
                                    alt={altText}
                                /> 
                                <Image src={images}/>
                            </Responsive>

                            {/* Desktop images */}
                            <Responsive minWidth={Responsive.onlyTablet.minWidth} className="main-image full">
                                <ProgressiveImager
                                    src={config.getImageUrl('main', mainImage)}
                                    alt={altText}
                                /> 
                                <Image src={images}/>
                            </Responsive>

                            

                        </div>

                        {imageMeta && 
                            <Card.Meta className="card-image-meta">
                                {imageMeta}
                            </Card.Meta>
                        }

                    </Grid.Column>

                    <Grid.Column mobile={16} tablet={8} computer={8}>
                        <CardSemanticUI.Description>
                            <div className={`card-header ${logo ? "logo" : ""}`}>
                           { config.getImageRatio(config.getImageUrl('halfwidth', logo)) === "landscape" &&
                                <div className="card-header-content">
                                    {descriptionMeta && 
                                        <div className="description-meta">
                                            {descriptionMeta}
                                        </div>
                                    }

                                    {title &&
                                        <div className="card-title">
                                            <Header as="h2">{title}</Header>
                                        </div> 
                                    }
                                </div>}

                                {logo && 
                                    config.getImageRatio(config.getImageUrl('halfwidth', logo)) === "landscape" &&
                                    <div className="item-logo">
                                        <Image src={config.getImageUrl('halfwidth', logo)} alt={`Logo ${title}`} />
                                    </div>
                                }

                            </div>

                            

                            {description && 
                                <div className={`card-item-description
                                    ${config.getImageRatio(config.getImageUrl('halfwidth', logo)) === "portrait" ||
                                    config.getImageRatio(config.getImageUrl('halfwidth', logo)) === "square" && "logo"}
                                `}>
                                    <div className={config.getImageRatio(config.getImageUrl('halfwidth', logo)) !== "landscape" ? "item-description portrait" : "item-description"}>
                                     
                                        {logo && 
                                            config.getImageRatio(config.getImageUrl('halfwidth', logo)) === "portrait" ||
                                            config.getImageRatio(config.getImageUrl('halfwidth', logo)) === "square" &&
                                            <div className="item-logo-portrait">
                                                <Image src={config.getImageUrl('halfwidth', logo)} alt={`Logo ${title}`} />
                                            </div>
                                        }
                                       {config.getImageRatio(config.getImageUrl('halfwidth', logo)) !== "landscape" &&
                                            <div className="card-header-content">
                                                <div className="card-header">
                                                    {descriptionMeta && 
                                                        <div className="description-meta">
                                                            {descriptionMeta}
                                                        </div>
                                                    }

                                                    {title &&
                                                        <div className="card-title">
                                                            <Header as="h2">{title}</Header>
                                                        </div> 
                                                    }
                                                </div>
                                            </div>
                                        }
                                        <Truncate lines={5}>
                                            {description}
                                        </Truncate>
                                    </div>
                                    
                                </div>
                            }


                            {button && 
                                <div className="card-button">
                                    {button}
                                </div>
                            }

                        </CardSemanticUI.Description>
                    </Grid.Column>

                </Grid>
            </CardSemanticUI>
        )
    }
}

export default Card
