import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';
import config from '../../config/config';
import ProgressiveImager from '../helpers/progressive-imager';
import './PreviewGallery.scss';
import '../Gallery/Gallery'
import Gallery from '../Gallery/Gallery';
import ImageWrapper from '../helpers/image-wrapper';

class PreviewGallerySecondary extends Component {

    state = {
        storyGallery: null,
        storyGalleryVideo: null,
        storySlide: 0,
        videoLightboxIsOpen: false
    }

    handleGallery = (slide) => {
        if(!slide) {
            slide = 0;
        }
        this.setState({storyGallery: true, storySlide: slide});
    }

    render() {

        const { images, altbase } = this.props;
        let imagesLightbox = [];

        images.map((m, index) => {
            imagesLightbox.push(config.getImageUrl('zoomDesktop', m.path));     
        });

        return (

            <div className="preview-gallery-secondary gallery-element">

            {/* One Image */}
            {images.length === 1 &&
                images.slice(0,1).map((m, index) => (
                    <div className="top-image" onClick={() => this.handleGallery(0)}>
                        <div className="image">
                            <ProgressiveImager key={index} src={config.getImageUrl('main', m.path)} alt={altbase + " Bild - " + m.id} />
                        </div>
                    </div>
                ))
            }

            {/* Two Images... */}
            {images.length === 2 &&
                <div className="two-images-gallery">
                {images.map((m, index) => (
                    <div className="image" onClick={() => this.handleGallery(index)}>
                        <ProgressiveImager key={index} src={config.getImageUrl('main', m.path)} alt={altbase + " Bild - " + m.id} />
                    </div>
                ))}
                </div>
            }

            {/* Three Images.. */}
            {images.length === 3 &&
                <div className="three-images-gallery">
                    {images.slice(0,1).map((m, index) => (
                        <div className="top-image" onClick={() => this.handleGallery(0)}>
                            <ProgressiveImager key={index} src={config.getImageUrl('main', m.path)} alt={altbase + " Bild - " + m.id} />
                        </div>
                    ))}
                    <div className="two-images">
                    {images.slice(1,3).map((m, index) => (
                        <div className="image" onClick={() => this.handleGallery(index+1)}>
                            <ProgressiveImager key={index} src={config.getImageUrl('main', m.path)} alt={altbase + " Bild - " + m.id} />
                        </div>
                    ))}
                    </div>
                </div>
            }

             {/* Four Images or more... */}
             {images.length === 4 &&
                <div className="four-images-gallery">
                    <div className="top-images">
                        {images.slice(0,2).map((m, index) => (
                            <div className="image" onClick={() => this.handleGallery(index)}>
                                <ProgressiveImager key={index} src={config.getImageUrl('main', m.path)} alt={altbase + " Bild - " + m.id} />
                            </div>
                        ))}
                    </div>
                    <div className="two-images">
                        {images.slice(2,4).map((m, index) => (
                            <div className="image" onClick={() => this.handleGallery(index+2)}>
                                <ProgressiveImager key={index} src={config.getImageUrl('main', m.path)} alt={altbase + " Bild - " + m.id} />
                            </div>
                        ))}
                    </div>

                </div>
            }

            {images.length === 5 &&
                <div className="five-images-gallery">
                    <div className="top-images">
                        {images.slice(0,2).map((m, index) => (
                            <div className="image" onClick={() => this.handleGallery(index)}>
                                <ProgressiveImager key={index} src={config.getImageUrl('main', m.path)} alt={altbase + " Bild - " + m.id} />
                            </div>
                        ))}
                        </div>
                    <div className="four-images">
                        {images.slice(2,5).map((m, index) => (
                            <div className="image" onClick={() => this.handleGallery(index+2)}>
                                <ProgressiveImager key={index} src={config.getImageUrl('main', m.path)} alt={altbase + " Bild - " + m.id} />
                            </div>
                        ))}
                    </div>
                </div>
            }

            {images.length >= 6 &&
            <>
               { images.slice(0,1).map((m, index) => (
                    <div className="top-image" onClick={() => this.handleGallery(0)}>
                        <div className="image">
                            <ProgressiveImager key={index} src={config.getImageUrl('main', m.path)} alt={altbase + " Bild - " + m.id} />
                        </div>
                    </div>
                ))}
                <div className="images-wrapper">
                    <div className="middle-images">

                    {images.slice(1,4).map((m, index) => (
                        <div className="image" key={index} onClick={() => this.handleGallery(index+1)}>
                            <ProgressiveImager src={config.getImageUrl('main', m.path)} alt={altbase + " Bild - " + m.id} />
                        </div>
                    ))}

                    </div>

                    <div className="bottom-images">

                        {images.slice(4,5).map((m, index) => (
                            <div className="image" key={index} onClick={() => this.handleGallery(index+4)}>
                                <ProgressiveImager src={config.getImageUrl('main', m.path)} alt={altbase + " Bild - " + m.id} />
                            </div>
                        ))}

                        {images.slice(5,6).map((m, index) => (
                            <div className="last image" key={index} onClick={() => this.handleGallery(images.length > 6 ? 0 : index+5)}>
                                <ProgressiveImager src={config.getImageUrl('main', m.path)} alt={altbase + " Bild - " + m.id} />
                                {images.length > 6 &&
                                    <div className="image-count-overlay">
                                        <div className="image-count">+{images.length - 6}</div>
                                    </div>
                                }
                            </div>
                        ))}

                    </div>
                </div>
            </>
            }

            <Gallery
                 images={imagesLightbox}
                 galleryOpen={this.state.storyGallery}
                 onClose={() => this.setState({storyGallery: false, storySlide: 0})}
                 slide={this.state.storySlide}
                 onMovePrevRequest={() =>
                     this.setState({
                         storySlide: (this.state.storySlide + images.length - 1) % images.length,
                     })
                 }
                 onMoveNextRequest={() =>
                     this.setState({
                         storySlide: (this.state.storySlide + 1) % images.length,
                     })
                 }
            />

        </div>
        )

    }
}

export default PreviewGallerySecondary;
