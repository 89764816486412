import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
    Link
} from "react-router-dom";
import {
    Label,
    Responsive,
    Button
} from 'semantic-ui-react';
import ProgressiveImager from '../../../components/helpers/progressive-imager';
import config from '../../../config/config';
import NextArrow from '../../../components/SlicksliderCustomArrows/NextArrow';
import PrevArrow from '../../../components/SlicksliderCustomArrows/PrevArrow';
import './list.scss';
import PlusButton from '../../../components/PlusButton/PlusButton';
import Truncate from 'react-truncate'

/**
* Listitem for own projects
*/

//Slick slider settings...
const settings = {
    dots: true,
    arrows: true,
    infinite: false,
    lazdyload: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <NextArrow/>,
    prevArrow: <PrevArrow/>,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                dots: true,
                arrows: false,
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                dots: true,
                arrows: false,
            }
        }
    ]
  };

class ListitemOwnProject extends Component {

    render() {
        const { projectId, projectTitle, projectMainImage, linktype, active, projectSlug, path, storiesCount } = this.props;
        const type = this.progressType();
        let projectLink;

        //Build link
        if(path === 'oldtimer'){
            projectLink = linktype === "stories" ? `/oldtimer/${projectSlug}/${projectId}/tab/stories` : linktype === "vehicle" ? `/oldtimer/${projectSlug}/${projectId}/tab/vehicle` : `/oldtimer/${projectSlug}/${projectId}/tab/interview`;
        }
        else {
            //Backend project
            projectLink = `/backend/project/${projectId}`;
        }
        
        return (

                    <div className="project">
                        <Link 
                            to={projectLink}
                        >
                            <div className="own-project-wrapper ">
                                <Responsive maxWidth={768}>
                                    <ProgressiveImager 
                                        className="project-img" 
                                        src={config.getImageUrl('preview', projectMainImage)} 
                                        circular 
                                        bordered 
                                        alt={projectTitle}
                                    />
                                </Responsive>
                                <Responsive minWidth={768}>
                                    <ProgressiveImager  alt={projectTitle} className="project-img" src={config.getImageUrl('preview', projectMainImage)} />
                                </Responsive>
                                {!active &&
                                    <div className="parked-label">
                                        <Label color="red" style={{display: "block"}}>Geparkt</Label>
                                    </div>
                                }
                            </div>
                            {/* <Progress percent={type} {...type} size='tiny' /> */}
                            <div className="project-title">
                                <Truncate lines={1}>{projectTitle}</Truncate>
                            </div>
                            {path !== 'oldtimer' && 
                                <div className={storiesCount === 0 ? "stories-btn first" : "stories-btn new"}>
                                    <PlusButton 
                                        as={Link} 
                                        to={`/backend/project/${projectId}/stories`}
                                        title={`${storiesCount === 0 ? 'Erste Story' : 'Neue Story'}`}
                                    />
                                </div>
                            }
                        </Link>
                    </div>

        )
    }

    progressType(progress) {
        if (progress < 50) {
            return {error: true};
        }
        else if (progress < 99) {
            return {warning: true};
        }
        else {
            return {success: true};
        }
    }
}

ListitemOwnProject.propTypes = {
    project: PropTypes.object,
}


export default ListitemOwnProject;
