import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

import { Menu, Icon, Image, Responsive, Dropdown } from 'semantic-ui-react'
import BeProject from '../../pages/project/backend/be-project';

import SettingIcon from '../../images/icons/footer-menu-dots.svg';

class ProjectNav extends Component {
    render(){
        const { project, active } = this.props;
        return (

            <div>

                <Menu pointing secondary className="project-nav">
                    <Menu.Item as={Link} to={`/backend/project/${project.id}`} active={active === 'Projekt'}>Projekt</Menu.Item>
                    <Menu.Item as={Link} to={`/backend/project/${project.id}/stories`} active={active === 'Storys'}>Storys</Menu.Item>
                    <Menu.Item as={Link} to={`/backend/project/${project.id}/expenses`} active={active === 'teileKosten'}>Teile/Kosten</Menu.Item>
                    <Menu.Item as={Link} to={`/backend/project/${project.id}/workinghours`} active={active === 'Aufwand'}>Aufwand</Menu.Item>
                    <Responsive minWidth={Responsive.onlyTablet.minWidth} as={Menu.Item} active={active === 'Aufgaben'}>
                        <Link to={`/backend/project/${project.id}/todos`}>Aufgaben</Link>
                    </Responsive>
                    <Responsive minWidth={Responsive.onlyTablet.minWidth} as={Menu.Item} active={active === 'montagehilfe'}>
                        <Link to={`/backend/project/${project.id}/assemblies`}>Montagehilfe</Link>
                    </Responsive>
                    <Responsive minWidth={Responsive.onlyTablet.minWidth} as={Menu.Item} className="settings-icon" active={active === 'einstellungen'}>
                        <Link to={`/backend/project/${project.id}/settings`}>
                            <Image src={SettingIcon} style={{width: "15px", height: "15px"}}/>
                        </Link>
                    </Responsive>

                    {/* Dropdown Menu for mobile devices */}

                    <Responsive 
                        {...Responsive.onlyMobile} as={Menu.Item} 
                        className="settings-icon project-nav-dropdown" 
                        active={active === 'einstellungen' || active === 'montagehilfe' || active === 'Aufgaben'}>

                        <Dropdown onClick={(e) => e.preventDefault()} icon={<Image src={SettingIcon} style={{width: "15px", height: "15px"}}/>}>
                            <Dropdown.Menu>
                                <Dropdown.Item>
                                    <Link to={`/backend/project/${project.id}/todos`}>Aufgaben</Link>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <Link to={`/backend/project/${project.id}/assemblies`}>Montagehilfe</Link>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <Link to={`/backend/project/${project.id}/settings`}>Einstellungen</Link>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                    </Responsive>
                    
                </Menu>

            </div>
        )
    }
}

export default ProjectNav
