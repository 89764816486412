import React from 'react';
import { withRouter, Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import Select from 'react-select';
import { selectError } from '../../../components/ReactSelect/customStyles';
import config from '../../../config/config'
import SearchModule from '../../../components/SearchModule/SearchModule';
import {
    Container,
    Header,
    Input,
    Button,
    Message,
    Icon,
    Form,
} from 'semantic-ui-react'
import { FileUpload } from 'ix-redux-file-upload';
import ImageWrapper from '../../../components/helpers/image-wrapper';
import { hideFooter, handleContainer, showFooter, setRuntime } from '../../../actions/global';
import BeParent from '../../project/backend/be-parent'
import { postProject } from '../../../actions/account';
import ProgressiveImager from '../../../components/helpers/progressive-imager';

/* CSS */
import 'react-circular-progressbar/dist/styles.css';
import './NewProject.scss';
import { clearSelectedFields } from '../../../actions/autosuggestion';
import { findSourceMap } from 'module';

/**
* NewProject
*/

class NewProject extends BeParent {

    state = {
        validationMessage: '',
        validationCategory: false,
        fieldValidationBrand: false,
        fieldValidationModel: false,
        fieldValidationVariant: false,
        fieldValidationGoal: false,
        fieldValidationYear: false,
        formValidation: {
            check: true,
            validationYear: {
                error: false,
                message: ''
            },
            validationCylinder: {
                error: false,
                message: ''
            },
            validationPerformance: {
                error: false,
                message: ''
            },
            validationGears: {
                error: false,
                message: ''
            },
            validationDisplacement: {
                error: false,
                message: ''
            },
        },
    }

    constructor(props) {
        super(props);

        this.validateFirst = this.validateFirst.bind(this);
        this.validateSecond = this.validateSecond.bind(this);
        this.validateSubmit = this.validateSubmit.bind(this);
        this.submit = this.submit.bind(this);

    }

    componentDidMount(){
        const { dispatch } = this.props;
        dispatch(hideFooter());
        dispatch(handleContainer("secondary", "Fahrzeug anlegen", false, "new-project"));
        dispatch(setRuntime({page: 'be-project-new'}));
        window.scrollTo(0, 0)
    }

    componentWillUnmount = () => {
        const { dispatch } = this.props;
        dispatch(showFooter());
    }

    validateFirst(e, element) {
        const { inputProject } = this.props;

        //Select validation
        if(!inputProject.scope){
            this.setState({validationCategory: true})
        }
        // if(!inputProject.goal){
        //     this.setState({fieldValidationGoal: true})
        // }
        if(inputProject.scope){
            this.setState({validationCategory: false})
        }
        // if(inputProject.goal){
        //     this.setState({fieldValidationGoal: false})
        // }


        //Autosuggestion validation
        if(!inputProject.brand){
            this.setState({fieldValidationBrand: true})
        }
        if(!inputProject.model){
            this.setState({fieldValidationModel: true})
        }
        if(inputProject.brand) {
            this.setState({fieldValidationBrand: false})
        }
        if(inputProject.model){
            this.setState({fieldValidationModel: false})
        }


        if (
            !inputProject.scope ||
            !inputProject.brand ||
            !inputProject.model
        ) {
            this.setState({
                // validationMessage: 'Die Felder Kategorie, Marke und Model müssen ausgefüllt werden.',
                validationMessage: 'Fülle die markierten Felder aus.'
            });
            e.preventDefault();
        }
        else {
            this.setState({
                validationMessage: '',
            });
        }
    }

    validateSecond(e, element) {
        const { inputProject } = this.props;

        if (
            !inputProject.mediamain
        ) {
            this.setState({
                validationMessage: 'Es muss ein Hauptbild hochgeladen werden.',
            });
            e.preventDefault();
        }
        else {
            this.setState({
                validationMessage: '',
            });
        }
    }

    clearFields() {
        this.handleChangeSelect('', '');
        this.handleChangeInput('', '')
    }

    validateSubmit = () => {
        const { inputProject } = this.props;

        this.setState({
            formValidation: {
                ...this.state.formValidation,
                validationYear: config.validateProductionYear(inputProject.year),
                validationCylinder: config.numericFields(inputProject.cylinder),
                validationDisplacement: config.numericFields(inputProject.displacement),
                validationGears: config.numericFields(inputProject.gearno),
                validationPerformance: config.validationPointNumbers(inputProject.performance)
            }
        }, this.submit)
    }

    submit(e, element) {
        const { dispatch, inputProject } = this.props;
        const { formValidation } = this.state;

        if(
            formValidation.validationYear.error ||
            formValidation.validationCylinder.error ||
            formValidation.validationDisplacement.error ||
            formValidation.validationGears.error ||
            formValidation.validationPerformance.error
        ) {
            this.setState({formValidation: { ...this.state.formValidation, check: false}})
            return false;
        }
        else {
            this.setState({ formValidation: { ...this.state.formValidation, check: true } })
            inputProject.performance = config.convertToFloat(inputProject.performance);
            dispatch(postProject(inputProject));
            dispatch(clearSelectedFields())
        }

    }

    componentDidUpdate(prevProps) {
        const { inputProject, location } = this.props;

        if(prevProps.location.pathname !== location.pathname) window.scrollTo(0, 0);

        if(inputProject.newId && inputProject.newId !== prevProps.inputProject.newId) {
            this.setState({ redirect: '/backend/project/'+inputProject.newId });
        }
    }

    render() {
        const { match } = this.props;

        if (this.state && this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }

        if (match.params.step === 'firststep') {
            return (<>{this.firststep()}</>);
        }
        else if (match.params.step === 'secondstep') {
            return (<>{this.secondstep()}</>);
        }
        else if (match.params.step === 'laststep') {
            return (<>{this.laststep()}</>);
        }
    }

    firststep(){
        const {inputProject} = this.props;

        return (
            <div className="new-project">
                <div className="top-header">
                    <Container>
                        <span>Art / Modell / Ziel</span>
                    </Container>
                </div>


                <Container>

                    <div className="progress-bar">
                        <CircularProgressbar
                            value={1}
                            maxValue={3}
                            text={`${1}`}
                            styles={buildStyles({
                                pathColor: "#E7344C",
                                textSize: "36px",
                                textColor: "#000"
                                })}
                            />
                    </div>

                </Container>

                <Container>

                    <Form>

                        <Form.Field>
                            <label className={this.state.validationCategory ? "error-label" : null}>Kategorie</label>
                            <Select menuPlacement="auto"
                                placeholder="Auswahl..."
                                options={config.getScopes()}
                                name="inputProject.scope"
                                styles={this.state.validationCategory && selectError}
                                onChange={this.handleChangeSelect}
                                value={(inputProject.scope ? config.getDefault(config.getScopes(), inputProject.scope) : null)}
                                classNamePrefix="custom-select"
                                className="custom-select-wrapper"
                            />
                        </Form.Field>

                        <SearchModule
                            validation={{
                                brand: this.state.fieldValidationBrand,
                                model: this.state.fieldValidationModel,
                                variant: this.state.fieldValidatioVariant
                            }}
                            brandInput
                            modelInput
                            variantInput
                            brandFieldName="inputProject.brand"
                            modelFieldName="inputProject.model"
                            variantFieldName="inputProject.variant"
                        />

                        <Form.Field>
                            <label className={this.state.fieldValidationGoal ? "error-label" : null}>Projektziel (optional)</label>
                            <Select menuPlacement="auto"
                                placeholder="Auswahl..."
                                // styles={this.state.fieldValidationGoal && selectError}
                                options={config.getGoals()}
                                name="inputProject.goal"
                                onChange={this.handleChangeSelect}
                                value={(inputProject.goal ? config.getDefault(config.getGoals(), inputProject.goal) : null)}
                                classNamePrefix="custom-select"
                                className="custom-select-wrapper"
                            />
                        </Form.Field>

                    </Form>

                    {this.state.validationMessage &&
                        <Message negative>{this.state.validationMessage}</Message>
                    }

                    <div className="new-projekt-next-btn" style={{paddingBottom: '5rem'}}>
                        <Link to ="/backend/new_project/secondstep" onClick={this.validateFirst}>
                            <Button
                                color="red"
                                style={{margin: "0 auto"}}
                            >
                                Weiter
                            </Button>
                        </Link>
                    </div>

                </Container>

            </div>

        )
    }

    secondstep(){
        const {account, inputProject} = this.props;

        return (
            <div className="new-project">

                <div className="top-header">
                    <Container>
                        <span>
                        Hauptbild / Vorschaubild
                        </span>
                    </Container>
                </div>


                <Container>

                    <div className="progress-bar">
                        <CircularProgressbar
                            value={2}
                            maxValue={3}
                            text={`${2}`}
                            styles={buildStyles({
                                pathColor: "#E7344C",
                                textSize: "36px",
                                textColor: "#000"
                                })}
                            />
                    </div>

                </Container>

                <Container>

                    <div style={{display: "block"}}>

                        <Header as="h2">Hauptbild</Header>
                        <Message color="teal">Das Hauptbild erscheint ganz oben auf deinen Projekten im Breitformat.</Message>

                        <FileUpload
                          headers={{ 'Authorization': 'Bearer '+account.token }}
                          allowedFileTypes={['jpg', 'jpeg']}
                          dropzoneId={'inputProject.mediamain'}
                          url={config.API_HOST + config.API_UPLOAD}
                        >
                        {inputProject.mediamainPath ?
                            <div className="image_container main_img">
                                <ImageWrapper path={inputProject.mediamainPath} className="new-project-main-img">
                                    <ProgressiveImager src={config.getImageUrl('main', inputProject.mediamainPath)}/>
                                    <Icon name="pencil" circular inverted color="grey"/>
                                </ImageWrapper>
                            </div>
                        :
                            <Button color="red">Hauptbild hinzufügen</Button>
                        }
                        </FileUpload>

                        <Header as="h2">Vorschaubild</Header>
                        <Message color="teal">Das Vorschaubild wird an verschiedenen Stellen in einem Kreis angezeigt.</Message>

                        <FileUpload
                          headers={{ 'Authorization': 'Bearer '+account.token }}
                          allowedFileTypes={['jpg', 'jpeg']}
                          dropzoneId={'inputProject.mediapreview'}
                          url={config.API_HOST + config.API_UPLOAD}
                        >
                        {inputProject.mediapreviewPath ?
                            <div className="circular-image">
                                <ImageWrapper className="image_container preview_img" path={inputProject.mediapreviewPath}>
                                    <ProgressiveImager src={config.getImageUrl('main', inputProject.mediapreviewPath)} circular />
                                    <Icon name="pencil" circular inverted color="grey"/>
                                </ImageWrapper>
                            </div>
                        :
                            <Button color="red">Vorschaubild hinzufügen</Button>
                        }
                        </FileUpload>

                    </div>

                    {this.state.validationMessage &&
                        <Message negative>{this.state.validationMessage}</Message>
                    }

                    <div className="new-projekt-next-btn">
                        <Link to ="/backend/new_project/firststep">
                            <Button
                                basic
                                color="red"
                                style={{marginRight: "5px" }}
                            >
                                Zurück
                            </Button>
                        </Link>
                        <Link to ="/backend/new_project/laststep" onClick={this.validateSecond}>
                            <Button
                                color="red"
                                style={{marginLeft: "5px"}}
                            >
                                Weiter
                            </Button>
                        </Link>
                    </div>

                </Container>

            </div>
        )
    }

    laststep(){
        const {inputProject} = this.props;

        return(
                <div className="new-project">
                    <div className="top-header">
                        <Container>
                            <span>Fahrzeugdaten</span>
                        </Container>
                    </div>

                    <Container>

                        <div className="progress-bar">
                            <CircularProgressbar
                                value={3}
                                maxValue={3}
                                text={`${3}`}
                                styles={buildStyles({
                                    pathColor: "#E7344C",
                                    textSize: "36px",
                                    textColor: "#000"
                                    })}
                                />
                        </div>

                    </Container>

                    <Container>

                        <Header as="h2">Fahrzeugdaten<span className="header-info-span"> (ab Werk)</span></Header>
                        <Message color="teal">Nachfolgend kannst Du die wichtigsten Fahrzeugdaten angeben (Werksangaben).
                         Du kannst diese später jederzeit ändern oder mit aktuellen Daten ergänzen.</Message>

                        <Form>
                            <Form.Field error={this.state.formValidation.validationYear.error}>
                                <label>Baujahr</label>
                                <Input
                                    type="text"
                                    style={{width: "80px"}}
                                    placeholder="1970"
                                    name="inputProject.year"
                                    onChange={this.handleChangeNumericInput}
                                    value={inputProject.year}
                                    maxLength="4"
                                    onBlur={(e) => this.setState({
                                        formValidation: {
                                            ...this.state.formValidation,
                                            validationYear: config.validateProductionYear(e.target.value)
                                        }
                                    })}
                                />
                                {/* <Checkbox style={{marginLeft: "1rem", padding: "0"}} label="geschätzt"/> */}
                                {this.state.formValidation.validationYear.error &&
                                    <Message color="red">{this.state.formValidation.validationYear.message}</Message>
                                }
                            </Form.Field>



                            <Form.Field
                                style={{
                                    width: "170px"
                                }}
                            >
                                <label>Motorart</label>
                                <Select menuPlacement="auto"
                                    placeholder="Auswahl..."
                                    options={config.getEngines()}
                                    name="inputProject.engine"
                                    onChange={this.handleChangeSelect}
                                    value={(inputProject.engine ? config.getDefault(config.getEngines(), inputProject.engine) : null)}
                                    classNamePrefix="custom-select"
                                    className="custom-select-wrapper"
                                />
                            </Form.Field>

                            <Form.Input
                                type="text"
                                label="Anzahl Zylinder"
                                placeholder="6"
                                style={{
                                    width: "70px"
                                }}
                                name="inputProject.cylinder"
                                onChange={this.handleChangeNumericInput}
                                maxLength="2"
                                value={inputProject.cylinder}
                                error={this.state.formValidation.validationCylinder.error ? true : false}
                                onBlur={(e) => this.setState({
                                    formValidation: {
                                        ...this.state.formValidation,
                                        validationCylinder: config.numericFields(e.target.value)
                                    }
                                })}
                            />

                    {this.state.formValidation.validationCylinder.error &&
                        <Message color="red">
                            {this.state.formValidation.validationCylinder.message}
                        </Message>
                    }

                            <Form.Field
                                style={{
                                    width: "170px"
                                }}
                            >
                                <label>Zylinderanordnung</label>
                                <Select menuPlacement="auto"
                                    placeholder="Auswahl..."
                                    options={config.getLayouts()}
                                    name="inputProject.layout"
                                    onChange={this.handleChangeSelect}
                                    value={(inputProject.layout ? config.getDefault(config.getLayouts(), inputProject.layout) : null)}
                                    classNamePrefix="custom-select"
                                    className="custom-select-wrapper"
                                />
                            </Form.Field>

                            <Form.Field error={this.state.formValidation.validationDisplacement.error ? true : false}>
                            <label className="custom-label">Hubraum*</label>
                            <Input
                                type="text"
                                style={{width: "170px"}}
                                label={{
                                    basic: true,
                                    content: 'ccm'
                                }}
                                labelPosition='right'
                                placeholder='2300'
                                name="inputProject.displacement"
                                onChange={this.handleChangeNumericInput}
                                value={inputProject.displacement}
                                maxLength="5"
                                onBlur={(e) => this.setState({
                                    formValidation: {
                                        ...this.state.formValidation,
                                        validationDisplacement: config.numericFields(e.target.value)
                                    }
                                })}
                            />
                            <span className="input-info" style={{color: "#00000066", display: "block"}}>* nach Steuern/aufgerundet</span>
                            </Form.Field>

                            {this.state.formValidation.validationDisplacement.error &&
                                <Message color="red">
                                    {this.state.formValidation.validationDisplacement.message}
                                </Message>
                            }

                            <Form.Field error={this.state.formValidation.validationPerformance.error ? true : false}>
                                <label className="custom-label">Leistung</label>
                                <Input
                                    style={{width: "170px"}}
                                    label={{
                                        basic: true,
                                        content: 'PS'
                                    }}
                                    type="text"
                                    labelPosition='right'
                                    placeholder='120'
                                    name="inputProject.performance"
                                    onChange={this.handleChangeNumericInput}
                                    maxLength="5"
                                    value={inputProject.performance && inputProject.performance !== 0 ? config.convertToPointNumber(inputProject.performance)  : ''}
                                    onBlur={(e) => this.setState({
                                        formValidation: {
                                            ...this.state.formValidation,
                                            validationPerformance: config.validationPointNumbers(e.target.value)
                                        }
                                    })}
                                />
                            </Form.Field>

                            {this.state.formValidation.validationPerformance.error &&
                                <Message color="red">
                                    {this.state.formValidation.validationPerformance.message}
                                </Message>
                            }

                    <Form.Field
                        style={{
                            width: "170px"
                        }}
                    >
                        <label>Kraftstoff</label>
                        <Select menuPlacement="auto"
                            placeholder='—'
                            name="inputProject.fuel"
                            onChange={this.handleChangeSelect}
                            value={config.getDefault(config.getFuels(), inputProject.fuel)}
                            fluid
                            options={config.getFuels()}
                            classNamePrefix="custom-select"
                            className="custom-select-wrapper"
                        />
                    </Form.Field>

                            <Form.Field
                                style={{
                                    width: "170px"
                                }}
                            >
                                <label>Getriebe</label>
                                <Select menuPlacement="auto"
                                    placeholder="Auswahl..."
                                    options={config.getGears()}
                                    name="inputProject.gear"
                                    onChange={this.handleChangeSelect}
                                    value={(inputProject.gear ? config.getDefault(config.getGears(), inputProject.gear) : null)}
                                    onKeyDown={(e) => this.allowOnlyNumbers(e)}
                                    classNamePrefix="custom-select"
                                    className="custom-select-wrapper"
                                />
                            </Form.Field>

                            <Form.Input
                                type="text"
                                label="Anzahl Gänge"
                                placeholder="3"
                                style={{
                                    width: "70px"
                                }}
                                name="inputProject.gearno"
                                pattern="[0-9]"
                                value={inputProject.gearno}
                                onChange={this.handleChangeNumericInput}
                                maxLength="2"
                                error={this.state.formValidation.validationGears.error ? true : false}
                                onBlur={(e) => this.setState({
                                    formValidation: {
                                        ...this.state.formValidation,
                                        validationGears: config.numericFields(e.target.value)
                                    }
                                })}
                            />

                    <Form.Field
                        style={{
                            width: "170px"
                        }}
                    >
                        <label>Antriebsart</label>
                        <Select menuPlacement="top"
                            classNamePrefix="custom-select"
                            className="custom-select-wrapper"
                            name="inputProject.drive"
                            onChange={this.handleChangeSelect}
                            value={config.getDefault(config.getDrives(), inputProject.drive)}
                            options={config.getDrives()}
                        />
                    </Form.Field>

                            {this.state.formValidation.validationGears.error &&
                                <Message color="red">
                                    {this.state.formValidation.validationGears.message}
                                </Message>
                            }

                        </Form>

                        {!this.state.formValidation.check &&

                           <Message color="red">
                               Überprüfe deine Angaben.
                           </Message>

                        }

                        {inputProject.postingMessage === 'FAILURE' &&
                            <Message negative>Beim Senden ist ein Fehler aufgetreten.</Message>
                        }
                        {this.state.validationMessage &&
                            <Message negative>{this.state.validationMessage}</Message>
                        }

                         <div className="new-projekt-next-btn" style={{position: "static"}}>

                            <Link to ="/backend/new_project/secondstep">
                                <Button
                                    basic
                                    color="red"
                                    style={{marginRight: "5px" }}
                                >
                                    Zurück
                                </Button>
                            </Link>

                            <Button
                                color="red"
                                style={{margin: "0 auto"}}
                                onClick={this.validateSubmit}
                                loading={inputProject.isPosting}
                            >
                                Fertig
                            </Button>
                        </div>

                    </Container>

                </div>
        )
    }

}


function mapStateToProps(state, ownProps) {
    const { globalByComponent, account } = state

    const { brand, model, variant } = globalByComponent[
        'SearchModule'
    ] || {
        brand: "",
        model: "",
        variant: "",
    }

    const inputProject = account['inputProject'] || {}

    return {
        brand,
        model,
        variant,
        inputProject,
        account,
    }
}


export default connect(mapStateToProps)(withRouter(NewProject));
