import React, { Component } from 'react';
import { Header, Container, Divider, List, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { footerActiveItem, handleContainer, handleHeaderItems } from '../actions/global';
import Helmet from 'react-helmet'
import { getPage } from '../actions/page';
import ErrorHandler from '../components/ErrorHandler/ErrorHandler';

class Conditions extends Component {

    componentDidMount = () => {
        const { dispatch } = this.props;
        dispatch(footerActiveItem('footermenu'))
        window.scrollTo(0, 0);
        dispatch(handleHeaderItems(""));
        dispatch(handleContainer("main", "AGB", null, null, null, null, {description: 'Die Allgemeine Geschäftsbedinungen von Vetero. Hast Du auch einen Oldtimer? Dann ist Vetero genau das Richtige für Dich.'}));
        this.fetchPageContent()
    }

    fetchPageContent = () => {
        const { dispatch } = this.props;
        dispatch(getPage(288, 'conditions'))
    }

    render() {

        const { title, content, isFetching, isFailed } = this.props;

        return (
            <>
            <Helmet>
                <title>AGB - Vetero Oldtimer Portal</title>
                <meta name="description" content='Die Allgemeine Geschäftsbedinungen von Vetero. Hast Du auch einen Oldtimer? Dann ist Vetero genau das Richtige für Dich.' />
            </Helmet>
            <Container className="privacy-page">

                {isFetching && 
                    <Loader active/>
                }

                {isFailed && 
                    <ErrorHandler callbackFunction={this.fetchPageContent}/>
                }

                {!isFetching && title && content && 
                <>
                    <Header as="h2">{title.rendered}</Header>
                    <Divider/>

                    <div dangerouslySetInnerHTML={{__html: content.rendered}}/>
                </>
                }
                <Divider/>

                
               
            </Container>
            </>
        )
    }
}

function mapStateToProps(state, ownProps){
    const { pageByName } = state
    const { title, content, isFetching, isFailed } = pageByName['conditions']
    || {
        isFetching: true,
        isFailed: false,
        content: '',
        title: ''
    }
    
    return {
        content,
        title,
        isFailed,
        isFetching
    }
}

export default connect(mapStateToProps)(Conditions);
