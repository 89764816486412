import React from 'react'
import { connect } from 'react-redux';
import { handleUserInput, fetchAutosuggestionBrand, fetchAutosuggestionModel, fetchAutosuggestionVariant, selectBrand, selectModel, selectVariant, toggleDropdown } from '../../actions/autosuggestion';
import './SearchModule.scss';
import BeParent from '../../pages/project/backend/be-parent';
import Select from 'react-select'

class SearchModule extends BeParent {

    constructor(props){
        super(props);

        this.handleOnChangeBrand = this.handleOnChangeBrand.bind(this);
        this.handleOnChangeModel = this.handleOnChangeModel.bind(this);
        this.handleOnChangeVariant = this.handleOnChangeVariant.bind(this);

        this.state = {
            noOptionsMessage: "Nicht gefunden"
        }

    }

    componentDidMount = () => {
        document.addEventListener('click', this.handleClick, false);
    }

    componentWillUnmount = () => {
        document.removeEventListener('click', this.handleClick, false);
        const { dispatch } = this.props;
        dispatch(handleUserInput([]))
    }

    handleClickOutside = () => {
        const { dispatch } = this.props;
        dispatch(fetchAutosuggestionBrand(null));
        dispatch(fetchAutosuggestionModel(null));
        dispatch(fetchAutosuggestionVariant());
    }

    handleClick = (e) => {
        document.addEventListener('click', this.handleClick, false);
        if(this.node.contains(e.target)){
            return;
        }

        this.handleClickOutside();

    }

    handleOnChangeBrand = (e) => {
        const { dispatch, brandFieldName } = this.props;
        dispatch(handleUserInput(e.target.value));
        dispatch(fetchAutosuggestionBrand(e.target.value));
        dispatch(selectBrand(e.target.value));
        dispatch(toggleDropdown(true, false, false));
        this.handleChange(brandFieldName, e.target.value);
    }

    handleOnChangeModel = (e) => {
        const { dispatch, modelFieldName } = this.props;
        dispatch(handleUserInput(e.target.value));
        dispatch(fetchAutosuggestionModel(e.target.value));
        dispatch(selectModel(e.target.value));
        dispatch(toggleDropdown(false, true, false));

        this.handleChange(modelFieldName, e.target.value);
    }

    handleOnChangeVariant = (e) => {
        const { dispatch, variantFieldName } = this.props;
        dispatch(handleUserInput(e.target.value));
        dispatch(fetchAutosuggestionVariant(e.target.value));
        dispatch(selectVariant(e.target.value));
        dispatch(toggleDropdown(false, false, true));
        console.log(variantFieldName)
        this.handleChangeAutosuggestionSelect(variantFieldName, e.target.value);
    }

    handleSelectBrand = (e) => {
        const { dispatch, brandFieldName } = this.props;
        dispatch(selectBrand(e.target.innerHTML));
        dispatch(toggleDropdown(false, false, false));
        this.handleChange(brandFieldName, e.target.innerHTML);
    }

    handleSelectModel = (e) => {
        const { dispatch, modelFieldName } = this.props;
        dispatch(selectModel(e.target.innerHTML));
        dispatch(toggleDropdown(false, false, false));
        this.handleChange("inputForumThread.thread.model", e.target.innerHTML);
    }

    handleSelectVariant = (e) => {
        const { dispatch, variantFieldName } = this.props;
        dispatch(selectVariant(e.target.innerHTML));
        dispatch(toggleDropdown(false, false, false));
        this.handleChange(variantFieldName, e.target.innerHTML);
    }

    render() {

        const { validation, brandInput, variantList, modelInput, variantInput, variantFieldName, brandFieldName, modelFieldName, brandInputValue } = this.props;

        return (
            <div ref={node => this.node = node}>
                {/* BRAND INPUT */}
                {brandInput &&
                    <div className="autosuggestion">
                        <label className={validation.brand ? "error-label" : null}>Marke</label>
                        <input
                            placeholder="Volkswagen, BMW..."
                            onChange={this.handleOnChangeBrand}
                            autoComplete="off"
                            className={!validation.brand ? "autosuggestion-input" : "autosuggestion-input error"}
                            value={brandInputValue ? brandInputValue : this.props.selectedBrand}
                            name={brandFieldName}
                            onKeyDown={this.handleSelectKeyboard}
                        />
                        <div className={this.props.brandMenu ?  "autosuggestion-list show" : "autosuggestion-list"}>
                        {this.props.brand &&
                                <ul>
                                    {this.props.brand.length > 0 &&
                                        this.props.brand.map((option, index) => (
                                            <li 
                                                onClick={this.handleSelectBrand} 
                                                key={index}
                                                
                                            >
                                                {option}
                                            </li>
                                        ))
                                    }

                                </ul>
                            }

                        </div>
                    </div>
                } 


                {/* MODEL INPUT */}
                {modelInput &&
                    <div className="autosuggestion">
                        <label className={validation.model ? "error-label" : null}>Modell</label>
                        <input
                            placeholder="Golf 1, 3er..."
                            onChange={this.handleOnChangeModel}
                            autoComplete="off"
                            className={!validation.model ? "autosuggestion-input" : "error autosuggestion-input"}
                            value={this.props.selectedModel}
                            name={modelFieldName}
                        />
                        <div className={this.props.modelMenu ? "autosuggestion-list show" : "autosuggestion-list"}>
                            {this.props.model &&
                                <ul>
                                    {this.props.model.length > 0 &&
                                        this.props.model.map((option, index) => (
                                            <li onClick={this.handleSelectModel} key={index}>{option}</li>
                                        ))
                                    }

                                </ul>
                            }

                        </div>
                    </div>
                  
                }

                {/* VARIANT INPUT */}
                {variantInput &&
                    <div className="autosuggestion">
                        <label className={validation.variant ? "error-label" : null}>Typ (optional)</label>
                        <input
                            placeholder="GTI, 323i..."
                            onChange={this.handleOnChangeVariant}
                            className={!validation.variant ? "autosuggestion-input" : "autosuggestion-input error"}
                            value={this.props.selectedVariant}
                            name={variantFieldName}
                        />
                        <div className={this.props.variantMenu ?  "autosuggestion-list show" : "autosuggestion-list"}>

                            {this.props.variant &&
                                <ul>

                                    {this.props.variant.length > 0 &&
                                        this.props.variant.map((option, index) => (
                                            <li onClick={this.handleSelectVariant}  key={index}>{option}</li>
                                        ))
                                    }

                                </ul>
                            }

                        </div>
                    </div>
                }

            </div>
        )
    }
}

function mapStateToProps(state, ownProps){
    const { globalByComponent, account } = state
    const { brand, model, variant, searchModulUserInput, selectedBrand, selectedModel, selectedVariant, brandMenu, modelMenu, variantMenu } = globalByComponent[
         'SearchModule'
    ] || {
        brand: [],
        model: [],
        variant: [],
        searchModulUserInput: "",
        selectedBrand: "",
        selectedModel: "",
        selectedVariant: "",
        brandMenu: false,
        modelMenu: false,
        variantMenu: false
    }

    const { inputProject, inputForumThread } = account;

    return {
        brand,
        model,
        variant,
        searchModulUserInput,
        selectedBrand,
        selectedModel,
        selectedVariant,
        brandMenu,
        modelMenu,
        variantMenu,
        inputProject: (inputProject ? inputProject : {}),
        inputForumThread: (inputForumThread ? inputForumThread : {}),
    }
}

export default connect(mapStateToProps)(SearchModule)
