import config from '../config/config'
import { createAction } from 'redux-api-middleware';

export const CHANGE_TODO = 'CHANGE_TODO'
export const NEW_TODO = 'NEW_TODO'
export const API_TODO_REQUEST = 'API_TODO_REQUEST'
export const API_TODO_SUCCESS = 'API_TODO_SUCCESS'
export const API_TODO_FAILURE = 'API_TODO_FAILURE'
export const API_TODO_DELETE_REQUEST = 'API_TODO_DELETE_REQUEST'
export const API_TODO_DELETE_SUCCESS = 'API_TODO_DELETE_SUCCESS'
export const API_TODO_DELETE_FAILURE = 'API_TODO_DELETE_FAILURE'
export const API_TODO_LIST_REQUEST = 'API_TODO_LIST_REQUEST'
export const API_TODO_LIST_SUCCESS = 'API_TODO_LIST_SUCCESS'
export const API_TODO_LIST_FAILURE = 'API_TODO_LIST_FAILURE'
export const API_POST_TODO_REQUEST = 'API_POST_TODO_REQUEST'
export const API_POST_TODO_SUCCESS = 'API_POST_TODO_SUCCESS'
export const API_POST_TODO_FAILURE = 'API_POST_TODO_FAILURE'


export function discardTodo(id, project) {
    return (dispatch, getState) => {
        const todolist = getState().todosByParent['project::'+project];
        var item = Object.values(todolist.items).filter(item => item.id === id)[0];

        delete item['input'];
        item['input'] = JSON.parse(JSON.stringify(item));

        dispatch(changeTodo(item));
    }
}

export function newTodo(project, name, value) {
    return {
        type: NEW_TODO,
        meta: { project },
        payload: {
            [name]: value,
        }
    }
}

export function postTodo(project, inputTitle, inputDate, inputDescription, inputProjectpart, inputMedia) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/todo`,
        method: 'POST',
        body: JSON.stringify({
            project,
            title: inputTitle,
            date: inputDate,
            description: inputDescription,
            projectpart: inputProjectpart,
            media: inputMedia,
        }),
        types: [
            {type: API_POST_TODO_REQUEST, meta: {project}},
            {type: API_POST_TODO_SUCCESS, meta: {project}},
            {type: API_POST_TODO_FAILURE, meta: {project}},
        ]
    })
}

export function changeTodo(item) {
    return {
        type: CHANGE_TODO,
        meta: {
            project: item.project,
        },
        payload: {
            item,
        }
    }
}

export function updateTodo(item) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/todo/${item.id}`,
        method: 'PUT',
        body: JSON.stringify({
            todo: item,
        }),
        types: [
            {type: API_TODO_REQUEST, meta: { project: item.project }},
            {type: API_TODO_SUCCESS, meta: { project: item.project }},
            {type: API_TODO_FAILURE, meta: { project: item.project }},
        ]
    })
}

export function deleteTodo(project, id) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/todo/${id}`,
        method: 'DELETE',
        types: [
            {type: API_TODO_DELETE_REQUEST, meta: { project, id }},
            {type: API_TODO_DELETE_SUCCESS, meta: { project, id }},
            {type: API_TODO_DELETE_FAILURE, meta: { project, id }},
        ]
    })
}

export function fetchTodoList(project) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/todo?project=${project}`,
        method: 'GET',
        types: [
            {type: API_TODO_LIST_REQUEST, meta: { project }},
            {type: API_TODO_LIST_SUCCESS, meta: { project }},
            {type: API_TODO_LIST_FAILURE, meta: { project }},
        ]
    })
}

function shouldFetchTodoList(state, project) {
    const todolist = state.todosByParent['project::'+project];
    if (
        !todolist ||
        ((todolist.isFetching || todolist.isFailed) && Date.now() - todolist.lastFetch > config.FETCH_TIMEOUT) ||
        (!todolist.isFetching && Date.now() - (todolist.lastUpdated ? todolist.lastUpdated : 0) > config.FETCH_AGAIN)
    ) {
        return true
    }
    return false
}

export default function fetchTodoListIfNeeded(project) {
    return (dispatch, getState) => {

        if (shouldFetchTodoList(getState(), project)) {
            return dispatch(fetchTodoList(project))
        }
    }
}
