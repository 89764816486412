import React, { Component } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { connect } from 'react-redux';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import './Gallery.scss';
import Carousel, { Modal, ModalGateway } from 'react-images';

const CustomModalFooter = ({ currentIndex, views }) => {
    const activeView = currentIndex + 1;
    const totalViews = views.length;
    if (!activeView || !totalViews) return null;
    return (
        totalViews > 1 &&
            <span class="react-images__footer__count custom-counter">
                {activeView} / {totalViews}
            </span>
    );
  };

  const CustomFooterCaption = ({ caption, views, currentIndex }) => {
    return (
        views.map((captions, index) => (
            currentIndex === index && captions.caption &&
            <div className="custom-captions" key={index}>
                <span className="caption">{captions.caption}</span>
            </div>
       ))
    );
  };

  const VideoView = ({views}) => {
      return (
        <video
            controls={true}
            autoPlay
            style={{ width: '100%', height: 'auto' }}
          >
            {views.map((vid, idx) => (
              <source key={idx} src={vid.src} />
            ))}
            Your browser does not support HTML5 video.
        </video>
      )
  }


class Gallery extends Component {

    render() {
        const { images, description, slide, onMovePrevRequest, onMoveNextRequest, galleryOpen, onClose, isVideo } = this.props;

        const customStyles = {
            footerCaption: () => ({
                background: "rgb(255, 255, 242)!important",
                color: "#36220F",
                fontSize: "0.875rem",
                position: "absolute",
                bottom: "2.25rem",
                left: "14px",
                right: "14px",
                maxWidth: "600px",
                margin: "0 auto",
                borderRadius: "5px",
              }),
        }

        if(galleryOpen) {
            document.body.classList.add('no-scroll');        }
        else {
            document.body.classList.remove('no-scroll')
        }

        return (
            isVideo ?
            //Video lightbox
                <ModalGateway>
                    {galleryOpen &&
                        <Modal onClose={onClose} preventScroll>
                            <Carousel
                                styles={customStyles}
                                views={images}
                                className="gallery-custom"
                                currentIndex={slide}
                                components={{ View: VideoView, FooterCount: CustomModalFooter, FooterCaption: CustomFooterCaption }}
                            />
                        </Modal>
                    }
                </ModalGateway>

                :

                galleryOpen &&
                    <Lightbox
                        mainSrc={images[slide]}
                        nextSrc={slide === images.length -1 ? null : images[(slide + 1) % images.length]}
                        prevSrc={slide === 0 ? null : images[(slide + images.length - 1) % images.length]}
                        imageCaption={description}
                        onMovePrevRequest={onMovePrevRequest}
                        onMoveNextRequest={onMoveNextRequest}
                        onCloseRequest={onClose}
                        wrapperClassName={description && description[0] === null && 'no-description'}
                        reactModalStyle={{
                            overlay: {
                                zIndex: 9999
                            }
                        }}
                    />

        )
    }
}

export default Gallery
