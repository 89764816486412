import config from '../config/config'
import { createAction } from 'redux-api-middleware';

export const CHANGE_PART = 'CHANGE_PART'
export const NEW_PART = 'NEW_PART'
export const API_PART_REQUEST = 'API_PART_REQUEST'
export const API_PART_SUCCESS = 'API_PART_SUCCESS'
export const API_PART_FAILURE = 'API_PART_FAILURE'
export const API_PART_DELETE_SUCCESS = 'API_PART_DELETE_SUCCESS'
export const API_PART_DELETE_REQUEST = 'API_PART_DELETE_REQUEST'
export const API_PART_DELETE_FAILURE = 'API_PART_DELETE_FAILURE'
export const API_PART_LIST_REQUEST = 'API_PART_LIST_REQUEST'
export const API_PART_LIST_SUCCESS = 'API_PART_LIST_SUCCESS'
export const API_PART_LIST_FAILURE = 'API_PART_LIST_FAILURE'
export const API_POST_PART_REQUEST = 'API_POST_PART_REQUEST'
export const API_POST_PART_SUCCESS = 'API_POST_PART_SUCCESS'
export const API_POST_PART_FAILURE = 'API_POST_PART_FAILURE'


export function discardPart(id, project) {
    return (dispatch, getState) => {
        const partlist = getState().partsByParent['project::'+project];
        var item = Object.values(partlist.items).filter(item => item.id === id)[0];

        delete item['input'];
        item['input'] = JSON.parse(JSON.stringify(item));

        dispatch(changePart(item));
    }
}

export function newPart(project, name, value) {
    return {
        type: NEW_PART,
        meta: { project },
        payload: {
            [name]: value,
        }
    }
}

export function postPart(project, inputTitle, inputDesc, inputMedia) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/part`,
        method: 'POST',
        body: JSON.stringify({
            project,
            name: inputTitle,
            description: inputDesc,
            media: inputMedia,
        }),
        types: [
            {type: API_POST_PART_REQUEST, meta: {project}},
            {type: API_POST_PART_SUCCESS, meta: {project}},
            {type: API_POST_PART_FAILURE, meta: {project}},
        ]
    })
}

export function changePart(item) {
    return {
        type: CHANGE_PART,
        meta: {
            project: item.project,
        },
        payload: {
            item,
        }
    }
}

// export function directBuy(item) {
//     return (dispatch) => {
//         item.input['done'] = true;
//         item.input['donedate'] = Math.round((new Date()).getTime() / 1000);
//
//         dispatch(updatePart(item.input));
//     }
// }

export function updatePart(item) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/part/${item.id}`,
        method: 'PUT',
        body: JSON.stringify({
            part: item,
        }),
        types: [
            {type: API_PART_REQUEST, meta: { project: item.project }},
            {type: API_PART_SUCCESS, meta: { project: item.project }},
            {type: API_PART_FAILURE, meta: { project: item.project }},
        ]
    })
}

export function deletePart(project, id) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/part/${id}`,
        method: 'DELETE',
        types: [
            {type: API_PART_DELETE_REQUEST, meta: { project, id }},
            {type: API_PART_DELETE_SUCCESS, meta: { project, id }},
            {type: API_PART_DELETE_FAILURE, meta: { project, id }},
        ]
    })
}

export function fetchPartList(project) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/part?project=${project}`,
        method: 'GET',
        types: [
            {type: API_PART_LIST_REQUEST, meta: { project }},
            {type: API_PART_LIST_SUCCESS, meta: { project }},
            {type: API_PART_LIST_FAILURE, meta: { project }},
        ]
    })
}

function shouldFetchPartList(state, project) {
    const partlist = state.partsByParent['project::'+project];
    if (
        !partlist ||
        ((partlist.isFetching || partlist.isFailed) && Date.now() - partlist.lastFetch > config.FETCH_TIMEOUT) ||
        (!partlist.isFetching && Date.now() - (partlist.lastUpdated ? partlist.lastUpdated : 0) > config.FETCH_AGAIN)
    ) {
        return true
    }
    return false
}

export default function fetchPartListIfNeeded(project) {
    return (dispatch, getState) => {

        if (shouldFetchPartList(getState(), project)) {
            return dispatch(fetchPartList(project))
        }
    }
}

export function fetchPartListIfDirty(project) {
    return (dispatch, getState) => {
        const partlist = getState().partsByParent['project::'+project];

        if (!partlist || partlist.isDirty) {
            return dispatch(fetchPartList(project));
        }
    }
}
