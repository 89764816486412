import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

/* PAGES */
/* *************************************************************** */

import NewProject from '../pages/dashboard/NewProject/NewProject';
import ProjectRouter from '../pages/project/backend/ProjectRouter';
import dashboard from '../pages/dashboard/dashboard';
import L from '../pages/dashboard/Likes';
import Messages from '../pages/dashboard/Messages/Messages';
import F from '../pages/dashboard/follower'
import DC from '../pages/dashboard/DashboardComments';
import For from '../pages/dashboard/Forum';
import Voucher from '../pages/dashboard/Voucher';
import OwnProjects from '../pages/dashboard/OwnProjects';
import AccountSettings from '../pages/dashboard/AccountSettings';
import ProfessionalEdit from '../pages/dashboard/Professional/ProfessionalEdit/ProfessionalEdit';
import ProfessionalList from '../pages/dashboard/Professional/ProfessionalList';
import Assembly from './../pages/project/backend/assembly';
import Todo from './../pages/project/backend/todo';
import Settings from './../pages/project/backend/settings';
import Expenditure from './../pages/project/backend/expenditure';
import PartsCosts from './../pages/project/backend/partsCosts';
import Storys from './../pages/project/backend/stories/storys';
import NewStory from './../pages/project/backend/stories/NewStory';
import EditStory from './../pages/project/backend/stories/EditStory';
import BeProject from './../pages/project/backend/be-project';
import ProfessionalAdminOverview from './../pages/dashboard/Admin/Professional/overview';
import ProfessionalAdminDetail from './../pages/dashboard/Admin/Professional/detail';

const BackendRoutes = () => {
    return (
        <Switch>
            <Route exact path="/backend" component={dashboard}/>
            <Route exact path="/backend/follower" component={F.Follower}/>
            <Route exact path="/backend/likes" component={L.Likes}/>
            <Route exact path="/backend/comments" component={DC.DashboardComments}/>
            <Route exact path="/backend/voucher" component={Voucher}/>
            <Route exact path="/backend/ownprojects" component={OwnProjects} />
            <Route exact path="/backend/forum" component={For.Forum} />
            <Route exact path="/backend/messages" component={Messages.Messages} />
            <Route exact path="/backend/messages/:id" component={Messages.MessagesMobile}/>
            <Route exact path="/backend/account_settings" component={AccountSettings}/>
            <Route exact path="/backend/professional" component={ProfessionalList}/>
            <Route exact path="/backend/professional/edit/:id/:step" component={ProfessionalEdit} />
            <Route exact path="/backend/admin/professional/overview" component={ProfessionalAdminOverview} />
            <Route exact path="/backend/admin/professional/detail/:id" component={ProfessionalAdminDetail} />
     
            <Route exact path="/backend/project/:id/assemblies" component={Assembly}/>
                <Route exact path="/backend/project/:id/todos" component={Todo}/>
                <Route exact path="/backend/project/:id/settings" component={Settings}/>
                <Route exact path="/backend/project/:id/workinghours" component={Expenditure}/>
                <Route exact path="/backend/project/:id/expenses/" component={PartsCosts} />
                <Route exact path="/backend/project/:id/stories/" component={Storys}/>
                <Route exact path="/backend/project/:id/stories/new_story" component={NewStory}/>
                <Route exact path="/backend/project/:id/stories/:storyId/edit_story" component={EditStory}/>
                <Route exact path="/backend/project/:id" component={BeProject}/>
            <Route exact path="/backend/new_project/:step">
                <NewProject/>
            </Route>
        </Switch>
    )
}

export default BackendRoutes;