import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Header, Container, Grid, Responsive, Divider, Image, List, Button, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { footerActiveItem, handleContainer, handleHeaderItems } from '../actions/global';
import Account from '../components/account/Account';

//Images
import headerBild from '../images/about-header.jpg'
import image2 from '../images/auto-3370768_1280.jpg';
import image3 from '../images/oldtimer-3508051_960_720.jpg';
import { getPage } from '../actions/page';
import HeaderImage from '../components/HeaderImage/HeaderImage';
import ErrorHandler from '../components/ErrorHandler/ErrorHandler';

class About extends Component {

    state = {
        loginPopupVisible: false
    }

    componentDidMount = () => {
        const { dispatch } = this.props;
       
        dispatch(footerActiveItem("about"))
        dispatch(handleContainer("newsfeed", "Über Vetero", null, null, null, null, {description: 'Erfahre hier die Philosophie von Vetero und der Vetero-Community. Hast Du auch einen Oldtimer? Dann ist Vetero genau das Richtige für Dich.'}));
        dispatch(handleHeaderItems("about"));
        this.fetchPageContent()
    }
    
    fetchPageContent = () => {
        const { dispatch } = this.props;
        dispatch(getPage(297, 'about', true))
    }

    render() {

        const { item, title, content, isFailed, isFetching, _embedded } = this.props;

        return (

            <div className="about-page">

                {isFetching && 
                    <Loader active/>
                }

                {isFailed && 
                    <ErrorHandler callbackFunction={this.fetchPageContent}/>
                }

                {!isFetching && title && content && 
                <>

                    {_embedded['wp:featuredmedia'][0] && _embedded['wp:featuredmedia'][0].media_details && _embedded['wp:featuredmedia'][0].media_details.sizes &&
                       <>
                        {_embedded['wp:featuredmedia'][0].media_details.sizes.medium_large &&
                            <Responsive {...Responsive.onlyMobile}>
                                <HeaderImage height="30vh" src={_embedded['wp:featuredmedia'][0].media_details.sizes.medium_large.source_url}/>
                            </Responsive>
                        }
                        {_embedded['wp:featuredmedia'][0].media_details.sizes.full && 
                            <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                                <HeaderImage src={_embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url}/>
                            </Responsive>
                        }
                        </>
                    }

                    <Container>

                        <Divider hidden/>
                        <Responsive {...Responsive.onlyMobile}>
                        <   Header as="h2">{title.rendered}</Header>
                        </Responsive>
                        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                            <Header as="h1">{title.rendered}</Header>
                        </Responsive>

                        <Divider hidden/>

                    </Container> 

                    <Container dangerouslySetInnerHTML={{__html: content.rendered}} /> 
                    
                    <Container>  
                        <Divider hidden/>
                        <Grid>
                            <Grid.Column width={16} textAlign="center">
                                {
                                    item && item.id ?
                                    <Button as={Link} color="red" to="/backend/new_project/firststep">
                                        Starte jetzt kostenlos dein Projekt
                                    </Button>
                                    :
                                    <Button color="red" onClick={() => this.setState({ loginPopupVisible: true })}>
                                        Starte jetzt kostenlos dein Projekt
                                    </Button>
                                }

                            </Grid.Column>
                        </Grid>
                        <Divider hidden/>
                    </Container> 

                    <Account
                        loginPopupVisible={this.state.loginPopupVisible}
                        loginPopupClose={() => this.setState({ loginPopupVisible: false })}
                        accountStep="register"
                    />

                </>
                }

            </div>
        )
    }
}

function mapStateToProps(state, ownProps){
    const { account, pageByName } = state;
    const { item } = account || {
        item: {},
    }

    const { title, content, _embedded, isFetching, isFailed } = pageByName['about']
    || {
        isFetching: true,
        isFailed: false,
        content: '',
        title: '',
        _embedded: null
    }

    return {
        item,
        content,
        title,
        _embedded,
        isFailed,
        isFetching
    }
}

export default connect(mapStateToProps)(withRouter(About));
