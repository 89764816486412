import React, { Component } from 'react'
import { Card, Container, Placeholder, Label, Divider, Responsive, Grid, Form, Message } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { handleContainer, handleHeaderItems, setRuntime } from '../../actions/global';
import { fetchNotificationList, resetNotificationList } from '../../actions/notification';
import config from '../../config/config';
import ProgressiveImager from '../../components/helpers/progressive-imager';
import ImageWrapper from '../../components/helpers/image-wrapper';
import { InfiniteScroll } from '../../components/helpers/InfiniteScroll'
import Truncate from 'react-truncate';
import Moment from 'react-moment';
import 'moment/locale/de'
import 'moment-timezone';

const calendarStrings = {
    lastDay : '[Gestern um] LT',
    sameDay : '[Heute um] LT',
    sameElse : 'LL',
}

class DashboardCommentsMobile extends Component {

    constructor(props){
        super(props);

        this.state = {
            windowWidth: null,
            commentContainerWidth: "100%"
        }

        this.commentContainer = React.createRef();

        this.events = [config.NTFCN_COMMENT_NEW, config.NTFCN_ANSWER_NEW];
        this.loadMore = this.loadMore.bind(this);
        this.loadMoreForce = this.loadMoreForce.bind(this);
    }

    componentDidMount = () => {
        const { dispatch } = this.props;
        if(document.querySelector('.comments-dashboard')){
            let commentContainer = document.querySelector('.comments-dashboard').offsetWidth;
            this.setState({commentContainerWidth: commentContainer})
        }

        dispatch(handleHeaderItems("dashboard"));
        dispatch(resetNotificationList(this.events, true));
        dispatch(fetchNotificationList(this.events, true));
        window.addEventListener('resize', this.updateDimensions);
        this.setState({windowWidth: window.innerWidth});
        window.scrollTo(0, 0);
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions = () => {

        let commentContainer = document.querySelector('.comments-dashboard').offsetWidth;

        this.setState({
            windowWidth: window.innerWidth,
            commentContainerWidth: commentContainer
        })
    }

    loadMore() {
        const { dispatch, page, isFailed } = this.props;

        if (!isFailed) {
            dispatch(fetchNotificationList(this.events, true, (page ? page+1 : 1)));
        }
    }

    loadMoreForce() {
        const { dispatch, page } = this.props;

        dispatch(fetchNotificationList(this.events, true, (page ? page+1 : 1)));
    }

    placeholderItem() {
        return (
            <div className="comments-dashboard">
                <Card className="comments-dashboard-placeholder">
                    <Card.Content>
                        <Placeholder className="comments-dashboard-placeholder-img">
                            <Placeholder.Image/>
                        </Placeholder>
                        <Placeholder className="comments-dashabord-placeholder-content">
                            <Placeholder.Line length="short"/>
                            <Placeholder.Line length="long"/>
                            <Placeholder.Line length="very long"/>
                        </Placeholder>
                    </Card.Content>
                </Card>
            </div>
        );
    }

    render() {
        const { listitems, isFetching, isFailed, hasMore } = this.props;

        if ((!listitems || !listitems.length) && isFetching) {
            return (<>
                {this.placeholderItem()}
                {this.placeholderItem()}
                {this.placeholderItem()}
           </> )
        }

        else if (!listitems || !listitems.length) {
            return (
                <Container text>
                    <Divider hidden/>
                    <Message color="teal">Es ist noch kein Eintrag vorhanden.</Message>
                </Container>
            )
        }

        else if (!isFailed) {
            return (
                <div className="comments-dashboard" ref={this.commentContainer}>
                    {listitems.map((comment, index) =>
                    <>
                    {/* Feedpost comments */}

                    {comment.feedPostData &&
                        <Card key={index} ref={this.commentContainer}>
                            <Card.Content as={Link} smooth to={`/feed/post/${comment.feedPostData.id}#comments`}>
                                <Link to={`/user/${comment.userAuthorData.urlslug}/${comment.userAuthor}`}>
                                    <ImageWrapper circular avatar path={comment.userAuthorData.mediaavatarPath}>
                                        <ProgressiveImager
                                            placeholder="avatar"
                                            circular
                                            src={config.getImageUrl('avatar', comment.userAuthorData.mediaavatarPath)}
                                            floated='left'
                                            size='mini'
                                        />
                                    </ImageWrapper>
                                </Link>
                                <Card.Header>
                                    <Link to={`/user/${comment.userAuthorData.urlslug}/${comment.userAuthor}`}>
                                        {comment.userAuthorData.name}
                                    </Link>

                                    <div className="comment-type">
                                        <Label color="green">Newsfeed</Label>
                                    </div>


                                        <Card.Meta className="comment-user-project">

                                            {/* <Link as="div" to={`/feed/post/${comment.feedPostData.id}#comments`}>
                                                {comment.feedPostData.text}
                                            </Link> */}

                                            {comment.feedPostData.text}

                                        </Card.Meta>
                                        {/* <Card.Meta className="comment-user-description">
                                            <Truncate width={this.state.windowWidth - 100}  lines={1} ellipsis={<>...</>}>
                                                {comment.feedPostData.text}
                                            </Truncate>
                                        </Card.Meta> */}

                            </Card.Header>
                            <Divider/>
                                <Card.Description className="comment-describtion">
                                    <Truncate width={this.state.windowWidth - 60} ellipsis={<>...</>} lines={1}>{comment.teaser}</Truncate>
                                </Card.Description>
                                <Card.Meta className="comment-date">
                                    <Moment format="DD.MM.YYYY" calendar={calendarStrings} unix >{comment.date}</Moment>
                                </Card.Meta>

                                {comment.new &&
                                    <div className="new-notification new-comment">
                                        <div className="new-notification-label"/>
                                    </div>
                                }

                            </Card.Content>
                        </Card>
                    }

                    {/* Project comments */}

                    {comment.project &&
                        <Card key={index} ref={this.commentContainer}>
                            <Card.Content as={Link} smooth to={`/oldtimer/${comment.projectData.urlslug}/${comment.project}#comments`}>
                                <Link to={`/user/${comment.userAuthorData.urlslug}/${comment.userAuthor}`}>
                                    <ImageWrapper circular avatar path={comment.userAuthorData.mediaavatarPath}>
                                        <ProgressiveImager
                                            placeholder="avatar"
                                            circular
                                            src={config.getImageUrl('avatar', comment.userAuthorData.mediaavatarPath)}
                                            floated='left'
                                            size='mini'
                                        />
                                    </ImageWrapper>
                                </Link>
                                <Card.Header>
                                    <Link to={`/user/${comment.userAuthorData.urlslug}/${comment.userAuthor}`}>
                                        {comment.userAuthorData.name}
                                    </Link>

                                    <div className="comment-type">
                                        <Label color="orange">Projekt</Label>
                                    </div>

                                        <Card.Meta className="comment-user-project">

                                            {/* <Link as="div" to={`/oldtimer/${comment.urlslug}/${comment.projectData.urlslug}/${comment.project}`}>
                                                {comment.projectData.title}
                                            </Link> */}

                                            {comment.projectData.title}

                                        </Card.Meta>
                                        {comment.project.description &&
                                            <Card.Meta className="comment-user-description">

                                                <Responsive as={Truncate} lines={1} width={this.state.windowWidth - 120} ellipsis={<>...</>} {...Responsive.onlyMobile}>
                                                    {comment.project.description}
                                                </Responsive>

                                                <Responsive as={Truncate} lines={1} width={this.state.commentContainerWidth - 120} ellipsis={<>...</>} minWidth={Responsive.onlyTablet.minWidth}>
                                                    {comment.project.description}
                                                </Responsive>

                                            </Card.Meta>
                                        }

                            </Card.Header>
                            <Divider/>
                                <Card.Description className="comment-describtion">
                                    <Truncate width={this.state.windowWidth - 60} ellipsis={<>...</>} lines={1}>{comment.teaser}</Truncate>
                                </Card.Description>
                                <Card.Meta className="comment-date">
                                    <Moment format="DD.MM.YYYY" calendar={calendarStrings} unix >{comment.date}</Moment>
                                </Card.Meta>

                                {comment.new &&
                                    <div className="new-notification new-comment">
                                        <div className="new-notification-label"/>
                                    </div>
                                }

                            </Card.Content>
                        </Card>
                        }

                        {/* Story comments */}

                        {comment.projectPartData &&
                        <Card key={index} ref={this.commentContainer}>
                            <Card.Content as={Link} smooth to={`/oldtimer/${comment.projectPartData.projecturlslug}/${comment.projectPartData.urlslug}/${comment.projectPart}#comments`}>
                                <Link to={`/user/${comment.userAuthorData.urlslug}/${comment.userAuthor}`}>
                                    <ImageWrapper circular avatar path={comment.userAuthorData.mediaavatarPath}>
                                        <ProgressiveImager
                                            placeholder="avatar"
                                            circular
                                            src={config.getImageUrl('avatar', comment.userAuthorData.mediaavatarPath)}
                                            floated='left'
                                            size='mini'
                                        />
                                    </ImageWrapper>
                                </Link>
                                <Card.Header>
                                    <Link to={`/user/${comment.userAuthorData.urlslug}/${comment.userAuthor}`}>
                                        {comment.userAuthorData.name}
                                    </Link>

                                    <div className="comment-type">
                                        <Label color="yellow">Story</Label>
                                    </div>

                                    <Card.Meta className="comment-user-project">

                                        {/* <Link as="div" to={`/oldtimer/${comment.projectPartData.projecturlslug}/${comment.projectPartData.project}`}>
                                            {comment.projectPartData.projecttitle}
                                        </Link> */}
                                        {comment.projectPartData.projecttitle}

                                    </Card.Meta>
                                    {comment.projectPartData.title &&
                                        <Card.Meta className="comment-user-description">

                                            {/* <Link as="div" to={`/oldtimer/${comment.projectPartData.projecturlslug}/${comment.projectPartData.urlslug}/${comment.projectPart}`}>
                                                {comment.projectPartData.title}
                                            </Link> */}
                                            {comment.projectPartData.title}
                                        </Card.Meta>
                                    }

                            </Card.Header>
                            <Divider/>
                                <Card.Description className="comment-describtion">
                                    <Truncate width={this.state.windowWidth - 60} ellipsis={<>...</>} lines={1}>{comment.teaser}</Truncate>
                                </Card.Description>
                                <Card.Meta className="comment-date">
                                    <Moment format="DD.MM.YYYY" calendar={calendarStrings} unix >{comment.date}</Moment>
                                </Card.Meta>

                                {comment.new &&
                                    <div className="new-notification new-comment">
                                        <div className="new-notification-label"/>
                                    </div>
                                }

                            </Card.Content>
                        </Card>
                        }
                    </>
                    )}

                    {isFetching && hasMore &&
                        <>
                            {this.placeholderItem()}
                        </>
                    }

                    {isFailed &&
                        <>
                            <Message negative>Beim Abrufen der Daten ist ein Fehler aufgetreten.</Message>
                            <Grid>
                                <Grid.Column width={16} textAlign="center">
                                    <Form.Button
                                        onClick={this.loadMoreForce}
                                        color="red"
                                    >
                                        Nochmals versuchen
                                    </Form.Button>
                                </Grid.Column>
                            </Grid>
                        </>
                    }

                    <InfiniteScroll
                        onLoadMore={this.loadMore}
                        loading={isFetching}
                        hasNextPage={hasMore}
                    />

                    {/*Workaround for InfiniteScroll*/}
                    &nbsp;
                </div>
            )
        }

        else {
            return (
                <Container text>
                    <Divider hidden/>
                    <Message negative>Es ist ein Fehler aufgetreten.</Message>
                </Container>
            )
        }
    }
}

class DashboardComments extends Component {

    componentDidMount = () => {
        const { dispatch } = this.props;
        dispatch(handleContainer("secondary", "Kommentare", true));
        dispatch(handleHeaderItems("dashboard"));
        dispatch(setRuntime({page: 'be-comments'}));
    }

    render() {
        return (
            <DashboardCommentsMobile />
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { globalByComponent,listByEntity, notificationById } = state;
    const { type, headerTitle, headerActiveItem } = globalByComponent[
        'Container'
    ] || {

    }

    const { list, isFetching, isFailed, hasMore, page } = listByEntity[
        'NTFCN' + "e" + config.NTFCN_COMMENT_NEW + "e" + config.NTFCN_ANSWER_NEW + "d1"
    ] || {
        list: {},
        isFetching: true,
    }

    var listitems = [];
    if (list) {
        for (let value of Object.values(list)) {
            listitems.push(notificationById[value].item);
        }
        listitems = [].concat(listitems).sort((a, b) => a.order > b.order ? 1 : -1);
    }

    return {
        type,
        headerTitle,
        headerActiveItem,
        listitems,
        isFetching,
        isFailed,
        hasMore,
        page,
    }
}

DashboardComments = connect(mapStateToProps)(withRouter(DashboardComments));
DashboardCommentsMobile = connect(mapStateToProps)(withRouter(DashboardCommentsMobile));

export default {
    DashboardComments,
    DashboardCommentsMobile,
}
