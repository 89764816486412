import React, { Component } from 'react';
import { Icon, Message } from 'semantic-ui-react';
import config from '../../config/config';
import ProgressiveImager from '../helpers/progressive-imager';
import './PreviewGallery.scss';
import '../Gallery/Gallery'
import Gallery from '../Gallery/Gallery';
import ImageWrapper from '../helpers/image-wrapper';

class SingleMedia extends Component {

    state = {
        storyGallery: null,
        storyGalleryVideo: null,
        storySlide: 0,
        videoLightboxIsOpen: false
    }

    render() {

        const { deletable,  requestDelete, image, enableLightbox, video, editable, circular } = this.props;

        return (
            <div className={`single-media ${circular ? "circular" : ""} ${editable ? "editable" : ""} ${deletable ? "deletable" : ""}`}>
                    
                {image && 
                    <div className={`image-item ${enableLightbox ? "is-lightbox" : ""}`} key={image.id}>
                        <ImageWrapper progress={image.progress} path={image.path}>
                            <ProgressiveImager alt={`Bild - ${image.id}`} src={config.getImageUrl('main', image.path)}/>
                                {deletable &&
                                    <div className="image-delete" onClick={() => requestDelete(image.id)}>
                                        <img src={process.env.PUBLIC_URL + '/images/close-cross.svg'} alt="Icon close"/>
                                    </div>
                                }
                                {editable && 
                                    <div className="image-edit">
                                        <Icon name="pencil" circular inverted color="grey" />
                                    </div>
                                }

                        </ImageWrapper>
                        {/* ERROR */}
                    </div>        
                }

                {video && 
                    <div className={`image-item video-item ${enableLightbox ? "is-lightbox" : ""} ${deletable ? "deletable" : ""}`} key={video.id}>
                        <ImageWrapper progress={video.progress} path={video.previewPath}>
                            <ProgressiveImager alt={`Vorschaubild - Video ${video.id}`} src={config.getImageUrl('main', video.previewPath)}/>
                            {deletable &&
                                <div className="image-delete" onClick={(e) => {requestDelete(video.id); e.preventDefault()}}>
                                    <img style={{ padding: "0.25rem" }} src={process.env.PUBLIC_URL + '/images/close-cross.svg'} alt="Icon close"/>
                                </div>
                            } 
                            {editable && 
                                <div className="image-edit">
                                    <Icon name="pencil" circular inverted color="grey" />
                                </div>
                            }
                        </ImageWrapper>
                        <Icon name="play circle outline" inverted/>
                    </div>
                }

                {/* ERROR */}
                {((image && image.path === "ERROR") || (video && video.path === "ERROR")) && 
                    <Message color="red">
                        Ein Fehler ist aufgetreten. Versuche es nochmal.
                    </Message>
                }

            </div>
                
        )
    }
}

export default SingleMedia;
