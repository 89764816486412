import config from '../config/config'
import { createAction } from 'redux-api-middleware';

export const CHANGE_STORY = 'CHANGE_STORY'
export const NEW_CONTENT = 'NEW_CONTENT'
export const DISCARD_CONTENT = "DISCARD_CONTENT";
export const SAVE_CONTENT = "SAVE_CONTENT";
export const API_STORY_REQUEST = 'API_STORY_REQUEST'
export const API_STORY_SUCCESS = 'API_STORY_SUCCESS'
export const API_STORY_FAILURE = 'API_STORY_FAILURE'
export const API_STORY_DELETE_SUCCESS = 'API_STORY_DELETE_SUCCESS'
export const API_STORY_LIST_REQUEST = 'API_STORY_LIST_REQUEST'
export const API_STORY_LIST_SUCCESS = 'API_STORY_LIST_SUCCESS'
export const API_STORY_LIST_FAILURE = 'API_STORY_LIST_FAILURE'
export const API_LIKE_STORY_REQUEST = "API_LIKE_STORY_REQUEST";
export const API_LIKE_STORY_SUCCESS = "API_LIKE_STORY_SUCCESS";
export const API_LIKE_STORY_FAILURE = "API_LIKE_STORY_FAILURE";
export const API_STORY_DIRECT_REQUEST = "API_STORY_DIRECT_REQUEST";
export const API_STORY_DIRECT_SUCCESS = "API_STORY_DIRECT_SUCCESS";
export const API_STORY_DIRECT_FAILURE = "API_STORY_DIRECT_FAILURE";
export const API_STORY_SORT_REQUEST = "API_STORY_SORT_REQUEST";
export const API_STORY_SORT_SUCCESS = "API_STORY_SORT_SUCCESS";
export const API_STORY_SORT_FAILURE = "API_STORY_SORT_FAILURE";


export function sortStories(project, projectparts) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/projectpart/sort`,
        method: 'POST',
        body: JSON.stringify({
            projectparts,
        }),
        types: [
            {type: API_STORY_SORT_REQUEST, meta: { project }},
            {type: API_STORY_SORT_SUCCESS, meta: { project }},
            {type: API_STORY_SORT_FAILURE, meta: { project }},
        ]
    })
}

export function directStoryUpdate(id, key, value) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/projectpart/${id}`,
        method: 'PUT',
        body: JSON.stringify({
            projectpart: {
                [key]: value,
            },
        }),
        types: [
            {type: API_STORY_DIRECT_REQUEST, meta: { id }},
            {type: API_STORY_DIRECT_SUCCESS, meta: { id }},
            {type: API_STORY_DIRECT_FAILURE, meta: { id }},
        ]
    })
}

export function likeStory(id, like, count){
    return createAction({
        endpoint: `${config.API_HOST}/v1/like`,
        method: 'PUT',
        body: JSON.stringify({
            entity: 'projectpart',
            type: 'welldone',
            id: id,
            value: like,
        }),
        types: [
            {type: API_LIKE_STORY_REQUEST, meta: { id, like, count }},
            {type: API_LIKE_STORY_SUCCESS, meta: { id, like, count }},
            {type: API_LIKE_STORY_FAILURE, meta: { id, like, count }},
        ],
    })
}

export function discardContent(storyId, contentId, content) {
    let item = content.filter(content => content.id === contentId)[0];

    delete item['input'];
    item['input'] = JSON.parse(JSON.stringify(item));

    return {
        type: DISCARD_CONTENT,
        payload: {
            storyId,
            contentId,
            item
        }
    }

}

export function saveContent(storyId, contentId, content) {
    let item = content.filter(content => content.id === contentId)[0].input;

    item['input'] = JSON.parse(JSON.stringify(item));

    return {
        type: SAVE_CONTENT,
        payload: {
            storyId,
            contentId,
            item
        }
    }

}

export function createNewContent(element, position, pid) {
    var item = {
        id: (Date.now() * -1),
        active: true,
        position: parseInt(position),
        createDate: null,
        publishDate: null,
        string: null,
        text: null,
        projectPartEntry: null,
        projectPart: pid,
        blockprefix: element,
    };
    item['input'] = JSON.parse(JSON.stringify(item));

    if (element === 'contentgallery') {
        item['media'] = [];
        item.input['media'] = [];
    }

    return {
        type: NEW_CONTENT,
        payload: {
            ...item,
        }
    }
}

export function changeStory(item, contents, keepDirty) {
    console.log(contents)
    return {
        type: CHANGE_STORY,
        meta: {
            id: item.id,
            keepDirty: !keepDirty ? true : false
        },
        payload: {
            item,
            contents,
        }
    }
}

export function updateStory(item, contents) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/projectpart/${item.id}`,
        method: 'PUT',
        body: JSON.stringify({
            projectpart: item,
            contents,
        }),
        types: [
            {type: API_STORY_REQUEST, meta: { id: item.id }},
            {type: API_STORY_SUCCESS, meta: { id: item.id }},
            {type: API_STORY_FAILURE, meta: { id: item.id }},
        ]
    })
}

export function deleteStory(id) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/projectpart/${id}`,
        method: 'DELETE',
        types: [
            {type: API_STORY_REQUEST, meta: { id }},
            {type: API_STORY_DELETE_SUCCESS, meta: { id }},
            {type: API_STORY_FAILURE, meta: { id }},
        ]
    })
}

export function fetchStoryList(project, page, size) {
    if (!page) {page = 1}
    if (!size) {size = 9999} // Project settings shouldnt have limit
    return createAction({
        endpoint: `${config.API_HOST}/v1/projectpart?project=${project}&page=${page}&size=${size}`,
        method: 'GET',
        types: [
            {type: API_STORY_LIST_REQUEST, meta: { page, project }},
            {type: API_STORY_LIST_SUCCESS, meta: { page, project }},
            {type: API_STORY_LIST_FAILURE, meta: { page, project }},
        ]
    })
}

function shouldFetchStoryList(state, project) {
    const storylist = state.listByEntity[project+'::story'];
    if (
        !storylist ||
        ((storylist.isFetching || storylist.isFailed) && Date.now() - storylist.lastFetch > config.FETCH_TIMEOUT) ||
        (!storylist.isFetching && Date.now() - (storylist.lastUpdated ? storylist.lastUpdated : 0) > config.FETCH_AGAIN)
    ) {
        return true
    }
    return false
}

export function fetchStoryListIfNeeded(project, size) {
    return (dispatch, getState) => {

        if (shouldFetchStoryList(getState(), project)) {
            return dispatch(fetchStoryList(project, false, size))
        }
    }
}

export function fetchStory(id) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/projectpart/${id}`,
        method: 'GET',
        types: [
            {type: API_STORY_REQUEST, meta: { id }},
            {type: API_STORY_SUCCESS, meta: { id }},
            {type: API_STORY_FAILURE, meta: { id }},
        ]
    })
}

function shouldFetchStory(state, id) {
    const story = state.storyById[id]
    if (
        !story ||
        ((story.isFetching || story.isFailed) && Date.now() - story.lastFetch > config.FETCH_TIMEOUT) ||
        (!story.isDirty && !story.isFetching && Date.now() - (story.lastUpdated ? story.lastUpdated : 0) > config.FETCH_AGAIN)
    ) {
        return true
    }
    return false
}

export default function fetchStoryIfNeeded(id) {
    return (dispatch, getState) => {
        if (shouldFetchStory(getState(), id)) {
            return dispatch(fetchStory(id))
        }
    }
}
