import { createAction } from 'redux-api-middleware';
import config from '../config/config'
export const FETCH_USER_REQUEST = "FETCH_USER_REQUEST";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILED = "FETCH_USER_FAILED";
export const FETCH_USER_FEEDPOST_REQUEST = "FETCH_USER_FEEDPOST_REQUEST"
export const FETCH_USER_FEEDPOST_SUCCESS = "FETCH_USER_FEEDPOST_SUCCESS"
export const FETCH_USER_FEEDPOST_FAILURE = "FETCH_USER_FEEDPOST_FAILURE"


export function fetchUser(userId) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/user/${userId}`,
        method: 'GET',
        types: [
            {type: FETCH_USER_REQUEST, meta: {id: userId}},
            {type: FETCH_USER_SUCCESS, meta: {id: userId}},
            {type: FETCH_USER_FAILED, meta: {id: userId} },
        ]
    })
}

function shouldFetchUser(state, id) {
    const user = state.userById[id]
    if (
        !user ||
        ((user.isFetching || user.isFailed) && Date.now() - user.lastFetch > config.FETCH_TIMEOUT) ||
        (!user.isFetching && Date.now() - (user.lastUpdated ? user.lastUpdated : 0) > config.FETCH_AGAIN)
    ) {
        return true
    }
    return false
}

export function fetchUserIfNeeded(id) {
    return (dispatch, getState) => {
        if (shouldFetchUser(getState(), id)) {
            return dispatch(fetchUser(id))
        }
    }
}

export function fetchUserFeedPosts(userId, size){
    if(!size) size = 9999;

    return createAction({
        endpoint: `${config.API_HOST}/v1/notification?author=${userId}&events=9&size=${size}`,
        method: 'GET',
        types: [
            {type: FETCH_USER_FEEDPOST_REQUEST, meta: {userId}},
            {type: FETCH_USER_FEEDPOST_SUCCESS, meta: {userId}},
            {type: FETCH_USER_FEEDPOST_FAILURE, meta: {userId}},
        ]
    })
}

