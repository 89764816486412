/* NOTE: The Blog use Wordpress REST API */

import { 
    GET_POST_LIST_REQUEST, 
    GET_POST_LIST_SUCCESS, 
    GET_POST_LIST_FAILED,
    GET_SINGLE_POST_REQUEST,
    GET_SINGLE_POST_SUCCESS,
    GET_SINGLE_POST_FAILED,
    GET_BLOG_TAGS_REQUEST,
    GET_BLOG_TAGS_SUCCESS,
    GET_BLOG_TAGS_FAILED,
    GET_POSTS_TOTAL_COUNT_REQUEST,
    GET_POSTS_TOTAL_COUNT_SUCCESS,
    GET_POSTS_TOTAL_COUNT_FAILED,
    GET_POST_COMMENTS_REQUEST,
    GET_POST_COMMENTS_SUCCESS,
    GET_POST_COMMENTS_FAILED
} 
from '../actions/blog'

export default function blog(state = {}, action) {

    try {

        if(action.type === GET_POST_LIST_REQUEST){
            return {
                ...state,
                post: {
                    ...state.post,
                    items: {
                        ...(state.post ? state.post.items : {})
                    },
                    isFetching: true,
                    isFailed: false
                }
            };
        }

        else if(action.type === GET_POST_LIST_SUCCESS) {

            action.payload.map((item) => {
                state = {
                    ...state,
                    post: {
                        ...state.post,
                        items: {
                            ...state.post.items,
                            [item.id]: {
                                ...state.post.items[item.id],
                                ...item
                            }
                        },
                        isFetching: false,
                        isFailed: false
                    }
                }
            })
            
        }

        else if(action.type === GET_POST_LIST_FAILED){
            return {
                ...state,
                post: {
                    ...state.post,
                    items: {
                        ...(state.post ? state.post.items : {})
                    },
                    isFetching: false,
                    isFailed: true
                }
            };
        }

        else if(action.type === GET_SINGLE_POST_REQUEST) {
            return {
                ...state,
                post: {
                    items: {
                        ...(state.post ? state.post.items : {})
                    },
                    isFetching: true,
                    isFailed: false
                }
            };
        }

        else if(action.type === GET_SINGLE_POST_SUCCESS) {

            return {
                ...state,
                post: {
                    ...state.post,
                    items: {
                        ...state.post.items,
                        [action.meta.id]: {
                            ...state.post.items[action.meta.id],
                            ...action.payload
                        }
                    },
                    isFetching: false,
                    isFailed: false
                }
            };
        }

        else if (action.type === GET_SINGLE_POST_FAILED) {
            return {
                post: {
                    ...state.post,
                    items: {
                        ...(state.post ? state.post.items : {})
                    },
                    isFetching: false,
                    isFailed: true
                }
            }
        }

        else if (action.type === GET_BLOG_TAGS_REQUEST) {
            return {
                ...state,
                tag: {
                    ...state.tag,
                    items: {},
                    isFetching: true,
                }
            };
        }

        else if (action.type === GET_BLOG_TAGS_SUCCESS) {

            action.payload.map((item) => {

                //Do not save empty tags
                item.count > 0 && (

                state = {
                    ...state,
                    tag: {
                        ...state.tag,
                        items: {
                            ...state.tag.items,
                            [item.id]: {
                                id: item.id,
                                slug: item.slug,
                                name: item.name,
                                count: item.count
                            }
                        },
                        isFetching: false,
                        isFailed: false
                    }
                }
            )})

        }

        else if (action.type === GET_BLOG_TAGS_FAILED) {
            state = {
                ...state,
                tag: {
                    ...state.tag,
                    isFetching: false,
                    isFailed: true
                }
            }
        }

        else if (action.type === GET_POSTS_TOTAL_COUNT_REQUEST) {
            state = {
                ...state,
                totalCount: {
                    ...state.totalCount,
                    isFetching: true,
                    isFailed: false
                }
            }
        }

        else if (action.type === GET_POSTS_TOTAL_COUNT_SUCCESS) {

            let count;
            let arr = [];
            action.payload.map(item => (
                item.status === "publish" && arr.push(item)
            ))
            count = arr.length;
            state = {
                ...state,
                totalCount: {
                    ...state.totalCount,
                    count,
                    isFetching: false,
                    isFailed: false
                }
            }
        }

        else if (action.type === GET_POSTS_TOTAL_COUNT_FAILED) {
            state = {
                ...state,
                totalCount: {
                    ...state.totalCount,
                    isFetching: false,
                    isFailed: true
                }
            }
        }

        else if (action.type === GET_POST_COMMENTS_REQUEST) {
            state = {
                ...state,
                comments: {
                    ...state.tag,
                    items: {
                        ...(state.comments ? state.comments.items : {}),
                    },
                    isFetching: true,
                    isFailed: false
                }
            }
        }

        else if (action.type === GET_POST_COMMENTS_SUCCESS) {

            action.payload.map((item) => {

                state = {
                    ...state,
                    comments: {
                        ...state.comments,
                        items: {
                            ...state.comments.items,
                            [item.id]: {
                                ...state.comments.items[item.id],
                                ...item
                            }
                        },
                        isFetching: false,
                        isFailed: false
                    }
                }
            })

        }

        else if (action.type === GET_POST_COMMENTS_FAILED) {
            state = {
                ...state,
                comments: {
                    ...state.comments,
                    isFetching: false,
                    isFailed: true
                }
            }
        }

        return state;

    } catch (e) {

        console.log(e);
        return {
            ...state,
            fatalError: true,
        };

    }
}