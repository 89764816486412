import React, { Component } from 'react'
import { Message, Icon, Button } from 'semantic-ui-react';
import './ErrorHandler.scss';

class ErrorHandler extends Component {
    render() {

        const { errorMessage, callbackFunction } = this.props;

        return (
            <Message negative className="error-message-wrapper">
                <div className="error-message">
                    <Icon className="error-icon" name="frown outline" size="big"/>
                    {errorMessage ? <p>{errorMessage}</p> : <p>Es ist ein Fehler aufgetreten.</p>}
                </div>
                <Button onClick={callbackFunction} color="red">
                    <Icon name="redo" />
                    Nochmal versuchen
                </Button>
            </Message>
        )
    }
}

export default ErrorHandler
