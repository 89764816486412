import React, { Component } from 'react';
import { connect } from 'react-redux'
import { footerActiveItem, handleContainer, setRuntime } from '../../../actions/global';
import ProjectFilter from '../../../components/FilterSearch/ProjectFilter';
import ListProjects from '../list/list-projects';
import  '../../Pages.scss'


class FeProjects extends Component {

    componentDidMount(){
        const { dispatch, location, page, backProject, backUser } = this.props;
        dispatch(footerActiveItem("project"));
        dispatch(handleContainer("projects", "Projekte", null, null, null, null, {title: 'Oldtimer/Projekte', description: 'Hier findest Du interessante Oldtimer-Projekte mit Storys von Fahrzeugen ab einem Alter von 25 Jahren. Hast Du auch einen Oldtimer? Dann ist Vetero genau das Richtige für Dich.', desctype: 'fixed'}));
    }

    componentWillUnmount = () => {
        const { dispatch, location, match } = this.props;

        dispatch(setRuntime({
            backProject: {page: 'fe-project-list', path: this.props.location, scroll: window.pageYOffset},
            backUser: {page: 'fe-project-list', path: this.props.location, scroll: window.pageYOffset},
        }));
    }

    render() {

        return (
            <>
                <ProjectFilter/>
                <ListProjects/>
            </>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { globalByComponent } = state
    const { page, backProject, backUser } = globalByComponent[
        'Runtimedata'
    ] || {}

    return {
        page,
        backProject,
        backUser,
    }

}

export default connect(mapStateToProps)(FeProjects);
