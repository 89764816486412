import {
    CHANGE_BILL,
    NEW_BILL,
    API_BILL_REQUEST,
    API_BILL_SUCCESS,
    API_BILL_FAILURE,
    API_BILL_DELETE_REQUEST,
    API_BILL_DELETE_SUCCESS,
    API_BILL_DELETE_FAILURE,
    API_BILL_LIST_REQUEST,
    API_BILL_LIST_SUCCESS,
    API_BILL_LIST_FAILURE,
    API_POST_BILL_REQUEST,
    API_POST_BILL_SUCCESS,
    API_POST_BILL_FAILURE,
} from '../actions/bill'
import {
    RESET_STORE,
} from '../actions/account'
import {actions} from 'ix-redux-file-upload';


export default function billsByParent(state = {}, action) {

    try {

        if (action.type === RESET_STORE) {
            return {};
        }

        if (action.meta && action.meta.project) {
            var key = 'project::'+action.meta.project;
        }

        if (action.type === CHANGE_BILL) {
            return {
                ...state,
                [key]: {
                    ...state[key],
                    isDirty: true,
                    items: {
                        ...state[key].items,
                        ...{
                            [action.payload.item.id]: {
                                ...action.payload.item,
                                isDirty: true,
                            },
                        },
                    },
                }
            };
        }
        else if (action.type === NEW_BILL) {
            return {
                ...state,
                [key]: {
                    ...state[key],
                    ...action.payload,
                }
            };
        }

        else if (action.type === API_BILL_REQUEST) {
            return {
                ...state,
                [key]: {
                    ...state[key],
                    isFetching: true,
                    lastFetch: Date.now(),
                }
            };
        }
        else if (
            action.type === API_BILL_SUCCESS ||
            action.type === API_POST_BILL_SUCCESS
        ) {
            state = {
                ...state,
                [key]: {
                    ...state[key],
                    isDirty: false,
                    isFetching: false,
                    isFailed: false,
                    items: {
                        ...state[key].items,
                        ...{
                            [action.payload.bill.id]: {
                                ...action.payload.bill,
                                isDirty: false,
                                input: JSON.parse(JSON.stringify(action.payload.bill)),
                            },
                        },
                    },
                    lastUpdated: Date.now(),
                }
            };

            if (action.type === API_POST_BILL_SUCCESS) {
                state = {
                    ...state,
                    [key]: {
                        ...state[key],
                        isPosting: false,
                        inputTitle: null,
                        inputMercant: null,
                        inputBilldate: null,
                        inputMedia: null,
                    }
                };
            }

            return state;
        }
        else if (action.type === API_BILL_FAILURE) {
            return {
                ...state,
                [key]: {
                    ...state[key],
                    isFetching: false,
                    isFailed: true,
                    lastUpdated: Date.now(),
                }
            };
        }

        else if (action.type === API_BILL_DELETE_REQUEST) {
            return {
                ...state,
                [key]: {
                    ...state[key],
                    isFailed: false,
                    isDeleting: true,
                }
            }
        }
        else if (action.type === API_BILL_DELETE_SUCCESS) {
            var items = {...state[key].items};
            delete items[action.meta.id];

            return {
                ...state,
                [key]: {
                    ...state[key],
                    isFailed: false,
                    isDeleting: false,
                    items: {
                        ...items,
                    },
                }
            };
        }
        else if (action.type === API_BILL_DELETE_FAILURE) {
            return {
                ...state,
                [key]: {
                    ...state[key],
                    isDeleting: false,
                    isFailed: true,
                }
            };
        }

        else if (action.type === actions.FILE_UPLOAD_PROGRESS) {
            var identificator = action.payload.identificator.split('.');
            // var key = identificator[0]+'::'+identificator[1];
            var key = 'project::'+identificator[1];

            if (identificator[0] === 'billsByProject' && identificator[2] === 'bill') {
                var newItems = {...state[key].items};

                for (let [k, c] of Object.entries(newItems)) {
                    if (c.id === parseInt(identificator[3])) {

                        newItems[k].input[identificator[4]] = newItems[k].input[identificator[4]].filter(item => item.id !== action.payload.fileType.uid)

                        newItems[k].input[identificator[4]].push({
                            id: null,
                            path: 'LOADING',
                            type: null,
                            description: '',
                        });
                        newItems[k]['isDirty'] = true;
                    }
                };

                return {
                    ...state,
                    [key]: {
                        ...state[key],
                        isDirty: true,
                        items: {
                            ...newItems,
                        },
                    }
                };
            }
            else if (identificator[0] === 'billsByProject') {
                var media = state[key][identificator[2]];

                if (!media) {
                    media = [];
                }

                var updated = false;
                if(media){
                    media.map((item, index) => {
                        if(item.id == action.payload.fileType.uid && !updated){
                            media[index].progress = action.payload.progress;
                            updated = true;
                        }
                    })
                }

                if(!updated) {
                    media.push({
                        id: action.payload.fileType.uid,
                        progress: action.payload.progress,
                        path: 'LOADING',
                        type: null,
                    });
                }

                state = {
                    ...state,
                    [key]: {
                        ...state[key],
                        isDirty: true,
                        [identificator[2]]: media,
                    }
                }

                return state;
            }
        }
        else if (action.type === actions.FILE_UPLOAD_COMPLETE) {
            var identificator = action.payload.identificator.split('.');
            // var key = identificator[0]+'::'+identificator[1];
            var key = 'project::'+identificator[1];

            if (identificator[0] === 'billsByProject' && identificator[2] === 'bill') {
                var newItems = {...state[key].items};
                var removedLoading = false;

                for (let [k, c] of Object.entries(newItems)) {
                    if (c.id === parseInt(identificator[3])) {
                        newItems[k].input[identificator[4]].push({
                            id: action.payload.response.id,
                            path: action.payload.response.path,
                            type: action.payload.response.type,
                            description: action.payload.file.name,
                        });

                        newItems[k].input[identificator[4]].map((mv, mk) => {
                            if (mv.path === 'LOADING' && !removedLoading) {
                                newItems[k].input[identificator[4]].splice(mk, 1);
                                removedLoading = true;
                                newItems[k]['isDirty'] = true;
                            }
                        });
                    }
                };

                return {
                    ...state,
                    [key]: {
                        ...state[key],
                        isDirty: true,
                        items: {
                            ...newItems,
                        },
                    }
                };
            }
            else if (identificator[0] === 'billsByProject') {
                var media = state[key][identificator[2]];

                if (!media) {
                    media = [];
                }

                media.push({
                    id: action.payload.response.id,
                    path: action.payload.response.path,
                    type: action.payload.response.type,
                    description: action.payload.file.name,
                });

                var removedLoading = false;
                media.map((mv, mk) => {
                    if (mv.path === 'LOADING' && !removedLoading) {
                        media.splice(mk, 1);
                        removedLoading = true;
                    }
                });

                state = {
                    ...state,
                    [key]: {
                        ...state[key],
                        isDirty: true,
                        [identificator[2]]: media,
                    }
                }

                return state;
            }
        }

        else if (action.type === API_BILL_LIST_REQUEST) {
            return {
                ...state,
                [key]: {
                    ...state[key],
                    isFetching: true,
                    lastFetch: Date.now(),
                }
            };
        }
        else if (action.type === API_BILL_LIST_SUCCESS) {
            // Set keys
            var items = {};
            if (Array.isArray(action.payload)) {
                action.payload.map(function(item) {
                    items[item.id] = {
                        ...item,
                        isDirty: false,
                        input: JSON.parse(JSON.stringify(item)),
                    };
                });
            }

            return {
                ...state,
                [key]: {
                    ...state[key],
                    isDirty: false,
                    isFetching: false,
                    isFailed: false,
                    items: {
                        ...items,
                    },
                    lastUpdated: Date.now(),
                }
            };
        }
        else if (action.type === API_BILL_LIST_FAILURE) {
            return {
                ...state,
                [key]: {
                    ...state[key],
                    isFetching: false,
                    isFailed: true,
                    lastUpdated: Date.now(),
                }
            };
        }

        else if (action.type === API_POST_BILL_REQUEST) {
            return {
                ...state,
                [key]: {
                    ...state[key],
                    isPosting: true,
                }
            };
        }
        else if (action.type === API_POST_BILL_FAILURE) {
            return {
                ...state,
                [key]: {
                    ...state[key],
                    isPosting: false,
                }
            };
        }

        return state;

    } catch (e) {

        console.log(e);
        return {
            ...state,
            fatalError: true,
        };

    }
}
