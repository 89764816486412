import {
    CHANGE_EXPENDITURE,
    NEW_EXPENDITURE,
    API_EXPENDITURE_REQUEST,
    API_EXPENDITURE_SUCCESS,
    API_EXPENDITURE_FAILURE,
    API_EXPENDITURE_DELETE_REQUEST,
    API_EXPENDITURE_DELETE_SUCCESS,
    API_EXPENDITURE_DELETE_FAILURE,
    API_EXPENDITURE_LIST_REQUEST,
    API_EXPENDITURE_LIST_SUCCESS,
    API_EXPENDITURE_LIST_FAILURE,
    API_POST_EXPENDITURE_REQUEST,
    API_POST_EXPENDITURE_SUCCESS,
    API_POST_EXPENDITURE_FAILURE,
} from '../actions/expenditure'
import {
    RESET_STORE,
} from '../actions/account'


export default function expendituresByParent(state = {}, action) {

    try{

        if (action.type === RESET_STORE) {
            return {};
        }

        if (action.meta && action.meta.project) {
            var key = 'project::'+action.meta.project;
        }

        if (action.type === CHANGE_EXPENDITURE) {
            return {
                ...state,
                [key]: {
                    ...state[key],
                    isDirty: true,
                    items: {
                        ...state[key].items,
                        ...{
                            [action.payload.item.id]: {
                                ...action.payload.item,
                                isDirty: true,
                            },
                        },
                    },
                }
            };
        }
        else if (action.type === NEW_EXPENDITURE) {
            return {
                ...state,
                [key]: {
                    ...state[key],
                    ...action.payload,
                }
            };
        }

        else if (action.type === API_EXPENDITURE_REQUEST) {
            return {
                ...state,
                [key]: {
                    ...state[key],
                    isFetching: true,
                    lastFetch: Date.now(),
                }
            };
        }
        else if (
            action.type === API_EXPENDITURE_SUCCESS ||
            action.type === API_POST_EXPENDITURE_SUCCESS
        ) {
            state = {
                ...state,
                [key]: {
                    ...state[key],
                    isDirty: false,
                    isFetching: false,
                    isFailed: false,
                    items: {
                        ...state[key].items,
                        [action.payload.projectpartentry.id]: {
                            ...action.payload.projectpartentry,
                            isDirty: false,
                            input: JSON.parse(JSON.stringify(action.payload.projectpartentry)),
                        },
                    },
                    lastUpdated: Date.now(),
                }
            };

            if (action.type === API_POST_EXPENDITURE_SUCCESS) {
                state = {
                    ...state,
                    [key]: {
                        ...state[key],
                        isPosting: false,
                        postingMessage: 'SUCCESS',
                        inputDatestart: null,
                        inputDateend: null,
                        inputWorkinghours: null,
                        inputProjectpart: null,
                    }
                };
            }

            return state;
        }
        else if (action.type === API_EXPENDITURE_FAILURE) {
            return {
                ...state,
                [key]: {
                    ...state[key],
                    isFetching: false,
                    isFailed: true,
                    lastUpdated: Date.now(),
                }
            };
        }

        else if (action.type === API_EXPENDITURE_DELETE_REQUEST) {
            return {
                ...state,
                [key]: {
                    ...state[key],
                    isFailed: false,
                    isDeleting: true,
                }
            };
        }
        else if (action.type === API_EXPENDITURE_DELETE_SUCCESS) {
            var items = {...state[key].items};
            delete items[action.meta.id];

            return {
                ...state,
                [key]: {
                    ...state[key],
                    isDeleting: false,
                    isFailed: false,
                    items: {
                        ...items,
                    },
                }
            };
        }
        else if (action.type === API_EXPENDITURE_DELETE_FAILURE) {
            return {
                ...state,
                [key]: {
                    ...state[key],
                    isDeleting: false,
                    isFailed: true,
                }
            };
        }

        else if (action.type === API_EXPENDITURE_LIST_REQUEST) {
            return {
                ...state,
                [key]: {
                    ...state[key],
                    isFetching: true,
                    lastFetch: Date.now(),
                }
            };
        }
        else if (action.type === API_EXPENDITURE_LIST_SUCCESS) {
            // Set keys
            var items = {};
            if (Array.isArray(action.payload)) {
                action.payload.map(function(item) {
                    items[item.id] = {
                        ...item,
                        isDirty: false,
                        input: JSON.parse(JSON.stringify(item)),
                    };
                });
            }

            return {
                ...state,
                [key]: {
                    ...state[key],
                    isDirty: false,
                    isFetching: false,
                    isFailed: false,
                    items: {
                        ...items,
                    },
                    lastUpdated: Date.now(),
                }
            };
        }
        else if (action.type === API_EXPENDITURE_LIST_FAILURE) {
            return {
                ...state,
                [key]: {
                    ...state[key],
                    isFetching: false,
                    isFailed: true,
                    lastUpdated: Date.now(),
                }
            };
        }

        else if (action.type === API_POST_EXPENDITURE_REQUEST) {
            return {
                ...state,
                [key]: {
                    ...state[key],
                    isPosting: true,
                    postingMessage: '',
                }
            };
        }
        else if (action.type === API_POST_EXPENDITURE_FAILURE) {
            return {
                ...state,
                [key]: {
                    ...state[key],
                    isPosting: false,
                    postingMessage: 'FAILURE',
                }
            };
        }

        return state;

    } catch (e) {

        console.log(e);
        return {
            ...state,
            fatalError: true,
        };

    }
}
