import { isRSAA, RSAA } from 'redux-api-middleware';
import { logoutAccount } from '../actions/account';


export default store => next => action => {

    if (!isRSAA(action)) {
        return next(action);
    }

    var token = store.getState().account.token;
    var defaultHeaders = {
        "Accept": "application/json",
        "Content-Type": "application/json",
    }

    // Add token
    if (token) {
        defaultHeaders['Authorization'] = 'Bearer '+token;
    }

    const finalAction = {
        ...action,
        [RSAA]: {
            ...action[RSAA],
            'headers': {
                ...defaultHeaders,
                ...action[RSAA].headers,
            }
        }
    }

    return next(finalAction)
    .then(
        function (response) {
            if(!response.payload) {
                return;
            }
            // Logout on 401
            if (response.payload.status === 401) {
                store.dispatch(logoutAccount());
            }
        }
    )
}
