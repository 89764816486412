import {
    FOOTER_SHOW,
    FOOTER_HIDE,
    CONTAINER_MAIN,
    CONTAINER_SECONDARY,
    CONTAINER_FRONTEND,
    HANDLE_CONTAINER,
    POPUP_CONTENT,
    FOOTER_ACTIVE_ITEM,
    TOGGLE_SLIDING_PANE,
    SLIDINGPANE_CONTENT,
    LOGIN_WORKFLOW,
    TOGGLE_SIDEBAR,
    INPUT_SEARCH,
    GET_ELEMENT_ID,
    TOGGLE_SLIDING_PANE_SECONDARY,
    SLIDINGPANE_SECONDARY_CONTENT,
    HANDLE_SLIDINGPANE,
    SELECTED_ELEMENT_ITEM,
    HANDLE_SECONDARY_SLIDINGPANE,
    FILE_UPLOAD,
    HANDLE_POPUP,
    HANDLE_SIDEBAR_SLIDINGPANE,
    TOGGLE_FILTER,
    GALLERY_HANDLE,
    HANDLE_LOGIN_POPUP,
    HANDLE_HEADER_MENU,
    HANDLE_COMMENTS,
    LAYOUT_TYPE,
    CONSENT,
    RUNTIME,
    SET_PREVIOUS_PATH,
    METADATA_SUCCESS,
    TOGGLE_PROJECT_LIST_VIEW,
    TOGGLE_PROFESSIONAL_LIST_VIEW,
    SET_CANNONICAL_TAG,
    PWA_INSTALL_BANNER,
    DASHBOARD_MESSAGES,
    EDIT_HEADER,
    SHOW_TOOLTIP,
    HIDE_TOOLTIP,
    GET_USER_COORDS
} from '../actions/global';
import {
    RESET_STORE,
} from '../actions/account';

import { HANDLE_INPUT_GENERIC, HANDLE_FORUM_FILTER, HANDLE_FILTER_TAGS, GET_VEHICLE_BRAND, GET_COUNTRIES, GET_VEHICLE_MODEL, HANDLE_INPUT_BRAND, HANDLE_INPUT_MODEL, HANDLE_INPUT_CATEGORY, HANDLE_INPUT_YEAR_FROM, HANDLE_INPUT_YEAR_TO, HANDLE_INPUT_COUNTRY, HANDLE_USER_INPUT, GET_COUNT, GET_RESULTS, GET_THREADS_COUNT, HANDLE_PROFESSIONAL_FILTER_TAGS } from '../actions/filter';
import { GET_AUTOSUGGESTION_BRAND, GET_AUTOSUGGESTION_MODEL, GET_AUTOSUGGESTION_VARIANT, GET_USER_INPUT, SELECTED_BRAND, SELECTED_MODEL, SELECTED_VARIANT, TOGGLE_DROPDOWN, CLEAR_SELECTED_FIELDS, API_AUTOCOMPLETE_REQUEST, API_AUTOCOMPLETE_SUCCESS, API_AUTOCOMPLETE_FAILURE, RESET_AUTOCOMPLETE } from '../actions/autosuggestion';
import { HANDLE_PROFESSIONAL_FILTER } from './../actions/filter';
import { GET_LOCATION_BY_CITY_REQUEST, GET_LOCATION_BY_CITY_SUCCESS, GET_LOCATION_BY_CITY_FAILED, GET_LOCATION_BY_COORDS_REQUEST, GET_LOCATION_BY_COORDS_FAILED, SET_USER_COORDS } from '../actions/geolocation'
import { GET_LOCATION_BY_COORDS_SUCCESS } from './../actions/geolocation';
import { API_PAYPAL_AUTH_REQUEST, API_PAYPAL_AUTH_SUCCESS, API_PAYPAL_AUTH_FAILURE } from '../actions/paypal';

export default function globalByComponent(state = {}, action) {

    try {

        if (action.type === RESET_STORE) {
            return {
                Metadata: state.Metadata,
                ConsentBanner: state.ConsentBanner,
                ['PWA Banner']: state['PWA Banner'] ? state['PWA Banner'] : null,
                DashboardMessage: state.DashboardMessage ? state.DashboardMessage : null,
                AccountAction: state.AccountAction ? state.AccountAction : null, 
                Runtimedata: {
                    ...state.Runtimedata,
                    softReload: false,
                },
            }
        }

        if(action.type === API_PAYPAL_AUTH_REQUEST){
            return {
                ...state,
                ['payPal']: {
                    ...state['payPal'],
                    isFetching: true
                }
            }
        }

        else if(action.type === API_PAYPAL_AUTH_SUCCESS) {
            return {
                ...state, 
                ['payPal']: {
                    ...state['payPal'],
                    isFetching: false,
                    data: action.payload
                }
            }
        }

        else if(action.type === API_PAYPAL_AUTH_FAILURE) {
            return {
                ...state, 
                ['payPal']: {
                    ...state['payPal'],
                    isFetching: false,
                    isFailed: true,
                    data: action.payload
                }
            }
        }

        if(action.type === GET_USER_COORDS) {
            console.log(action.payload)
            return {
                ...state,
                ['UserCoords']: {
                    ...state['UserCoords'],
                    userLat: action.payload.position ? action.payload.position.coords.latitude : null,
                    userLong: action.payload.position ? action.payload.position.coords.longitude : null,
                    status: action.payload.status ? action.payload.status : false
                } 
            }
        }

        if(action.type === GET_LOCATION_BY_CITY_REQUEST){
            return {
                ...state,
                ['ProfessionalFilter']: {
                    ...state['ProfessionalFilter'],
                    cityObject: "FETCHING",
                }
            }
        }

        else if(action.type === GET_LOCATION_BY_CITY_SUCCESS){
            return {
                ...state,
                ['ProfessionalFilter']: {
                    ...state['ProfessionalFilter'],
                    cityObject: action.payload
                }
            }
        }

        else if(action.type === GET_LOCATION_BY_CITY_FAILED){
            return {
                ...state,
                ['ProfessionalFilter']: {
                    ...state['ProfessionalFilter'],
                    cityObject: "FAILED"
                }
            }
        }

        if (action.type === METADATA_SUCCESS) {
            // Prevent unnecessary updates
            if (JSON.stringify(state['Metadata']) !== JSON.stringify(action.payload)) {
                return {
                    ...state,
                    ['Metadata']: {
                        ...state['Metadata'],
                        ...action.payload,
                    }
                }
            }
        }
        if (action.type === HANDLE_INPUT_GENERIC || action.type === RUNTIME) {
            return {
                ...state,
                [action.component]: {
                    ...state[action.component],
                    ...action.payload,
                }
            }
        }
        if (action.type === API_AUTOCOMPLETE_SUCCESS) {
            return {
                ...state,
                ['Autocomplete']: {
                    ...state['Autocomplete'],
                    [action.meta.key]: {
                        ...action.payload,
                    }
                }
            }
        }
        if (action.type === API_AUTOCOMPLETE_REQUEST || action.type === API_AUTOCOMPLETE_FAILURE || action.type === RESET_AUTOCOMPLETE) {
            return {
                ...state,
                ['Autocomplete']: {
                    ...state['Autocomplete'],
                    [action.meta.key]: {},
                }
            }
        }
        if(action.type === HANDLE_FORUM_FILTER) {
            return {
                ...state,
                [action.component]: {
                    ...state[action.component],
                    scope: action.payload.scope ? action.payload.scope : '',
                    categories: action.payload.categories ? action.payload.categories : [],
                    brand: action.payload.brand ? action.payload.brand : '',
                    model: action.payload.model ? action.payload.model : '',
                    forum: action.payload.forum ? action.payload.forum : '',
                }
            }
        }
        if(action.type === GET_THREADS_COUNT){
            return {
                ...state,
                [action.component]: {
                    ...state[action.component],
                    count: action.data.count.count
                }
            }
        }

        if(action.type === HANDLE_PROFESSIONAL_FILTER) {
            return {
                ...state,
                [action.component]: {
                    ...state[action.component],
                    type: action.payload.type ? action.payload.type : '',
                    scope: action.payload.scope ? action.payload.scope : '',
                    zip: action.payload.zip ? action.payload.zip : '',
                    city: action.payload.city ? action.payload.city : '',
                    coords: action.payload.coords ? action.payload.coords : '',
                    categories: action.payload.categories ? action.payload.categories : [],
                    radius: action.payload.radius ? action.payload.radius : '',
                    maxDistance: action.payload.maxDistance ? action.payload.maxDistance : '',
                    minDistance: action.payload.minDistance ? action.payload.minDistance : '',
                    currentCity: action.payload.currentCity ? action.payload.currentCity : ''
                }
            }
        }

        if(action.type === GET_LOCATION_BY_COORDS_REQUEST){
            return {
                ...state,
                ['ProfessionalFilter']: {
                    ...state['ProfessionalFilter'],
                    city: "FETCHING",
                    coords: "FETCHING"
                }
            }
        }

        if(action.type === GET_LOCATION_BY_COORDS_SUCCESS){
            return {
                ...state,
                ['ProfessionalFilter']: {
                    ...state['ProfessionalFilter'],
                    city: action.payload.address ? (action.payload.address.city ? action.payload.address.city + ", " + action.payload.address.postcode : action.payload.address.town + ", " + action.payload.address.postcode) : action.payload.display_name,
                    coords: [parseFloat(action.payload.lat), parseFloat(action.payload.lon)],
                }
            }
        }

        if(action.type === GET_LOCATION_BY_COORDS_FAILED){
            return {
                ...state,
                ['ProfessionalFilter']: {
                    ...state['ProfessionalFilter'],
                    city: "FAILED",
                    cords: "FAILED"
                }
            }
        }

        if(action.type === PWA_INSTALL_BANNER){
            return {
                ...state,
                [action.component]: {
                    ...state[action.component],
                    ...action.payload
                }
            }
        }

        if(action.type === DASHBOARD_MESSAGES){
            return {
                ...state,
                [action.component]: {
                    ...state[action.component],
                    ...action.payload,
                }
            }
        }

        if(action.type === SHOW_TOOLTIP){
            
            let name = state[action.component] ? state[action.component][action.payload.parent].name : [];
            if(name.includes(action.payload.tooltip)){
                name = name.filter(item => item !== action.payload.tooltip);
            }
            else {
                name.push(action.payload.tooltip)
            }

            return {
                ...state,
                [action.component]: {
                    ...state[action.component],
                    [action.payload.parent]: {
                        ...state[action.component] ? state[action.component][action.payload.parent] : {},
                        name
                    }
                }
            }
        }

        // TODO: Use payload instead of data and move to previous switch
        switch (action.type) {
            case FOOTER_SHOW:
            case FOOTER_HIDE:
            case FOOTER_ACTIVE_ITEM:
            case CONTAINER_MAIN:
            case CONTAINER_SECONDARY:
            case CONTAINER_FRONTEND:
            case TOGGLE_SLIDING_PANE:
            case TOGGLE_SLIDING_PANE_SECONDARY:
            case SLIDINGPANE_CONTENT:
            case SLIDINGPANE_SECONDARY_CONTENT:
            case HANDLE_SLIDINGPANE:
            case TOGGLE_SIDEBAR:
            case HANDLE_POPUP:
            case POPUP_CONTENT:
            case HANDLE_FILTER_TAGS:
            case HANDLE_PROFESSIONAL_FILTER_TAGS:
            case INPUT_SEARCH:   
            case LOGIN_WORKFLOW:
            case GET_ELEMENT_ID:
            case SELECTED_ELEMENT_ITEM:
            case HANDLE_SECONDARY_SLIDINGPANE:
            case FILE_UPLOAD:
            case HANDLE_SIDEBAR_SLIDINGPANE:
            case TOGGLE_FILTER:
            case SET_CANNONICAL_TAG:    
            case GALLERY_HANDLE:
            case HANDLE_CONTAINER:
            case HANDLE_LOGIN_POPUP:
            case HANDLE_HEADER_MENU:
            case HANDLE_COMMENTS:
            case GET_VEHICLE_BRAND:
            case GET_COUNTRIES:
            case GET_VEHICLE_MODEL:
            case GET_COUNT:
            case GET_AUTOSUGGESTION_BRAND:
            case GET_AUTOSUGGESTION_MODEL:
            case GET_AUTOSUGGESTION_VARIANT:
            case GET_USER_INPUT:
            case SELECTED_BRAND:
            case SELECTED_MODEL:
            case SELECTED_VARIANT:
            case CLEAR_SELECTED_FIELDS:
            case TOGGLE_DROPDOWN:
            case HANDLE_INPUT_BRAND:
            case HANDLE_INPUT_CATEGORY:
            case HANDLE_INPUT_MODEL:
            case HANDLE_INPUT_YEAR_FROM:
            case HANDLE_INPUT_YEAR_TO:
            case HANDLE_INPUT_COUNTRY:
            case GET_RESULTS:
            case LAYOUT_TYPE:
            case EDIT_HEADER:
            case CONSENT:
            case SET_PREVIOUS_PATH:
            case TOGGLE_PROJECT_LIST_VIEW:
            case TOGGLE_PROFESSIONAL_LIST_VIEW:   
                return Object.assign({}, state, {
                    [action.component]: Object.assign({}, state[action.component], action.data)
                })
            default:
                return state
        }

    } catch (e) {

        console.log(e);
        return {
            ...state,
            fatalError: true,
        };

    }
}
