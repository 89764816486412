import { FileUpload } from 'ix-redux-file-upload';
import * as moment from "moment";
import 'moment-timezone';
import 'moment/locale/de';
import Swipeout from 'rc-swipeout';
import 'rc-swipeout/assets/index.css';
import React from 'react';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import Select from 'react-select';
import {
    Button, Container, Divider, Dropdown, Form, Grid, Header, Icon, Image, Message, Placeholder, Responsive, Segment
} from 'semantic-ui-react';
import { handleContainer, setRuntime } from '../../../actions/global';
import fetchProjectIfNeeded from '../../../actions/project';
import fetchTodoListIfNeeded, { changeTodo, deleteTodo, discardTodo, newTodo, postTodo, updateTodo } from '../../../actions/todo';
import ImageWrapper from '../../../components/helpers/image-wrapper';
import ProgressiveImager from '../../../components/helpers/progressive-imager';
import config from '../../../config/config';
import menuDotsIcon_2 from '../../../images/icons/footer-menu-dots_2.svg';
import trashIcon from '../../../images/icons/icon-swipe-trash.svg';
import CustomPopup from '../../../components/CustomPopup/CustomPopup';
import CustomSlidingPane from '../../../components/CustomSlidingPane/CustomSlidingPane';
import Gallery from '../../../components/Gallery/Gallery';
import ProjectNav from '../../../components/layout/ProjectNav';
import PlusButton from '../../../components/PlusButton/PlusButton';
import BeParent from './be-parent';
import './Backend.scss';
import PreviewGallery from './../../../components/PreviewGallery/PreviewGallery';



class Todo extends BeParent {

    inputFields = ['title', 'date', 'description', 'projectPart', 'media'];

    constructor(props) {
        super(props)

        this.handleDate = this.handleDate.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.handleInputSelect = this.handleInputSelect.bind(this);
        this.submitValidate = this.submitValidate.bind(this);
        this.submit = this.submit.bind(this);
        this.discard = this.discard.bind(this);
        this.showButton = this.showButton.bind(this);

        this.state = {
            galleryOpen: false,
            currentSlide: 0,
            confirmModal: {
                isOpen: false
            },
            todoItem: {
                isOpen: false,
                title: "",
                content: "",
                itemId: null
            },
            popup: {
                isOpen: false
            },
            formValidation: {
                check: true,
                checkMsg: '',
                validationTitle: {
                    error: false,
                    message: ''
                },
            },
            closeAfterSave: false,
            windowWidth: 0,
        }
    }

    componentDidMount() {
        this.setDimensions();
        window.addEventListener('resize', this.setDimensions);

        const { dispatch, match, item } = this.props;

        dispatch(fetchProjectIfNeeded(match.params.id));
        dispatch(fetchTodoListIfNeeded(match.params.id));
        dispatch(handleContainer("secondary", item.title, false, "dashboard"));
        dispatch(setRuntime({page: 'be-todos'}));

    }

    handleDate = (event, {name, value}) => {
        const { dispatch, match} = this.props;
        name = name.split('.');
        var field = name[1];

        dispatch(newTodo(match.params.id, field, moment(value).format('X')));

    }

    handleInput(e, element) {
        const { dispatch, match } = this.props;
        var name = element.name.split('.');
        var field = name[1];

        dispatch(newTodo(match.params.id, field, element.value));
    }

    handleInputSelect(opt, element) {
        const { dispatch, match } = this.props;
        var name = element.name.split('.');
        var field = name[1];

        dispatch(newTodo(match.params.id, field, opt.value));
    }

    handleDeleteMedia = (id) => {
        const { dispatch, todos, item, inputMedia } = this.props;
        const { todoItem } = this.state;

        let updatedArray = [];
        if(todoItem.content === 'new-todo'){
            inputMedia.filter(todoMedia => todoMedia.id !== id).map(item => updatedArray.push(item));
            dispatch(newTodo(item.id, 'inputMedia', updatedArray));
        }
        else if(todoItem.content === 'edit-todo'){
            todos.filter(todo => todo.id === todoItem.itemId).map(item => {
                item.input.media.filter(todoMedia => todoMedia.id !== id).map(item => updatedArray.push(item))
                item.input.media = updatedArray;

                dispatch(changeTodo(item));
            })
        }

    }

    submitValidate() {

        const { inputTitle, todos } = this.props;
        const { formValidation, todoItem } = this.state;

        // New
        if (todoItem.content === "new-todo") {
            this.setState({
                formValidation: {
                    ...formValidation,
                    validationTitle: config.validateTextField(inputTitle, true),
                }
            }, this.submit)
        }
        // Edit
        else if (todoItem.itemId) {
            var todo = todos.filter((item) => item.id === todoItem.itemId)[0];
            this.setState({
                formValidation: {
                    ...formValidation,
                    validationTitle: config.validateTextField(todo.input.title, true),
                }
            }, this.submit)
        }
    }

    submit() {

        const { dispatch, match, inputTitle, inputDate, inputDescription, inputProjectpart, inputMedia, todos } = this.props;
        const { formValidation, todoItem } = this.state;

        if (
            !formValidation.validationTitle.error
        ) {
            this.setState({
                formValidation: {
                    ...formValidation,
                    check: true,
                    checkMsg: "",
                    validationTitle: {
                        error: false
                    },
                }
            });

            if (todoItem.content === "new-todo") {
                dispatch(postTodo(match.params.id, inputTitle, inputDate, inputDescription, inputProjectpart, inputMedia));
            }
            else {
                var todo = todos.filter((item) => item.id === todoItem.itemId)[0];
                dispatch(updateTodo(todo.input));
                this.setState({closeAfterSave: true});
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { isPosting, isFetching, isDeleting, postingMessage } = this.props;
        const { closeAfterSave } = this.state;

        if (prevProps.isPosting !== isPosting && !isPosting && postingMessage === 'SUCCESS') {
            this.handleTodoItem(false);
        }

        if (closeAfterSave && prevProps.isFetching && !isFetching) {
            this.handleTodoItem(false);
            this.setState({closeAfterSave: false});
        }

        if (prevProps.isDeleting && !isDeleting) {
            this.setState({popup: {isOpen: false}});
        }
    }

    handleTodoItem = (isOpen, title, content, itemId) => {
        this.setState({
            todoItem: {
                isOpen,
                title,
                content,
                itemId,
            }
        })
    }

    handleTodoContent = () => {
        const { todoItem } = this.state;

        switch(todoItem.content){
            case "new-todo":
                return this.todo(false)
            case "edit-todo":
                return this.todo(true, todoItem.itemId)
            default:
                return null
        }
    }

    discard() {
        const { dispatch, item } = this.props;
        const { todoItem } = this.state;

        // Discard changes
        if (todoItem.content === "new-todo") {
            dispatch(newTodo(item.id, 'inputTitle', null));
            dispatch(newTodo(item.id, 'inputDate', null));
            dispatch(newTodo(item.id, 'inputDescription', null));
            dispatch(newTodo(item.id, 'inputProjectpart', null));
            dispatch(newTodo(item.id, 'inputMedia', []));
            dispatch(newTodo(item.id, 'postingMessage', ''));
        }
        else {
            dispatch(discardTodo((todoItem.itemId ? todoItem.itemId : null), item.id));
        }

        // Reset validation
        this.setState({
            formValidation: {
                ...this.state.formValidation,
                check: true,
                checkMsg: "",
                validationTitle: {
                    error: false
                },
            }
        });

        // Close popup/pane
        this.handleTodoItem(false);
        this.setState({
            confirmModal: {
                isOpen: false
            },
        });
    }

    editTodoClose = () => {
        const { inputTitle, inputDate, inputDescription, inputProjectpart, inputMedia, todos } = this.props;
        const { todoItem } = this.state;

        if (todoItem.content === "new-todo") {
            if (inputTitle || inputDate || inputDescription || inputProjectpart || inputMedia) {
                this.setState({
                    confirmModal: {
                        isOpen: true
                    },
                })
            }
            else {
                this.handleTodoItem(false);
            }
        }

        if (todoItem.itemId) {
            if (config.isChanged(todos.filter((item) => item.id === todoItem.itemId)[0], this.inputFields)) {
                this.setState({
                    confirmModal: {
                        isOpen: true
                    },
                })
            }
            else {
                this.handleTodoItem(false);
            }
        }
    }

    showButton() {
        const { inputTitle, inputDate, inputDescription, inputProjectpart, inputMedia, todos} = this.props;
        const { todoItem } = this.state;

        if (todoItem.content === "new-todo" && (inputTitle || inputDate || inputDescription || inputProjectpart || inputMedia)) {
            return true;
        }

        if (todoItem.itemId && (config.isChanged(todos.filter((item) => item.id === todoItem.itemId)[0], this.inputFields))) {
            return true;
        }

        return false;
    }

    render(){
         const { dispatch, match, isFetching, isFailed, todos } = this.props;
         const { formValidation } = this.state;

         if ((!todos || !todos.length) && isFetching) {
             return (<>

                <ProjectNav active="Aufgaben"  project={{id: match.params.id}}/>
                <Container style={{margin: "15px 0"}}>
                    <Grid>
                        <Grid.Column floated="left" width={5}>
                            <Header as="h2" className="text-placeholder">Aufgaben</Header>
                        </Grid.Column>
                        <Grid.Column floated="right" width={7} textAlign="right">
                            <PlusButton
                                title="Neue Aufgabe"
                                color="red"
                                disabled={true}
                            />
                        </Grid.Column>
                    </Grid>
                    <Message color="teal" className="text-placeholder">
                        <Placeholder>
                            <Placeholder.Line length='full' />
                            <Placeholder.Line length='short' />
                        </Placeholder>
                    </Message>
                 </Container>
                    <Segment className="part-cost-placeholder">
                        <Placeholder>
                            <Placeholder.Line length='full' />
                            <Placeholder.Line length='very long' />
                            <Placeholder.Line length='short' />
                        </Placeholder>
                    </Segment>
                    <Segment className="part-cost-placeholder">
                        <Placeholder>
                            <Placeholder.Line length='full' />
                            <Placeholder.Line length='very long' />
                            <Placeholder.Line length='short' />
                        </Placeholder>
                    </Segment>
                    <Segment className="part-cost-placeholder">
                        <Placeholder>
                            <Placeholder.Line length='full' />
                            <Placeholder.Line length='very long' />
                            <Placeholder.Line length='short' />
                        </Placeholder>
                    </Segment>
                </>);
         }
         if (isFailed) {
             return (
                 <Container>
                     <Divider hidden/>
                     <Message color="red">
                         Es ist ein Fehler beim Abrufen der Daten aufgetreten.
                     </Message>
                 </Container>
             );
         }

         return(
             <div>
                 {this.confirmModal(this.discard)}

                 <ProjectNav active="Aufgaben" project={{id: this.props.match.params.id}}/>
                 <Container style={{margin: "15px 0"}}>
                    <Grid>
                        <Grid.Column floated="left" width={5}>
                            <Header as="h2">Aufgaben</Header>
                        </Grid.Column>
                        <Grid.Column floated="right" width={7} textAlign="right">
                            <PlusButton
                                title="Neue Aufgabe"
                                color="red"
                                onClick={() => this.handleTodoItem(true, "Aufgabe anlegen", "new-todo")}
                            />
                        </Grid.Column>
                    </Grid>
                    <Message color="teal">
                        Hier Kannst du dir Aufgaben zum Projekt notieren, optional mit einem Fertigstellungsdatum versehen und erledigte Aufgaben abhaken.
                    </Message>
                 </Container>
                 <Divider hidden/>
                 {(!todos || !todos.length) &&
                     <Container>
                         <Divider hidden/>
                         <Message color="teal">
                             Für dieses Projekt wurden noch keine Aufgaben angelegt.
                         </Message>
                     </Container>
                 }
                 {todos &&
                     <>
                         {todos.map(item =>(
                             <div style={{marginBottom: "1rem"}}>
                                <Swipeout
                                    autoClose
                                    right={[
                                        {
                                            text: <div
                                                    onTouchEnd={(e) => {
                                                        dispatch(updateTodo({
                                                            ...item,
                                                            done: true,
                                                            donedate: Math.round((new Date()).getTime() / 1000),
                                                        }));
                                                    }}
                                                    style={{ textAlign: "center" }}
                                                    className="swipeout-img-container"
                                                  >
                                                      <div className="swipeout-img">
                                                            <Icon name="check circle outline" size='large'/>
                                                            {item.description || item.media.length > 0 ? <span>Erledigt</span> : ''}
                                                      </div>
                                            </div>,
                                            style: {
                                                backgroundColor: "#00D83C",
                                                color: "white",
                                                textAlign: "center",
                                                fontWeight: "bold",
                                                width: !item.done ? "92px" : "0px",
                                                height: "100%",

                                            }
                                        },
                                        {
                                            text: <div
                                                    className="swipeout-img-container"
                                                    onTouchEnd={() => this.setState({popup: {isOpen: true, deleteid: item.id}})}
                                                   >
                                                       <div className="swipeout-img">
                                                            <Image src={trashIcon}/>
                                                            {item.media.length > 0 ? <span>Löschen</span> : ''}
                                                       </div>
                                                  </div>,
                                            style: {
                                                backgroundColor: "#E7344C",
                                                color: "white",
                                                textAlign: "center",
                                                fontWeight: "bold",
                                                width: "92px",
                                                height: "100%",
                                                cursor: "pointer"
                                            },

                                        }
                                    ]}
                                >
                                    <Segment onClick={() => this.handleTodoItem(true, "Aufgabe bearbeiten", "edit-todo", item.id)} className="todo-item editable-list-item">
                                        <Grid columns={3}>
                                            <Grid.Column width="12">
                                                {item.done && <Icon name="check circle outline" color="green" size="large" style={{verticalAlign: 'baseline'}} />}
                                                <span className="todo-title">{item.title}</span>
                                                {item.projectPart && this.props.item.projectpartsData.filter(pp => pp.id === item.projectPart).map(pp => (
                                                    <span className="sub-title">{pp.title}</span>
                                                ))}
                                            </Grid.Column>
                                            <Grid.Column mobile={4} tablet={4} computer={2} textAlign="right">
                                                {item.date > 0 &&
                                                    <span className={"todo-date" + (item.done ? ' date-done' : ((new Date(item.date*1000)).getTime() <= (new Date()).getTime()) ? ' date-urgent' : '')}>
                                                        <Moment unix format="DD.MM.YY">{item.date}</Moment>
                                                    </span>
                                                }
                                                {(!item.date || item.date === 0) &&
                                                    <span className={"todo-date" + (item.done ? ' date-done' : ' date-none')}>
                                                        <Moment unix format="DD.MM.YY">{item.donedate > 0 ? item.donedate : item.createDate}</Moment>
                                                    </span>
                                                }
                                            </Grid.Column>
                                            <Grid.Column
                                                className="elements-dots-menu"
                                                textAlign="right"
                                                only="computer"
                                                width={2}
                                                style={{
                                                    lineHeight: 0,
                                                    marginTop: "-0.25rem"
                                                }}
                                            >
                                                <div className="elements-options-menu">
                                                    <Dropdown icon={<Image className="elements-options-menu-dots" src={menuDotsIcon_2}/>}>
                                                        <Dropdown.Menu style={{left: 'auto', right: 0}}>
                                                            <Dropdown.Item className="delete-story" onClick={() => this.setState({popup: {isOpen: true, deleteid: item.id}})}>
                                                                <Image className="elements-options-menu-icon" src={trashIcon}/>
                                                                <span>Löschen</span>
                                                            </Dropdown.Item>
                                                            {!item.done &&

                                                                <Dropdown.Item className="purchase-part" onClick={() => {
                                                                    dispatch(updateTodo({
                                                                        ...item,
                                                                        done: true,
                                                                        donedate: Math.round((new Date()).getTime() / 1000),
                                                                    }));
                                                                }}>
                                                                    <Icon name="check circle outline" size='large'/>
                                                                    <span>Erledigt</span>
                                                                </Dropdown.Item>

                                                            }
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </Grid.Column>
                                        </Grid>
                                        {item.media && item.media.length > 0 &&
                                            <>
                                                <Divider hidden style={{margin: '0.5rem 0'}} />
                                                {this.mediaPreview(item.media)}
                                            </>
                                        }
                                    </Segment>
                                </Swipeout>
                             </div>
                         ))}
                     </>
                 }

                {/* Handle content - mobile sliding pane */}

                <Responsive
                    as={CustomSlidingPane}
                    {...Responsive.onlyMobile}
                    isOpen={this.state.todoItem.isOpen}
                    title={this.state.todoItem.title}
                    onRequestClose={this.editTodoClose}
                    saveButtonCallback={this.submitValidate}
                    saveButton={this.showButton()}
                    isLoading={this.props.isPosting || this.props.isFetching}
                >
                    {this.handleTodoContent()}
                </Responsive>

                {/* Handle content - desktop popup */}

                <Responsive
                    as={CustomPopup}
                    minWidth={Responsive.onlyTablet.minWidth}
                    popupOpen={this.state.todoItem.isOpen}
                    popupHeader={this.state.todoItem.title}
                    onClose={this.editTodoClose}
                    saveButtonCallback={this.submitValidate}
                    saveButton={this.showButton()}
                    isLoading={this.props.isPosting || this.props.isFetching}
                    onDocumentClick={
                        formValidation.validationTitle.error ||
                        !formValidation.check ?
                        false : true
                    }
                    showCloseIcon
                    className="larger-popup"
                >
                    {this.handleTodoContent()}
                </Responsive>

                {/* Delete item confirm popup */}

                <CustomPopup
                    popupOpen={this.state.popup.isOpen}
                    popupHeader="Aufgabe löschen"
                    onClose={() => this.setState({popup: {isOpen: false}})}
                    showCloseIcon
                >
                    {this.confirmDelete()}
                </CustomPopup>

             </div>
         )
     }

     confirmDelete(){
         const { isDeleting } = this.props;
         return (
            <Container>
                <Divider hidden/>
                    <div style={{textAlign: "center", margin: "1rem 0"}}>
                        <Icon name="warning sign" color="red" size="huge"/>
                    </div>
                    <div style={{textAlign: "center"}}>Willst du die Aufgabe wirklich löschen?</div>
                    <Divider hidden/>
                    <div className="buttons-center">
                        <Button color="red" onClick={isDeleting ? null : () => {
                            this.setState({popup: {isOpen: false}});
                        }}>
                            Zurück
                        </Button>
                        <Button
                            loading={isDeleting}
                            basic
                            color="red"
                            onClick={isDeleting ? null : () => {
                                this.props.dispatch(deleteTodo(this.props.match.params.id, this.state.popup.deleteid));
                            }
                        }>
                            Löschen
                        </Button>
                    </div>
                    <Divider hidden/>
            </Container>
         )
     }

     todo(edit, id) {
         const { item: project, account, todos, match, inputTitle, inputDate, inputDescription, inputProjectpart, inputMedia, postingMessage } = this.props;
         const { formValidation, galleryOpen, currentSlide } = this.state;

         if (edit) {
             return (
                 <div>
                     {todos.filter(item => item.id === id).map(item => (
                         <div>
                             <Container>
                                 <Form>
                                     <Form.Input
                                         label="Bezeichnung"
                                         value={item.input.title}
                                         name={"todo." + item.id + ".title"}
                                         onChange={this.handleChangeInput}
                                         error={formValidation.validationTitle.error}
                                     />

                                     {formValidation.validationTitle.error &&
                                         <Message color="red">
                                             {formValidation.validationTitle.message}
                                         </Message>
                                     }

                                     <Form.Group widths='equal'>
                                        <Form.Field>
                                            <label>Fertigstellungsdatum (optional)</label>
                                            <Form.Input
                                                type='date'
                                                name={"todo." + item.id + ".date"}
                                                value={item.input.date ? moment.unix(item.input.date).format("YYYY-MM-DD") : null}
                                                onChange={this.handleChangeDate}
                                            />
                                        </Form.Field>

                                        <Form.Field>
                                         <label>Story zuweisen (optional)</label>
                                         <Select menuPlacement="auto"
                                             options={config.buildChoice(project.projectpartsData, 'title')}
                                             name={"todo."+item.id+".projectPart"}
                                             classNamePrefix="custom-select"
                                             className="custom-select-wrapper"
                                             onChange={this.handleChangeSelect}
                                             value={config.getDefault(config.buildChoice(project.projectpartsData, 'title'), item.input.projectPart)}
                                         />
                                        </Form.Field>
                                     </Form.Group>

                                     <Form.Input
                                         label="Beschreibung (optional)"
                                         value={item.input.description}
                                         name={"todo." + item.id + ".description"}
                                         onChange={this.handleChangeInput}
                                     />

                                 </Form>
                             </Container>
                             {/* PDFs/Images */}
                             <Divider hidden />
                             {/* Images */}
                             <FileUpload
                                 headers={{ 'Authorization': 'Bearer ' + account.token }}
                                 allowedFileTypes={['jpg', 'jpeg']}
                                 dropzoneId={"todosByProject." + match.params.id + ".todo." + item.id + ".media"}
                                 multiple={true}
                                 url={config.API_HOST + config.API_UPLOAD}
                             >
                                 <div style={{ marginTop: "2rem", textAlign: "center" }}>
                                     <Button color="red">Bilder hinzufügen</Button>
                                 </div>
                             </FileUpload>
                             <Divider hidden />
                             {item.input.media &&

                                <PreviewGallery 
                                    deletable
                                    imagesPerRow={3}
                                    imagesOnly={item.input.media}
                                    requestDelete={this.handleDeleteMedia}
                                />

                             }

                                {/* Include lightbox */}
                                <Responsive {...Responsive.onlyMobile}>
                                    <Gallery
                                        galleryOpen={galleryOpen === item.id}
                                        slide={currentSlide}
                                        onClose={() => this.setState({ galleryOpen: false })}
                                        images={item.input.media.map(img =>
                                            config.getImageUrl('zoom', img.path)
                                        )}
                                        onMovePrevRequest={() =>
                                            this.setState({
                                                currentSlide: (currentSlide + item.input.media.length - 1) % item.input.media.length,
                                            })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                currentSlide: (currentSlide + 1) % item.input.media.length,
                                            })
                                        }
                                    />
                                </Responsive>

                                <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                                    <Gallery
                                        galleryOpen={galleryOpen === item.id}
                                        slide={currentSlide}
                                        onClose={() => this.setState({ galleryOpen: false })}
                                        images={item.input.media.map(img =>
                                            config.getImageUrl('zoomDesktop', img.path)
                                        )}
                                        onMovePrevRequest={() =>
                                            this.setState({
                                                currentSlide: (currentSlide + item.input.media.length - 1) % item.input.media.length,
                                            })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                currentSlide: (currentSlide + 1) % item.input.media.length,
                                            })
                                        }
                                    />
                                </Responsive>

                             <Divider hidden />
                         </div>
                     ))}

                 </div>
             )
         }
         else {
             return (
                 <>
                 <Container>
                     {postingMessage === 'FAILURE' &&
                         <Message negative>Beim Senden ist ein Fehler aufgetreten.</Message>
                     }

                     <Form>
                         <Form.Input
                             label="Bezeichnung"
                             required
                             value={inputTitle}
                             name={"todo.inputTitle"}
                             error={formValidation.validationTitle.error}
                             onChange={this.handleInput}
                         />

                         {formValidation.validationTitle.error &&
                             <Message color="red">
                                 {formValidation.validationTitle.message}
                             </Message>
                         }

                         <Form.Group widths='equal'>
                            <Form.Field>
                                <label>Fertigstellungsdatum (optional)</label>
                                <Form.Input
                                    type='date'
                                    name={"todo.inputDate"}
                                    value={inputDate ? moment.unix(inputDate).format("YYYY-MM-DD") : null}
                                    onChange={this.handleDate}
                                />
                            </Form.Field>

                            <Form.Field>
                             <label>Story zuweisen (optional)</label>
                             <Select menuPlacement="auto"
                                 options={config.buildChoice(project.projectpartsData, 'title')}
                                 name="todo.inputProjectpart"
                                 onChange={this.handleInputSelect}
                                 classNamePrefix="custom-select"
                                 className="custom-select-wrapper"
                                 value={config.getDefault(config.buildChoice(project.projectpartsData, 'title'), inputProjectpart)}
                             />
                            </Form.Field>
                         </Form.Group>

                         <Form.Input
                             label="Beschreibung (optional)"
                             value={todos.inputDesc}
                             name={"todo.inputDesc"}
                             onChange={this.handleInput}
                         />

                     </Form>
                     </Container>
                     <Divider hidden />
                     {/* Images */}
                     <FileUpload
                         headers={{ 'Authorization': 'Bearer ' + account.token }}
                         allowedFileTypes={['jpg', 'jpeg']}
                         dropzoneId={"todosByProject." + match.params.id + ".inputMedia"}
                         multiple={true}
                         url={config.API_HOST + config.API_UPLOAD}
                     >
                         <div style={{ marginTop: "2rem", textAlign: "center" }}>
                             <Button color="red">Bilder hinzufügen</Button>
                         </div>
                     </FileUpload>
                     <Divider hidden />


                         {inputMedia && 

                            <PreviewGallery 
                                deletable
                                imagesPerRow={3}
                                imagesOnly={inputMedia}
                                requestDelete={this.handleDeleteMedia}
                            />

                         }

                     <Divider hidden />
            </> )
         }
     }
 }

function mapStateToProps(state, ownProps) {
    const { globalByComponent, projectById, todosByParent, storyById, account } = state;
    // var collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });

    const { item, isFetching, isFailed, isDeleted } = projectById[
        ownProps.match.params.id
    ] || {
        item: {},
        isFetching: true,
        isFailed: false,
        isDeleted: false,
    }

    const { items, isFetching: isFetching2, isFailed: isFailed2, isPosting, postingMessage, inputTitle, inputDate, inputDescription, inputProjectpart, inputMedia, isDeleting } = todosByParent[
        'project::'+ownProps.match.params.id
    ] || {
        items: {},
        isFetching: true,
        isFailed: false,
        isDeleting: false,
        inputMedia: []
    }
    var todos = [];
    if (items) {
        todos = [].concat(Object.values(items)).sort((a, b) => {
            if (a.done && !b.done) { return 1; }
            if (!a.done && b.done) { return -1; }
            var adate = (a.date > 0 ? a.date : (a.donedate > 0 ? a.donedate : a.createDate));
            var bdate = (b.date > 0 ? b.date : (b.donedate > 0 ? b.donedate : b.createDate));
            return (adate < bdate ? 1 : -1);
        });
    }

    return {
        account,
        todos,
        item,
        isFetching: (isFetching || isFetching2),
        isFailed: (isFailed || isFailed2),
        isDeleted,
        isDeleting,
        isPosting,
        postingMessage,
        inputTitle,
        inputDate,
        inputDescription,
        inputProjectpart,
        inputMedia,
        isDeleting,
    }
}

 export default connect(mapStateToProps)(Todo);
