import React from 'react'
import { connect } from 'react-redux';
import { Container, Form, Message, Button, Image, Divider } from 'semantic-ui-react';
import { changeProfessional} from '../../../../actions/professional';
import BeParent from '../../../project/backend/be-parent';
import SingleMedia from '../../../../components/PreviewGallery/SingleMedia';
import { FileUpload } from 'ix-redux-file-upload';
import config from '../../../../config/config';
import PreviewGallery from '../../../../components/PreviewGallery/PreviewGallery';
import cameraIconRed from '../../../../images/icons/frontend/icon-camera-red.svg';
import arrayMove from 'array-move';
import SortableGallery from './../../../../components/PreviewGallery/SortableGallery';
import { match } from 'assert';

class Media extends BeParent {

    state = {
        tagModelIsOpen: false,
        newOrderList: []
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);
    }

    handleDeleteImage = (imgPosition) => {
        const { dispatch, professional, id } = this.props;
        let updatedArray = professional.input.media.filter(item => item.position !== imgPosition);
        
        professional.input['media'] = updatedArray;
        dispatch(changeProfessional(id, professional.input));
    }

    handleDeleteVideo = () => {
        const { dispatch, professional, id } = this.props;
        
        professional.input['mediavideo'] = null;
        professional.input['mediavideoPath'] = null;
        professional.input['mediavideoPreviewPath'] = null;
        dispatch(changeProfessional(id, professional.input));
    }

    // onSortEnd = ({oldIndex, newIndex}) => {
    //     const { storyId, sortedMedia, content } = this.props;

    //     this.setState({
    //         newOrderList: arrayMove(sortedMedia, oldIndex, newIndex)
    //     }, this.onSortEnd2)
    // };

    onSortEnd = ({ oldIndex, newIndex }) => {
        const { professional, dispatch, id } = this.props;

        let updatedList = [];

        professional.input['media'] = arrayMove(professional.input['media'], oldIndex, newIndex)

        // this.state.newOrderList.map((item, index) => {
        //     item.position = index
        //     updatedList.push({...item})
        // })

        professional.input['media'].map((item, index) => {
            item.position = index;
            updatedList.push({...item})
        });

        professional.input['media'] = updatedList;
        dispatch(changeProfessional(id, professional.input));

    };

    render() {

        const { professional, token, id, validation } = this.props;

        return (
            <>

                <div className="new-professional">
                    <Divider hidden/>

                    <Message color="teal">
                        Alle unten angegebene Inhalte werden im Inserat für alle Besucher angezeigt. 
                        Die Inhalte können hier jederzeit angepasst werden. Es kann allerdings ein paar Stunden dauern bis geänderte Inhalte im Inserat angezeigt werden.
                    </Message>

                    <Form>
                        <Form.Group widths={2} className="mb-2">
                            <Form.Field error={validation.mediamain && validation.mediamain.error}>
                                <label>Hauptbild hinzufügen</label>

                                {validation.mediamain && validation.mediamain.error && 
                                    <Message color="red">
                                        {validation.mediamain.errorMsg}
                                    </Message>
                                }

                                <FileUpload
                                    headers={{ 'Authorization': 'Bearer '+token }}
                                    allowedFileTypes={['jpg', 'jpeg', 'png']}
                                    dropzoneId={`professional.${id}.input.mediamain`}
                                    multiple={false}
                                    url={config.API_HOST + config.API_UPLOAD}
                                    >

                                        {professional.input.mediamain ?
                                 
                                            <SingleMedia
                                                editable
                                                image={{
                                                    path: professional.input.mediamainPath,
                                                    progress: professional.input.mediamainProgress,
                                                    id: professional.input.mediamain
                                                }}
                                            />

                                            :

                                            <div className="button-element">
                                                <Button basic color="red" className="single-button w-100">
                                                    <Image src={cameraIconRed} alt="Icon Kamera" className="button-icon"/>
                                                    <span>Hauptbild hinzufügen</span>
                                                </Button>
                                            </div>

                                        }
                                </FileUpload>
                            </Form.Field>

                            <Form.Field error={validation.medialogo && validation.medialogo.error}>
                                <label>Logo hinzufügen</label>

                                {validation.medialogo && validation.medialogo.error && 
                                    <Message color="red">
                                        {validation.medialogo.errorMsg}
                                    </Message>
                                }

                                <FileUpload
                                    headers={{ 'Authorization': 'Bearer '+token }}
                                    allowedFileTypes={['jpg', 'jpeg', 'png']}
                                    dropzoneId={`professional.${id}.input.medialogo`}
                                    multiple={false}
                                    url={config.API_HOST + config.API_UPLOAD}
                                >

                                    {professional.input.medialogo ?
                            
                                        <SingleMedia
                                            editable
                                            image={{
                                                path: professional.input.medialogoPath,
                                                progress: professional.input.medialogoProgress,
                                                id: professional.input.medialogo
                                            }}
                                        />
                                        :
                                        <div className="button-element">
                                            <Button basic color="red" className="single-button w-100">
                                                <Image src={cameraIconRed} alt="Icon Kamera" className="button-icon"/>
                                                <span>Logo hinzufügen</span>
                                            </Button>
                                        </div>

                                    }
                                </FileUpload>
                            </Form.Field>
                        </Form.Group>
                        
                        <Form.Field className="mb-2">
                            <label>Galerie hinzufügen/verwalten</label>
                            <FileUpload
                                headers={{ 'Authorization': 'Bearer '+token }}
                                allowedFileTypes={['jpg', 'jpeg']}
                                dropzoneId={`professional.${id}.input.media`}
                                multiple={true}
                                url={config.API_HOST + config.API_UPLOAD}
                            >
                                    <div className="button-element">
                                        <Button basic color="red" className="single-button">
                                            <Image src={cameraIconRed} alt="Icon Kamera" className="button-icon"/>
                                            <span>Bilder hinzufügen</span>
                                        </Button>
                                    </div>
                                </FileUpload>

                                {professional.input.media &&
                                    <SortableGallery
                                        images={professional.input.media}
                                        deletable
                                        handleDeleteItem={this.handleDeleteImage}
                                        onSortEnd={this.onSortEnd}
                                    />
                                }
                        </Form.Field>

                        <Form.Field className="video-upload">
                            <label>Video hinzufügen</label>
                            <FileUpload
                                headers={{ 'Authorization': 'Bearer '+token }}
                                allowedFileTypes={['mp4']}
                                dropzoneId={`professional.${id}.input.mediavideo`}
                                multiple={false}
                                url={config.API_HOST + config.API_UPLOAD}
                            >
                                {(professional.input.mediavideoPath && professional.input.mediavideoPath !== "ERROR") &&

                                    <div class="w-50">
                                        <SingleMedia
                                            deletable
                                            requestDelete={this.handleDeleteVideo}
                                            editable                                        
                                            video={{
                                                path: professional.input.mediavideoPath,
                                                previewPath: professional.input.mediavideoPreviewPath,
                                                progress: professional.input.mediavideoProgress,
                                                id: professional.input.mediavideo
                                            }}
                                        />
                                    </div>
                                }

                                {(!professional.input.mediavideoPath || professional.input.mediavideoPath === "ERROR") &&
                                    <>
                                        <div className="button-element">
                                            <Button basic color="red" className="single-button">
                                                <Image src={cameraIconRed} alt="Icon Kamera" className="button-icon"/>
                                                <span>Video hinzufügen</span>
                                            </Button>
                                            {professional.input.mediavideoPath === "ERROR" && 
                                                <Message color="red">
                                                    Das Video konnte nicht hochgeladen werden. Versuche es nochmal.
                                                </Message>
                                            }
                                        </div>
                                        
                                    </>
                                }
                            </FileUpload>
                            <Divider hidden/>
                        </Form.Field>

                    </Form>
                    <Divider hidden/>

                </div>
            </>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { account, professionalsById } = state;

    const { item: accountItem, token } = account || {}

    const { isFetching, isFailed, item: professional } = professionalsById[ownProps.id] || {
        item: {},
        isFetching: true,
        isFailed: false,
        professional: {}
    }

    if(professional && professional.input.media.length > 0){
        professional.input.media.sort((a, b) => a.position > b.position ? 1 : -1);
    }

    return {
        accountItem,
        token,
        isFetching, 
        isFailed,
        professional
    };
}

export default connect(mapStateToProps)(Media);
