import React, { createRef } from 'react'
import {
    Header,
    Placeholder,
    Input,
    Button,
    Ref,
    Icon,
    Container,
    Grid,
    Image,
    Message,
    Form,
    Divider,
    Tab,
    Responsive,
} from 'semantic-ui-react'
import {
    Link,
    withRouter,
    Prompt,
} from "react-router-dom";
import { connect } from 'react-redux';
import { FileUpload } from 'ix-redux-file-upload';
import TextareaAutosize from 'react-textarea-autosize';
import CustomSlidingPane from '../../../components/CustomSlidingPane/CustomSlidingPane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import CustomPopup from '../../../components/CustomPopup/CustomPopup';
import ProjectNav from '../../../components/layout/ProjectNav';
import Select from 'react-select'
import fetchProjectIfNeeded, { updateProject, directProjectUpdate, discardTechnicalData, API_PROJECT_SUCCESS_NODIRTY } from '../../../actions/project'
import config from '../../../config/config'
import SingleImage from '../../../components/PreviewGallery/SingleMedia';
import { handleContainer, showFooter, handleHeaderItems, footerActiveItem, setRuntime } from '../../../actions/global';
import SaveModal from '../../../components/SaveModal/SaveModal';
import ProgressiveImager from '../../../components//helpers/progressive-imager';
import heartIcon from '../../../images/icons/frontend/icon-heart.svg';
import commentIcon from '../../../images/icons/frontend/icon-comment.svg';
import eurIcon from '../../../images/icons/frontend/icon-euro-circle.svg';
import clockIcon from '../../../images/icons/frontend/icon-clock-circle.svg';
import ImageWrapper from '../../../components/helpers/image-wrapper';
import ArrowRight from '../../../images/icons/arow-right.svg'
import BeParent from './be-parent'
import Moment from 'react-moment';
import 'moment/locale/de'
import 'moment-timezone';
import '../../../components/ReactSelect/customSelect.scss';
import './Backend.scss';
import SingleMedia from '../../../components/PreviewGallery/SingleMedia';


/**
* Project backend view
*/
class BeProject extends BeParent {

    inputFieldsTdo = ['design', 'engine', 'cylinder', 'layout', 'displacement', 'performance', 'fuel', 'gear', 'gearno', 'drive'];
    inputFieldsTd = ['design', 'engine', 'cylinder', 'layout', 'displacement', 'performance', 'fuel', 'gear', 'gearno', 'drive', 'year'];
    inputFieldsVehicle = ['scope', 'name', 'typecode', 'nickname', ];
    inputFieldsBrand = ['name', 'countrycode'];
    inputFieldsManufacturer = ['name', 'countrycode'];

    constructor(props){
        super(props);

        this.state = {
            popupIsOpen: false,
            popupClickonDoc: true,
            popupOnEsc: false,
            slidingPane: {
                isOpen: false,
                title: ""
            },
            formValidation: {
                check: true,
                validationProjectName: {
                    error: false,
                    message: ''
                },
                validationBrand: {
                    error: false,
                    message: ''
                },
                validationModel: {
                    error: false,
                    message: ''
                },
                validationYear: {
                    error: false,
                    message: ''
                },
                validationTdCylinder: {
                    error: false,
                    message: ''
                },
                validationTdoCylinder: {
                    error: false,
                    message: ''
                },
                validationTdPerformance: {
                    error: false,
                    message: ''
                },
                validationTdoPerformance: {
                    error: false,
                    message: ''
                },
                validationTdGears: {
                    error: false,
                    message: ''
                },
                validationTdoGears: {
                    error: false,
                    message: ''
                },
                validationTdDisplacement: {
                    error: false,
                    message: ''
                },
                validationTdoDisplacement: {
                    error: false,
                    message: ''
                }
            },
            confirmModal: {
                isOpen: false,
            },
            nextLocation: null,
            leavenow: false,
        }

        this.submit = this.submit.bind(this);
        this.submitValidate = this.submitValidate.bind(this);
        this.showButton = this.showButton.bind(this);
        this.close = this.close.bind(this);
        this.discard = this.discard.bind(this);
    }

    contextRef = createRef()

    componentDidMount() {
        const { dispatch, match, item, isFetching } = this.props
        window.scrollTo(0, 0);
        dispatch(fetchProjectIfNeeded(match.params.id))
        dispatch(showFooter());
        dispatch(handleContainer("secondary", item.title, false, "dashboard"));
        dispatch(handleHeaderItems("dashboard"));
        dispatch(footerActiveItem("user"));
        dispatch(setRuntime({page: 'be-project-id-'+match.params.id}));

        // Set back path
        dispatch(setRuntime({
            backProject: {page: 'be-project-id-'+match.params.id, path: this.props.location},
        }));
    }

    componentDidUpdate(prevProps) {
        const { dispatch, item, isFetching, isDirty } = this.props;
        const { leavenow, closeAfterSave, nextLocation } = this.state;

        if (item && prevProps.item && item.title !== prevProps.item.title) {
            dispatch(handleContainer("secondary", item.title, false, "dashboard"));
        }

        if (leavenow && !isDirty) {
            this.setState({
                leavenow: false,
            });
            if (nextLocation) {
                this.props.history.push(nextLocation);
            }
        }

        if (closeAfterSave && prevProps.isFetching && !isFetching) {
            this.handleSlidingPanes(false);
            this.setState({closeAfterSave: false});
        }
    }

    handleSlidingPanes = (isOpen, title) => {
        this.setState({
            slidingPane: {
                isOpen: isOpen,
                title: title,
            },
            popupIsOpen: isOpen,
        });
    }

    projectValidation = () => {
        const { item, dispatch } = this.props;
        const { formValidation } = this.state;

        if(config.validateTextField(item.title, true).error){
            this.setState({
                formValidation: {
                    ...this.state.formValidation,
                    validationProjectName: config.validateTextField(item.title, true)
                }
            })
            return false
        }
        else {
            this.setState({
                formValidation: {
                    ...this.state.formValidation,
                    validationProjectName: config.validateTextField(item.title, true)
                }
            })
            this.submitChange()
            dispatch(handleContainer("secondary", item.title, false, "dashboard"));
        }

    }

    revertChangeValidation = () => {
        const { item, dispatch, match } = this.props;
        const { formValidation } = this.state;

       this.revertChange();

        this.setState({
            formValidation: {
                ...this.state.formValidation,
                validationProjectName: {
                    error: false,
                    message: ''
                }
            }
        })

        dispatch(handleContainer("secondary", item.title, false, "dashboard"));

    }

    technicalDataValidation = () => {

        const { td } = this.props;
        const { formValidation } = this.state;

        if(
            formValidation.validationModel.error ||
            formValidation.validationBrand.error ||
            formValidation.validationYear.error ||
            formValidation.validationTdoDisplacement.error ||
            formValidation.validationTdDisplacement.error ||
            formValidation.validationTdGears.error ||
            formValidation.validationTdoGears.error ||
            formValidation.validationTdPerformance.error ||
            formValidation.validationTdoPerformance.error ||
            formValidation.validationTdCylinder.error ||
            formValidation.validationTdoCylinder.error
        ) {
            this.setState({
                popupClickonDoc: false,
                formValidation: {
                     ...this.state.formValidation, check: false
                    },

            })

            return false;
        }
        else {
            this.setState({
                popupClickonDoc: true,
                formValidation: { ...this.state.formValidation, check: true },
            })

            return true;
        }

    }

    render() {
        const { dispatch, item, isFetching, lastUpdated } = this.props;

        if (!(item && item.id) && isFetching) {
            return (
                <Ref innerRef={this.contextRef}>
                    <div style={{ padding: '0' }} className="fetching-project">

                        {this.saveModal()}
                        {this.errorMessage()}

                        <div>
                            <div className="project-nav-loading">
                                <ProjectNav active="Projekt" project={item} />
                            </div>
                            <Container>
                                <Grid>
                                    <Grid.Row columns={2}>
                                        <Grid.Column>
                                            <Header as='h2' className="text-placeholder">
                                                Fahrzeug
                                        </Header>
                                        </Grid.Column>

                                        <Grid.Column>
                                            <div className="latest-update-date">
                                                <span className="text-placeholder">Letzte Änderung: </span>
                                                <span>-</span>
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <Grid reversed="computer tablet" className="project-info-wrapper">

                                    <Grid.Column mobile={16} tablet={8} computer={8} className="project-info-data-container">
                                        <div className="project-info">

                                            <span className="icons-social" >
                                                <Image src={clockIcon} className="icon-social" />
                                                <span className="social-amount text-grey">-</span>
                                            </span>

                                            <span className="icons-social" >
                                                <Image src={eurIcon} className="icon-social" />
                                                <span className="social-amount text-grey">-</span>
                                            </span>

                                            <span className="icons-social" >
                                                <Image src={heartIcon} className="icon-social" />
                                                <span className="social-amount text-grey">-</span>
                                            </span>

                                            <span className="icons-social" >
                                                <Image src={commentIcon} className="icon-social" />
                                                <span className="social-amount text-grey">-</span>
                                            </span>

                                        </div>
                                    </Grid.Column>

                                    <Grid.Column mobile={16} tablet={8} computer={8}>
                                        <Input style={{ marginBottom: '10px' }} placeholder='Projekttitel' value="-" fluid />
                                    </Grid.Column>

                                </Grid>

                                <Divider />

                                <Grid className="project-image-placeholder">
                                    <Grid.Column width={8}>
                                        <Header as="h3" className="text-placeholder">Hauptbild</Header>
                                        <Placeholder className="main_img">
                                            <Placeholder.Image />
                                        </Placeholder>
                                    </Grid.Column>

                                    <Grid.Column width={8}>
                                        <Header textAlign="center" as="h3" className="text-placeholder">Vorschaubild</Header>
                                        <Placeholder className="preview_img">
                                            <Placeholder.Image />
                                        </Placeholder>
                                    </Grid.Column>
                                </Grid>

                            </Container>
                            <Divider />

                            <div class="vehicle-data">
                                <Header as="h3" className="text-placeholder">
                                    Fahrzeugdaten
                                {/*<CircularProgressbar
                                        value="-"
                                        maxValue={100}
                                        text={`${"-"}%`}
                                        styles={buildStyles({
                                            textSize: "36px",
                                            pathColor: "#FF9600",
                                            textColor: "#000"
                                        })}
                                    />*/}
                                    <Responsive className="vehicle-data-icon" maxWidth={Responsive.onlyTablet.maxWidth}>
                                        <Icon name="angle right" size="small" />
                                    </Responsive>
                                    <Responsive className="vehicle-data-icon" minWidth={Responsive.onlyComputer.minWidth}>
                                        <Icon name="angle right" size="small" />
                                    </Responsive>
                                </Header>
                            </div>
                            <Divider />
                            <Container>
                                <Placeholder>
                                    <Placeholder.Paragraph>
                                        <Placeholder.Line length="medium" />
                                        <Placeholder.Line length="long" />
                                        <Placeholder.Line length="very long" />
                                        <Placeholder.Line length="long" />
                                    </Placeholder.Paragraph>
                                    <Placeholder.Paragraph>
                                        <Placeholder.Line length="medium" />
                                        <Placeholder.Line length="long" />
                                        <Placeholder.Line length="very long" />
                                        <Placeholder.Line length="long" />
                                    </Placeholder.Paragraph>
                                </Placeholder>
                            </Container>
                        </div>

                    </div>
                </Ref>
            )
        }
        else if (item && item.id) {

            return (
                <Ref innerRef={this.contextRef}>
                    <div>
                        {this.confirmModal(() => {
                            if (!this.state.slidingPane.isOpen && !this.state.popupIsOpen) {
                                this.revertChangeValidation();
                                this.setState({
                                    leavenow: true,
                                });
                            }
                            else {
                                this.discard();
                            }
                        })}
                        <Prompt when={this.props.isDirty} message={(location, action) => {
                            this.setState({
                                confirmModal: {
                                    isOpen: true,
                                },
                                nextLocation: location,
                            });

                            return false;
                        }} />
                        {this.saveModal()}
                        {this.errorMessage()}
                        <ProjectNav active="Projekt" project={item} />

                        {this.vehicle()}
                        {this.projectInfo()}

                    </div>
                </Ref>
            )
        }
        else {
            return (
                <Container text>
                    <Divider hidden/>
                    <Message negative>Es ist ein Fehler aufgetreten.</Message>
                </Container>
            )
        }
    }

    vehicle() {
        const { dispatch, token, item, isUpdating } = this.props;

        return (
            <div className="vehicle-page">
                <Container>
                    <Grid>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <Header as='h2' style={{ whiteSpace: "nowrap", display: 'inline-block' }}>
                                    Fahrzeug

                                    {/* {!item.active &&
                                        <Label color="red">Geparkt</Label>
                                    } */}

                                </Header>

                                {item.active &&
                                    <span className="project-link">
                                        <Button as={Link} color="green" to={`/oldtimer/${item.urlslug}/${item.id}`}>
                                            Live
                                            <Icon name='external alternate'/>
                                        </Button>
                                    </span>
                                }

                            </Grid.Column>

                            <Grid.Column>
                                <div className="latest-update-date">
                                    <span className="text-grey">Letzte Änderung: </span>
                                    <span><Moment unix format="DD.MM.YYYY">{item.sortingDatelastentry}</Moment></span> {/*TODO: Fetch project update date */}
                                </div>
                            </Grid.Column>

                        </Grid.Row>
                    </Grid>

                    {!item.active &&
                        <Grid>
                            <Grid.Column textAlign="center">
                                <Message negative>
                                    Das Fahrzeug ist geparkt und deshalb inkl. dessen Storys für andere unsichtbar.
                                    Du solltest das Fahrzeug veröffentlichen. Du kannst es jederzeit erneut parken.
                                </Message>
                                <Button loading={isUpdating} color="green" onClick={() => {
                                        //this.handleChangeCheckbox(null, {name:'project.active', checked: true})
                                        if (!isUpdating) {
                                            dispatch(directProjectUpdate(item.id, 'active', true));
                                        }
                                }}>
                                    <span>Dieses Fahrzeug veröffentlichen</span>
                                </Button>
                                <Divider hidden/>
                            </Grid.Column>
                        </Grid>
                    }

                    <Grid reversed="computer tablet" className="project-info-wrapper">
                        <Grid.Column mobile={16} tablet={8} computer={8} className="project-info-data-container">
                            <div className="project-info-data">

                                <span className="icons-social" >
                                    <Image src={clockIcon} className="icon-social" />
                                    <span className="social-amount text-grey">{config.getFormattedInt(item.sumWorkinghours)}</span>
                                </span>

                                <span className="icons-social" >
                                    <Image src={eurIcon} className="icon-social" />
                                    <span className="social-amount text-grey">{config.getFormattedInt(item.sumPrice)}</span>
                                </span>

                                <span className="icons-social" >
                                    <Image src={heartIcon} className="icon-social" />
                                    <span className="social-amount text-grey">{config.getFormattedInt(item.countLike)}</span>
                                </span>

                                <span className="icons-social" >
                                    <Image src={commentIcon} className="icon-social" />
                                    <span className="social-amount text-grey">{config.getFormattedInt(item.countComment)}</span>
                                </span>

                            </div>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={8} computer={8}>
                            <Input
                                placeholder='Projekttitel'
                                value={item.title}
                                fluid
                                name="project.title"
                                onChange={this.handleChangeInput}
                                error={this.state.formValidation.validationProjectName.message}
                                // onBlur={(e) => this.setState({
                                //     formValidation: {
                                //         ...this.state.formValidation,
                                //         validationProjectName: config.validateTextField(e.target.value, true)
                                //     }
                                // })}
                            />

                            {this.state.formValidation.validationProjectName.error &&
                                <Message color="red" style={{textAlign: "left"}}>
                                    {this.state.formValidation.validationProjectName.message}
                                </Message>
                            }

                        </Grid.Column>
                    </Grid>

                    <Divider />

                    <Grid>
                        <Grid.Column width={8}>
                            <Header as="h3">Hauptbild</Header>
                            <FileUpload
                                headers={{ 'Authorization': 'Bearer ' + token }}
                                allowedFileTypes={['jpg', 'jpeg']}
                                dropzoneId={'project.' + item.id + '.mediamain'}
                                url={config.API_HOST + config.API_UPLOAD}
                            >
                                {/* <div className="image_container main_img">
                                    <ImageWrapper path={item.mediamainPath} className="main_img-inside">
                                        <ProgressiveImager src={config.getImageUrl('halfwidth', item.mediamainPath)} />
                                        <Icon name="pencil" circular inverted color="grey" />
                                    </ImageWrapper>
                                </div> */}
                                <SingleMedia 
                                    editable
                                    image={{
                                        path: item.mediamainPath,
                                        progress: item.mediamainProgress,
                                        id: item.mediamain
                                    }}
                                />
                            </FileUpload>
                        </Grid.Column>

                        <Grid.Column width={8}>
                            <Header textAlign="center" as="h3">Vorschaubild</Header>
                            <FileUpload
                                headers={{ 'Authorization': 'Bearer ' + token }}
                                allowedFileTypes={['jpg', 'jpeg']}
                                dropzoneId={'project.' + item.id + '.mediapreview'}
                                url={config.API_HOST + config.API_UPLOAD}
                            >
                                {/* <div className="circular-image">
                                    <ImageWrapper className="image_container preview_img" path={item.mediapreviewPath}>
                                        <ProgressiveImager src={config.getImageUrl('halfwidth', item.mediapreviewPath)} circular />
                                        <Icon name="pencil" circular inverted color="grey" />
                                    </ImageWrapper>
                                </div> */}
                                <SingleMedia
                                    circular
                                    editable
                                    image={{
                                        path: item.mediapreviewPath,
                                        progress: item.mediapreviewProgress,
                                        id: item.mediapreview
                                    }}
                                />
                            </FileUpload>
                        </Grid.Column>
                    </Grid>

                    <Divider />

                    <div class="vehicle-data">
                    <Responsive maxWidth={Responsive.onlyTablet.maxWidth} onClick={() => this.handleSlidingPanes(true, "Fahrzeugdaten")}>
                    <Header as="h3">
                        Fahrzeugdaten
                        {/*<CircularProgressbar
                            value={75}
                            maxValue={100}
                            text={`${75}%`}
                            styles={buildStyles({
                                textSize: "36px",
                                pathColor: "#FF9600",
                                textColor: "#000"
                            })}
                        />*/}
                        <div className="vehicle-data-icon">
                            <Image src={ArrowRight}/>
                        </div>
                    </Header>
                    </Responsive>
                    <Responsive minWidth={Responsive.onlyComputer.minWidth} onClick={() => this.handleSlidingPanes(true, "Fahrzeugdaten")}>
                    <Header as="h3">
                        Fahrzeugdaten
                        {/*<CircularProgressbar
                            value={75}
                            maxValue={100}
                            text={`${75}%`}
                            styles={buildStyles({
                                textSize: "36px",
                                pathColor: "#FF9600",
                                textColor: "#000"
                            })}
                        />*/}
                        <div className="vehicle-data-icon">
                            <Image src={ArrowRight}/>
                        </div>
                    </Header>
                    </Responsive>
                    </div>
                    <Responsive as={CustomSlidingPane} {...Responsive.onlyMobile}
                        isOpen={this.state.slidingPane.isOpen}
                        title={this.state.slidingPane.title}
                        onRequestClose={this.close}
                        saveButtonCallback={this.submitValidate}
                        saveButton={this.showButton()}
                        isLoading={this.props.isFetching}
                    >
                        {this.slidingPane()}
                    </Responsive>

                    <Responsive as={CustomPopup} minWidth={Responsive.onlyTablet.minWidth}
                        popupHeader="Fahrzeugdaten"
                        popupOpen={this.state.popupIsOpen}
                        onDocumentClick={this.state.popupClickonDoc}
                        className="larger-popup"
                        onClose={this.close}
                        saveButtonCallback={this.submitValidate}
                        saveButton={this.showButton()}
                        isLoading={this.props.isFetching}
                    >
                        {this.slidingPane()}
                    </Responsive>
                    <Divider />

                </Container>
            </div>
        )
    }

    projectInfo() {
        const { item } = this.props

        return (
            <div>
                <Container>

                    <Header as="h2">Projektziel</Header>
                    <Select menuPlacement="auto"
                        style={{width: "100%"}}
                        name="project.goal"
                        onChange={this.handleChangeSelect}
                        value={config.getDefault(config.getGoals(), item.goal)}
                        options={config.getGoals()}
                        classNamePrefix="custom-select"
                        className="custom-select-wrapper"
                        />

                    <Header as="h2">Interview</Header>

                    <Message info>
                        Warum musste es grade dieses Fahrzeug sein, wo hast Du es her und viele weitere Dinge interessieren uns und andere Schrauber.
                        Beantworte daher möglichst die nachfolgenden Fragen. Du kannst später jederzeit Änderungen an den Texten vornehmen.
                    </Message>

                    <Divider hidden />

                    {/* Mobile */}

                    <Responsive {...Responsive.onlyMobile}>

                        <Form>
                            <Form.Field>
                                <label>Warum mußte es dieses Fahrzeug sein?</label>
                                <TextareaAutosize
                                    minRows="5"
                                    maxRows="15"
                                    value={item.description1}
                                    name="project.description1"
                                    onChange={this.handleChangeInputAlt}
                                    inline
                                />
                            </Form.Field>

                            <Form.Field>
                                <label>Wie bist Du auf das Fahrzeug aufmerksam geworden?</label>
                                <TextareaAutosize
                                    minRows="5"
                                    maxRows="15"
                                    value={item.description2}
                                    name="project.description2"
                                    onChange={this.handleChangeInputAlt}
                                />
                            </Form.Field>

                            <Form.Field>
                                <label>Wie kam das Fahrzeug zu dir nachhause?</label>
                                <TextareaAutosize
                                    minRows="5"
                                    maxRows="15"
                                    value={item.description3}
                                    name="project.description3"
                                    onChange={this.handleChangeInputAlt}
                                />
                            </Form.Field>

                            <Form.Field>
                                <label>Wie war der Zustand bei Abholung?</label>
                                <TextareaAutosize
                                    minRows="5"
                                    maxRows="15"
                                    value={item.description4}
                                    name="project.description4"
                                    onChange={this.handleChangeInputAlt}
                                />
                            </Form.Field>

                            <Form.Field>
                                <label>Was hast du mit dem Fahrzeug vor (Ziel)?</label>
                                <TextareaAutosize
                                    minRows="5"
                                    maxRows="15"
                                    value={item.description5}
                                    name="project.description5"
                                    onChange={this.handleChangeInputAlt}
                                />
                            </Form.Field>

                            <Form.Field>
                                <label>Gibt es sonst noch etwas spannendes oder lustiges?</label>
                                <TextareaAutosize
                                    minRows="5"
                                    maxRows="15"
                                    value={item.description}
                                    name="project.description"
                                    onChange={this.handleChangeInputAlt}
                                />
                            </Form.Field>
                        </Form>

                    </Responsive>

                    {/* Desktop */}

                    <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                        <Form>
                            <Grid>

                                <Grid.Row columns="equal">
                                    <Grid.Column>
                                        <Form.Field>
                                            <label>Warum mußte es dieses Fahrzeug sein?</label>
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <TextareaAutosize
                                            minRows="5"
                                            maxRows="15"
                                            value={item.description1}
                                            name="project.description1"
                                            onChange={this.handleChangeInputAlt}
                                            inline
                                        />
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row columns="equal">
                                    <Grid.Column>
                                        <Form.Field>
                                            <label>Wie bist Du auf das Fahrzeug aufmerksam geworden?</label>
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <TextareaAutosize
                                            minRows="5"
                                            maxRows="15"
                                            value={item.description2}
                                            name="project.description2"
                                            onChange={this.handleChangeInputAlt}
                                        />
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row columns="equal">
                                    <Grid.Column>
                                        <Form.Field>
                                            <label>Wie kam das Fahrzeug zu Dir nach hause?</label>
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <TextareaAutosize
                                            minRows="5"
                                            maxRows="15"
                                            value={item.description3}
                                            name="project.description3"
                                            onChange={this.handleChangeInputAlt}
                                        />
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row columns="equal">
                                    <Grid.Column>
                                        <Form.Field>
                                            <label>Wie war der Zustand bei Abholung?</label>
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <TextareaAutosize
                                            minRows="5"
                                            maxRows="15"
                                            value={item.description4}
                                            name="project.description4"
                                            onChange={this.handleChangeInputAlt}
                                        />
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row columns="equal">
                                    <Grid.Column>
                                        <Form.Field>
                                            <label>Was hast/hattest Du mit dem Fahrzeug vor (Ziel)?</label>
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <TextareaAutosize
                                            minRows="5"
                                            maxRows="15"
                                            value={item.description5}
                                            name="project.description5"
                                            onChange={this.handleChangeInputAlt}
                                        />
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row columns="equal">
                                    <Grid.Column>
                                        <Form.Field>
                                            <label>Gibt es sonst noch etwas Spannendes oder Lustiges? Wie lief es bisher?</label>
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <TextareaAutosize
                                            minRows="5"
                                            maxRows="15"
                                            value={item.description}
                                            name="project.description"
                                            onChange={this.handleChangeInputAlt}
                                        />
                                    </Grid.Column>
                                </Grid.Row>

                            </Grid>
                        </Form>
                    </Responsive>
                    <Divider hidden />
                    <Divider hidden />
                    <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                        <Divider hidden />
                        <Divider hidden />
                    </Responsive>
                </Container>
            </div>
        )
    }

    saveModal() {
        const { isFetching, isDirty } = this.props;
        const { slidingPane, popupIsOpen, confirmModal } = this.state;

        return (

            <SaveModal 
                showSaveModal={isDirty && !slidingPane.isOpen && !popupIsOpen && !confirmModal.isOpen} 
                fetching={isFetching} 
                revert={this.revertChangeValidation} 
                submit={this.projectValidation} 
                messageText="Du bist dabei, Änderungen an Deinem Projekt vorzunehmen. Nimm weiter Änderungen vor, bis Du fertig bist. Danach kannst Du alle Änderungen speichern oder verwerfen."
                submitText="Änderungen speichern"
                revertText="Änderungen verwerfen"
                />

        )
    }

    errorMessage() {
        const { isFailed } = this.props

        if (isFailed) {
            return (
                <Container text>
                    <Divider hidden/>
                    <Message negative>Beim Übertragen der Daten ist ein Fehler aufgetreten.</Message>
                </Container>
            )
        }

        return (<></>);
    }

    factoryData() {
        const { td, vehicle } = this.props
        return (
            <Form>
                <Form.Field>
                    <label>Fahrzeugart</label>
                    <Select menuPlacement="auto"
                        label="Fahrzeugart"
                        options={config.getDesigns(vehicle.scope)}
                        name="td.design"
                        onChange={this.handleChangeSelect}
                        value={config.getDefault(config.getDesigns(vehicle.scope), td.input.design)}
                        classNamePrefix="custom-select"
                        className="custom-select-wrapper"
                    />
                </Form.Field>

                <Form.Field>
                    <label>Motorart</label>
                    <Select menuPlacement="auto"
                        classNamePrefix="select-custom"
                        name="td.engine"
                        onChange={this.handleChangeSelect}
                        value={config.getDefault(config.getEngines(), td.input.engine)}
                        options={config.getEngines()}
                        classNamePrefix="custom-select"
                        className="custom-select-wrapper"
                    />
                </Form.Field>

                <Form.Input
                    type="text"
                    label="Anzahl Zylinder"
                    placeholder='—'
                    style={{width: "170px"}}
                    name="td.cylinder"
                    error={this.state.formValidation.validationTdCylinder.error ? true : false}
                    onChange={this.handleChangeNumericInput}
                    maxLength="2"
                    onBlur={(e) => {
                        this.setState({
                        formValidation: {
                            ...this.state.formValidation,
                            validationTdCylinder: config.numericFields(e.target.value)
                        }
                    }, this.technicalDataValidation
                    )
                }}
                    // onKeyDown={(e) => this.allowOnlyNumbers(e)}
                    value={td.input.cylinder}
                    fluid
                />

                {this.state.formValidation.validationTdCylinder.error &&
                    <Message color="red">
                        {this.state.formValidation.validationTdCylinder.message}
                    </Message>
                }

                <Form.Field>
                    <label>Anordnung</label>
                    <Select menuPlacement="auto"
                        classNamePrefix="select-custom"
                        name="td.layout"
                        onChange={this.handleChangeSelect}
                        value={config.getDefault(config.getLayouts(), td.input.layout)}
                        options={config.getLayouts()}
                        classNamePrefix="custom-select"
                        className="custom-select-wrapper"
                    />
                </Form.Field>


                <Form.Field error={this.state.formValidation.validationTdDisplacement.error ? true : false}>
                    <label>Hubraum</label>
                    <Input
                        type="text"
                        placeholder='—'
                        name="td.displacement"
                        onChange={this.handleChangeNumericInput}
                        maxLength="6"
                        error={this.state.formValidation.validationTdDisplacement.error ? true : false}
                        value={td.input.displacement}
                        style={{width: "170px"}}
                        label={{
                            basic: true,
                            content: 'ccm'
                        }}
                        labelPosition='right'
                        error={this.state.formValidation.validationTdDisplacement.error ? true : false}
                        onBlur={(e) => this.setState({
                            formValidation: {
                                ...this.state.formValidation,
                                validationTdDisplacement: config.numericFields(e.target.value)
                            }
                        }, this.technicalDataValidation)}
                    />
                </Form.Field>

                {this.state.formValidation.validationTdDisplacement.error &&
                    <Message color="red">
                        {this.state.formValidation.validationTdDisplacement.message}
                    </Message>
                }

                <Form.Field error={this.state.formValidation.validationTdPerformance.error ? true : false}>
                    <label>Leistung</label>
                    <Input
                        placeholder='—'
                        type="text"
                        name="td.performance"
                        onChange={this.handleChangeNumericInput}
                        maxLength="5"
                        value={td.input.performance && td.input.performance != 0 ? config.convertToPointNumber(td.input.performance)  : ''}
                        style={{width: "170px"}}
                        label={{
                            basic: true,
                            content: 'PS'
                        }}
                        labelPosition='right'
                        error={this.state.formValidation.validationTdPerformance.error ? true : false}
                        onBlur={(e) => this.setState({
                            formValidation: {
                                ...this.state.formValidation,
                                validationTdPerformance: config.validationPointNumbers(e.target.value)
                            }
                        }, this.technicalDataValidation)}
                    />
                </Form.Field>

                {this.state.formValidation.validationTdPerformance.error &&
                    <Message color="red">
                        {this.state.formValidation.validationTdPerformance.message}
                    </Message>
                }

                <Form.Field>
                    <label>Kraftstoff</label>
                    <Select menuPlacement="auto"
                        placeholder='—'
                        name="td.fuel"
                        onChange={this.handleChangeSelect}
                        value={config.getDefault(config.getFuels(), td.input.fuel)}
                        fluid
                        options={config.getFuels()}
                        classNamePrefix="custom-select"
                        className="custom-select-wrapper"
                    />
                </Form.Field>

                <Form.Field>
                    <label>Getriebe</label>
                    <Select menuPlacement="auto"
                        placeholder='—'
                        name="td.gear"
                        classNamePrefix="custom-select"
                        className="custom-select-wrapper"
                        onChange={this.handleChangeSelect}
                        value={config.getDefault(config.getGears(), td.input.gear)}
                        fluid
                        options={config.getGears()}
                    />
                </Form.Field>

                <Form.Input
                    type="text"
                    label="Anzahl Gänge"
                    style={{width: "170px"}}
                    placeholder='—'
                    value={td.input.gearno}
                    maxLength="2"
                    fluid
                    name="td.gearno"
                    onChange={this.handleChangeNumericInput}
                    error={this.state.formValidation.validationTdGears.error ? true : false}
                    onBlur={(e) => this.setState({
                        formValidation: {
                            ...this.state.formValidation,
                            validationTdGears: config.numericFields(e.target.value)
                        }
                    }, this.technicalDataValidation)}
                />

                 {this.state.formValidation.validationTdGears.error &&
                    <Message color="red">
                        {this.state.formValidation.validationTdGears.message}
                    </Message>
                }

                <Form.Field>
                    <label>Antriebsart</label>
                    <Select menuPlacement="auto"
                        classNamePrefix="custom-select"
                        className="custom-select-wrapper"
                        name="td.drive"
                        onChange={this.handleChangeSelect}
                        value={config.getDefault(config.getDrives(), td.input.drive)}
                        options={config.getDrives()}
                    />
                </Form.Field>

            </Form>
        )
    }

    overrideData() {
        const { tdo, vehicle } = this.props
        return (
            <Form>

                <Form.Field>
                    <label>Fahrzeugart</label>
                    <Select menuPlacement="auto"
                        classNamePrefix="custom-select"
                        className="custom-select-wrapper"
                        name="tdo.design"
                        onChange={this.handleChangeSelect}
                        value={config.getDefault(config.getDesigns(vehicle.scope), tdo.input.design)}
                        options={config.getDesigns(vehicle.scope)}
                    />
                </Form.Field>

                <Form.Field>
                    <label>Motorart</label>
                    <Select menuPlacement="auto"
                        classNamePrefix="custom-select"
                        className="custom-select-wrapper"
                        name="tdo.engine"
                        onChange={this.handleChangeSelect}
                        value={config.getDefault(config.getEngines(), tdo.input.engine)}
                        options={config.getEngines()}
                    />
                </Form.Field>

                <Form.Input
                    type="text"
                    label="Anzahl Zylinder"
                    style={{width: "170px"}}
                    placeholder='—'
                    name="tdo.cylinder"
                    error={this.state.formValidation.validationTdoCylinder.error ? true : false}
                    onChange={this.handleChangeNumericInput}
                    onBlur={(e) => this.setState({
                        formValidation: {
                            ...this.state.formValidation,
                            validationTdoCylinder: config.numericFields(e.target.value)
                        }
                    }, this.technicalDataValidation)}
                    maxLength="2"
                    value={tdo.input.cylinder}
                    fluid

                />

                {this.state.formValidation.validationTdoCylinder.error &&
                    <Message color="red">
                        {this.state.formValidation.validationTdoCylinder.message}
                    </Message>
                }

                <Form.Field>
                    <label>Anordnung</label>
                    <Select menuPlacement="auto"
                        classNamePrefix="custom-select"
                        className="custom-select-wrapper"
                        name="tdo.layout"
                        onChange={this.handleChangeSelect}
                        value={config.getDefault(config.getLayouts(), tdo.input.layout)}
                        options={config.getLayouts()}
                    />
                </Form.Field>

                <Form.Field error={this.state.formValidation.validationTdoDisplacement.error ? true : false}>
                    <label>Hubraum</label>
                    <Input
                        type="text"
                        placeholder='—'
                        name="tdo.displacement"
                        autoComplete="off"
                        onChange={this.handleChangeNumericInput}
                        maxLength="6"
                        value={tdo.input.displacement}
                        style={{width: "170px"}}
                        label={{
                            basic: true,
                            content: 'ccm'
                        }}
                        labelPosition='right'
                        error={this.state.formValidation.validationTdoDisplacement.error ? true : false}
                        onBlur={(e) => this.setState({
                            formValidation: {
                                ...this.state.formValidation,
                                validationTdoDisplacement: config.numericFields(e.target.value)
                            }
                        }, this.technicalDataValidation)}
                    />
                </Form.Field>

                {this.state.formValidation.validationTdoDisplacement.error &&
                    <Message color="red">
                        {this.state.formValidation.validationTdoDisplacement.message}
                    </Message>
                }

                <Form.Field error={this.state.formValidation.validationTdoPerformance.error ? true : false}>
                    <label>Leistung</label>
                    <Input
                        placeholder='—'
                        type="text"
                        name="tdo.performance"
                        onChange={this.handleChangeNumericInput}
                        maxLength="5"
                        value={tdo.input.performance && tdo.input.performance != 0 ? config.convertToPointNumber(tdo.input.performance) : '' }
                        style={{width: "170px"}}
                        label={{
                            basic: true,
                            content: 'PS'
                        }}
                        labelPosition='right'
                        error={this.state.formValidation.validationTdoPerformance.error ? true : false}
                        onBlur={(e) => this.setState({
                            formValidation: {
                                ...this.state.formValidation,
                                validationTdoPerformance: config.validationPointNumbers(e.target.value)
                            }
                        }, this.technicalDataValidation)}
                    />
                </Form.Field>

                {this.state.formValidation.validationTdoPerformance.error &&
                    <Message color="red">
                        {this.state.formValidation.validationTdoPerformance.message}
                    </Message>
                }

                <Form.Field>
                    <label>Kraftstoff</label>
                    <Select menuPlacement="auto"
                        classNamePrefix="custom-select"
                        className="custom-select-wrapper"
                        placeholder='—'
                        name="tdo.fuel"
                        onChange={this.handleChangeSelect}
                        value={config.getDefault(config.getFuels(), tdo.input.fuel)}
                        fluid
                        options={config.getFuels()}
                    />
                </Form.Field>

                <Form.Field>
                    <label>Getriebe</label>
                    <Select menuPlacement="auto"
                        classNamePrefix="custom-select"
                        className="custom-select-wrapper"
                        placeholder='—'
                        name="tdo.gear"
                        onChange={this.handleChangeSelect}
                        error={this.state.formValidation.validationTdoGears.error ? true : false}
                        value={config.getDefault(config.getGears(), tdo.input.gear)}
                        fluid
                        options={config.getGears()}
                    />
                </Form.Field>

                <Form.Input
                    type="text"
                    label="Anzahl Gänge"
                    style={{width: "170px"}}
                    maxLength="2"
                    placeholder='—'
                    value={tdo.input.gearno}
                    fluid
                    name="tdo.gearno"
                    onChange={this.handleChangeNumericInput}
                    error={this.state.formValidation.validationTdoGears.error ? true : false}
                        onBlur={(e) => this.setState({
                            formValidation: {
                                ...this.state.formValidation,
                                validationTdoGears: config.numericFields(e.target.value)
                            }
                        }, this.technicalDataValidation)}
                />

                {this.state.formValidation.validationTdoGears.error &&
                    <Message color="red">
                        {this.state.formValidation.validationTdoGears.message}
                    </Message>
                }

                <Form.Field>
                    <label>Antriebsart</label>
                    <Select menuPlacement="auto"
                        classNamePrefix="custom-select"
                        className="custom-select-wrapper"
                        className="custom-select-wrapper"
                        name="tdo.drive"
                        onChange={this.handleChangeSelect}
                        value={config.getDefault(config.getDrives(), tdo.input.drive)}
                        options={config.getDrives()}
                    />
                </Form.Field>

            </Form>
        )
    }

    slidingPane() {

        const { tdo, td, vehicle, brand, manufacturer } = this.props

        const tabContent = [
            {
                menuItem: 'Ab Werk', pane: (
                    <Tab.Pane key='tab1'>
                        {this.factoryData()}
                    </Tab.Pane>
                )
            },
            {
                menuItem: 'Aktuell', pane: (
                    <Tab.Pane>
                       {this.overrideData()}
                    </Tab.Pane>
                )
            }
        ]
        return (

            <>
                {!this.state.formValidation.check &&
                <Container>
                    <Message color="red">
                        Überprüfe deine Angaben.
                    </Message>
                    <Divider hidden/>
                </Container>
                }
                <Grid>

                    <Grid.Column mobile={16} tablet={16} computer={8} style={{paddingBottom: 0}}>
                        <Container>
                            <Form>
                                {/* Fahrzeugart */}
                                <Form.Field>
                                    <label>Kategorie</label>
                                    <Input
                                        className="transparent-border"
                                        value={config.getDefault(config.getScopes(), vehicle.input.scope).label}
                                        readonly
                                        focus={false}
                                    />
                                </Form.Field>
                                {/* Marke/Modell */}
                                <Form.Field error={this.state.formValidation.validationBrand.error ? true : false}>
                                    <label>Marke</label>
                                    <Input
                                        // icon={<Icon name='times' inverted circular />}
                                        name="brand.name"
                                        onChange={this.handleChangeInput}
                                        value={brand.input.name}
                                        onBlur={(e) => this.setState({
                                            formValidation: {
                                                ...this.state.formValidation,
                                                validationBrand: config.validateTextField(e.target.value, true)
                                            }
                                        }, this.technicalDataValidation)}
                                    />
                                </Form.Field>

                                {this.state.formValidation.validationBrand.error &&
                                    <Message color="red">
                                        {this.state.formValidation.validationBrand.message}
                                    </Message>
                                }

                                <Form.Field error={this.state.formValidation.validationModel.error ? true : false}>
                                    <label>Modell</label>
                                    <Input
                                        // icon={<Icon name='times' inverted circular />}
                                        name="vehicle.name"
                                        onChange={this.handleChangeInput}
                                        value={vehicle.input.name}
                                        onBlur={(e) => this.setState({
                                            formValidation: {
                                                ...this.state.formValidation,
                                                validationModel: config.validateTextField(e.target.value, true)
                                            }
                                        }, this.technicalDataValidation)}
                                    />
                                </Form.Field>

                                {this.state.formValidation.validationModel.error &&
                                    <Message color="red">
                                        {this.state.formValidation.validationModel.message}
                                    </Message>
                                }

                                {/* Werkscode */}
                                <Form.Field>
                                    <label>Werkscode</label>
                                    <Input
                                        // icon={<Icon name='times' inverted circular />}
                                        name="vehicle.typecode"
                                        onChange={this.handleChangeInput}
                                        value={vehicle.input.typecode}
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <label>Landläufige Bezeichnung</label>
                                    <Input
                                        // icon={<Icon name='times' inverted circular />}
                                        name="vehicle.nickname"
                                        onChange={this.handleChangeInput}
                                        value={vehicle.input.nickname}
                                    />
                                </Form.Field>

                            </Form>
                        </Container>
                    </Grid.Column>

                    <Grid.Column mobile={16} tablet={16} computer={8}>
                        <Container>
                            <Form>

                                {/* Baujahr */}
                                <Form.Input
                                    type="text"
                                    style={{ width: "80px" }}
                                    label="Baujahr"
                                    name="td.year"
                                    error={this.state.formValidation.validationYear.error ? true : false}
                                    onChange={this.handleChangeNumericInput}
                                    maxLength="4"
                                    value={td.input.year}
                                    onBlur={(e) => this.setState({
                                        formValidation: {
                                            ...this.state.formValidation,
                                            validationYear: config.validateProductionYear(e.target.value)
                                        }
                                    }, this.technicalDataValidation)}
                                >
                                </Form.Input>

                                {this.state.formValidation.validationYear.error &&
                                    <Message color="red">{this.state.formValidation.validationYear.message}</Message>
                                }

                                {/* Hersteller */}

                                <Form.Field>
                                    <label>Land (Marke)</label>
                                        <Select menuPlacement="auto"
                                            classNamePrefix="custom-select"
                                            className="custom-select-wrapper"
                                            options={config.countryChoice()}
                                            name="brand.countrycode"
                                            onChange={this.handleChangeSelect}
                                            value={config.getDefault(config.countryChoice(), brand.input.countrycode)}
                                        />
                                </Form.Field>

                                <Form.Field>
                                    <label>Hersteller</label>
                                    <Input
                                        // icon={<Icon name='times' inverted circular />}
                                        name="manufacturer.name"
                                        onChange={this.handleChangeInput}
                                        value={manufacturer.input.name}
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <label>Land (Hersteller)</label>
                                        <Select menuPlacement="auto"
                                            classNamePrefix="custom-select"
                                            className="custom-select-wrapper"
                                            options={config.countryChoice()}
                                            name="manufacturer.countrycode"
                                            onChange={this.handleChangeSelect}
                                            value={config.getDefault(config.countryChoice(), manufacturer.input.countrycode)}
                                        />
                                </Form.Field>

                            </Form>
                        </Container>

                    </Grid.Column>



                    <Grid.Column width={16} only="computer">
                        <Responsive as={Divider} minWidth={Responsive.onlyComputer.minWidth} />
                        <Responsive as={Divider} hidden minWidth={Responsive.onlyComputer.minWidth}/>
                        <Grid>
                            <Grid.Column width={8}>
                                <Header as="h3">Ab Werk</Header>
                                {this.factoryData()}
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <Header as="h3">Aktuell</Header>
                                {this.overrideData()}
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>

                    <Divider hidden />

                    <Responsive as={Divider} hidden minWidth={Responsive.onlyComputer.minWidth} />

                </Grid>

                <Responsive as={Container} maxWidth={Responsive.onlyTablet.maxWidth}>
                    <Tab className="custom-tab-menu" menu={{ pointing: true }} panes={tabContent} renderActiveOnly={false} />
                </Responsive>
            </>

        )
    }

    resetValidation() {
        this.setState({
            formValidation: {
                ...this.state.formValidation,
                check: true,
                validationBrand: {
                    error: false,
                    message: ''
                },
                validationModel: {
                    error: false,
                    message: ''
                },
                validationYear: {
                    error: false,
                    message: ''
                },
                validationTdCylinder: {
                    error: false,
                    message: ''
                },
                validationTdoCylinder: {
                    error: false,
                    message: ''
                },
                validationTdPerformance: {
                    error: false,
                    message: ''
                },
                validationTdoPerformance: {
                    error: false,
                    message: ''
                },
                validationTdGears: {
                    error: false,
                    message: ''
                },
                validationTdoGears: {
                    error: false,
                    message: ''
                },
                validationTdDisplacement: {
                    error: false,
                    message: ''
                },
                validationTdoDisplacement: {
                    error: false,
                    message: ''
                }
            },
        });
    }

    discard() {
        const { dispatch, item } = this.props;

        // Discard changes
        dispatch(discardTechnicalData(item.id));

        // Reset validation
        this.resetValidation();

        // Close popup/pane
        this.handleSlidingPanes(false);
        this.setState({
            confirmModal: {
                isOpen: false
            },
        });
    }

    close = () => {
        const { dispatch, match, tdo, td, vehicle, brand, manufacturer, item } = this.props;

        if (
            config.isChanged(tdo, this.inputFieldsTdo) ||
            config.isChanged(td, this.inputFieldsTd) ||
            config.isChanged(vehicle, this.inputFieldsVehicle) ||
            config.isChanged(brand, this.inputFieldsBrand) ||
            config.isChanged(manufacturer, this.inputFieldsManufacturer)
        ) {
            this.setState({
                confirmModal: {
                    isOpen: true
                },
            })
        }
        else {
            this.handleSlidingPanes(false);
        }
    }

    showButton() {
        const { dispatch, match, tdo, td, vehicle, brand, manufacturer, item } = this.props;

        if (
            config.isChanged(tdo, this.inputFieldsTdo) ||
            config.isChanged(td, this.inputFieldsTd) ||
            config.isChanged(vehicle, this.inputFieldsVehicle) ||
            config.isChanged(brand, this.inputFieldsBrand) ||
            config.isChanged(manufacturer, this.inputFieldsManufacturer)
        ) {
            return true;
        }

        return false;
    }

    submitValidate() {
        const { dispatch, item, tdo, td, vehicle, brand, manufacturer } = this.props;
        const { formValidation } = this.state;

        this.setState({
            formValidation: {
                ...formValidation,
                validationTdCylinder: config.numericFields(td.input.cylinder),
                validationTdDisplacement: config.numericFields(td.input.displacement),
                validationTdPerformance: config.validationPointNumbers(td.input.performance),
                validationTdGears: config.numericFields(td.input.gearno),
                validationTdoCylinder: config.numericFields(tdo.input.cylinder),
                validationTdoDisplacement: config.numericFields(tdo.input.displacement),
                validationTdoPerformance: config.validationPointNumbers(tdo.input.performance),
                validationTdoGears: config.numericFields(tdo.input.gearno),
                validationBrand: config.validateTextField(brand.input.name, true),
                validationModel: config.validateTextField(vehicle.input.name, true),
                validationYear: config.validateProductionYear(td.input.year),
            }
        }, this.submit)
    }

    submit() {
        const { dispatch, item, tdo, td, vehicle, brand, manufacturer } = this.props;

        if (this.technicalDataValidation()) {
            this.resetValidation();

            //Convert point numbers to float
            td.input.performance = config.convertToFloat(td.input.performance);
            tdo.input.performance = config.convertToFloat(tdo.input.performance);

            dispatch(updateProject({id: item.id}, tdo.input, td.input, vehicle.input, brand.input, manufacturer.input, API_PROJECT_SUCCESS_NODIRTY));
            this.setState({closeAfterSave: true});
        }
    }

}

function mapStateToProps(state, ownProps) {

    const { projectById, account } = state
    const { isUpdating, isFetching, isFailed, isDirty, lastUpdated, lastFetch, item, tdo, td, vehicle, brand, manufacturer } = projectById[
        ownProps.match.params.id
    ] || {
            isFetching: true,
            item: {},
        }

    const { token } = account || {
        token: null
    }

    return {
        token,
        item,
        tdo,
        td,
        vehicle,
        brand,
        manufacturer,
        isFetching,
        isFailed,
        isDirty,
        lastUpdated,
        lastFetch,
        isUpdating,
    };
}

export default connect(mapStateToProps)(withRouter(BeProject))
