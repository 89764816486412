import config from '../config/config'
import { createAction } from 'redux-api-middleware';

export const CHANGE_EXPENSE = 'CHANGE_EXPENSE'
export const NEW_EXPENSE = 'NEW_EXPENSE'
export const API_EXPENSE_REQUEST = 'API_EXPENSE_REQUEST'
export const API_EXPENSE_SUCCESS = 'API_EXPENSE_SUCCESS'
export const API_EXPENSE_FAILURE = 'API_EXPENSE_FAILURE'
export const API_EXPENSE_DELETE_REQUEST = 'API_EXPENSE_DELETE_REQUEST'
export const API_EXPENSE_DELETE_SUCCESS = 'API_EXPENSE_DELETE_SUCCESS'
export const API_EXPENSE_DELETE_FAILURE = 'API_EXPENSE_DELETE_FAILURE'
export const API_EXPENSE_LIST_REQUEST = 'API_EXPENSE_LIST_REQUEST'
export const API_EXPENSE_LIST_SUCCESS = 'API_EXPENSE_LIST_SUCCESS'
export const API_EXPENSE_LIST_FAILURE = 'API_EXPENSE_LIST_FAILURE'
export const API_POST_EXPENSE_REQUEST = 'API_POST_EXPENSE_REQUEST'
export const API_POST_EXPENSE_SUCCESS = 'API_POST_EXPENSE_SUCCESS'
export const API_POST_EXPENSE_FAILURE = 'API_POST_EXPENSE_FAILURE'


export function discardExpense(id, project) {
    return (dispatch, getState) => {
        const expenselist = getState().expensesByParent['project::'+project];
        var item = Object.values(expenselist.items).filter(item => item.id === id)[0];

        delete item['input'];
        item['input'] = JSON.parse(JSON.stringify(item));

        dispatch(changeExpense(item));
    }
}

export function newExpense(project, name, value) {
    return {
        type: NEW_EXPENSE,
        meta: { project },
        payload: {
            [name]: value,
        }
    }
}

export function postExpense(project, inputTitle, inputDesc, inputPrice, inputBill, inputPart, inputMercant, inputMedia, inputProjectpart) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/expense`,
        method: 'POST',
        body: JSON.stringify({
            project,
            title: inputTitle,
            description: inputDesc,
            price: inputPrice,
            bill: inputBill,
            part: inputPart,
            mercant: inputMercant,
            media: inputMedia,
            projectPart: inputProjectpart,
        }),
        types: [
            {type: API_POST_EXPENSE_REQUEST, meta: {project}},
            {type: API_POST_EXPENSE_SUCCESS, meta: {project, inputPart}},
            {type: API_POST_EXPENSE_FAILURE, meta: {project}},
        ]
    })
}

export function changeExpense(item) {
    return {
        type: CHANGE_EXPENSE,
        meta: {
            project: item.project,
        },
        payload: {
            item,
        }
    }
}

export function updateExpense(item) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/expense/${item.id}`,
        method: 'PUT',
        body: JSON.stringify({
            expense: item,
        }),
        types: [
            {type: API_EXPENSE_REQUEST, meta: { project: item.project }},
            {type: API_EXPENSE_SUCCESS, meta: { project: item.project }},
            {type: API_EXPENSE_FAILURE, meta: { project: item.project }},
        ]
    })
}

export function deleteExpense(project, id) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/expense/${id}`,
        method: 'DELETE',
        types: [
            {type: API_EXPENSE_DELETE_REQUEST, meta: { project, id }},
            {type: API_EXPENSE_DELETE_SUCCESS, meta: { project, id }},
            {type: API_EXPENSE_DELETE_FAILURE, meta: { project, id }},
        ]
    })
}

export function fetchExpenseList(project) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/expense?project=${project}`,
        method: 'GET',
        types: [
            {type: API_EXPENSE_LIST_REQUEST, meta: { project }},
            {type: API_EXPENSE_LIST_SUCCESS, meta: { project }},
            {type: API_EXPENSE_LIST_FAILURE, meta: { project }},
        ]
    })
}

function shouldFetchExpenseList(state, project) {
    const expenselist = state.expensesByParent['project::'+project];
    if (
        !expenselist ||
        ((expenselist.isFetching || expenselist.isFailed) && Date.now() - expenselist.lastFetch > config.FETCH_TIMEOUT) ||
        (!expenselist.isFetching && Date.now() - (expenselist.lastUpdated ? expenselist.lastUpdated : 0) > config.FETCH_AGAIN)
    ) {
        return true
    }
    return false
}

export default function fetchExpenseListIfNeeded(project) {
    return (dispatch, getState) => {

        if (shouldFetchExpenseList(getState(), project)) {
            return dispatch(fetchExpenseList(project))
        }
    }
}

export function fetchExpenseListIfDirty(project) {
    return (dispatch, getState) => {
        const expenselist = getState().expensesByParent['project::'+project];

        if (!expenselist || expenselist.isDirty) {
            return dispatch(fetchExpenseList(project));
        }
    }
}
