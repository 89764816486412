import config from '../config/config'
import { createAction } from 'redux-api-middleware';

export const CHANGE_PROJECT = 'CHANGE_PROJECT'
export const CHANGE_PROJECT_NODIRTY = 'CHANGE_PROJECT_NODIRTY'
export const API_PROJECT_REQUEST = 'API_PROJECT_REQUEST'
export const API_PROJECT_SUCCESS = 'API_PROJECT_SUCCESS'
export const API_PROJECT_SUCCESS_NODIRTY = 'API_PROJECT_SUCCESS_NODIRTY'
export const API_PROJECT_FAILURE = 'API_PROJECT_FAILURE'
export const API_PROJECT_DELETE_SUCCESS = 'API_PROJECT_DELETE_SUCCESS'
export const API_PROJECT_LIST_REQUEST = 'API_PROJECT_LIST_REQUEST'
export const API_PROJECT_LIST_SUCCESS = 'API_PROJECT_LIST_SUCCESS'
export const API_PROJECT_LIST_FAILURE = 'API_PROJECT_LIST_FAILURE'
export const RESET_PROJECT_LIST = "RESET_PROJECT_LIST";
export const API_USERPROJECT_REQUEST = "API_USERPROJECT_REQUEST";
export const API_USERPROJECT_SUCCESS = "API_USERPROJECT_SUCCESS";
export const API_USERPROJECT_FAILURE = "API_USERPROJECT_FAILURE";
export const API_FAVPROJECT_REQUEST = "API_FAVPROJECT_REQUEST";
export const API_FAVPROJECT_SUCCESS = "API_FAVPROJECT_SUCCESS";
export const API_FAVPROJECT_FAILURE = "API_FAVPROJECT_FAILURE";
export const API_NEW_PROJECT_LIST_REQUEST = "API_NEW_PROJECT_LIST_REQUEST"
export const API_NEW_PROJECT_LIST_SUCCESS = "API_NEW_PROJECT_LIST_SUCCESS"
export const API_NEW_PROJECT_LIST_FAILURE = "API_NEW_PROJECT_LIST_FAILURE"
export const NEW_STORY = "NEW_STORY"
export const API_POST_STORY_REQUEST = "API_POST_STORY_REQUEST"
export const API_POST_STORY_SUCCESS = "API_POST_STORY_SUCCESS"
export const API_POST_STORY_FAILURE = "API_POST_STORY_FAILURE"
export const API_LIKE_PROJECT_REQUEST = "API_LIKE_PROJECT_REQUEST";
export const API_LIKE_PROJECT_SUCCESS = "API_LIKE_PROJECT_SUCCESS";
export const API_LIKE_PROJECT_FAILURE = "API_LIKE_PROJECT_FAILURE";
export const API_FOLLOW_PROJECT_REQUEST = "API_FOLLOW_PROJECT_REQUEST";
export const API_FOLLOW_PROJECT_SUCCESS = "API_FOLLOW_PROJECT_SUCCESS";
export const API_FOLLOW_PROJECT_FAILURE = "API_FOLLOW_PROJECT_FAILURE";
export const API_PROJECT_DIRECT_REQUEST = "API_PROJECT_DIRECT_REQUEST";
export const API_PROJECT_DIRECT_SUCCESS = "API_PROJECT_DIRECT_SUCCESS";
export const API_PROJECT_DIRECT_FAILURE = "API_PROJECT_DIRECT_FAILURE";
export const API_PROJECT_UPDATE_REQUEST = 'API_PROJECT_UPDATE_REQUEST';
export const API_PROJECT_UPDATE_SUCCESS = 'API_PROJECT_UPDATE_SUCCESS';
export const API_PROJECT_UPDATE_FAILURE = 'API_PROJECT_UPDATE_FAILURE';


export function discardTechnicalData(id) {
    return (dispatch, getState) => {
        var project = getState().projectById[id];

        delete project.tdo['input'];
        project.tdo['input'] = JSON.parse(JSON.stringify(project.tdo));

        delete project.td['input'];
        project.td['input'] = JSON.parse(JSON.stringify(project.td));

        delete project.vehicle['input'];
        project.vehicle['input'] = JSON.parse(JSON.stringify(project.vehicle));

        delete project.brand['input'];
        project.brand['input'] = JSON.parse(JSON.stringify(project.brand));

        delete project.manufacturer['input'];
        project.manufacturer['input'] = JSON.parse(JSON.stringify(project.manufacturer));

        dispatch(changeProject(project.item, project.tdo, project.td, project.vehicle, project.brand, project.manufacturer, CHANGE_PROJECT_NODIRTY));
    }
}

export function directProjectUpdate(id, key, value) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/project/${id}`,
        method: 'PUT',
        body: JSON.stringify({
            project: {
                [key]: value,
            },
        }),
        types: [
            {type: API_PROJECT_DIRECT_REQUEST, meta: { id }},
            {type: API_PROJECT_DIRECT_SUCCESS, meta: { id }},
            {type: API_PROJECT_DIRECT_FAILURE, meta: { id }},
        ]
    })
}

export function newStory(id, name, value) {
    return {
        type: NEW_STORY,
        meta: { id },
        payload: {
            [name]: value,
        }
    }
}

export function postStory(id, inputTitle, inputDesc, inputCategory, inputMedia) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/projectpart`,
        method: 'POST',
        body: JSON.stringify({
            project: id,
            title: inputTitle,
            description: inputDesc,
            category: inputCategory,
            mediamain: inputMedia,
        }),
        types: [
            {type: API_POST_STORY_REQUEST, meta: {id}},
            {type: API_POST_STORY_SUCCESS, meta: {id}},
            {type: API_POST_STORY_FAILURE, meta: {id}},
        ]
    })
}

// TODO: Check if updateParent is obsolete
export function updateParent(id) {
    return (dispatch, getState) => {
        // Update project
        const project = getState().projectById[id];
        dispatch(updateProject(project.item, project.tdo, project.td, project.vehicle, project.brand, project.manufacturer));
    }
}

// TODO: Check if fetchParent is obsolete
export function fetchParent(id) {
    return (dispatch, getState) => {
        // Fetch project
        dispatch(fetchProject(id));
    }
}

export function changeProject(item, tdo, td, vehicle, brand, manufacturer, action) {
    return {
        type: (action ? action : CHANGE_PROJECT),
        meta: {
            id: item.id,
        },
        payload: {
            item,
            tdo,
            td,
            vehicle,
            brand,
            manufacturer,
        }
    }
}

export function updateProject(item, tdo, td, vehicle, brand, manufacturer, action) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/project/${item.id}`,
        method: 'PUT',
        body: JSON.stringify({
            project: item,
            technicaldata: td,
            technicaldataOverride: tdo,
            vehicle: vehicle,
            brand: brand,
            manufacturer: manufacturer,
        }),
        types: [
            {type: API_PROJECT_REQUEST, meta: { id: item.id }},
            {type: (action ? action : API_PROJECT_SUCCESS), meta: { id: item.id }},
            {type: API_PROJECT_FAILURE, meta: { id: item.id }},
        ]
    })
}

export function deleteProject(id) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/project/${id}`,
        method: 'DELETE',
        types: [
            {type: API_PROJECT_REQUEST, meta: { id }},
            {type: API_PROJECT_DELETE_SUCCESS, meta: { id }},
            {type: API_PROJECT_FAILURE, meta: { id }},
        ]
    })
}

export function fetchProjectList(options, page, size) {
    if (!options) {options = {}}
    if (!page) {page = 1}
    if (!size) {size = 20}

    var query = '';
    for (let [key, value] of Object.entries(options)) {
        query += '&'+encodeURIComponent(key)+'='+encodeURIComponent(value);
    }

    return createAction({
        endpoint: `${config.API_HOST}/v1/project?page=${page}&size=${size}${query}`,
        method: 'GET',
        types: [
            {type: API_PROJECT_LIST_REQUEST, meta: { page }},
            {type: API_PROJECT_LIST_SUCCESS, meta: { page }},
            {type: API_PROJECT_LIST_FAILURE, meta: { page }},
        ]
    })
}

function shouldFetchProjectList(state) {
    const projectlist = state.listByEntity['project'];
    if (
        !projectlist ||
        ((projectlist.isFetching || projectlist.isFailed) && Date.now() - projectlist.lastFetch > config.FETCH_TIMEOUT)
    ) {
        return true
    }
    return false
}

export function fetchProjectListIfNeeded(options, page, size) {
    return (dispatch, getState) => {
        if (shouldFetchProjectList(getState())) {
            return dispatch(fetchProjectList(options, page, size))
        }
    }
}

export function fetchUserProjects(userId){
    return createAction({
        endpoint: `${config.API_HOST}/v1/project?user=${userId}`,
        method: 'GET',
        types: [
            {type: API_USERPROJECT_REQUEST, meta: { userId }},
            {type: API_USERPROJECT_SUCCESS, meta: { userId }},
            {type: API_USERPROJECT_FAILURE, meta: { userId }},
        ]
    })
}

function shouldFetchUserProjects(state, id){
    const projectlist = state.listByEntity[id+'::userProjects'];
    if (
        !projectlist ||
        ((projectlist.isFetching || projectlist.isFailed) && Date.now() - projectlist.lastFetch > config.FETCH_TIMEOUT) ||
        (!projectlist.isFetching && Date.now() - (projectlist.lastUpdated ? projectlist.lastUpdated : 0) > config.FETCH_AGAIN)
    ) {
        return true
    }
    return false
}

export function fetchUserProjectsIfNeeded(id) {
    return (dispatch, getState) => {
        if (shouldFetchUserProjects(getState(), id)) {
            return dispatch(fetchUserProjects(id))
        }
    }
}

export function fetchNewProjectList() {
    const order = config.ORDER_NEW;
    const page = 1;
    const size = 5;

    return createAction({
        endpoint: `${config.API_HOST}/v1/project?project_list_order=${order}&page=${page}&size=${size}`,
        method: 'GET',
        types: [
            {type: API_NEW_PROJECT_LIST_REQUEST, meta: { page }},
            {type: API_NEW_PROJECT_LIST_SUCCESS, meta: { page }},
            {type: API_NEW_PROJECT_LIST_FAILURE, meta: { page }},
        ]
    })
}

function shouldFetchNewProjectList(state) {
    const projectlist = state.listByEntity['newProjectsList'];
    if (
        !projectlist ||
        ((projectlist.isFetching || projectlist.isFailed) && Date.now() - projectlist.lastFetch > config.FETCH_TIMEOUT) ||
        (!projectlist.isFetching && Date.now() - (projectlist.lastUpdated ? projectlist.lastUpdated : 0) > config.FETCH_AGAIN)
    ) {
        return true
    }
    return false
}

export function fetchNewProjectListIfNeeded(){
    return (dispatch, getState) => {
        if (shouldFetchNewProjectList(getState())) {
            return dispatch(fetchNewProjectList())
        }
    }
}

export function resetProjectList(){
    return {
        type: RESET_PROJECT_LIST
    }
}

export function fetchProject(id) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/project/${id}`,
        method: 'GET',
        types: [
            {type: API_PROJECT_REQUEST, meta: { id }},
            {type: API_PROJECT_SUCCESS, meta: { id }},
            {type: API_PROJECT_FAILURE, meta: { id }},
        ]
    })
}

function shouldFetchProject(state, id) {
    const project = state.projectById[id]
    if (
        !project ||
        ((project.isFetching || project.isFailed) && Date.now() - project.lastFetch > config.FETCH_TIMEOUT) ||
        (!project.isDirty && !project.isFetching && Date.now() - (project.lastUpdated ? project.lastUpdated : 0) > config.FETCH_AGAIN)
    ) {
        return true
    }
    return false
}

export default function fetchProjectIfNeeded(id) {
    return (dispatch, getState) => {
        if (shouldFetchProject(getState(), id)) {
            return dispatch(fetchProject(id))
        }
    }
}

export function fetchFavProjects(){
    return createAction({
        endpoint: `${config.API_HOST}/v1/project?fav=1`,
        method: 'GET',
        types: [
            {type: API_FAVPROJECT_REQUEST},
            {type: API_FAVPROJECT_SUCCESS},
            {type: API_FAVPROJECT_FAILURE},
        ]
    })
}

function shouldFetchFavProjects(state){
    const favProjects = state.listByEntity['FavProjects'];
    if (
        !favProjects ||
        ((favProjects.favListFetching || favProjects.favListFailed) && Date.now() - favProjects.lastFetch > config.FETCH_TIMEOUT) ||
        (!favProjects.favListFetching && Date.now() - (favProjects.lastUpdated ? favProjects.lastUpdated : 0) > config.FETCH_AGAIN)
    ) {
        return true
    }
    return false
}

export function fetchFavProjectsIfNeeded() {
    return (dispatch, getState) => {
        if (shouldFetchFavProjects(getState())) {
            return dispatch(fetchFavProjects())
        }
    }
}

export function likeProject(id, like, count){
    return createAction({
        endpoint: `${config.API_HOST}/v1/like`,
        method: 'PUT',
        body: JSON.stringify({
            entity: 'project',
            type: 'like',
            id: id,
            value: like,
        }),
        types: [
            {type: API_LIKE_PROJECT_REQUEST, meta: { id, like, count }},
            {type: API_LIKE_PROJECT_SUCCESS, meta: { id, like, count }},
            {type: API_LIKE_PROJECT_FAILURE, meta: { id, like, count }}
        ],
    })
}

export function followProject(id, follow){
    return createAction({
        endpoint: `${config.API_HOST}/v1/like`,
        method: 'PUT',
        body: JSON.stringify({
            entity: 'project',
            type: 'follow',
            id: id,
            value: follow,
        }),
        types: [
            {type: API_FOLLOW_PROJECT_REQUEST, meta: { id, follow }},
            {type: API_FOLLOW_PROJECT_SUCCESS, meta: { id, follow }},
            {type: API_FOLLOW_PROJECT_FAILURE, meta: { id, follow }}
        ],
    })
}

export function checkForUpdates(options) {
    if (!options) {options = {}}
    var page = 1;
    var size = 1;

    var query = '';
    for (let [key, value] of Object.entries(options)) {
        query += '&'+encodeURIComponent(key)+'='+encodeURIComponent(value);
    }

    return createAction({
        endpoint: `${config.API_HOST}/v1/project?page=${page}&size=${size}${query}`,
        method: 'GET',
        types: [
            {type: API_PROJECT_UPDATE_REQUEST, meta: { page, key: 'project' }},
            {type: API_PROJECT_UPDATE_SUCCESS, meta: { page, key: 'project' }},
            {type: API_PROJECT_UPDATE_FAILURE, meta: { page, key: 'project' }},
        ]
    })
}

function shouldCheckForUpdates(state) {
    const projectlist = state.listByEntity['project'];
    if (
        projectlist &&
        projectlist.hasUpdate === false &&
        (Date.now() - projectlist.hasUpdateLastFetch > config.FETCH_TIMEOUT)
    ) {
        return true
    }
    return false
}

export function checkForUpdatesIfNeeded(options) {
    return (dispatch, getState) => {
        if (shouldCheckForUpdates(getState())) {
            return dispatch(checkForUpdates(options))
        }
    }
}
