import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Container, Divider, Message, Responsive, Header, Grid, Placeholder} from 'semantic-ui-react';
import Slider from "react-slick";

import { fetchFavProjectsIfNeeded } from '../../actions/project';
import ListitemProject from '../project/list/listitem-project';
import config from '../../config/config';


class DashboardModuleFavorites extends Component {

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(fetchFavProjectsIfNeeded());
    }

    render() {
        const { favProjectsList, favListFetching, favListFailed } = this.props;

        if (!favProjectsList.length && favListFetching) {
            return (
                <div>
                    {this.placeholder()}
                </div>
            );
        }

        if (favListFailed) {
            return (
                <Container text>
                    <Divider hidden/>
                    <Message negative>Beim Abrufen der Daten ist ein Fehler aufgetreten.</Message>
                </Container>
            )
        }

        if(!favProjectsList.length && !favListFetching){
            return (
                <div></div>
            )
        }

        if (favProjectsList.length) {
            return (
                <div className="dashboard-projects-slideshow">
                    <Divider hidden/>
                    
                    <Responsive {...Responsive.onlyMobile}>
                        <Container>
                            <Header as='h2' >Updates verfolgter Projekte</Header>
                            <Divider hidden />
                        </Container>
                    </Responsive>

                    <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                        <Header as='h2'>Updates verfolgter Projekte</Header>
                        <Divider hidden />
                    </Responsive>

                    <Slider {...config.SLIDE_DB_SETTINGS}>
                        {favProjectsList.map((item, index) => (
                            <div key={index}>
                                <ListitemProject
                                    projectId={item.item.id}
                                    projectTitle={item.item.title}
                                    userImg={item.item.userData.mediaavatarPath}
                                    projectMainImage={item.item.mediamainPath}
                                    projectSlug={item.item.urlslug}
                                    linktype={item.item.linktype}
                                />
                            </div>
                        ))}
                    </Slider>
                </div>
            );
        }

        return null;
    }

    placeholder(){
        return (
            <>
                <div className="dashboard-slider-placeholder">
                    <Divider hidden/>
                    <Container>
                        <Header as='h2' className="text-placeholder" >Updates verfolgter Projekte</Header>
                        <Divider hidden />
                        <Grid columns="equal">

                            <Grid.Column>
                                <Placeholder className="slides-placeholder">
                                    <Placeholder.Image />
                                </Placeholder>
                            </Grid.Column>

                            <Grid.Column>
                                <Placeholder className="slides-placeholder">
                                    <Placeholder.Image />
                                    </Placeholder>
                            </Grid.Column>

                            <Grid.Column>
                                <Placeholder className="slides-placeholder">
                                    <Placeholder.Image />
                                    </Placeholder>
                            </Grid.Column>

                        </Grid>

                    </Container>
                </div>
            </>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { listByEntity,  projectById} = state;
    const { list: favList, favListFetching, favListFailed } = listByEntity['FavProjects'] || {};

    let favProjectsList = [];

    //Favourite projects
    if(favList){
        for (let [key, value] of Object.entries(favList)) {
            favProjectsList.push(projectById[value]);
        }
    }

    return {
        favProjectsList,
        favListFetching,
        favListFailed,
    }
}

export default connect(mapStateToProps)(DashboardModuleFavorites);
