import React, { Component } from "react";
import { connect } from "react-redux";
import Select from 'react-select';
// import AsyncSelect from 'react-select/async';
import { Link, withRouter } from "react-router-dom";
import config from '../../config/config';
import { motion, AnimatePresence } from "framer-motion";
import { RemoveScrollBar } from 'react-remove-scroll-bar'
import {
    Button, Container, Form, Loader, Label, Icon, Grid, Divider, Message } from "semantic-ui-react";
import {
    footerActiveItem,
    getUserCoords,
    handleContainer,
    handleHeaderItems,
    setRuntime,
    toggleProfessionalListView
} from "../../actions/global";
import { handleProfessionalFilter, handleProfessionalFilterTags } from '../../actions/filter';
import Helmet from 'react-helmet';
import '../Pages.scss';

//Images /
import FilterSearch from "../../components/FilterSearch/FilterSearch";
import Card from "../../components/Card/Card";
import ListIcon from '../../images/icons/frontend/icon-liste.svg';
import ListIconActive from '../../images/icons/frontend/icon-liste-mo.svg';
import MapIcon from '../../images/icons/icon-map.svg';
import MapIconActive from '../../images/icons/frontend/icon-map-hover.svg';
import Tagging from "../../components/Tagging/Tagging";
import { resetProfessionalList, fetchProfessionalList } from './../../actions/professional';
import InfoHandler from "../../components/ErrorHandler/InfoHandler";
import ErrorHandler from "../../components/ErrorHandler/ErrorHandler";
import LeafletMap from "../../components/LeafletMap/LeafletMap";
import Autosuggestion from "../../components/AutoSuggestion/Autosuggestion";
import getLocation, { getReverseLocation } from './../../actions/geolocation';

class SpecialistsOverview extends Component {

  state = {
    userPosition: {
      lat: null,
      long: null,
    },
    city: [],
    userPositionError: false,
    mapZoom: 12,
    lightboxIsOpen: false,
    currentId: 0,
    open: false,
    filterSearchIsOpen: false,
    zoomToLocation: false,
    requestUserLocation: false,
    timeOut: null,
    locationError: null,
    zoomLevel: 6,
    newZoom: 13,
    searchingLocation: false,
    retryLocation: false,
    choosedCity: false //Prevent from sending an request if suggestion was choosed 
  };

  timer = null;

  componentDidMount = () => {
    const { dispatch, currentView, options } = this.props;
    dispatch(fetchProfessionalList(options));
    dispatch(footerActiveItem('footermenu'));
    dispatch(
      handleContainer("newsfeed", "Spezialisten", null, null, null, null, {
        description: "Erfahre hier mit welchen Funktionen Vetero nicht nur Oldtimer Schrauber unterstützt. Von der Präsentation deiner Oldtimer, den Austausch mit anderen Oldtimer Begeisterten bis zur Auswertung der Arbeitszeit und der Kosten."
    })
      //TODO: Meta description and page title
    );
    dispatch(handleHeaderItems("professional"));
    // Set page
    dispatch(setRuntime({
        page: 'professionals-overview'
    }));

    //Get user location in map view on mount
    if(currentView === "map"){
      this.getUserLocation();
    }

    document.getElementById('root').addEventListener('click', this.handleClick, false);

    //Append Google Places script to the body 
    const script = document.createElement("script");
    
    script.src = `https://maps.googleapis.com/maps/api/js?key=${config.GOOGLE_API_KEY}&libraries=places`;
    script.async = true;

    //add only once 
    if(!document.body.contains(script)) {
      document.body.appendChild(script)
    }
    
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { dispatch, professionals, options, isFetching, isFailed, currentView, city, order, coords, cityObject, userLat, userLong } = this.props;
    const { userPosition, choosedCity, retryLocation } = this.state;

    if(retryLocation !== prevState.retryLocation) {
      this.handleOnClickCityOption(cityObject[0]);
      this.setState({ retryLocation: false })
    }

    //Get user location in map view
    if(currentView === "map" && (prevProps.currentView !== currentView) && !userLat){
      this.getUserLocation();
    }

    if(!isFetching && !isFailed && prevProps.professionals !== professionals && !professionals.length) {
        dispatch(fetchProfessionalList(options, false, false, order));
    }

    //Try to fetch professionals list again, if the first try wasn't successful (sorted by distance not possible)
    if (isFailed && order === config.ORDER_DISTANCE && !isFetching && !professionals.length) {
      dispatch(fetchProfessionalList(options, false, false, config.ORDER_NAME)); //Sort by name
    }


    /*if (hasUpdateLastFetch && (Date.now() - hasUpdateLastFetch > config.PING_TIMEOUT)) {
        dispatch(checkForUpdatesIfNeeded(options));
    }*/


    //Check for project view change
    if(prevProps.currentView !== currentView){
        window.scroll(0, 0)
    }

    //Request user location
    if(userLat !== prevProps.userLat) {
      dispatch(getReverseLocation(userLat, userLong));

      this.setState({ requestUserLocation: false })
    }

    //Update city list
    if(city && city !== prevProps.city && !choosedCity){
      this.setState({
        timer: setTimeout(() => {dispatch(getLocation(city)) }, 1300),
        choosedCity: false
      }) 
      clearTimeout(this.state.timer);
    }

    //Handle closing project lightbox by clicking on overlay 
    document.getElementById('root').addEventListener('click', this.handleClick, false);

  }

  componentWillUnmount = () => {
    const { dispatch } = this.props;

    dispatch(setRuntime({
        backProfessional: {page: 'professional-overview', path: this.props.location, scroll: window.pageYOffset},
    }));

    //document.getElementById('root').removeEventListener('click', this.handleClick, false);

  }

  handleAutosuggestionPlace = (e) => {
    const { city } = this.props;
    var input = e.target
      var autocomplete = new window.google.maps.places.Autocomplete(input,{types: ['(cities)']});
      autocomplete.addListener(autocomplete, 'place_changed', function(){
         var place = autocomplete.getPlace();
         console.log(place)
      })
      
  }

   //Handle closing project lightbox by clicking on overlay
   handleClickOutside = () => {
    this.setState({projectLightbox: { isOpen: false, projectId: 0 }})
  }

  //Handle closing project lightbox by clicking on overlay
  handleClick = (e) => {
      document.getElementById('root').addEventListener('click', this.handleClick, false);
      if(e.target.className === "leaflet-marker-icon leaflet-zoom-animated leaflet-interactive" || e.target.className !== "project-lightbox" ){
          return;
      }
      this.handleClickOutside();
  }

  //Handle closing project lightbox by clicking on overlay
  handleClickOutside = () => {
    this.setState({
      lightboxIsOpen: false,
      currentId: 0
  });
  }

  getUserLocation = () => {

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.showPosition, this.handleGeolocationError, {...config.GEOLOCATION_SETTINGS});
    } else {
       this.setState({ geolocationError: "Aktivieren Sie den Standort um die Karte anzuzeigen." })
    }

  }

  handleGeolocationError = (error) => {
    const { dispatch } = this.props;
    switch(error.code) {
      case error.PERMISSION_DENIED:
        dispatch(getUserCoords(null, "Erlaube den Standortzugriff um die Karte anzuzeigen."))
        // this.setState({ userPositionError: "Erlaube den Standortzugriff um die Karte anzuzeigen." })
        break;
      case error.POSITION_UNAVAILABLE:
        dispatch(getUserCoords(null, "Es ist ein Fehler bei der Standortermittlung aufgetreten. Versuche es nochmal."))
        // this.setState({ userPositionError: "Es ist ein Fehler bei der Standortermittlung aufgetreten. Versuche es nochmal." })
        break;
      case error.TIMEOUT:
        dispatch(getUserCoords(null, "Es ist ein Fehler bei der Standortermittlung aufgetreten. Versuche es nochmal."))
        // this.setState({ userPositionError: "Es ist ein Fehler bei der Standortermittlung aufgetreten. Versuche es nochmal." })
        break;
      case error.UNKNOWN_ERROR:
        dispatch(getUserCoords(null, "Es ist ein Fehler bei der Standortermittlung aufgetreten. Versuche es nochmal."))
        // this.setState({ userPositionError: "Es ist ein Fehler bei der Standortermittlung aufgetreten. Versuche es nochmal." })
        break;
    }
  }

  showPosition = (position) => {
    const { dispatch } = this.props;
    dispatch(getUserCoords(position, "OK"))
    // this.setState({
    //   userPosition: {
    //     lat: position.coords.latitude,
    //     long: position.coords.longitude
    //   }
    // })
  }

  handleAddTag = (tag) => {
    const { dispatch, inputTags } = this.props;
    let tags = inputTags ? inputTags : [];

    dispatch(handleProfessionalFilterTags([...tags, tag]))
  }

  handleDeleteTag = (tag) => {
      const { dispatch, inputTags } = this.props;
      let tags = inputTags && inputTags.filter(item => item.value !== tag.value);
      dispatch(handleProfessionalFilterTags(tags))
  }

  handleDirectDeleteTag = (tag) => {
      const { dispatch, inputTags } = this.props;
      let tags = inputTags && inputTags.filter(item => item.value !== tag.value);

      dispatch(handleProfessionalFilterTags(tags));
  }

  handleRevertTag = () => {
      const { dispatch, categories } = this.props;
      let tags = categories ? categories : [];

      //Reset all tags
      dispatch(handleProfessionalFilterTags(tags));
      this.setState({ filterSearchIsOpen: false })
  }

  handleSaveTag = () => {
      const { dispatch, inputTags } = this.props;
      let tags = inputTags ? inputTags : [];

      dispatch(handleProfessionalFilterTags(tags));
      dispatch(resetProfessionalList())
      this.setState({ filterSearchIsOpen: false })
  }

  retryGetLocation = async () => {
    const { dispatch, city, cityObject } = this.props;

    await dispatch(getLocation(city));

    //Jump to location 
    if(cityObject && cityObject.length && cityObject !== "FETCHING") {
      this.setState({ 
        locationError: null,
        searchingLocation: false,
        retryLocation: true
      })
    }
    else {
      this.setState({ searchingLocation: false })
      return this.setState({ locationError: "Ort wurde nicht gefunden." })
    }

  }

  showFilterResults = async () => {
    const { dispatch, inputTags, coords, currentCity, city, cityObject, type, scope, radius } = this.props;
    const { zoomLevel } = this.state;

    let tags = inputTags ? inputTags : [];

    //Check if city wasn't choosed from the suggestion list
    if(city && (city !== currentCity)) {
      this.setState({ searchingLocation: true })
      await this.retryGetLocation();

    }

    //Check if location field is empty
    if(!city) {
      this.setState({ locationError: null })
      dispatch(handleProfessionalFilter(type, scope, "", coords, radius, ""));
  
      this.setState({ newZoom: zoomLevel, zoomToLocation: true }) // Set zoom to default
    }

    dispatch(handleProfessionalFilterTags(tags));
    dispatch(resetProfessionalList())
    this.setState({ filterSearchIsOpen: false });

    if(city && coords && coords.length > 0){
      this.setState({ zoomToLocation: true, newZoom: 13 })
    }

  }

  handleTypeOnChange = (e) => {
    const { dispatch, scope, city, radius, coords, currentCity } = this.props;

    dispatch(handleProfessionalFilter(e, scope, city, coords, radius, currentCity));
    //dispatch(resetProfessionalList());
}

  handleScopeOnChange = (e) => {
      const { dispatch, type, city, radius, coords, currentCity } = this.props;
      //let tags = categories ? categories : [];

      dispatch(handleProfessionalFilter(type, e, city, coords, radius, currentCity));
      //dispatch(resetProfessionalList());
  }

  handleProfessionalListView = (view) => {
    const { dispatch } = this.props;
    dispatch(toggleProfessionalListView(view));
  }

  handleProfessionalLightbox = (id) => {
    const { dispatch, type, scope, city, currentCity, radius } = this.props;

    this.setState({
        lightboxIsOpen: true,
        currentId: id,
    });

    //Reset current coords...
    dispatch(handleProfessionalFilter(type, scope, city, "", radius, currentCity));

  }

  handleCityInput = (e) => {
    const { dispatch, type, scope, coords, radius, currentCity } = this.props;
    clearTimeout(this.timer)
    e.persist(); //INFO: https://reactjs.org/docs/legacy-event-pooling.html
    this.setState({ choosedCity: false });
    dispatch(handleProfessionalFilter(type, scope, e.target.value, coords, radius, currentCity));
  }

  handleOnClickCityOption = (item) => {
    const { dispatch, type, scope, radius } = this.props;  
    const { zoomToLocation } = this.state;  
    //Prevent from instant zoom to new location
    if(zoomToLocation){
      this.setState({ zoomToLocation: false })
    }

    this.setState({ choosedCity: true })
    this.setState({ locationError: null })

    dispatch(handleProfessionalFilter(type, scope, item.display_name, [item.lat, item.lon], radius, item.display_name));

  }

  handleCityInputGetLocation = () => {
 
    this.getUserLocation();
    this.setState({ requestUserLocation: true })
    
  }


  handleLoadCityList = async () => {
    const { city, dispatch } = this.props;

    dispatch(getLocation(city))
    
  }

  handleRadiusInput = (e) => {
    const { dispatch, type, scope, city, coords, currentCity } = this.props;
    dispatch(handleProfessionalFilter(type, scope, city, coords, e, currentCity));
  }

  handleFetchProfessionals = () => {
    const { dispatch, options, order } = this.props;
    dispatch(fetchProfessionalList(options, false, false, order));
  }

  render() {
    const { professionals, dispatch, cityObject, coords, isFetching, scope, city, type, radius, inputTags, currentView, status, isFailed, userLat, userLong, positionStatus } = this.props;
    const { currentId, lightboxIsOpen, userPosition, filterSearchIsOpen, userPositionError, locationError, searchingLocation, zoomToLocation, requestUserLocation, zoomLevel, newZoom } = this.state;

    return (
    <div className="specialists-page">
        <Helmet>
          <title>Vetero - Spezialisten</title>
          <meta name="description" content="Vetero Spezialisten" />
        </Helmet>

        {/* FILTER */}
        <div className="filter-section">
            <FilterSearch
              filterHeader="Filter"
              switchViews={true}
              currentView={currentView}
              filterOpen={filterSearchIsOpen}
              onRequestOpen={() => this.setState({ filterSearchIsOpen: true })}
              onRequestClose={() => this.setState({ filterSearchIsOpen: false })}
              onRequestSwitchView={this.handleProfessionalListView}
              SwitchItem={
                {
                  icon: MapIcon,
                  iconActive: MapIconActive,
                  name: 'map'
                }
              }
              SwitchItem2={
                {
                  icon: ListIcon,
                  iconActive: ListIconActive,
                  name: 'list'
                }
              }
            >

                <Grid textAlign="center">
                  <Grid.Column width={16}>
                      {currentView === "map" && !userLat && userLong && 
                        <div class="mb-1">
                          
                        </div>
                      }
                      <Button.Group>
                        <Button basic className="mr-2" color="red">
                          Zurücksetzen
                        </Button>
                        <Button 
                          color="red" 
                          onClick={this.showFilterResults} 
                          disabled={
                            searchingLocation || (currentView === "map" && !userLat && !userLong)
                          } 
                          loading={searchingLocation}
                        >
                          Anzeigen suchen
                        </Button>
                      </Button.Group>
                  </Grid.Column>
                </Grid>
                <Divider/>
                <Container>
                  <Form>
                      <Form.Group widths={2}>

                          <Form.Field>
                              <label>Was suchst du?</label>
                              <Select
                                  placeholder="Auswählen..."
                                  options={config.getProfessionalType()}
                                  onChange={this.handleTypeOnChange}
                                  value={type}
                                  classNamePrefix="custom-select"
                                  isMulti
                              />
                          </Form.Field>

                          <Form.Field>
                              <label>Fahrzeugtyp</label>
                              <Select
                                  placeholder="Auswählen..."
                                  value={scope}
                                  options={config.getScopes()}
                                  onChange={this.handleScopeOnChange}
                                  classNamePrefix="custom-select"
                                  isMulti
                              />
                          </Form.Field>

                      </Form.Group>

                      <Form.Group widths={2}>
                        <Form.Field error={locationError ? true : false}>
                            <Autosuggestion 
                              label="Ort" 
                              placeholder="Ort..."
                              value={city === "FETCHING" ? "" : city}
                              onChange={this.handleCityInput}
                              options={cityObject && cityObject.length > 0 && cityObject !== "FAILED" && cityObject}
                              onClickOption={this.handleOnClickCityOption}
                              isFetching={cityObject && cityObject === "FETCHING"}
                              // action={{
                              //   content: <Icon loading={coords === "FETCHING" ? true : false} disabled={(coords || requestUserLocation) === "FETCHING" ? true : false} name="map marker alternate"/>,
                              //   onClick: this.handleCityInputGetLocation
                              // }}
                              />
                              {locationError && 
                                <Message color="red">
                                  {locationError}
                                </Message>
                              }
                          </Form.Field>
                          <Form.Field>
                            <label>Umkreis</label>
                            <Select
                              value={radius}
                              placeholder="Auswählen"
                              options={config.getRadius()}
                              onChange={this.handleRadiusInput}
                              classNamePrefix="custom-select"
                            />
                          </Form.Field>
                      </Form.Group>

                  </Form>
                </Container>
                <div className="pt-1">
                  <Tagging
                      handleTag={this.handleAddTag}
                      handleDeleteTag={this.handleDeleteTag}
                      onRequestSave={this.handleSaveTag}
                      onRequestClose={this.handleRevertTag}
                      inputTags={inputTags}
                      hideActionButtons
                    />
                </div>
    
            </FilterSearch>
        </div>

        {/* Show choosed tagging */}
        <Container text className="choosed-tags" style={{marginTop: "1rem"}}>
              <>
              {(inputTags && inputTags.length > 0) && 
                <span><strong>Tags:</strong></span>
              }

                {inputTags && inputTags.length > 0 &&
                  inputTags.map((tag, index) => (
                    <Label
                      as='a'
                      key={index}
                      onClick={() => {
                        this.handleDirectDeleteTag(tag)
                        dispatch(resetProfessionalList())
                      }}
                    >
                      {tag.label}

                      <Icon name="delete"/>
                    </Label>
                    ))}
                </>
                
        </Container>

        {/* SPECIALISTS LIST - map though item list */}
        {currentView !== "map" && 
          <div className="specialists-list pt-2">
            {isFetching && currentView !== "map" && 
            <div className="fetching-list">
              <Container>
                <Loader active/>
              </Container>
            </div>
            }

            {!isFetching && status && status === 404 &&
                <Container text>
                  <InfoHandler errorMessage="Es wurden keine passende Anzeigen gefunden."/>
                </Container>
            }


            {isFailed && !isFetching && (!professionals.length || !professionals) && !status &&
                <Container text>
                  <ErrorHandler callbackFunction={this.handleFetchProfessionals}/>
                </Container>
            }

            <Container>

              {/* LIST VIEW */}
              {currentView === "list" && 
                !isFetching && professionals && professionals.map((item,index) => (
                    <Card 
                      mainImage={item.item.mediamainPath}
                      logo={item.item.medialogoPath}
                      title={item.item.headline}
                      description={item.item.intro}
                      descriptionMeta={item.item.zip + " " + item.item.city + " "+ (item.item.distance ? `(${Math.ceil(item.item.distance/1000)} KM)` : ``)}
                      button={
                        <Button as={Link} to={`/professionals/${item.item.urlslug}/${item.item.id}`} color="red">Mehr zum Spezialist</Button>
                      }
                    />
                ))
              }

            </Container>

          </div>
        }

        {/* MAP VIEW */}
        {currentView === "map" && (
          (userLat !== null && userLong !== null) ?
            <div className="list-map">
              <LeafletMap
                userPosition={[parseFloat(userLat), parseFloat(userLong)]}
                markerGroup={professionals}
                handleMarkerOnClick={this.handleProfessionalLightbox}
                fetchData={this.fetchDataOnZoom}
                zoomLevel={zoomLevel}
                newZoom={newZoom}
                newLocation={zoomToLocation && !filterSearchIsOpen && !lightboxIsOpen && coords}
              />    
            </div>

            :

            <div className="list-map bg-grey-light map-loading">
              <div className="text-center">
                <Icon name="map marker alternate" size="massive"/>
                {positionStatus !== "OK" &&
                  <div className="mt-2 map-message">{positionStatus}</div>
                }
                {!positionStatus &&
                  <div className="mt-2 map-message">Die Karte wird geladen...</div>
                }
              </div>
            </div>

        )}

          {/* Project info - lightbox */}
          <AnimatePresence>
              {lightboxIsOpen &&
                <motion.div
                  className={`project-lightbox`}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0, transition: { duration: 0.3 } }}
                  transition={{ duration: 0.3, delay: 0.15 }}
                  style={{ pointerEvents: "auto" }}
                >

                  {professionals.filter(professional => professional.item.id === currentId).map(item => (
                    <Container className="project-card-wrapper" key={item.item.id}>
                      <RemoveScrollBar/>
                      <Card
                        onClose={() => this.handleClickOutside()}
                        closeButton
                        sumMedias={item.sumMedias}
                        item={item.item}
                        mainImage={item.item.mediamainPath}
                        logo={item.item.medialogoPath}
                        title={item.item.headline}
                        description={item.item.intro}
                        descriptionMeta={item.item.zip + " " + item.item.city}
                        button={
                          <Button as={Link} to={`/professionals/${item.item.urlslug}/${item.item.id}`} color="red">Mehr zum Spezialist</Button>
                        }
                        />
                    </Container>
                  ))}
                </motion.div>
              }
          </AnimatePresence>

    </div>

    );
  }
}

function mapStateToProps(state, ownProps) {
  const { listByEntity, professionalsById, globalByComponent } = state;
  const {
    list,
    isFetching,
    isFailed,
    order,
    status
  } = listByEntity["professional"] || {
      list: {},
      isFetching: true,
      isFailed: false,
      order: config.ORDER_DISTANCE,
      status
  };

  const { currentView } = globalByComponent[
    'ProfessionalList'
  ] || {
      currentView: "list"
  }

  const { userLat, userLong, status: positionStatus } = globalByComponent[
    'UserCoords'
  ] || {
    userLat: null,
    userLong: null,
    positionStatus: null
  }

  const { type, scope, city, cityObject, coords, categories, radius, inputTags, maxDistance, currentCity } = globalByComponent[
    'ProfessionalFilter'
  ] || {
      type: '',
      scope: '',
      city: '',
      coords: '',
      radius:'',
      categories: [],
      inputTags: [],
      maxDistance: '',
      cityObject: {},
      currentCity: ''
  }

  let professionals = [];
  if (list && Object.keys(list).length) {
      professionals = Object.values(list).map(function(professional) {
          return professionalsById[professional];
      });
  }

  var options = {};

  if(type){
    if(type.length > 0) {
        let arr = [];
        type.map(item => arr.push(item.value));
        options['professional_filter[types]'] = arr
    }
  }

  if(scope) {
    if(scope.length > 0) {
      let arr = [];
      scope.map(item => arr.push(item.value));
      options['professional_filter[scopes]'] = arr
    }
  }

  if (coords && coords.length > 0) {
      options['professional_filter[lat]'] = coords[0];
      options['professional_filter[long]'] = coords[1];
  }

  if (radius && radius.value) {
    options['professional_filter[maxDistance]'] = radius.value;
}

  if(inputTags){
    if(inputTags.length > 0) {
        let arr = [];
        inputTags.map(item => arr.push(item.value));
        options['professional_filter[categories]'] = arr
    }
  }


  return {
    professionals,
    isFetching,
    isFailed,
    type,
    scope,
    city,
    coords,
    radius,
    categories,
    inputTags,
    currentView,
    maxDistance,
    currentCity,
    cityObject,
    options,
    order,
    status,
    positionStatus,
    userLat,
    userLong
  };
}

export default connect(mapStateToProps)(withRouter(SpecialistsOverview));
