import React, { Component } from 'react';
import { Card as CardSemanticUI, Grid, Responsive, Header, Image } from 'semantic-ui-react';
import ProgressiveImager from '../helpers/progressive-imager';
import config from '../../config/config';
import Truncate from 'react-truncate';
import closeIcon from '../../images/icons/close-cross-white.svg'
import Moment from 'react-moment';
import './Card.scss';
import UserBreadcrumb from './../UserBreadcrumb/UserBreadcrumb';
import NumericLabel from 'react-pretty-numbers';
/* ICONS */
import heartIcon from "../../images/icons/frontend/icon-heart.svg";
import commentIcon from "../../images/icons/frontend/icon-comment.svg";
import heartIconActive from "../../images/icons/frontend/icon-active-heart.svg";

class FeedCard extends Component {
    render() {

        const { showComments, item, handleLightboxGallery } = this.props;

        return (
            <div className="card feed">

                    <div className="card-header">
                        <UserBreadcrumb
                            meta={true}
                            user={{name: "Amigo"}}
                            userId={1}
                            //project={item.projectData}
                            projectId={item.project}
                        />
                        <div className="card-meta">
                            <Moment local format="DD.MM.YYYY" unix>{item.createDate}</Moment>
                        </div>
                    </div>

                    <div className="card-body">
                        <Truncate lines={20} ellipsis={<>...</>}>
                            {item.text}
                        </Truncate>
                    </div>
            
                    {item.medias && 
                        (item.medias.length > 0 &&
                            <div className="card-image">
                                {item.medias.length === 1 && 
                                
                                    <>
                                        {/* Mobile images */}
                                        <Responsive {...Responsive.onlyMobile} className="main-image full" onClick={() => handleLightboxGallery(item.id, 0)}>
                                                <ProgressiveImager
                                                    src={config.getImageUrl('halfwidth', item.medias[0].path)}
                                                    alt={
                                                        (item.text
                                                        ? item.text.substring(
                                                            0,
                                                            50
                                                            )
                                                        : "Newsfeed") +
                                                        " – Bild: " +
                                                        item.id
                                                    }
                                                /> 
                                            </Responsive>

                                        {/* Desktop images */}
                                        <Responsive minWidth={Responsive.onlyTablet.minWidth} className="main-image full" onClick={() => handleLightboxGallery(item.id, 0)}>
                                            <ProgressiveImager
                                                src={config.getImageUrl('main', item.medias[0].path)}
                                                alt={
                                                    (item.text
                                                    ? item.text.substring(
                                                        0,
                                                        50
                                                        )
                                                    : "Newsfeed") +
                                                    " – Bild: " +
                                                    item.id
                                                }
                                            /> 
                                        </Responsive>
                                    </>
                                }

                                {item.medias.length > 1 && 
                                    <div className="images">

                                        {/* Mobile images */}
                                        <Responsive {...Responsive.onlyMobile} className="main-image" onClick={() => handleLightboxGallery(item.id, 0)}>
                                            <ProgressiveImager
                                                src={config.getImageUrl('halfwidth', item.medias[0].path)}
                                                alt={
                                                    (item.text
                                                    ? item.text.substring(
                                                        0,
                                                        50
                                                        )
                                                    : "Newsfeed") +
                                                    " – Bild: " +
                                                    item.id
                                                }
                                            /> 
                                        </Responsive>

                                        {/* Desktop images */}
                                        <Responsive minWidth={Responsive.onlyTablet.minWidth} className="main-image" onClick={() => handleLightboxGallery(item.id, 0)}>
                                            <ProgressiveImager
                                                src={config.getImageUrl('main', item.medias[0].path)}
                                                alt={
                                                    (item.text
                                                    ? item.text.substring(
                                                        0,
                                                        50
                                                        )
                                                    : "Newsfeed") +
                                                    " – Bild: " +
                                                    item.id
                                                }
                                            /> 
                                        </Responsive>

                                        {/* Mobile images */}
                                        <Responsive {...Responsive.onlyMobile} className="secondary-image" onClick={() => handleLightboxGallery(item.id, 0)}>
                                            <ProgressiveImager
                                                src={config.getImageUrl('halfwidth', item.medias[1].path)}
                                                alt={
                                                    (item.text
                                                    ? item.text.substring(
                                                        0,
                                                        50
                                                        )
                                                    : "Newsfeed") +
                                                    " – Bild: " +
                                                    item.id
                                                }
                                            /> 
                                            {item.medias.length > 2 && (
                                                <div className="image-count-overlay">
                                                    <div className="image-count">
                                                        +
                                                        {item.medias.length -
                                                        2}
                                                    </div>
                                                </div>
                                            )}
                                        </Responsive>

                                        {/* Desktop images */}
                                        <Responsive minWidth={Responsive.onlyTablet.minWidth} className="secondary-image" onClick={() => handleLightboxGallery(item.id, 0)}>
                                            <ProgressiveImager
                                                src={config.getImageUrl('main', item.medias[1].path)}
                                                alt={
                                                    (item.text
                                                    ? item.text.substring(
                                                        0,
                                                        50
                                                        )
                                                    : "Newsfeed") +
                                                    " – Bild: " +
                                                    item.id
                                                }
                                            /> 
                                            {item.medias.length > 2 && (
                                                <div className="image-count-overlay">
                                                    <div className="image-count">
                                                        +
                                                        {item.medias.length -
                                                        2}
                                                    </div>
                                                </div>
                                            )}
                                        </Responsive>

                                    </div>
                                }

                            </div>
                        )
                    }

                    <div className="card-actions">
                        <div className="actions">
                            <span className="action like">
                                <Image src={commentIcon} alt="Icon Like" className="icon-newsfeed-social" />
                                <NumericLabel params={config.PRETTY_CONFIG}>
                                    22
                                </NumericLabel>
                            </span>
                        </div>
                        {showComments && 
                        <div className="actions">
                            <span className="action comment">
                                Kommentieren
                            </span>
                        </div>  
                        }  
                    </div>
                
            </div>
        )
    }
}

export default FeedCard
