import {
    API_PROJECT_LIST_REQUEST,
    API_PROJECT_LIST_SUCCESS,
    API_PROJECT_LIST_FAILURE,
    API_PROJECT_DELETE_SUCCESS,
    RESET_PROJECT_LIST,
    API_USERPROJECT_SUCCESS,
    API_USERPROJECT_REQUEST,
    API_USERPROJECT_FAILURE,
    API_FAVPROJECT_REQUEST,
    API_FAVPROJECT_SUCCESS,
    API_FAVPROJECT_FAILURE,
    API_NEW_PROJECT_LIST_FAILURE,
    API_NEW_PROJECT_LIST_SUCCESS,
    API_NEW_PROJECT_LIST_REQUEST,
    API_POST_STORY_SUCCESS,
    API_FOLLOW_PROJECT_SUCCESS,
    API_PROJECT_UPDATE_REQUEST,
    API_PROJECT_UPDATE_SUCCESS,
} from '../actions/project'
import {
    API_STORY_LIST_REQUEST,
    API_STORY_LIST_SUCCESS,
    API_STORY_LIST_FAILURE,
} from '../actions/story'
import {
    API_NOTIFICATION_UPDATE_REQUEST,
    API_NOTIFICATION_UPDATE_SUCCESS,
    API_NOTIFICATION_LIST_REQUEST,
    API_NOTIFICATION_LIST_SUCCESS,
    API_NOTIFICATION_LIST_FAILURE,
    RESET_NOTIFICATION_LIST,
} from '../actions/notification'
import {
    GET_POST_LIST_REQUEST,
    GET_POST_LIST_SUCCESS,
    GET_POST_LIST_FAILED,
    RESET_BLOG_LIST
} from '../actions/blog';
import {
    RESET_STORE,
    API_POST_PROJECT_SUCCESS,
} from '../actions/account'
import {
    API_FORUMTHREAD_UPDATE_REQUEST,
    API_FORUMTHREAD_UPDATE_SUCCESS,
    RESET_FORUMTHREAD_LIST,
    API_FORUMTHREAD_LIST_REQUEST,
    API_FORUMTHREAD_LIST_SUCCESS,
    API_FORUMTHREAD_LIST_FAILURE,
    API_USERTHREADS_REQUEST,
    API_USERTHREADS_SUCCESS,
    API_USERTHREADS_FAILURE,
} from '../actions/forumthread';
import {
    API_PROFESSIONAL_LIST_REQUEST,
    API_PROFESSIONAL_LIST_SUCCESS,
    API_PROFESSIONAL_LIST_FAILURE,
    RESET_PROFESSIONAL_LIST,
    RESET_USER_PROFESSIONAL_LIST,
    API_PROFESSIONAL_USER_SUCCESS,
    API_PROFESSIONAL_USER_REQUEST,
    API_PROFESSIONAL_USER_FAILURE
} from '../actions/professional'
import { 
    FETCH_BRAND_PROJECTS_REQUEST,
    FETCH_BRAND_PROJECTS_SUCCESS,
    FETCH_BRAND_PROJECTS_FAILED,
    RESET_BRAND_PROJECTS_LIST
} from '../actions/global'

export default function listByEntity(state = {}, action) {

    try {

        if (action.type === RESET_STORE || action.type === API_PROJECT_DELETE_SUCCESS || action.type === API_POST_PROJECT_SUCCESS) {
            return {};
        }

        if (action.type === RESET_BLOG_LIST){
            return {
                ...state,
                post: {},
            }
        }

        if(action.type === FETCH_BRAND_PROJECTS_REQUEST){
            return {
                ...state,
                ['brandProjects']: {
                    ...state['brandProjects'],
                    isFetching: true,
                    lastFetch: Date.now(),
                }
            }
        }
        else if(action.type === FETCH_BRAND_PROJECTS_SUCCESS){

            let ids = {};
            action.payload.list.map((pl) => {
                ids[pl.order] = pl.project.id;
            });

            return {
                ...state,
                ['brandProjects']: {
                    ...state['brandProjects'],
                    isFetching: false,
                    isFailed: false,
                    list: {
                        ...(state['brandProjects'] ? state['brandProjects']['list'] : {}),
                        ...ids
                    }
                }
            }
        }

        else if (action.type === FETCH_BRAND_PROJECTS_FAILED) {
            return {
                ...state,
                ['brandProjects']: {
                    ...state['brandProjects'],
                    isFetching: false,
                    isFailed: true,
                    lastUpdated: Date.now(),
                }
            };
        }

        if (
            action.type === API_NOTIFICATION_UPDATE_REQUEST &&
            action.type === API_PROJECT_UPDATE_REQUEST &&
            action.type === API_FORUMTHREAD_UPDATE_REQUEST
        ) {
            return {
                ...state,
                [action.meta.key]: {
                    ...state[action.meta.key],
                    hasUpdateLastFetch: Date.now(),
                }
            };
        }
        if (action.type === API_NOTIFICATION_UPDATE_SUCCESS) {
            if (action.payload.list && action.payload.list.length) {
                var id = action.payload.list[0].id;
                if (state[action.meta.key] && state[action.meta.key].list && !state[action.meta.key].list[id]) {
                    return {
                        ...state,
                        [action.meta.key]: {
                            ...state[action.meta.key],
                            hasUpdate: true,
                        }
                    };
                }
            }
        }
        if (action.type === API_PROJECT_UPDATE_SUCCESS) {
            if (action.payload.list && action.payload.list.length) {
                var id = action.payload.list[0].project.id;

                if (state[action.meta.key].list[0] !== id) {
                    return {
                        ...state,
                        [action.meta.key]: {
                            ...state[action.meta.key],
                            hasUpdate: true,
                        }
                    };
                }
            }
        }
        if (action.type === API_FORUMTHREAD_UPDATE_SUCCESS) {
            if (action.payload.list && action.payload.list.length) {
                var id = action.payload.list[0].forumthread.id;

                if (state[action.meta.key].list[0] !== id) {
                    return {
                        ...state,
                        [action.meta.key]: {
                            ...state[action.meta.key],
                            hasUpdate: true,
                        }
                    };
                }
            }
        }

        if (action.type === API_FORUMTHREAD_LIST_REQUEST) {
            return {
                ...state,
                ['forumthread']: {
                    ...state['forumthread'],
                    isFetching: true,
                    lastFetch: Date.now(),
                }
            };
        }
        else if (action.type === API_FORUMTHREAD_LIST_SUCCESS) {
            var ids = {};
            action.payload.list.map((pl) => {
                ids[pl.order] = pl.forumthread.id;
            });

            return {
                ...state,
                ['forumthread']: {
                    ...state['forumthread'],
                    isFetching: false,
                    isFailed: false,
                    lastUpdated: Date.now(),
                    list: {
                        ...(state['forumthread'] ? state['forumthread']['list'] : {}),
                        ...ids,
                    },
                    page: action.meta.page,
                    hasMore: action.payload.hasMore,
                    hasUpdate: (action.meta.page === 1 ? false : state['forumthread']['hasUpdate']),
                    hasUpdateLastFetch: (action.meta.page === 1 ? Date.now() : state['forumthread']['hasUpdateLastFetch']),
                }
            };
        }
        else if (action.type === API_FORUMTHREAD_LIST_FAILURE) {
            return {
                ...state,
                ['forumthread']: {
                    ...state['forumthread'],
                    isFetching: false,
                    isFailed: true,
                    lastUpdated: Date.now(),
                }
            };
        }

        else if (action.type === API_PROFESSIONAL_LIST_REQUEST){
            return {
                ...state,
                ['professional']: {
                    ...state['professional'],
                    isFetching: true,
                    isFailed: false,
                    lastFetch: Date.now(),
                    order: action.meta.order
                }
            }
        }
        else if (action.type === API_PROFESSIONAL_LIST_SUCCESS) {
            var ids = {};
            action.payload.list.map((pl) => {
                ids[pl.order] = pl.professional.id;
            });
            
            return {
                ...state,
                ['professional']: {
                    ...state['professional'],
                    isFetching: false,
                    isFailed: false,
                    lastUpdated: Date.now(),
                    list: {
                        // ...(state['professional'] ? state['professional']['list'] : {}),
                        ...ids,
                    },
                    page: action.meta.page,
                    hasMore: action.payload.hasMore,
                    order: action.meta.order,
                    hasUpdate: (action.meta.page === 1 ? false : state['professional']['hasUpdate']),
                    hasUpdateLastFetch: (action.meta.page === 1 ? Date.now() : state['professional']['hasUpdateLastFetch']),
                    status: action.payload.status ? action.payload.status : null,
                }
            };
        }
        else if (action.type === API_PROFESSIONAL_LIST_FAILURE){
            return {
                ...state,
                ['professional']: {
                    ...state['professional'],
                    isFetching: false,
                    isFailed: true,
                    lastFetch: Date.now(),
                    status: action.payload.status ? action.payload.status : null,
                    order: action.meta.order
                }
            }
        }

        else if (action.type === RESET_PROFESSIONAL_LIST) {
            return {
                ...state,
                ['professional']: {
                    ...state['professional'],
                    isFetching: false,
                    isFailed: false,
                    lastFetch: null,
                    lastUpdated: null,
                    list: {}
                }
            };
        }

        else if (action.type === RESET_USER_PROFESSIONAL_LIST) {
            return {
                ...state,
                [action.payload.id + '::userProfessional']: {
                    ...state[action.payload.id + '::userProfessional'],
                    isFetching: false,
                    isFailed: false,
                    lastFetch: null,
                    lastUpdated: null,
                    list: {},
                    status: null
                }
            };
        }

        else if (action.type === API_PROJECT_LIST_REQUEST) {
            return {
                ...state,
                ['project']: {
                    ...state['project'],
                    isFetching: true,
                    lastFetch: Date.now(),
                }
            };
        }
        else if (action.type === API_PROJECT_LIST_SUCCESS) {
            var ids = {};
            action.payload.list.map((pl) => {
                ids[pl.order] = pl.project.id;
            });

            return {
                ...state,
                ['project']: {
                    ...state['project'],
                    isFetching: false,
                    isFailed: false,
                    lastUpdated: Date.now(),
                    list: {
                        ...(state['project'] ? state['project']['list'] : {}),
                        ...ids,
                    },
                    page: action.meta.page,
                    hasMore: action.payload.hasMore,
                    hasUpdate: (action.meta.page === 1 ? false : state['project']['hasUpdate']),
                    hasUpdateLastFetch: (action.meta.page === 1 ? Date.now() : state['project']['hasUpdateLastFetch']),
                }
            };
        }
        else if (action.type === API_PROJECT_LIST_FAILURE) {
            return {
                ...state,
                ['project']: {
                    ...state['project'],
                    isFetching: false,
                    isFailed: true,
                    lastUpdated: Date.now(),
                }
            };
        }
        else if (action.type === API_NEW_PROJECT_LIST_REQUEST) {
            return {
                ...state,
                ['newProjectsList']: {
                    ...state['newProjectsList'],
                    isFetching: true,
                    isFailed: false,
                    lastFetch: Date.now(),
                }
            };
        }
        else if (action.type === API_NEW_PROJECT_LIST_SUCCESS) {
            var ids = {};
            action.payload.list.map((pl) => {
                ids[pl.order] = pl.project.id;
            });

            return {
                ...state,
                ['newProjectsList']: {
                    ...state['newProjectsList'],
                    isFetching: false,
                    isFailed: false,
                    lastUpdated: Date.now(),
                    list: {
                        ...(state['newProjectsList'] ? state['newProjectsList']['list'] : {}),
                        ...ids,
                    },
                    page: action.meta.page,
                    hasMore: action.payload.hasMore,
                }
            };
        }
        else if (action.type === API_NEW_PROJECT_LIST_FAILURE) {
            return {
                ...state,
                ['newProjectsList']: {
                    ...state['newProjectsList'],
                    isFetching: false,
                    isFailed: true,
                    lastUpdated: Date.now(),
                }
            };
        }
        else if (action.type === API_FAVPROJECT_REQUEST) {
            return {
                ...state,
                ['FavProjects']: {
                    ...state['FavProjects'],
                    favListFetching: true,
                    favListFailed: false,
                    lastFetch: Date.now(),
                }
            };
        }
        else if (action.type === API_FAVPROJECT_SUCCESS) {
            var ids = {};
            action.payload.list.map((pl) => {
                ids[pl.order] = pl.project.id;
            });

            return {
                ...state,
                ['FavProjects']: {
                    ...state['FavProjects'],
                    favListFetching: false,
                    favListFailed: false,
                    lastUpdated: Date.now(),
                    list: {
                        ...(state['FavProjects'] ? state['FavProjects']['list'] : {}),
                        ...ids,
                    },
                    hasMore: action.payload.hasMore,
                }
            };
        }
        else if (action.type === API_FAVPROJECT_FAILURE) {
            return {
                ...state,
                ['FavProjects']: {
                    ...state['FavProjects'],
                    favListFetching: false,
                    favListFailed: action.payload.status === 404 ? false : true,
                    lastUpdated: Date.now(),
                }
            };
        }
        else if (action.type === API_FOLLOW_PROJECT_SUCCESS) {
            return {
                ...state,
                ['FavProjects']: {
                    ...state['FavProjects'],
                    isFetching: false,
                    isFailed: false,
                    lastFetch: null,
                    lastUpdated: null,
                    list: {}
                }
            };
        }
        else if (action.type === API_STORY_LIST_REQUEST) {
            return {
                ...state,
                [action.meta.project+'::story']: {
                    ...state[action.meta.project+'::story'],
                    isFetching: true,
                    lastFetch: Date.now(),
                }
            };
        }
        else if (action.type === API_STORY_LIST_SUCCESS) {
            var ids = {};
            action.payload.list.map((pl) => {
                ids[pl.projectpart.id] = pl.projectpart.id;
            });

            return {
                ...state,
                [action.meta.project+'::story']: {
                    ...state[action.meta.project+'::story'],
                    isFetching: false,
                    isFailed: false,
                    lastUpdated: Date.now(),
                    list: {
                        ...(state[action.meta.project+'::story'] ? state[action.meta.project+'::story']['list'] : {}),
                        ...ids,
                    },
                    page: action.meta.page,
                    hasMore: action.payload.hasMore,
                }
            };
        }
        else if (action.type === API_STORY_LIST_FAILURE) {
            return {
                ...state,
                [action.meta.project+'::story']: {
                    ...state[action.meta.project+'::story'],
                    isFetching: false,
                    isFailed: true,
                    lastUpdated: Date.now(),
                }
            };
        }
        else if (action.type === API_POST_STORY_SUCCESS) {
            return {
                ...state,
                [action.meta.id+'::story']: {
                    ...state[action.meta.id+'::story'],
                    list: {
                        ...(state[action.meta.id+'::story'] ? state[action.meta.id+'::story']['list'] : {}),
                        ...{[action.payload.projectpart.id]: action.payload.projectpart.id},
                    },
                }
            };
        }
        // else if(action.type === HANDLE_INPUT){
        //     return {
        //         ...state,
        //             userInput: {
        //                 ...state.userInput,
        //                 brand: {
        //                     ...state.brand,
        //                     ...action.payload.brand
        //                 },
        //                 model: {
        //                     ...state.model,
        //                     ...action.payload.model
        //                 },
        //                 variant: {
        //                     ...state.variant,
        //                     ...action.payload.variant
        //                 },
        //                 yearFrom: {
        //                     ...state.yearFrom,
        //                     ...action.payload.yearFrom
        //                 },
        //                 yearTo: {
        //                     ...state.yearFrom,
        //                     ...action.payload.yearTo
        //                 },
        //                 country: {
        //                     ...state.country,
        //                     ...action.payload.country
        //                 },
        //             }
        //     }
        // }
        else if (action.type === RESET_PROJECT_LIST) {
            return {
                ...state,
                ['project']: {
                    ...state['project'],
                    isFetching: false,
                    isFailed: false,
                    lastFetch: null,
                    lastUpdated: null,
                    list: {}
                }
            };
        }
        else if(action.type === RESET_BRAND_PROJECTS_LIST){
            return {
                ...state,
                ['brandProjects']: {
                    ...state['brandProjects'],
                    isFetching: false,
                    isFailed: false,
                    lastFetch: null,
                    lastUpdated: null,
                    list: {}
                }
            };
        }
        else if (action.type === RESET_FORUMTHREAD_LIST) {
            return {
                ...state,
                ['forumthread']: {
                    ...state['forumthread'],
                    isFetching: false,
                    isFailed: false,
                    lastFetch: null,
                    lastUpdated: null,
                    list: {}
                }
            };
        }
        //User projects list
        else if (action.type === API_USERPROJECT_REQUEST) {
            return {
                ...state,
                [action.meta.userId+'::userProjects']: {
                    ...state[action.meta.userId+'::userProjects'],
                    isFetching: true,
                    isFailed: false,
                }
            }
        }
        else if (action.type === API_USERPROJECT_SUCCESS) {
            var ids = {};
            action.payload.list.map((pl) => {
                ids[pl.order] = pl.project.id;
            });

            return {
                ...state,
                [action.meta.userId+'::userProjects']: {
                    ...state[action.meta.userId+'::userProjects'],
                    isFetching: false,
                    isFailed: false,
                    list:{
                        ...(state[action.meta.userId+'::userProjects'] ? state[action.meta.userId+'::userProjects']['list'] : {}),
                        ...ids,
                    },
                    userId: action.meta.userId,
                    hasMore: action.payload.hasMore,
                }
            }
        }
        else if (action.type === API_USERPROJECT_FAILURE) {
            return {
                ...state,
                [action.meta.userId+'::userProjects']: {
                    ...state[action.meta.userId+'::userProjects'],
                    isFailed: action.payload.status === 404 ? false : true,
                    isFetching: false
                }
            }
        }

        //User forumthread list
        if (action.type === API_USERTHREADS_REQUEST) {
            return {
                ...state,
                [action.meta.userId+'::userThreads']: {
                    ...state[action.meta.userId+'::userThreads'],
                    isFetching: true,
                    isFailed: false,
                }
            }
        }
        else if (action.type === API_USERTHREADS_SUCCESS) {
            var ids = {};
            action.payload.list.map((pl, index) => {
                ids[index] = pl.forumthread.id;
            });

            return {
                ...state,
                [action.meta.userId+'::userThreads']: {
                    ...state[action.meta.userId+'::userThreads'],
                    isFetching: false,
                    isFailed: false,
                    list:{
                        ...(state[action.meta.userId+'::userThreads'] ? state[action.meta.userId+'::userThreads']['list'] : {}),
                        ...ids,
                    },
                    userId: action.meta.userId,
                    hasMore: action.payload.hasMore,
                }
            }
        }
        else if (action.type === API_USERTHREADS_FAILURE) {
            return {
                ...state,
                [action.meta.userId+'::userThreads']: {
                    ...state[action.meta.userId+'::userThreads'],
                    isFailed: true,
                    isFetching: false
                }
            }
        }

        //User professional list
        if (action.type === API_PROFESSIONAL_USER_REQUEST) {
            return {
                ...state,
                [action.meta.userId+'::userProfessional']: {
                    ...state[action.meta.userId+'::userProfessional'],
                    isFetching: true,
                    isFailed: false,
                    status: null
                }
            }
        }
        else if (action.type === API_PROFESSIONAL_USER_SUCCESS) {
            var ids = {};
            action.payload.list.map((pl, index) => {
                ids[index] = pl.professional.id;
            });

            return {
                ...state,
                [action.meta.userId+'::userProfessional']: {
                    ...state[action.meta.userId+'::userProfessional'],
                    isFetching: false,
                    isFailed: false,
                    list:{
                        ...(state[action.meta.userId+'::userProfessional'] ? state[action.meta.userId+'::userProfessional']['list'] : {}),
                        ...ids,
                    },
                    userId: action.meta.userId,
                    hasMore: action.payload.hasMore,
                    status: action.payload.status ? action.payload.status : null,
                }
            }
        }
        else if (action.type === API_PROFESSIONAL_USER_FAILURE) {
            return {
                ...state,
                [action.meta.userId+'::userProfessional']: {
                    ...state[action.meta.userId+'::userProfessional'],
                    isFailed: true,
                    isFetching: false,
                    status: action.payload.status ? action.payload.status : null
                }
            }
        }

        if (action.type === API_NOTIFICATION_LIST_REQUEST) {
            return {
                ...state,
                [action.meta.key]: {
                    ...state[action.meta.key],
                    isFetching: true,
                    lastFetch: Date.now(),
                }
            };
        }
        else if (action.type === API_NOTIFICATION_LIST_SUCCESS) {
            var ids = {};
            action.payload.list.map((pl) => {
                ids[pl.id] = pl.id;
            });

            return {
                ...state,
                [action.meta.key]: {
                    ...state[action.meta.key],
                    isFetching: false,
                    isFailed: false,
                    lastUpdated: Date.now(),
                    list: {
                        ...(state[action.meta.key] ? state[action.meta.key]['list'] : {}),
                        ...ids,
                    },
                    page: action.meta.page,
                    hasMore: action.payload.hasMore,
                    hasUpdate: (action.meta.page === 1 ? false : state[action.meta.key]['hasUpdate']),
                    hasUpdateLastFetch: (action.meta.page === 1 ? Date.now() : state[action.meta.key]['hasUpdateLastFetch']),
                }
            };
        }
        else if (action.type === API_NOTIFICATION_LIST_FAILURE) {
            return {
                ...state,
                [action.meta.key]: {
                    ...state[action.meta.key],
                    isFetching: false,
                    isFailed: true,
                    lastUpdated: Date.now(),
                }
            };
        }
        else if (action.type === RESET_NOTIFICATION_LIST) {
            return {
                ...state,
                [action.meta.key]: {
                    ...state[action.meta.key],
                    isFetching: false,
                    lastFetch: null,
                    lastUpdated: null,
                    list: {}
                }
            };
        }

        else if(action.type === GET_POST_LIST_REQUEST){
            return {
                ...state,
                post: {
                    ...state.post,
                    isFetching: true,
                    isFailed: false,
                    lastFetch: Date.now()
                },
            }
        }

        else if(action.type === GET_POST_LIST_SUCCESS) {

            let ids = {};
            action.payload.map((item, index) => {
                ids[index] = item.id;
            });
            return {
                ...state,
                post: {
                    ...state.post,
                    items: {
                        ...ids,
                    },
                    page: action.meta.page,
                    isFetching: false,
                    isFailed: false,
                    lastUpdated: Date.now()
                },
            }
        }

        else if (action.type === GET_POST_LIST_FAILED) {
            return {
                ...state,
                post: {
                    ...state.post,
                    isFetching: false,
                    isFailed: true
                }
            }
        }

        return state;

    } catch (e) {

        console.log(e);
        return {
            ...state,
            fatalError: true,
        };

    }

}
