/* NOTE: The Blog uses Wordpress REST API */
import config from '../config/config'
import fetch from 'cross-fetch';
import { createAction } from 'redux-api-middleware';

export const GET_POST_LIST_REQUEST = 'GET_POST_LIST_REQUEST';
export const GET_POST_LIST_SUCCESS = 'GET_POST_LIST_SUCCESS';
export const GET_POST_LIST_FAILED = 'GET_POST_LIST_FAILED';
export const GET_SINGLE_POST_REQUEST = "GET_SINGLE_POST_REQUEST";
export const GET_SINGLE_POST_SUCCESS = "GET_SINGLE_POST_SUCCESS";
export const GET_SINGLE_POST_FAILED = "GET_SINGLE_POST_FAILED";
export const GET_BLOG_TAGS_REQUEST = "GET_BLOG_TAGS_REQUEST";
export const GET_BLOG_TAGS_SUCCESS = "GET_BLOG_TAGS_SUCCESS";
export const GET_BLOG_TAGS_FAILED = "GET_BLOG_TAGS_FAILED";
export const GET_POSTS_TOTAL_COUNT_REQUEST =  "GET_POSTS_TOTAL_COUNT_REQUEST";
export const GET_POSTS_TOTAL_COUNT_SUCCESS = "GET_POSTS_TOTAL_COUNT_SUCCESS";
export const GET_POSTS_TOTAL_COUNT_FAILED = "GET_POSTS_TOTAL_COUNT_FAILED";
export const GET_POST_COMMENTS_REQUEST = "GET_POST_COMMENTS_REQUEST";
export const GET_POST_COMMENTS_SUCCESS = "GET_POST_COMMENTS_SUCCESS";
export const GET_POST_COMMENTS_FAILED = "GET_POST_COMMENTS_FAILED";
export const POST_NEW_COMMENT_REQUEST = 'POST_NEW_COMMENT_REQUEST';
export const POST_NEW_COMMENT_SUCCESS = 'POST_NEW_COMMENT_SUCCESS';
export const POST_NEW_COMMENT_FAILURE = 'POST_NEW_COMMENT_FAILURE';

export const RESET_BLOG_LIST = "RESET_BLOG_LIST";

export function getPostList(page, posts_amount, tags){
    if (!page) page = 1;
    if (!posts_amount) posts_amount = 6;

    //Wordpress API fields - specify response fields 
    let fields = "id,excerpt,title,content,tags,modified, featured_media, slug, status, sticky _embedded, _links"

    //Wordpress API embed 
    let embed = "wp:featuredmedia, author"

    return createAction({
        endpoint: `${config.BLOG_API_HOST}/wp-json/wp/v2/posts?per_page=${posts_amount}&page=${page}&_embed=${embed}&_fields=${fields}` + (tags ? `&tags[]=${tags}` : '') ,
        method: 'GET',
        types: [
            {type: GET_POST_LIST_REQUEST, meta: page},
            {type: GET_POST_LIST_SUCCESS, meta: page},
            {type: GET_POST_LIST_FAILED, meta: page},
        ]
    })
}

export function shouldGetPostList(state){
    const bloglist = state.listByEntity['post'];
    if(bloglist && Object.keys(bloglist).length === 0 || bloglist && (bloglist.isFetching || bloglist.isFailed)){
        return true
    }
    return false
}

export function getPostListIfNeeded(page, posts_amount, tags){
    return (dispatch, getState) => {
        if (shouldGetPostList(getState())) {
            return dispatch(getPostList(page, posts_amount, tags))
        }
    }
}

export function getSinglePost(id){

    //Wordpress API fields - specify response fields 
    let fields = "id,excerpt,title,content,tags,modified, featured_media, slug, sticky, status, _embedded, _links"

    //Wordpress API embed 
    let embed = "wp:featuredmedia, author"

    return createAction({
        endpoint: `${config.BLOG_API_HOST}/wp-json/wp/v2/posts/${id}?_embed=${embed}&_fields=${fields}`,
        method: 'GET',
        types: [
            {type: GET_SINGLE_POST_REQUEST, meta:{ id }},
            {type: GET_SINGLE_POST_SUCCESS, meta:{ id }},
            {type: GET_SINGLE_POST_FAILED, meta:{ id }},
        ]
    })
}

export function shouldGetSinglePost(id, state){
    
    const bloglist = state.blog.post && state.blog.post.items[id];
    if(!bloglist || (bloglist.isFetching || bloglist.isFailed)){
        return true
    }
    return false
}

export function getSinglePostIfNeeded(id){
    return (dispatch, getState) => {
        if (shouldGetSinglePost(id, getState())) {
            return dispatch(getSinglePost(id))
        }
    }
}

export function getBlogTags(){

    //Wordpress API fields - specify response fields 
    let fields = "id,name,slug,count"

    return createAction({
        endpoint: `${config.BLOG_API_HOST}/wp-json/wp/v2/tags?_fields=${fields}&per_page=100`,
        method: 'GET',
        types: [
            {type: GET_BLOG_TAGS_REQUEST},
            {type: GET_BLOG_TAGS_SUCCESS},
            {type: GET_BLOG_TAGS_FAILED},
        ]
    })
}

export function shouldGetBlogTags(state, tagId){
    const tag = state.blog.tag && state.blog.tag.items;
    if(!tag || (tag.isFetching || tag.isFailed)){
        return true
    }
    else if (!tag[tagId]) {
        return true
    }
    return true
}

export function getBlogTagsIfNeeded(tagId){
    return (dispatch, getState) => {
        if (shouldGetBlogTags(getState(), tagId)) {
            return dispatch(getBlogTags())
        }
    }
}

export function resetBlogList(){
    return {
        type: RESET_BLOG_LIST,
    }
}

export function getTotalCount(){

     //Wordpress API fields - specify response fields 
     let fields = "id, status"

    return createAction({
        //TODO: WP REST API limits posts per page to 100 - Fetch more than 100?
        endpoint: `${config.BLOG_API_HOST}/wp-json/wp/v2/posts?_fields=${fields}&per_page=100`,
        method: 'GET',
        types: [
            {type: GET_POSTS_TOTAL_COUNT_REQUEST},
            {type: GET_POSTS_TOTAL_COUNT_SUCCESS},
            {type: GET_POSTS_TOTAL_COUNT_FAILED},
        ]
    })
}

export function getPostComments(postId){
    return createAction({
        endpoint: `${config.BLOG_API_HOST}/wp-json/wp/v2/comments?post=${postId}`,
        method: 'GET',
        types: [
            {type: GET_POST_COMMENTS_REQUEST, meta: {postId}},
            {type: GET_POST_COMMENTS_SUCCESS, meta: {postId}},
            {type: GET_POST_COMMENTS_FAILED, meta: {postId}},
        ]
    })
}

export function postComment(author, email, text, post) {
    return createAction({
        endpoint: `${config.BLOG_API_HOST}/wp-json/wp/v2/comments`,
        method: 'POST',
        body: JSON.stringify({
            author_name: author,
            author_mail: email,
            content: text,
            post: post
        }),
        types: [
            {type: POST_NEW_COMMENT_REQUEST},
            {type: POST_NEW_COMMENT_SUCCESS},
            {type: POST_NEW_COMMENT_FAILURE},
        ]
    })
}