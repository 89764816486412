import config from '../config/config';
import { createAction } from 'redux-api-middleware';

export const API_CONVERSATION_LIST_REQUEST = 'API_CONVERSATION_LIST_REQUEST';
export const API_CONVERSATION_LIST_SUCCESS = 'API_CONVERSATION_LIST_SUCCESS';
export const API_CONVERSATION_LIST_FAILURE = 'API_CONVERSATION_LIST_FAILURE';
export const API_CONVERSATION_REQUEST = 'API_CONVERSATION_REQUEST';
export const API_CONVERSATION_SUCCESS = 'API_CONVERSATION_SUCCESS';
export const API_CONVERSATION_FAILURE = 'API_CONVERSATION_FAILURE';
export const API_POST_MESSAGE_REQUEST = 'API_POST_MESSAGE_REQUEST';
export const API_POST_MESSAGE_SUCCESS = 'API_POST_MESSAGE_SUCCESS';
export const API_POST_MESSAGE_FAILURE = 'API_POST_MESSAGE_FAILURE';
export const NEW_MESSAGE = 'NEW_MESSAGE';
export const NEW_MESSAGE_USER = 'NEW_MESSAGE_USER';
export const CHANGE_PRIVATE_MESSAGE = 'CHANGE_PRIVATE_MESSAGE';
export const API_UPDATE_MESSAGE_REQUEST = 'API_UPDATE_MESSAGE_REQUEST';
export const API_UPDATE_MESSAGE_SUCCESS = 'API_UPDATE_MESSAGE_SUCCESS';
export const API_UPDATE_MESSAGE_FAILURE = 'API_UPDATE_MESSAGE_FAILURE';


export function changeMessage(id, inputText) {
    return {
        type: CHANGE_PRIVATE_MESSAGE,
        meta: {
            id,
        },
        payload: {
            inputText,
        }
    }
}

export function updateMessage(item) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/conversation/message/${item.id}`,
        method: 'PUT',
        body: JSON.stringify({
            conversation_message: {
                ...item,
                text: item.inputText,
            },
        }),
        types: [
            {type: API_UPDATE_MESSAGE_REQUEST, meta: { id: item.id }},
            {type: API_UPDATE_MESSAGE_SUCCESS, meta: { id: item.id }},
            {type: API_UPDATE_MESSAGE_FAILURE, meta: { id: item.id }},
        ]
    })
}

export function newMessageUser(text, id) {
    return {
        type: NEW_MESSAGE_USER,
        meta: {
            id,
        },
        payload: {
            inputText: text,
        }
    }
}

export function newMessage(text, id) {
    return {
        type: NEW_MESSAGE,
        meta: {
            id,
        },
        payload: {
            inputText: text,
        }
    }
}

export function postMessage(text, members = null, conversation = null) {
    var meta = {};
    var data = {
        text,
    };

    if (members instanceof Array) {
        meta = {
            userid: members[0],
        };
        data = {
            ...data,
            members,
        };
    }

    if (conversation) {
        meta = {
            id: conversation,
        };
        data = {
            ...data,
            conversation,
        };
    }

    return createAction({
        endpoint: `${config.API_HOST}/v1/conversation`,
        method: 'POST',
        body: JSON.stringify(data),
        types: [
            {type: API_POST_MESSAGE_REQUEST, meta},
            {type: API_POST_MESSAGE_SUCCESS, meta},
            {type: API_POST_MESSAGE_FAILURE, meta},
        ]
    })
}

export function fetchNextMessages(pid, id) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/conversation/${pid}?basemessage[]=${id}`,
        method: 'GET',
        types: [
            {type: API_CONVERSATION_REQUEST, meta: { id: pid }},
            {type: API_CONVERSATION_SUCCESS, meta: { id: pid }},
            {type: API_CONVERSATION_FAILURE, meta: { id: pid }},
        ]
    })
}

export function fetchPreviousMessages(pid, id) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/conversation/${pid}?loadmessage[]=${id}`,
        method: 'GET',
        types: [
            {type: API_CONVERSATION_REQUEST, meta: { id: pid }},
            {type: API_CONVERSATION_SUCCESS, meta: { id: pid }},
            {type: API_CONVERSATION_FAILURE, meta: { id: pid }},
        ]
    })
}

export function fetchConversation(pid) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/conversation/${pid}`,
        method: 'GET',
        types: [
            {type: API_CONVERSATION_REQUEST, meta: { id: pid }},
            {type: API_CONVERSATION_SUCCESS, meta: { id: pid }},
            {type: API_CONVERSATION_FAILURE, meta: { id: pid }},
        ]
    })
}

function shouldFetchConversation(state, id) {
    const conversation = state.conversationById[id]
    if (
        !conversation ||
        !conversation.messages ||
        ((conversation.isFetching || conversation.isFailed) && Date.now() - conversation.lastFetch > config.FETCH_TIMEOUT) ||
        (!conversation.isFetching && Date.now() - (conversation.lastUpdated ? conversation.lastUpdated : 0) > config.FETCH_AGAIN)
    ) {
        return true
    }
    return false
}

export default function fetchConversationIfNeeded(id) {
    return (dispatch, getState) => {
        if (shouldFetchConversation(getState(), id)) {
            return dispatch(fetchConversation(id))
        }
    }
}

export function fetchConversationList() {
    return createAction({
        endpoint: `${config.API_HOST}/v1/conversation`,
        method: 'GET',
        types: [
            {type: API_CONVERSATION_LIST_REQUEST},
            {type: API_CONVERSATION_LIST_SUCCESS},
            {type: API_CONVERSATION_LIST_FAILURE},
        ]
    })
}
