import React, { Component } from 'react'
import { Message, Icon, Button } from 'semantic-ui-react';
import './ErrorHandler.scss';

class InfoHandler extends Component {
    render() {

        const { errorMessage, callbackFunction } = this.props;

        return (
            <Message color="teal" className="error-message-wrapper">
                <div className="error-message">
                    <Icon className="error-icon" name="list alternate outline" size="big"/>
                    {errorMessage ? <p><strong>{errorMessage}</strong></p> : <p>Keine Einträge gefunden.</p>}
                </div>
                {callbackFunction &&
                <Button onClick={callbackFunction} color="red">
                    <Icon name="redo" />
                    Nochmal versuchen
                </Button>}
            </Message>
        )
    }
}

export default InfoHandler
