import React from 'react'
import { connect } from 'react-redux';
import { Container, Divider, Header, Grid, Message, Loader } from 'semantic-ui-react';
import { setRuntime, handleContainer, showFooter, editHeader } from '../../../../actions/global';
import { clearNewProfessional, fetchProfessional, fetchUserProfessional, updateProfessional, resetProfessionalList, resetUserProfessionalList } from '../../../../actions/professional';
import BeParent from '../../../project/backend/be-parent';
import { Redirect } from 'react-router-dom';
import ContactData from './ContactData';
import { directProfessionalUpdate } from './../../../../actions/professional';
import Description from './Description';
import Media from './Media';
import '../../dashboard.scss';
import config from '../../../../config/config';
import Moment from 'react-moment';
import Pause from './Pause';
import ProfessionalValidation from './../../../../components/DataValidation/ProfessionalValidation';
import { hideFooter } from './../../../../actions/global';
import EditHeader from '../../../../components/layout/Header/EditHeader';
import singleFieldValidation from './../../../../components/DataValidation/GlobalValidation';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

const inputFields = [
    "name", "description", "street", "zip", "city", "countrycode", "owner", "website", "phone", "headline", "intro", "scopes", "types", "categories", "media", "mediamain", "medialogo", "mediavideo"
]

class ProfessionalEdit extends BeParent {

    state = {
        tagModelIsOpen: false,
        currentStep: "contact",
        validation: {},
        validationMsg: "",
        isUploading: false,
        hideSaveModal: null,
        redirectToMainPage: false,
        confirmModal: {
            isOpen: false
        }
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);
        const { dispatch, history, inputProfessional, match } = this.props;

        dispatch(setRuntime({
            page: 'fe-professional-new',
        }));
        dispatch(fetchProfessional(match.params.id))
        dispatch(hideFooter());
        dispatch(handleContainer("edit", 'Spezialisten Anzeige bearbeiten', false, "dashboard"));

        // Init if empty
        if(!inputProfessional){
            dispatch(clearNewProfessional())
        }

        //Set current step / page
        if(!match.params.step){
            history.push(`/backend/professional/edit/${match.params.id}/contact`);
        }

        this.setState({ currentStep: match.params.step })

    }

    componentDidUpdate(prevProps) {
        const { dispatch, match, history, isDeleted, item, professional } = this.props;

        //Update current step / page
        if((match.params.step !== prevProps.match.params.step)) {
            this.setState({ currentStep: match.params.step })
        }

        if(isDeleted && (isDeleted !== prevProps.isDeleted)) {
  
            dispatch(resetUserProfessionalList(item.id));
            dispatch(resetProfessionalList());
            
            // Update list
            dispatch(fetchUserProfessional(item.id));
            history.push('/backend/professional#deleted');
        }

    }

    componentWillUnmount = () => {
        const { dispatch } = this.props;
        dispatch(showFooter());
    }

    handleStepMenu = (item) => {
        const { history, match } = this.props;

        this.setState({ currentStep: item });
        history.push(`/backend/professional/edit/${match.params.id}/${item}`)
    }

    validateFirst = (validationItem, validationMsg) => {
        const { professional } = this.props;

        //Default validation object
        if(!validationItem) validationItem = professional;
        //Default validation error message
        if(!validationMsg) validationMsg = `Fülle alle Pflichtfelder aus um das Abonemment abzuschließen.`

        //Validate data first
        this.setState({
            validation: ProfessionalValidation(validationItem),
            validationMsg: validationMsg
        })    

        if(Object.keys(ProfessionalValidation(validationItem)).length > 0){
            return false;
        }

    }

    validateSingleField = (field, item, errorMsg) => {
        const { validation } = this.state;

        this.setState({ 
            validation: {
                ...validation,
                ...singleFieldValidation(field, item, errorMsg)
            }
        })
    }

    revertChanges = () => {
        const { dispatch, match } = this.props;
        dispatch(fetchProfessional(match.params.id))
        this.setState({ validation: {} })
    }

    saveChanges = (e) => {
        const { dispatch, professional, subscription } = this.props;
        e.preventDefault()

        if(
            (professional.input.street !== professional.street) || 
            (professional.input.city !== professional.city) ||
            (professional.input.zipcode !== professional.zipcode)
          ) {
              professional.input.latitude = null;
              professional.input.longitude = null;
          }

        //Validate if professional is already active
        if((professional.active || (subscription && subscription.status === "ACTIVE" )) && this.validateFirst(professional.input) === false){
            return;
        }

        dispatch(updateProfessional(professional.id, professional.input));
    }

    requestGoback = () => {
        const { professional, isUploading } = this.props;
        if (config.isChanged(professional, inputFields, "benefits") || isUploading){
            this.setState({ confirmModal: {isOpen: true} })
            return;
        }
    }

    dismiss = () => {
        this.setState({ redirectToMainPage: true })
    }

    render() {

        const { match, professional, isFetching, isDeleted, isUploading, isFailed } = this.props;
        const { currentStep, validation, redirectToMainPage } = this.state;

        if(redirectToMainPage) {
            return (
                <Redirect to="/backend/professional"/>
            )
        }

        if(professional){
            return (
                 <PayPalScriptProvider options={
                     {
                         "client-id": "AYFELnD5_PLQqheq4pjPjGK5zWaY_JQhHogjV_eTfhwj7NUPPcO_CQVdPns7PhtUWNSt7zUYHQcUxJQo",
                         vault: true,
                         intent: "subscription"
                     }
                 }>
                 {this.confirmModal(this.dismiss)}
                    {!isDeleted &&
                        <EditHeader 
                            stepMenu={{
                                menuItems: [
                                    { name: "Kontaktdaten", step:"contact", validation: validation.contact},
                                    { name: "Beschreibung", step:"description", validation: validation.description},
                                    { name: "Bilder/Video", step:"medias", validation: validation.medias},
                                    { name: "Abonement", step:"visibility", validation: validation.visibility }
                                ],
                                currentStep: currentStep
                            }}
                            validation={validation}
                            handleStepMenu={this.handleStepMenu}
                            requestGoback={this.requestGoback}
                            title="Spezialisten bearbeiten" 
                            backPath="/backend/professional"
                            updateText={professional.active ? "Aktualisieren" : "Veröffentlichen"}
                            previewPath={`/professionals/${professional.urlslug}/${professional.id}/preview`}
                            requestPublish={this.saveChanges}
                            fetching={isFetching} 
                            mediaUpload={isUploading}
                            updateDisable={
                                config.isChanged(professional, inputFields, "benefits")
                                //(validation && Object.keys(validation).length > 0) 
                                
                            }
                            editDate={professional.editDate && <Moment unix format="DD.MM.YYYY">{professional.editDate}</Moment>}
                        >

                        <Container text>
                            <Grid>
                                <Grid.Column width={8} textAlign="left">
                                    <Header as="h2">
                                        {currentStep === "contact" && 
                                            "Kontaktdaten" 
                                        } 
                                        {currentStep === "description" && 
                                            "Beschreibung"
                                        } 
                                        {currentStep === "medias" && 
                                            "Bilder/Video" 
                                        }  
                                        {currentStep === "visibility" && 
                                            //"Pausieren/Kündigen" 
                                            "Abonnement"
                                        }  
                                    </Header>
                                </Grid.Column>
                            </Grid>

                            {!isFetching && isFailed &&
                                <Message color="red">
                                    Ein Fehler ist aufgetreten. Versuche es später nochmal.
                                </Message>
                            }

                            {currentStep === "contact" && 
                                <ContactData validation={validation} id={match.params.id} requestValidateSingle={this.validateSingleField} />
                            }

                            {currentStep === "description" && 
                                <Description validation={validation} id={match.params.id} requestValidateSingle={this.validateSingleField} />
                            }  

                            {currentStep === "medias" && 
                                <Media validation={validation} id={match.params.id} requestValidateSingle={this.validateSingleField} />
                            }

                            {currentStep === "visibility" && 
                                <Pause validation={validation} validateFirst={this.validateFirst} id={match.params.id} submit={this.publish}/>
                            }

                        </Container>
                    </EditHeader>
                    }
                </PayPalScriptProvider>    
                
            )
        }
        else {
            return (
                <Container>
                    <Grid verticalAlign="middle" style={{height: "100vh"}}>
                        <Grid.Column textAlign="center" width={16}>
                            <Loader active/>
                        </Grid.Column>
                    </Grid>
                </Container>
            )
        }
    }
}

function mapStateToProps(state, ownProps) {
    const { professionalsById, account } = state;

    const { isFetching, isFailed, item: professional, isDeleted, isUploading, subscription } = professionalsById[ownProps.match.params.id] || {
        professional: {},
        isFetching: true,
        isFailed: false,
        isDeleted: false,
        isUploading: false,
        subscription: null
    }

    const { item } = account || {}

    return {
        isFetching, 
        isFailed,
        isUploading,
        professional,
        isFetching,
        isDeleted,
        item,
        subscription
    };
}

export default connect(mapStateToProps)(ProfessionalEdit);
