import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Divider} from 'semantic-ui-react';

import DashboardComments from '../../pages/dashboard/DashboardComments';
import Follower from '../../pages/dashboard/follower';
import Likes from '../../pages/dashboard/Likes';
import { containerMain, handleContainer, handleHeaderItems, footerActiveItem, setRuntime } from '../../actions/global';
import { fetchUserNotificationsIfNeeded } from '../../actions/account';
import DashboardAppMessage from './dashboardAppMessage';
import DashboardModuleProjects from './dashboardModuleProjects';
import DashboardModuleFavorites from './dashboardModuleFavorites';
import DashboardModuleNew from './dashboardModuleNew';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


/**
 Dashboard
*/
class Dashboard extends Component {

    constructor(props) {
        super(props)
        this.state = {
            messageVisible: true,
            sidebarSlidingPane: {
                isOpen: false,
                content: "",
                title: ""
            }
        }
    }

    componentDidMount() {
        const { dispatch, item, location } = this.props;

        window.scrollTo(0, 0);
        dispatch(containerMain());
        dispatch(handleContainer("backend", "Dashboard von "+item.name, true, "dashboard"));
        dispatch(handleHeaderItems("dashboard"));
        dispatch(footerActiveItem("user"));

        dispatch(fetchUserNotificationsIfNeeded());

        // Set back path
        dispatch(setRuntime({
            backProject: {page: 'be-dashboard', path: location},
            backStory: {page: 'be-dashboard', path: location},
            backFeedPost: {page: 'be-dashboard', path: location},
        }));
    }

    render() {
        return (
            <div>
                {/* Notification */}

                <DashboardAppMessage />

                <DashboardModuleProjects />
                <DashboardModuleFavorites />
                <DashboardModuleNew />
                <Divider/>
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { account } = state;
    const { item } = account || {
        item: {},
    }

    return {
        item,
    }
}

export default connect(mapStateToProps)(Dashboard);
