import React from 'react';
import {
    Header,
    Container,
    Divider,
    Message,
    Grid,
    Button,
    Checkbox,
    Icon,
    Loader,
} from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import ProjectNav from '../../../components/layout/ProjectNav';
import { connect } from 'react-redux';
import CustomPopup from '../../../components/CustomPopup/CustomPopup';
import fetchProjectIfNeeded, { deleteProject, directProjectUpdate} from '../../../actions/project'
import {fetchStoryListIfNeeded, directStoryUpdate} from '../../../actions/story'
import BeParent from './be-parent'


class Settings extends BeParent {

    state = {
        popupIsOpen: false,
        currentlyLoading: '',
    }

    componentDidMount() {
        const { dispatch, match } = this.props
        dispatch(fetchProjectIfNeeded(match.params.id))
        dispatch(fetchStoryListIfNeeded(match.params.id))
    }

    render(){
        const {dispatch, listitems, item, isFetching, isDeleted, isUpdating} = this.props;

        if (isDeleted) {
            this.props.history.push('/backend');
            // TODO: I realized that this code will not run if it is called within the nested component. Therefore only the rendered page component can call this function for it to work properly.
            // Maybe dispatch action and set redirect for page component
            return <Redirect to='/backend'/>
        }

        if (!(item && item.id) && isFetching) {
            return (
                <>
                    <ProjectNav active="einstellungen" project={{id: this.props.match.params.id}}/>
                    <Container>
                        <Header as="h2" className="text-placeholder">Einstellungen</Header>
                        <Divider hidden/>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={12}>
                                    <span className="font-small text-placeholder">Fahrzeug/Projekt veröffentlichen</span>
                                </Grid.Column>
                                <Grid.Column width={4} style={{textAlign: "right"}}>
                                    <Checkbox
                                        toggle
                                        disabled
                                        />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={2}>
                                <Grid.Column width={12}>
                                    <span className="font-small text-placeholder">Kosten veröffentlichen</span>
                                </Grid.Column>
                                <Grid.Column width={4} style={{textAlign: "right"}}>
                                    <Checkbox
                                        toggle
                                        disabled
                                        />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={2}>
                                <Grid.Column width={12}>
                                    <span className="font-small text-placeholder">Arbeitszeiten veröffentlichen</span>
                                </Grid.Column>
                                <Grid.Column width={4} style={{textAlign: "right"}}>
                                    <Checkbox
                                        toggle
                                        disabled
                                        />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>

                    </Container>
                </>
            )
        }
        else if (item && item.id) {
            return(
                <>
                    <ProjectNav active="einstellungen"  project={{id: this.props.match.params.id}}/>
                    <Container>
                        <Header as="h2">Einstellungen</Header>
                        <Divider hidden/>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={10}>
                                    <span className="font-small">Projekt veröffentlichen</span>
                                </Grid.Column>
                                <Grid.Column width={6} style={{textAlign: "right"}}>
                                    <Loader active={isUpdating && this.state.currentlyLoading === 'active'} size="small" inline style={{verticalAlign: "baseline", marginRight: '0.5rem'}} />
                                    <Checkbox
                                        disabled={isUpdating}
                                        toggle
                                        className="settings-checkbox-toggle"
                                        name="project.active"
                                        onChange={() => {
                                            if (!isUpdating) {
                                                this.setState({currentlyLoading: 'active'});
                                                dispatch(directProjectUpdate(item.id, 'active', (item.active ? false : true)));
                                            }
                                        }}
                                        checked={item.active}
                                        />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={2}>
                                <Grid.Column width={10}>
                                    <span className="font-small">Kosten veröffentlichen</span>
                                </Grid.Column>
                                <Grid.Column width={6} style={{textAlign: "right"}}>
                                    <Loader active={isUpdating && this.state.currentlyLoading === 'showExpenses'} size="small" inline style={{verticalAlign: "baseline", marginRight: '0.5rem'}} />
                                    <Checkbox
                                        disabled={isUpdating}
                                        toggle
                                        className="settings-checkbox-toggle"
                                        name="project.showExpenses"
                                        onChange={() => {
                                            if (!isUpdating) {
                                                this.setState({currentlyLoading: 'showExpenses'});
                                                dispatch(directProjectUpdate(item.id, 'showExpenses', (item.showExpenses ? false : true)));
                                            }
                                        }}
                                        checked={item.showExpenses}
                                        />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={2}>
                                <Grid.Column width={10}>
                                    <span className="font-small">Arbeitszeiten veröffentlichen</span>
                                </Grid.Column>
                                <Grid.Column width={6} style={{textAlign: "right"}}>
                                    <Loader active={isUpdating && this.state.currentlyLoading === 'showWorkinghours'} size="small" inline style={{verticalAlign: "baseline", marginRight: '0.5rem'}} />
                                    <Checkbox
                                        disabled={isUpdating}
                                        toggle
                                        className="settings-checkbox-toggle"
                                        name="project.showWorkinghours"
                                        onChange={() => {
                                            if (!isUpdating) {
                                                this.setState({currentlyLoading: 'showWorkinghours'});
                                                dispatch(directProjectUpdate(item.id, 'showWorkinghours', (item.showWorkinghours ? false : true)));
                                            }
                                        }}
                                        checked={item.showWorkinghours}
                                        />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>

                        {listitems.length > 0 &&
                            <Header as="h2">Storys veröffentlichen</Header>
                        }
                        <Grid>
                            {listitems.map((story) =>
                                <Grid.Row columns={2}>
                                    <Grid.Column width={10}>
                                        <span className="font-small">{story.item.title}</span>
                                    </Grid.Column>
                                    <Grid.Column width={6} style={{textAlign: "right"}}>
                                        <Loader active={story.isUpdating} size="small" inline style={{verticalAlign: "baseline", marginRight: '0.5rem'}} />
                                        <Checkbox
                                            disabled={story.isUpdating}
                                            toggle
                                            className="settings-checkbox-toggle"
                                            name={"story."+story.item.id+".active"}
                                            onChange={() => {
                                                if (!story.isUpdating) {
                                                    dispatch(directStoryUpdate(story.item.id, 'active', (story.item.active ? false : true)));
                                                }
                                            }}
                                            checked={story.item.active}
                                            />
                                    </Grid.Column>
                                </Grid.Row>
                            )}
                        </Grid>

                        <Divider hidden />
                        <div style={{textAlign: "center"}}>
                            <Button color="red" onClick={() => this.setState({popupIsOpen: true})}>Fahrzeug/Projekt löschen</Button>
                        </div>
                    </Container>
                    {/* Delete Project Confirm*/}

                    <Divider hidden/>

                    <CustomPopup
                        popupHeader="Fahrzeug/Projekt löschen"
                        popupOpen={this.state.popupIsOpen}
                        onClose={() => this.setState({popupIsOpen: false})}
                        >
                            {this.confirmDeletePopup()}
                    </CustomPopup>
                </>
            )
        }
        else {
            return (
                <Container text>
                    <Divider hidden/>
                    <Message negative>Es ist ein Fehler aufgetreten.</Message>
                </Container>
            )
        }
    }

    confirmDeletePopup(){
        // TODO: Better use isDeleted
        const { item, isFetching } = this.props;
        return(
            <Container>
                <Divider hidden/>
                <div style={{textAlign: "center", margin: "1rem 0"}}>
                    <Icon name="warning sign" color="red" size="huge"/>
                </div>
                <div style={{textAlign: "center"}}>Willst du das Projekt wirklich löschen?</div>
                <Divider hidden/>
                <div className="buttons-center">
                    <Button loading={isFetching} basic color="red" onClick={() => {
                        if (!isFetching) {
                            this.props.dispatch(deleteProject(item.id));
                        }
                    }}>
                        Löschen
                    </Button>
                    <Button color="red" onClick={() => {
                        if (!isFetching) {
                            this.setState({popupIsOpen: false});
                        }
                    }}>
                        Zurück
                    </Button>
                </div>
                <Divider hidden/>
            </Container>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { globalByComponent, projectById, listByEntity, storyById } = state

    const { popupOpen } = globalByComponent[
        'CustomPopup'
    ] || {
        popupOpen: false,
    }

    const { item, isFetching, isFailed, isDeleted, isUpdating } = projectById[
        ownProps.match.params.id
    ] || {
        item: {},
        isFetching: true,
        isFailed: false,
        isDeleted: false,
    }

    const { list, isFetching: isFetching2, isFailed: isFailed2 } = listByEntity[
        ownProps.match.params.id+'::story'
    ] || {
        list: {},
        isFetching: true,
        isFailed: false,
    }

    var listitems = [];

    if (list) {
        for (let [key, value] of Object.entries(list)) {
            if (!storyById[value].isDeleted) {
                listitems.push(storyById[value]);
            }
        }
    }

    return {
        listitems,
        popupOpen,
        item,
        isFetching: (isFetching || isFetching2),
        isFailed: (isFailed || isFailed2),
        isDeleted,
        isUpdating,
    }
}

export default connect(mapStateToProps)(Settings)
