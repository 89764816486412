import React from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { changeMessage, updateMessage } from '../../actions/forumthread';
import tinymce from 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/emoticons/js/emojis';
import 'tinymce/plugins/paste';
import { Editor } from '@tinymce/tinymce-react';
import '../../tinymce/langs/de';
import config from '../../config/config'
import PreviewGallery from '../PreviewGallery/PreviewGallery';
import { FileUpload } from 'ix-redux-file-upload';
import BeParent from '../../pages/project/backend/be-parent';
import { Container, Form, Button, Divider, Image, Grid } from 'semantic-ui-react';
import cameraIconRed from '../../images/icons/frontend/icon-camera-red.svg';
import AddIcon from '../../images/icons/circle-add-element.svg';
import CustomPopup from '../../components/CustomPopup/CustomPopup';

class EditPost extends BeParent {

    componentDidUpdate = (prevProps) => {
        const { closeModal, message } = this.props;
        //Close modal if message was succesfuly updated
        if(prevProps.message.isUpdating && !message.isUpdating) {
            closeModal();
        }
    }

    handleChangeMessage = (content) => {
        const { dispatch, match, message } = this.props;

        dispatch(changeMessage(match.params.id, message.id, match.params.page ? match.params.page : 1, content, message.inputMedia ? message.inputMedia : message.media))
    }

    handleDeleteImage = (id) => {
        const { dispatch, message, match } = this.props;

        let updatedArray = message.inputMedia.filter(item => item.id !== id);

        dispatch(changeMessage(match.params.id, message.id, match.params.page ? match.params.page : 1, message.inputText ? message.inputText: message.text, updatedArray ))
    }

    handleRevertChanges = () => {
        const { dispatch, message, closeModal, match } = this.props;

        dispatch(changeMessage(match.params.id, message.id, match.params.page ? match.params.page : 1, message.text, message.media))
        closeModal();
    }

    handleSaveChanges = () => {
        const { dispatch, message, threadItem, match } = this.props;

        dispatch(updateMessage(threadItem.title, message, match.params.page ? match.params.page : 1));
    }

    render(){
        const { messageData, token, message, match } = this.props;
        return (
            <div className="post-edit">
                <Container>
                    <Form className="question-content">
                        <Form.Field>
                            <label>Dein Post</label>
                            <Editor
                                value={message.inputText ? message.inputText : message.text}
                                init={{...config.TINY_INIT}}
                                onEditorChange={(e) => this.handleChangeMessage(e)}
                            />
                        </Form.Field>
                        <h2>Bilder und Videos</h2>
                        <Form.Field>
                            <FileUpload
                                headers={{ 'Authorization': 'Bearer '+token }}
                                allowedFileTypes={['jpg', 'jpeg']}
                                dropzoneId={`${message.forumThread}.${match.params.page ? match.params.page : 1}.${message.id}.inputMedia`}
                                multiple={true}
                                url={config.API_HOST + config.API_UPLOAD}
                                >
                                    <div className="button-element">
                                        <Button basic color="red" className="single-button">
                                            <Image src={cameraIconRed} alt="Icon Kamera" className="button-icon"/>
                                            <span>Bilder/Videos hinzufügen</span>
                                        </Button>
                                    </div>
                            </FileUpload>
                            <Divider hidden/>

                            {message.inputMedia &&
                                <PreviewGallery
                                    images={message.inputMedia}
                                    deletable
                                    requestDelete={this.handleDeleteImage}
                                />
                            }
                        </Form.Field>
                    </Form>
                    <Divider hidden/>
                    <Grid>
                        <Grid.Column width={16} textAlign="center">
                            <Button
                                color="red"
                                loading={message.isUpdating}
                                disabled={!message.inputText || (message.inputText === message.text && JSON.stringify(message.inputMedia) === JSON.stringify(message.media)) ? true : false}
                                onClick={this.handleSaveChanges}
                            >
                                Änderungen speichern
                            </Button>
                        </Grid.Column>
                    </Grid>

                    <Divider hidden/>

                </Container>
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { account, forumthreadById } = state;

    const { token } = account || {
        token: ''
    }

    const { pages } = forumthreadById[ownProps.messageData.forumThread] || {
        pages: {}
    }

    let message = Object.values(pages).length > 0 && pages[ownProps.match.params.page ? ownProps.match.params.page : 1][ownProps.messageData.id].forummessage;

    return {
        token,
        message
    };
}


export default withRouter(connect(mapStateToProps)(EditPost));
