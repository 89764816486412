
import { createAction } from 'redux-api-middleware';
import config from '../config/config';

export const NEW_PROFESSIONAL = "NEW_PROFESSIONAL";
export const API_POST_PROFESSIONAL_REQUEST = 'API_POST_PROFESSIONAL_REQUEST';
export const API_POST_PROFESSIONAL_SUCCESS = 'API_POST_PROFESSIONAL_SUCCESS';
export const API_POST_PROFESSIONAL_FAILURE = 'API_POST_PROFESSIONAL_FAILURE';
export const API_PROFESSIONAL_REQUEST = "API_PROFESSIONAL_REQUEST";
export const API_PROFESSIONAL_SUCCESS = "API_PROFESSIONAL_SUCCESS";
export const API_PROFESSIONAL_FAILURE = "API_PROFESSIONAL_FAILURE";
export const API_PROFESSIONAL_LIST_REQUEST = "API_PROFESSIONAL_LIST_REQUEST";
export const API_PROFESSIONAL_LIST_SUCCESS = "API_PROFESSIONAL_LIST_SUCCESS";
export const API_PROFESSIONAL_LIST_FAILURE = "API_PROFESSIONAL_LIST_FAILURE";
export const RESET_PROFESSIONAL_LIST = "RESET_PROFESSIONAL_LIST";
export const RESET_USER_PROFESSIONAL_LIST = "RESET_USER_PROFESSIONAL_LIST";
export const CLEAR_NEW_PROFESSIONAL = "CLEAR_NEW_PROFESSIONAL";
export const NEW_PROFESSIONAL_ADD_TAG = "NEW_PROFESSIONAL_ADD_TAG";
export const NEW_PROFESSIONAL_REMOVE_TAG = "NEW_PROFESSIONAL_REMOVE_TAG";
export const NEW_PROFESSIONAL_SAVE_TAG = "NEW_PROFESSIONAL_SAVE_TAG";
export const NEW_PROFESSIONAL_REVERT_TAG = "NEW_PROFESSIONAL_REVERT_TAG";
export const NEW_PROFESSIONAL_DELETE_DIRECT_TAG = "NEW_PROFESSIONAL_DELETE_DIRECT_TAG";
export const API_PROFESSIONAL_USER_REQUEST = "API_PROFESSIONAL_USER_REQUEST";
export const API_PROFESSIONAL_USER_SUCCESS = "API_PROFESSIONAL_USER_SUCCESS";
export const API_PROFESSIONAL_USER_FAILURE = "API_PROFESSIONAL_USER_FAILURE";
export const API_PROFESSIONAL_DIRECT_REQUEST = "API_PROFESSIONAL_DIRECT_REQUEST";
export const API_PROFESSIONAL_DIRECT_SUCCESS = "API_PROFESSIONAL_DIRECT_SUCCESS";
export const API_PROFESSIONAL_DIRECT_FAILURE = "API_PROFESSIONAL_DIRECT_FAILURE";
export const API_PROFESSIONAL_DELETE_SUCCESS = "API_PROFESSIONAL_DELETE_SUCCESS";
export const CHANGE_PROFESSIONAL = "CHANGE_PROFESSIONAL";
export const API_UPDATE_PROFESSIONAL_REQUEST = "API_UPDATE_PROFESSIONAL_REQUEST";
export const API_UPDATE_PROFESSIONAL_SUCCESS = "API_UPDATE_PROFESSIONAL_SUCCESS";
export const API_UPDATE_PROFESSIONAL_FAILURE = "API_UPDATE_PROFESSIONAL_FAILURE";
export const API_PROFESSIONAL_PAYMENT_ID_REQUEST = "API_PROFESSIONAL_PAYMENT_ID_REQUEST";
export const API_PROFESSIONAL_PAYMENT_ID_SUCCESS = "API_PROFESSIONAL_PAYMENT_ID_SUCCESS";
export const API_PROFESSIONAL_PAYMENT_ID_FAILURE = "API_PROFESSIONAL_PAYMENT_ID_FAILURE";


export function clearNewProfessional(){
    return {
        type: CLEAR_NEW_PROFESSIONAL
    }
}

export function resetUserProfessionalList(id){
    return {
        type: RESET_USER_PROFESSIONAL_LIST,
        payload: {
            id
        }
    }
}

export function resetProfessionalList(){
    return {
        type: RESET_PROFESSIONAL_LIST
    }
}

export function newProfessional(inputProfessional) {
    return {
        type: NEW_PROFESSIONAL,
        payload: {
            inputProfessional,
        }
    }
}

export function postProfessional(inputProfessional) {
    console.log(inputProfessional);
    return createAction({
        endpoint: `${config.API_HOST}/v1/professional`,
        method: 'POST',
        body: JSON.stringify({
            name: inputProfessional.name,
            description: inputProfessional.description,
            street: inputProfessional.street,
            zip: inputProfessional.zip,
            city: inputProfessional.city,
            countrycode: inputProfessional.countrycode,
            latitude: inputProfessional.latitude ? inputProfessional.latitude : null,
            longitude: inputProfessional.longitude ? inputProfessional.longitude : null,
            scopes: inputProfessional.scopes,
            types: inputProfessional.types,
            owner: inputProfessional.owner,
            website: inputProfessional.website,
            phone: inputProfessional.phone,
            email: inputProfessional.email,
            headline: inputProfessional.headline,
            intro: inputProfessional.intro,
            benefits: inputProfessional.benefits,
            media: inputProfessional.media,
            medialogo: inputProfessional.logo ? inputProfessional.logo[0].id : null,
            mediavideo: inputProfessional.mediavideo ? inputProfessional.mediavideo[0].id : null,
            mediamain: inputProfessional.mediamain ? inputProfessional.mediamain[0].id : null,
            categories: inputProfessional.categories
        }),
        types: [
            {type: API_POST_PROFESSIONAL_REQUEST},
            {type: API_POST_PROFESSIONAL_SUCCESS},
            {type: API_POST_PROFESSIONAL_FAILURE},
        ]
    })
}

export function directProfessionalUpdate(id, key, value) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/professional/${id}`,
        method: 'PUT',
        body: JSON.stringify({
            professional: {
                [key]: value,
            }
        }),
        types: [
            {type: API_PROFESSIONAL_DIRECT_REQUEST, meta: { id }},
            {type: API_PROFESSIONAL_DIRECT_SUCCESS, meta: { id }},
            {type: API_PROFESSIONAL_DIRECT_FAILURE, meta: { id }},
        ]
    })
}

export function approvePayment(id, value) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/professional/${id}`,
        method: 'PUT',
        body: JSON.stringify({
            payment: {
                ext_id: value,
            }
        }),
        types: [
            {type: API_PROFESSIONAL_PAYMENT_ID_REQUEST, meta: { id }},
            {type: API_PROFESSIONAL_PAYMENT_ID_SUCCESS, meta: { id }},
            {type: API_PROFESSIONAL_PAYMENT_ID_FAILURE, meta: { id }},
        ]
    })
}

export function deleteProfessional(id) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/professional/${id}`,
        method: 'DELETE',
        types: [
            {type: API_PROFESSIONAL_REQUEST, meta: { id }},
            {type: API_PROFESSIONAL_DELETE_SUCCESS, meta: { id }},
            {type: API_PROFESSIONAL_FAILURE, meta: { id }},
        ]
    })
}

export function changeProfessional(id, input) {
    return {
        type: CHANGE_PROFESSIONAL,
        meta: {
            id,
        },
        payload: {
            input
        }
    }
}

export function updateProfessional(id, changes) {
    console.log(changes);
    return createAction({
        endpoint: `${config.API_HOST}/v1/professional/${id}`,
        method: 'PUT',
        body: JSON.stringify({
            professional: changes
        }),
        types: [
            {type: API_PROFESSIONAL_REQUEST, meta: {id}},
            {type: API_PROFESSIONAL_SUCCESS, meta: {id}},
            {type: API_PROFESSIONAL_FAILURE, meta: {id}},
        ]
    })
}
export function fetchProfessionalList(options, page, size, order) {
    if (!options) {options = {}}
    if (!page) {page = 1}
    if (!order) {order = config.ORDER_DISTANCE}
    if (!size) {size = 30} //TODO: Use config variable to set page size

    var query = '';
    for (let [key, value] of Object.entries(options)) {
        query += '&'+encodeURIComponent(key)+'='+encodeURIComponent(value);
    }

    return createAction({
        endpoint: `${config.API_HOST}/v1/professional?page=${page}&size=${size}&professional_list_order=${order}${query}`,
        method: 'GET',
        types: [
            {type: API_PROFESSIONAL_LIST_REQUEST, meta: { page, order }},
            {type: API_PROFESSIONAL_LIST_SUCCESS, meta: { page, order }},
            {type: API_PROFESSIONAL_LIST_FAILURE, meta: { page, order }},
        ]
    })
}

export function fetchProfessional(id) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/professional/${id}`,
        method: 'GET',
        types: [
            {type: API_PROFESSIONAL_REQUEST, meta: { id }},
            {type: API_PROFESSIONAL_SUCCESS, meta: { id }},
            {type: API_PROFESSIONAL_FAILURE, meta: { id }},
        ]
    })
}

function shouldFetchProfessional(state) {
    const professionalList = state.listByEntity['professional'];
    if (
        !professionalList ||
        ((professionalList.isFetching || professionalList.isFailed) && Date.now() - professionalList.lastFetch > config.FETCH_TIMEOUT)
    ) {
        return true
    }
    return false
}

export function fetchProjectListIfNeeded(id) {
    return (dispatch, getState) => {
        if (shouldFetchProfessional(getState())) {
            return dispatch(fetchProfessional(id))
        }
    }
}

function shouldFetchProfessionalList(state) {
    const list = state.listByEntity['professional'];
    if (
        !list ||
        ((list.isFetching || list.isFailed) && Date.now() - list.lastFetch > config.FETCH_TIMEOUT)
    ) {
        return true
    }
    return false
}

export function fetchProfessionalListIfNeeded(options, page, size) {
    return (dispatch, getState) => {
        if (shouldFetchProfessionalList(getState())) {
            return dispatch(fetchProfessionalList(options, page, size))
        }
    }
}

export function newProfessionalAddTag(tag){
    return {
        type: NEW_PROFESSIONAL_ADD_TAG,
        payload: {
            tag
        }
    }
}

export function newProfessionalRemoveTag(tag){
    return {
        type: NEW_PROFESSIONAL_REMOVE_TAG,
        payload: {
            tag
        }
    }
}

export function newProfessionalSaveTag(tag){
    return {
        type: NEW_PROFESSIONAL_SAVE_TAG,
        payload: {
            tag
        }
    }
}

export function newProfessionalRevertTag(tag){
    return {
        type: NEW_PROFESSIONAL_REVERT_TAG,
        payload: {
            tag
        }
    }
}

export function newProfessionalDeleteDirectTag(tag){
    return {
        type: NEW_PROFESSIONAL_DELETE_DIRECT_TAG,
        payload: {
            tag
        }
    }
}

function shouldFetchUserProfessional(state, id){
    const projectlist = state.listByEntity[id+'::userProfessional'];
    if (
        !projectlist ||
        ((projectlist.isFetching || projectlist.isFailed) && Date.now() - projectlist.lastFetch > config.FETCH_TIMEOUT) ||
        (!projectlist.isFetching && Date.now() - (projectlist.lastUpdated ? projectlist.lastUpdated : 0) > config.FETCH_AGAIN)
    ) {
        return true
    }
    return false
}

export function fetchUserProfessionalIfNeeded(id) {
    return (dispatch, getState) => {
        if (shouldFetchUserProfessional(getState(), id)) {
            return dispatch(fetchUserProfessional(id))
        }
    }
}

export function fetchUserProfessional(userId, size){
    if(!size) {size = 100;}

    return createAction({
        endpoint: `${config.API_HOST}/v1/professional?user=${userId}&size=${size}&professional_list_order=${config.ORDER_NAME}`,
        method: 'GET',
        types: [
            {type: API_PROFESSIONAL_USER_REQUEST, meta: { userId }},
            {type: API_PROFESSIONAL_USER_SUCCESS, meta: { userId }},
            {type: API_PROFESSIONAL_USER_FAILURE, meta: { userId }},
        ]
    })
}