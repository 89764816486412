import {
    CHANGE_PROJECT,
    CHANGE_PROJECT_NODIRTY,
    API_PROJECT_REQUEST,
    API_PROJECT_SUCCESS,
    API_PROJECT_SUCCESS_NODIRTY,
    API_PROJECT_FAILURE,
    API_PROJECT_DELETE_SUCCESS,
    API_PROJECT_LIST_SUCCESS,
    API_USERPROJECT_SUCCESS,
    API_FAVPROJECT_SUCCESS,
    API_NEW_PROJECT_LIST_SUCCESS,
    NEW_STORY,
    API_POST_STORY_REQUEST,
    API_POST_STORY_SUCCESS,
    API_POST_STORY_FAILURE,
    API_LIKE_PROJECT_REQUEST,
    API_LIKE_PROJECT_SUCCESS,
    API_LIKE_PROJECT_FAILURE,
    API_FOLLOW_PROJECT_REQUEST,
    API_FOLLOW_PROJECT_SUCCESS,
    API_FOLLOW_PROJECT_FAILURE,
    API_PROJECT_DIRECT_REQUEST,
    API_PROJECT_DIRECT_SUCCESS,
    API_PROJECT_DIRECT_FAILURE,
} from '../actions/project'
import {
    API_STORY_SUCCESS,
    API_STORY_DIRECT_SUCCESS,
    API_STORY_SORT_SUCCESS,
} from '../actions/story'
import {
    API_POST_PROJECT_SUCCESS,
    RESET_STORE,
} from '../actions/account'
import {
    API_POST_COMMENT_SUCCESS,
} from '../actions/comments';
import {actions} from 'ix-redux-file-upload';
import { FETCH_BRAND_PROJECTS_SUCCESS } from '../actions/global';


export default function projectById(state = {}, action) {

    try {

        if (action.type === RESET_STORE) {
            return {};
        }

        if (action.type === API_PROJECT_DIRECT_REQUEST) {
            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    isUpdating: true,
                }
            };
        }
        if (action.type === API_PROJECT_DIRECT_SUCCESS) {
            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    item: {
                        ...state[action.meta.id].item,
                        ...action.payload.project,
                    },
                    isUpdating: false,
                }
            };
        }
        if (action.type === API_PROJECT_DIRECT_FAILURE) {
            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    isUpdating: false,
                }
            };
        }

        if(action.type === API_POST_COMMENT_SUCCESS){
            //Update comment count
            if(action.payload.comment.project && state[action.payload.comment.project]){
                return {
                    ...state,
                    [action.payload.comment.project]: {
                        ...state[action.payload.comment.project],
                        item: {
                            ...state[action.payload.comment.project].item,
                            countComment: action.payload.commentcount
                        }
                    }
                }

            }
        }

        if (action.type === CHANGE_PROJECT || action.type === CHANGE_PROJECT_NODIRTY) {
            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    isDirty: (action.type === CHANGE_PROJECT_NODIRTY ? state[action.meta.id].isDirty : true),
                    item: {
                        ...state[action.meta.id].item,
                        ...action.payload.item,
                    },
                    tdo: {
                        ...state[action.meta.id].tdo,
                        ...action.payload.tdo,
                    },
                    td: {
                        ...state[action.meta.id].td,
                        ...action.payload.td,
                    },
                    vehicle: {
                        ...state[action.meta.id].vehicle,
                        ...action.payload.vehicle,
                    },
                    brand: {
                        ...state[action.meta.id].brand,
                        ...action.payload.brand,
                    },
                    manufacturer: {
                        ...state[action.meta.id].manufacturer,
                        ...action.payload.manufacturer,
                    },
                }
            };
        }
        else if (action.type === API_PROJECT_REQUEST) {
            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    isFetching: true,
                    lastFetch: Date.now(),
                }
            };
        }
        else if (
            action.type === API_PROJECT_SUCCESS ||
            action.type === API_POST_PROJECT_SUCCESS ||
            action.type === API_PROJECT_SUCCESS_NODIRTY
        ) {
            return {
                ...state,
                [action.payload.project.id]: {
                    ...state[action.payload.project.id],
                    isDirty: (action.type === API_PROJECT_SUCCESS_NODIRTY ? state[action.payload.project.id].isDirty : false),
                    isFetching: false,
                    isFailed: false,
                    isDeleted: false,
                    item: (action.type === API_PROJECT_SUCCESS_NODIRTY ? state[action.payload.project.id].item : {
                        ...action.payload.project,
                    }),
                    tdo: {
                        ...action.payload.technicaldataOverride,
                        input: JSON.parse(JSON.stringify(action.payload.technicaldataOverride)),
                    },
                    td: {
                        ...action.payload.technicaldata,
                        input: JSON.parse(JSON.stringify(action.payload.technicaldata)),
                    },
                    vehicle: {
                        ...action.payload.vehicle,
                        input: JSON.parse(JSON.stringify(action.payload.vehicle)),
                    },
                    brand: {
                        ...action.payload.brand,
                        input: JSON.parse(JSON.stringify(action.payload.brand)),
                    },
                    manufacturer: {
                        ...action.payload.manufacturer,
                        input: {...JSON.parse(JSON.stringify(action.payload.manufacturer))},
                    },
                    sumMedias: action.payload.sumMedias,
                    
                    lastUpdated: Date.now(),
                }
            };
        }
        else if (action.type === API_PROJECT_FAILURE) {
            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    isFetching: false,
                    isFailed: true,
                    lastUpdated: Date.now(),
                }
            };
        }
        else if (action.type === API_PROJECT_DELETE_SUCCESS) {
            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    isDirty: false,
                    isFetching: false,
                    isFailed: false,
                    isDeleted: true,
                    item: {},
                    tdo: {},
                    td: {},
                    vehicle: {},
                    brand: {},
                    manufacturer: {},
                    sumMedias: [],
                    lastUpdated: Date.now(),
                }
            };
        }
        else if (action.type === actions.FILE_UPLOAD_PROGRESS) {
            var identificator = action.payload.identificator.split('.');

            if (identificator[0] === 'project' && !identificator[3]) {
                return {
                    ...state,
                    [identificator[1]]: {
                        ...state[identificator[1]],
                        isDirty: true,
                        item: {
                            ...state[identificator[1]].item,
                            [identificator[2] + 'Path']: 'LOADING',
                            [identificator[2] + "Progress"]: action.payload.progress
                        },
                    }
                };
            }
            else if (identificator[0] === 'newStoryForProject') {
                return {
                    ...state,
                    [identificator[1]]: {
                        ...state[identificator[1]],
                        'inputMediaPath': 'LOADING',
                        [identificator[2] + "Progress"]: action.payload.progress
                    }
                };
            }
        }
        
        else if (action.type === actions.FILE_UPLOAD_COMPLETE) {
            identificator = action.payload.identificator.split('.');

            if (identificator[0] === 'project' && !identificator[3]) {
                return {
                    ...state,
                    [identificator[1]]: {
                        ...state[identificator[1]],
                        isDirty: true,
                        item: {
                            ...state[identificator[1]].item,
                            [identificator[2]]: action.payload.response.id,
                            [identificator[2] + 'Path']: action.payload.response.path,
                        },
                    }
                };
            }
            else if (identificator[0] === 'newStoryForProject') {
                return {
                    ...state,
                    [identificator[1]]: {
                        ...state[identificator[1]],
                        isDirty: true,
                        'inputMedia': action.payload.response.id,
                        'inputMediaPath': action.payload.response.path,
                    }
                };
            }
        }

        if (action.type === actions.FILE_UPLOAD_ERROR) {
        
            var identificator = action.payload.identificator.split('.');
            if (identificator[0] === 'project' && !identificator[3]) {
                console.log("Error")
                return {
                    ...state,
                    [identificator[1]]: {
                        ...state[identificator[1]],
                        isDirty: true,
                        item: {
                            ...state[identificator[1]].item,
                            [identificator[2]]: "ERROR",
                            [identificator[2] + 'Path']: "ERROR",
                        },
                    }
                };
            }
        }

        else if (
            action.type === API_PROJECT_LIST_SUCCESS ||
            action.type === API_USERPROJECT_SUCCESS ||
            action.type === API_FAVPROJECT_SUCCESS ||
            action.type === API_NEW_PROJECT_LIST_SUCCESS ||
            action.type === FETCH_BRAND_PROJECTS_SUCCESS
            ) {

            action.payload.list.map((pl) => {
                if (!state[pl.project.id]) {
                    state = {
                        ...state,
                        [pl.project.id]: {
                            item: {},
                            tdo: {},
                            td: {},
                            vehicle: {},
                            brand: {},
                            manufacturer: {},
                            sumMedias: [],
                        }
                    }
                }

                state = {
                    ...state,
                    [pl.project.id]: {
                        ...state[pl.project.id],
                        isDirty: false,
                        isFetching: false,
                        isFailed: false,
                        item: {
                            ...pl.project,
                        },
                        tdo: {
                            ...pl.technicaldataOverride,
                            input: JSON.parse(JSON.stringify(pl.technicaldataOverride)),
                        },
                        td: {
                            ...pl.technicaldata,
                            input: JSON.parse(JSON.stringify(pl.technicaldata)),
                        },
                        vehicle: {
                            ...pl.vehicle,
                            input: JSON.parse(JSON.stringify(pl.vehicle)),
                        },
                        brand: {
                            ...pl.brand,
                            input: JSON.parse(JSON.stringify(pl.brand)),
                        },
                        manufacturer: {
                            ...pl.manufacturer,
                            input: {...JSON.parse(JSON.stringify(pl.manufacturer))},
                        },
                        sumMedias: pl.sumMedias,
                        lastUpdated: Date.now(),
                    }
                }
            }
            );
        }

        else if (action.type === NEW_STORY) {
            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    ...action.payload,
                }
            };
        }
        else if (action.type === API_POST_STORY_REQUEST) {
            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    isPosting: true,
                    postingMessage: '',
                }
            };
        }
        else if (action.type === API_POST_STORY_SUCCESS) {
            state = {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    isPosting: false,
                    postingMessage: 'SUCCESS',
                    inputTitle: '',
                    inputDesc: '',
                    inputCategory: null,
                    inputMedia: null,
                    inputMediaPath: '',
                }
            };
        }
        else if (action.type === API_POST_STORY_FAILURE) {
            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    isPosting: false,
                    postingMessage: 'FAILURE',
                }
            };
        }

        else if(action.type === API_LIKE_PROJECT_REQUEST){

            if(state[action.meta.id]) {
                return {
                    ...state,
                    [action.meta.id]: {
                        ...state[action.meta.id],
                        item: {
                            ...state[action.meta.id].item,
                            userLike: action.meta.like,
                            isLiking: true,
                        }
                    }
                }
            }
            else {
                return state
            }
        }
        else if(action.type === API_LIKE_PROJECT_SUCCESS){
            var countLike = action.meta.count;
            if(state[action.meta.id]) {
                if (action.meta.like === action.payload) {
                    countLike = action.payload ? parseInt(countLike) +1 : parseInt(countLike) - 1
                }

                return {
                    ...state,
                    [action.meta.id]: {
                        ...state[action.meta.id],
                        item: {
                            ...state[action.meta.id].item,
                            countLike,
                            userLike: action.payload,
                            isLiking: false,
                        }
                    }
                }
            }

            return state;

        }
        else if(action.type === API_LIKE_PROJECT_FAILURE){
            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    item: {
                        ...state[action.meta.id].item,
                        userLike: (action.meta.like ? false : true),
                        isLiking: false,
                    }
                }
            }
        }

        else if(action.type === API_FOLLOW_PROJECT_REQUEST){
            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    item: {
                        ...state[action.meta.id].item,
                        userFavorite: action.meta.follow,
                        isFollowing: true,
                    }
                }
            }
        }
        else if(action.type === API_FOLLOW_PROJECT_SUCCESS){
            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    item: {
                        ...state[action.meta.id].item,
                        userFavorite: action.payload,
                        isFollowing: false,
                    }
                }
            }
        }
        else if(action.type === API_FOLLOW_PROJECT_FAILURE){
            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    item: {
                        ...state[action.meta.id].item,
                        userFavorite: (action.meta.follow ? false : true),
                        isFollowing: false,
                    }
                }
            }
        }

        else if (action.type === API_STORY_SUCCESS || action.type === API_STORY_DIRECT_SUCCESS) {
            if (state[action.payload.projectpart.project] && state[action.payload.projectpart.project].item) {
                var current = state[action.payload.projectpart.project].item.projectpartsData.filter(pp => pp.id === action.payload.projectpart.id);

                return {
                    ...state,
                    [action.payload.projectpart.project]: {
                        ...state[action.payload.projectpart.project],
                        item: {
                            ...state[action.payload.projectpart.project].item,
                            projectpartsData: [
                                ...state[action.payload.projectpart.project].item.projectpartsData.filter(pp => pp.id !== action.payload.projectpart.id),
                                {
                                    ...(current ? current[0] : {}),
                                    ...action.payload.projectpart,
                                },
                            ],
                        },
                        isUpdating: false,
                    }
                };
            }
        }

        else if (action.type === API_STORY_SORT_SUCCESS) {

            var ppData = [];

            for (let [id, pos] of Object.entries(action.payload.projectparts)) {
                var current = state[action.meta.project].item.projectpartsData.filter(pp => parseInt(pp.id) === parseInt(id));

                if (current && current[0]) {
                    ppData.push({
                        ...current[0],
                        position: pos,
                    });
                }
            }

            return {
                ...state,
                [action.meta.project]: {
                    ...state[action.meta.project],
                    item: {
                        ...state[action.meta.project].item,
                        projectpartsData: ppData,
                    },
                    isUpdating: false,
                }
            };
        }

        return state;

    } catch (e) {

        console.log(e);
        return {
            ...state,
            fatalError: true,
        };

    }
}
