import React, { Component } from 'react'
import { Header, Container, Grid, Divider, Button, Image, Responsive } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { footerActiveItem, handleContainer, handleHeaderItems } from '../actions/global';
import Rektol from '../images/logo-rektol.png';
import LordGeorgeLogo from '../images/Lord_George_Logo_rgb-01-300x213.png';
import './Pages.scss';

class Partner extends Component {

    componentDidMount = () => {
        const { dispatch } = this.props;
        dispatch(handleContainer("main", "Partner", null, null, null, null, {description: 'Vetero pflegt Partnerschaften zu anderen Unternehmen aus der Classic-Fahrzeug-Branche. Wir kooperieren ausschließlich mit Firmen, von deren Produkten, Leistung und Qualität wir 100%ig überzeugt sind.'}));
        dispatch(handleHeaderItems("dots-menu"))
        dispatch(footerActiveItem('footermenu'))
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <Container className="vetero-partner">
                
                <section>
                    <Grid divided="vertically">

                        <Grid.Row>
                            <Grid.Column width={16}>

                                <Header as="h2">Vetero.de-Partner</Header>

                                <p>
                                    Wir pflegen Partnerschaften zu anderen Unternehmen aus der Classic-Fahrzeug-Branche. Wir kooperieren ausschließlich mit Firmen,
                                    von deren Produkten, Leistung und Qualität wir 100%ig überzeugt sind. Diese empfehlen wir dir gerne und mit gutem Gewissen. Wo immer möglich, erhälst du als Vetero.de-User noch einen Kostenvorteil.
                                </p>

                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Header as="h2">{`Touren ${'&'} Rallyes!`}</Header>
                            </Grid.Column>

                            <Responsive {...Responsive.onlyMobile} className="partner-logo">
                                <Grid.Column only="mobile" mobile={16} tablet={4} computer={4} textAlign="center">
                                    <Image src={LordGeorgeLogo} alt="Rektol"/>
                                </Grid.Column>
                            </Responsive>

                            <Grid.Column mobile={16} tablet={12} computer={12}>
                                <p>
                                    Lust auf eine Rally in einem anderen Oldtimer oder suchst Du ein  besonderes Geschenk für einen Oldtimer Fan? Bei Lord George kannst Du aus einer ganzen Flotte von Oldtimern wählen, Dir Dein Lieblingsfahrzeug aussuchen und bei einer der dort angebotenen Oldtimer-Rallys mitfahren. 
                                </p>
                                <p>
                                    Auch ein Oldtimer mit Chauffeur für eine Hochzeit, Firmenevents und andere spannende Dinge rund um Oldtimer sind möglich. Als Vetero-Mitglied nach Login auf <a href="https://www.vetero.de">vetero.de</a>, im Bereich „Gutscheine“ 10 % Rabatt sichern</p>
                                <a href="https://lord-george.de/" target="_blank" and rel="noopener noreferrer">
                                    <Button color="red">
                                        www.lord-george.de
                                    </Button>
                                </a>
                            </Grid.Column>

                            <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                                <Grid.Column mobile={16} tablet={4} computer={4} textAlign="center">
                                    <Image src={LordGeorgeLogo} alt="Lord George Logo"/>
                                </Grid.Column>
                            </Responsive>
            
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Header as="h2">Schmierstoffe</Header>
                            </Grid.Column>
                            <Grid.Column mobile={16} tablet={4} computer={4} textAlign="center">
                                <Image src={Rektol} alt="Rektol"/>
                            </Grid.Column>
                            <Grid.Column mobile={16} tablet={12} computer={12}>
                                <p>
                                    Es gibt sie noch, die Originale – REKTOL-Klassik-Öle in Originalrezeptur. Die über 125-jährige Markterfahrung und Entwicklungskompetenz von REKTOL ermöglichen die Bestimmung und Herstellung von seinerzeit gültigen Original-Spezifikationen und -Ölrezepturen für jeden schmiertechnischen Einsatz
                                    in klassischen Fahrzeugen und Maschinen. Moderner Online-Shop, in dem du bequem einkaufen kannst.
                                </p>
                                <p>
                                    Als Vetero-Mitglied mit echtem Projekt kannst du dir nach Login auf vetero.de, im Bereich "Gutscheine", <b>Rabattcodes</b> downloaden.
                                </p>
                                <a href="https://www.rektol-klassik.de/" target="_blank" and rel="noopener noreferrer">
                                    <Button color="red">
                                        www.rektol-klassik.de
                                    </Button>
                                </a>
                            </Grid.Column>
                        </Grid.Row>


                    </Grid>
                </section>
                <Divider hidden/>
            </Container>
        )
    }
}

export default connect()(Partner);
