import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Grid, Container, Header, Image, Button, Pagination, Placeholder, Label, Divider, TableFooter } from 'semantic-ui-react';
import { getPostListIfNeeded, resetBlogList, getBlogTagsIfNeeded, getTotalCount, getPostList } from '../../actions/blog'
import {
    footerActiveItem,
    handleContainer,
    handleHeaderItems,
    setRuntime
} from "../../actions/global";
import HeaderImage from "../../components/HeaderImage/HeaderImage";
import PlaceholderImage from '../../images/header-empty.jpg'
import HeaderImg from '../../images/about-header.jpg';
import TagFilter from './TagFilter';
import ErrorHandler from '../../components/ErrorHandler/ErrorHandler';
import Truncate from 'react-truncate'
import MetaTag from 'react-meta-tags';
import { StructuredBlogData } from '../../components/StructuredData/StructuredData';
import { Helmet } from 'react-helmet';

var striptags = require('striptags');

class Postlist extends Component {

    state = {
        activeTag: "alle",
        activePage: 1
    }

    componentDidMount = () => {
        const { dispatch, match } = this.props;
        //Fetch posts
        dispatch(getBlogTagsIfNeeded());
        dispatch(handleHeaderItems("blog"));
        dispatch(getTotalCount());
        /* TODO: Meta description */
        dispatch(
            handleContainer("blog", "SPAN#Oldtimer Blog", null, null, null, null, {
              description:
                "Der Oldtimer Blog für alle Schrauber und Fans von Oldtimern. Vom Anfänger bis zum Profi. ",
                title: `Oldtimer Blog für Schrauber und Fans`
            })
        );
        dispatch(footerActiveItem("blog"))
        // Set page
        dispatch(setRuntime({
            page: 'blog'
        }));

        //Check for :tag
        if(match.params.tag) {
            this.handleTags(match.params.tag, match.params.tagId);
            dispatch(
                handleContainer("blog", `SPAN#Oldtimer Blog`, null, null, null, null, {
                  description:
                    `Du interessierst dich für das Thema ${match.params.tag}? Mit unseren Blogbeiträgen erfährst du Alles, um in dein neues Hobby zu starten.`,
                    title: `Oldtimer Blog für Schrauber und Fans - ${match.params.tag}`
                })
            );
        }
        //Check for page
        else if(match.params.page){
            this.setState({ activePage: match.params.page })
            dispatch(resetBlogList())
            dispatch(getPostList(match.params.page));
            dispatch(
                handleContainer("blog", `SPAN#Oldtimer Blog`, null, null, null, null, {
                  description:
                    `Der Oldtimer Blog für alle Schrauber und Fans von Oldtimern. Vom Anfänger bis zum Profi. - Seite ${match.params.page}`,
                    title: `Oldtimer Blog für Schrauber und Fans - Seite ${match.params.page} `
                })
            );
        }
        //Standard fetch 
        else {
            dispatch(resetBlogList());
            dispatch(getPostListIfNeeded());
        }

    }

    componentWillUnmount = () => {
        const { dispatch } = this.props;
    
        dispatch(setRuntime({
            backBlog: {page: 'blog', path: this.props.location, scroll: window.pageYOffset},
        }));
    }

    handleTags = (slug, id) => {
        const { dispatch, match } = this.props;
        //Set active tag
        this.setState({ activeTag: slug })
        
        /* Dispatch action to get filtered posts  */
        /* TODO: Use Pagination? */
        dispatch(resetBlogList())
        if(!slug){
            this.setState({ activeTag: 'alle' });
            dispatch(getPostListIfNeeded());
            dispatch(
                handleContainer("blog", "Oldtimer Blog für Schrauber und Fans", null, null, null, null, {
                  description:
                    "Der Oldtimer Blog für alle Schrauber und Fans von Oldtimern. Vom Anfänger bis zum Profi.",
                    title: `Oldtimer Blog für Schrauber und Fans`
                })
            );
        }
        else {
            dispatch(getPostListIfNeeded(1, 100, id));
            dispatch(
                handleContainer("blog", `Oldtimer Blog für Schrauber und Fans - ${match.params.tag}`, null, null, null, null, {
                  description:
                    `Du interessierst dich für das Thema ${slug}? Mit unseren Blogbeiträgen erfährst du Alles, um in dein neues Hobby zu starten.`,
                  title: `Oldtimer Blog für Schrauber und Fans - ${match.params.tag}`
                })
            );
        }

    }

    handlePageChange = (e, {activePage}) => {
        const { dispatch, history } = this.props;

        this.setState({ activePage })
        dispatch(resetBlogList());
        dispatch(getPostList(activePage));
        
        //Handle URL 
        history.push(`/oldtimer-blog/page/${activePage}`)

        dispatch(
            handleContainer("blog", "Oldtimer Blog", null, null, null, null, {
              description:
                `Der Oldtimer Blog für alle Schrauber und Fans von Oldtimern. Vom Anfänger bis zum Profi. - Seite ${activePage}`
            })
        );

    }

    reloadBlogList = () => {
        const { dispatch } = this.props;
        dispatch(getPostList())
    }

    render() {

        const { postItems, tagItems, totalCount, match, isFetching, isFailed } = this.props;
        const { activeTag, activePage } = this.state;

        return (
            <>

                <HeaderImage src={HeaderImg} overlay>
                    <Container>
                        <Grid>
                            <Grid.Column width={16} textAlign="center">
                                <Header as="h1">Oldtimer-Blog</Header>
                                <p>Alles für Oldtimer-Liebhaber</p>
                            </Grid.Column>
                        </Grid>
                    </Container>
                </HeaderImage>

                {match.params.tag &&
                    <Helmet>
                        <title>Oldtimer Blog für Schrauber und Fans - {match.params.tag} - Vetero</title>
                    </Helmet>
                }

                {match.params.page &&
                    <Helmet>
                        <title>Oldtimer Blog für Schrauber und Fans - Seite {match.params.page} - Vetero</title>
                    </Helmet>
                }

                {!match.params.page && !match.params.tag &&
                    <Helmet>
                        <title>Oldtimer Blog für Schrauber und Fans - Vetero</title>
                    </Helmet>
                }

                {/* Fetching data...*/}
                {/* ********************************** */}
                {isFetching && 
                <>
                    <Container className="blog-tags fetching">
                        <Label size="large"/>
                        <Label size="large"/>
                        <Label size="large"/>
                        <Divider/>
                    </Container>
                    <div className="blog-index fetching">
                        <Container>
                            <Grid divided="vertically">
                                <Grid.Row>
                                    <Grid.Column mobile={16} tablet={16} computer={8} style={{height: "100%"}}>
                                        <div className="blog-featured-image">
                                            <Placeholder>
                                                <Placeholder.Image/>
                                            </Placeholder>
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column mobile={16} tablet={16} computer={8} style={{height: "100%"}}>
                                        <div className="blog-content-wrapper">
                                            <Placeholder>
                                                <Placeholder.Paragraph>
                                                    <Placeholder.Line />
                                                    <Placeholder.Line />
                                                    <Placeholder.Line />
                                                    <Placeholder.Line />
                                                    <Placeholder.Line />
                                                    <Placeholder.Line />
                                                    <Placeholder.Line />
                                                </Placeholder.Paragraph>
                                            </Placeholder>
                                            <Button disabled>Weiterlesen</Button>
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>    
                                <Grid.Row>
                                    <Grid.Column width={8}>
                                        <div className="blog-featured-image">
                                            <Placeholder>
                                                <Placeholder.Image/>
                                            </Placeholder>
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column width={8}>
                                        <div className="blog-content-wrapper">
                                            <Placeholder>
                                                <Placeholder.Paragraph>
                                                    <Placeholder.Line />
                                                    <Placeholder.Line />
                                                    <Placeholder.Line />
                                                    <Placeholder.Line />
                                                    <Placeholder.Line />
                                                    <Placeholder.Line />
                                                    <Placeholder.Line />
                                                </Placeholder.Paragraph>
                                            </Placeholder>
                                            <Button disabled>Weiterlesen</Button>
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>      
                            </Grid>
                        </Container>
                    </div>
                </>
                    }

                    {/* Fetching data end*/}
                    {/* ********************************** */}

                    {/* Fetching data failed*/}
                    {/* ********************************** */}
                    {!isFetching && !postItems && !tagItems && isFailed && 
                        <Container text>
                            <ErrorHandler callbackFunction={this.reloadBlogList}/>
                        </Container>
                    }


                    {/* Show data */}
                    {/* ********************************** */}

                    {tagItems && !isFetching && 

                        <Container className="blog-tags">
                            <TagFilter tags={tagItems} activeTag={activeTag} handleTags={this.handleTags}/>
                            <Divider/>
                        </Container>

                    }
                
                    {postItems && !isFetching &&

                    <div className="blog-index" itemScope itemType="https://schema.org/Blog">
                        <Container>
                            <Grid divided='vertically'>
                                {/* Blog posts */}
                                    {postItems.map(postItem => 
                                    
                                    /* Check if published... */
                                    postItem.status === "publish" &&
                                    <Grid.Row key={postItem.id}>
                                        <Grid.Column mobile={16} tablet={16} computer={16}>
                                            <article className="blog-index-post" itemScope itemType="https://schema.org/BlogPosting">
                                                {/* Schema.org meta info*/}
                                                <MetaTag>
                                                    <script type="application/ld+json">
                                                        {StructuredBlogData(postItem)}
                                                    </script>
                                                </MetaTag>
                                                <meta itemProp="mainEntityOfPage" content={`https://vetero.de/${`oldtimer-blog/${postItem.slug}/${postItem.id}`}`}/>
                                                <meta itemProp="image" content=""/>
                                                <meta itemProp="dateModified" content={postItem.modified}/>
                                                
                                                <meta itemProp="author" content="Vetero" />

                                                <span itemProp="publisher" itemScope itemType="https://schema.org/Organization">
                                                    <meta itemProp="name" content="Vetero Oldtimer Portal"/>
                                                    <span itemProp="logo" itemScope itemType="https://schema.org/ImageObject">
                                                        <meta itemProp="url" content="https://www.vetero.de/static/media/Logo-Vetero-weiss-quer.45fca5ec.svg"/>
                                                        <meta itemProp="height" content="400"/>
                                                    </span>
                                                </span>

                                                <Grid>
                                                    <Grid.Row>
                                                        <Grid.Column mobile={16} tablet={16} computer={8}>
                                                            {/*  Blog Post Featured Image */}
                                                            <div className="blog-featured-image">
                                          
                                                                {/* Check for image */}
                                                                {postItem._embedded && postItem._embedded['wp:featuredmedia'] &&
                                                                    postItem._embedded['wp:featuredmedia'][0] ? 
                                                                
                                                                        <Image 
                                                                            loading="lazy" 
                                                                            src={postItem._embedded['wp:featuredmedia'][0].media_details.sizes.medium_large.source_url} 
                                                                            alt={postItem._embedded['wp:featuredmedia'][0].alt_text ? postItem._embedded['wp:featuredmedia'][0].alt_text : `Blog Beitrag: ${postItem.title.rendered}`}
                                                                        />
                                                                
                                                                            
                                                                        :

                                                                        <Image loading="lazy" src={PlaceholderImage} alt={`Blog Beitrag: ${postItem.title.rendered}`}/>
                                                                }
                                                                    
                                                            </div>
                                                        </Grid.Column>
                                                        <Grid.Column mobile={16} tablet={16} computer={8}>
                                                            {/*  Blog Post content */}
                                                            <div className="blog-content-wrapper">
                                                                <div className="blog-content">

                                                                    <div className="blog-title">
                                                                        <Header as="h2" itemProp="name headline">
                                                                            {postItem.title.rendered}
                                                                        </Header>
                                                                    </div>
                                                                    
                                                                    <div 
                                                                        className="blog-teaser" 
                                                                        >
                                                                            <Truncate lines={4}>
                                                                                { striptags(postItem.excerpt.rendered, 'p') }
                                                                            </Truncate>
                                                                        </div>
                                                                </div>
                                                                <Link title={postItem.title.rendered} to={`/oldtimer-blog/${postItem.slug}/${postItem.id}`}>
                                                                    <Button size="large" color="red">Weiterlesen</Button>
                                                                </Link> 
                                                            </div>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </article>
                                        </Grid.Column>
                                    </Grid.Row>
                                    )}
                                    {/*  PAGINATION  */}
                                    {/* ************************************** */}
                                    {/* Show if there is more than ... posts */}
                                    
                                    {!match.params.tag && totalCount && totalCount.count > 6 && 

                                        <Grid.Row className="blog-pagination">
                                            <Grid.Column width={16} textAlign="center">
                                                <Pagination
                                                    boundaryRange={0}
                                                    ellipsisItem={null}
                                                    firstItem={null}
                                                    lastItem={null}
                                                    siblingRange={1}
                                                    totalPages={Math.ceil(totalCount.count / 6)}
                                                    activePage={activePage}
                                                    onPageChange={this.handlePageChange}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>

                                    }
                            </Grid>

                        </Container>
                    </div>
                    } 
            </>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { blog, listByEntity } = state;
    const { post, tag, totalCount } = blog || {
        post: {},
        tag: {},
        totalCount
    };
    const { page } = listByEntity['post'] || {
        page: 0
    }

      //Check if all needed data is fetched
      let isFetching;
      if(listByEntity.post && post && tag){
        post.isFetching || tag.isFetching || listByEntity.post.isFetching ? isFetching = true : isFetching = false
      }

      //Get posts from reducer 
      let postItems = [];
      if(post && post.items && listByEntity.post && listByEntity.post.items) {
        Object.values(listByEntity.post.items).map(item => {
            postItems.push(post.items[item])
        })
      }

      let tagItems = [];
      if(tag && tag.items){
        Object.values(tag.items).map(item => {
            tagItems.push(item)
        })
      }

      return {
        postItems,
        tagItems,
        totalCount,
        isFetching,
        isFailed: post && post.isFailed ? post.isFailed : false,
        page
      };
  }
  
export default connect(mapStateToProps)(withRouter(Postlist));


