import React from 'react'
import { connect } from 'react-redux';
import { Container, Divider, Grid, Input, Dropdown, Form } from 'semantic-ui-react';
import { setRuntime, handleContainer, showFooter } from '../../../../actions/global';
import { fetchProfessional } from '../../../../actions/professional';
import BeParent from '../../../project/backend/be-parent';
import '../../dashboard.scss';
import Moment from 'react-moment';
import { hideFooter } from '../../../../actions/global';
import ItemCard, { ItemCardFetch } from '../../../../components/Card/ItemCard';
import './Professional.scss';
import { fetchProfessionalList } from './../../../../actions/professional';


const DropdownMenuItems = [
    {key: 'Alle', text: 'Alle', value: 'Alle'},
    {key: 'Veröffentlicht', text: 'Veröffentlicht', value: 'Veröffentlicht'},
    {key: 'Geparkt', text: 'Geparkt', value: 'Geparkt'},
]

class Overview extends BeParent {


    componentDidMount = () => {
        window.scrollTo(0, 0);
        const { dispatch } = this.props;

        dispatch(setRuntime({
            page: 'fe-professional-new',
        }));
        dispatch(fetchProfessionalList())
        dispatch(hideFooter());
        dispatch(handleContainer("secondary", 'Spezialisten verwalten', false, "professional"));
        //Set edit hedaer

    }

    componentWillUnmount = () => {
        const { dispatch } = this.props;
        dispatch(showFooter());
    }


    render() {

        const { isFetching, listitem } = this.props;

        return (
            <Container>
                
                <Divider hidden/>

                <div className='search-bar mt-1 mb-4 bg-grey-light pt-1 px-1'>
                    <Grid style={{
                        alignItems: 'center'
                    }}>
                        <Grid.Column width={10}>
                            <Form>
                                <Form.Field>
                                    <Input icon='search' placeholder="Anzeige..."/>
                                </Form.Field>
                            </Form>
                        </Grid.Column>
                        <Grid.Column width={6} textAlign='right'>
                            <Dropdown 
                                inline
                                defaultValue={DropdownMenuItems[0].text}
                                options={DropdownMenuItems}
                             />
                               
                        </Grid.Column>
                    </Grid>
                </div>

                {isFetching && 
                    <>
                        <ItemCardFetch/>
                        <ItemCardFetch/>
                        <ItemCardFetch/>
                    </>
                }

                {/* */}

                {!isFetching && listitem && listitem.length > 0 && listitem.map((professional, index) => (
                
                    <ItemCard 
                        headline={professional.item.headline}
                        title={professional.item.name}
                        status={{
                            active: professional.item.active,
                            true: "Veröffentlicht",
                            false: "Pausiert"
                        }}
                        image={professional.item.mediamainPath}
                        path={`/backend/admin/professional/detail/${professional.item.id}`}
                        meta={
                            <div className="professional-meta-info">
                                <div className="professional-createdate">
                                    Anzeige angelegt: <Moment format="DD.MM.YY" locale="de" unix>{professional.item.createDate}</Moment>
                                </div>
                            </div>
                        }
                    />
                ))}

            </Container>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { listByEntity, account, professionalsById } = state;

    const { list, isFetching, isFailed } = listByEntity["professional"] || {
        list: {},
        isFetching: true,
        isFailed: false
    };  

    let listitem = [];
    if (list && Object.keys(list).length) {
        listitem = Object.values(list).map(function(professional) {
            return professionalsById[professional];
        });
    }

    const { item } = account || {}

    return {
        listitem,
        isFetching, 
        isFailed
    };
}

export default connect(mapStateToProps)(Overview);
