import React, { Component } from 'react'
import {
    Menu,
    Image,
    Container
} from 'semantic-ui-react'
import { connect } from 'react-redux';
import { footerActiveItem } from '../../../actions/global';
import {
    Link
} from "react-router-dom";
import Account from '../../account/Account';
import { ReactSVG } from 'react-svg';

//Menu icons...

import menuDotsIcon_2 from '../../../images/icons/mobile-footer/icon-mehr.svg';
import newsIcon from '../../../images/icons/mobile-footer/icon-antenne-17.svg';
import projektIcon from '../../../images/icons/mobile-footer/icon-projekte.svg';
import forumIcon from '../../../images/icons/mobile-footer/icon-forum.svg';
import loginIcon from '../../../images/icons/mobile-footer/icon-login.svg'

//Menu active Icons...
// import menuDotsIconActive from '../../images/icons/mobile-footer/active/icon-mo-mehr.svg';
// import newsIconActive from '../../images/icons/mobile-footer/active/icon-antenne-rot.svg';
// import projektIconActive from '../../images/icons/mobile-footer/active/icon-mo-projekte.svg';
// import forumIconActive from '../../images/icons/mobile-footer/active/icon-mo-forum.svg';


import config from '../../../config/config';
import ProgressiveImager from '../../helpers/progressive-imager';
//User placeholder image
import userAvatar from '../../../images/icon-avatar.svg'

/* CSS */
import '../Layout.scss';

/**
* Footer
*/

class FooterMenu extends Component {

    state = {
        loginPopupVisible: false,
        footermenutrigger: false,
        dropdownOpen: false,
        menuIsOpen: false,
    }

    constructor(props) {
        super(props)

        this.footermenuclose = this.footermenuclose.bind(this);
    }

    componentDidMount = () => {
        let footerHeight ;
        // if(document.querySelector('.footer-mobile-menu')) footerHeight = document.querySelector('.footer-mobile-menu').offsetHeight;
        // let current = parseInt(document.querySelector('#root').style.paddingBottom);
        // current = (current ? current : 0);
        // if (document.querySelector('.footer-mobile-menu')) {
        //     // document.querySelector('.footer-mobile-menu').style.marginBottom = footerHeight + "px";
        //     document.querySelector('#root').style.paddingBottom = (footerHeight) + "px";
        // }
        if (document.querySelector('#ConsentBanner')) {
            document.querySelector('#ConsentBanner').style.bottom = footerHeight + "px";
        }

    }

    componentWillUnmount = () => {
        if (document.querySelector('#ConsentBanner')) {
            document.querySelector('#ConsentBanner').style.bottom = 0;
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { activeItem } = this.props;

        if(activeItem !== prevProps.activeItem && activeItem !== "footermenu") {
            this.setState({ menuIsOpen: false });
        }

    }

    footermenuclose() {
        this.setState({footermenuopen: false});
    }

    handleLoginItem = (isOpen, loginDashboard) => {
        this.setState({ loginPopupVisible: isOpen });
    }

    handleFooterMenu = () => {
        const { dispatch } = this.props;
        const { menuIsOpen } = this.state;
        this.setState({ menuIsOpen: menuIsOpen ? false : true })
        menuIsOpen ? dispatch(footerActiveItem("")) : dispatch(footerActiveItem("footermenu"));

    }

    render(){

        if(!this.props.isVisible) {
            return (null)
        }

        else {

            const { item, notificationsSum } = this.props;
            const { menuIsOpen } = this.state;

            return(
                <>
                    <Menu
                        fixed='bottom'
                        secondary
                        icon
                        className="footer-mobile-menu"
                    >
                        <Container>

                            <Menu.Item as={Link} onClick={()=> this.props.dispatch(footerActiveItem("news"))} active={this.props.activeItem === "news"} to="/newsfeed">
                                {/* <Image
                                    src={this.props.activeItem === "news" ? newsIconActive : newsIcon}
                                    className="footer-icon"
                                    alt='Newsfeed icon'
                                    title='Newsfeed'
                                /> */}
                                <ReactSVG
                                    src={newsIcon}
                                    className="footer-icon"
                                />
                            </Menu.Item>

                            <Menu.Item as={Link} onClick={()=> this.props.dispatch(footerActiveItem("project"))} active={this.props.activeItem === "project"}  to="/oldtimer">
                                    {/* <Image
                                        src={this.props.activeItem === "project" ? projektIconActive : projektIcon}
                                        className="footer-icon"
                                        alt='Oldtimer / Projekte icon'
                                        title='Oldtimer / Projekte'
                                    /> */}
                                <ReactSVG
                                    src={projektIcon}
                                    className="footer-icon"
                                />
                            </Menu.Item>


                            {!(item && item.id) ?
                                <Menu.Item onClick={() => this.handleLoginItem(true, true)} className="login-footer">
                                    {/* <Image
                                        src={loginIcon}
                                        className="footer-icon"
                                        alt='Login icon'
                                        title='Einloggen'
                                    /> */}
                                    <ReactSVG
                                        src={loginIcon}
                                        className="footer-icon"
                                    />
                                </Menu.Item>
                                :
                                <Menu.Item as={Link} className="user-footer" onClick={()=> this.props.dispatch(footerActiveItem("user"))} active={this.props.activeItem === "user"} to="/backend">
                                <div className="user-footer-wrapper">
                                    <ProgressiveImager
                                        placeholder="avatar"
                                        src={item.mediaavatarPath ? config.getImageUrl('avatar', item.mediaavatarPath) : userAvatar}
                                        circular
                                        bordered
                                        className="user-img"
                                        alt={`Profilbild von ${item.name}`}
                                    />
                                    {notificationsSum > 0 ?
                                        <span className="notification">
                                            <span>{notificationsSum}</span>
                                        </span>
                                        :
                                        ''
                                    }
                                </div>
                            </Menu.Item>

                            }
                            <Menu.Item as={Link} name="images" onClick={()=> this.props.dispatch(footerActiveItem("forum"))} active={this.props.activeItem === "forum"} to="/forum">
                                {/* <Image
                                    src={this.props.activeItem === "forum" ? forumIconActive : forumIcon}
                                    className="footer-icon"
                                    alt='Forum icon'
                                    title='Oldtimer Forum'
                                /> */}
                                <ReactSVG
                                    src={forumIcon}
                                    className="footer-icon"
                                />
                            </Menu.Item>
                            <Menu.Item
                                className="footer-menu-dropdown-menu"
                                as={"div"}
                                active={this.props.activeItem === "footermenu"}
                                onClick={this.handleFooterMenu}
                            >
                                {/* <Image
                                    src={this.props.activeItem === "footermenu" ? menuDotsIconActive : menuDotsIcon_2  }
                                    className="footer-icon"
                                    alt='More icon'
                                    title='Mehr'
                                /> */}
                                <ReactSVG
                                    src={menuDotsIcon_2}
                                    className="footer-icon"
                                />

                                {this.state.menuIsOpen &&

                                    

                                    <div className="footer-fullsize-menu">
                                        <Menu vertical fluid>
                                            <Menu.Item as={Link} to="/functions">Funktionen</Menu.Item>
                                            {/* <Menu.Item as={Link} to="/professionals">Spezialisten</Menu.Item>  */}
                                            <Menu.Item as={Link} to="/about">Über Vetero</Menu.Item>
                                            <Menu.Item as={Link} to="/oldtimer-blog">Oldtimer-Blog</Menu.Item>
                                            <Menu.Item as={Link} to="/partner">Partner</Menu.Item>
                                            <Menu.Item as={Link} to="/updates">Updates</Menu.Item>
                                            <Menu.Item as={Link} to="/brand">Marken</Menu.Item>
                                            <Menu.Item as={Link} to="/impressum">Impressum</Menu.Item>
                                            <Menu.Item as={Link} to="/privacy">Datenschutz</Menu.Item>
                                            <Menu.Item as={Link} to="/conditions">AGB</Menu.Item>
                                        </Menu>
                                    </div>
                                }

                                

                            </Menu.Item>

                        </Container>
                    </Menu>

                    {/* Login Popup include */}

                    <Account
                        loginPopupVisible={this.state.loginPopupVisible}
                        loginPopupClose={() => this.handleLoginItem(false)}
                        loginDashboard
                    />

                   {/* 3 dots menu */}


                </>
            )
        }
    }
}

function mapStateToProps(state, ownProps) {
    const { globalByComponent, account } = state
    const { isFetching, isFailed, isDirty, lastUpdated, lastFetch, item, token, notifications } = account || {
        item: {},
        notifications: {}
    }
    const { isVisible, activeItem } = globalByComponent[
        'Footer'
    ] || {
        isVisible: true,
        activeItem: '',
    }

    let notificationsSum;
    let messages, comments, followers, likes;
    if(notifications){
        if(notifications.count) {
            messages = notifications.count.countMessages ? parseInt(notifications.count.countMessages) : 0;
            comments = notifications.count.countComments && parseInt(notifications.count.countComments);
            followers = notifications.count.countFollowers && parseInt(notifications.count.countFollowers);
            likes = notifications.count.countLikes && parseInt(notifications.count.countLikes)
            notificationsSum = messages + comments + followers + likes;
        }
    }

    return {
        isVisible,
        activeItem,
        item,
        isFetching,
        isFailed,
        isDirty,
        lastUpdated,
        lastFetch,
        token,
        notificationsSum
    }
}

export default connect(mapStateToProps)(FooterMenu)
