import React from 'react';
import { Message, Container, Responsive, Divider, Form, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import TextareaAutosize from 'react-textarea-autosize';
import CustomSlidingPane  from '../../../components/CustomSlidingPane/CustomSlidingPane';
import CustomPopup from '../../../components/CustomPopup/CustomPopup';
import { newMessageUser, postMessage} from '../../../actions/conversation'
import config from '../../../config/config'
import ProgressiveImager from '../../../components/helpers/progressive-imager';
import BeParent from '../../project/backend/be-parent'

//User placeholder image
import userAvatar from '../../../images/icon-avatar.svg'


class MessagesInput extends BeParent {

    state = {
        confirmModal: {
            isOpen: false
        },
        slidingPane: {
            isOpen: false,
            title: "",
        },
        msgPopup: false,
        closeAfterSave: false,
    }

    constructor(props) {
        super(props);

        this.handleInput = this.handleInput.bind(this);
        this.submit = this.submit.bind(this);
        this.close = this.close.bind(this);
        this.discard = this.discard.bind(this);
        this.showButton = this.showButton.bind(this);
        this.handleSlidingPanes = this.handleSlidingPanes.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        const { isPosting } = this.props;
        const { closeAfterSave } = this.state;

        if (closeAfterSave && prevProps.isPosting && !isPosting) {
            this.setState({
                confirmModal: {
                    isOpen: false
                },
                slidingPane: { isOpen: false },
                msgPopup: false,
                closeAfterSave: false,
            });
        }
    }

    handleSlidingPanes = (isOpen, title) => {
        this.setState({
            slidingPane: {
                isOpen: true,
                title: title
            }
        })
    }

    handleInput(e) {
        const { dispatch, userid } = this.props;
        const element = e.currentTarget;
        dispatch(newMessageUser(element.value, userid));
    }

    submit(e, element) {
        const { dispatch, inputText, userid } = this.props;

        if (inputText) {
            dispatch(postMessage(inputText, [userid]));
            this.setState({closeAfterSave: true});
        }
        else {
            // TODO: Validation/Error message (trim?)
        }
    }

    showButton() {
        const { inputText } = this.props;

        if (inputText) {
            return true;
        }

        return false;
    }

    discard() {
        const { dispatch, userid } = this.props;

        // Discard changes
        dispatch(newMessageUser(null, userid));

        // TODO: Reset validation

        // Close popup/pane
        this.setState({
            confirmModal: {
                isOpen: false
            },
            slidingPane: { isOpen: false },
            msgPopup: false,
        });
    }

    close = () => {
        const { inputText } = this.props;

        if (this.state.msgPopup && inputText) {
            this.setState({
                confirmModal: {
                    isOpen: true
                },
            })
        }
        else {
            this.setState({
                slidingPane: { isOpen: false },
                msgPopup: false,
            })
        }
    }

    render() {
        const { isPosting } = this.props;

        return (
            <>
                {this.confirmModal(this.discard)}

                <Responsive {...Responsive.onlyMobile}>
                    <Button fluid onClick={()=> this.handleSlidingPanes(true, "Nachricht senden")} color="red">Nachricht senden</Button>
                </Responsive>
                <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                    <Button fluid onClick={()=>this.setState({msgPopup: true})} color="red">Nachricht senden</Button>
                </Responsive>

                <CustomSlidingPane
                    isOpen={this.state.slidingPane.isOpen}
                    title={this.state.slidingPane.title}
                    onRequestClose={this.close}
                    saveButtonCallback={this.submit}
                    saveButton={this.showButton()}
                    saveButtonLabel="Senden"
                    isLoading={isPosting}
                >
                    {this.renderInner()}
                </CustomSlidingPane>

                {/* Desktop - show content in Popup instead of SlidingPane */}
                <CustomPopup
                    className="msg-popup"
                    popupHeader="Nachricht senden"
                    position="center center"
                    headerBackgroundWhite
                    popupOpen={this.state.msgPopup}
                    onClose={this.close}
                    saveButtonCallback={this.submit}
                    saveButton={this.showButton()}
                    saveButtonLabel="Senden"
                    isLoading={isPosting}
                    showCloseIcon
                >
                    {this.renderInner()}
                </CustomPopup>
            </>
        );
    }

    renderInner() {
        const { item, inputText, postingMessage } = this.props;

        return (
            <Container className="send-msg-container">
                    <>
                        <div className="send-msg-user">
                            <ProgressiveImager
                                placeholder="avatar"
                                src={item.mediaavatarPath ? config.getImageUrl('avatar', item.mediaavatarPath) : userAvatar}
                                circular
                                bordered
                            />
                            <span className="send-msg-user-name">{item.name}</span>
                        </div>
                        <Divider hidden/>
                        {postingMessage === 'FAILURE' &&
                            <Message negative>Beim Senden ist ein Fehler aufgetreten.</Message>
                        }
                        <Form>
                            <Form.Field>
                                <label>Nachricht</label>
                                <TextareaAutosize
                                    placeholder="Gib Deine Nachricht ein..."
                                    onChange={this.handleInput}
                                    value={inputText}
                                    minRows="1"
                                    maxRows="10"
                                />
                            </Form.Field>
                        </Form>
                        <Divider hidden/>
                    </>
            </Container>
        )
    }

}

function mapStateToProps(state, ownProps){
    const { account, userById } = state

    const { inputText, isPosting, postingMessage } = userById[
        ownProps.userid
    ] || {
        inputText: '',
        isPosting: false,
    }

    return {
        item: account.item,
        inputText,
        isPosting,
        postingMessage,
    }
}

export default connect(mapStateToProps)(MessagesInput);
