import * as moment from "moment";
import 'moment-timezone';
import 'moment/locale/de';
import Swipeout from 'rc-swipeout';
import 'rc-swipeout/assets/index.css';
import React, { createRef } from 'react';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import Select from 'react-select';
import {
    Button, Checkbox, Container, Divider, Dropdown, Form, Grid, Header, Icon, Image, Input, Label, Message, Placeholder, Responsive, Segment
} from 'semantic-ui-react';
import fetchExpenditureListIfNeeded, { deleteExpenditure, discardExpenditure, newExpenditure, postExpenditure, updateExpenditure } from '../../../actions/expenditure';
import { handleContainer, setRuntime } from '../../../actions/global';
import fetchProjectIfNeeded from '../../../actions/project';
import config from '../../../config/config';
import menuDotsIcon_2 from '../../../images/icons/footer-menu-dots_2.svg';
import trashIcon from '../../../images/icons/icon-swipe-trash.svg';
import CustomPopup from '../../../components/CustomPopup/CustomPopup';
import CustomSlidingPane from '../../../components/CustomSlidingPane/CustomSlidingPane';
import ProjectNav from '../../../components/layout/ProjectNav';
import PlusButton from '../../../components/PlusButton/PlusButton';
import BeParent from './be-parent';
import ExpenditureContent from './expenditure-content';
import './Backend.scss';



class Expenditure extends BeParent {

    inputFields = ['datestart', 'dateend', 'workinghours', 'projectPart', 'contentSuperclass'];

    constructor(props) {
        super(props)

        this.handleDate = this.handleDate.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.handleInputSelect = this.handleInputSelect.bind(this);
        this.handleInputCheckbox = this.handleInputCheckbox.bind(this);
        this.submitValidate = this.submitValidate.bind(this);
        this.submit = this.submit.bind(this);
        this.discard = this.discard.bind(this);
        this.showButton = this.showButton.bind(this);

        this.state = {
            confirmModal: {
                isOpen: false
            },
            expenditureItem: {
                isOpen: false,
                title: "",
                content: "",
                itemId: null
            },
            popup: {
                isOpen: false
            },
            formValidation: {
                check: true,
                checkMsg: '',
                validationDateStart: {
                    error: false,
                    message: ''
                },
                validationDateEnd: {
                    error: false,
                    message: ''
                },
                validationWorkingHours: {
                    error: false,
                    message: ''
                },
            },
            closeAfterSave: false,
        }

        this.popupRef = createRef();
    }

    scrollToSelect() {
        if (this.popupRef.current && this.popupRef.current.ContentEl) {
            var element = this.popupRef.current.ContentEl.querySelector('.popup-content-inside');
            setTimeout(function() {
                element.scrollTo(0, 255);
            }, 100);
        }
    }

    handleDate = (event, {name, value}) => {
        const { dispatch, match, inputDatestart, inputDateend} = this.props;

        name = name.split('.');
        var entity = name[0];
        var field = name[1];

        dispatch(newExpenditure(match.params.id, field, moment(value).format('X')));

    }

    handleInput(e, element) {
        const { dispatch, match } = this.props;
        var name = element.name.split('.');
        var entity = name[0];
        var field = name[1];

        dispatch(newExpenditure(match.params.id, field, element.value));
    }

    handleInputSelect(opt, element) {
        const { dispatch, match } = this.props;
        var name = element.name.split('.');
        var entity = name[0];
        var field = name[1];

        dispatch(newExpenditure(match.params.id, field, opt.value));
    }

    handleInputCheckbox(e, element) {
        const { dispatch, match } = this.props;
        var name = element.name.split('.');
        var entity = name[0];
        var field = name[1];

        dispatch(newExpenditure(match.params.id, field, element.checked));
    }

    submitValidate(forward) {

        this.setState({forwardAfterSubmit: (forward ? true : false)});

        const { dispatch, match, inputDatestart, inputDateend, inputWorkinghours, inputProjectpart, expenditures } = this.props;
        const { formValidation, expenditureItem } = this.state;

        // New
        if (expenditureItem.content == "new-workinghours") {
            this.setState({
                formValidation: {
                    ...formValidation,
                    validationWorkingHours: config.numericFields(inputWorkinghours, true),
                    ...config.validateWorkingHoursDate(inputDatestart, inputDateend)
                }
            }, this.submit)
        }
        // Edit
        else if (expenditureItem.itemId) {
            var exp = expenditures.filter((item) => item.id === expenditureItem.itemId)[0];
            this.setState({
                formValidation: {
                    ...formValidation,
                    validationWorkingHours: config.numericFields(exp.input.workinghours, true),
                    ...config.validateWorkingHoursDate(exp.input.datestart, exp.input.dateend)
                }
            }, this.submit)
        }
    }

    submit() {

        const { dispatch, match, inputDatestart, inputDateend, inputWorkinghours, inputProjectpart, expenditures, isPosting } = this.props;
        const { formValidation, expenditureItem } = this.state;

        if (
            !formValidation.validationDateEnd.error &&
            !formValidation.validationDateStart.error &&
            !formValidation.validationWorkingHours.error
        ) {
            this.setState({
                formValidation: {
                    ...formValidation,
                    check: true,
                    checkMsg: "",
                    validationDateEnd: {
                        error: false
                    },
                    validationDateStart: {
                        error: false
                    },
                    validationWorkingHours: {
                        error: false
                    },
                }
            });

            if (expenditureItem.content == "new-workinghours") {
                dispatch(postExpenditure(match.params.id, inputDatestart, inputDateend, inputWorkinghours, inputProjectpart));
            }
            else {
                var exp = expenditures.filter((item) => item.id === expenditureItem.itemId)[0];
                dispatch(updateExpenditure(exp.input));
                this.setState({closeAfterSave: true});
            }
        }
    }

    componentDidMount() {
        const { dispatch, match, item } = this.props;

        dispatch(fetchProjectIfNeeded(match.params.id));
        dispatch(fetchExpenditureListIfNeeded(match.params.id));
        dispatch(handleContainer("secondary", item.title, false, "dashboard"));
        dispatch(setRuntime({page: 'be-expenditures'}));
    }

    componentDidUpdate(prevProps, prevState) {
        const { isPosting, isFetching, isDeleting, postingMessage, expenditures } = this.props;
        const { expenditureItem, closeAfterSave, forwardAfterSubmit } = this.state;

        if (prevProps.isPosting !== isPosting && !isPosting && postingMessage === 'SUCCESS') {
            this.handleExpenditureItem(false);
        }

        if (closeAfterSave && prevProps.isFetching && !isFetching) {
            this.handleExpenditureItem(false);
            this.setState({closeAfterSave: false});
        }

        if (prevProps.isDeleting && !isDeleting) {
            this.setState({popup: {isOpen: false}});
        }

        if (forwardAfterSubmit && prevProps.expenditures.length < expenditures.length) {
            var lastid = 0;
            expenditures.forEach((exp, i) => {
                if (exp.id > lastid) {
                    lastid = exp.id;
                }
            });
            this.handleExpenditureItem(true, "Arbeitszeiten bearbeiten", "edit-workinghours", lastid);
            this.setState({forwardAfterSubmit: false});
            this.scrollToSelect();
        }
    }

    handleExpenditureItem = (isOpen, title, content, id) => {
        this.setState({
            expenditureItem: {
                isOpen,
                title: title,
                content: content,
                itemId: id
            }
        })
    }

    handleExpenditureContent = () => {
        const { expenditureItem, content, itemId } = this.state;
        switch(expenditureItem.content){
            case "new-workinghours":
                return this.expenditure(false)
            case "edit-workinghours":
                return this.expenditure(true, expenditureItem.itemId)
            default:
                return null
        }
    }

    discard() {
        const { dispatch, item } = this.props;
        const { expenditureItem } = this.state;

        // Discard changes
        if (expenditureItem.content == "new-workinghours") {
            dispatch(newExpenditure(item.id, 'inputDatestart', null));
            dispatch(newExpenditure(item.id, 'inputDateend', null));
            dispatch(newExpenditure(item.id, 'inputWorkinghours', null));
            dispatch(newExpenditure(item.id, 'inputProjectpart', null));
        }
        else {
            dispatch(discardExpenditure((expenditureItem.itemId ? expenditureItem.itemId : null), item.id));
        }

        // Reset validation
        this.setState({
            formValidation: {
                ...this.state.formValidation,
                check: true,
                checkMsg: "",
                validationDateEnd: {
                    error: false
                },
                validationDateStart: {
                    error: false
                },
                validationWorkingHours: {
                    error: false
                },
            }
        });

        // Close popup/pane
        this.handleExpenditureItem(false);
        this.setState({
            confirmModal: {
                isOpen: false
            },
        });
    }

    editExpenditureClose = () => {
        const { dispatch, match, inputDatestart, inputDateend, inputWorkinghours, inputProjectpart, expenditures, item } = this.props;
        const { formValidation, expenditureItem } = this.state;

        if (expenditureItem.content == "new-workinghours") {
            if (inputDatestart || inputDateend || inputWorkinghours || inputProjectpart) {
                this.setState({
                    confirmModal: {
                        isOpen: true
                    },
                })
            }
            else {
                this.handleExpenditureItem(false);
            }
        }

        if (expenditureItem.itemId) {
            if (config.isChanged(expenditures.filter((item) => item.id === expenditureItem.itemId)[0], this.inputFields)) {
                this.setState({
                    confirmModal: {
                        isOpen: true
                    },
                })
            }
            else {
                this.handleExpenditureItem(false);
            }
        }
    }

    showButton() {
        const { dispatch, match, inputDatestart, inputDateend, inputWorkinghours, inputProjectpart, expenditures, item } = this.props;
        const { formValidation, expenditureItem } = this.state;

        if (expenditureItem.content == "new-workinghours" && (inputDatestart || inputDateend || inputWorkinghours || inputProjectpart)) {
            return true;
        }

        if (expenditureItem.itemId && (config.isChanged(expenditures.filter((item) => item.id === expenditureItem.itemId)[0], this.inputFields))) {
            return true;
        }

        return false;
    }

    render(){
         const { dispatch, match, isFetching, isFailed, expenditures, sum, isDirty } = this.props;
         const { formValidation } = this.state;

         if ((!expenditures || !expenditures.length) && isFetching) {
             return (<>

                <ProjectNav active="Aufwand"  project={{id: match.params.id}}/>
                <Container style={{margin: "15px 0"}}>
                    <Grid>
                        <Grid.Column floated="left" width={5}>
                            <Header as="h2" className="text-placeholder">Aufwand/Zeiten</Header>
                        </Grid.Column>
                        <Grid.Column floated="right" width={7} textAlign="right">
                            <PlusButton
                                title="Neue anlegen"
                                color="red"
                                disabled={true}
                                onClick={() => this.handleExpenditureItem(true, "Arbeitszeit anlegen", "new-workinghours")}
                            />
                        </Grid.Column>
                    </Grid>
                    <Message color="teal" className="text-placeholder">
                        Hier findest Du alle bisher eingetragenen Arbeitszeiten des Projektes. Du kannst diese bearbeiten,
                        löschen oder neue anlegen.
                    </Message>
                 </Container>
                 <div style= {{textAlign: "center", margin: "1.33rem 0 1.66rem 0"}} className="text-placeholder">
                    <div className="time">-</div>
                    <div className="hours">Stunden</div>
                 </div>
                 <Container><Header as="h3">Bisherige Arbeitszeiten</Header></Container>
                    <Segment className="part-cost-placeholder">
                        <Placeholder>
                            <Placeholder.Line length='full' />
                            <Placeholder.Line length='very long' />
                            <Placeholder.Line length='short' />
                        </Placeholder>
                    </Segment>
                    <Segment className="part-cost-placeholder">
                        <Placeholder>
                            <Placeholder.Line length='full' />
                            <Placeholder.Line length='very long' />
                            <Placeholder.Line length='short' />
                        </Placeholder>
                    </Segment>
                    <Segment className="part-cost-placeholder">
                        <Placeholder>
                            <Placeholder.Line length='full' />
                            <Placeholder.Line length='very long' />
                            <Placeholder.Line length='short' />
                        </Placeholder>
                    </Segment>
                </>);
         }
         if (isFailed) {
             return (
                 <Container>
                     <Divider hidden/>
                     <Message color="red">
                         Es ist ein Fehler beim Abrufen der Daten aufgetreten.
                     </Message>
                 </Container>
             );
         }

         return(
             <div>
                 {this.confirmModal(this.discard)}

                 <ProjectNav active="Aufwand" project={{id: this.props.match.params.id}}/>
                 <Container style={{margin: "15px 0"}}>
                    <Grid>
                        <Grid.Column floated="left" width={5}>
                            <Header as="h2">Aufwand/Zeiten</Header>
                        </Grid.Column>
                        <Grid.Column floated="right" width={7} textAlign="right">
                            <PlusButton
                                title="Neue anlegen"
                                color="red"
                                onClick={() => this.handleExpenditureItem(true, "Arbeitszeit anlegen", "new-workinghours")}
                            />
                        </Grid.Column>
                    </Grid>
                    <Message color="teal">
                        Hier findest Du alle bisher eingetragenen Arbeitszeiten des Projektes. Du kannst diese bearbeiten,
                        löschen oder neue anlegen.
                    </Message>
                 </Container>
                 <div style= {{textAlign: "center", margin: "1.33rem 0 1.66rem 0"}}>
                    <div className="time">{config.getFormattedInt(sum)}</div>
                    <div className="hours">Stunden</div>
                 </div>
                 <Container><Header as="h3">Bisherige Arbeitszeiten</Header></Container>
                 <Divider hidden/>
                 {(!expenditures || !expenditures.length) &&
                     <Container>
                         <Divider hidden/>
                         <Message color="teal">
                             Für dieses Projekt wurden noch keine Zeiten erfasst.
                         </Message>
                     </Container>
                 }
                 {expenditures &&
                     <>
                         {expenditures.map(item =>(
                             <div style={{marginBottom: "1rem"}}>
                                <Swipeout
                                    autoClose
                                    right={[
                                        {
                                            text: <div
                                                    className="swipeout-img-container"
                                                    onTouchEnd={() => this.setState({popup: {isOpen: true, deleteid: item.id}})}
                                                   >
                                                       <div className="swipeout-img">
                                                            <Image src={trashIcon}/>
                                                       </div>
                                                  </div>,
                                            style: {
                                                backgroundColor: "#E7344C",
                                                color: "white",
                                                textAlign: "center",
                                                fontWeight: "bold",
                                                width: "50px",
                                                height: "100%",
                                                cursor: "pointer"
                                            },

                                        }
                                    ]}
                                >
                                    <Segment onClick={() => this.handleExpenditureItem(true, "Arbeitszeiten bearbeiten", "edit-workinghours", item.id)} className="expenditure-item">
                                        <Grid columns={3}>
                                            <Grid.Column width="7">
                                                <span className="expenditure-date">
                                                    <Moment unix format="DD.MM.YY">{item.datestart}</Moment>
                                                    <span> - </span>
                                                    <Moment unix format="DD.MM.YY">{item.dateend}</Moment>
                                                </span>
                                            </Grid.Column>
                                            <Grid.Column width="4">
                                                <span className="expenditure-amount">{config.getFormattedInt(item.workinghours)} Std</span>
                                            </Grid.Column>
                                            <Grid.Column mobile={5} tablet={5} computer={3} style={{textAlign: "center"}}>
                                                 {item.projectPart ? <Label color="green">zugewiesen</Label> : <span className="not-assigned">Noch nicht zugewiesen</span>}
                                            </Grid.Column>
                                            <Grid.Column
                                                className="elements-dots-menu"
                                                textAlign="right"
                                                only="computer"
                                                width={2}
                                                style={{
                                                    lineHeight: 0,
                                                    marginTop: "-0.25rem"
                                                }}
                                            >
                                                <div className="elements-options-menu">
                                                    <Dropdown icon={<Image className="elements-options-menu-dots" src={menuDotsIcon_2}/>}>
                                                        <Dropdown.Menu style={{left: 'auto', right: 0}}>
                                                            <Dropdown.Item className="delete-story" onClick={() => this.setState({popup: {isOpen: true, deleteid: item.id}})}>
                                                                <Image className="elements-options-menu-icon" src={trashIcon}/>
                                                                <span>Löschen</span>
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </Grid.Column>
                                        </Grid>
                                    </Segment>
                                </Swipeout>
                             </div>
                         ))}
                     </>
                 }

                {/* Handle content - mobile sliding pane */}

                <Responsive
                    as={CustomSlidingPane}
                    {...Responsive.onlyMobile}
                    isOpen={this.state.expenditureItem.isOpen}
                    title={this.state.expenditureItem.title}
                    onRequestClose={this.editExpenditureClose}
                    saveButtonCallback={this.submitValidate}
                    saveButton={this.showButton()}
                    isLoading={this.props.isPosting || this.props.isFetching}
                >
                    {this.handleExpenditureContent()}
                </Responsive>

                {/* Handle content - desktop popup */}

                <Responsive
                    as={CustomPopup}
                    minWidth={Responsive.onlyTablet.minWidth}
                    popupOpen={this.state.expenditureItem.isOpen}
                    popupHeader={this.state.expenditureItem.title}
                    onClose={this.editExpenditureClose}
                    saveButtonCallback={this.submitValidate}
                    saveButton={this.showButton()}
                    isLoading={this.props.isPosting || this.props.isFetching}
                    onDocumentClick={
                        formValidation.validationWorkingHours.error ||
                        formValidation.validationDateStart.error ||
                        !formValidation.check || 
                        formValidation.validationDateEnd.error ?
                        false : true
                    }
                    showCloseIcon
                    className="larger-popup"
                    passref={this.popupRef}
                >
                    {this.handleExpenditureContent()}
                </Responsive>

                {/* Delete item confirm popup */}

                <CustomPopup
                    popupOpen={this.state.popup.isOpen}
                    popupHeader="Arbeitszeiten löschen"
                    onClose={() => this.setState({popup: {isOpen: false}})}
                    showCloseIcon
                >
                    {this.confirmDelete()}
                </CustomPopup>

             </div>
         )
     }

     confirmDelete(){
         const { isDeleting } = this.props;
         return (
            <Container>
                <Divider hidden/>
                    <div style={{textAlign: "center", margin: "1rem 0"}}>
                        <Icon name="warning sign" color="red" size="huge"/>
                    </div>
                    <div style={{textAlign: "center"}}>Willst du die Arbeitszeiten wirklich löschen?</div>
                    <Divider hidden/>
                    <div className="buttons-center">
                        <Button color="red" onClick={() => this.setState({popup: {isOpen: false}})}>
                            Zurück
                        </Button>
                        <Button
                            loading={isDeleting}
                            basic
                            color="red"
                            onClick={isDeleting ? null : () => {
                                this.props.dispatch(deleteExpenditure(this.props.match.params.id, this.state.popup.deleteid));
                            }
                        }>Löschen</Button>
                    </div>
                    <Divider hidden/>
            </Container>
         )
     }

     expenditure(edit, id){
        if(edit) {
            const { item: project, expenditures, useFilter } = this.props;
            const { formValidation } = this.state;

            return(
                <div>
                    {expenditures.filter(item => item.id === id).map(item => (
                        <div>
                            <Container>
                                <Message color="teal">
                                    Hier kannst Du die tatsächlich benötigte Arbeitszeit für einen Zeitraum angeben. Tipp: Arbeitszeiten
                                    sollten möglichst zeitnah erfasst werden, da man sonst gerne Zeiten vergisst oder sich verschätzt.
                                </Message>

                                {!formValidation.check &&
                                <>
                                    <Divider hidden/>
                                    <Message color="red">
                                        {formValidation.checkMsg}
                                    </Message>
                                </>
                                }
                                <Divider hidden/>
                                <Form>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={8}>
                                                <Form.Field
                                                    error={formValidation.validationDateStart.error}
                                                >
                                                    <label>Anfang</label>
                                                    <Form.Input
                                                        type='date'
                                                        name={"expenditure."+item.id+".datestart"}
                                                        value={item.input.datestart ? moment.unix(item.input.datestart).format("YYYY-MM-DD") : null}
                                                        onChange={this.handleChangeDate}
                                                        onBlur={(e) => this.setState({
                                                            formValidation: {
                                                                ...this.state.formValidation,
                                                                ...config.validateWorkingHoursDate(item.input.datestart, item.input.dateend)                                                            }
                                                        })}
                                                    />
                                                </Form.Field>

                                                {formValidation.validationDateStart.error &&
                                                    <Message color="red">
                                                        {formValidation.validationDateStart.message}
                                                    </Message>
                                                }

                                            </Grid.Column>
                                            <Grid.Column width={8}>
                                                <Form.Field
                                                    error={formValidation.validationDateEnd.error}
                                                >
                                                    <label>Ende</label>
                                                    <Form.Input
                                                        type='date'
                                                        name={"expenditure."+item.id+".dateend"}
                                                        value={item.input.dateend ? moment.unix(item.input.dateend).format("YYYY-MM-DD") : null}
                                                        onChange={this.handleChangeDate}
                                                        onBlur={(e) => this.setState({
                                                            formValidation: {
                                                                ...this.state.formValidation,
                                                                ...config.validateWorkingHoursDate(item.input.datestart, item.input.dateend)                                                            }
                                                        })}
                                                    />
                                                </Form.Field>

                                            {formValidation.validationDateEnd.error &&
                                                <Message color="red">
                                                    {formValidation.validationDateEnd.message}
                                                </Message>
                                            }

                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                    <Form.Field
                                        error={formValidation.validationWorkingHours.error ? true : false}
                                    >
                                        <label>Arbeitszeit</label>
                                        <Input
                                            type="text"
                                            style={{width: "30%"}}
                                            pattern="\d*"
                                            label={{basic: true, content: "Std."}}
                                            labelPosition="right"
                                            value={item.input.workinghours}
                                            name={"expenditure."+item.id+".workinghours"}
                                            onChange={this.handleChangeInput}
                                            onBlur={(e) => this.setState({
                                                formValidation: {
                                                    ...this.state.formValidation,
                                                    validationWorkingHours: config.numericFields(e.target.value, true)
                                                }
                                            })}
                                        />
                                    </Form.Field>

                                    {formValidation.validationWorkingHours.error &&
                                        <Message color="red">
                                            {formValidation.validationWorkingHours.message}
                                        </Message>
                                    }

                                    <Form.Field>
                                        <label>Story zuweisen (optional)</label>
                                        <Select menuPlacement="auto"
                                            options={config.buildChoice(project.projectpartsData, 'title')}
                                            name={"expenditure."+item.id+".projectPart"}
                                            classNamePrefix="select-custom"
                                            className="custom-select-wrapper"
                                            onChange={this.handleChangeSelect}
                                            value={config.getDefault(config.buildChoice(project.projectpartsData, 'title'), item.input.projectPart)}
                                            onFocus={() => {this.scrollToSelect();}}
                                        />
                                    </Form.Field>

                                {!item.input.projectPart &&
                                    <Divider hidden/>
                                }

                                {item.input.projectPart &&
                                    <>
                                        <Header as="h2">Zeit bestehenden Einträgen zuweisen</Header>

                                        <Checkbox
                                            label="Einträge mit gebuchten Zeiten ausblenden"
                                            name={"expenditure.useFilter"}
                                            onChange={this.handleInputCheckbox}
                                            checked={useFilter}
                                        />

                                        <Message color="teal">
                                            Wähle alle Einträge der Story aus, die mit der oben angegebenen Arbeitszeit zu tun haben.
                                            Du kannst die Zuweisung jederzeit ändern.
                                        </Message>
                                        <Divider hidden/>
                                    </>
                                }
                                </Form>
                            </Container>
                            {item.input.projectPart &&
                                <ExpenditureContent expenditure={item.id} story={item.input.projectPart} useFilter={useFilter} />
                            }
                        </div>
                    ))}
                </div>
            )
        }
        else {
            const { item, inputDatestart, inputDateend, inputWorkinghours, inputProjectpart, isPosting, postingMessage } = this.props;
            const { formValidation } = this.state;

            return(
                <div>
                    <Container>
                        {postingMessage === 'FAILURE' &&
                            <Message negative>Beim Senden ist ein Fehler aufgetreten.</Message>
                        }

                        <Message color="teal">
                            Hier kannst Du die tatsächlich benötigte Arbeitszeit für einen Zeitraum angeben. Tipp: Arbeitszeiten
                            sollten möglichst zeitnah erfasst werden, da man sonst gerne Zeiten vergisst oder sich verschätzt.
                        </Message>

                        {!formValidation.check &&
                            <>
                                <Divider hidden/>
                                <Message color="red">
                                    {formValidation.checkMsg}
                                </Message>
                            </>
                        }

                        <Divider hidden/>
                        <Form>
                            <Grid style={{marginBottom: 0}}>
                                <Grid.Row>
                                    <Grid.Column width={8}>
                                        <Form.Field
                                            error={formValidation.validationDateStart.error ? true : false}
                                        >
                                            <label>Anfang</label>
                                            <Form.Input
                                                type='date'
                                                name={"expenditure.inputDatestart"}
                                                value={inputDatestart ? moment.unix(inputDatestart).format("YYYY-MM-DD") : null}
                                                onChange={this.handleDate}
                                                onBlur={(e) => this.setState({
                                                    formValidation: {
                                                        ...this.state.formValidation,
                                                        ...config.validateWorkingHoursDate(inputDatestart, inputDateend)
                                                    }
                                                })}
                                            />
                                        </Form.Field>

                                        {formValidation.validationDateStart.error &&
                                            <Message color="red">
                                                {formValidation.validationDateStart.message}
                                            </Message>
                                        }

                                    </Grid.Column>
                                    <Grid.Column width={8}>
                                        <Form.Field
                                            error={formValidation.validationDateEnd.error ? true : false}
                                        >
                                            <label>Ende</label>
                                            <Form.Input
                                                type='date'
                                                name={"expenditure.inputDateend"}
                                                value={inputDateend ? moment.unix(inputDateend).format("YYYY-MM-DD") : null}
                                                onChange={this.handleDate}
                                                onBlur={(e) => this.setState({
                                                    formValidation: {
                                                        ...this.state.formValidation,
                                                        ...config.validateWorkingHoursDate(inputDatestart, inputDateend)
                                                    }
                                                })}
                                            />
                                        </Form.Field>

                                        {formValidation.validationDateEnd.error &&
                                            <Message color="red">
                                                {formValidation.validationDateEnd.message}
                                            </Message>
                                        }

                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <Form.Field
                                error={formValidation.validationWorkingHours.error ? true : false}
                            >
                                <label>Arbeitszeit</label>
                                <Input
                                    type="text"
                                    style={{width: "30%"}}
                                    min="1"
                                    step="1"
                                    label={{basic: true, content: "Std."}}
                                    labelPosition="right"
                                    value={inputWorkinghours}
                                    name={"expenditure.inputWorkinghours"}
                                    onChange={this.handleInput}
                                    onBlur={(e) => this.setState({
                                        formValidation: {
                                            ...this.state.formValidation,
                                            validationWorkingHours: config.numericFields(e.target.value, true)
                                        }
                                    })}
                                />
                            </Form.Field>

                            {formValidation.validationWorkingHours.error &&
                                <Message color="red">
                                    {formValidation.validationWorkingHours.message}
                                </Message>
                            }

                            <Form.Field>
                                <label>Story zuweisen (optional)</label>
                                <Select menuPlacement="auto"
                                    options={config.buildChoice(item.projectpartsData, 'title')}
                                    name="expenditure.inputProjectpart"
                                    onChange={this.handleInputSelect}
                                    classNamePrefix="select-custom"
                                    className="custom-select-wrapper"
                                    value={config.getDefault(config.buildChoice(item.projectpartsData, 'title'), inputProjectpart)}
                                    onFocus={() => {this.scrollToSelect();}}
                                />
                            </Form.Field>

                            {inputProjectpart &&
                                <Button loading={this.props.isPosting || this.props.isFetching} color="green" onClick={() => {
                                        this.submitValidate(true);
                                    }}>
                                    Speichern & Einträge zuweisen
                                </Button>
                            }
                        </Form>
                    </Container>
                </div>
         )
        }
     }
 }

function mapStateToProps(state, ownProps) {
    const { globalByComponent, projectById, expendituresByParent, storyById } = state

    const { item, isDirty, isFetching, isFailed, isDeleted } = projectById[
        ownProps.match.params.id
    ] || {
        item: {},
        isDirty:false,
        isFetching: true,
        isFailed: false,
        isDeleted: false,
    }

    const { items, isFetching: isFetching2, isFailed: isFailed2, isPosting, postingMessage, inputDatestart, inputDateend, inputWorkinghours, inputProjectpart, useFilter, isDeleting } = expendituresByParent[
        'project::'+ownProps.match.params.id
    ] || {
        items: {},
        isFetching: true,
        isFailed: false,
        isDeleting: false
    }
    var expenditures = [];
    if (items) {
        expenditures = [].concat(Object.values(items)).sort((a, b) => a.datestart > b.datestart ? 1 : -1);
    }

    var sum = 0;
    expenditures.map(function(li) {
        sum += parseInt(li.workinghours);
    })

    return {
        expenditures,
        item,
        isDirty,
        isFetching: (isFetching || isFetching2),
        isFailed: (isFailed || isFailed2),
        isDeleted,
        isDeleting,
        isPosting,
        postingMessage,
        inputDatestart,
        inputDateend,
        inputWorkinghours,
        inputProjectpart,
        sum,
        useFilter,
        isDeleting
    }
}

 export default connect(mapStateToProps)(Expenditure);
