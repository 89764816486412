import config from '../config/config'
import { createAction } from 'redux-api-middleware';

export const CHANGE_ASSEMBLY = 'CHANGE_ASSEMBLY'
export const NEW_ASSEMBLY = 'NEW_ASSEMBLY'
export const API_ASSEMBLY_REQUEST = 'API_ASSEMBLY_REQUEST'
export const API_ASSEMBLY_SUCCESS = 'API_ASSEMBLY_SUCCESS'
export const API_ASSEMBLY_FAILURE = 'API_ASSEMBLY_FAILURE'
export const API_ASSEMBLY_DELETE_REQUEST = 'API_ASSEMBLY_DELETE_REQUEST'
export const API_ASSEMBLY_DELETE_SUCCESS = 'API_ASSEMBLY_DELETE_SUCCESS'
export const API_ASSEMBLY_DELETE_FAILURE = 'API_ASSEMBLY_DELETE_FAILURE'
export const API_ASSEMBLY_LIST_REQUEST = 'API_ASSEMBLY_LIST_REQUEST'
export const API_ASSEMBLY_LIST_SUCCESS = 'API_ASSEMBLY_LIST_SUCCESS'
export const API_ASSEMBLY_LIST_FAILURE = 'API_ASSEMBLY_LIST_FAILURE'
export const API_POST_ASSEMBLY_REQUEST = 'API_POST_ASSEMBLY_REQUEST'
export const API_POST_ASSEMBLY_SUCCESS = 'API_POST_ASSEMBLY_SUCCESS'
export const API_POST_ASSEMBLY_FAILURE = 'API_POST_ASSEMBLY_FAILURE'


export function discardAssembly(id, project) {
    return (dispatch, getState) => {
        const assemblylist = getState().assemblysByParent['project::'+project];
        var item = Object.values(assemblylist.items).filter(item => item.id === id)[0];

        delete item['input'];
        item['input'] = JSON.parse(JSON.stringify(item));

        dispatch(changeAssembly(item));
    }
}

export function newAssembly(project, name, value) {
    return {
        type: NEW_ASSEMBLY,
        meta: { project },
        payload: {
            [name]: value,
        }
    }
}

export function postAssembly(project, inputTitle, inputDescription, inputSortingcategory, inputProjectpart, inputMedia) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/assembly`,
        method: 'POST',
        body: JSON.stringify({
            project,
            title: inputTitle,
            description: inputDescription,
            sortingCategory: inputSortingcategory,
            projectpart: inputProjectpart,
            media: inputMedia,
        }),
        types: [
            {type: API_POST_ASSEMBLY_REQUEST, meta: {project}},
            {type: API_POST_ASSEMBLY_SUCCESS, meta: {project}},
            {type: API_POST_ASSEMBLY_FAILURE, meta: {project}},
        ]
    })
}

export function changeAssembly(item) {
    return {
        type: CHANGE_ASSEMBLY,
        meta: {
            project: item.project,
        },
        payload: {
            item,
        }
    }
}

export function updateAssembly(item) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/assembly/${item.id}`,
        method: 'PUT',
        body: JSON.stringify({
            assembly: item,
        }),
        types: [
            {type: API_ASSEMBLY_REQUEST, meta: { project: item.project }},
            {type: API_ASSEMBLY_SUCCESS, meta: { project: item.project }},
            {type: API_ASSEMBLY_FAILURE, meta: { project: item.project }},
        ]
    })
}

export function deleteAssembly(project, id) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/assembly/${id}`,
        method: 'DELETE',
        types: [
            {type: API_ASSEMBLY_DELETE_REQUEST, meta: { project, id }},
            {type: API_ASSEMBLY_DELETE_SUCCESS, meta: { project, id }},
            {type: API_ASSEMBLY_DELETE_FAILURE, meta: { project, id }},
        ]
    })
}

export function fetchAssemblyList(project) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/assembly?project=${project}`,
        method: 'GET',
        types: [
            {type: API_ASSEMBLY_LIST_REQUEST, meta: { project }},
            {type: API_ASSEMBLY_LIST_SUCCESS, meta: { project }},
            {type: API_ASSEMBLY_LIST_FAILURE, meta: { project }},
        ]
    })
}

function shouldFetchAssemblyList(state, project) {
    const assemblylist = state.assemblysByParent['project::'+project];
    if (
        !assemblylist ||
        ((assemblylist.isFetching || assemblylist.isFailed) && Date.now() - assemblylist.lastFetch > config.FETCH_TIMEOUT) ||
        (!assemblylist.isFetching && Date.now() - (assemblylist.lastUpdated ? assemblylist.lastUpdated : 0) > config.FETCH_AGAIN)
    ) {
        return true
    }
    return false
}

export default function fetchAssemblyListIfNeeded(project) {
    return (dispatch, getState) => {

        if (shouldFetchAssemblyList(getState(), project)) {
            return dispatch(fetchAssemblyList(project))
        }
    }
}
