import React, { Component } from 'react'
import { Grid, Responsive, Header } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import GoBackBtn from './goBackBtn'

class CustomFrontendNavigation extends Component {

    render() {
        const { title } = this.props;
        return (

            <Responsive minWidth={Responsive.onlyTablet.minWidth} as={Grid} container className="functions-navigation">
                <Grid.Column width={4}>
                    <GoBackBtn/>
                </Grid.Column>
                <Grid.Column width={8} textAlign="center">
                    <Header as="h2">{title}</Header>
                </Grid.Column>
                <Grid.Column width={4}>

                </Grid.Column>
            </Responsive>
           
        )
    }
}

export default withRouter(CustomFrontendNavigation);
