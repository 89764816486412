import React, { Component } from 'react'
import Headroom from 'react-headroom';
import { Container, Dropdown, Menu, Image } from 'semantic-ui-react';
import { zeroRightClassName } from 'react-remove-scroll-bar';
import CustomPopup from '../CustomPopup/CustomPopup';
import filterIcon from '../../images/icons/frontend/icon-filter.svg';
import activeFilterIcon from '../../images/icons/frontend/icon-active-filter.svg';
import './FilterSearch.scss';
import { ReactSVG } from 'react-svg';

class FilterSearch extends Component {

    state = {
        filterSearchIsOpen: false,
        filterPosition: 0,
    }

    componentDidMount = () => {
        //Get Position of headroom element (filter bar)
        if(document.querySelector('.forum-filter-headroom')){
            this.setState({ filterPosition: document.querySelector('.forum-filter-headroom').offsetTop })
        }
    }

    componentWillUnmount = () => {
        document.getElementById('root').removeEventListener('click', this.handleClick, false);
    } 

    render() {

        const { filterSearchIsOpen, filterPosition, showTagging } = this.state;
        const { children, filterHeader, switchViews, onRequestSwitchView, SwitchItem, onRequestOpen, SwitchItem2, currentView, onRequestClose, filterOpen } = this.props;

        return (
            <>
            <Headroom
                disableInlineStyles
                pinStart={filterPosition}
                className={filterSearchIsOpen ? "forum-filter-headroom filter-show" : "forum-filter-headroom"}
            >

                <div className={zeroRightClassName + " headroom-content"}>
                    <div className="top-bar" ref={node => this.node = node}>
                        <Menu
                            secondary
                            className="topheader-sort"
                        >
                            <Container>
                                <Menu.Item position="left">
                                    {/* <Dropdown TODO: ADD DROPDOWN
                                        inline
                                        options={config.getForumOrdersDropdown()}
                                        onChange={this.handleChangeDropdown}
                                    /> */}
                                </Menu.Item>

                                {/* Enable different list views */}
                                {switchViews && 
                                <>
                                    <Menu.Item className="gallery-view" onClick={() => onRequestSwitchView(SwitchItem.name)}>
                                        <Image src={currentView === SwitchItem.name ? SwitchItem.iconActive : SwitchItem.icon} className={currentView === SwitchItem.name ? 'active' : ''} />
                                    </Menu.Item>
                                    <Menu.Item className="list-view" onClick={() => onRequestSwitchView(SwitchItem2.name)}>
                                        <Image src={currentView === SwitchItem2.name ? SwitchItem2.iconActive : SwitchItem2.icon} className={currentView === SwitchItem2.name ? 'active' : ''} />                                   
                                    </Menu.Item>
                                    <div className="topheader-divider"></div>
                                </>
                                }

                                <Menu.Item  className="toggle-filter" onClick={() => onRequestOpen()}>
                                    <Image alt="Icon Filter" src={filterSearchIsOpen ? activeFilterIcon : filterIcon}/>
                                    <span className={filterSearchIsOpen ? "active-filter" : null}>Filter</span>
                                </Menu.Item>
                            </Container>
                        </Menu>

                    </div>
                </div>

            </Headroom>

            <CustomPopup
                popupOpen={filterOpen}
                onClose={onRequestClose}
                popupHeader={filterHeader}
                headerBackgroundWhite
                showCloseIcon={false}  
            >
                {children}
            </CustomPopup>

            </>
            
        )
    }
}

export default FilterSearch;
