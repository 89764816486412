import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Message, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import { dashboardMesssages } from '../../actions/global'
import config from '../../config/config';


class DashboardAppMessage extends Component {

    componentDidMount(){
        const { listitem, dashboardMessages, metadata } = this.props;

        //SHOW DASHBOARD BANNER MESSAGES
        //IMPORTANT: SHOW DASHBOARD BANNER MESSAGE ON APP UPDATE
        if(metadata && (Date.now() < (config.DASHBOARD_UPDATE_MESSAGE + metadata.build))){
            if(dashboardMessages && dashboardMessages.type === "updateMessage"){
                return;
            }
            this.handleDashboardMessage(true, "updateMessage");
        }
        else if((listitem && !dashboardMessages) || (listitem && (dashboardMessages && dashboardMessages.showedDate )&& (Date.now() > (config.DASHBOARD_STORY_MESSAGE + dashboardMessages.showedDate)))) {
            //Check if project has story...
            for(let i = 0; i < listitem.length; i++){
                if(listitem[i].item.projectpartsData.length > 0 ){
                    this.handleDashboardMessage(false, "storyMessage");
                    break;
                }
                else {
                    this.handleDashboardMessage(true, "storyMessage") //No story found
                }
            }
        }
    }

    handleDashboardMessage = (show, msgType) => {
        const { dispatch, listItem } = this.props;

        dispatch(dashboardMesssages(show, msgType));
    }

    render() {

        const { dashboardMessages } = this.props;

        return (
            dashboardMessages && dashboardMessages.showDashboardMessage ?
                <Message
                    className="dashboard-message-top"
                    color={
                        dashboardMessages.type === "updateMessage" ? "teal" : "red"
                    }
                >
                    {(dashboardMessages.type == "updateMessage" || dashboardMessages.type == "appMessage")?
                        <div className="dashboard-message" onClick={() => this.handleDashboardMessage(false, dashboardMessages.type)}>
                            <Link to={dashboardMessages.type == "updateMessage" ? "/updates" : "/functions/vetero-app"}>
                                {dashboardMessages.message}
                            </Link>
                        </div>
                        :
                        <div className="dashboard-message">{dashboardMessages.message}</div>
                    }
                        <div className="dashboard-message-close" onClick={() => this.handleDashboardMessage(false, dashboardMessages.type)}>
                            <Icon name="close" />
                        </div>
                </Message>
                :
                <div></div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { globalByComponent, account, listByEntity, projectById } = state;
    const { item } = account || {
        item: {},
    }
    const { list } = listByEntity[item.id+ '::userProjects'] || {};
    const dashboardMessages = globalByComponent['DashboardMessage'];
    const metadata = globalByComponent['Metadata'];

    let listitem = [];

    //Own projects
    if (list) {
        for (let [key, value] of Object.entries(list)) {
            if (projectById[value] && !projectById[value].isDeleted) {
                listitem.push(projectById[value]);
            }
        }
    }

    return {
        listitem,
        dashboardMessages,
        metadata,
    }
}

export default connect(mapStateToProps)(DashboardAppMessage);
