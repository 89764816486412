import React, { Component } from 'react';
import { Icon, Form } from 'semantic-ui-react';
import config from '../../config/config';
import ProgressiveImager from '../helpers/progressive-imager';
import './PreviewGallery.scss';
import '../Gallery/Gallery'
import ImageWrapper from '../helpers/image-wrapper';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';

class GalleryItem extends Component {

    state = {
        stringCounterShow: false
    }

    render() {
        const {value, handleDeleteItem, contentId, storyId, handleChangeImageDescription, showDescription } = this.props;
        const { stringCounterShow } = this.state;
        
        return (
            <div className="sortable-wrapper">

                <div className="image-item sortable" key={value.id}>
                    <ImageWrapper progress={value.progress} path={value.path}>
                        <ProgressiveImager alt={`Bild - ${value.id}`} src={config.getImageUrl('main', value.path)}/>
                        <div className="image-delete" onClick={() => handleDeleteItem(value.position)}>
                            <img src={process.env.PUBLIC_URL + '/images/close-cross.svg'} alt="Icon close"/>
                        </div>
                    </ImageWrapper>
                </div>
                {showDescription && value.path !== "ERROR" &&
                    <div className="image-item-description">
                        <Form className="content-gallery-description-textarea">
                            <Form.Field>
                                <Form.TextArea
                                    name={"story."+storyId+".content."+contentId+".gallerymedia."+value.id+".description"}
                                    value={value.description ? value.description : ''}
                                    placeholder="Text (optional)"
                                    rows={3}
                                    maxLength={90}
                                    onChange={handleChangeImageDescription}
                                    onClick={() => this.setState({ stringCounterShow: value.id })}
                                    onBlur={() => this.setState({ stringCounterShow: false })}
                                />

                                    {stringCounterShow === value.id &&
                                        <span className={`textarea-counter ${value.description && value.description.length === 90 ? 'counter-error' : ''}`}>
                                            {value.description && value.description.length ? value.description.length : 0} / 90
                                        </span>
                                    }   
                            </Form.Field>
                        </Form>
                    </div>
                }
                {value.path === "ERROR" && 
                    <div className="image-item-description text-red">
                        Ein Fehler ist aufgetreten!
                    </div>
                }

            </div>

        );
    }
}

const SortableItem = SortableElement(({value, showDescription, handleDeleteItem, storyId, contentId, handleChangeImageDescription}) => {
    return (
        <GalleryItem value={value} showDescription={showDescription} handleDeleteItem={handleDeleteItem} handleChangeImageDescription={handleChangeImageDescription} storyId={storyId} contentId={contentId} />
    )
})

const SortableList = SortableContainer(({ items, handleClick, storyId, contentId, handleChangeImageDescription, showDescription }) => {

    return (
      <div className="images">
        {items && items.map((value, index) => (
          <SortableItem key={`item-${index}`} showDescription={showDescription} handleChangeImageDescription={handleChangeImageDescription} handleDeleteItem={handleClick} index={index} value={value} storyId={storyId} contentId={contentId} />
        ))}
      </div>
    );
  });

class SortableGallery extends Component {

    state = {
        storyGallery: null,
        storyGalleryVideo: null,
        storySlide: 0,
        videoLightboxIsOpen: false
    }

    render() {

        const { images, handleDeleteItem, storyId, onSortEnd, contentId, handleChangeImageDescription, showDescription } = this.props;

        return (
            <div className={`preview-gallery sortable deletable`}>
                <SortableList 
                    helperClass='sortableHelper' 
                    distance={1} 
                    lockToContainerEdges 
                    axis="xy" 
                    handleClick={handleDeleteItem} 
                    items={images} 
                    onSortEnd={onSortEnd} 
                    storyId={storyId} 
                    contentId={contentId}  
                    handleChangeImageDescription={handleChangeImageDescription}
                    showDescription={showDescription}
                />
            </div>
        )
    }
}

export default SortableGallery;
