import React, { Component } from 'react'
import { Container, Divider, Grid, Segment, Header, Icon, Responsive } from 'semantic-ui-react';
import config from '../../../../config/config'
import ProgressiveImager from '../../../../components/helpers/progressive-imager';
import ImageWrapper from '../../../../components/helpers/image-wrapper';
import Truncate from 'react-truncate';


export default class BeContentElement extends Component {

    render() {
        switch(this.props.content.blockprefix){
            case "contentbeforeafter":
                return this.beforeAfterElement();
            case "contentgallery":
                return this.galleryElement();
            case "contentimagetext":
                return this.textImage();
            case "contentvideo":
                return this.video();
            case "contentimage":
                return this.image();
            case "contentheadline":
            case "contenttext":
                return this.text();
            default:
                return (<div>{this.props.content.blockprefix}</div>)
        }
    }

    beforeAfterElement(){
        const {content, popup} = this.props;
        return (
            <Segment className="storys-content storys-content-dashboard before-after" onClick={() => popup(true, "Vorher / Nachher bearbeiten", content.id, content.blockprefix, true)}>
                {content.string ? <Header as="h2">{content.string}</Header> : '' }
                {content.text ? <span className="storys-description">{content.text}</span> : '' }
                {content.string || content.text ? <Divider hidden/> : '' }
                <Grid>
                    <Grid.Column width={8}>
                        <ImageWrapper path={content.mediaBeforePath} className="before-after-image">
                            <ProgressiveImager src={config.getImageUrl('halfwidth', content.mediaBeforePath)}/>
                        </ImageWrapper>
                        <div className="before-after-header">Vorher</div>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <ImageWrapper path={content.mediaAfterPath} className="before-after-image">
                            <ProgressiveImager src={config.getImageUrl('halfwidth', content.mediaAfterPath)}/>
                        </ImageWrapper>
                        <div className="before-after-header">Nachher</div>
                    </Grid.Column>
                </Grid>
            </Segment>
        )
    }

    galleryElement(){
        const {content, popup} = this.props;
        return (
            <Segment className="storys-content storys-content-dashboard" onClick={() => popup(true, "Galerie bearbeiten", content.id, content.blockprefix, true)}>
                {content.string ? <Header as="h2">{content.string}</Header> : '' }
                {content.text ? <span className="storys-description">{content.text}</span> : '' }
                {content.string || content.text ? <Divider hidden/> : '' }
                <Container>
                    <Grid columns={4} className="story-content-galerie-imgs">
                        {content.media && content.media.length > 0 && content.media.slice(0,7).map((m) => (
                            <Grid.Column>
                                <ImageWrapper path={m.path} className="content-galerie-wrapper">
                                        <ProgressiveImager src={config.getImageUrl('preview', m.path)}/>
                                </ImageWrapper>
                            </Grid.Column>
                        ))}
                        {content.media && content.media.length > 0 && content.media.length > 7 &&
                            /* Show more images  */
                            <Grid.Column>
                                <div className="more-images">
                                    <span>+{content.media.length - 7}</span>
                                </div>
                            </Grid.Column>
                        }
                    </Grid>
                </Container>
            </Segment>
        )
    }

    textImage() {
        const {content, popup} = this.props;
        return (
            <Segment className="storys-content storys-content-dashboard" onClick={() => popup(true, "Text / Bild bearbeiten", content.id, content.blockprefix, true)}>                
                <Responsive {...Responsive.onlyMobile}>
                    {content.string ? <Header style={{marginBottom: "1rem"}} as="h2">{content.string}</Header> : '' }
                    {content.text ? <Truncate lines={3} className="storys-description">{content.text}</Truncate> : '' }
                    <ImageWrapper path={content.mediaPath}>
                        <ProgressiveImager style={{marginTop: "0.5rem"}} src={config.getImageUrl('main', content.mediaPath)}/>
                    </ImageWrapper>
                </Responsive>

                <Responsive minWidth={Responsive.onlyTablet.minWidth}>

                    {content.mediaorientation === config.ORIENTATION_LEFT ?
                        <Grid>
                            <Grid.Column width={8}>
                                <ImageWrapper path={content.mediaPath}>
                                    <ProgressiveImager src={config.getImageUrl('main', content.mediaPath)}/>
                                </ImageWrapper>
                            </Grid.Column>
                            <Grid.Column width={8} verticalAlign="middle">
                                {content.string ? <Header style={{marginBottom: "0.5rem"}} as="h2">{content.string}</Header> : '' }
                                {content.text ? <Truncate lines={9} width={300} className="storys-description">{content.text}</Truncate> : '' }
                            </Grid.Column>
                        </Grid>
                    :
                        <Grid>
                            <Grid.Column width={8} verticalAlign="middle">
                                {content.string ? <Header style={{marginBottom: "0.5rem"}} as="h2">{content.string}</Header> : '' }
                                {content.text ? <Truncate lines={9} width={300} className="storys-description">{content.text}</Truncate> : '' }
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <ImageWrapper path={content.mediaPath}>
                                    <ProgressiveImager src={config.getImageUrl('main', content.mediaPath)}/>
                                </ImageWrapper>
                            </Grid.Column>
                        </Grid>
                    }

                </Responsive>

            </Segment>
        )
    }

    video(){
        const {content, popup} = this.props;
        return (
            <Segment className="storys-content storys-content-dashboard video-element" onClick={() => popup(true, "Video bearbeiten", content.id, content.blockprefix, true)}>
                {content.string ? <Header as="h2">{content.string}</Header> : '' }
                {content.text ? <span className="storys-description">{content.text}</span> : '' }
                {content.string || content.text ? <Divider hidden/> : '' }
                <div className="video-preview">
                    <ImageWrapper path={content.mediaPreviewPath}>
                        <ProgressiveImager src={config.getImageUrl('main', content.mediaPreviewPath)}/>
                    </ImageWrapper>
                    <Icon name="play circle outline" inverted/>
                </div>

            </Segment>
        )
    }

    image(){
        const {content, popup} = this.props;
        return (
            <Segment className="storys-content storys-content-dashboard" onClick={() => popup(true, "Bild bearbeiten", content.id, content.blockprefix, true)}>
                {content.string ? <Header as="h2">{content.string}</Header> : '' }
                {content.text ? <span className="storys-description">{content.text}</span> : '' }
                {content.string || content.text ? <Divider hidden/> : '' }
                <ImageWrapper path={content.mediaPath}>
                    <ProgressiveImager src={config.getImageUrl('main', content.mediaPath)}/>
                </ImageWrapper>
            </Segment>
        )
    }

    text(){
        const {content, popup} = this.props;
        return (
            <Segment className="storys-content storys-content-dashboard" onClick={() => popup(true, "Text bearbeiten", content.id, content.blockprefix, true)}>
                {content.string ? <Header as="h2"><Truncate lines={3}>{content.string}</Truncate></Header> : '' }
                {content.text &&
                <>
                    <Responsive maxWidth={Responsive.onlyTablet.maxWidth} className="storys-description">
                        <div className="storys-description">
                            <Truncate lines={3} ellipsis={<>...</>}>{content.text}</Truncate>
                        </div>
                    </Responsive>

                    <Responsive minWidth={Responsive.onlyComputer.minWidth} className="storys-description">
                        <div className="storys-description">
                            <Truncate lines={10} ellipsis={<>...</>}>{content.text}</Truncate>
                        </div>
                    </Responsive>
                </>
                }

            </Segment>
        )
    }
}
