import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Divider, Icon, Container, Grid, Message } from 'semantic-ui-react';
import { setRuntime, handleContainer, footerActiveItem } from '../../../actions/global'; 
import { fetchUserProfessionalIfNeeded, deleteProfessional, fetchUserProfessional, postProfessional } from './../../../actions/professional';
import ItemCard, { ItemCardFetch } from '../../../components/Card/ItemCard';
import CustomPopup from '../../../components/CustomPopup/CustomPopup';
import Moment from 'react-moment';
import "moment/locale/de";
import "moment-timezone";
import PlusButton from './../../../components/PlusButton/PlusButton';
import InfoHandler from '../../../components/ErrorHandler/InfoHandler';
import ErrorHandler from '../../../components/ErrorHandler/ErrorHandler';

class ProfessionalList extends Component {

    state = {
        tagModelIsOpen: false,
        deletePopupisOpen: false,
        professionalDeleteId: 0,
        deleted: false,
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);
        const { dispatch, item, location } = this.props;

        dispatch(setRuntime({
            page: 'professional-edit'
        }));
        dispatch(footerActiveItem("user"))
        dispatch(handleContainer("secondary", 'Deine Spezialisten Anzeigen', false, "dashboard"));
        dispatch(fetchUserProfessionalIfNeeded(item.id));

        //Check if content was deleted
        if(location.hash === "#deleted"){
            this.setState({ deleted: true });
        }
        
    }

    componentDidUpdate = (prevProps) => {
        const { dispatch, listitem, item, inputProfessional, history } = this.props;

        if(listitem.length < prevProps.listitem.length) {
            this.setState({deletePopupisOpen: false, professionalDeleteId: null});
            // Update project
            dispatch(fetchUserProfessional(item.id));
        }

        if(inputProfessional && (inputProfessional.newId && inputProfessional.newUrlslug) && inputProfessional.newId !== prevProps.inputProfessional.newId) {
            history.push('/backend/professional/edit/'+inputProfessional.newId+"/contact");
        }

    }

    createProfessional = (inputProfessional) => {
        const { dispatch } = this.props;
        dispatch(postProfessional(inputProfessional));

    }

    fetchProfessionals = () => {
        const { dispatch, item } = this.props;
        dispatch(fetchUserProfessional(item.id));
    }

    render() {
        
        const { listitem, isFetching, inputProfessional, isFailed, status } = this.props;
        const { deletePopupisOpen, deleted } = this.state;

        return (
            <div className="professionals-userlist">
                <Divider hidden/>

                {isFetching && 
                <>
                    <ItemCardFetch/>
                    <ItemCardFetch/>
                    <ItemCardFetch/>
                </>
                }
                
               {!isFetching && listitem && listitem.length > 0 && listitem.map((professional, index) => (
 
                    <ItemCard 
                        headline={professional.item.headline}
                        title={professional.item.name}
                        status={{
                            active: professional.item.active,
                            true: "Veröffentlicht",
                            false: "Pausiert",
                            activating: (!professional.item.active && (professional.subscription && professional.subscription.status === "ACTIVE")) ? "wird aktiviert" : false,
                            deactivating: (professional.item.active && (professional.subscription && professional.subscription.status === "SUSPENDED")) ? "wird deaktiviert" : false
                        }}
                        image={professional.item.mediamainPath}
                        path={`/backend/professional/edit/${professional.item.id}/contact`}
                        meta={
                            <>
                            <div className="professional-meta-info">
                                <div className="professional-createdate">
                                    Anzeige angelegt: <Moment format="DD.MM.YY" locale="de" unix>{professional.item.createDate}</Moment>
                                </div>
                            </div>
                            </>
                        }
                    />
                ))}

                {!isFetching && status && status === 404 &&
                    <Container text>
                    <InfoHandler errorMessage="Du hast noch keine Anzeigen angelegt."/>
                    </Container>
                }


                {isFailed && !isFetching && (!listitem.length || !listitem) && !status &&
                    <Container text>
                    <ErrorHandler callbackFunction={this.fetchProfessionals}/>
                    </Container>
                }
 

               {/* Popup include */}
               <CustomPopup
                    popupHeader="Anzeige löschen"
                    popupOpen={deletePopupisOpen}
                    onClose={() => this.setState({deletePopupisOpen: false})}
                >
                    {this.confirmDelete()}
                </CustomPopup>

                <Grid>
                    <Grid.Column width={16} textAlign="center">
                        <PlusButton 
                            color="red"
                            title="Neue Anzeige für Spezialisten anlegen"
                            onClick={this.createProfessional}
                            loading={inputProfessional && inputProfessional.isPosting ? inputProfessional.isPosting : false}
                        />
                    </Grid.Column>
                </Grid>

            </div>
        )
    }
    confirmDelete(){

        const { dispatch } = this.props;
        const { professionalDeleteId } = this.state;

        return (
            <Container>
                <Divider hidden/>
                <div style={{textAlign: "center", margin: "1rem 0"}}>
                    <Icon name="warning sign" color="red" size="huge"/>
                </div>
                <div style={{textAlign: "center"}}>Willst du die Anzeige wirklich löschen?</div>
                <Divider hidden/>
                <div className="buttons-center">
                    <Button color="red" onClick={() => {
                            if (professionalDeleteId) {
                                this.setState({popupIsOpen: false, professionalDeleteId: null});
                            }
                        }}>
                        Zurück
                    </Button>
                    <Button basic color="red" loading={!professionalDeleteId} onClick={() => {
                            if (professionalDeleteId) {
                                dispatch(deleteProfessional(professionalDeleteId));
                                this.setState({professionalDeleteId: null});
                            }
                        }}>
                        Löschen
                    </Button>
                </div>
                <Divider hidden/>
            </Container>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { globalByComponent, account, listByEntity, professionalsById } = state;

    const { item, inputProfessional } = account || {
        item: {},
        inputProfessional: {}
    } 

    const { list, isFetching, isFailed, status } = listByEntity[item.id + '::userProfessional'] || {
        list: {},
        isFetching: true,
        isFailed: false,
        status: null
    }

    let listitem = [];
    if (list) {
        for (let [key, value] of Object.entries(list)) {
            if (professionalsById[value]) {
                !professionalsById[value].isDeleted && listitem.push(professionalsById[value]);
            }
        }
    }

    return {
        listitem,
        isFetching,
        item,
        inputProfessional,
        isFailed,
        status
    }
}

export default connect(mapStateToProps)(ProfessionalList);
