import { 
    GET_PAGE_REQUEST,
    GET_PAGE_SUCCESS,
    GET_PAGE_FAILED
} from '../actions/page'


export default function pageByName(state = {}, action) { 
    try {

        if(action.type === GET_PAGE_REQUEST) {
            return {
                ...state,
                [action.meta.pageTitle]: {
                    ...state[action.meta.pageTitle],
                    isFetching: true,
                    isFailed: false           
                }
            }
        }
    
        else if(action.type === GET_PAGE_SUCCESS) {
            return {
                ...state,
                [action.meta.pageTitle]: {
                    ...state[action.meta.pageTitle],
                    ...action.payload,
                    isFetching: false,
                    isFailed: false               
                }
            }
        }
    
        else if(action.type === GET_PAGE_FAILED) {
            return {
                ...state,
                [action.meta.pageTitle]: {
                    ...state[action.meta.pageTitle],
                    isFetching: false,
                    isFailed: true               
                }
            }
        }
    
        return state

    } catch (error) {
        console.log(error)
        return {
            ...state,
            fatalError: true,
        };
    }

}