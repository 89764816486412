import React, { Component } from 'react';
import SlidingPane from 'react-sliding-pane';
import { zeroRightClassName, RemoveScrollBar } from 'react-remove-scroll-bar';
import { Button } from 'semantic-ui-react'
import 'react-sliding-pane/dist/react-sliding-pane.css';
import './CustomSlidingPane.scss';

class CustomSlidingPane extends Component {

    render() {

        const { children, hideHeader, isOpen, title, className, overlayClassName, isLoading, saveButtonLabel, saveButton, onRequestClose, saveButtonCallback, from, closeIcon } = this.props;
        return (
            <>
            <SlidingPane
                isOpen={isOpen}
                title={title}
                className={zeroRightClassName + ' ' + className + " custom-slidingpane"}
                overlayClassName={overlayClassName}
                appElement={document.getElementById('app')}
                saveButton={saveButton}
                hideHeader={hideHeader}
                onRequestClose={onRequestClose && saveButtonCallback ? null : onRequestClose}
                from={!from ? "bottom" : from}
                width="100%"
                closeIcon={
                    closeIcon ? closeIcon
                    : // default
                    <div className="slidingpane-action-btn">
                        {saveButton && <Button loading={isLoading} onClick={isLoading ? null : saveButtonCallback} color="green">{saveButtonLabel ? saveButtonLabel : 'Speichern'}</Button>}
                        <div className="slidingpane-close-btn-wrapper" onClick={onRequestClose}>
                            <img className="slidingpane-close-btn" style={{width: "14px", height: "14px"}} src={process.env.PUBLIC_URL + '/images/close-cross.svg'} alt="Icon close"/>
                        </div>
                    </div>
                }
            >
                <>
                    {children}
                </>
            </SlidingPane> 
            </>
        )
    }
}

export default CustomSlidingPane;
