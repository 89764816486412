import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Button, Grid, Divider, Message, Container } from 'semantic-ui-react';
import { zeroRightClassName,fullWidthClassName, noScrollbarsClassName } from 'react-remove-scroll-bar';
import { setConsent } from '../../actions/global';


class ConsentBanner extends Component {
    render() {
        const {dispatch, ConsentBanner, inline} = this.props;

        if (inline || (ConsentBanner.consent !== true && ConsentBanner.consent !== false)) {
            return (
                <div id="ConsentBanner" style={{
                    background: "white",
                    zIndex: (inline ? 0 : 1000),
                    position: (inline ? 'static' : 'fixed'),
                    bottom: 0,
                    width: '100%',
                }}>
                    <Container>
                        <Grid>
                            <Grid.Column width={16} textAlign="center">
                                <Divider hidden/>
                                <p>Vetero verwendet Cookies, Analyse-Tools und Social-Media-Pixel. So stellen wir sicher, dass Vetero für dich gut funktioniert und wir immer mehr Schrauber und Oldtimer-Fans erreichen. Auf der Seite <a href="/privacy" style={{color: '#e7344c'}}>Datenschutz</a> erfährst du mehr darüber. Du kannst Deine Datenschutzeinstellungen dort anpassen.</p>
                                {inline && ConsentBanner.consent === false &&
                                    <Message negative>Aktuelle Einstellung: Abgelehnt</Message>
                                }
                                {inline && ConsentBanner.consent === true &&
                                    <Message success>Aktuelle Einstellung: Akzeptiert</Message>
                                }
                                <div>
                                    {inline ?
                                        <>
                                            {ConsentBanner.consent ?
                                                <Button basic onClick={() => {
                                                    dispatch(setConsent(false))
                                                }}>Ablehnen</Button>
                                                :
                                                <Button color='green' onClick={() => {
                                                    dispatch(setConsent(true))
                                                }}>
                                                    Akzeptieren
                                                </Button>
                                            }
                                        </>
                                        :
                                        <>
                                            <Button basic onClick={() => dispatch(setConsent(false))}>Ablehnen</Button>
                                            <Button color='green' onClick={() => {
                                                dispatch(setConsent(true))
                                                window.dataLayer.push({'event': 'acceptAll'});
                                            }}>
                                                Akzeptieren
                                            </Button>
                                        </>
                                    }

                                </div>
                                <Divider hidden/>
                            </Grid.Column>
                        </Grid>
                    </Container>

                </div>
            );
        }

        return null;
    }
}

function mapStateToProps(state, ownProps) {
    const { globalByComponent } = state;

    return {
        ConsentBanner: (globalByComponent['ConsentBanner'] ? globalByComponent['ConsentBanner'] : {consent: null}),
    }
}

export default connect(mapStateToProps)(ConsentBanner);
