import React, { Component } from 'react'
import { Container, Grid, Image, Divider, Header, Table, Tab, Responsive, Placeholder, Message } from 'semantic-ui-react'
import { connect } from 'react-redux';
import { ReactSVG } from 'react-svg'
import fetchProjectIfNeeded, { likeProject, followProject } from '../../../actions/project'
import { withRouter, Link as RouterLink } from 'react-router-dom'
import Helmet from 'react-helmet'
import { footerActiveItem, handleHeaderItems, handleContainer, setRuntime, setCanonicalTag } from '../../../actions/global';
import ImageWrapper from '../../../components/helpers/image-wrapper'
import CustomSlidingPane from '../../../components/CustomSlidingPane/CustomSlidingPane';
import Comments from '../../../components/Comments/Comments';
import config from '../../../config/config';
import ProgressiveImager from '../../../components/helpers/progressive-imager';
import ListItemStorys from '../list/listitem-storys';
import Account from '../../../components/account/Account';
import NumericLabel from 'react-pretty-numbers'

/* ICONS */
import heartIcon from '../../../images/icons/frontend/icon-heart.svg';
import commentIcon from '../../../images/icons/frontend/icon-comment.svg';
import eurIcon from '../../../images/icons/frontend/icon-euro-circle.svg';
import clockIcon from '../../../images/icons/frontend/icon-clock-circle.svg';
import heartIconDark from '../../../images/icons/frontend/icon-heart-dark.svg';
import followIcon from '../../../images/icons/frontend/icon-follow.svg';
import followIconActive from '../../../images/icons/frontend/icon-follow-active.svg';
import heartIconActive from '../../../images/icons/frontend/icon-active-heart.svg';
import MetaTag from 'react-meta-tags';
import { StructuredVehicleData } from '../../../components/StructuredData/StructuredData';

let countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/de.json"));

const nl2br = require('react-nl2br');

class FeProject extends Component {

    state = {
        popupIsOpen: false,
        loginPopup: false,
        slidingPane: {
            isOpen: false,
            title: '',
            content: ''
        },
        mobileComments: {
            isOpen: false,
        },
        isLiking: false,
        isFollowing: false,
        activeIndex: 0,
    }

    componentDidMount(){
        const { dispatch, match, location } = this.props;
        dispatch(footerActiveItem("project"));
        dispatch(handleHeaderItems("projects"));
        dispatch(fetchProjectIfNeeded(match.params.id));
        dispatch(handleContainer("secondary", 'SPAN#Fahrzeug'));
        dispatch(handleContainer("secondary", 'SPAN#Fahrzeug', null, null, null, null, {title: `Fahrzeug#${match.params.id + '+' + match.params.tab}`}));
        //Show slidingPane on mount
        if(location.hash === '#comments'){
            this.setState({mobileComments: { isOpen: true }})
        }

        //Handle tab menu
        else if(match.params.tab === 'stories'){
            this.setState({ activeIndex: 0 })
        }
        else if(match.params.tab === 'vehicle'){
            this.setState({ activeIndex: 2 })
        }
        else if(match.params.tab === 'interview'){
            this.setState({ activeIndex: 1 })
        }
        else {
            this.setState({ activeIndex: 0 })
            console.log("tab");
            
            dispatch(setCanonicalTag(config.BASE_LIVE_URL + location.pathname + "/tab/stories"))
        }

        // Set page
        dispatch(setRuntime({
            page: 'fe-project-id-'+match.params.id,
        }));
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { activeIndex } = this.state;
        const { location, match, history, item } = this.props;

        if(prevState.activeIndex !== activeIndex) {
            
            var baseurl = location.pathname;
            // Remove tab
            baseurl = baseurl.replace(/\/tab\/stories/, '');
            baseurl = baseurl.replace(/\/tab\/vehicle/, '');
            baseurl = baseurl.replace(/\/tab\/interview/, '');
            baseurl = baseurl.replace(/\/$/, '');

            if(activeIndex === 1 ) {
                history.push(baseurl + '/tab/interview');
            }
            else if(activeIndex === 2) {
                history.push(baseurl + '/tab/vehicle');
            }
            else {
                history.push(baseurl + '/tab/stories');
            }
        }

        if(prevProps.match.params.tab !== match.params.tab){
            if(match.params.tab === 'stories'){
                this.setState({ activeIndex: 0 })
            }
            else if(match.params.tab === 'vehicle'){
                this.setState({ activeIndex: 2 })
            }
            else if(match.params.tab === 'interview'){
                this.setState({ activeIndex: 1 })
            }
            else {
                this.setState({ activeIndex: 0 })
            }

            this.setTitle();
        }

        if ((!prevProps.item || !prevProps.item.id) && item && item.id) {
            this.setTitle();
        }
    }

    setTitle() {
        const { match, item, dispatch } = this.props;

        // Workaround: Use handleContainer to set title, using helmet in the tab methods doesnt always work
        var tabtext = 'Storys';

        if(match.params.tab === 'vehicle'){
            tabtext = 'Fahrzeugdaten';
        }
        else if(match.params.tab === 'interview'){
            tabtext = 'Interview';
        }

        if (item && item.title) {
            dispatch(handleContainer("secondary", 'SPAN#Fahrzeug', null, null, null, null, {'title': item.title+" von "+item.userData.name+" – "+tabtext}));
        }
    }

    componentWillUnmount = () => {
        const { dispatch, match } = this.props;

        dispatch(setRuntime({
            backStory: {page: 'fe-project-id-'+match.params.id, path: this.props.location, scroll: window.pageYOffset},
            backUser: {page: 'fe-project-id-'+match.params.id, path: this.props.location},
        }));
    }

    handleSlidingPanes = (isOpen, title, content) => {
        this.setState({
            slidingPane: {
                isOpen: true,
                title: title,
                content: content
            }
        })
    }

    handleLikeProject = (id, value) => {
        const { dispatch, isLiking, item } = this.props;

        if(!isLiking) {
            dispatch(likeProject(id, value, item.countLike));
        }
    }

    handleFollowProject = (id, value) => {
        const { dispatch, isFollowing } = this.props;

        if(!isFollowing){
            dispatch(followProject(id, value))
        }
    }

    handleCloseMobileComments = () => {
        this.setState({mobileComments: {show: false}})
    }

    handleTabChange = (e, { activeIndex }) => this.setState({ activeIndex })

    render() {

        const { item, isFetching, accountItem, match } = this.props;

        //Tab menu content
        const projectPanes = [
            {
                menuItem: 'Storys',
                render: () => this.projectStories()
            },
            {
                menuItem: 'Interview',
                render: () => this.projectInfo()
            },
            {
                menuItem: 'Fahrzeugdaten',
                render: () => this.vehicleData()
            },
        ]

        if(!(item && item.id) && isFetching) {
            return (
                <div>
                    <div className="project-header">
                        <div className="image">
                            <Placeholder>
                                <Placeholder.Image/>
                            </Placeholder>
                        </div>
                        <div className="project-details">
                            <Container>
                                <Grid>
                                    <Grid.Row>

                                        <Grid.Column mobile={4} tablet={3} computer={3}>
                                            <div className="detail">
                                                {/* <Image src={clockIcon} alt="Icon Clock"/> */}
                                                <ReactSVG src={clockIcon} />
                                                <span className="detail-sum">-</span>
                                            </div>
                                        </Grid.Column>

                                        <Grid.Column mobile={4} tablet={3} computer={3}>
                                            <div className="detail">
                                                <ReactSVG src={eurIcon}/>
                                                <span className="detail-sum">-</span>
                                            </div>
                                        </Grid.Column>

                                        <Grid.Column mobile={4} tablet={3} computer={3}>
                                            <div className="detail">
                                                <ReactSVG src={heartIcon}/>
                                                <span className="detail-sum">-</span>
                                            </div>
                                        </Grid.Column>

                                        <Grid.Column mobile={4} tablet={3} computer={2}>
                                            <div className="detail">
                                                <ReactSVG src={commentIcon}/>
                                                <span className="detail-sum">-</span>
                                            </div>
                                        </Grid.Column>
                                        {/* <Grid.Column only="computer" width={1}>
                                            <div className="divider-vertical"></div>
                                        </Grid.Column> */}

                                    </Grid.Row>
                                </Grid>
                            </Container>
                        </div>
                    </div>
                    <Divider hidden/>
                </div>
                )
            }

        else if (item && item.id) {
            const { brand, vehicle, td } = this.props;
            return (
                <>
                    {/* Insert Schema.org markup */}
                    <MetaTag>
                        <script type="application/ld+json">
                            {StructuredVehicleData(vehicle, brand, td, item)}
                        </script>
                    </MetaTag>

                    <div className="project-header">
                        <Responsive {...Responsive.onlyMobile} className="user-label">
                            <RouterLink to={`/user/${item.userData.urlslug}/${item.user}`}>
                                <div className="user-image-wrapper">
                                    <ImageWrapper avatar circular path={item.userData.mediaavatarPath}>
                                        <ProgressiveImager placeholder="avatar" circular src={config.getImageUrl('avatar', item.userData.mediaavatarPath)} alt={"Profilbild von " + item.userData.name} />
                                    </ImageWrapper>
                                </div>
                                <span className="user-name">
                                    {item.userData.name}
                                </span>
                            </RouterLink>
                        </Responsive>

                        <Responsive as={Container} minWidth={Responsive.onlyTablet.minWidth} style={{position: "relative"}}>
                            <div className="user-label">
                                <RouterLink to={`/user/${item.userData.urlslug}/${item.user}`}>
                                    <div className="user-image-wrapper">
                                        <ImageWrapper avatar circular path={item.userData.mediaavatarPath}>
                                            <ProgressiveImager placeholder="avatar" circular src={config.getImageUrl('avatar', item.userData.mediaavatarPath)} alt={item.userData.name} />
                                        </ImageWrapper>
                                    </div>
                                    <span className="user-name">
                                        {item.userData.name}
                                    </span>
                                </RouterLink>
                            </div>
                        </Responsive>

                        <Responsive {...Responsive.onlyMobile}>
                            <ProgressiveImager backgroundImage className="image" src={config.getImageUrl('main', item.mediamainPath)}/>
                        </Responsive>

                        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                            <ProgressiveImager backgroundImage className="image" src={config.getImageUrl('mainDesktop', item.mediamainPath)}/>
                        </Responsive>

                        <div className="project-details">
                            <Container>
                                <Grid>
                                    <Grid.Row columns='equal' textAlign="center">

                                        <Grid.Column>
                                            <div className="detail">
                                                <Image alt='Icon clock' src={clockIcon}/> 
                                                    {item.showWorkinghours ?
                                                        <>
                                                            <span className="detail-sum">{item.sumWorkinghours ? config.getFormattedInt(item.sumWorkinghours)+'h' : '–'}</span>
                                                        </>
                                                    :
                                                        '-'
                                                    }
     

                                            </div>
                                        </Grid.Column>

                                        <Grid.Column>
                                            <div className="detail">
                                                <Image alt='Icon Euro' src={eurIcon}/> 
                                                <span className="detail-sum">
                                                    {item.showExpenses ?
                                                        <>
                                                            {item.sumPrice ?
                                                            <>
                                                                <NumericLabel params={config.PRETTY_NUMERIC_CONFIG}>{item.sumPrice }</NumericLabel>
                                                                <span>€</span>
                                                            </>
                                                            :
                                                            '0€'}
                                                        </>
                                                    :
                                                        '-'
                                                    }
                                                </span>
                                            </div>
                                        </Grid.Column>

                                        <Grid.Column>

                                            <div className="detail detail-like" onClick={() => accountItem && accountItem.id ? this.handleLikeProject(item.id, !item.userLike) : this.setState({loginPopup: true})}>
                                                <Image src={item.userLike ? heartIconActive : heartIcon} />
                                                <span className="detail-sum">
                                                    {item.countLike ?
                                                        <NumericLabel style={{display: "inline-block"}} params={config.PRETTY_CONFIG}>{item.countLike}</NumericLabel>
                                                        :
                                                        '0'
                                                    }
                                                </span>
                                            </div>

                                        </Grid.Column>

                                        <Grid.Column>
                                            <div className="detail detail-comment" onClick={() => this.setState({mobileComments: { isOpen: true }})}>
                                                <Image src={commentIcon} />
                                                <span className="detail-sum">

                                                    {item.countComment ?
                                                        <NumericLabel style={{display: "inline-block"}} params={config.PRETTY_CONFIG}>{item.countComment}</NumericLabel>
                                                        :
                                                        '0'
                                                    }

                                                </span>
                                            </div>
                                        </Grid.Column>

                                        <Grid.Column>
                                            {accountItem && accountItem.id ?
                                                <div className="user-actions-icon detail" onClick={() => this.handleFollowProject(item.id, !item.userFavorite)}>
                                                    <Image src={item.userFavorite ? followIconActive : followIcon} />
                                                    <Responsive as={'span'} minWidth={Responsive.onlyTablet.minWidth} className={item.userFavorite ? "action action-active" : "action"}>Folgen</Responsive>
                                                </div>
                                                :
                                                <div className="user-actions-icon detail" onClick={() => this.setState({ loginPopup: true })}>
                                                    <Image src={item.userFavorite ? followIconActive : followIcon} />
                                                    <Responsive as={'span'} minWidth={Responsive.onlyTablet.minWidth} className="action">Folgen</Responsive>
                                                </div>
                                            }
                                        </Grid.Column>

                                        {/* <Grid.Column width={16}>
                                            <Divider hidden/>
                                            <Grid columns={3}  className="user-actions">
                                                <Grid.Column>
                                                    {accountItem && accountItem.id ?
                                                        <div className="user-actions-icon" onClick={() => this.handleLikeProject(item.id, !item.userLike)}>
                                                            <Image src={item.userLike ? heartIconActive : heartIconDark}/>
                                                            <span className={item.userLike ? "action action-active" : "action"}>Gefällt mir</span>
                                                        </div>
                                                    :
                                                        <div className="user-actions-icon" onClick={() => this.setState({loginPopup: true})}>
                                                            <Image src={heartIconDark}/>
                                                            <span className="action">Gefällt mir</span>
                                                        </div>
                                                    }

                                                </Grid.Column>
                                                <Grid.Column>
                                                    {accountItem && accountItem.id ?
                                                        <div className="user-actions-icon" onClick={() => this.handleFollowProject(item.id, !item.userFavorite)}>
                                                            <Image src={item.userFavorite ? followIconActive : followIcon }/>
                                                            <span className={item.userFavorite ? "action action-active" : "action"}>Projekt folgen</span>
                                                        </div>
                                                    :
                                                        <div className="user-actions-icon" onClick={() => this.setState({loginPopup: true})}>
                                                            <Image src={followIcon}/>
                                                            <span className="action">Projekt folgen</span>
                                                        </div>
                                                    }
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <div className="user-actions-icon" onClick={() => this.setState({mobileComments: { isOpen: true }})}>
                                                        <Image src={commentIconDark}/>
                                                        <span className="action">
                                                            Kommentieren
                                                        </span>
                                                    </div>
                                                </Grid.Column>
                                            </Grid>
                                        </Grid.Column> */}

                                    </Grid.Row>
                                </Grid>
                            </Container>
                        </div>
                    </div>

                    {/* Mobile Comments slidingPane */}
                    <Responsive
                        as={CustomSlidingPane}
                        maxWidth={Responsive.onlyTablet.maxWidth}
                        isOpen={this.state.mobileComments.isOpen}
                        title="Kommentare"
                        onRequestClose={() => this.setState({mobileComments: { isOpen: false }})}
                    >
                        <Comments parent={"project::" + match.params.id} />
                    </Responsive>

                    <Responsive
                        as={CustomSlidingPane}
                        minWidth={Responsive.onlyComputer.minWidth}
                        isOpen={this.state.mobileComments.isOpen}
                        title="Kommentare"
                        from='right'
                        onRequestClose={() => this.setState({mobileComments: { isOpen: false }})}
                    >
                        <Comments parent={"project::" + match.params.id} />
                    </Responsive>

                    <Account loginPopupVisible={this.state.loginPopup} loginPopupClose={() => this.setState({loginPopup: false})}/>

                    <Divider hidden/>

                    {/* Tab menu */}
                    <Tab
                        activeIndex={this.state.activeIndex}
                        // defaultActiveIndex={window.location.ha}
                        className="custom-tab-menu"
                        menu={{ pointing: true }}
                        panes={projectPanes}
                        onTabChange={this.handleTabChange}
                    />

                </>
            )
        }
        else {
            return (
                <Container text>
                    <Divider hidden/>
                    <Message negative>Es ist ein Fehler aufgetreten.</Message>
                </Container>
            )
        }
    }
    projectInfo(){

        const { item, brand, vehicle, td } = this.props;

        return (
            <>
                <Helmet>
                    <title>{item.title} von {item.userData.name} – Interview{config.TITLE_SUFFIX}</title>
                </Helmet>

                {!item.description1 && !item.description2 && !item.description3 && !item.description4 && !item.description5 && !item.description &&
                    <Container>
                        <Helmet>
                            <meta name="description" content={item.userData.name+" hat noch keine allgemeinen Informationen zum Oldtimer "+brand.name+" "+vehicle.name+" ("+td.year+") preisgegeben. Es gibt aber viele weitere Oldtimer auf Vetero."} />
                        </Helmet>

                        <Divider hidden/>
                        <Message color="teal">
                            {item.userData.name} hat noch keine allgemeinen Informationen für dieses Projekt preisgegeben.
                        </Message>
                    </Container>
                }

                {(item.description1 || item.description2 || item.description3 || item.description4 || item.description5 || item.description) &&

                <Container>

                    {item.description1 &&
                        <Helmet>
                            <meta name="description" content={item.userData.name+" hat sich aus folgendem Grund für den Oldtimer "+brand.name+" "+vehicle.name+" ("+td.year+") entschieden: "+(item.description1.length >= 100 ? item.description1.substring(0, 97) + "..." : item.description1)} />
                        </Helmet>
                    }
                    {!item.description1 && item.description &&
                        <Helmet>
                            <meta name="description" content={item.userData.name+" hat sich aus folgendem Grund für den Oldtimer "+brand.name+" "+vehicle.name+" ("+td.year+") entschieden: "+(item.description.length >= 100 ? item.description.substring(0, 97) + "..." : item.description)} />
                        </Helmet>
                    }
                    {!item.description1 && !item.description &&
                        <Helmet>
                            <meta name="description" content={"Hier findest Du alle Infos zum Oldtimer "+brand.name+" "+vehicle.name+" ("+td.year+") von "+item.userData.name+". Hast Du auch einen Oldtimer? Dann ist Vetero genau das Richtige für Dich."} />
                        </Helmet>
                    }

                    <Divider hidden/>

                    <Container className="storys-content text-element">
                        {item.title ? <Header textAlign="center" className="story-main-header" style={{textTransform: 'unset'}} as="h1">{item.title} von {item.userData.name} – Interview</Header> : '' }
                    </Container>

                    <Divider hidden/>

                    <div className="interview-content">

                        <Grid>
                            {item.description1 &&
                                <Grid.Row>
                                    <Grid.Column mobile={16} tablet={6} computer={6}>
                                        <h3 className="interview-header">Warum mußte es dieses Fahrzeug sein?</h3>
                                    </Grid.Column>
                                    <Grid.Column mobile={16} tablet={10} computer={10}>
                                        <p>{nl2br(item.description1)}</p>
                                    </Grid.Column>
                                    <Divider />
                                </Grid.Row>
                            }

                            {item.description2 &&
                                <Grid.Row>
                                    <Grid.Column mobile={16} tablet={6} computer={6}>
                                        <h3 className="interview-header">Wie bist Du auf das Fahrzeug aufmerksam geworden?</h3>
                                    </Grid.Column>
                                    <Grid.Column mobile={16} tablet={10} computer={10}>
                                        <p>{nl2br(item.description2)}</p>
                                    </Grid.Column>
                                    <Divider />
                                </Grid.Row>
                            }

                            {item.description3 &&
                                <Grid.Row>
                                    <Grid.Column mobile={16} tablet={6} computer={6}>
                                        <h3 className="interview-header">Wie kam das Fahrzeug zu Dir nach Hause?</h3>
                                    </Grid.Column>
                                    <Grid.Column mobile={16} tablet={10} computer={10}>
                                        <p>{nl2br(item.description3)}</p>
                                    </Grid.Column>
                                    <Divider />
                                </Grid.Row>
                            }

                            {item.description4 &&
                                <Grid.Row>
                                    <Grid.Column mobile={16} tablet={6} computer={6}>
                                        <h3 className="interview-header">Wie war der Zustand bei Abholung?</h3>
                                    </Grid.Column>
                                    <Grid.Column mobile={16} tablet={10} computer={10}>
                                        <p>{nl2br(item.description4)}</p>
                                    </Grid.Column>
                                    <Divider />
                                </Grid.Row>
                            }

                            {item.description5 &&
                                <Grid.Row>
                                    <Grid.Column mobile={16} tablet={6} computer={6}>
                                        <h3 className="interview-header">Was hast Du mit dem Fahrzeug vor (Ziel)?</h3>
                                    </Grid.Column>
                                    <Grid.Column mobile={16} tablet={10} computer={10}>
                                        <p>{nl2br(item.description5)}</p>
                                    </Grid.Column>
                                    <Divider />
                                </Grid.Row>
                            }

                            {item.description &&
                                <Grid.Row>
                                    <Grid.Column mobile={16} tablet={6} computer={6}>
                                        <h3 className="interview-header">Gibt es sonst noch etwas spannendes oder lustiges? Wie lief es bisher?</h3>
                                    </Grid.Column>
                                    <Grid.Column mobile={16} tablet={10} computer={10}>
                                        <p>{nl2br(item.description)}</p>
                                    </Grid.Column>
                                </Grid.Row>
                            }

                        </Grid>
                    </div>
                    <Divider hidden />
                </Container>

                        }
                    </>
        )
    }
    vehicleDataTable(){
        const { tdo, td, vehicle, brand } = this.props;
        return (<>

                <Responsive minWidth={Responsive.onlyComputer.minWidth} className="table-top-header">
                    <span className="placeholder-header"></span>
                    <span className="td-header">Ab Werk</span>
                    <span className="tdo-header">Aktuell</span>
                </Responsive>
                <Table celled striped unstackable className="vehicle-data-table">
                        <Table.Body>

                            <Table.Row>

                                <Table.HeaderCell>
                                    Fahrzeugart
                                </Table.HeaderCell>

                                <Table.Cell>
                                    {config.getDefault(config.getDesigns(vehicle.scope), td.design).label}
                                </Table.Cell>

                                <Responsive minWidth={Responsive.onlyComputer.minWidth} as={Table.Cell}>
                                    {config.getDefault(config.getDesigns(vehicle.scope), tdo.design).label}
                                </Responsive>

                            </Table.Row>

                            <Table.Row>

                                <Table.HeaderCell>
                                    Motorart
                                </Table.HeaderCell>

                                <Table.Cell>
                                    {config.getDefault(config.getEngines(), td.engine).label}
                                </Table.Cell>

                                <Responsive minWidth={Responsive.onlyComputer.minWidth} as={Table.Cell}>
                                    {config.getDefault(config.getEngines(), tdo.engine).label}
                                </Responsive>

                            </Table.Row>

                            <Table.Row>

                                <Table.HeaderCell>
                                    Anzahl Zylinder
                                </Table.HeaderCell>

                                <Table.Cell>
                                    {td.cylinder ? td.cylinder : '-'}
                                </Table.Cell>

                                <Responsive minWidth={Responsive.onlyComputer.minWidth} as={Table.Cell}>
                                    {td.cylinder ? td.cylinder : '-'}
                                </Responsive>

                            </Table.Row>

                            <Table.Row>

                                <Table.HeaderCell>
                                    Zylinderanordnung
                                </Table.HeaderCell>

                                <Table.Cell>
                                    {config.getDefault(config.getLayouts(), td.layout).label}
                                </Table.Cell>

                                <Responsive minWidth={Responsive.onlyComputer.minWidth} as={Table.Cell}>
                                    {config.getDefault(config.getLayouts(), tdo.layout).label}
                                </Responsive>

                            </Table.Row>

                            <Table.Row>

                                <Table.HeaderCell>
                                    Hubraum(ccm)
                                </Table.HeaderCell>

                                <Table.Cell>
                                    {td.displacement ? td.displacement : '-'}
                                </Table.Cell>

                                <Responsive minWidth={Responsive.onlyComputer.minWidth} as={Table.Cell}>
                                    {td.displacement ? td.displacement : '-'}
                                </Responsive>

                            </Table.Row>

                            <Table.Row>

                                <Table.HeaderCell>
                                    Leistung(PS)
                                </Table.HeaderCell>

                                <Table.Cell>
                                    {td.performance ? td.performance : '-'}
                                </Table.Cell>

                                <Responsive minWidth={Responsive.onlyComputer.minWidth} as={Table.Cell}>
                                    {td.performance ? td.performance : '-'}
                                </Responsive>

                            </Table.Row>

                            <Table.Row>

                                <Table.HeaderCell>
                                    Kraftstoff/Energie
                                </Table.HeaderCell>

                                <Table.Cell>
                                    {config.getDefault(config.getFuels(), td.fuel).label}
                                </Table.Cell>

                                <Responsive minWidth={Responsive.onlyComputer.minWidth} as={Table.Cell}>
                                    {config.getDefault(config.getFuels(), tdo.fuel).label}
                                </Responsive>

                            </Table.Row>

                            <Table.Row>

                                <Table.HeaderCell>
                                    Getriebe
                                </Table.HeaderCell>

                                <Table.Cell>
                                    {config.getDefault(config.getGears(), td.gear).label}
                                </Table.Cell>

                                <Responsive minWidth={Responsive.onlyComputer.minWidth} as={Table.Cell}>
                                    {config.getDefault(config.getGears(), tdo.gear).label}
                                </Responsive>

                            </Table.Row>

                            <Table.Row>

                                <Table.HeaderCell>
                                    Anzahl Gänge
                                </Table.HeaderCell>

                                <Table.Cell>
                                    {td.gearno ? td.gearno : '-'}
                                </Table.Cell>

                                <Responsive minWidth={Responsive.onlyComputer.minWidth} as={Table.Cell}>
                                    {tdo.gearno ? td.gearno : '-'}
                                </Responsive>

                            </Table.Row>

                            <Table.Row>

                                <Table.HeaderCell>
                                    Antriebsart
                                </Table.HeaderCell>

                                <Table.Cell>
                                    {config.getDefault(config.getDrives(), td.drive).label}
                                </Table.Cell>

                                <Responsive minWidth={Responsive.onlyComputer.minWidth} as={Table.Cell}>
                                    {config.getDefault(config.getDrives(), tdo.drive).label}
                                </Responsive>

                            </Table.Row>

                        </Table.Body>
                    </Table>
        </>)
    }
    vehicleDataAfter(){
        const { tdo, vehicle } = this.props;

        return(
            <Table celled striped unstackable className="vehicle-data-table">
                        <Table.Body>

                            <Table.Row>

                                <Table.HeaderCell>
                                    Fahrzeugart
                                </Table.HeaderCell>

                                <Table.Cell>
                                    {config.getDefault(config.getDesigns(vehicle.scope), tdo.design).label}
                                </Table.Cell>

                            </Table.Row>

                            <Table.Row>

                                <Table.HeaderCell>
                                    Motorart
                                </Table.HeaderCell>

                                <Table.Cell>
                                    {config.getDefault(config.getEngines(), tdo.engine).label}
                                </Table.Cell>

                            </Table.Row>

                            <Table.Row>

                                <Table.HeaderCell>
                                    Anzahl Zylinder
                                </Table.HeaderCell>

                                <Table.Cell>
                                    {tdo.cylinder ? tdo.cylinder : "-"}
                                </Table.Cell>

                            </Table.Row>

                            <Table.Row>

                                <Table.HeaderCell>
                                    Zylinderanordnung
                                </Table.HeaderCell>

                                <Table.Cell>
                                    {config.getDefault(config.getLayouts(), tdo.layout).label}
                                </Table.Cell>

                            </Table.Row>

                            <Table.Row>

                                <Table.HeaderCell>
                                    Hubraum(ccm)
                                </Table.HeaderCell>

                                <Table.Cell>
                                    {tdo.displacement ? tdo.displacement : "-"}
                                </Table.Cell>

                            </Table.Row>

                            <Table.Row>

                                <Table.HeaderCell>
                                    Leistung(PS)
                                </Table.HeaderCell>

                                <Table.Cell>
                                    {tdo.performance ? tdo.performance : "-"}
                                </Table.Cell>

                            </Table.Row>

                            <Table.Row>

                                <Table.HeaderCell>
                                    Kraftstoff/Energie
                                </Table.HeaderCell>

                                <Table.Cell>
                                    {config.getDefault(config.getFuels(), tdo.fuel).label}
                                </Table.Cell>

                            </Table.Row>

                            <Table.Row>

                                <Table.HeaderCell>
                                    Getriebe
                                </Table.HeaderCell>

                                <Table.Cell>
                                    {config.getDefault(config.getGears(), tdo.gear).label}
                                </Table.Cell>

                            </Table.Row>

                            <Table.Row>

                                <Table.HeaderCell>
                                    Anzahl Gänge
                                </Table.HeaderCell>

                                <Table.Cell>
                                    {tdo.gearno ? tdo.gearno : "-"}
                                </Table.Cell>

                            </Table.Row>

                            <Table.Row>

                                <Table.HeaderCell>
                                    Antriebsart
                                </Table.HeaderCell>

                                <Table.Cell>
                                    {config.getDefault(config.getDrives(), tdo.drive).label}
                                </Table.Cell>

                            </Table.Row>

                            </Table.Body>
                    </Table>
        )
    }
    vehicleData(){

        const { item, td, vehicle, brand, manufacturer } = this.props;

        const panes = [
            {
                menuItem: "Ab Werk",
                render: () => this.vehicleDataTable()
            },
            {
                menuItem: "Aktuell",
                render: () => this.vehicleDataAfter()
            }
        ]

        return(
            <Container>
                <Helmet>
                    <title>{item.title} von {item.userData.name} – Fahrzeugdaten{config.TITLE_SUFFIX}</title>
                    <meta name="description" content={"Fahrzeug- und Technische-Daten zum Oldtimer "+brand.name+" "+vehicle.name+" ("+td.year+") von "+item.userData.name+". Hast Du auch einen Oldtimer? Dann ist Vetero genau das Richtige für Dich."} />
                </Helmet>

                <Divider hidden/>

                <Container className="storys-content text-element">
                    {item.title ? <Header textAlign="center" className="story-main-header" style={{textTransform: 'unset'}} as="h1">{item.title} von {item.userData.name} – Fahrzeugdaten</Header> : '' }
                </Container>

                <Divider hidden/>

                    <Grid>
                        <Grid.Column mobile={16} tablet={16} computer={6}>
                        <Table celled striped unstackable className="vehicle-data-table basic-data">
                            <Table.Body>

                                <Table.Row>

                                    <Table.HeaderCell>
                                        Kategorie
                                    </Table.HeaderCell>

                                    <Table.Cell>
                                    {config.getDefault(config.getScopes(), vehicle.scope).label}
                                    </Table.Cell>

                                </Table.Row>

                                <Table.Row>

                                    <Table.HeaderCell>
                                        Marke
                                    </Table.HeaderCell>

                                    <Table.Cell>
                                        {brand.name}
                                    </Table.Cell>

                                </Table.Row>

                                <Table.Row>

                                    <Table.HeaderCell>
                                        Modell
                                    </Table.HeaderCell>

                                    <Table.Cell>
                                        {vehicle.name}
                                    </Table.Cell>

                                </Table.Row>

                                <Table.Row>

                                    <Table.HeaderCell>
                                        Werkscode
                                    </Table.HeaderCell>

                                    <Table.Cell>
                                    {vehicle.typecode ? vehicle.typecode : "-"}
                                    </Table.Cell>

                                </Table.Row>

                                <Table.Row>

                                    <Table.HeaderCell>
                                        Baujahr
                                    </Table.HeaderCell>

                                    <Table.Cell>
                                        {td.year}
                                    </Table.Cell>

                                </Table.Row>

                                <Table.Row>

                                    <Table.HeaderCell>
                                        Hersteller
                                    </Table.HeaderCell>

                                    <Table.Cell>
                                        {manufacturer.name ? manufacturer.name : "-"}
                                    </Table.Cell>

                                </Table.Row>

                                <Table.Row>

                                        <Table.HeaderCell>
                                            Land (Marke)
                                        </Table.HeaderCell>

                                        <Table.Cell>
                                            {brand.countrycode ?
                                                countries.getName(brand.countrycode, "de")
                                                :
                                                "-"
                                            }
                                        </Table.Cell>

                                    </Table.Row>

                                    <Table.Row>

                                        <Table.HeaderCell>
                                            Land (Hersteller)
                                        </Table.HeaderCell>

                                        <Table.Cell>
                                            {manufacturer.countrycode ?
                                                countries.getName(manufacturer.countrycode, "de")
                                                :
                                                "-"
                                            }
                                        </Table.Cell>

                                    </Table.Row>

                                    <Table.Row>

                                        <Table.HeaderCell>
                                            Gängige Bezeichnung
                                        </Table.HeaderCell>

                                        <Table.Cell>
                                            {vehicle.nickname ? vehicle.nickname : "-"}
                                        </Table.Cell>

                                    </Table.Row>

                                    {/* <Table.Row>

                                        <Table.HeaderCell>
                                            Spitzname des Fahrzeugs
                                        </Table.HeaderCell>

                                        <Table.Cell>
                                            {vehicle.nickname ? vehicle.nickname : "-"}
                                        </Table.Cell>

                                    </Table.Row> */}
                            </Table.Body>
                        </Table>
                        <Responsive maxWidth={Responsive.onlyTablet.maxWidth}>
                            <Divider hidden/>
                            <Tab className="custom-tab-menu" menu={{ pointing: true }} panes={panes} />
                            <Divider hidden/>
                        </Responsive>
                        </Grid.Column>
                        <Grid.Column width={10} only="computer">
                            {this.vehicleDataTable()}
                            {/* {this.vehicleDataAfter()} */}
                        </Grid.Column>
                    </Grid>
                    <Responsive minWidth={Responsive.onlyComputer.minWidth}>
                        <Divider hidden/>
                    </Responsive>
            </Container>
        )
    }
    projectStories(projectId){

        const { item } = this.props;

        return (
                <Container>
                    <Helmet>
                        <title>{item.title} von {item.userData.name} – Storys{config.TITLE_SUFFIX}</title>
                    </Helmet>

                    <Divider hidden/>

                    <Container className="storys-content text-element">
                        {item.title ? <Header textAlign="center" className="story-main-header" style={{textTransform: 'unset'}} as="h1">{item.title} von {item.userData.name} – Storys</Header> : '' }
                    </Container>

                    <Divider hidden/>
                    <Divider hidden/>

                    <ListItemStorys
                        props={this.props}
                        projectId={projectId}
                    />

               </Container>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { globalByComponent, projectById, account } = state

    const { isFetching, item, tdo, td, vehicle, brand, manufacturer, isLiking, isFollowing } = projectById[
        ownProps.match.params.id
    ] || {
        isFetching: true,
        item: {},
    }

    const { item: accountItem } = account || {
        accountItem: {},
        isFetching: true
    }

    const { type, headerTitle } = globalByComponent[
        'Container'
    ] || {

    }
    const { page, backStory } = globalByComponent[
        'Runtimedata'
    ] || {}

    return {
        page,
        backStory,
        type,
        headerTitle,
        isFetching,
        item,
        tdo,
        td,
        vehicle,
        brand,
        manufacturer,
        accountItem,
        isLiking,
        isFollowing,
    }
}

export default connect(mapStateToProps)(withRouter(FeProject));
