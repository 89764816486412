import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';
import config from '../../config/config';
import ProgressiveImager from '../helpers/progressive-imager';
import './PreviewGallery.scss';
import '../Gallery/Gallery'
import Gallery from '../Gallery/Gallery';
import ImageWrapper from '../helpers/image-wrapper';

class PreviewGallery extends Component {

    state = {
        storyGallery: null,
        storyGalleryVideo: null,
        storySlide: 0,
        videoLightboxIsOpen: false
    }

    render() {

        const { images, sortabel, deletable, itemId, entity, requestDelete, imagesPerRow, enableLightbox, imagesOnly } = this.props;
        let imagesLightbox = [];
        let videosLightbox = [];

        if(enableLightbox){
            images ?
                images.map((m, index) => {
                    if (m.type === config.FILE_IMAGE) {
                        imagesLightbox.push(config.getImageUrl('zoomDesktop', m.path));
                    }
                    else if (m.type === config.FILE_VIDEO) {
                        videosLightbox.push({src: config.getDirectUrl(m.path)});
                    }
                })
            :
                imagesOnly.map((m, index) => (
                    imagesLightbox.push(config.getImageUrl('zoomDesktop', m.path))
                ))
        }

        return (
            <div className={`preview-gallery ${sortabel ? "sortabel" : ""} ${deletable ? "deletable" : ""}`}>
                <div className={`images ${imagesPerRow ? 'cols-'+ imagesPerRow : ""}`}>
                    {!imagesOnly ? 
                        images && images.filter(m => m.type === config.FILE_IMAGE).map((m, index) => (
                            <div className={`image-item ${enableLightbox ? "is-lightbox" : ""}`} key={m.id} onClick={() => this.setState({ storyGallery: true, storySlide: index })}>
                                <ImageWrapper progress={m.progress} path={m.path}>
                                    <ProgressiveImager alt={`Bild - ${m.id}`} src={config.getImageUrl('main', m.path)}/>
                                    {deletable &&
                                        <div className="image-delete" onClick={(e) => {requestDelete(m.id, itemId, entity); e.preventDefault()}}>
                                            <img src={process.env.PUBLIC_URL + '/images/close-cross.svg'} alt="Icon close"/>
                                        </div>
                                    }
                                </ImageWrapper>
                            </div>
                        ))
                        :
                        imagesOnly.map((m, index) => (
                            <div className={`image-item ${enableLightbox ? "is-lightbox" : ""}`} key={m.id} onClick={() => this.setState({ storyGallery: true, storySlide: index })}>
                                <ImageWrapper  progress={m.progress} path={m.path}>
                                    <ProgressiveImager alt={`Bild - ${m.id}`} src={config.getImageUrl('main', m.path)}/>
                                    {deletable &&
                                        <div className="image-delete" onClick={(e) => {requestDelete(m.id, itemId, entity); e.preventDefault()}}>
                                            <img src={process.env.PUBLIC_URL + '/images/close-cross.svg'} alt="Icon close"/>
                                        </div>
                                    }
                                </ImageWrapper>
                            </div>
                        ))
                    }
                    {images && images.filter(m => m.type === config.FILE_VIDEO).map((m, index) => (
                        <div className={`image-item ${enableLightbox ? "is-lightbox" : ""}`} key={m.id} onClick={() => this.setState({ storyGalleryVideo: true, storySlide: index })}>
                            <ImageWrapper progress={m.progress} path={m.previewPath}>
                                <ProgressiveImager alt={`Vorschaubild - Video ${m.id}`} src={config.getImageUrl('main', m.previewPath)}/>
                                {deletable &&
                                    <div className="image-delete" onClick={(e) => {requestDelete(m.id, itemId, entity); e.preventDefault()}}>
                                        <img src={process.env.PUBLIC_URL + '/images/close-cross.svg'} alt="Icon close"/>
                                    </div>
                                }
                            </ImageWrapper>
                            <Icon name="play circle outline" inverted/>
                        </div>
                    ))}
                </div>
                {/* Gallery lightbox */}
                {enableLightbox &&
                    <>
                        <Gallery
                            images={imagesLightbox}
                            galleryOpen={this.state.storyGallery}
                            onClose={() => this.setState({storyGallery: false, storySlide: 0})}
                            slide={this.state.storySlide}
                            onMovePrevRequest={() =>
                                this.setState({
                                    storySlide: (this.state.storySlide + imagesLightbox.length - 1) % imagesLightbox.length,
                                })
                            }
                            onMoveNextRequest={() =>
                                this.setState({
                                    storySlide: (this.state.storySlide + 1) % imagesLightbox.length,
                                })
                            }
                        />
                        <Gallery
                            isVideo={true}
                            images={videosLightbox}
                            galleryOpen={this.state.storyGalleryVideo}
                            onClose={() => this.setState({storyGalleryVideo: false, storySlide: 0})}
                            slide={this.state.storySlide}
                            onMovePrevRequest={() =>
                                this.setState({
                                    storySlide: (this.state.storySlide + videosLightbox.length - 1) % videosLightbox.length,
                                })
                            }
                            onMoveNextRequest={() =>
                                this.setState({
                                    storySlide: (this.state.storySlide + 1) % videosLightbox.length,
                                })
                            }
                        />
                    </>
                }
            </div>
        )
    }
}

export default PreviewGallery;
