import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Container, Loader, Responsive } from 'semantic-ui-react';
import config from '../config/config';
import FeedCard from '../components/Card/FeedCard';
import { footerActiveItem, handleHeaderItems, setRuntime, handleContainer } from '../actions/global';
import { fetchUserFeedPosts } from '../actions/user';
import Gallery from '../components/Gallery/Gallery';

class UserLiveposts extends Component {

    state = {
        livePostsLightbox: false,
        postId: false
    }

    componentDidMount = () => {
        const { dispatch, match, user } = this.props;
        dispatch(footerActiveItem(null))
        dispatch(handleHeaderItems("user"));
        dispatch(setRuntime({page: 'fe-user-id'+match.params.id}));
        
        dispatch(fetchUserFeedPosts(match.params.id));

        window.scrollTo(0, 0);

        if (user && user.name) {
            dispatch(handleContainer("secondary", "Alle Live-Posts von "+user.name));
        }
        else {
            dispatch(handleContainer("secondary", "Live-Posts"));
        }
    }

    handleGallery = (id, index) => {
        this.setState({
            postId: id,
            livePostsLightbox: index
        })
    }

    render() {

        const { liveposts, livepostsIsFetching } = this.props;
        const { postId, livePostsLightbox } = this.state;

        return (
            <Container text>
                
                {livepostsIsFetching && 
                    <Grid>
                        <Grid.Column width={16} textAlign="center">
                            <Loader active/>
                        </Grid.Column>
                    </Grid>
                }

                {!livepostsIsFetching && liveposts && liveposts.map((post, index) => (
                    // <FeedCard post={item}/>
                    <>
                    <FeedCard type="primary" item={post.feedPostData} handleLightboxGallery={this.handleGallery}/>
                    {post.feedPostData.medias.length > 0 && 
                        <>
                            <Responsive {...Responsive.onlyMobile}>
                                <Gallery
                                    galleryOpen={this.state.postId === post.feedPostData.id}
                                    slide={livePostsLightbox}
                                    onClose={() => this.setState({ postId: false })}
                                    images={
                                        post.feedPostData.medias.map(img =>
                                            config.getImageUrl('zoom', img.path)
                                    )}
                                                onMovePrevRequest={() =>
                                                    this.setState({
                                                        livePostsLightbox: (livePostsLightbox + post.feedPostData.medias.length - 1) % post.medias.length,
                                                    })
                                                }
                                                onMoveNextRequest={() =>
                                                    this.setState({
                                                        livePostsLightbox: (livePostsLightbox + 1) % post.feedPostData.medias.length,
                                                    })
                                                }
                                            />
                                    </Responsive>

                                    <Responsive minWidth={Responsive.onlyTablet.minWidth}>

                                            <Gallery
                                                galleryOpen={this.state.postId === post.feedPostData.id}
                                                slide={livePostsLightbox}
                                                onClose={() => this.setState({ postId: false })}
                                                images={post.feedPostData.medias.map(img =>
                                                    config.getImageUrl('zoomDesktop', img.path)
                                                )}
                                                onMovePrevRequest={() =>
                                                    this.setState({
                                                        livePostsLightbox: (livePostsLightbox + post.feedPostData.medias.length - 1) % post.medias.length,
                                                    })
                                                }
                                                onMoveNextRequest={() =>
                                                    this.setState({
                                                        livePostsLightbox: (livePostsLightbox + 1) % post.feedPostData.medias.length,
                                                    })
                                                }
                                            />

                                    </Responsive>
                                    </>
                            }
                            </>
                ))}

            </Container>
        )
    }
}

function mapStateToProps(state, ownProps){
    const { feedpostByUserId } = state

    const { list: liveposts, isFetching: livepostsIsFetching } = feedpostByUserId[ownProps.match.params.id] || {
        liveposts: {},
        livepostsIsFetching: true
    }


    return {
        liveposts,
        livepostsIsFetching
    }
}

export default connect(mapStateToProps)(UserLiveposts);
