import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import './CustomPopup.scss';
import { Icon, Header, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { RemoveScrollBar, zeroRightClassName } from 'react-remove-scroll-bar';
import { motion, AnimatePresence } from 'framer-motion'

class CustomPopup extends Component {

    constructor(props){
        super(props);
        this.setHeight = this.setHeight.bind(this);
    }

    state = {
        orientation: '',
        popupIsOpen: this.props.popupOpen,
        height: 500,
    }

    componentDidMount = () => {
        const { popupOpen } = this.props;
        window.addEventListener('resize', this.setHeight);
        this.setHeight();

        //Set viewport value to override schrinking content on Android devices if keyboard shown
        let viewport = document.querySelector("meta[name=viewport]");
        viewport.setAttribute("content", viewport.content + ", height=" + window.innerHeight)
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { dispatch, popupOpen } = this.props;
        const { popupIsOpen } = this.state;

        if (prevProps.popupOpen !== popupOpen) {
            this.setState({
                popupIsOpen: popupOpen
            });
        }
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.setHeight);
    }

    setHeight() {
        this.setState({
            height: (window.innerHeight - 200),
        });
    }

    handlePopup = (isOpen) => {
        this.setState({popupIsOpen: false})
    }

    buttons() {
        return (
            <>
                {this.props.saveButton && <Button loading={this.props.isLoading} onClick={this.props.isLoading ? null : this.props.saveButtonCallback} color="green" style={{position: 'absolute', right: '3rem', top: '0.75rem'}}>{this.props.saveButtonLabel ? this.props.saveButtonLabel : 'Speichern'}</Button>}
                <Icon onClick={() => this.props.onClose()} className="close-icon-popup" style={{position: "absolute", right: "14px", top: "14px", zIndex: 10}} name="times"/>
            </>
        );
    }

    render() {
        return (
            <AnimatePresence>
                <Popup
                    position="center center"
                    closeOnDocumentClick={this.props.saveButton ? false : this.props.onDocumentClick}
                    closeOnEscape={false}
                    open={this.state.popupIsOpen}
                    onClose={this.props.onClose}
                    className={zeroRightClassName + " pop " + this.props.className}
                    modal
                    overlayStyle={
                        {marginTop: 0}
                    }
                    ref={this.props.passref}
                >
                    <div>

                        {this.props.popupOpen && <RemoveScrollBar/>}

                        {this.props.popupHeader &&
                            <div className={this.props.headerBackgroundWhite ? "popup-header popup-header-white" : "popup-header"}>
                                <Header as="h2">{this.props.popupHeader}</Header>
                                {this.buttons()}
                            </div>
                        }
                        <div className="popup-content-inside" style={this.props.className && this.props.className.indexOf('login-popup-content') !== -1 ? null : {maxHeight: this.state.height + 'px', overflowX: 'hidden', overflowY: "auto"}}>
                            {this.props.showCloseIcon &&
                                <>{this.buttons()}</>
                            }
                            {this.props.children}
                        </div>
                    </div>
                </Popup>
            </AnimatePresence>
        )
    }
}

export default connect()(CustomPopup);
