import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Grid, Header, Container, List, Divider, Image } from "semantic-ui-react";
import {
    footerActiveItem,
    handleContainer,
    handleHeaderItems,
    setRuntime
} from "../../actions/global";
import Helmet from 'react-helmet';
import './functions.scss';
import HeaderImage from "../../components/HeaderImage/HeaderImage";
import HeaderImg from '../../images/funktionen-header.jpg'
import iOSImage from '../../images/pwa-guide/ios/ios_1.jpg'
import iOSImage_2 from '../../images/pwa-guide/ios/ios_2.jpg'

class VeteroApp extends Component {

  componentDidMount = () => {
    const { dispatch } = this.props;

    dispatch(footerActiveItem('footermenu'))
    dispatch(
      handleContainer("functions", "Vetero als App", null, null, null, null, {
        description: "Erfahre hier mit welchen Funktionen Vetero nicht nur Oldtimer Schrauber unterstützt. Von der Präsentation deiner Oldtimer, den Austausch mit anderen Oldtimer Begeisterten bis zur Auswertung der Arbeitszeit und der Kosten."
      })
    );
    dispatch(handleHeaderItems("functions"));
    // Set page
    dispatch(setRuntime({
        page: 'functions'
    }));

  };

  componentWillUnmount = () => {
    const { dispatch } = this.props;

    dispatch(setRuntime({
        backFunctions: {page: 'functions', path: this.props.location, scroll: window.pageYOffset},
    }));
}

  render() {
    const { item } = this.props;

    return (
      <div className="functions-page">

        <Helmet>
          <title>Vetero - Vetero als App installieren</title>
        </Helmet>

        <Container className="pwa-guide">

          <Header as="h1">Vetero als App installieren</Header>

          <Divider/>
          <div className="pwa-guide-text">
            <p>
              Egal ob PC oder MAc, Android oder iOS, Smartphone oder Tablet. Du kannst die Vetero-APP - genauer Progressive-Web-App (PWA) - mit wenigen
              Klicks auf allen Geräten installieren. Die App läuft immer schneller, Du siehst mehr, musst für die Installation in keinen App-Store und Updates sind automatisch verfügbar.
            </p>

            <Header dividing as="h2">iPhone</Header>
            <p>Um Vetero auf einem iPhone als App zu Installieren, gehe wie folgt vor:</p>
            <List as='ol'> 
              <List.Item as='li'> 
                Starte Safari und rufe die Website <a href="https://www.vetero.de">https://www.vetero.de</a> auf.
              </List.Item>
              <List.Item as='li'> 
                Wähle unten das Teile Symbol
              </List.Item>
              <List.Item as='li'>
                Wähle danach den Punkt "Zum Home-Bildschirm"
              </List.Item>
            </List>

            <Divider/>
            <Grid textAlign="center">
              <Grid.Column mobile={16} tablet={8} computer={8}>
                <Image src={iOSImage} style={{margin: "0 auto"}} loading="lazy" alt="iOS PWA Anleitung - Screenshot 1" />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={8} computer={8} style={{margin: "0 auto"}}>
                <Image src={iOSImage_2} style={{margin: "0 auto"}} loading="lazy" alt="iOS PWA Anleitung - Screenshot 2" />
              </Grid.Column>
            </Grid>
            <Divider/>

          </div>

          <Header as="h2">MAC / PC</Header>
          <p>
            Auch wenn Du normalerweise Safari, Firefox oder einen anderen Browser benutzt, solltest Du für PWAs den Browser Google Chrome installieren
            (<a href="https://www.google.com/intl/de_de/chrome/">https://www.google.com/intl/de_de/chrome/</a>), da dieser die beste Unterstütztung 
            bietet. Du kannst Deinen bisherigen Browser weiter als Standard-Browser verwenden. 
          </p>
          <p>
            Eine Anleitung, wie Du Vetero auf einem Mac oder PC installierst, findest Du hier: 
          </p>

          <Header as="h2">Android</Header>
          <p>Eine Anleitung, wie Du Vetero auf Android-Geräten installierst, findest Du hier: <a href="https://support.google.com/chrome/answer/9658361/progressive-web-apps-verwenden-computer?hl=de&co=GENIE.Platform%3DAndroid&oco=1">https://support.google.com/chrome/answer/9658361/progressive-web-apps-verwenden-computer?hl=de&co=GENIE.Platform%3DAndroid&oco=1</a> </p>
          <Divider/>

        </Container>

      </div>
    );
  }
}


export default connect()(withRouter(VeteroApp));
