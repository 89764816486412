import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { Container, Grid, Header, Divider, Responsive } from 'semantic-ui-react';
import { fetchBrandAll, footerActiveItem, handleContainer } from '../../../actions/global';
import  '../../Pages.scss'


class BrandIndex extends Component {

    state = {
        currentLetter: "",
        lastLetter: ""
    }

    componentDidMount(){
        const { dispatch } = this.props;
        dispatch(fetchBrandAll());
        dispatch(footerActiveItem(''));
        dispatch(
      handleContainer("newsfeed", "SPAN#Marken", null, null, null, null, {
        description: "Hier findest du alle Marken die in Vetero Oldtimer Portal angelegt wurden."
      })
    );
    }

    render() {

        const { brand } = this.props;
        let currentLetter;
        let lastLetter;
        let letterPerRow = [];
        let categoryItemsAmount = [];

        let items = brand && brand.map((item, index) => {

            currentLetter = item.label.charAt(0).toUpperCase();
            lastLetter = brand[index-1] && brand[index-1].label.charAt(0).toUpperCase();
            currentLetter !== lastLetter && letterPerRow.push(currentLetter);
            currentLetter !== lastLetter && categoryItemsAmount.push(index+1);
      
            return (

                <div className="brand-category">
                    {currentLetter !== lastLetter && <div className="sorted-by">{currentLetter}</div>}            
                        <div className="brand-sorted">      
                            <Link className="brand" to={`/brand/${item.slug}`}>{item.label}</Link>
                        </div>
                </div>

                )  
            }).reduce((arr, el, index) => {

                categoryItemsAmount.map((num, i) => (
                    (index+1) === num && (i % 4 === 0) && arr.push([])
                ))

                arr[arr.length-1].push(el);
                return arr;
            }, []).map((columns, index) => {
                    return (
                        <Grid.Column>
                            {columns}
                        </Grid.Column>
                    )
            })

        return (
            <Container className="all-brands">

                <Header as="h1">Marken</Header>

                <Divider/>
                <Grid className="brand-list" columns="equal" stackable>
                    {items}
                </Grid>
            </Container>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { brandBySlug } = state
    
    let brand = brandBySlug && Object.values(brandBySlug)

    return {
        brand
    }

}

export default connect(mapStateToProps)(BrandIndex);
