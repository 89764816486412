import React, { Component, useState } from 'react';
import { MapContainer, TileLayer, Marker, Circle, LayerGroup, useMapEvents, useMap } from 'react-leaflet';
import {Icon} from 'leaflet'
import markerIconPng from "leaflet/dist/images/marker-icon.png"
import MarkerClusterGroup from 'react-leaflet-markercluster';
import './leaflet.css';
import './markercluster.min.css';

class LeafletMap extends Component {

    componentDidUpdate = (prevProps) => {
      const { newLocation } = this.props;

    }

    handleZoomStart = (distance) => {   
        const { fetchData } = this.props; 
        
        fetchData(distance)
        // dispatch(resetProfessionalList())
    }

    handleMarkerEvent = (id) => {
      const { handleMarkerOnClick } = this.props;
      handleMarkerOnClick(id)
    }

    render() {

        const { enableZoomCallback, userPosition, markerGroup, handleMarkerOnClick, zoomLevel, newLocation, newZoom } = this.props;
        
        return (
          <>

            {userPosition &&
              <MapContainer 
                    center={userPosition} 
                    zoom={zoomLevel} 
                    scrollWheelZoom={false}
                  >
                    <TileLayer
                      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                      {markerGroup && markerGroup.length > 0 &&
                        <MarkerClusterGroup>
                          {markerGroup.map((item,index) => (
                            item.item.latitude && item.item.longitude &&
                       
                            <Marker
                              key={index}
                              position={[item.item.latitude, item.item.longitude]}
                              icon={new Icon({iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41]})} 
                              eventHandlers={{
                                click: (e) => this.handleMarkerEvent(item.item.id),
                                
                              }}
                            />
                     
                            ))}
                        </MarkerClusterGroup>
                      }

                    {enableZoomCallback &&
                      <HandleZoom handleZoom={this.handleZoomStart}/>
                    }
                    
                    {newLocation && newLocation.length > 0 && newLocation[0] && newLocation[1] &&
                    <HandleLocation pos={newLocation} zoom={newZoom} />
                    }
                    
                    <>
                    {userPosition &&
                      <LayerGroup>
                        <Circle radius={300} pathOptions={{ fillColor: 'red' }} center={userPosition} />
                        <Circle
                          center={userPosition}
                          pathOptions={{fillColor: "red"}}
                          radius={200}
                          stroke={false}
                        />
                      </LayerGroup>
                    }
                      </>
              </MapContainer>
            }
          </>
        )
    }
}

function CenterMap(){
  const map = useMap();
  map.getCenter();
}

function HandleLocation({ pos, zoom }) {
  const map = useMap();

  if(pos && pos.length > 0) {
    map.flyTo(pos, zoom)
  }
  return null
}

function HandleZoom({handleZoom}) {
    const [zoomLevel, setZoomLevel] = useState(5); // initial zoom level provided for MapContainer
    const [zoomDistance, setZoomDistance] = useState(0);
    const [centerPos, setCenterPos] = useState(0);
  
    const mapEvents = useMapEvents({
        zoomend: () => {
            setZoomLevel(mapEvents.getZoom());
  
            let mapBoundNorthEast = mapEvents.getBounds().getNorthEast();
            let mapDistance = mapBoundNorthEast.distanceTo(mapEvents.getCenter());
            setZoomDistance(mapDistance/1000)
            handleZoom(Math.floor(mapDistance))
  
        },
        moveend: () => {
          setCenterPos(mapEvents.getCenter())
          let mapBoundNorthEast = mapEvents.getBounds().getNorthEast();
          let mapDistance = mapBoundNorthEast.distanceTo(mapEvents.getCenter());      }
    });
  
    return null
  }

export default LeafletMap
