import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { handleContainer } from '../actions/global';
import { Container, Icon, Header, Grid, Divider, Message, Loader, Button } from 'semantic-ui-react';
import { resetMessages, putEmailChange } from '../actions/account';
import './Pages.scss';

class EmailChange extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount = () => {
        const { dispatch, history, match } = this.props;

        dispatch(handleContainer("newsfeed", ""));
        dispatch(resetMessages());
        dispatch(putEmailChange(match.params.user, match.params.code));
    }

    render() {
        const { isChangingEmail, changingEmailMessage } = this.props;

        return (

            <Container className="account-activation">
                <Divider hidden/>

                {changingEmailMessage === '' &&
                    <Grid>
                        <Grid.Column width={16} textAlign="center">

                            <Loader active inline='centered' size="massive" />
                            <Header as="h2">Verarbeitung läuft</Header>
                            <p>Bitte warte einen kleinen Moment.</p>

                        </Grid.Column>
                    </Grid>
                }
                {changingEmailMessage === 'SUCCESS' &&
                    <Grid>
                        <Grid.Column width={16} textAlign="center">

                            <Icon name="check circle" size="massive" color="green"/>
                            <Header as="h2">Die E-Mailadresse wurde erfolgreich geändert</Header>
                            <p>&nbsp;</p>
                            <Divider hidden/>
                            <Button as="a" href="/">Zur Startseite</Button>

                        </Grid.Column>
                    </Grid>
                }
                {changingEmailMessage === 'FAILURE' &&
                    <Grid>
                        <Grid.Column width={16} textAlign="center">

                            <Icon name="warning circle" size="massive" color="red"/>
                            <Header as="h2">Ein Fehler ist aufgetreten</Header>
                            <p>Bitte versuch es später noch einmal.</p>
                            <Divider hidden/>
                            <Button as="a" href="/">Zur Startseite</Button>

                        </Grid.Column>
                    </Grid>
                }
                {changingEmailMessage === 'alreadychanged' &&
                    <Grid>
                       <Grid.Column width={16} textAlign="center">

                           <Icon name="smile" size="massive" color="green"/>
                           <Header as="h2">Die E-Mailadresse wurde bereits geändert</Header>
                           <p>&nbsp;</p>
                           <Divider hidden/>
                           <Button as="a" href="/">Zur Startseite</Button>

                       </Grid.Column>
                   </Grid>
                }
                {changingEmailMessage === 'wrongcode' &&
                    <Grid>
                        <Grid.Column width={16} textAlign="center">

                            <Icon name="warning circle" size="massive" color="red"/>
                            <Header as="h2">Der Änderungscode stimmt nicht</Header>
                            <p>Bitte prüfe, ob der Link aus unserer E-Mail korrekt und vollständig aufgerufen wurde.</p>
                            <Divider hidden/>
                            <Button as="a" href="/">Zur Startseite</Button>

                        </Grid.Column>
                    </Grid>
                }

            </Container>

        )
    }
}

function mapStateToProps(state, ownProps) {
    const { account } = state;

    const { isChangingEmail, changingEmailMessage } = account || {
        isChangingEmail: false,
        changingEmailMessage: '',
    }

    return {
        isChangingEmail,
        changingEmailMessage,
    }
}

export default connect(mapStateToProps)(withRouter(EmailChange));
