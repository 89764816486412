import React, { Component } from 'react';
import ProjectCard from '../../../../components/Card/ProjectCard';
import ScrollViewport from 'react-scroll-viewport';
import '../Frontend.scss';

class ProjectListCardView extends Component {

    state = {
        cardElement: 410
    }

    componentDidMount = () => {
        window.addEventListener('resize', this.updateDimensions);
        window.addEventListener('scroll', this.updateDimensions);
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.updateDimensions);
        window.removeEventListener('scroll', this.updateDimensions);
    }

    updateDimensions = () => {
        const height = document.getElementsByClassName('project-item')[0].clientHeight ;
        if(height){
            this.setState({
                cardElement: height + 32 //Add margin-bottom
            })
        }
    }

    render(){

        const { list } = this.props;
        const { cardElement } = this.state;

        let items;
        items = list.map(item => {
            return (
                <ProjectCard sumMedias={item.sumMedias} item={item.item} key={item.item.id} />
            )
        })

        return (
            <ScrollViewport sync={true} overscan={10} rowHeight={cardElement}>
                {items}
            </ScrollViewport>
        )
    }
}

export default ProjectListCardView;
