import React, { Component } from 'react';
import { Button, Input, Loader } from 'semantic-ui-react';
import './Autosuggestion.scss';

class Autosuggestion extends Component {

    state = {
        showMenu: false
    }

    componentDidMount = () => {
        document.addEventListener('click', this.handleClick, false);
    }

    componentDidUpdate = (prevProps) => {
        const { options, value } = this.props;
        console.log(options);
        if(options && (options !== prevProps.options && options.length > 0)) {
            this.setState({ showMenu: true });
        }
        
        if(value !== prevProps.value && !value){
            this.setState({ showMenu: false })
        }

    }

    componentWillUnmount = () => {
        document.removeEventListener('click', this.handleClick, false);
    }

    handleClick = (e) => {
        const { options } = this.props;

        document.addEventListener('click', this.handleClick, false);
        if(this.node.contains(e.target)){
            return;
        }

        this.setState({ showMenu: false })
    }

    handleInputOnClick = () => {
        const { options, value } = this.props;
        const { showMenu } = this.state;

        if(value && options && options.length > 0 && !showMenu) {
            this.setState({ showMenu: true })
        }
    }

    render() {

        const { value, placeholder, onChange, label, options, onClickOption, action, isFetching } = this.props;
        const { showMenu } = this.state;

        return (
            <>
            {label && <label>{label}</label>}
            <div className="autosuggestion-input" ref={node => this.node = node}>
                <Input 
                    value={value}
                    placeholder={placeholder}
                    onChange={onChange}
                    onClick={this.handleInputOnClick}
                    action
                >
                    <input/>
                    {action && action.onClick && 
                        <Button onClick={action.onClick}>
                            {action.content}
                        </Button>
                    }
                </Input>
                
                {isFetching && 
                    <div className="autosuggestion-menu-container">
                        <ul className="autosuggestion-menu">
                            <li><Loader active/></li>
                        </ul>
                    </div>
                }    
                
                {!isFetching && showMenu && options && options.length > 0 && 
                    <div className="autosuggestion-menu-container">
                        <ul className="autosuggestion-menu">
                            {options.map((item, index) => (
                                <li key={index} onClick={() => { onClickOption(item); this.setState({ showMenu: false }) }} className="autosuggestion-option">{item.display_name}</li>
                            ))}
                        </ul>
                    </div>
                }

            </div>
            </>
        )
    }
}

export default Autosuggestion
