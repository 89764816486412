import React, { Component } from "react";
import {
  Container,
  Form,
  Grid,
  Image,
  Divider,
  Segment,
  Label,
  Button,
  Message,
  Responsive,
  Placeholder,
  Icon,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Gallery from "../../components/Gallery/Gallery";
import { Link } from "react-router-dom";
import Account from "../../components/account/Account";
import {
  footerActiveItem,
  handleContainer,
  handleHeaderItems,
  setRuntime,
} from "../../actions/global";
import CustomSlidingPane from "../../components/CustomSlidingPane/CustomSlidingPane";
import Comments from "../../components/Comments/Comments.js";
import UserBreadcrumb from "../../components/UserBreadcrumb/UserBreadcrumb";
import { InfiniteScroll } from "../../components/helpers/InfiniteScroll";
import fetchNotificationListIfNeeded, {
  fetchNotificationList,
  resetNotificationList,
  checkForUpdatesIfNeeded,
} from "../../actions/notification";
import Moment from "react-moment";
import "moment/locale/de";
import "moment-timezone";
import ImageWrapper from "../../components/helpers/image-wrapper";
import Truncate from "react-truncate";
import config from "../../config/config";
import NewsfeedInput from "./newsfeedInput";
import Helmet from "react-helmet";
import ProgressiveImager from "../../components/helpers/progressive-imager";
import NumericLabel from "react-pretty-numbers";
import { likeStory } from "../../actions/story";
import { likeProject } from "../../actions/project"
import { likeFeed } from '../../actions/notification';
import { LivePostMeta } from "./../livePost"
import ErrorHandler from "../../components/ErrorHandler/ErrorHandler";

/* ICONS */
import heartIcon from "../../images/icons/frontend/icon-heart.svg";
import commentIcon from "../../images/icons/frontend/icon-comment.svg";
import heartIconActive from "../../images/icons/frontend/icon-active-heart.svg";


class Newsfeed extends Component {
  state = {
    galleryOpen: false,
    currentSlide: 1,
    comments: {
      isOpen: false,
      id: null,
    },
    loginPopup: false,
  };

  constructor(props) {
    super(props);

    this.loadMore = this.loadMore.bind(this);
    this.loadMoreForce = this.loadMoreForce.bind(this);
  }

  componentDidMount() {
    const {
      dispatch,
      match,
      history,
      runtimedata,
      location,
      runtimepage,
      backFeedPost,
      backProject,
      backStory,
      backUser,
    } = this.props;
    dispatch(footerActiveItem("news"));
    dispatch(
      handleContainer("newsfeed", "Newsfeed", null, null, null, null, {
        description:
          "Hier findest Du Updates und News zu Oldtimer-Projekten und Oldtimer-Fahrzeugen. Hast Du auch einen Oldtimer? Dann ist Vetero genau das Richtige für Dich.",
        desctype: "fixed",
      })
    );
    dispatch(handleHeaderItems("newsfeed"));
    dispatch(checkForUpdatesIfNeeded());
    dispatch(fetchNotificationListIfNeeded());
  }

  componentDidUpdate(prevProps, prevState) {
    const { dispatch, hasUpdateLastFetch } = this.props;

    if (
      hasUpdateLastFetch &&
      Date.now() - hasUpdateLastFetch > config.PING_TIMEOUT
    ) {
      dispatch(checkForUpdatesIfNeeded());
    }
  }

  componentWillUnmount = () => {
    const { dispatch, location, match } = this.props;

    dispatch(
      setRuntime({
        backFeedPost: {
          page: "fe-newsfeed",
          path: this.props.location,
          scroll: window.pageYOffset,
        },
        backProject: {
          page: "fe-newsfeed",
          path: this.props.location,
          scroll: window.pageYOffset,
        },
        backStory: {
          page: "fe-newsfeed",
          path: this.props.location,
          scroll: window.pageYOffset,
        },
        backUser: {
          page: "fe-newsfeed",
          path: this.props.location,
          scroll: window.pageYOffset,
        },
        backForumThread: {
          page: "fe-newsfeed",
          path: this.props.location,
          scroll: window.pageYOffset,
        },
      })
    );
  };

  reloadList = () => {
    const { dispatch } = this.props;
    dispatch(resetNotificationList());
    dispatch(fetchNotificationList());
  };

  loadMore() {
    const { dispatch, page, isFailed } = this.props;

    if (!isFailed) {
      dispatch(fetchNotificationList([], false, page ? page + 1 : 1));
    }
  }

  loadMoreForce() {
    const { dispatch, page } = this.props;

    dispatch(fetchNotificationList([], false, page ? page + 1 : 1));
  }

  handleSlidingPanes = (isOpen, title, content, parent) => {
    this.setState({
      slidingPane: {
        isOpen: true,
        title,
        content,
        parent,
      },
    });
  };

  handleGallery = (current, id) => {
    this.setState({
      currentSlide: current,
      galleryOpen: id,
    });
  };

  handleDesktopComments = (isOpen, parent) => {
    this.setState({
      desktopComments: { showCommentsDesktop: isOpen, parent: parent },
    });
  };

  handleSlidingPanesContent = () => {
    if (this.state.slidingPane.content === "comments") {
      return (
        <Comments type="feed" parent={this.state.slidingPane.parent} />
      );
    }
  };

  handleLikeStory = (id, value, count) => {
    const { dispatch, isStoryLiking } = this.props;

    if (!isStoryLiking) {
      dispatch(likeStory(id, value, count));
    }
  };

  handleLikeProject = (id, value, count) => {
    const { dispatch, isProjectLiking } = this.props;

    if (!isProjectLiking) {
      dispatch(likeProject(id, value, count));
    }
  };

  handleLikeNewsfeed = (id, value, count) => {
    const { dispatch, isNewsfeedLiking } = this.props;

    if (!isNewsfeedLiking) {
      dispatch(likeFeed(id, value, count));
    }
  };

  render() {
    const { item, headerTitle } = this.props;
    const { currentSlide, galleryOpen } = this.state;

    return (
      <>
        {headerTitle && (
          <Helmet htmlAttributes={{ lang: "de" }}>
            <title>Newsfeed{config.TITLE_SUFFIX}</title>
          </Helmet>
        )}
        <Container text>
          {item && item.id && <NewsfeedInput />}
          {this.newsfeed()}
        </Container>

        {/* include SlidingPane, Popups... */}

        <Responsive
          as={CustomSlidingPane}
          maxWidth={Responsive.onlyTablet.maxWidth}
          isOpen={this.state.comments.isOpen}
          title="Kommentare"
          onRequestClose={() => this.setState({ comments: { isOpen: false } })}
        >
          <Comments parent={this.state.comments.id} />
        </Responsive>

        <Responsive
          as={CustomSlidingPane}
          minWidth={Responsive.onlyComputer.minWidth}
          isOpen={this.state.comments.isOpen}
          title="Kommentare"
          from="right"
          onRequestClose={() => this.setState({ comments: { isOpen: false } })}
        >
          <Comments parent={this.state.comments.id} />
        </Responsive>
      </>
    );
  }

  placeholder() {
    return (
      <div className="feed">
        <Divider hidden />
        <Segment className="newsfeed-content newsfeed-content-placeholder">
          <div>
            <div className="newsfeed-inside">
              <div className="newsfeed-image">
                <Placeholder>
                  <Placeholder.Image />
                </Placeholder>
              </div>
            </div>

            <div className="newsfeed-description" style={{ marginTop: "1rem" }}>
              <Placeholder>
                <Placeholder.Paragraph>
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                </Placeholder.Paragraph>
                <Placeholder.Paragraph>
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                </Placeholder.Paragraph>
              </Placeholder>
            </div>
            <Divider hidden />

            <Grid className="social-buttons">
              <Grid.Column width={8} textAlign="left">
                <div>
                  <span className="icons-newsfeed">
                    <Image src={heartIcon} alt="Icon Like" className="icon-newsfeed-social" />
                    <span className="social-amount fontsize-small-m font-semi">
                      -
                    </span>
                  </span>
                  <span className="icons-newsfeed">
                    <Image src={commentIcon} alt="Icon Kommentieren" className="icon-newsfeed-social" />
                    <span className="social-amount fontsize-small-m font-semi">
                      -
                    </span>
                  </span>
                </div>
              </Grid.Column>

              <Grid.Column width={8} className="comment-btn placeholder">
                <div className="fontsize-small-m font-semi">Kommentieren</div>
              </Grid.Column>
            </Grid>
          </div>
        </Segment>

        <Segment className="newsfeed-content">
          <div>
            <div className="newsfeed-inside">
              <div className="breadcrumb-placeholder">
                <div className="user-image-placeholder">
                  <Placeholder>
                    <Placeholder.Image />
                  </Placeholder>
                </div>
                <div className="breadcrumb-placeholder-content">
                  <Placeholder>
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder>
                </div>
              </div>

              <div className="newsfeed-description">
                <Placeholder>
                  <Placeholder.Paragraph>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Paragraph>
                  <Placeholder.Paragraph>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Paragraph>
                </Placeholder>
              </div>
              <Divider hidden />
              <div className="newsfeed-image">
                <Placeholder>
                  <Placeholder.Image />
                </Placeholder>
              </div>

              <Grid className="social-buttons">
                <Grid.Column width={8} textAlign="left">
                  <div>
                    <span className="icons-newsfeed">
                      <Image src={heartIcon} alt="Icon Like" className="icon-newsfeed-social" />
                      <span className="social-amount fontsize-small-m font-semi">
                        -
                      </span>
                    </span>
                    <span className="icons-newsfeed">
                      <Image
                        src={commentIcon}
                        className="icon-newsfeed-social"
                        alt="Icon Kommentieren"
                      />
                      <span className="social-amount fontsize-small-m font-semi">
                        -
                      </span>
                    </span>
                  </div>
                </Grid.Column>

                <Grid.Column width={8} className="comment-btn placeholder">
                  <div className="fontsize-small-m font-semi">Kommentieren</div>
                </Grid.Column>
              </Grid>
            </div>
          </div>
        </Segment>
      </div>
    );
  }

  newsfeed() {
    const {
      listitems,
      isFetching,
      isFailed,
      hasMore,
      page,
      item: accountItem,
    } = this.props;
    if (!listitems.length && isFetching) {
      return <>{this.placeholder()}</>;
    } else if (listitems.length) {
      const {
        userInput,
        userInputBrand,
        userInputCategory,
        userInputModel,
        userInputYearFrom,
        userInputYearTo,
        userInputCountry,
        listitems,
        hasMore,
        isFetching,
        lastFetch,
        hasUpdate,
      } = this.props;
      const { currentSlide, galleryOpen } = this.state;

      return (
        <>
          <Divider hidden />

          {hasUpdate && (
            <Grid>
              <Grid.Column width={16} textAlign="center">
                <Button
                  onClick={this.reloadList}
                  color="red"
                  basic
                >
                  Neue Meldungen laden
                </Button>
                <Divider hidden />
              </Grid.Column>
            </Grid>
          )}

          {listitems.map((item, index) => (
            <div className="feed" key={index}>
              <Segment className="newsfeed-content" key={index}>

                {/* Forum Thread */}
                {item.event === config.NTFCN_FORUM_THREAD_NEW && (
                  <div className="newsfeed-inside">
                    <UserBreadcrumb
                        meta={true}
                        user={item.userAuthorData}
                        userId={item.userAuthor}
                        project={{
                          id: item.forumThreadData.project,
                          title: item.forumThreadData.projecttitle,
                          urlslug: item.forumThreadData.projecturlslug,
                          active: item.forumThreadData.projectactive,
                      }}
                        projectPart={{
                          id: item.forumThreadData.projectpart,
                          title: item.forumThreadData.projectparttitle,
                          urlslug: item.forumThreadData.projectparturlslug,
                          active: item.forumThreadData.projectpartactive,
                      }}
                        
                    />

                      <div className="meta-info">
                          <Moment unix format="DD.MM.YYYY">
                            {item.date}
                          </Moment>
                          <Label color="black" className="newsfeed-label">
                            Neue Forumsfrage
                           </Label>
                      </div>

                      <Link to={`/forum/${item.forumThreadData.urlslug}/${item.forumThread}`}>
                        <div className="content-title">
                          {item.forumThreadData.title}
                        </div>
                        <p dangerouslySetInnerHTML={{__html: item.forumThreadData.teaser}}/>
                    
                      </Link>

                  </div>
                )}

                {/* Newsfeed post */}

                {item.event === config.NTFCN_FEED_POST && (
                  <div>
                    <div className="newsfeed-inside">
                      <UserBreadcrumb
                        meta={true}
                        user={item.userAuthorData}
                        userId={item.userAuthor}
                        project={item.feedPostData.projectData}
                        projectId={item.feedPostData.project}
                      />
                        <LivePostMeta feedpost={item.feedPostData} />

                      <Link to={`/feed/post/${item.feedPost}`}>
                        <div className="newsfeed-description">
                          <Truncate lines={20} ellipsis={<>...</>}>
                            {item.feedPostData.text ? (
                              <>{item.feedPostData.text}</>
                            ) : (
                              <>{item.description}</>
                            )}
                          </Truncate>
                        </div>
                      </Link>
                      {accountItem && item.feedPostData.user === accountItem.id &&
                          <Link className="edit-feedpost" to={`/feed/post/${item.feedPost}#edit`}><Icon name="pencil" circular inverted color="grey" /> </Link>
                      }

                      {item.feedPostData.medias &&
                        item.feedPostData.medias.length > 0 && (
                          <>
                            {item.feedPostData.medias.length === 1 && (
                              <div className="newsfeed-image">
                                <div
                                  className="feed-image"
                                  onClick={() => this.handleGallery(0, item.id)}
                                >
                                  <ProgressiveImager
                                    className="responsive"
                                    src={config.getImageUrl(
                                      "main",
                                      item.feedPostData.medias[0].path
                                    )}
                                    alt={
                                      (item.feedPostData.text
                                        ? item.feedPostData.text.substring(
                                            0,
                                            50
                                          )
                                        : "Newsfeed") +
                                      " – Bild: " +
                                      item.feedPostData.medias[0].id
                                    }
                                  />
                                </div>
                              </div>
                            )}

                            {item.feedPostData.medias.length > 1 && (
                              <div className="newsfeed-images">
                                <div className="feed-images">
                                  <div
                                    className="first"
                                    onClick={() =>
                                      this.handleGallery(0, item.id)
                                    }
                                  >
                                    <ProgressiveImager
                                      className="responsive"
                                      src={config.getImageUrl(
                                        "main",
                                        item.feedPostData.medias[0].path
                                      )}
                                      alt={
                                        (item.feedPostData.text
                                          ? item.feedPostData.text.substring(
                                              0,
                                              50
                                            )
                                          : "Newsfeed") +
                                        " – Bild: " +
                                        item.feedPostData.medias[0].id
                                      }
                                    />
                                  </div>
                                  {item.feedPostData.medias[1] && (
                                    <div
                                      className="second"
                                      onClick={() =>
                                        this.handleGallery(0, item.id)
                                      }
                                    >
                                      <ProgressiveImager
                                        className="responsive"
                                        src={config.getImageUrl(
                                          "main",
                                          item.feedPostData.medias[1].path
                                        )}
                                        alt={
                                          (item.feedPostData.text
                                            ? item.feedPostData.text.substring(
                                                0,
                                                50
                                              )
                                            : "Newsfeed") +
                                          " – Bild: " +
                                          item.feedPostData.medias[1].id
                                        }
                                      />
                                      {item.feedPostData.medias.length > 2 && (
                                        <div className="image-count-overlay">
                                          <div className="image-count">
                                            +
                                            {item.feedPostData.medias.length -
                                              2}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}

                            {/* Gallery include */}

                            <Responsive {...Responsive.onlyMobile}>
                              <Gallery
                                images={item.feedPostData.medias.map((img) =>
                                  config.getImageUrl("zoom", img.path)
                                )}
                                slide={currentSlide}
                                galleryOpen={galleryOpen === item.id}
                                onClose={() =>
                                  this.setState({ galleryOpen: false })
                                }
                                onMovePrevRequest={() =>
                                  this.setState({
                                    currentSlide:
                                      (currentSlide +
                                        item.feedPostData.medias.length -
                                        1) %
                                      item.feedPostData.medias.length,
                                  })
                                }
                                onMoveNextRequest={() =>
                                  this.setState({
                                    currentSlide:
                                      (currentSlide + 1) %
                                      item.feedPostData.medias.length,
                                  })
                                }
                              />
                            </Responsive>

                            <Responsive
                              minWidth={Responsive.onlyTablet.minWidth}
                            >
                              <Gallery
                                images={item.feedPostData.medias.map((img) =>
                                  config.getImageUrl("zoomDesktop", img.path)
                                )}
                                slide={currentSlide}
                                galleryOpen={galleryOpen === item.id}
                                onClose={() =>
                                  this.setState({ galleryOpen: false })
                                }
                                onMovePrevRequest={() =>
                                  this.setState({
                                    currentSlide:
                                      (currentSlide +
                                        item.feedPostData.medias.length -
                                        1) %
                                      item.feedPostData.medias.length,
                                  })
                                }
                                onMoveNextRequest={() =>
                                  this.setState({
                                    currentSlide:
                                      (currentSlide + 1) %
                                      item.feedPostData.medias.length,
                                  })
                                }
                              />
                            </Responsive>
                          </>
                        )}
                    </div>
                  </div>
                )}

                {/* Other */}

                {item.event !== config.NTFCN_FEED_POST && item.event !== config.NTFCN_FORUM_THREAD_NEW &&(
                  <div>
                    <div className="new-project-inside">
                      {item.event === config.NTFCN_PROJECT_PUBLISH && (
                        <Link
                          to={item.projectData.linktype === "stories" ? `/oldtimer/${item.projectData.urlslug}/${item.project}/tab/stories` : item.projectData.linktype === "vehicle" ? `/oldtimer/${item.projectData.urlslug}/${item.project}/tab/vehicle` : `/oldtimer/${item.projectData.urlslug}/${item.project}/tab/interview` }
                        >
                          <div className="preview-images">
                            <ImageWrapper
                              className="first responsive"
                              path={item.filePath}
                            >
                              <ProgressiveImager
                                className="responsive"
                                src={config.getImageUrl("main", item.filePath)}
                                alt={
                                  item.projectData.title
                                    ? item.projectData.title +
                                      " von " +
                                      item.userAuthorData.name
                                    : item.description
                                }
                              />
                            </ImageWrapper>
                          </div>
                        </Link>
                      )}

                      {(item.event === config.NTFCN_PROJECTPART_PUBLISH ||
                        item.event === config.NTFCN_CONTENT_PUBLISH) && (
                        <Link
                          to={`/oldtimer/${item.projectPartData.projecturlslug}/${item.projectPartData.urlslug}/${item.projectPart}`}
                        >
                          <div className="preview-images">
                            <ImageWrapper
                              className="first responsive"
                              path={item.filePath}
                            >
                              <ProgressiveImager
                                className="responsive"
                                src={config.getImageUrl("main", item.filePath)}
                                alt={
                                  item.projectPartData.title
                                    ? item.projectPartData.projecttitle +
                                      " – Story: " +
                                      item.projectPartData.title
                                    : item.description
                                }
                              />
                            </ImageWrapper>
                          </div>
                        </Link>
                      )}

                      <div className="user-label" key={index}>
                        <Link
                          to={`/user/${item.userAuthorData.urlslug}/${item.userAuthor}`}
                        >
                          <div className="user-image-wrapper">
                            <ImageWrapper
                              avatar
                              circular
                              path={item.userAuthorData.mediaavatarPath}
                            >
                              <ProgressiveImager
                                placeholder="avatar"
                                circular
                                src={config.getImageUrl(
                                  "avatar",
                                  item.userAuthorData.mediaavatarPath
                                )}
                                alt={item.userAuthorData.name}
                              />
                            </ImageWrapper>
                          </div>
                          <span className="user-name">
                            {item.userAuthorData.name}
                          </span>
                        </Link>
                      </div>

                      <div className="story-update-content">
                        <div className="meta-info">
                          <Moment unix format="DD.MM.YYYY">
                            {item.date}
                          </Moment>
                          {item.event === config.NTFCN_PROJECT_PUBLISH && (
                            <Label color="black" className="newsfeed-label">
                              Neues Fahrzeug
                            </Label>
                          )}
                          {item.event === config.NTFCN_PROJECTPART_PUBLISH && (
                            <Label color="black" className="newsfeed-label">
                              Neue Story
                            </Label>
                          )}
                          {item.event === config.NTFCN_CONTENT_PUBLISH && (
                            <Label color="black" className="newsfeed-label">
                              Story Update
                            </Label>
                          )}
                        </div>

                        {item.event === config.NTFCN_PROJECT_PUBLISH && (
                          <>
                            <div className="project-name">
                              <Link
                                to={item.projectData.linktype === "stories" ? `/oldtimer/${item.projectData.urlslug}/${item.project}/tab/stories` : item.projectData.linktype === "vehicle" ? `/oldtimer/${item.projectData.urlslug}/${item.project}/tab/vehicle` : `/oldtimer/${item.projectData.urlslug}/${item.project}/tab/interview` }
                              >
                                <span className="content-title">
                                  {item.projectData.title}
                                </span>
                              </Link>
                            </div>
                            <div className="newsfeed-description">
                              <Truncate lines={3} ellipsis={<>...</>}>
                                {item.projectData.description ? (
                                  <>{item.projectData.description}</>
                                ) : (
                                  <>{item.description}</>
                                )}
                              </Truncate>
                            </div>
                          </>
                        )}
                        {(item.event === config.NTFCN_PROJECTPART_PUBLISH ||
                          item.event === config.NTFCN_CONTENT_PUBLISH) && (
                          <>
                            <div className="project-name">
                              <Link
                                to={`/oldtimer/${item.projectPartData.projecturlslug}/${item.projectPartData.project}/tab/stories`
                              }
                              >
                                <span className="project-title">
                                  {item.projectPartData.projecttitle}
                                </span>
                              </Link>

                              <Link
                                to={`/oldtimer/${item.projectPartData.projecturlslug}/${item.projectPartData.urlslug}/${item.projectPart}`}
                              >
                                <span className="content-title">
                                  {item.projectPartData.title}
                                </span>
                              </Link>
                            </div>
                            <div className="newsfeed-description">
                              <Truncate lines={3} ellipsis={<>...</>}>
                                {item.projectPartData.description ? (
                                  <>{item.projectPartData.description}</>
                                ) : (
                                  <>{item.description}</>
                                )}
                              </Truncate>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <Divider />
                <Grid className="social-buttons">
                  <Grid.Column width={8} textAlign="left">
                    <div>
                      {(item.event === config.NTFCN_PROJECT_PUBLISH ||
                        item.event === config.NTFCN_PROJECTPART_PUBLISH ||
                        item.event === config.NTFCN_CONTENT_PUBLISH) && (
                        <span className="icons-newsfeed">
                          {/* Like story */}
                          {(item.event === config.NTFCN_PROJECTPART_PUBLISH ||
                            item.event === config.NTFCN_CONTENT_PUBLISH) && (
                            <>
                              <span
                                className="like"
                                onClick={() =>
                                  accountItem && accountItem.id
                                    ? this.handleLikeStory(
                                        item.projectPart,
                                        !item.projectPartData.userWelldone,
                                        item.projectPartData.countWelldone
                                      )
                                    : this.setState({ loginPopup: true })
                                }
                              >
                                <Image
                                  src={
                                    item.projectPartData.userWelldone
                                      ? heartIconActive
                                      : heartIcon
                                  }
                                  alt="Gefällt mir"
                                  title="Gefällt mir"
                                  className="icon-newsfeed-social"
                                />
                                <span className="social-amount fontsize-small-m font-semi">
                                  <NumericLabel params={config.PRETTY_CONFIG}>
                                    {item.projectPartData.countWelldone}
                                  </NumericLabel>
                                </span>
                              </span>
                            </>
                          )}

                          {/* Like project */}
                          {item.event === config.NTFCN_PROJECT_PUBLISH && (
                            <>
                              <span
                                className="like"
                                onClick={() =>
                                  accountItem && accountItem.id
                                    ? this.handleLikeProject(
                                        item.project,
                                        !item.projectData.userLike,
                                        item.projectData.countLike
                                      )
                                    : this.setState({ loginPopup: true })
                                }
                              >
                                <Image
                                  src={
                                    item.projectData.userLike
                                      ? heartIconActive
                                      : heartIcon
                                  }
                                  className="icon-newsfeed-social"
                                  alt="Gefällt mir"
                                  title="Gefällt mir"
                                />
                                <span className="social-amount fontsize-small-m font-semi">
                                  <NumericLabel params={config.PRETTY_CONFIG}>
                                    {item.projectData.countLike}
                                  </NumericLabel>
                                </span>
                              </span>
                            </>
                          )}
                        </span>
                      )}

                      {item.event === config.NTFCN_PROJECT_PUBLISH && (
                        <>
                          <span
                            className="icons-newsfeed"
                            onClick={() =>
                              this.setState({
                                comments: {
                                  isOpen: true,
                                  id: "project::" + item.project,
                                },
                              })
                            }
                          >
                            <Image
                              src={commentIcon}
                              alt="Kommentieren"
                              title="Kommentieren"
                              className="icon-newsfeed-social"
                            />
                            <span className="social-amount fontsize-small-m font-semi">
                              <NumericLabel params={config.PRETTY_CONFIG}>
                                {item.projectData.commentcount}
                              </NumericLabel>
                            </span>
                          </span>
                        </>
                      )}

                      {(item.event === config.NTFCN_PROJECTPART_PUBLISH ||
                        item.event === config.NTFCN_CONTENT_PUBLISH) && (
                        <>
                          <span
                            className="icons-newsfeed"
                            onClick={() =>
                              this.setState({
                                comments: {
                                  isOpen: true,
                                  id: "projectpart::" + item.projectPart,
                                },
                              })
                            }
                          >
                            <Image
                              src={commentIcon}
                              className="icon-newsfeed-social"
                            />
                            <span className="social-amount fontsize-small-m font-semi">
                              <NumericLabel params={config.PRETTY_CONFIG}>
                                {item.projectPartData.commentcount}
                              </NumericLabel>
                            </span>
                          </span>
                        </>
                      )}

                      {item.event === config.NTFCN_FEED_POST && (
                        <>
                          <span
                                className="icons-newsfeed"
                                onClick={() =>
                                  accountItem && accountItem.id
                                    ? this.handleLikeNewsfeed(
                                        item.feedPost,
                                        !item.feedPostData.userLike,
                                        item.feedPostData.countLike
                                      )
                                    : this.setState({ loginPopup: true })
                                }
                              >
                                <Image
                                  src={
                                    item.feedPostData.userLike
                                      ? heartIconActive
                                      : heartIcon
                                  }
                                  alt="Gefällt mir"
                                  title="Gefällt mir"
                                  className="icon-newsfeed-social"
                                />
                                <span className="social-amount fontsize-small-m font-semi">
                                  <NumericLabel params={config.PRETTY_CONFIG}>
                                    {item.feedPostData.countLike}
                                  </NumericLabel>
                                </span>
                              </span>
                          <span
                            className="icons-newsfeed"
                            onClick={() =>
                              this.setState({
                                comments: {
                                  isOpen: true,
                                  id: "feedpost::" + item.feedPost,
                                },
                              })
                            }
                          >
                            <Image
                              src={commentIcon}
                              className="icon-newsfeed-social"
                            />
                            <span className="social-amount fontsize-small-m font-semi">
                              <NumericLabel params={config.PRETTY_CONFIG}>
                                {item.feedPostData.commentcount}
                              </NumericLabel>
                            </span>
                          </span>
                        </>
                      )}
                    </div>
                  </Grid.Column>

                  {item.event === config.NTFCN_PROJECT_PUBLISH && (
                    <>
                      {/* Comments - Mobile */}
                      <Grid.Column
                        width={8}
                        className="comment-btn"
                        onClick={() =>
                          this.setState({
                            comments: {
                              isOpen: true,
                              id: "project::" + item.project,
                            },
                          })
                        }
                      >
                        <div className="fontsize-small-m font-semi">
                          Kommentieren
                        </div>
                      </Grid.Column>
                    </>
                  )}
                  {(item.event === config.NTFCN_PROJECTPART_PUBLISH ||
                    item.event === config.NTFCN_CONTENT_PUBLISH) && (
                    <>
                      {/* Comments - Mobile */}
                      <Grid.Column
                        width={8}
                        className="comment-btn"
                        onClick={() =>
                          this.setState({
                            comments: {
                              isOpen: true,
                              id: "projectpart::" + item.projectPart,
                            },
                          })
                        }
                      >
                        <div className="fontsize-small-m font-semi">
                          Kommentieren
                        </div>
                      </Grid.Column>
                    </>
                  )}
                  {item.event === config.NTFCN_FEED_POST && (
                    <>
                      <Grid.Column
                        width={8}
                        className="comment-btn"
                        onClick={() =>
                          this.setState({
                            comments: {
                              isOpen: true,
                              id: "feedpost::" + item.feedPost,
                            },
                          })
                        }
                      >
                        <div className="fontsize-small-m font-semi">
                          Kommentieren
                        </div>
                      </Grid.Column>
                    </>
                  )}

                  {item.event === config.NTFCN_FORUM_THREAD_NEW && (
                    <>
                      <Grid.Column
                        width={8}
                        className="comment-btn"
                      >
                        <div className="fontsize-small-m font-semi">
                        <Link to={`/forum/${item.forumThreadData.urlslug}/${item.forumThread}`}>
                          Weiterlesen
                        </Link>
                        </div>
                      </Grid.Column>
                    </>
                  )}
                </Grid>
              </Segment>

              <Divider hidden />
              <Divider hidden />
            </div>
          ))}

          <Account
            loginPopupVisible={this.state.loginPopup}
            loginPopupClose={() => this.setState({ loginPopup: false })}
          />

          {isFetching && hasMore && <>{this.placeholder()}</>}

          {isFailed && (
            <>
              <Message negative>
                Beim Abrufen der Daten ist ein Fehler aufgetreten.
              </Message>
              <Grid>
                <Grid.Column width={16} textAlign="center">
                  <Form.Button onClick={this.loadMoreForce} color="red">
                    Nochmals versuchen
                  </Form.Button>
                </Grid.Column>
              </Grid>
            </>
          )}

          <InfiniteScroll
            onLoadMore={this.loadMore}
            loading={isFetching}
            hasNextPage={hasMore}
          />
        </>
      );
    } else {
      return (
        <Container text>
          <ErrorHandler callbackFunction={this.reloadList}/>
        </Container>
      );
    }
  }
}

function mapStateToProps(state, ownProps) {
  const { globalByComponent, listByEntity, notificationById, account } = state;

  const { item } = account || {
    item: {},
  };

  const { type, headerTitle, headerActiveItem } =
    globalByComponent["Container"] || {};

  const {
    list,
    isFetching,
    lastFetch,
    isFailed,
    hasMore,
    page,
    isStoryLiking,
    isProjectLiking,
    isNewsfeedLiking,
    hasUpdate,
    hasUpdateLastFetch,
  } = listByEntity["NTFCN"] || {
    list: {},
    isFetching: true,
    isStoryLiking: false,
    isProjectLiking: false,
  };

  var listitems = [];
  if (list) {
    for (let value of Object.values(list)) {
      if (notificationById[value]) {
        listitems.push(notificationById[value].item);
      }
    }
    listitems = []
      .concat(listitems)
      .sort((a, b) => (a.order > b.order ? 1 : -1));
  }

  // Remove obsolete notifications
  listitems = listitems.filter((item) => {
    if (
      item.event === config.NTFCN_PROJECT_PUBLISH &&
      (!item.projectData || !item.projectData.active)
    ) {
      return false;
    }
    if (
      (item.event === config.NTFCN_PROJECTPART_PUBLISH ||
        item.event === config.NTFCN_CONTENT_PUBLISH) &&
      (!item.projectPartData ||
        !item.projectPartData.active ||
        !item.projectPartData.projectactive)
    ) {
      return false;
    }
    if (item.event === config.NTFCN_FEED_POST && !item.feedPostData) {
      return false;
    }

    return true;
  });

  const { page: runtimepage, backFeedPost, backProject, backStory, backUser } =
    globalByComponent["Runtimedata"] || {};

  return {
    runtimepage,
    backFeedPost,
    backProject,
    backStory,
    backUser,
    type,
    headerTitle,
    headerActiveItem,
    isFetching,
    lastFetch,
    isFailed,
    hasMore,
    page,
    listitems,
    hasUpdate,
    hasUpdateLastFetch,
    account,
    item,
    isStoryLiking,
    isProjectLiking,
    runtimedata: globalByComponent["Runtimedata"],
  };
}

export default connect(mapStateToProps)(withRouter(Newsfeed));
