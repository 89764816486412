import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {Container, Divider, Message, Responsive, Header, Grid, Placeholder, Segment, Button} from 'semantic-ui-react';
import Slider from "react-slick";

import { fetchUserProjectsIfNeeded } from '../../actions/project';
import ListitemOwnProject from '../project/list/listitem-own-project';
import PlusButton from '../../components/PlusButton/PlusButton';
import config from '../../config/config';


class DashboardModuleProjects extends Component {

    componentDidMount() {
        const { dispatch, item } = this.props;

        dispatch(fetchUserProjectsIfNeeded(item.id));
    }

    render() {
        const { item, listitem, isFetching, isFailed } = this.props;

        if (!item || !item.id || (!listitem.length && isFetching)) {
            return (
                <div>
                    <Divider hidden/>
                    {this.placeholder()}
                </div>
            );
        }

        if (isFailed) {
            return (
                <Container text>
                    <Divider hidden/>
                    <Message negative>Beim Abrufen der Daten ist ein Fehler aufgetreten.</Message>
                </Container>
            )
        }

        return (
            <div className="your-projects-wrapper">
                <div className="bg-grey-light px-1 py-1 sm-px-0 sm-py-0">

                    <Responsive {...Responsive.onlyMobile}>
                        <Container>
                            <Header as='h2' >Deine Projekte</Header>
                            <Divider hidden />
                        </Container>
                    </Responsive>

                    <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                        <Header as='h2'>Deine Projekte</Header>
                        <Divider hidden />
                    </Responsive>

                    <Slider {...config.SLIDE_DB_SETTINGS}>
                        {listitem && listitem.map((item, index) => (
                            <div key={index}>
                                <ListitemOwnProject
                                    projectId={item.item.id}
                                    projectTitle={item.item.title}
                                    projectMainImage={item.item.mediamainPath}
                                    path='/backend/project/'
                                    active={item.item.active}
                                    storiesCount={item.item.projectpartsData.length}
                                />
                            </div>
                        ))}
                    </Slider>
                    {(!listitem || !listitem.length) &&
                        <Container>
                            <Message color="teal">
                                Du hast noch kein Projekt angelegt.
                            </Message>
                        </Container>
                    }
                    <Segment basic textAlign={"center"}>
                        <PlusButton
                            as={Link}
                            to="/backend/new_project/firststep"
                            title="Neues Fahrzeug"
                            color="red"
                            />
                    </Segment>
                </div>
            </div>
        );
    }

    placeholder(){
        return (
            <>
                <div className="dashboard-slider-placeholder">
                    <div className="your-projects-wrapper">

                        <Container>
                            <Header as='h2' className="text-placeholder" >Deine Projekte</Header>
                            <Divider hidden />
                            <Grid columns="equal">

                                <Grid.Column>
                                    <Placeholder className="slides-placeholder">
                                        <Placeholder.Image />
                                    </Placeholder>
                                </Grid.Column>

                                <Grid.Column>
                                    <Placeholder className="slides-placeholder">
                                        <Placeholder.Image />
                                    </Placeholder>
                                </Grid.Column>

                                <Grid.Column>
                                    <Placeholder className="slides-placeholder">
                                        <Placeholder.Image />
                                    </Placeholder>
                                </Grid.Column>

                            </Grid>

                            <Divider hidden/>
                            <Grid>
                                <Grid.Column width={16} textAlign="center">
                                    <Button disabled color='red'>Neues Fahrzeug</Button>
                                </Grid.Column>
                            </Grid>

                        </Container>
                    </div>
                </div>
            </>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { account, listByEntity,  projectById} = state;

    const { item } = account || {
        item: {},
    };
    const { list, isFetching, isFailed } = listByEntity[item.id+ '::userProjects'] || {};

    let listitem = [];

    //Own projects
    if (list) {
        for (let [key, value] of Object.entries(list)) {
            if (projectById[value] && !projectById[value].isDeleted) {
                listitem.push(projectById[value]);
            }
        }
    }

    return {
        listitem,
        item,
        isFetching,
        isFailed,
    }
}

export default connect(mapStateToProps)(DashboardModuleProjects);
