import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loginAccount, logoutAccount, postAccount, postRecover, putRequest, resetMessages } from '../../actions/account';
import { popupContent, loginPopup, setActionAfterLogin } from '../../actions/global';
import { withRouter, Link, Redirect } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { RemoveScrollBar } from 'react-remove-scroll-bar';
import { zeroRightClassName,fullWidthClassName, noScrollbarsClassName } from 'react-remove-scroll-bar';
import { Form, Input, Container, Header, Image, Button, Label, Divider, Icon, Segment, Loader, Grid, Message } from 'semantic-ui-react';
import config from '../../config/config'
import './Account.scss';

/* ICONS / IMGS */
import Logo from '../../images/vetero-bildmarke.svg';
import fbIcon from '../../images/icons/logo-facebook.svg';
import googleIcon from '../../images/icons/logo-google.svg';
import CustomPopup from '../CustomPopup/CustomPopup';


class Account extends Component {
    state = {
        username: "",
        password: "",
        accountPopupContent: this.props.accountStep,
        validation: {},
        loginDashboard: this.props.loginDashboard,
        register: {
            name: '',
            email: '',
            plainPassword: '',
            plainPassword2: '',
            terms: false,
        },
        recover: {
            email: '',
        }
    }

    constructor(props) {
        super(props);

        this.logout = this.logout.bind(this);
        this.handleInputReg = this.handleInputReg.bind(this);
        this.handleCheckboxReg = this.handleCheckboxReg.bind(this);
        this.handleSubmitReg = this.handleSubmitReg.bind(this);
        this.handleInputRec = this.handleInputRec.bind(this);
        this.handleSubmitRec = this.handleSubmitRec.bind(this);
    }

    componentDidMount() {
        const { dispatch, redirectToDashboard } = this.props;
        dispatch(resetMessages());
    }

    handleInputReg(e, element) {
        this.setState({
            register: {
                ...this.state.register,
                [element.name]: element.value,
            },
        });
    }

    handleCheckboxReg(e, element) {
        this.setState({
            register: {
                ...this.state.register,
                [element.name]: element.checked,
            },
        });
    }

    handleSubmitReg = event => {
        event.preventDefault()
        const { dispatch, isRegistering } = this.props;
        const { register } = this.state;

        if (!isRegistering) {
            // TODO: Check client side
            dispatch(postAccount(register));
        }
    }

    handleInputRec(e, element) {
        this.setState({
            recover: {
                ...this.state.recover,
                [element.name]: element.value,
            },
        });
    }

    handleSubmitRec = event => {
        event.preventDefault()
        const { dispatch, isRecovering } = this.props;
        const { recover } = this.state;

        if (!isRecovering && recover.email) {
            // TODO: Check client side
            dispatch(putRequest(recover));
        }
    }

    componentDidUpdate = (prevProps) => {
        const { item, status, loginPopupVisible, dispatch, registeringMessage, redirectToDashboard, requestingMessage, message, accountStep, history } = this.props;
        
        if((!prevProps.item || Object.keys(prevProps.item).length === 0) && item && item.id){
            if(redirectToDashboard){
                history.push(redirectToDashboard);
            }
            this.handleClosePopup();
            //dispatch(setActionAfterLogin("/"));
        }
        if(prevProps.status != status && status == 401 && message === "Invalid credentials.") {
            this.setState({validation: {
                wrongData: "Login nicht möglich. Kontrolliere die von dir eingegebene E-Mail Adresse und das Passwort."
            }})
        }
        if(prevProps.status != status && status == 401 && message === "Account is disabled.") {
            this.setState({validation: {
                wrongData: "Bitte bestätige zuerst deine E-Mail-Adresse."
            }})
        }
        if(prevProps.status != status && status == 500 && status == 403) {
            this.setState({validation: {
                serverError: "Etwas ist schiefgelaufen. Bitte versuch es nochmal."
            }})
        }
        if(prevProps.loginPopupVisible != loginPopupVisible) {
            this.setState({accountPopupContent: accountStep, validation: {}, username: '', password: '', register: ''})
        }
        if (prevProps.registeringMessage !== registeringMessage && registeringMessage === 'SUCCESS') {
            this.setState({accountPopupContent: "success"});
        }
        if (prevProps.requestingMessage !== requestingMessage && requestingMessage === 'SUCCESS') {
            this.setState({accountPopupContent: "successRecover"});
        }
}

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit = event => {
        event.preventDefault()
        const { dispatch, item } = this.props;
        const { username, password } = this.state;
        if(username && password) {
            dispatch(loginAccount(this.state));
            this.setState({
                validation: {}
            })
        }
        else if(!password && !username) {
            this.setState({
                validation: {
                    all: "Gib deine Anmeldeinformationen ein."
                }
            })
        }
        else if(!username) {
            this.setState({
                validation: {
                    login: 'Gib deine E-Mail-Adresse ein.'
                }
            })
        }
        else if(!password){
            this.setState({
                validation: {
                    password: 'Gib dein Passwort ein.'
                }
            })
        }
    }

    handleClosePopup = () => {
        const { item, history } = this.props;
        const { loginDashboard } = this.state;

        // Close
        this.props.loginPopupClose(false)
    }

    handleContent = (content) => {
        switch (content) {
            case "login":
                return this.login()
            case "register":
                return this.register()
            case "password":
                return this.remindPw()
            case "success":
                return this.success()
            case "successRecover":
                return this.successRecover()
            default:
                return this.login();
        }
    }


    render() {
        const { item, isFetching} = this.props;

        return (
            <Popup
                position="center center"
                closeOnDocumentClick
                closeOnEscape
                open={this.props.loginPopupVisible}
                onClose={this.props.loginPopupClose}
                className={zeroRightClassName + " pop login-popup"}
                modal
                overlayStyle={
                    {marginTop: 0}
                }
            >
                <>
                    <RemoveScrollBar/>
                    {this.handleContent(this.state.accountPopupContent)}
                </>
            </Popup>

        )
    }

    login() {
        const { item, isFetching} = this.props;
        const { validation } = this.state;
            return (<>
                <Container className="login-popup-content">
                    <div className="logo">
                        <Image src={Logo} />
                    </div>

                    <Header as="h2" textAlign="center" className="login-header">Willkommen bei Vetero</Header>
                    <Form onSubmit={this.handleSubmit}>

                        {validation.serverError &&
                            <Message color="red">
                                {validation.serverError}
                            </Message>
                        }

                        {validation.wrongData &&
                            <Message color="red">
                                {validation.wrongData}
                            </Message>
                        }

                        {validation.all &&
                            <Message color="red">
                                {validation.all}
                            </Message>
                        }

                        {validation.login &&
                            <Message color="red">
                                {validation.login}
                            </Message>
                        }

                        {validation.password &&
                            <Message color="red">
                                {validation.password}
                            </Message>
                        }

                        <Form.Input
                            name="username"
                            placeholder="E-Mail oder Name"
                            value={this.state.username}
                            onChange={this.handleChange}
                            fluid
                            error={validation.login || validation.wrongData || validation.all ? true : false}
                        />

                        <Form.Input
                            name="password"
                            type="password"
                            placeholder="Passwort"
                            value={this.state.password}
                            onChange={this.handleChange}
                            fluid
                            className="pw-input"
                            error={validation.password || validation.wrongData || validation.all ? true : false}
                        />
                        <a className="pw-remind" onClick={()=>this.setState({accountPopupContent: "password"})}>Passwort vergessen?</a>

                        <Button fluid className="submit" loading={this.props.isFetching} type="submit" color="red">
                            Einloggen
                        </Button>

                        <div className="divider">
                            <div className="line">
                                <div className="or-wrapper">
                                    <span>oder</span>
                                </div>
                            </div>
                        </div>

                        <div className="socials-login">
                            <Button color="facebook" fluid as="a" href={config.API_HOST+'/oauth/connect/facebook'}>
                                <Image src={fbIcon} />
                                <span>Mit Facebook anmelden</span>
                            </Button>

                            <Button color="blue" fluid as="a" href={config.API_HOST+'/oauth/connect/google'}>
                                <Image src={googleIcon} />
                                <span>Mit Google anmelden</span>
                            </Button>
                        </div>

                        <span className="create-account">Noch kein Vetero-Mitglied? <span onClick={()=>this.setState({accountPopupContent: "register"})} className="create-account-link">Jetzt Konto erstellen</span></span>

                    </Form>
                </Container>
            </>);
    }

    register() {
        const { isRegistering, registeringMessage } = this.props;

        return (
            <Container className="login-popup-content">
                <Header as="h2">Konto erstellen</Header>
                <Form autoComplete="off">
                    <Form.Input
                        label="Benutzername"
                        name="name"
                        placeholder="Benutzername"
                        fluid
                        onChange={this.handleInputReg}
                        required
                    />

                    <Form.Input
                        label="E-Mail"
                        name="email"
                        type="email"
                        placeholder="E-Mail"
                        fluid
                        onChange={this.handleInputReg}
                        required
                    />

                    <Form.Input
                        label="Passwort"
                        name="plainPassword"
                        type="password"
                        placeholder="Passwort"
                        fluid
                        className="pw-input"
                        onChange={this.handleInputReg}
                        autoComplete="new-password"
                        required
                    />

                    <Form.Input
                        label="Passwort wiederholen"
                        name="plainPassword2"
                        type="password"
                        placeholder="Wiederholen"
                        fluid
                        className="pw-input"
                        onChange={this.handleInputReg}
                        autoComplete="new-password"
                        required
                    />

                    <Form.Field required className="register-checkbox">
                        <Form.Checkbox
                            name="terms"
                            onChange={this.handleCheckboxReg}
                        />
                        <label className="register-checkbox-label">Ich akzeptiere <Link onClick={this.handleClosePopup} className="link" to="/privacy">AGB/Nutzungsbedingungen</Link></label>
                    </Form.Field>

                    <Divider hidden/>

                    {registeringMessage === 'FAILURE' &&
                        <Message negative>Beim Senden ist ein Fehler aufgetreten.</Message>
                    }
                    {registeringMessage === 'termsempty' &&
                        <Message negative>Die AGB wurden nicht akzeptiert.</Message>
                    }
                    {registeringMessage === 'fieldsempty' &&
                        <Message negative>Es wurden nicht alle Formularfelder ausgefüllt.</Message>
                    }
                    {registeringMessage === 'pwmissmatch' &&
                        <Message negative>Die Passwörter stimmen nicht überein.</Message>
                    }
                    {registeringMessage === 'emailexists' &&
                        <Message negative>Diese E-Mail-Adresse wird bereits verwendet.</Message>
                    }
                    {registeringMessage === 'nameexists' &&
                        <Message negative>Dieser Benutzername wird bereits verwendet.</Message>
                    }
                    {registeringMessage === 'emailinavlid' &&
                        <Message negative>Diese E-Mail-Adresse ist ungültig.</Message>
                    }

                    <Button.Group widths="2">
                        <Button fluid basic color="red" onClick={()=>this.setState({accountPopupContent: "login"})} className="m-top">Zurück</Button>
                        <Button loading={isRegistering} fluid color="red" onClick={this.handleSubmitReg}>Weiter</Button>
                    </Button.Group>


                </Form>
            </Container>
        )
    }

    success(){
        return (
            <Container className="login-popup-content register-success" textAlign="center">
                <Icon color="green" name="check circle outline" size="huge"/>
                <Header textAlign="center" as="h2">Du hast dich erfolgreich registriert!</Header>
                <p>Du erhältst eine Bestätigung per E-Mail.</p>
                <Divider hidden/>
                <Button color="red" onClick={()=>this.setState({accountPopupContent: "login"})}>Schließen</Button>
                <Divider hidden/>
            </Container>
        )
    }

    successRecover(){
        return (
            <Container className="login-popup-content">
                <div className="text-center">
                    <Icon color="green" name="check circle outline" size="big"/>
                </div>
                <Header textAlign="center" as="h2">Passwort Wiederherstellung gestartet!</Header>
                <div>Du erhältst den Wiedeherstellungslink per E-Mail.</div>
                <Divider hidden/>
                <Button color="red" onClick={()=>this.setState({accountPopupContent: "login"})}>Schließen</Button>
                <Divider hidden/>
            </Container>
        )
    }

    remindPw() {
        const { isRequesting, requestingMessage } = this.props;

        return (
            <Container className="login-popup-content">
                <Header as="h2">Passwort zurücksetzen</Header>
                <Form>
                    <Form.Input
                        label="E-Mail Adresse"
                        name="email"
                        type="email"
                        placeholder="E-Mail"
                        fluid
                        onChange={this.handleInputRec}
                    />
                </Form>
                <Divider hidden/>

                {requestingMessage === 'FAILURE' &&
                    <Message negative>Beim Senden ist ein Fehler aufgetreten.</Message>
                }
                {requestingMessage === 'nouser' &&
                    <Message negative>Es wurde kein Account mit dieser E-Mail-Adresse gefunden.</Message>
                }

                <Button.Group widths="2">
                    <Button fluid basic color="red" onClick={()=>this.setState({accountPopupContent: "login"})} className="m-top">Zurück</Button>
                    <Button loading={isRequesting} fluid color="red" onClick={this.handleSubmitRec}>Weiter</Button>
                </Button.Group>

            </Container>
        )
    }

    logout() {
        const { dispatch } = this.props;
        dispatch(logoutAccount());
    }
}

function mapStateToProps(state, ownProps) {
    const { account, globalByComponent } = state;
    const { isFetching, isFailed, isDirty, message, lastUpdated, lastFetch, item, token, status, isRegistering, registeringMessage, isRecovering, recoveringMessage, isActivating, activatingMessage, isRequesting, requestingMessage } = account || {
        item: {},
        status: null,
        message: ""
    }

    const { redirectToDashboard } = globalByComponent['AccountAction'] || {
    }

    return {
        item,
        isFetching,
        isFailed,
        isDirty,
        lastUpdated,
        lastFetch,
        token,
        status,
        isRegistering,
        registeringMessage,
        isRecovering,
        recoveringMessage,
        isActivating,
        activatingMessage,
        isRequesting,
        requestingMessage,
        message,
        redirectToDashboard
    }
}

export default connect(mapStateToProps)(withRouter(Account));
