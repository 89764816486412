import React, { Component } from 'react'
import { Grid, GridColumn, Button, ButtonGroup, Container, Menu } from 'semantic-ui-react'
import { Link } from 'react-router-dom';

class EditHeader extends Component {

    state = {
        menuHeight: 0,
    }

    componentDidMount = () => {
        this.setDimensions();
        document.body.classList.add('is-editing');
    }

    componentWillUnmount = () => {
        document.body.classList.remove('is-editing');
        this.setState({ menuHeight: 0 })
    }

    setDimensions = () => {
        //Workaround - doesn't work without setTimeout
        // Set height of header
        let scope = this;
        setTimeout(function(){
            let menuHeight;
            if (document.querySelector('.header-menu')) {
                menuHeight = document.querySelector('.header-menu').offsetHeight;
                scope.setState({menuHeight: menuHeight});
            }

        }, 0)
    }

    render() {

        const { title, backPath, stepMenu, requestPublish, previewPath, children, requestGoback, handleStepMenu, updateDisable, mediaUpload, fetching } = this.props;
        const { menuHeight } = this.state;

        return (
                <>
                <header className="header-menu edit-header">
                    <div className="edit-header-main">
                        <Grid verticalAlign="top">
                            <Grid.Column mobile={16} tablet={5} computer={5}>
                                <ButtonGroup>
                            
                                    {(updateDisable || mediaUpload) ? 
                                        <Button onClick={requestGoback} basic>
                                            Zurück
                                        </Button>
                                        :
                                        <Button as={Link} to={backPath} basic>
                                            Zurück
                                        </Button>
                                    }
                                    <Button basic onMouseDown={(e) => e.preventDefault()}  loading={fetching} disabled={!updateDisable} onClick={requestPublish}>
                                        Speichern
                                    </Button>
                                </ButtonGroup>
                                {updateDisable && 
                                    <span className="not-saved text-white text-bold">Nicht gespeichert</span>
                                }
                            </Grid.Column>
                            <GridColumn width={6} only="tablet computer" verticalAlign="middle" textAlign="center">
                                <div class="h1 text-white">{title}</div>
                            </GridColumn>
                            <Grid.Column width={5} only="tablet computer"></Grid.Column>
                        </Grid>
                    </div>
                    {stepMenu && 
                    <>
                        <Menu pointing secondary className="stepmenu mb-2">
                            {stepMenu.menuItems.map((item, index) => (
                                <Menu.Item 
                                    className={item.validation ? "validation-error" : ""}
                                    onClick={() => handleStepMenu(item.step)}
                                    active={stepMenu.currentStep === item.step}
                                >
                                    {item.name}
                                </Menu.Item>
                            ))}
                            <div className="preview-button">
                                <Button as={Link} target="_blank" to={previewPath} basic color="grey">Vorschau</Button>
                            </div>
                        </Menu>
                    </>
                    }
                </header>
                <main style={{marginTop: this.state.menuHeight}}>
                    {children}
                </main>
                </>
            
        )
    }
}

export default EditHeader;
