import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { Container, Segment, Grid, Label, Placeholder, Image } from 'semantic-ui-react';
import ProgressiveImager from "../helpers/progressive-imager";
import config from "../../config/config";
import placeholderHeader from '../../images/header-empty.jpg';
import Truncate from "react-truncate";

class ItemCard extends Component {
    render() {

        const { path, image, status, title, meta, headline, dropdown } = this.props;

        return (

            <Link
                to={path}
                className="card item-card"
            >
                <div className="item-card">
                    <Segment>
                        <Grid>
                            <Grid.Column mobile={7} tablet={6} computer={6}>
                                <div className="item-card-image">
                                    {image ?
                                    <ProgressiveImager
                                        src={config.getImageUrl(
                                            "halfwidth",
                                            image
                                        )}
                                    />
                                    :
                                    <Image src={placeholderHeader} alt="Spezialisten Platzhalter Bild" />
                                    }
                                    {status && status.active && (
                                        <Label color="green">{status.true}</Label>
                                    )} 
                                    {status && !status.active && (
                                        <Label color="red">{status.false}</Label>
                                    )}
                                    {status && status.activating && (
                                        <Label color="yellow">{status.activating}</Label>
                                    )}
                                    {status && status.deactivating && (
                                        <Label color="yellow">{status.deactivating}</Label>
                                    )}
                                </div>
                            </Grid.Column>
                            <Grid.Column
                                mobile={9}
                                tablet={10}
                                computer={10}
                            >
                                <div className="item-card-content">
                                    <div className="item-card-header">
                                        <div className="item-card-headline">
                                            <Truncate lines={2}>
                                                {headline ? headline : "Ohne Titel"}
                                            </Truncate>
                                        </div>
                                        {title && 
                                            <div className="item-card-subheadline">
                                                <Truncate lines={2}>
                                                    {title}
                                                </Truncate>
                                            </div>
                                        }
                                    </div>
                                    {meta && 
                                        <div className="item-card-meta">
                                            {meta}
                                        </div>
                                    }  
                                </div>

                                {dropdown && 
                                    <div className="item-card-dropdown">
                                        {dropdown}
                                    </div>
                                } 

                            </Grid.Column>
                        </Grid>
                    </Segment>
                </div>
            </Link>
        );
    }
}

class ItemCardFetch extends Component {
    render(){

        return (
            <div className="card item-card fetching">
                <Segment>
                    <Grid>
                        <Grid.Column mobile={7} tablet={6} computer={6}>
                            <div className="item-card-image">
                                <Placeholder>
                                    <Placeholder.Image/>
                                </Placeholder>
                            </div>
                        </Grid.Column>
                        <Grid.Column mobile={9} tablet={10} computer={10}>
                            <div className="item-card-content">
                                <Placeholder>
                                    <Placeholder.Line/>
                                    <Placeholder.Line/>
                                    <Placeholder.Line/>
                                </Placeholder>
                            </div>
                        </Grid.Column>
                    </Grid>
                </Segment>
            </div>
        )
    }
}

export default ItemCard;
export { ItemCardFetch }
