import { CHANGE_PROFESSIONAL, API_PROFESSIONAL_REQUEST, API_PROFESSIONAL_SUCCESS, API_PROFESSIONAL_LIST_SUCCESS, API_PROFESSIONAL_USER_SUCCESS, API_PROFESSIONAL_DIRECT_SUCCESS, API_PROFESSIONAL_DELETE_SUCCESS, API_PROFESSIONAL_PAYMENT_ID_FAILURE, API_PROFESSIONAL_PAYMENT_ID_SUCCESS, API_PROFESSIONAL_PAYMENT_ID_REQUEST, API_PROFESSIONAL_FAILURE } from '../actions/professional';
import {actions} from 'ix-redux-file-upload';
import { API_PROFESSIONAL_DIRECT_REQUEST, API_PROFESSIONAL_DIRECT_FAILURE } from './../actions/professional';
import { API_PAYPAL_SUBSCRIPTION_FAILURE, API_PAYPAL_SUBSCRIPTION_REQUEST, API_PAYPAL_SUBSCRIPTION_SUCCESS, API_PAYPAL_SUBSCRIPTION_CANCEL_SUCCESS, API_PAYPAL_SUBSCRIPTION_CANCEL_FAILURE, API_PAYPAL_SUBSCRIPTION_CANCEL_REQUEST, API_PAYPAL_SUBSCRIPTION_ACTIVATE_REQUEST, API_PAYPAL_SUBSCRIPTION_ACTIVATE_FAILURE, API_PAYPAL_SUBSCRIPTION_SUSPEND_SUCCESS } from '../actions/paypal';
import { API_PAYPAL_SUBSCRIPTION_SUSPEND_REQUEST, API_PAYPAL_SUBSCRIPTION_ACTIVATE_SUCCESS, API_PAYPAL_SUBSCRIPTION_SUSPEND_FAILURE } from './../actions/paypal';

export default function professionalsById(state = {}, action) {

    try {

        /* Paypal subscription */
        if(action.type === API_PAYPAL_SUBSCRIPTION_REQUEST) {
            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    subscription: {
                        ...state[action.meta.id].subscription,
                        isFetching: true,
                        isFailed: false,
                        //Additional information
                        isLoading: true,
                        isSuspending: false,
                        isCanceling: false,
                        isActivating: false,
                    }
                }
            };
        }
        
        else if(action.type === API_PAYPAL_SUBSCRIPTION_SUCCESS) {
            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    subscription: {
                        ...state[action.meta.id].subscription,
                        isFetching: false,
                        isFailed: false,
                        isLoading: false,
                        ...action.payload,
                    }
                }
            };
        }

        else if(action.type === API_PAYPAL_SUBSCRIPTION_FAILURE) {
            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    subscription: {
                        ...state[action.meta.id].subscribtion,
                        isFetching: false,
                        isFailed: true,
                        isLoading: false,
                    }
                }
            };
        }

        if(action.type === API_PAYPAL_SUBSCRIPTION_CANCEL_REQUEST) {
            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    subscription: {
                        ...state[action.meta.id].subscription,
                        isFetching: true,
                        isFailed: false,
                        isCanceling: true
                    }
                }
            };
        }
        
        else if(action.type === API_PAYPAL_SUBSCRIPTION_CANCEL_SUCCESS) {
            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    subscription: {
                        ...state[action.meta.id].subscription,
                        isFetching: false,
                        isFailed: false,
                        isCanceling: false
                    }
                }
            };
        }

        else if(action.type === API_PAYPAL_SUBSCRIPTION_CANCEL_FAILURE) {
            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    subscription: {
                        ...state[action.meta.id].subscribtion,
                        isFetching: false,
                        isFailed: true,
                        isCanceling: false
                    }
                }
            };
        }

        if (action.type === API_PAYPAL_SUBSCRIPTION_ACTIVATE_REQUEST) {
            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    subscription: {
                        ...state[action.meta.id].subscribtion,
                        isFetching: true,
                        isFailed: false,
                        isActivating: true
                    }
                }
            }
        }

        else if (action.type === API_PAYPAL_SUBSCRIPTION_ACTIVATE_SUCCESS) {
            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    subscription: {
                        ...state[action.meta.id].subscribtion,
                        isFetching: false,
                        isFailed: false,
                        isActivating: false
                    }
                }
            }
        }

        else if (action.type === API_PAYPAL_SUBSCRIPTION_ACTIVATE_FAILURE) {
            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    subscription: {
                        ...state[action.meta.id].subscribtion,
                        isFetching: false,
                        isFailed: false,
                        isActivating: false
                    }
                }
            }
        }

        if (action.type === API_PAYPAL_SUBSCRIPTION_SUSPEND_REQUEST) {
            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    subscription: {
                        ...state[action.meta.id].subscribtion,
                        isFetching: true,
                        isFailed: false,
                        isSuspending: true
                    }
                }
            };
        }

        else if (action.type === API_PAYPAL_SUBSCRIPTION_SUSPEND_SUCCESS) {
            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    subscription: {
                        ...state[action.meta.id].subscribtion,
                        isFetching: false,
                        isFailed: false,
                        isSuspending: false
                    }
                }
            };
        }

        else if (action.type === API_PAYPAL_SUBSCRIPTION_SUSPEND_FAILURE) {
            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    subscription: {
                        ...state[action.meta.id].subscribtion,
                        isFetching: false,
                        isFailed: true,
                        isSuspending: false
                    }
                }
            };
        }

        if (action.type === API_PROFESSIONAL_REQUEST) {
            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    isFetching: true,
                    lastFetch: Date.now(),
                }
            };
        }
        else if (action.type === API_PROFESSIONAL_SUCCESS) {

            let categories = [];
            let inputTags = [];

            if(action.payload.professional.categorizations){
                action.payload.professional.categorizations.map(item => {
                    categories.push(parseFloat(item))
                    inputTags.push(parseFloat(item))
                })
            }

            return {
                ...state,
                [action.payload.professional.id]: {
                    ...state[action.payload.professional.id],
                    isFetching: false,
                    isFailed: false,
                    isDirty: false,
                    item: {
                        ...(state[action.payload.professional.id].item ? state[action.payload.professional.id].item: {}),
                        ...action.payload.professional ? action.payload.professional : {},
                        categories,
                        input: {
                            ...action.payload.professional ? action.payload.professional : {},
                            categories,
                            inputTags          
                        }

                    },
                    payment: {
                        ...(state[action.payload.professional.id].payment ? state[action.payload.professional.id].payment: {}),
                        ...action.payload.payment ? action.payload.payment : {}
                    },
                    lastFetch: Date.now(),
                }
            }
        }

        else if (action.type === API_PROFESSIONAL_FAILURE) {
            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    isFetching: false,
                    lastFetch: Date.now(),
                    isFailed: true
                }
            };
        }

        if (action.type === API_PROFESSIONAL_LIST_SUCCESS || action.type === API_PROFESSIONAL_USER_SUCCESS) {

            action.payload.list.map((pl) => {
                if (!state[pl.professional.id]) {
                    state = {
                        ...state,
                        [pl.professional.id]: {
                            item: {}
                        }
                    }
                }

                var messages = {};
                if(pl.list) {
                    Object.values(pl.list).map((item) => {
                        messages[item.professional.id] = {...item};
                    });
                }

                state = {
                    ...state,
                    [pl.professional.id]: {
                        ...state[pl.professional.id],
                        isFetching: false,
                        isFailed: false,
                        item: {
                            ...pl.professional,
                            input: pl.professional,
                        },
                        lastUpdated: Date.now(),
                    }
                };
            });
        }

        if (action.type === CHANGE_PROFESSIONAL) {
            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    item: {
                        ...state[action.meta.id].item,
                        input: {
                            ...state[action.meta.id].item.input,
                            ...action.payload.input,
                        }
                    }
                }
            };
        }
        if (action.type === API_PROFESSIONAL_PAYMENT_ID_REQUEST) {
            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    isPaymentUpdating: true,
                }
            };
        }
        if (action.type === API_PROFESSIONAL_PAYMENT_ID_SUCCESS) {

            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    payment: {
                        ...(state[action.payload.professional.id].payment ? state[action.payload.professional.id].payment: {}),
                        ...action.payload.payment
                    },
                    isPaymentUpdating: false,
                }
            };
        }
        if (action.type === API_PROFESSIONAL_PAYMENT_ID_FAILURE) {
            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    isPaymentUpdating: false,
                }
            };
        }

        if (action.type === API_PROFESSIONAL_DIRECT_REQUEST) {
            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    isUpdating: true,
                }
            };
        }
        if (action.type === API_PROFESSIONAL_DIRECT_SUCCESS) {
            let categories = [];
            let inputTags = [];

            if(action.payload.professional.categorizations){
                action.payload.professional.categorizations.map(item => {
                    categories.push(item)
                    inputTags.push(item)
                })
            }

            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    item: {
                        ...(state[action.payload.professional.id].item ? state[action.payload.professional.id].item: {}),
                        ...action.payload.professional ? action.payload.professional : {},
                        categories,
                        input: {
                            ...action.payload.professional ? action.payload.professional : {},
                            categories,
                            inputTags          
                        }
                    },
                    isUpdating: false,
                }
            };
        }
        if (action.type === API_PROFESSIONAL_DIRECT_FAILURE) {
            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    isUpdating: false,
                }
            };
        }
        else if (action.type === API_PROFESSIONAL_DELETE_SUCCESS) {
            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    isDirty: false,
                    isFetching: false,
                    isFailed: false,
                    isDeleted: true,
                    item: {},
                    lastUpdated: Date.now(),
                }
            };
        }

        if (action.type === actions.FILE_UPLOAD_PROGRESS) {
            var identificator = action.payload.identificator.split('.');

            if (identificator[0] === 'professional' && identificator[3] !== "media") {
                return {
                    ...state,
                    [identificator[1]]: {
                        ...state[identificator[1]],
                        isUploading: true,
                        item: {
                            ...state[identificator[1]].item,
                            input: {
                                ...state[identificator[1]].item.input,
                                [identificator[3] + 'Path']: 'LOADING',
                                [identificator[3] + 'PreviewPath']: 'LOADING',
                                [identificator[3] + 'Progress']: action.payload.progress,

                            }
                        },
                    }
                };
            }

            else if (identificator[0] === 'professional' && identificator[3] === "media"){
                var media = state[identificator[1]].item.input.media;

                if (!media) {
                    media = [];
                }

                var updated = false;
                if(media){
                    media.map((item, index) => {
                        if(item.id == action.payload.fileType.uid && !updated){
                            media[index].progress = action.payload.progress;
                            updated = true;
                        }
                    })
                }

                if(!updated) {
                    media.push({
                        id: action.payload.fileType.uid,
                        progress: action.payload.progress,
                        path: 'LOADING',
                        previewPath: "LOADING",
                        position: media.length +1,
                        type: action.payload.isImage ? 1 : 2,
                    });
                }

                state = {
                    ...state,
                    [identificator[1]]: {
                        ...state[identificator[1]],
                        isUploading: true,
                        item: {
                            ...state[identificator[1]].item,
                            input: {
                                ...state[identificator[1]].item.input,
                                media
                            }
                        },
                    }
                }

                return state;
            }

        }
        if (action.type === actions.FILE_UPLOAD_COMPLETE) {

            var identificator = action.payload.identificator.split('.');
            if (identificator[0] === 'professional' && identificator[3] !== "media") {
                return {
                    ...state,
                    [identificator[1]]: {
                        ...state[identificator[1]],
                        isUploading: false,
                        item: {
                            ...state[identificator[1]].item,
                            input: {
                                ...state[identificator[1]].item.input,
                                [identificator[3]]: action.payload.response.id,
                                [identificator[3] + 'Path']: action.payload.response.path,
                                [identificator[3] + 'PreviewPath']: action.payload.response.previewPath ? action.payload.response.previewPath : null
                            }
                           
                        },
                    }
                };
            }
            else if (identificator[0] === 'professional' && identificator[3] === "media") {
                var media = state[identificator[1]].item.input.media;
                let arr = [];

                if (!media) {
                    media = [];
                }

                var removedLoading = false;
                media.map((mv, mk) => {
                    if ((mv.path === 'LOADING' && mk+1 === mv.position && mv.progress === 100) && !removedLoading) {
                        media[mk] = {
                            ...media[mk],
                            id: action.payload.response.id,
                            path: action.payload.response.path,
                            type: action.payload.response.type,
                            previewPath: action.payload.response.type === 2 ? action.payload.response.previewPath : null,
                            position: mv.position
                        };
                        removedLoading = true;
                    }
                });

                

                state = {
                    ...state,
                    [identificator[1]]: {
                        ...state[identificator[1]],
                        isUploading: false,
                        item: {
                            ...state[identificator[1]].item,
                            input: {
                                ...state[identificator[1]].item.input,
                                media: media.map(item => item)
                            }
                        },
                    }
                }

                return state;
            }
        }

        if (action.type === actions.FILE_UPLOAD_ERROR) {

            var identificator = action.payload.identificator.split('.');
            if (identificator[0] === 'professional' && identificator[3] !== "media") {
                return {
                    ...state,
                    [identificator[1]]: {
                        ...state[identificator[1]],
                        isUploading: false,
                        item: {
                            ...state[identificator[1]].item,
                            input: {
                                ...state[identificator[1]].item.input,
                                [identificator[3] + 'Path']: "ERROR",
                                [identificator[3] + 'PreviewPath']: "ERROR"
                            }
                           
                        },
                    }
                };
            }
            else if (identificator[0] === 'professional' && identificator[3] === "media") {
                var media = state[identificator[1]].item.input.media;
                if (!media) {
                    media = [];
                }

                var removedLoading = false;
                media.map((mv, mk) => {
                    if ((mv.path === 'LOADING' && mk+1 === mv.position && mv.progress === 100) && !removedLoading) {
                        media[mk] = {
                            ...media[mk],
                            path: "ERROR",
                            previewPath: "ERROR"
                        };
                        removedLoading = true;
                    }
                });


                state = {
                    ...state,
                    [identificator[1]]: {
                        ...state[identificator[1]],
                        isUploading: false,
                        item: {
                            ...state[identificator[1]].item,
                            input: {
                                ...state[identificator[1]].item.input,
                                media
                            }
                        },
                    }
                }

                return state;
            }
        }
        
       
        return state;
        


    } catch (e) {

        console.log(e);
        return {
            ...state,
            fatalError: true,
        };

    }
}
