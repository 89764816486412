import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { Responsive, Segment, Label, Grid, Divider, Image, Placeholder, Container, Message, Icon, Button, Form } from 'semantic-ui-react';
import { handleContainer, handleHeaderItems, footerActiveItem, setRuntime } from '../actions/global';
import Moment from 'react-moment';
import Helmet from 'react-helmet';
import TextareaAutosize from 'react-textarea-autosize';
import { FileUpload } from 'ix-redux-file-upload';
import { likeFeed } from '../actions/notification';
import ImageWrapper from '../components/helpers/image-wrapper';
import UserBreadcrumb from '../components/UserBreadcrumb/UserBreadcrumb';
import {fetchFeedpostIfNeeded, changeFeedPost, updateFeedPost} from '../actions/notification';
import Gallery from "../components/Gallery/Gallery";
import Comments from '../components/Comments/Comments';
import CustomSlidingPane from '../components/CustomSlidingPane/CustomSlidingPane';
import config from '../config/config'
import ProgressiveImager from '../components/helpers/progressive-imager';
import NumericLabel from 'react-pretty-numbers';
import './Pages.scss';

/* ICONS */
import commentIcon from '../images/icons/frontend/icon-comment.svg';
import cameraIcon from '../images/icons/frontend/icon-camera-red.svg';
import heartIcon from "../images/icons/frontend/icon-heart.svg";
import heartIconActive from "../images/icons/frontend/icon-active-heart.svg";

const nl2br = require('react-nl2br');


class LivePostMeta extends Component {
    render() {
        const {feedpost} = this.props;

        const calendarStrings = {
            lastDay : '[Gestern um] HH:mm',
            sameDay : '[Heute um] HH:mm',
            nextWeek: 'DD.MM.YYYY',
            lastDay: 'DD.MM.YYYY',
            lastWeek: 'DD.MM.YYYY',
            sameElse : 'DD.MM.YYYY',
        }

        var calendarStringsEdit = {
            lastDay : '[Gestern um] HH:mm',
            sameDay : '[Heute um] HH:mm',
            nextWeek: '[am] DD.MM.YYYY',
            lastDay: '[am] DD.MM.YYYY',
            lastWeek: '[am] DD.MM.YYYY',
            sameElse : '[am] DD.MM.YYYY',
        }

        // Same date
        var showedittime = true;
        var create = new Date(feedpost.createDate * 1000).setHours(0, 0, 0, 0);
        var edit = new Date(feedpost.editDate * 1000).setHours(0, 0, 0, 0);
        var days = (new Date(create)).setDate((new Date(create)).getDate() + 2);
        if(create === edit) {
            calendarStringsEdit = {
                lastDay : '[um] HH:mm',
                sameDay : '[um] HH:mm',
                nextWeek: '[um] HH:mm',
                lastDay: '[um] HH:mm',
                lastWeek: '[um] HH:mm',
                sameElse : '[um] HH:mm',
            }

            if((new Date()) > days){
                showedittime = false;
            }
        }

        return (
            <div className="meta-info">
                <Moment local calendar={calendarStrings} unix>{feedpost.createDate}</Moment>
                <Label color="black" className="newsfeed-label">
                    Newsfeed
                </Label>
                {feedpost.editDate > 0 &&
                    <div className="feed-edited"> [Bearbeitet{showedittime && <> <Moment style={{margin: '0'}} local calendar={calendarStringsEdit} unix>{feedpost.editDate}</Moment></>}]</div>
                }
            </div>
        );
    }
}

export { LivePostMeta }

class LivePost extends Component {

    state = {
        galleryOpen: false,
        currentSlide: 1,
        slidingPane: {
            isOpen: false,
            title: "",
            content: "",
        },
        edit: false,
    }

    commentref = React.createRef();

    componentDidMount() {
        const { dispatch, match, item, location } = this.props;
        dispatch(footerActiveItem("news"));
        dispatch(handleHeaderItems("newsfeed"));
        dispatch(setRuntime({page: 'fe-feedpost-id-'+match.params.id}));
        dispatch(fetchFeedpostIfNeeded(match.params.id));

        if(location.hash === '#comments') {
            this.handleSlidingPanes(true, "Kommentare", "comments", "feedpost::"+match.params.id)
        }

        if(location.hash === '#edit') {
            this.setState({edit: true});
        }

        if (item && item.id) {
            dispatch(handleContainer("secondary", "Live-Post von "+item.userData.name+" am "+config.getFormattedDate(item.createDate), true));
        }
        else {
            dispatch(handleContainer("secondary", "Live-Post", true));
        }
    }

    componentDidUpdate = (prevProps) => {
        const { dispatch, item, isUpdating, updatingMessage } = this.props;

        if (
            (!prevProps.item || !prevProps.item.id) &&
            item && item.id
        ) {
            dispatch(handleContainer("secondary", "Live-Post von "+item.userData.name+" am "+config.getFormattedDate(item.createDate), true));
        }

        if (prevProps.isUpdating && !isUpdating && updatingMessage === 'SUCCESS') {
            this.setState({
                edit: false,
            });
        }
    }


    handleSlidingPanes = (isOpen, title, content, parent) => {
        this.setState({
            slidingPane: {
                isOpen,
                title,
                content,
                parent,
            }
        })
    }

    handleGallery = (current, id) => {
        this.setState({
            currentSlide: current,
            galleryOpen: id
        })
    }

    handleLikeNewsfeed = (id, value, count) => {
        const { dispatch, isNewsfeedLiking } = this.props;
    
        if (!isNewsfeedLiking) {
          dispatch(likeFeed(id, value, count));
        }
      };

    handleInput(e, id) {
        const { dispatch, item } = this.props;
        dispatch(changeFeedPost(id, e.target.value, item.inputMedias));
    }

    handleDeleteImage(id) {
        const { dispatch, item } = this.props;
        let updatedArray = [];

        item.inputMedias.filter(m => m.id !== id).map((m, i) => {
            updatedArray.push(m);
        })

        dispatch(changeFeedPost(item.id, item.inputText, updatedArray));
    }

    render() {
        const { dispatch, isFetching, match, item, userItem, token, isUpdating, updatingMessage } = this.props;
        const { galleryOpen, currentSlide, edit } = this.state;

        if (!(item && item.id) && isFetching) {
            return (
                <Container text>
                    <Helmet>
                        <title>Live-Post #{match.params.id}</title>
                    </Helmet>
                    <Divider hidden/>
                    {this.placeholder()}
                </Container>
            );
        }
        else if (item && item.id) {
            return (
                <>
                    <Helmet>
                        <title>Live-Post von {item.userData.name} am {config.getFormattedDate(item.createDate)}</title>
                        <meta name="description" content={item.text === null || item.text === '' ? "Live-Post von "+item.userData.name+" am "+config.getFormattedDate(item.createDate) : item.text.substring(0, 150)} />
                    </Helmet>

                    <Container text>
                        <Divider hidden/>
                        <Responsive as={Divider} {...Responsive.onlyMobile}/>
                        <Segment className="newsfeed-content">
                            <div className="newsfeed-inside">
                                <UserBreadcrumb
                                  meta={true}
                                  user={item.userData}
                                  userId={item.user}
                                  project={{
                                    id: item.project && item.project,
                                    title: item.project && item.projectData.title,
                                    urlslug: item.project && item.projectData.urlslug,
                                    active: item.project && item.projectData.active,
                                    linktype: item.project && item.projectData.linktype
                                }}
                                />
                                <LivePostMeta feedpost={item} />

                                <div className="newsfeed-description">
                                    {!edit &&
                                        <>
                                            {nl2br(item.text)}
                                            {userItem && item.user === userItem.id &&
                                                <Icon className="edit-feedpost" name="pencil" circular inverted color="grey" onClick={() => this.setState({edit: true})} />
                                            }
                                        </>
                                    }
                                    {edit &&
                                    <Form>
                                        <Form.Field>
                                            <TextareaAutosize
                                                className="fullWidth"
                                                minRows="5"
                                                maxRows="15"
                                                autoComplete="nope"
                                                textContentType="oneTimeCode"
                                                type="text"
                                                value={(item.inputText ? item.inputText : item.text)}
                                                ref={(tag) => (this.textarea = tag)}
                                                onChange={(e) => {
                                                    this.handleInput(e, item.id)
                                                }}
                                                disabled={isUpdating ? true : false}
                                            />
                                        </Form.Field>
                                    </Form>
                                    }
                                </div>

                                {!edit && item.medias && item.medias.length > 0 &&
                                    <>
                                        {item.medias.length === 1 &&
                                            <div className="newsfeed-image">
                                                <div className="feed-image" onClick={() => this.handleGallery(0, item.id)}>
                                                    <ProgressiveImager 
                                                        className="responsive" 
                                                        src={config.getImageUrl('main', item.medias[0].path)}
                                                        alt={
                                                            (item.text
                                                              ? item.text.substring(
                                                                  0,
                                                                  50
                                                                )
                                                              : "Newsfeed") +
                                                            " – Bild: " +
                                                            item.medias[0].id
                                                          }
                                                    />
                                                </div>
                                            </div>
                                        }

                                        {item.medias.length > 1 &&

                                            <div className="newsfeed-images">
                                                <div className="feed-images">
                                                    <div className="first" onClick={() => this.handleGallery(0, item.id)}>
                                                        <ProgressiveImager 
                                                            className="responsive"
                                                            src={config.getImageUrl('main', item.medias[0].path)}
                                                            alt={
                                                                (item.text
                                                                  ? item.text.substring(
                                                                      0,
                                                                      50
                                                                    )
                                                                  : "Newsfeed") +
                                                                " – Bild: " +
                                                                item.medias[0].id
                                                              }
                                                        />
                                                    </div>
                                                    {item.medias[1] &&
                                                        <div className="second" onClick={() => this.handleGallery(1, item.id)}>
                                                            <ProgressiveImager 
                                                                className="responsive"
                                                                src={config.getImageUrl('main', item.medias[1].path)}
                                                                alt={
                                                                    (item.text
                                                                      ? item.text.substring(
                                                                          0,
                                                                          50
                                                                        )
                                                                      : "Newsfeed") +
                                                                    " – Bild: " +
                                                                    item.medias[1].id
                                                                  }    
                                                            />
                                                            {item.medias.length > 2 &&
                                                                <div className="image-count-overlay">
                                                                    <div className="image-count">+{item.medias.length-2}</div>
                                                                </div>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            </div>

                                        }

                                        {/* Gallery include */}

                                        <Responsive {...Responsive.onlyMobile}>
                                            <Gallery
                                                images={item.medias.map(img =>
                                                    config.getImageUrl('zoom', img.path),
                                                )}
                                                slide={currentSlide}
                                                galleryOpen={galleryOpen === item.id}
                                                onClose={() => this.setState({galleryOpen: false})}
                                                onMovePrevRequest={() =>
                                                    this.setState({
                                                        currentSlide: (currentSlide + item.medias.length - 1) % item.medias.length,
                                                    })
                                                  }
                                                onMoveNextRequest={() =>
                                                    this.setState({
                                                        currentSlide: (currentSlide + 1) % item.medias.length,
                                                    })
                                                }
                                            />
                                        </Responsive>
                                        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                                            <Gallery
                                                images={item.medias.map(img =>
                                                    config.getImageUrl('zoomDesktop', img.path)

                                                )}
                                                slide={currentSlide}
                                                galleryOpen={galleryOpen === item.id}
                                                onClose={() => this.setState({galleryOpen: false})}
                                                onMovePrevRequest={() =>
                                                    this.setState({
                                                        currentSlide: (currentSlide + item.medias.length - 1) % item.medias.length,
                                                    })
                                                }
                                                onMoveNextRequest={() =>
                                                    this.setState({
                                                        currentSlide: (currentSlide + 1) % item.medias.length,
                                                    })
                                                }
                                            />
                                        </Responsive>

                                    </>

                                }

                                {edit &&
                                    <>
                                        <FileUpload
                                          headers={{ 'Authorization': 'Bearer '+token }}
                                          allowedFileTypes={['jpg', 'jpeg']}
                                          dropzoneId={"feedpost."+item.id+".inputMedias"}
                                          multiple={true}
                                          url={config.API_HOST + config.API_UPLOAD}
                                        >
                                            <div className="button-element feed-add-images">
                                                <Button basic color="red" className="single-button">
                                                    <Image src={cameraIcon} className="button-icon"/>
                                                    <span>Fotos hinzufügen</span>
                                                </Button>
                                            </div>
                                        </FileUpload>
                                        <Divider hidden/>

                                        {item.inputMedias &&
                                            <div className="content-gallery-imgs">
                                                {item.inputMedias.map((m) => (
                                                    <ImageWrapper className="content-gallery-wrapper no-description" path={m.path}>
                                                        <ProgressiveImager src={config.getImageUrl('main', m.path)}/>
                                                        <div className="content-gallery-delete">
                                                            <Icon name="times" onClick={() => this.handleDeleteImage(m.id)}/>
                                                        </div>
                                                    </ImageWrapper>
                                                ))}
                                            </div>
                                        }
                                        <Divider hidden/>

                                        {updatingMessage === 'FAILURE' &&
                                            <Message negative>Beim Übertragen der Daten ist ein Fehler aufgetreten.</Message>
                                        }

                                        <Grid textAlign="center">
                                            <Grid.Column width={16}>
                                                <Button
                                                    color="red"
                                                    size="mini"
                                                    loading={isUpdating ? true : false}
                                                    disabled={!item.inputText || (item.text === item.inputText && JSON.stringify(item.medias) === JSON.stringify(item.inputMedias)) ? true : false}
                                                    onClick={(e) => {
                                                        dispatch(updateFeedPost(item));
                                                    }}
                                                >
                                                    Speichern
                                                </Button>
                                            </Grid.Column>
                                        </Grid>
                                    </>
                                }

                            </div>
                            <Divider/>
                            <Grid className="social-buttons">
                                <Grid.Column width={8} textAlign="left">
                                <span
                                className="icons-newsfeed"
                                onClick={() =>
                                    userItem && userItem.id
                                    ? this.handleLikeNewsfeed(
                                        item.id,
                                        !item.userLike,
                                        item.countLike
                                      )
                                    : this.setState({ loginPopup: true })
                                }
                              >
                                <Image
                                  src={
                                    item.userLike
                                      ? heartIconActive
                                      : heartIcon
                                  }
                                  alt="Gefällt mir"
                                  title="Gefällt mir"
                                  className="icon-newsfeed-social"
                                />
                                <span className="social-amount fontsize-small-m font-semi">
                                  <NumericLabel params={config.PRETTY_CONFIG}>
                                    {item.countLike}
                                  </NumericLabel>
                                </span>
                              </span>
                                    <span className="icons-newsfeed" onClick={() => this.setState({ slidingPane: { isOpen: true } })}>
                                        <Image alt="Kommentieren" title="Kommentieren" src={commentIcon} className="icon-newsfeed-social"/>
                                            <span className="social-amount fontsize-small-m font-semi">
                                                {item.commentcount}
                                            </span>
                                    </span>
                                </Grid.Column>

                                {/* Comments - Mobile */}
                                <Grid.Column width={8} className="comment-btn" onClick={() => this.handleSlidingPanes(true, "Kommentare", "comments", "feedpost::"+item.id)}>
                                     <div className="fontsize-small-m font-semi">Kommentieren</div>
                                </Grid.Column>

                            </Grid>

                        </Segment>
                        <Divider hidden/>
                    </Container>

                    <Responsive
                        as={CustomSlidingPane}
                        maxWidth={Responsive.onlyTablet.maxWidth}
                        isOpen={this.state.slidingPane.isOpen}
                        title="Kommentare"
                        onRequestClose={() => this.setState({slidingPane: { isOpen: false }})}
                    >
                        <Comments parent={"feedpost::"+ match.params.id} />
                    </Responsive>
                    
                    <Responsive
                        as={CustomSlidingPane}
                        minWidth={Responsive.onlyComputer.minWidth}
                        from='right'
                        isOpen={this.state.slidingPane.isOpen}
                        title="Kommentare"
                        onRequestClose={() => this.setState({slidingPane: { isOpen: false }})}
                    >
                        <Comments parent={"feedpost::"+ match.params.id} />
                    </Responsive>
                </>
            )
        }
        else {
            return (
                <Container text>
                    <Divider hidden/>
                    <Message negative>Es ist ein Fehler aufgetreten.</Message>
                </Container>
            )
        }
    }

    placeholder() {
        return (
            <>

                <Divider hidden/>
                <Segment className="newsfeed-content newsfeed-content-placeholder" >
                    <div>
                        <div className="newsfeed-inside">
                            <div className="newsfeed-image">
                                <Placeholder>
                                    <Placeholder.Image/>
                                </Placeholder>
                            </div>
                        </div>

                        <div className="newsfeed-description" style={{marginTop: "1rem"}}>
                            <Placeholder>
                                <Placeholder.Paragraph>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Paragraph>
                                <Placeholder.Paragraph>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Paragraph>
                            </Placeholder>
                        </div>
                        <Divider hidden/>

                            <Grid className="social-buttons">
                                <Grid.Column width={8} textAlign="left">
                                    <div>
                                        <span className="icons-newsfeed">
                                            <Image src={commentIcon} className="icon-newsfeed-social"/>
                                            <span className="social-amount fontsize-small-m font-semi">-</span>
                                        </span>
                                    </div>
                                </Grid.Column>

                                <Grid.Column width={8} className="comment-btn placeholder" >
                                    <div className="fontsize-small-m font-semi">Kommentieren</div>
                                </Grid.Column>

                            </Grid>
                    </div>
                </Segment>

            </>
        )
    }
}

function mapStateToProps(state, ownProps) {

    const { feedpostById, account, commentsByParent } = state;

    const { isFetching, isFailed, lastUpdated, lastFetch, item, isUpdating, updatingMessage } = feedpostById[
        ownProps.match.params.id
    ] || {
        isFetching: true,
        item: {},
    }
    const { lastUpdated: commentsLastUpdated, isFetching: commentsFetching } = commentsByParent[
        "feedpost::" + ownProps.match.params.id
    ] || {
        commentsLastUpdated: '',
        commentsFetching: true
    }

    const { item: userItem, token } = account;

    return {
        userItem,
        token,
        isFetching,
        isFailed,
        lastUpdated,
        lastFetch,
        item,
        isUpdating,
        updatingMessage,
        commentsLastUpdated,
        commentsFetching
    };
}

export default connect(mapStateToProps)(LivePost)
