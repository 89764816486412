import {
    CHANGE_EXPENSE,
    NEW_EXPENSE,
    API_EXPENSE_REQUEST,
    API_EXPENSE_SUCCESS,
    API_EXPENSE_FAILURE,
    API_EXPENSE_DELETE_REQUEST,
    API_EXPENSE_DELETE_SUCCESS,
    API_EXPENSE_DELETE_FAILURE,
    API_EXPENSE_LIST_REQUEST,
    API_EXPENSE_LIST_SUCCESS,
    API_EXPENSE_LIST_FAILURE,
    API_POST_EXPENSE_REQUEST,
    API_POST_EXPENSE_SUCCESS,
    API_POST_EXPENSE_FAILURE,
} from '../actions/expense'
import {
    RESET_STORE,
} from '../actions/account'
import {actions} from 'ix-redux-file-upload';


export default function expensesByParent(state = {}, action) {

    try {

        if (action.type === RESET_STORE) {
            return {};
        }

        if (action.meta && action.meta.project) {
            var key = 'project::'+action.meta.project;
        }

        if (action.type === CHANGE_EXPENSE) {
            return {
                ...state,
                [key]: {
                    ...state[key],
                    isDirty: true,
                    items: {
                        ...state[key].items,
                        ...{
                            [action.payload.item.id]: {
                                ...action.payload.item,
                                isDirty: true,
                            },
                        },
                    },
                }
            };
        }
        else if (action.type === NEW_EXPENSE) {
            return {
                ...state,
                [key]: {
                    ...state[key],
                    ...action.payload,
                }
            };
        }

        else if (action.type === API_EXPENSE_REQUEST) {
            return {
                ...state,
                [key]: {
                    ...state[key],
                    isFetching: true,
                    isDeleting: false,
                    lastFetch: Date.now(),
                }
            };
        }

        else if (action.type === API_EXPENSE_DELETE_REQUEST) {
            return {
                ...state,
                [key]: {
                    ...state[key],
                    isFailed: false,
                    isDeleting: true,
                }
            }
        }
        else if (action.type === API_EXPENSE_DELETE_SUCCESS) {
            var items = {...state[key].items};
            delete items[action.meta.id];

            return {
                ...state,
                [key]: {
                    ...state[key],
                    isFailed: false,
                    isDeleting: false,
                    items: {
                        ...items,
                    },
                }
            };
        }
        else if (action.type === API_EXPENSE_DELETE_FAILURE) {
            return {
                ...state,
                [key]: {
                    ...state[key],
                    isDeleting: false,
                    isFailed: true,
                }
            };
        }

        else if (
            action.type === API_EXPENSE_SUCCESS ||
            action.type === API_POST_EXPENSE_SUCCESS
        ) {
            state = {
                ...state,
                [key]: {
                    ...state[key],
                    isDirty: false,
                    isFetching: false,
                    isFailed: false,
                    isDeleting: false,
                    items: {
                        ...state[key].items,
                        ...{
                            [action.payload.expense.id]: {
                                ...action.payload.expense,
                                isDirty: false,
                                input: JSON.parse(JSON.stringify(action.payload.expense)),
                            },
                        },
                    },
                    lastUpdated: Date.now(),
                }
            };

            if (action.type === API_POST_EXPENSE_SUCCESS) {
                state = {
                    ...state,
                    [key]: {
                        ...state[key],
                        isPosting: false,
                        inputTitle: null,
                        inputDesc: null,
                        inputPrice: null,
                        inputBill: null,
                        inputPart: null,
                        inputMercant: null,
                        inputMedia: null,
                    }
                };
            }

            return state;
        }
        else if (action.type === API_EXPENSE_FAILURE) {
            return {
                ...state,
                [key]: {
                    ...state[key],
                    isFetching: false,
                    isFailed: true,
                    lastUpdated: Date.now(),
                }
            };
        }
        else if (action.type === API_EXPENSE_DELETE_SUCCESS) {
            var items = {...state[key].items};
            delete items[action.meta.id];

            return {
                ...state,
                [key]: {
                    ...state[key],
                    isFetching: false,
                    isFailed: false,
                    items: {
                        ...items,
                    },
                }
            };
        }

        else if (action.type === API_EXPENSE_LIST_REQUEST) {
            return {
                ...state,
                [key]: {
                    ...state[key],
                    isFetching: true,
                    lastFetch: Date.now(),
                }
            };
        }
        else if (action.type === API_EXPENSE_LIST_SUCCESS) {
            // Set keys
            var items = {};
            if (Array.isArray(action.payload)) {
                action.payload.map(function(item) {
                    items[item.id] = {
                        ...item,
                        isDirty: false,
                        input: JSON.parse(JSON.stringify(item)),
                    };
                });
            }

            return {
                ...state,
                [key]: {
                    ...state[key],
                    isDirty: false,
                    isFetching: false,
                    isFailed: false,
                    items: {
                        ...items,
                    },
                    lastUpdated: Date.now(),
                }
            };
        }
        else if (action.type === API_EXPENSE_LIST_FAILURE) {
            return {
                ...state,
                [key]: {
                    ...state[key],
                    isFetching: false,
                    isFailed: true,
                    lastUpdated: Date.now(),
                }
            };
        }

        else if (action.type === API_POST_EXPENSE_REQUEST) {
            return {
                ...state,
                [key]: {
                    ...state[key],
                    isPosting: true,
                }
            };
        }
        else if (action.type === API_POST_EXPENSE_FAILURE) {
            return {
                ...state,
                [key]: {
                    ...state[key],
                    isPosting: false,
                }
            };
        }

        else if (action.type === actions.FILE_UPLOAD_PROGRESS) {
            var identificator = action.payload.identificator.split('.');
            // var key = identificator[0]+'::'+identificator[1];
            var key = 'project::'+identificator[1];

            if (identificator[0] === 'expensesByProject' && identificator[2] === 'expense') {
                var newItems = {...state[key].items};

                for (let [k, c] of Object.entries(newItems)) {
                    if (c.id === parseInt(identificator[3])) {

                        if (!newItems[k].input[identificator[4]]) {
                            newItems[k].input[identificator[4]] = [];
                        }

                        var updated = false;
                        if(newItems[k].input[identificator[4]]){
                            newItems[k].input[identificator[4]].map((item, index) => {
                                if(item.id == action.payload.fileType.uid && !updated){
                                    item.progress = action.payload.progress;
                                    updated = true;
                                }
                            })
                        }

                        
                        if(!updated) {
                            newItems[k].input[identificator[4]].push({
                                id: action.payload.fileType.uid,
                                progress: action.payload.progress,
                                uploadPosition: newItems[k].input[identificator[4]].length +1,
                                path: 'LOADING',
                                type: null,
                            });
                        }

                    }
                };

                return {
                    ...state,
                    [key]: {
                        ...state[key],
                        isDirty: true,
                        items: {
                            ...newItems,
                        },
                    }
                };
            }
            else if (identificator[0] === 'expensesByProject') {
                var media = state[key][identificator[2]];

              if (!media) {
                    media = [];
                }

                var updated = false;
                if(media){
                    media.map((item, index) => {
                        if(item.id == action.payload.fileType.uid && !updated){
                            media[index].progress = action.payload.progress;
                            updated = true;
                        }
                    })
                }

                if(!updated) {
                    media.push({
                        id: action.payload.fileType.uid,
                        progress: action.payload.progress,
                        path: 'LOADING',
                        previewPath: "LOADING",
                        position: media.length +1,
                        type: action.payload.isImage ? 1 : 2,
                    });
                }

                state = {
                    ...state,
                    [key]: {
                        ...state[key],
                        isDirty: true,
                        [identificator[2]]: media,
                    }
                }

                return state;
            }
        }
        else if (action.type === actions.FILE_UPLOAD_COMPLETE) {
            var identificator = action.payload.identificator.split('.');
            // var key = identificator[0]+'::'+identificator[1];
            var key = 'project::'+identificator[1];

            if (identificator[0] === 'expensesByProject' && identificator[2] === 'expense') {
                var newItems = {...state[key].items};
                var removedLoading = false;

                for (let [k, c] of Object.entries(newItems)) {
                    if (c.id === parseInt(identificator[3])) {
                        newItems[k].input[identificator[4]].map((mv, mk) => {
                            if (mv.path === 'LOADING' && mv.progress === 100 && mk+1 === mv.uploadPosition && !removedLoading) {
                                console.log(mk);
                                newItems[k].input[identificator[4]][mk] = {
                                    ...mv,
                                    id: action.payload.response.id,
                                    path: action.payload.response.path,
                                    type: action.payload.response.type,
                                }
                                removedLoading = true;
                                newItems[k]['isDirty'] = true;
                            }
                        });
                    }
                };

                return {
                    ...state,
                    [key]: {
                        ...state[key],
                        isDirty: true,
                        items: {
                            ...newItems,
                        },
                    }
                };
            }
            else if (identificator[0] === 'expensesByProject') {
                var media = state[key][identificator[2]];

                if (!media) {
                    media = [];
                }

                var removedLoading = false;
                media.map((mv, mk) => {
                    if ((mv.path === 'LOADING' && mk+1 === mv.position && mv.progress === 100) && !removedLoading) {
                        media[mk] = {
                            ...media[mk],
                            id: action.payload.response.id,
                            path: action.payload.response.path,
                            type: action.payload.response.type,
                            previewPath: action.payload.response.type === 2 ? action.payload.response.previewPath : null
                        };
                        removedLoading = true;
                    }
                });

                state = {
                    ...state,
                    [key]: {
                        ...state[key],
                        isDirty: true,
                        [identificator[2]]: media,
                    }
                }

                return state;
            }
        }

        return state;

    } catch (e) {

        console.log(e);
        return {
            ...state,
            fatalError: true,
        };

    }
}
