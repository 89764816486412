import React, { Component } from 'react';
import { Grid, Divider, Container, Image, Header } from 'semantic-ui-react';
import { Link } from "react-router-dom";
import './functions.scss';

//Gifs
// import ArbeitszeitenGif from '../../images/about-page/gif/Arbeitszeiten_bearb.gif';
// import AufgabenGif from '../../images/about-page/gif/Aufgaben_bearb.gif';
// import AusgabenGif from '../../images/about-page/gif/Ausgaben_bearbw.gif';
// import DashboardGif from '../../images/about-page/gif/Dashboard_bearbe.gif';
// import EinstellungenGif from '../../images/about-page/gif/Einstellungen_bearb.gif';
// import ErsatzteileGif from '../../images/about-page/gif/Ersatzteile_bearb.gif';
// import FahrzeugGif from '../../images/about-page/gif/Fahrzeug_bearb.gif';
// import GutscheinGif from '../../images/about-page/gif/Gutscheine_bearb.gif';
// import MontagehilfeGif from '../../images/about-page/gif/Montagehilfe_bearb.gif';
// import RechnungenGif from '../../images/about-page/gif/Rechnungen_bearb.gif';
// import StorysGif from '../../images/about-page/gif/Storys-1_bearb.gif';

//Videos
import StoryVid from '../../images/about-page/functions/story-bearbeiten1.mp4';
import ArbeitszeitenVid from '../../images/about-page/functions/Arbeitszeiten.mp4';
import AufgabenVid from '../../images/about-page/functions/Aufgaben.mp4';
import AusgabenVid from '../../images/about-page/functions/Ausgaben.mp4';
import DashboardVid from '../../images/about-page/functions/Dashboard.mp4';
import EinstellungenVid from '../../images/about-page/functions/Einstellungen.mp4';
import FahrzeugVid from '../../images/about-page/functions/Fahrzeug-bearbeiten.mp4';
import GutscheineVid from '../../images/about-page/functions/Gutscheine.mp4';
import Montagehilfe from '../../images/about-page/functions/Montagehilfe.mp4';
import RechnungenVid from '../../images/about-page/functions/Rechnungen-bearbeiten.mp4';
import TeileVid from '../../images/about-page/functions/Teile.mp4';

class FunctionsGifSection extends Component {

    render() {
        return (
            <Container className="guide-section">
            <Divider hidden/>
            <Grid>

                <Grid.Row>
                    <Grid.Column mobile={16} tablet={8} computer={8} verticalAlign="middle">
                        <Header as="h3">Das Dashboard – alles im Blick</Header>
                        <p>
                            Dein persönliches Dashboard zeigt Dir auf einen Blick Deine angelegten Fahrzeuge, ob es etwas Neues bei beobachteten Projekten gibt, ob neue Oldtimer-Projekte oder Fahrzeuge dazugekommen sind, ob es ungelesene Nachrichten oder Kommentare 
                            gibt und enthält zudem noch viele weitere Funktionen. 
                        </p>
                        {/* <Link className="desktop-link" to="/about/dashboard"><strong>Mehr zum Dashboard</strong></Link> */}
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                        {/* <Image loading="lazy" className="gif-element" src={DashboardGif} alt="Vetero Dashboard"/> */}
                        {/* <Link className="mobile-link" to="/about/dashboard"><strong>Mehr zum Dashboard</strong></Link> */}
                        <video autoPlay loop>
                            <source src={DashboardVid} type="video/mp4" />
                        </video>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row reversed="mobile tablet vertically">
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                        {/* <Image loading="lazy"  className="gif-element" src={FahrzeugGif} alt="Vetero Fahrzeug bearbeiten"/> */}
                        <video autoPlay loop>
                            <source src={FahrzeugVid} type="video/mp4" />
                        </video>
                        {/* <Link className="mobile-link" to="/about/dashboard"><strong> Mehr zum Bereich „Fahrzeug“</strong></Link> */}
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={8} verticalAlign="middle">
                        <Header as="h3">Fahrzeug mit Interview</Header>
                        <p>
                            Jedes Deiner Fahrzeuge hat seinen eigenen Bereich in dem u. a. die Fahrzeugdaten, 
                            das Projektziel und noch einige andere Angaben hinterlegt werden können. 
                        </p>
                        {/* <Link className="desktop-link" to="/about/dashboard"><strong> Mehr zum Bereich „Fahrzeug“</strong></Link> */}
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column mobile={16} tablet={8} computer={8} verticalAlign="middle">
                        <Header as="h3">Storys</Header>
                        <p>
                            Storys sind das am meisten unterschätzte Werkzeug in Vetero. Mit Storys kannst Du zum Beispiel die Fortschritte einer Restauration, eine Rallye oder andere Ereignisse 
                            bis ins kleinste Detail in Text, Bild und Video dokumentieren
                        </p>
                        <Link className="desktop-link story-link" to="/functions/storys"><strong>Mehr zu Storys</strong></Link>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                        <video autoPlay loop>
                            <source src={StoryVid} type="video/mp4" />
                        </video>
                        {/* <Image loading="lazy"  className="gif-element" src={StorysGif} alt="Vetero Storys"/> */}
                        <Link className="mobile-link story-link" to="/functions/storys"><strong>Mehr zu Storys</strong></Link>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row reversed="mobile tablet vertically">
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                        {/* <Image loading="lazy"  className="gif-element" src={ErsatzteileGif} alt="Vetero Ersatzteile"/> */}
                        {/* <Link className="mobile-link" to="/about/parts"><strong>Mehr zu „Ersatzteile“</strong></Link> */}
                        <video autoPlay loop>
                            <source src={TeileVid} type="video/mp4" />
                        </video>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={8} verticalAlign="middle">
                        <Header as="h3">Ersatzteile</Header>
                        <p>
                            Alle Teile, die Du für ein Projekt benötigst, kannst Du hier mit Text und Bild hinterlegen. 
                            Auf der Veterama hast Du somit alles dabei;-)  
                        </p>
                        {/* <Link className="desktop-link" to="/about/parts"><strong>Mehr zu „Ersatzteile“</strong></Link> */}
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column mobile={16} tablet={8} computer={8} verticalAlign="middle">
                        <Header as="h3">Ausgaben</Header>
                        <p>
                            Gekaufte Teile können hier mit Angaben zum Händler und Preis hinterlegt werden. 
                            Damit behältst Du den vollen Überblick über die gesamten Projektkosten.  
                        </p>
                        {/* <Link className="desktop-link" to="/about/expenditure"><strong>Mehr zu Ausgaben</strong></Link> */}
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                        <video autoPlay loop>
                            <source src={AusgabenVid} type="video/mp4" />
                        </video>
                        {/* <Image loading="lazy" className="gif-element" src={AusgabenGif} alt="Vetero Ausgaben"/> */}
                        {/* <Link className="mobile-link" to="/about/expenditure"><strong>Mehr zu Ausgaben</strong></Link> */}
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row reversed="mobile tablet vertically">
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                        <video autoPlay loop>
                            <source src={RechnungenVid} type="video/mp4" />
                        </video>
                        {/* <Image className="gif-element" src={RechnungenGif} alt="Vetero Rechnungen"/> */}
                        {/* <Link className="mobile-link" to="/about/bills"><strong>Mehr zu Rechnungen</strong></Link> */}
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={8} verticalAlign="middle">
                        <Header as="h3">Rechnungen</Header>
                        <p>
                            Alle Rechnungen von Ersatzteilen oder Leistungen kannst Du zum Fahrzeug hinzufügen. Damit kannst Du jederzeit belegen, 
                            was verbaut und gemacht wurde.  
                        </p>
                        {/* <Link className="desktop-link" to="/about/bills"><strong>Mehr zu Rechnungen</strong></Link> */}
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column mobile={16} tablet={8} computer={8} verticalAlign="middle">
                        <Header as="h3">Aufwand/Arbeitszeit</Header>
                        <p>
                            Alles braucht seine Zeit und oft gibt es längere Pausen. Lasse Dich überraschen wie viel Zeit Du tatsächlich in ein Projekt Oldtimer-Projekt 
                            investiert hast.   
                        </p>
                        {/* <Link className="desktop-link" to="/about/workinghours"><strong>Mehr zu Arbeitszeit</strong></Link> */}
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                        <video autoPlay loop>
                            <source src={ArbeitszeitenVid} type="video/mp4" />
                        </video>
                        {/* <Image loading="lazy"  className="gif-element" src={ArbeitszeitenGif} alt="Vetero Arbeitszeit"/> */}
                        {/* <Link className="mobile-link" to="/about/workinghours"><strong>Mehr zu Arbeitszeit</strong></Link> */}
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row reversed="mobile tablet vertically">
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                        <video autoPlay loop>
                            <source src={AufgabenVid} type="video/mp4" />
                        </video>
                        {/* <Image className="gif-element" src={AufgabenGif} alt="Vetero Aufgaben"/> */}
                        {/* <Link className="mobile-link" to="/about/todos"><strong>Mehr zu Aufgaben</strong></Link> */}
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={8} verticalAlign="middle">
                        <Header as="h3">Aufgaben</Header>
                        <p>
                            Es gibt vieles, dass man später noch erledigen muss oder möchte. Das ist der Platz, an dem Du Dir solche Dinge merken kannst.
                        </p>
                        {/* <Link className="desktop-link" to="/about/todos"><strong>Mehr zu Aufgaben</strong></Link> */}
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column mobile={16} tablet={8} computer={8} verticalAlign="middle">
                        <Header as="h3">Montagehilfe</Header>
                        <p>
                            Auseinanderbauen ist schön und gut. Aber irgendwann später muss das alles auch wieder richtig zusammenpassen. Schreibe Wichtiges auf und hinterlege
                            zusätzlich Bilder oder Videos.
                        </p>
                        {/* <Link className="desktop-link" to="/about/assembly"><strong>Mehr zur Montagehilfe</strong></Link> */}
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                        <video autoPlay loop>
                            <source src={Montagehilfe} type="video/mp4" />
                        </video>
                        {/* <Image loading="lazy"  className="gif-element" src={MontagehilfeGif} alt="Vetero Montagehilfe"/> */}
                        {/* <Link className="mobile-link" to="/about/assembly"><strong>Mehr zur Montagehilfe</strong></Link> */}
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row reversed="mobile tablet vertically">
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                        <video autoPlay loop>
                            <source src={EinstellungenVid} type="video/mp4" />
                        </video>
                        {/* <Image loading="lazy"  className="gif-element" src={EinstellungenGif} alt="Vetero Einstellungen"/> */}
                        {/* <Link className="mobile-link" to="/about/settings"><strong>Mehr zu Einstellungen</strong></Link> */}
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={8} verticalAlign="middle">
                        <Header as="h3">Einstellungen</Header>
                        <p>
                            Vielleicht möchtest Du nicht alle Fahrzeuge/Storys veröffentlichen oder die Kosten und den Zeitaufwand für Dich behalten. 
                            Hier kannst Du es einstellen.
                        </p>
                        {/* <Link className="desktop-link" to="/about/settings"><strong>Mehr zu Einstellungen</strong></Link> */}
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column mobile={16} tablet={8} computer={8} verticalAlign="middle">
                        <Header as="h3">Gutscheine</Header>
                        <p>
                            Wenn Du genügend Dokumentiert und veröffentlicht hast, soll es Dein Schaden nicht sein. Echte Schrauber erhalten daher Gutscheine.
                        </p>
                        {/* <Link className="desktop-link" to="/about/voucher"><strong>Mehr zu den Gutscheinen</strong></Link> */}
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                        <video autoPlay loop>
                            <source src={GutscheineVid} type="video/mp4" />
                        </video>
                        {/* <Image loading="lazy"  className="gif-element" src={GutscheinGif} alt="Vetero Gutscheine"/> */}
                        {/* <Link className="mobile-link" to="/about/voucher"><strong>Mehr zu den Gutscheinen</strong></Link> */}
                    </Grid.Column>
                </Grid.Row>

            </Grid>
            <Divider hidden/>
        </Container>
        )
    }
}

export default FunctionsGifSection
