import React from 'react'
import { Image, Container } from 'semantic-ui-react'
import { withServiceWorkerUpdater } from '@3m1/service-worker-updater';

const SWUpdater = (props) => {
  const { newServiceWorkerDetected, onLoadNewServiceWorkerAccept, icon, resetStore } = props
  
  const updateApp = () => {
    onLoadNewServiceWorkerAccept();
    resetStore();
  }

  return newServiceWorkerDetected ? (


        <div class="popup-new-version">
          <Container>
            <div className="popup-new-version-inside">
            <div className="app-icon">
                <Image src={icon} alt="Vetero Oldtimer Portal Logo" />
            </div>
            <div className="new-version-msg" onClick={updateApp}>
              <p>Eine neue Version ist verfügbar!</p>
              <p>Klicke hier um die App zu aktualisieren.</p>
            </div>
            </div>
          </Container>
        </div>
    
  ) : null // If no update is available, render nothing
}

export default withServiceWorkerUpdater(SWUpdater)