import React, { Component } from 'react';
import { Grid, Image, Segment, Label, Card, Placeholder, Divider, Header, Responsive, Message, Button, Container } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { handleContainer, handleHeaderItems, setRuntime } from '../../actions/global'
import { Link, withRouter, Redirect } from 'react-router-dom';
import config from '../../config/config';
import ProgressiveImager from '../../components/helpers/progressive-imager';
import NumericLabel from 'react-pretty-numbers'
import './dashboard.scss'
//Icons
import commentIcon from '../../images/icons/frontend/icon-comment.svg';
import eurIcon from '../../images/icons/frontend/icon-euro-circle.svg';
import clockIcon from '../../images/icons/frontend/icon-clock-circle.svg';
import heartIcon from '../../images/icons/frontend/icon-heart.svg';

class OwnProjects extends Component {

    state = {
        redirect: false
    }

    componentDidMount = () => {
        const { dispatch } = this.props;

        window.addEventListener('resize', this.mobileRedirect);
        this.mobileRedirect();

        // Set back path
        dispatch(setRuntime({
            backProject: {page: 'be-project-list', path: this.props.location},
        }));
    }

    componentWillUnmount = () => {
        const { dispatch } = this.props;
        window.removeEventListener('resize', this.mobileRedirect);
        dispatch(handleContainer("backend", "Dashboard", true));
    }

    mobileRedirect = () => {
        const { match } = this.props;
        const { dispatch } = this.props;

        if(config.getDeviceType() === 'mobile' && match.url === '/backend/ownprojects'){
            this.setState({redirect: true});
        }
        else {
            this.setState({redirect: false});
            dispatch(handleContainer("secondary", "Fahrzeuge", true));
            dispatch(handleHeaderItems("dashboard"));
            dispatch(setRuntime({page: 'be-project-list'}));
            window.scrollTo(0, 0);
        }
    }

    handleCloseSlidingPane = () => {
        const { close, dispatch } = this.props;
        if(config.getDeviceType() === 'mobile') {
            close();
        }
    }

    render() {
        const { listitem, userProjectsIsFetching } = this.props;
        const { redirect } = this.state;

        return (

                 <>
                        {listitem && listitem.map((item, index) =>
                        <Link to={'/backend/project/' +`${item.item.id}`}>
                            <Segment key={index} className="own-projects-list" onClick={() => this.handleCloseSlidingPane()}>
                                <Grid>
                                    <Grid.Column mobile={7} tablet={5} computer={5}>
                                        <div className="item-img">
                                            <ProgressiveImager src={config.getImageUrl('halfwidth', item.item.mediamainPath)}/>
                                            {item.item.active ? <Label className="font-bold" color="green">Veröffentlicht</Label>  : <Label color="red">Geparkt</Label> }
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column width={9} style={{paddingLeft: 0}}>
                                        <div className="item-description">
                                            <Header as="h3">
                                                {item.item.title}
                                            </Header>
                                        </div>
                                        <Grid columns="equal" className="item-info">
                                            <Grid.Column>
                                                <div className="item-info-content">
                                                    <Image src={clockIcon}/>
                                                    <span className="info-content">
                                                        {item.item.sumWorkinghours ?
                                                            <NumericLabel params={config.PRETTY_NUMERIC_CONFIG}>{item.item.sumWorkinghours}</NumericLabel>
                                                            :
                                                            '0'
                                                        }
                                                    </span>
                                                </div>
                                                <div className="item-info-content">
                                                    <Image src={heartIcon}/>
                                                    <span className="info-content">
                                                        {item.item.countLike ?
                                                            <NumericLabel params={config.PRETTY_NUMERIC_CONFIG}>{item.item.countLike}</NumericLabel>
                                                            :
                                                            '0'
                                                        }
                                                    </span>
                                                </div>
                                            </Grid.Column>
                                            <Grid.Column>
                                                <div className="item-info-content">
                                                    <Image src={eurIcon}/>
                                                    <span className="info-content">
                                                        {item.item.sumPrice ?
                                                            <NumericLabel params={config.PRETTY_NUMERIC_CONFIG}>{item.item.sumPrice}</NumericLabel>
                                                            :
                                                            '0'
                                                        }
                                                    </span>
                                                </div>
                                                <div className="item-info-content">
                                                    <Image src={commentIcon}/>
                                                    <span className="info-content">
                                                        {item.item.countComment ?
                                                            <NumericLabel params={config.PRETTY_NUMERIC_CONFIG}>{item.item.countComment}</NumericLabel>
                                                            :
                                                            '0'
                                                        }
                                                    </span>
                                                </div>
                                            </Grid.Column>
                                        </Grid>
                                    </Grid.Column>
                                </Grid>
                            </Segment>
                        </Link>
                        )}
                        {(!listitem || !listitem.length) &&
                            <Container>
                                <Message color="teal">
                                    Du hast noch kein Projekt angelegt.
                                </Message>
                            </Container>
                        }
                        <Responsive minWidth={Responsive.onlyTablet.minWidth} as={Segment} basic textAlign={"center"}>
                            <Button as={Link} to="/backend/new_project/firststep" color='red'>Neues Fahrzeug</Button>
                        </Responsive>
                </>
            )
        }

}

function mapStateToProps(state, ownProps) {
    const { globalByComponent, account, listByEntity, projectById } = state;
    const { list, userProjectsIsFetching } = listByEntity[state.account.item.id + '::userProjects'] || {
        list: {},
        userProjectsIsFetching: true
    }

    let listitem = [];

    if (list) {
        for (let [key, value] of Object.entries(list)) {
            if (projectById[value] && !projectById[value].isDeleted) {
                listitem.push(projectById[value]);
            }
        }
    }

    return {
        listitem,
        userProjectsIsFetching
    }
}

export default connect(mapStateToProps)(withRouter(OwnProjects));
