import React, { Component } from 'react'
import { Container, Grid, Divider, Header, List, Responsive, Form, Placeholder, Message, Label, Icon, Button } from 'semantic-ui-react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Truncate from 'react-truncate';
import { handleContainer, handleHeaderItems, hideFooter, showFooter, setRuntime } from '../../../actions/global';
import fetchConversationIfNeeded, {fetchConversationList, fetchConversation, fetchNextMessages, fetchPreviousMessages, changeMessage, updateMessage} from '../../../actions/conversation'
import config from '../../../config/config';
import ProgressiveImager from '../../../components/helpers/progressive-imager';
import Moment from 'react-moment';
import 'moment/locale/de'
import 'moment-timezone';
import TextareaAutosize from 'react-textarea-autosize';
import MessagesInput from './MessagesInput'
import ImageWrapper from '../../../components/helpers/image-wrapper';
import './Messages.scss'
const nl2br = require('react-nl2br');


class MessagesMeta extends Component {
    render() {
        const {message} = this.props;

        const calendarStrings = {
            lastDay : '[Gestern um] HH:mm',
            sameDay : '[Heute um] HH:mm',
            nextWeek: 'DD.MM.YYYY',
            lastDay: 'DD.MM.YYYY',
            lastWeek: 'DD.MM.YYYY',
            sameElse : 'DD.MM.YYYY',
        }

        var calendarStringsEdit = {
            lastDay : '[Gestern um] HH:mm',
            sameDay : '[Heute um] HH:mm',
            nextWeek: '[am] DD.MM.YYYY',
            lastDay: '[am] DD.MM.YYYY',
            lastWeek: '[am] DD.MM.YYYY',
            sameElse : '[am] DD.MM.YYYY',
        }

        // Same date
        var showedittime = true;
        var create = new Date(message.createDate * 1000).setHours(0, 0, 0, 0);
        var edit = new Date(message.editDate * 1000).setHours(0, 0, 0, 0);
        var days = (new Date(create)).setDate((new Date(create)).getDate() + 2);
        if(create === edit) {
            calendarStringsEdit = {
                lastDay : '[um] HH:mm',
                sameDay : '[um] HH:mm',
                nextWeek: '[um] HH:mm',
                lastDay: '[um] HH:mm',
                lastWeek: '[um] HH:mm',
                sameElse : '[um] HH:mm',
            }

            if((new Date()) > days){
                showedittime = false;
            }
        }

        return (
            <div className="message-date">
                <Moment local calendar={calendarStrings} unix>{message.createDate}</Moment>
                {message.editDate > 0 &&
                    <span style={{fontSize: '0.75em'}}> [Bearbeitet{showedittime && <> <Moment local calendar={calendarStringsEdit} unix>{message.editDate}</Moment></>}]</span>
                }
            </div>
        );
    }
}

class MessagesText extends Component {

    state = {
        edit: false,
    }

    constructor(props) {
        super(props);

        this.handleInput = this.handleInput.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        const {message} = this.props;

        if (prevProps.message.isUpdating && !message.isUpdating && message.updatingMessage === 'SUCCESS') {
            this.setState({
                edit: false,
            });
        }
    }

    render() {
        const {edit} = this.state;
        const {dispatch, message, userItem} = this.props;

        var isOwn = false;
        if (userItem && message.user === userItem.id) {
            isOwn = true;
        }

        if (!edit) {
            return (
                <div className="message-text">
                    {nl2br(message.text)}
                    {isOwn &&
                        <Icon className="iconedit" name="pencil" circular inverted color="grey" onClick={() => this.setState({edit: true})} />
                    }
                </div>
            );
        }

        return (
            <div className="message-text">
                <TextareaAutosize
                    className="fullWidth"
                    minRows="1"
                    maxRows="10"
                    autoComplete="nope"
                    textContentType="oneTimeCode"
                    type="text"
                    value={(message.inputText ? message.inputText : message.text)}
                    ref={(tag) => (this.textarea = tag)}
                    onChange={(e) => {
                        this.handleInput(e, message.id)
                    }}
                    disabled={message.isUpdating ? true : false}
                />
                {message.updatingMessage === 'FAILURE' &&
                    <Message negative>Beim Übertragen der Daten ist ein Fehler aufgetreten.</Message>
                }
                <Button
                    color="red"
                    size="mini"
                    loading={message.isUpdating ? true : false}
                    disabled={!message.inputText || message.text === message.inputText ? true : false}
                    onClick={(e) => {
                        dispatch(updateMessage(message));
                    }}
                >
                    Speichern
                </Button>
            </div>
        );
    }

    handleInput(e, id) {
        const { dispatch } = this.props;
        dispatch(changeMessage(id, e.target.value));
    }
}

function mapStateToMessagesTextProps(state, ownProps){
    const { account } = state;

    const { item } = account;

    return {
        userItem: item,
    }
}

const MessagesTextConnected = connect(mapStateToMessagesTextProps)(MessagesText);


class Messages extends Component {

    state = {
        activeItem: null,
        windowWidth: null,
    }

    componentDidMount() {
        const { dispatch, match } = this.props;
        dispatch(hideFooter());
        dispatch(fetchConversationList());
        dispatch(handleContainer("secondary", "Nachrichten(PN)", true));
        dispatch(handleHeaderItems("dashboard"));
        dispatch(setRuntime({page: 'be-messages'}));
        this.setState({windowWidth: window.innerWidth})
        window.addEventListener('resize', this.updateDimensions);
        document.body.classList.add('prevent-scroll');
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(showFooter())
        window.removeEventListener('resize', this.updateDimensions);
        document.body.classList.remove('prevent-scroll');
    }

    handleActiveItem = (e, { name }) => {
        this.setState({activeItem: name});
    }

    updateDimensions = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    render() {
        const { activeItem } = this.state;
        const { account, conversations } = this.props;

        return (
            <>
            <preventReload/>

                    <div className="messages-section">
                        <List className="list-msg" divided>
                            {/* {!conversations.length && account.isFetchingConversations ? */}
                            {account.isFetchingConversations ?
                                 <>
                                 <div className="messages-placeholder">
                                     <Placeholder className="messages-placeholder-image">
                                         <Placeholder.Image/>
                                     </Placeholder>
                                     <Placeholder>
                                         <Placeholder.Line/>
                                         <Placeholder.Line length="short"/>
                                     </Placeholder>
                                     <Divider/>
                                 </div>
                                 <div className="messages-placeholder">
                                     <Placeholder className="messages-placeholder-image">
                                         <Placeholder.Image/>
                                     </Placeholder>
                                     <Placeholder>
                                         <Placeholder.Line/>
                                         <Placeholder.Line length="short"/>
                                     </Placeholder>
                                     <Divider/>
                                 </div>
                                 <div className="messages-placeholder">
                                     <Placeholder className="messages-placeholder-image">
                                         <Placeholder.Image/>
                                     </Placeholder>
                                     <Placeholder>
                                         <Placeholder.Line/>
                                         <Placeholder.Line length="short"/>
                                     </Placeholder>
                                     <Divider/>
                                 </div>
                             </>
                            :

                                conversations.map((conversation) => (<>

                                    <List.Item className="conversation" as={Link} to={`/backend/messages/${conversation.membership.conversation}`} name={'conversation::'+conversation.membership.conversation} active={activeItem === 'conversation::'+conversation.membership.conversation} onClick={this.handleActiveItem}>
                                        <ImageWrapper avatar={true} path={conversation.membership.filePath}>
                                            <ProgressiveImager placeholder="avatar" avatar src={config.getImageUrl('avatar', conversation.membership.filePath)} />
                                        </ImageWrapper>
                                        <List.Content>

                                            <List.Header className="lineheight1">
                                                {conversation.membership.title}
                                            </List.Header>

                                            {conversation.membership.count > 0 &&
                                                <div className="msg-count-label">
                                                    <Label color="red">{conversation.membership.count}</Label>
                                                </div>
                                            }

                                            <span className="message-preview">

                                                <Responsive as={Truncate} {...Responsive.onlyMobile} width={this.state.windowWidth - 100} lines={1} ellipsis={<>...</>}>
                                                    {conversation.messages[conversation.messages.length - 1].text}
                                                </Responsive>

                                                <Responsive as={Truncate} {...Responsive.onlyTablet} width={this.state.windowWidth - 300}  lines={1} ellipsis={<>...</>}>
                                                    {conversation.messages[conversation.messages.length - 1].text}
                                                </Responsive>

                                            </span>
                                            <div className="message-date">

                                                <Moment local unix format="DD.MM.YYYY" >{conversation.messages[conversation.messages.length - 1].createDate}</Moment>
                                            </div>
                                        </List.Content>
                                    </List.Item>

                                </>))
                            }
                        </List>
                    </div>

                {conversations.length == 0 &&
                    <Responsive minWidth={Responsive.onlyComputer.minWidth}>
                        <Container className="messages-section">
                            <Message>Du hast noch keine Nachrichten gesendet oder empfangen.</Message>
                        </Container>
                    </Responsive>
                }
            </>
        )
    }
}

function mapStateToMessagesProps(state, ownProps) {
    const { account, conversationById } = state;

    var conversations = [];
    if(conversationById) {
        for (var [kconv, vconv] of Object.entries(conversationById)) {

            // Sort messages
            if(vconv.messages) {
                var msgarr = [];
                for (var [kmsg, vmsg] of Object.entries(vconv.messages)) {
                    if (vmsg && vmsg.load) {
                        vmsg['id'] = parseInt(kmsg);
                    }
                    msgarr.push(vmsg);
                };
                msgarr = [].concat(msgarr).sort((a, b) => a.id > b.id ? 1 : -1);

                conversations.push({
                    membership: vconv.membership,
                    messages: msgarr,
                });
            }
        };
    }

    conversations = [].concat(conversations).sort((a, b) => parseInt(a.messages[a.messages.length - 1].id) > parseInt(b.messages[b.messages.length - 1].id) ? -1 : 1);

    return {
        account,
        conversations,
    }
}

class Mobile extends Component {

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(fetchConversationList());
        dispatch(hideFooter());
    }

    componentWillUnmount = () => {
        const { dispatch } = this.props;
        dispatch(showFooter());
    }

    render() {
        const { account, conversations } = this.props;

        return (
            <>
                <Responsive maxWidth={Responsive.onlyTablet.maxWidth}>
                    <Container className="messages-section">
                        <MessagesInner conversationid={this.props.match.params.id} />
                    </Container>
                </Responsive>
                <Responsive minWidth={Responsive.onlyComputer.minWidth}>
                    <Container className="messages-section">
                        <Grid>
                            <Grid.Column computer={11} className="coversations-inner">
                                <MessagesInner conversationid={this.props.match.params.id} />
                            </Grid.Column>

                            <Grid.Column computer={5} className="chat-list">
                                <Header as="h3">Deine Unterhaltungen</Header>
                                <List className="list-msg" divided>
                                    {!conversations.length && account.isFetchingConversations ?
                                        <Placeholder>
                                            <Placeholder.Line length='full' />
                                            <Placeholder.Line length='full' />
                                            <Placeholder.Line length='full' />
                                        </Placeholder>
                                    :
                                        conversations.map((conversation) => (
                                            <Link
                                                to={`/backend/messages/${conversation.membership.conversation}`}
                                                className={conversation.membership.conversation == this.props.match.params.id && "active-msg"}
                                            >
                                                {/*active={activeItem === 'conversation::'+conversation.membership.conversation}*/}
                                                <List.Item name={'conversation::'+conversation.membership.conversation} onClick={this.handleActiveItem}>
                                                    <ImageWrapper
                                                        circular avatar={true}
                                                        path={conversation.membership.filePath}

                                                    >
                                                        <ProgressiveImager placeholder="avatar" className="chat-list-user-img" avatar src={config.getImageUrl('avatar', conversation.membership.filePath)} />
                                                    </ImageWrapper>
                                                    <List.Content>
                                                        <List.Header className="lineheight1">
                                                            {conversation.membership.title}
                                                        </List.Header>
                                                    </List.Content>
                                                    {conversation.membership.count > 0 &&
                                                        <div className="msg-count-label">
                                                            <Label color="red">{conversation.membership.count}</Label>
                                                        </div>
                                                    }
                                                </List.Item>
                                            </Link>
                                        ))
                                    }
                                </List>
                            </Grid.Column>
                        </Grid>
                    </Container>
                </Responsive>
            </>
        )
    }
}

class MessagesInner extends Component {

    constructor(props) {
        super(props)

        this.watchNewMessages = this.watchNewMessages.bind(this);
    }

    componentDidMount() {
        const { dispatch, conversationid } = this.props;
        dispatch(fetchConversationIfNeeded(conversationid));
        dispatch(handleContainer("secondary", "Nachrichten(PN)", true));
        dispatch(handleHeaderItems("dashboard"));
        dispatch(setRuntime({page: 'be-messages'}));
        dispatch(hideFooter());
        window.scrollTo(0,document.body.scrollHeight);
        // Watch for new messages
        const watcher = setInterval(this.watchNewMessages, config.COMMENT_UPDATETIME);
        this.setState({'watcher': watcher});
        document.body.classList.add('prevent-scroll');
        //Scroll to the bottom - desktop message container
        if(document.querySelector('.chat-conversation')){
            document.querySelector('.chat-conversation').scrollTo(0, document.querySelector('.chat-conversation').scrollHeight)
        }
    }

    componentDidUpdate(prevProps){
        const { messages } = this.props;

        if(
            messages && messages.length && (
                !(prevProps.messages && prevProps.messages.length) ||
                prevProps.messages[prevProps.messages.length - 1].id != messages[messages.length - 1].id
            )
        ) {
            window.scrollTo(0,document.body.scrollHeight);
            if(document.querySelector('.chat-conversation')){
                document.querySelector('.chat-conversation').scrollTo(0, document.querySelector('.chat-conversation').scrollHeight)
            }
        }
    }

    componentWillUnmount = () => {
        const { dispatch } = this.props;
        this.setState({animationClass: "hide"});
        if (this.state && this.state.watcher) {
            clearInterval(this.state.watcher);
        }

        dispatch(showFooter())

        document.body.classList.remove('prevent-scroll');
    }

    handlePrevious(messageid) {
        const { dispatch, conversationid } = this.props;
        dispatch(fetchPreviousMessages(conversationid, messageid));
    }

    watchNewMessages() {
        const { dispatch, messages, isFetching, isFailed, conversationid, account } = this.props;

        if (!isFetching && !isFailed) {
            var watchthis = [];
            for (var watchkey = messages.length - 1; watchkey >= 0; --watchkey) {
                // Ignore just posted own messages
                // TODO: Optimize
                if (messages[watchkey].user !== account.item.id) {
                    watchthis.push(messages[watchkey].id);
                    break;
                }
            }

            if (!watchthis.length) {
                dispatch(fetchConversation(conversationid));
            }
            else {
                dispatch(fetchNextMessages(conversationid, watchthis));
            }
        }
    }

    render() {
        const { account, isFetching, messages, conversationid, membership } = this.props;
        if (!messages.length && isFetching) {
            return (
                <>
                <Responsive maxWidth={Responsive.onlyTablet.maxWidth}>
                    <div className="msg-header msg-header-placeholder">
                        <Placeholder className="messages-placeholder-image">
                            <Placeholder.Image/>
                        </Placeholder>
                        <Placeholder>
                            <Placeholder.Line length="short"/>
                        </Placeholder>
                    </div>
                </Responsive>
                <Responsive minWidth={Responsive.onlyComputer.minWidth}>
                    <div className="msg-header-desktop msg-header-placeholder">
                        <Placeholder className="messages-placeholder-image">
                            <Placeholder.Image/>
                        </Placeholder>
                        <Placeholder>
                            <Placeholder.Line length="short"/>
                        </Placeholder>
                    </div>
                </Responsive>

                <div className="message-inner-placeholder">
                    <div className="messages-placeholder message-inner">
                        <Placeholder className="messages-placeholder-image">
                            <Placeholder.Image/>
                        </Placeholder>
                        <Placeholder>
                            <Placeholder.Line/>
                            <Placeholder.Line length="short"/>
                        </Placeholder>
                    </div>
                    <div className="messages-placeholder message-inner own-message-placeholder">
                        <Placeholder>
                            <Placeholder.Line/>
                            <Placeholder.Line length="medium"/>
                            <Placeholder.Line length="long"/>
                        </Placeholder>
                    </div>
                    <div className="messages-placeholder message-inner">
                        <Placeholder className="messages-placeholder-image">
                            <Placeholder.Image/>
                        </Placeholder>
                        <Placeholder>
                            <Placeholder.Line/>
                            <Placeholder.Line length="short"/>
                        </Placeholder>
                    </div>
                    <div className="messages-placeholder message-inner own-message-placeholder">
                        <Placeholder>
                            <Placeholder.Line/>
                            <Placeholder.Line length="medium"/>
                            <Placeholder.Line length="long"/>
                        </Placeholder>
                    </div>
                </div>
                </>
            )
        }
        else {
            return (
                <>
                    <Responsive maxWidth={Responsive.onlyTablet.maxWidth}>
                        <div className="msg-header">
                            <div className="user-img">
                            <ImageWrapper avatar={true} path={membership.filePath}>
                                <ProgressiveImager placeholder="avatar" avatar src={config.getImageUrl('avatar', membership.filePath)} />
                            </ImageWrapper>
                            </div>
                            <span>{membership.title}</span>
                        </div>
                    </Responsive>
                    <Responsive minWidth={Responsive.onlyComputer.minWidth}>
                        <div className="msg-header">
                            <div className="user-img">
                            <ImageWrapper avatar={true} path={membership.filePath}>
                                <ProgressiveImager placeholder="avatar" avatar src={config.getImageUrl('avatar', membership.filePath)} />
                            </ImageWrapper>
                            </div>
                            <span>{membership.title}</span>
                        </div>
                    </Responsive>

                    <List className="chat-conversation">
                        {messages.map((msg) => (
                            <>
                                {!msg.load ?
                                    <>
                                        {msg.user === account.item.id ?
                                            <List.Item className="own-message">
                                                <List.Content className="own-message-content">
                                                    <MessagesTextConnected message={msg} />
                                                    <MessagesMeta message={msg} />
                                                </List.Content>
                                            </List.Item>
                                        :
                                            <List.Item className="incoming-message">
                                                <Link to={`/user/${msg.userData.urlslug}/${msg.user}`}>
                                                    <ImageWrapper className="user-img" avatar={true} path={membership.filePath}>
                                                        <ProgressiveImager placeholder="avatar" avatar src={config.getImageUrl('avatar', membership.filePath)} />
                                                    </ImageWrapper>
                                                </Link>
                                                <List.Content className="incoming-message-content">
                                                    <MessagesTextConnected message={msg} />
                                                    <MessagesMeta message={msg} />
                                                </List.Content>
                                            </List.Item>
                                        }
                                    </>

                                    :

                                    <List.Item>
                                        <Grid>
                                            <Grid.Column width={16} textAlign="center">
                                                <Form.Button
                                                    onClick={() => {this.handlePrevious(msg.load.id)}}
                                                    color="grey"
                                                    basic
                                                >
                                                    Vorherige Mitteilungen anzeigen
                                                </Form.Button>
                                                <Divider hidden />
                                            </Grid.Column>
                                        </Grid>
                                    </List.Item>
                                }

                            </>
                        ))}

                    </List>
                    <MessagesInput conversationid={conversationid} />
                </>
            )
        }
    }
}

function mapStateToMessagesInnerProps(state, ownProps) {
    const { account, conversationById } = state;

    const { isFetching, isFailed, lastUpdated, lastFetch, membership, messages } = conversationById[
        ownProps.conversationid
    ] || {
        isFetching: true,
        membership: {},
        messages: {},
    }

    var msgarr = [];
    if (messages) {
        for (var [kmsg, vmsg] of Object.entries(messages)) {
            if (vmsg && vmsg.load) {
                vmsg['id'] = parseInt(kmsg);
            }
            msgarr.push(vmsg);
        };
        msgarr = [].concat(msgarr).sort((a, b) => a.id > b.id ? 1 : -1);
    }

    return {
        account,
        isFetching,
        isFailed,
        lastUpdated,
        lastFetch,
        membership,
        messages: msgarr,
    }
}

MessagesInner = connect(mapStateToMessagesInnerProps)(MessagesInner);
export default {
    Messages: connect(mapStateToMessagesProps)(withRouter(Messages)),
    MessagesMobile: connect(mapStateToMessagesProps)(withRouter(Mobile)),
}
