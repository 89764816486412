import React, { Component } from 'react'
import config from '../../config/config';
import ProgressiveImager from '../helpers/progressive-imager';
import { Link } from 'react-router-dom';
import { Link as ScrollLink} from 'react-scroll';
import { Breadcrumb, Icon, Placeholder, Divider, Image } from 'semantic-ui-react';
import Moment from 'react-moment';
import EditPost from './editPost';
import "moment/locale/de";
import "moment-timezone";
import { motion } from 'framer-motion'
import PreviewGallery from '../PreviewGallery/PreviewGallery';
import quoteIcon from '../../images/icons/frontend/icon-zitieren.svg';

import './Forum.scss';
import CustomPopup from '../CustomPopup/CustomPopup';

class PostMeta extends Component {
    render() {
        const { post } = this.props;

        const calendarStrings = {
            lastDay : '[Gestern um] HH:mm',
            sameDay : '[Heute um] HH:mm',
            nextWeek: 'DD.MM.YYYY',
            lastDay: 'DD.MM.YYYY',
            lastWeek: 'DD.MM.YYYY',
            sameElse : 'DD.MM.YYYY',
        }

        var calendarStringsEdit = {
            lastDay : '[Gestern um] HH:mm',
            sameDay : '[Heute um] HH:mm',
            nextWeek: '[am] DD.MM.YYYY',
            lastDay: '[am] DD.MM.YYYY',
            lastWeek: '[am] DD.MM.YYYY',
            sameElse : '[am] DD.MM.YYYY',
        }

        // Same date
        var showedittime = true;
        var create = new Date(post.createDate * 1000).setHours(0, 0, 0, 0);
        var edit = new Date(post.editDate * 1000).setHours(0, 0, 0, 0);
        var days = (new Date(create)).setDate((new Date(create)).getDate() + 2);
        if(create === edit) {
            calendarStringsEdit = {
                lastDay : '[um] HH:mm',
                sameDay : '[um] HH:mm',
                nextWeek: '[um] HH:mm',
                lastDay: '[um] HH:mm',
                lastWeek: '[um] HH:mm',
                sameElse : '[um] HH:mm',
            }

            if((new Date()) > days){
                showedittime = false;
            }
        }

        return (
            <div className="post-published-date">
                <Moment locale="de" calendar={calendarStrings} unix>{post.createDate}</Moment>
                {post.editDate > 0 &&
                    <span style={{marginLeft: "0.25rem"}}>[Bearbeitet{showedittime && <> <Moment local calendar={calendarStringsEdit} unix>{post.editDate}</Moment></>}]</span>
                }
            </div>
        );
    }
}

export { PostMeta }

class Post extends Component {
    
    state = {
        isEditing: false
    }

    render() {

        const calendarStrings = {
            lastDay : '[Gestern um] HH:mm',
            sameDay : '[Heute um] HH:mm',
            nextWeek: 'DD.MM.YYYY',
            lastDay: 'DD.MM.YYYY',
            lastWeek: 'DD.MM.YYYY',
            sameElse : 'DD.MM.YYYY',
        }

        const { isFetching, message, handleQuote, ownPost, threadItem, showBreadcrumb, projectId, projectData, projectPartId, projectPartData, enableQuotes } = this.props;
        const { isEditing } = this.state;

        return (
            <>
            {isFetching ? 

                <div className="post fetching">
                    <div className="post-header">
                        <div className="post-author-image">
                            <Placeholder>
                                <Placeholder.Image/>
                            </Placeholder>
                        </div>
                        <div className="post-meta">
                            <Placeholder>
                                <Placeholder.Line length="very short"/>
                                <Placeholder.Line length="short" />
                            </Placeholder>
                        </div>
                    </div>
                    <div className="post-content">
                        <Placeholder>
                            <Placeholder.Paragraph>
                                <Placeholder.Line/>
                                <Placeholder.Line/>
                                <Placeholder.Line/>
                                <Placeholder.Line/>
                                <Placeholder.Line/>
                                <Placeholder.Line/>
                                <Placeholder.Line/>
                                <Placeholder.Line/>
                                <Placeholder.Line/>
                            </Placeholder.Paragraph>
                        </Placeholder>
                    </div>
                </div>

                :

                <div className="post" itemProp="comment" itemScope itemType="https://schema.org/Comment">
                    <div className="post-header">
                        <div className="post-author-image">
                            <Link to={`/user/${message.userData.urlslug}/${message.user}`}>
                                <ProgressiveImager alt={`Profilbild von ${message.userData.name}`} circular placeholder="avatar" src={config.getImageUrl('avatar', message.userData.mediaavatarPath)}/>
                            </Link>
                        </div>
                        <div className="post-meta">
                            <PostMeta post={message}/>
                            <div className="author" itemProp="author" itemScope itemType="https://schema.org/Person">
                                <Link itemProp="name" to={`/user/${message.userData.urlslug}/${message.user}`}>
                                    {message.userData.name}
                                </Link>
                            </div>
                            {/* Show breadcrum if project was added to thread and own post*/}
                            {projectId && showBreadcrumb &&
                                <div className="breadcrumb">
                                    <Breadcrumb>
                                        <Breadcrumb.Section>
                                            <Link to={`/user/${message.userData.urlslug}/${message.user}`}>
                                                {message.userData.name}
                                            </Link>
                                        </Breadcrumb.Section>
                                        <Breadcrumb.Divider icon="triangle right" />
                                        <Breadcrumb.Section>
                                            <Link 
                                                to={projectData.linktype === "stories" ? `/oldtimer/${projectData.urlslug}/${projectId}/tab/stories` : projectData.linktype === "vehicle" ? `/oldtimer/${projectData.urlslug}/${projectId}/tab/vehicle` : `/oldtimer/${projectData.urlslug}/${projectId}/tab/interview` }
                                                >
                                                {projectData.title}
                                            </Link>
                                        </Breadcrumb.Section>
                                        {projectPartId && 
                                        <>
                                            <Breadcrumb.Divider icon="triangle right" />
                                            <Breadcrumb.Section>
                                                <Link to={`/oldtimer/${projectData.urlslug}/${projectPartData.urlslug}/${projectPartId}`}>
                                                    {projectPartData.title}
                                                </Link>
                                            </Breadcrumb.Section>
                                        </>
                                        }
                                    </Breadcrumb>
                                </div>
                            }
                        </div>
                        
                        {ownPost && 
                            <div className="post-edit" onClick={() => this.setState({ isEditing: true })}>
                                <Icon name="pencil alternate" />
                            </div>
                        } 
                  
                    </div>
                    <div itemProp="text" className="post-content" dangerouslySetInnerHTML={{__html: message.text}}/>
                    {/* Render Post Medias */}
                    {message.media && message.media.length > 0 && 
                        <div className="post-content-medias">
                            <PreviewGallery 
                                images={message.media}
                                imagesPerRow="4"
                                enableLightbox
                            />
                        </div>
                    }
                    {/* Enable quotes if user logged in */}
                    {enableQuotes &&
                        <div className="post-actions">
                            {/*<div className="post-report"> // TODO - later :-)
                                Melden
                            </div>*/} 
                            <ScrollLink isDynamic offset={-250} smooth to="tinymce-editor" className="post-quote" onClick={handleQuote}>
                                <Image src={quoteIcon} alt="Icon Zitieren" title="Zitieren" />
                                Zitieren
                            </ScrollLink>
                        </div>
                    }
                    <Divider />
                </div>
            }
            {/* EDIT POST */}
            <CustomPopup
                popupOpen={isEditing}
                onClose={() => this.setState({ isEditing: false })}
                //saveButton={this.showButton()}
                showCloseIcon={false}
                popupHeader="Post bearbeiten"
                headerBackgroundWhite
                className="larger-popup"
            >
                <EditPost 
                    messageData={message}
                    threadItem={threadItem}
                    closeModal={() => this.setState({ isEditing: false })}
                />
            </CustomPopup>
            </>
        )
    }
}

export default Post;