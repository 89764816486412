import {
    API_COMMENTS_REQUEST,
    API_COMMENTS_SUCCESS,
    API_COMMENTS_FAILURE,
    API_POST_COMMENT_REQUEST,
    API_POST_COMMENT_SUCCESS,
    API_POST_COMMENT_FAILURE,
    NEW_COMMENT,
    CHANGE_COMMENT,
    API_UPDATE_COMMENT_REQUEST,
    API_UPDATE_COMMENT_SUCCESS,
    API_UPDATE_COMMENT_FAILURE,
} from '../actions/comments'
import {
    RESET_STORE,
} from '../actions/account'
// import {actions} from 'ix-redux-file-upload';


export default function commentsByParent(state = {}, action) {

    try{

        if (action.type === RESET_STORE) {
            return {};
        }

        if (
            action.type === CHANGE_COMMENT ||
            action.type === API_UPDATE_COMMENT_REQUEST ||
            action.type === API_UPDATE_COMMENT_SUCCESS ||
            action.type === API_UPDATE_COMMENT_FAILURE
        ) {
            // Find ID
            for (var [parent, data] of Object.entries(state)) {
                for (var [id, comment] of Object.entries(data.comments)) {
                    if (comment.id === action.meta.id) {
                        // Found

                        var changes = {};

                        if (action.type === CHANGE_COMMENT) {
                            changes = {
                                inputText: action.payload.inputText,
                            };
                        }

                        else if (action.type === API_UPDATE_COMMENT_REQUEST) {
                            changes = {
                                isUpdating: true,
                                updatingMessage: '',
                            };
                        }

                        else if (action.type === API_UPDATE_COMMENT_SUCCESS) {
                            changes = {
                                ...action.payload.comment,
                                isUpdating: false,
                                inputText: null,
                                updatingMessage: 'SUCCESS',
                            };
                        }

                        else if (action.type === API_UPDATE_COMMENT_FAILURE) {
                            changes = {
                                isUpdating: false,
                                updatingMessage: 'FAILURE',
                            };
                        }

                        return {
                            ...state,
                            [parent]: {
                                ...state[parent],
                                comments: {
                                    ...state[parent]['comments'],
                                    [comment.id]: {
                                        ...state[parent]['comments'][comment.id],
                                        ...changes,
                                    }
                                }
                            }
                        };

                    }
                }
            }
        }

        if (action.type === NEW_COMMENT) {
            return {
                ...state,
                [action.meta.parent]: {
                    ...state[action.meta.parent],
                    inputText: action.payload.inputText,
                }
            };
        }

        else if (action.type === API_COMMENTS_REQUEST) {
            return {
                ...state,
                [action.meta.parent]: {
                    ...state[action.meta.parent],
                    isFetching: true,
                    lastFetch: Date.now(),
                }
            };
        }
        else if (
            action.type === API_COMMENTS_SUCCESS ||
            action.type === API_POST_COMMENT_SUCCESS
        ) {
            var pl = (action.payload.basecomment ? action.payload.basecomment : (action.payload.loadcomment ? action.payload.loadcomment : action.payload.parentcomment));

            if (!pl && action.payload.comment) {
                // Fake structure for single comment
                pl = {
                    'Level1': {
                        [action.payload.comment.id]: action.payload,
                    },
                };
            }

            if (pl) {
                for (var [kbc, vbc] of Object.entries(pl)) {
                    for (var [kcomment, vcomment] of Object.entries(vbc)) {

                        // Process children
                        if (vcomment.comment.children && vcomment.comment.children.length) {
                            var newparent = 'comment::'+vcomment.comment.id;
                            for (var [kanswer, vanswer] of Object.entries(vcomment.comment.children)) {
                                // Init if needed
                                if (!state[newparent]) {
                                    state = {
                                        ...state,
                                        [newparent]: {
                                            comments: {},
                                        }
                                    }
                                }

                                state = {
                                    ...state,
                                    [newparent]: {
                                        ...state[newparent],
                                        isFetching: false,
                                        isFailed: false,
                                        comments: {
                                            ...state[newparent]['comments'],
                                            ...{
                                                [(vanswer.load ? vanswer.load.id : vanswer.id)]: {
                                                    ...state[newparent]['comments'][(vanswer.load ? vanswer.load.id : vanswer.id)],
                                                    ...vanswer,
                                                    load: vanswer.load ? vanswer.load : false
                                                },
                                            },
                                        },
                                        count: vcomment.commentcount,
                                        lastUpdated: Date.now(),
                                    }
                                };
                            }

                            delete(vcomment.comment.children);
                        }

                        // Process parent
                        var newparent = action.meta.parent;
                        if (vcomment.comment.commentparent) {
                            var newparent = 'comment::'+vcomment.comment.commentparent;
                        }
                        if (!state[newparent] || !state[newparent]['comments']) {
                            state = {
                                ...state,
                                [newparent]: {
                                    ...(state[newparent] ? state[newparent] : {}),
                                    comments: {},
                                }
                            }
                        }

                        state = {
                            ...state,
                            [newparent]: {
                                ...state[newparent],
                                isFetching: false,
                                isFailed: false,
                                comments: {
                                    ...state[newparent]['comments'],
                                    ...{
                                        [(vcomment.comment.load ? vcomment.comment.load.id : vcomment.comment.id)]: {
                                            ...state[newparent]['comments'][(vcomment.comment.load ? vcomment.comment.load.id : vcomment.comment.id)],
                                            ...vcomment.comment,
                                            load: vcomment.comment.load ? vcomment.comment.load : false

                                        },
                                    },
                                },
                                count: vcomment.commentcount,
                                lastUpdated: Date.now(),
                            }
                        };
                    }
                }
            }

            state = {
                ...state,
                [action.meta.parent]: {
                    ...state[action.meta.parent],
                    isFetching: false,
                    isFailed: false,
                    lastUpdated: Date.now(),
                }
            };

            if (action.type === API_POST_COMMENT_SUCCESS) {
                state = {
                    ...state,
                    [action.meta.parent]: {
                        ...state[action.meta.parent],
                        isPosting: false,
                        inputText: '',
                    }
                };
            }

            return state;
        }
        else if (action.type === API_COMMENTS_FAILURE) {
            return {
                ...state,
                [action.meta.parent]: {
                    ...state[action.meta.parent],
                    isFetching: false,
                    isFailed: true,
                    lastUpdated: Date.now(),
                }
            };
        }

        else if (action.type === API_POST_COMMENT_REQUEST) {
            return {
                ...state,
                [action.meta.parent]: {
                    ...state[action.meta.parent],
                    isPosting: true,
                }
            };
        }
        else if (action.type === API_POST_COMMENT_FAILURE) {
            return {
                ...state,
                [action.meta.parent]: {
                    ...state[action.meta.parent],
                    isPosting: false,
                }
            };
        }

        return state;

    } catch (e) {

        console.log(e);
        return {
            ...state,
            fatalError: true,
        };

    }
}
