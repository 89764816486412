import config from '../config/config'
import { createAction } from 'redux-api-middleware';

let baseURL = "https://api-m.sandbox.paypal.com";

export const API_PAYPAL_AUTH_REQUEST = 'API_PAYPAL_AUTH_REQUEST';
export const API_PAYPAL_AUTH_SUCCESS = 'API_PAYPAL_AUTH_SUCCESS';
export const API_PAYPAL_AUTH_FAILURE = 'API_PAYPAL_AUTH_FAILURE';
export const API_PAYPAL_SUBSCRIPTION_REQUEST = 'API_PAYPAL_SUBSCRIPTION_REQUEST';
export const API_PAYPAL_SUBSCRIPTION_SUCCESS = 'API_PAYPAL_SUBSCRIPTION_SUCCESS';
export const API_PAYPAL_SUBSCRIPTION_FAILURE = 'API_PAYPAL_SUBSCRIPTION_FAILURE';
export const API_PAYPAL_SUBSCRIPTION_SUSPEND_REQUEST = 'API_PAYPAL_SUBSCRIPTION_SUSPEND_REQUEST';
export const API_PAYPAL_SUBSCRIPTION_SUSPEND_SUCCESS = 'API_PAYPAL_SUBSCRIPTION_SUSPEND_SUCCESS';
export const API_PAYPAL_SUBSCRIPTION_SUSPEND_FAILURE = 'API_PAYPAL_SUBSCRIPTION_SUSPEND_FAILURE';
export const API_PAYPAL_SUBSCRIPTION_ACTIVATE_REQUEST = "API_PAYPAL_SUBSCRIPTION_ACTIVATE_REQUEST";
export const API_PAYPAL_SUBSCRIPTION_ACTIVATE_SUCCESS = "API_PAYPAL_SUBSCRIPTION_ACTIVATE_SUCCESS";
export const API_PAYPAL_SUBSCRIPTION_ACTIVATE_FAILURE = "API_PAYPAL_SUBSCRIPTION_ACTIVATE_FAILURE"
export const API_PAYPAL_SUBSCRIPTION_CANCEL_REQUEST = "API_PAYPAL_SUBSCRIPTION_CANCEL_REQUEST";
export const API_PAYPAL_SUBSCRIPTION_CANCEL_SUCCESS = "API_PAYPAL_SUBSCRIPTION_CANCEL_SUCCESS";
export const API_PAYPAL_SUBSCRIPTION_CANCEL_FAILURE = "API_PAYPAL_SUBSCRIPTION_CANCEL_FAILURE";
 

export function payPalAuth() {
    //https://developer.paypal.com/api/rest/authentication/
    const clientIdAndSecret = `${process.env.REACT_APP_PAYPAL_CLIENT_ID}:${process.env.REACT_APP_PAYPAL_SECRET_ID}`;
    const base64 = Buffer.from(clientIdAndSecret).toString('base64')
    
    return createAction({
        endpoint: `${baseURL}/v1/oauth2/token`,
        method: 'POST',
        headers: {
            'Accept': 'application/json', 
            'Accept-Language': 'en_US',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Basic ' + base64
        },
        body: 'grant_type=client_credentials',
        types: [
            {type: API_PAYPAL_AUTH_REQUEST},
            {type: API_PAYPAL_AUTH_SUCCESS},
            {type: API_PAYPAL_AUTH_FAILURE},
        ]
    })
}

export function shouldRefreshToken() {
    return async (dispatch, getState) => {
        const state = getState().account;
        if(!state.paypal || (state.paypal && (Date.now() > (state.paypal.lastFetch + (state.paypal.data.expires_in - 900))))) {
            await dispatch(payPalAuth());
        }
    }
}

function payPalSubscriptionDetails(subscribtionId, token, id) {
    
    //https://developer.paypal.com/api/subscriptions/v1

    return createAction({
        endpoint: `${baseURL}/v1/billing/subscriptions/${subscribtionId}`,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        types: [
            {type: API_PAYPAL_SUBSCRIPTION_REQUEST, meta: {id}},
            {type: API_PAYPAL_SUBSCRIPTION_SUCCESS, meta: {id}},
            {type: API_PAYPAL_SUBSCRIPTION_FAILURE, meta: {id}},
        ]
    })
}

export function subscriptionDetails(subscribtionId, id) {
    return (dispatch, getState) => {
        const token = getState().account.paypal ? getState().account.paypal.data.access_token : null;
        return dispatch(shouldRefreshToken())
            .then(() => dispatch(payPalSubscriptionDetails(subscribtionId, getState().account.paypal.data.access_token, id)))
    }
}

function payPalSubscriptionSuspend(subscribtionId, token, id) {
    
    //https://developer.paypal.com/api/subscriptions/v1
 
    return createAction({
        endpoint: `${baseURL}/v1/billing/subscriptions/${subscribtionId}/suspend`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({
            reason: "Abonemment pausiert"
        }),
        types: [
            {type: API_PAYPAL_SUBSCRIPTION_SUSPEND_REQUEST, meta: {id}},
            {type: API_PAYPAL_SUBSCRIPTION_SUSPEND_SUCCESS, meta: {id}},
            {type: API_PAYPAL_SUBSCRIPTION_SUSPEND_FAILURE, meta: {id}},
        ]
    })
}

export function subscriptionSuspend(subscribtionId, token, id) {
    return (dispatch, getState) => {
        return dispatch(shouldRefreshToken())
            .then(() => dispatch(payPalSubscriptionSuspend(subscribtionId, token, id)))
            .then(() => dispatch(payPalSubscriptionDetails(subscribtionId, token, id)))
    }
}

function payPalSubscriptionActivate(subscribtionId, token, id) {
    //https://developer.paypal.com/api/subscriptions/v1
   
    return createAction({
        endpoint: `${baseURL}/v1/billing/subscriptions/${subscribtionId}/activate`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({
            reason: "Abonemment reaktiviert"
        }),
        types: [
            {type: API_PAYPAL_SUBSCRIPTION_ACTIVATE_REQUEST, meta: {id}},
            {type: API_PAYPAL_SUBSCRIPTION_ACTIVATE_SUCCESS, meta: {id}},
            {type: API_PAYPAL_SUBSCRIPTION_ACTIVATE_FAILURE, meta: {id}},
        ]
    })
}

export function subscriptionActivate(subscribtionId, token, id) {
    return (dispatch, getState) => {
        return dispatch(shouldRefreshToken())
            .then(() => dispatch(payPalSubscriptionActivate(subscribtionId, token, id)))
            .then(() => dispatch(payPalSubscriptionDetails(subscribtionId, token, id)))
    }
}

function payPalSubscriptionCancel(subscribtionId, token, id) {
    //https://developer.paypal.com/api/subscriptions/v1
   
    return createAction({
        endpoint: `${baseURL}/v1/billing/subscriptions/${subscribtionId}/cancel`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({
            reason: "Abonemment gekündigt"
        }),
        types: [
            {type: API_PAYPAL_SUBSCRIPTION_CANCEL_REQUEST, meta: {id}},
            {type: API_PAYPAL_SUBSCRIPTION_CANCEL_SUCCESS, meta: {id}},
            {type: API_PAYPAL_SUBSCRIPTION_CANCEL_FAILURE, meta: {id}},
        ]
    })
}

export function subscriptionCancel(subscribtionId, token, id) {
    return (dispatch, getState) => {
        return dispatch(shouldRefreshToken())
            .then(() => dispatch(payPalSubscriptionCancel(subscribtionId, token, id)))
            .then(() => dispatch(payPalSubscriptionDetails(subscribtionId, token, id)))
    }
}