class config {
    // App config
    API_HOST = 'https://api.vetero.de';
    API_UPLOAD = '/_uploader/media/upload';
    API_PUSH_KEY = 'BBryS/UmR8phPCCW60Y30dmFXgvkEgGwfSgQdsHMSUVf2fi3kn/FTFhsRmSntGAqk0nin68KwUFLM4YZt0YgX+g=';
    BLOG_API_HOST = "https://blog.vetero.de/";
    BASE_LIVE_URL = "https://vetero.de";
    PAYPAL_CLIENTID = "Adi2fPvd_iahkpZggopjMPFzS7fcJ9en_G4Lfdy6Ic02JvC9KU2mVBrAgT8gPczrlF1RjvezaXI5fqhA";
    GOOGLE_API_KEY = "AIzaSyDx2P5ml6oqUsfXhaQ5zDpspe_umVJ-W5s";
    FETCH_TIMEOUT = 30000;
    FETCH_AGAIN = 300000;
    FETCH_AGAIN_SHORT = 5000;
    COMMENT_UPDATETIME = 5000; // Update every n ms
    //Dashboard message timestamps
    DASHBOARD_STORY_MESSAGE = 604800000; // 7 days
    DASHBOARD_UPDATE_MESSAGE = 259200000;
    TAG_MANAGER_ID = 'GTM-TVDZGXK';
    PING_TIMEOUT = 120000;
    // Fixed Text
    TITLE_SUFFIX = ' – Vetero Oldtimer Portal';
    // Scope types
    SCOPE_BIKE = 1;
    SCOPE_CAR = 2;
    SCOPE_UTILITY = 3;
    // Design types bike
    DESIGN_AUTOCYCLE = 1;
    DESIGN_MOPED = 2;
    DESIGN_SCOOTER = 3;
    DESIGN_MC_MISC = 4;
    DESIGN_MC_TOURER = 5;
    DESIGN_MC_SPORT = 6;
    DESIGN_MC_CHOPPER = 7;
    DESIGN_MC_CROSS = 8;
    DESIGN_MC_TRAILER = 9;
    DESIGN_TRIKE = 10;
    // Design types car
    DESIGN_LIMO = 101;
    DESIGN_ESTATE = 102;
    DESIGN_COUPE = 103;
    DESIGN_CABRIO = 104;
    DESIGN_ROADSTER = 105;
    DESIGN_ALLTERRAIN = 106;
    DESIGN_PICKUP = 107;
    DESIGN_VAN = 108;
    DESIGN_TRANSPORT = 109;
    // Design types utility
    DESIGN_TRACTOR = 201;
    DESIGN_AGRICULTURAL = 202;
    DESIGN_CONSTRUCTION = 203;
    DESIGN_BUS = 204;
    DESIGN_TRUCK = 205;
    DESIGN_ARTICULATED = 206;
    DESIGN_UNIVERSAL = 207;
    // Design types misc
    DESIGN_MISC = 1001;
    // Layout types
    LAYOUT_MISC = 1;
    LAYOUT_NOENGINE = 2;
    LAYOUT_LINE = 3;
    LAYOUT_V = 4;
    LAYOUT_BOXER = 5;
    LAYOUT_STAR = 6;
    // Engine types
    ENGINE_MISC = 1;
    ENGINE_NONE = 2;
    ENGINE_FOURSTROKE = 3;
    ENGINE_TWOSTROKE = 4;
    ENGINE_WANKEL = 5;
    ENGINE_ELECTRIC = 6;
    ENGINE_STEAM = 7;
    // Gear types
    GEAR_MISC = 1;
    GEAR_NONE = 2;
    GEAR_MANUAL = 3;
    GEAR_SEMIAUTO = 4;
    GEAR_AUTO = 5;
    // Drive types
    DRIVE_MISC = 1;
    DRIVE_NONE = 2;
    DRIVE_FRONT = 3;
    DRIVE_BACK = 4;
    DRIVE_ALL = 5;
    // Categories
    CAT_ENGINE = 1; // Legacy
    CAT_ENGINE_BLOCK = 2;
    CAT_ENGINE_CYLINDER = 3;
    CAT_ENGINE_VALVE = 4;
    CAT_ENGINE_CRANKSHAFT = 5;
    CAT_ENGINE_PISTON = 6;
    CAT_ENGINE_TIMING = 7;
    CAT_ENGINE_FUEL = 8;
    CAT_ENGINE_MIXTURE = 9;
    CAT_ENGINE_COOLING = 10;
    CAT_ENGINE_GEAR = 11;
    CAT_ENGINE_MISC = 12;
    CAT_ENGINE_CLUTCH = 13;
    CAT_CARRIAGE = 21; // Legacy
    CAT_CARRIAGE_FRONTAXLE = 22;
    CAT_CARRIAGE_REARTAXLE = 23;
    CAT_CARRIAGE_SUSPENSION = 24;
    CAT_CARRIAGE_ABSORBER = 25;
    CAT_CARRIAGE_FORK = 26;
    CAT_CARRIAGE_SWINGARM = 27;
    CAT_CARRIAGE_DRIVESHAFT = 28;
    CAT_CARRIAGE_MISC = 29;
    CAT_BODY_ROOF = 30;
    CAT_BODY_VARNISH = 31;
    CAT_BODY_SEALING = 32;
    CAT_BODY_WINDOW = 33;
    CAT_BODY_MIRROR = 34;
    CAT_ELECTRICS = 41; // Legacy
    CAT_ELECTRICS_BATTERY = 42;
    CAT_ELECTRICS_DYNAMO = 43;
    CAT_ELECTRICS_IGNITION = 44;
    CAT_ELECTRICS_EXTERIORLIGHT = 45;
    CAT_ELECTRICS_INTERIORLIGHT = 46;
    CAT_ELECTRICS_STOPLIGHT = 47;
    CAT_ELECTRICS_HARNESS = 48;
    CAT_ELECTRICS_FUSE = 49;
    CAT_ELECTRICS_MISC = 50;
    CAT_BODY = 61; // Legacy
    CAT_BODY_FRAME = 62;
    CAT_BODY_DOOR = 63;
    CAT_BODY_FRONTLID = 64;
    CAT_BODY_CARGOBAY = 65;
    CAT_BODY_MISC = 66;
    CAT_INTERIOR = 81; // Legacy
    CAT_INTERIOR_SEAT = 82;
    CAT_INTERIOR_BENCH = 83;
    CAT_INTERIOR_COVER = 84;
    CAT_INTERIOR_DASHBOARD = 85;
    CAT_INTERIOR_HATRACK = 86;
    CAT_INTERIOR_CEILING = 87;
    CAT_INTERIOR_CARPET = 88;
    CAT_INTERIOR_CONSOLE = 89;
    CAT_INTERIOR_STEERING = 90;
    CAT_INTERIOR_MIRROR = 91;
    CAT_INTERIOR_MISC = 92;
    CAT_EXHAUST = 101; // Legacy
    CAT_EXHAUST_MANIFOLD = 102;
    CAT_EXHAUST_FLUE = 103;
    CAT_EXHAUST_POTS = 104;
    CAT_EXHAUST_CATALYST = 105;
    CAT_EXHAUST_MISC = 106;
    CAT_WHEELS_MISC = 201;
    CAT_WHEELS_RIMS = 202;
    CAT_WHEELS_TYRES = 203;
    CAT_VEHICLE_MISC = 301;
    CAT_BRAKES_MISC = 401;
    CAT_BRAKES_DISC = 402;
    CAT_BRAKES_DRUM = 403;
    CAT_BRAKES_PIPE = 404;
    CAT_BRAKES_CYLINDER = 405;
    CAT_BRAKES_BOOSTER = 406;
    // Fuel types
    FUEL_MISC = 1;
    FUEL_BENZINE = 2;
    FUEL_DIESEL = 3;
    FUEL_GAS = 4;
    FUEL_WOOD = 5;
    FUEL_ELECTRIC = 6;
    FUEL_KEROSENE = 7;
    // Project goals
    GOAL_ORIGINAL = 1;
    GOAL_MODIFICATION = 2;
    GOAL_SELFMADE = 3;
    GOAL_ONLYDRIVE = 4;
    GOAL_CONVERSION = 5;
    // Professional type
    PRO_SELLER = 1;
    PRO_PROVIDER = 2;
    PRO_ORGANIZER = 3;
    // Media orientation
    ORIENTATION_LEFT = 1;
    ORIENTATION_RIGHT = 2;
    // File types
    FILE_IMAGE = 1;
    FILE_VIDEO = 2;
    FILE_PDF = 3;
    // Nofitication events
    NTFCN_PROJECT_PUBLISH = 1;
    NTFCN_COMMENT_NEW = 2;
    NTFCN_ANSWER_NEW = 3;
    NTFCN_LIKE_NEW = 4;
    NTFCN_FAVORITE_NEW = 5;
    NTFCN_WELLDONE_NEW = 6;
    NTFCN_PROJECTPART_PUBLISH = 7;
    NTFCN_CONTENT_PUBLISH = 8;
    NTFCN_FEED_POST = 9;
    NTFCN_MESSAGE_NEW = 10;
    NTFCN_FORUM_THREAD_NEW = 11;
    NTFCN_FORUM_MESSAGE_NEW = 12;
    NTFCN_FEED_POST_LIKE_NEW = 13;
    // Notification interval
    NTFCN_INT_NEVER = 0; // Never
    NTFCN_INT_DAILY = 1; // Once per day
    NTFCN_INT_WEEKLY = 2; // Once per week
    NTFCN_INT_EVERY_SECOND_WEEK = 3; // Twice per month
    NTFCN_INT_MONTHLY = 4; // Once per month
    // List order types
    ORDER_NEW = -1;
    ORDER_LIKES = 1;
    ORDER_UPDATES = 2;
    ORDER_RANK = 3;
    ORDER_NAME = 4;
    ORDER_DISTANCE = 5;
    // List order defaults
    PROJECT_DEFAULT_ORDER = 3;
    //Responsive breakpoints
    BREAKPOINT_MOBILE = 320;
    BREAKPOINT_TABLET = 768;
    BREAKPOINT_COMPUTER = 992;
    //REACT PRETTY NUMBERS OPTIONS
    PRETTY_CONFIG = {
        'shortFormat': true,
        'shortFormatMinValue': 10000,
        'shortFormatPrecision': 1,
        'justification': 'L',
    }
    PRETTY_NUMERIC_CONFIG = {
        locales : 'de-DE',
        // currency: true,
        commafy: true,
        justification: 'L',
    }
    // Input config
    TINY_INIT = {
        min_height: 32,
        max_height: 320,
        autoresize_overflow_padding: 16,
        menubar: false,
        plugins: ['link', 'lists', 'autoresize', 'emoticons', 'paste'],
        bbcode_dialect: 'punbb',
        toolbar: 'bold italic underline link | bullist numlist outdent indent | emoticons removeformat | blockquote ',
        branding: false,
        link_title: false,
        target_list: false,
        language: 'de',
    }
    TINY_INIT_PROFESSIONALS = {
        min_height: 32,
        max_height: 320,
        autoresize_overflow_padding: 16,
        menubar: false,
        plugins: ['lists', 'autoresize', 'paste'],
        bbcode_dialect: 'punbb',
        toolbar: 'bullist',
        branding: false,
        link_title: false,
        target_list: false,
        language: 'de',
    }
    //Blog config
    BLOG_PAGE_SIZE = 6; //IMPORTANT: Changes have to be done in API too.
    // Generic forum themes
    FORUM_OLDTIMER = 1;
    FORUM_EVENTS = 2;
    FORUM_TOOLS = 3;
    FORUM_OFFTOPIC = 4;
    // Forum thread - messages per Page
    FORUMTHREAD_PAGE_SIZE = 10; //IMPORTANT: Changes have to be done in API too.
    GTM_ENABLED = true;
    COUNTRY_CODES = [
        {value: 'DE', label: 'DE'},
        {value: 'AT', label: 'AT'},
        {value: 'CH', label: 'CH'}
    ];
    // Dots Slide settings...
    SLIDE_DB_SETTINGS = {
        dots: true,
        arrows: false,
        infinite: false,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 3,
    };
    //HTML Geolocation settings...
    GEOLOCATION_SETTINGS = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
    }

    constructor() {
        // Development config
        if (process.env.NODE_ENV === 'development') {
            //Disable Google Tag Manager in development
            this.GTM_ENABLED = false;

            this.API_HOST = 'https://dev.vetero.de';
            this.API_PUSH_KEY = 'BPOtTHI3AlonjKPPHdDIBhqSDono8iJIlCjTiqZQMtivnGUws2xPC2+OlArSjrWHJjuzWWq+uMm/jP91TZsiu98=';

            // Local
            // this.API_HOST = 'http://localhost/vetero/web/app_dev.php';
            // this.API_PUSH_KEY = 'BI449Ixig8VHONTf7YwS+yjJ7AfQVaUErqRjucalusVtwfEG1ilRq/gDEPLoPtD1ixya1DQ4A+mCXIFziLICMQU=';
        }

        if(window.location.hostname === "appdev.vetero.de"){
            // Disable GTM on testing subdomain (Staging)
            this.GTM_ENABLED = false

            // Staging
            this.API_HOST = 'https://dev.vetero.de';
            this.API_PUSH_KEY = 'BPOtTHI3AlonjKPPHdDIBhqSDono8iJIlCjTiqZQMtivnGUws2xPC2+OlArSjrWHJjuzWWq+uMm/jP91TZsiu98=';
        }

    }

    // Singleton
    static instance = null;
    static getInstance() {
        if (config.instance === null) {
            config.instance = new config();
        }
        return this.instance;
    }

    isChanged(item, fields, editorValue) {
        var changed = false;
        var that = this;

        fields.map(function(field) {
            var int1 = that.isNormalInteger(item[field]);
            var int2 = that.isNormalInteger(item.input[field]);
            
            if (
                (int1 && int2 && parseInt(item[field]) !== parseInt(item.input[field])) ||
                ((!int1 || !int2) && !(that.isEmpty(item[field]) && that.isEmpty(item.input[field])) && JSON.stringify(item[field]) !== JSON.stringify(item.input[field]))
            ) {
                changed = true;
                
            }

            //Check arrays
            if(Array.isArray(item[field]) && Array.isArray(item.input[field])){
                
                if(item[field].length !== item.input[field].length) changed = true;
            }

            //Workaround for tinyMCE Editor Validation 
            if(editorValue){
                let val = item[editorValue] && item[editorValue].replace(/\s/g,'');
                let valInput = item.input[editorValue] && item.input[editorValue].replace(/\s/g,'');
                
                if(val !== valInput){
                    changed = true;
                }
            }

        });

        return changed;
    }

    getImageRatio(img){
        
        let image = new Image();
        image.src = img;

        if (image.width > image.height){
            return "landscape"
        } 
        else if (image.width < image.height){
            return "portrait"
        } 
        else {
            return "square"
        }
         
    }

    getRadius(){
        return [
            {value: null, label: '-'},
            {value: 25000, label: '25km'},
            {value: 50000, label: '50km'}, 
            {value: 100000, label: '100km'},
            {value: 200000, label: '200km'},
            {value: 500000, label: '500km'},  
        ]
    }

    isNormalInteger(value) {
        if (Number.isInteger(value)) {
            return true;
        }
        if (typeof value === 'string') {
            var n = Math.floor(Number(value));
            return n !== Infinity && String(n) === value && n >= 0;
        }
        return false;
    }

    isEmpty(value) {
        if (!value && value !== 0) {
            return true;
        }
        return false;
    }

    getDirectUrl(path)
    {
        let API_HOST = this.API_HOST.replace(/\/app_dev\.php/, '');
        return API_HOST + "/" + path;
    }

    getDirectSrc(src)
    {
        src = src.replace(/\/app_dev\.php/, '');
        src = src.replace(/\/resolve/, '');
        return src;
    }

    addEmpty(addEmpty) {
        var add = [];
        if (addEmpty) {
            add.push({ value: '', label: '–' });
        }
        return add;
    }

    getDefault(values, defaultvalue) {
        if (defaultvalue === null || defaultvalue === undefined ) {
            defaultvalue = '';
        }
        var defaultobj = {};
        values.forEach(function(item) {
            if (String(item.value) === String(defaultvalue)) {
                defaultobj = item;
            }
            else if (Array.isArray(item.options)) {
                item.options.forEach(function(item2) {
                    if (String(item2.value) === String(defaultvalue)) {
                        defaultobj = item2;
                    }
                })
            }
        })
        return defaultobj;
    }

    getLabel(options, value) {
        var label = null;

        for (var key = 0; key < options.length; ++key) {
            if (options[key].options) {
                label = this.getLabel(options[key].options, value);
            }
            else if (options[key].value === value) {
                label = options[key].label;
            }
            if (label) {
                break;
            }
        }

        return label;
    }

    getOrdersDropdown() {
        return [
            { value: this.ORDER_RANK, text: 'Aktivste zuerst (VRS)' },
            { value: this.ORDER_LIKES, text: 'Beliebteste zuerst' },
            { value: this.ORDER_UPDATES, text: 'Aktuellste zuerst' },
            { value: this.ORDER_NEW, text: 'Neuste zuerst' },
        ]
    }

    getForumOrdersDropdown() {
        return [
            { value: this.ORDER_NEW, text: 'Neuste Frage' },
        ]
    }

    getIntervals() {
        return [
            { value: this.NTFCN_INT_NEVER, label: 'Nie' },
            { value: this.NTFCN_INT_DAILY, label: 'Täglich' },
            { value: this.NTFCN_INT_WEEKLY, label: 'Wöchentlich' },
            { value: this.NTFCN_INT_EVERY_SECOND_WEEK, label: '2 Wöchentlich' },
            { value: this.NTFCN_INT_MONTHLY, label: 'Monatlich' },
        ]
    }

    getScopes() {
        return [
            { value: this.SCOPE_BIKE, label: 'Zweirad' },
            { value: this.SCOPE_CAR, label: 'Automobil' },
            { value: this.SCOPE_UTILITY, label: 'Nutzfahrzeug' },
        ]
    }

    getCountryCodes(){
        return [
            { value: 'DE', label: 'Deutschland' },
            { value: 'AT', label: 'Österreich' },
            { value: 'CH', label: 'Schweiz' },
          ]
    }

    getProfessionalType() {
        return [
            { value: this.PRO_SELLER, label: 'Verkäufer' },
            { value: this.PRO_ORGANIZER, label: 'Veranstalter' },
            { value: this.PRO_PROVIDER, label: 'Dienstleister' },
        ]
    }

    getScope(value){ // TODO: Replace by getDefault
        this.getScopes().filter(scope => scope.label === value).map((scope) => scope);
    }

    getOrientations() {
        return [
            { value: this.ORIENTATION_LEFT, label: 'Links' },
            { value: this.ORIENTATION_RIGHT, label: 'Rechts' },
        ]
    }

    getDesigns(scope, addEmpty = true)
    {
        var add = this.addEmpty(addEmpty)
        scope = parseInt(scope)

        if (scope === this.SCOPE_BIKE) {
            return [...add, ...this.getDesignsBike()]
        }
        if (scope === this.SCOPE_CAR) {
            return [...add, ...this.getDesignsCar()]
        }
        if (scope === this.SCOPE_UTILITY) {
            return [...add, ...this.getDesignsUtility()]
        }
    }

    getDesignsBike()
    {
        return [
            { value: this.DESIGN_AUTOCYCLE, label: 'Mofa' },
            { value: this.DESIGN_MOPED, label: 'Mokick/Moped (50er/80er)' },
            { value: this.DESIGN_SCOOTER, label: 'Roller' },
            { label: 'Motorrad', options: [
                { value: this.DESIGN_MC_TOURER, label: 'Tourer' },
                { value: this.DESIGN_MC_SPORT, label: 'Sport' },
                { value: this.DESIGN_MC_CHOPPER, label: 'Chopper/Cruiser' },
                { value: this.DESIGN_MC_CROSS, label: 'Cross' },
                { value: this.DESIGN_MC_TRAILER, label: 'Gespann' },
                { value: this.DESIGN_MC_MISC, label: 'Sonstiges' },
            ] },
            { value: this.DESIGN_TRIKE, label: 'Trike' },
            { value: this.DESIGN_MISC, label: 'Sonstiges' },
        ]
    }

    getDesignsCar()
    {
        return [
            { value: this.DESIGN_LIMO, label: 'Limousine' },
            { value: this.DESIGN_ESTATE, label: 'Kombi' },
            { value: this.DESIGN_COUPE, label: 'Coupé' },
            { value: this.DESIGN_CABRIO, label: 'Cabrio' },
            { value: this.DESIGN_ROADSTER, label: 'Roadster' },
            { value: this.DESIGN_ALLTERRAIN, label: 'Geländewagen' },
            { value: this.DESIGN_PICKUP, label: 'Pritschenwagen' },
            { value: this.DESIGN_VAN, label: 'Bus/Van' },
            { value: this.DESIGN_TRANSPORT, label: 'Transporter' },
            { value: this.DESIGN_MISC, label: 'Sonstiges' },
        ]
    }

    getDesignsUtility()
    {
        return [
            { value: this.DESIGN_TRACTOR, label: 'Traktor/Schlepper' },
            { value: this.DESIGN_AGRICULTURAL, label: 'Landmaschine' },
            { value: this.DESIGN_CONSTRUCTION, label: 'Baumaschine' },
            { value: this.DESIGN_BUS, label: 'Bus' },
            { value: this.DESIGN_TRUCK, label: 'LKW' },
            { value: this.DESIGN_ARTICULATED, label: 'Sattelzugmaschine' },
            { value: this.DESIGN_UNIVERSAL, label: 'Universal/unimog' },
            { value: this.DESIGN_MISC, label: 'Sonstiges' },
        ]
    }

    getEngines(addEmpty = true)
    {
        var add = this.addEmpty(addEmpty)
        return [
            ...add,
            { value: this.ENGINE_NONE, label: 'Ohne' },
            { value: this.ENGINE_FOURSTROKE, label: 'Viertakt' },
            { value: this.ENGINE_TWOSTROKE, label: 'Zweitakt' },
            { value: this.ENGINE_WANKEL, label: 'Wankel' },
            { value: this.ENGINE_ELECTRIC, label: 'Elektro' },
            { value: this.ENGINE_STEAM, label: 'Dampf' },
            { value: this.ENGINE_MISC, label: 'Sonstiges' },
        ]
    }

    getGears(addEmpty = true)
    {
        var add = this.addEmpty(addEmpty)
        return [
            ...add,
            { value: this.GEAR_NONE, label: 'Ohne' },
            { value: this.GEAR_MANUAL, label: 'Schaltung' },
            { value: this.GEAR_SEMIAUTO, label: 'Halbautomatik' },
            { value: this.GEAR_AUTO, label: 'Automatik' },
            { value: this.GEAR_MISC, label: 'Sonstiges' },
        ]
    }

    getGears(addEmpty = true)
    {
        var add = this.addEmpty(addEmpty)
        return [
            ...add,
            { value: this.GEAR_NONE, label: 'Ohne' },
            { value: this.GEAR_MANUAL, label: 'Schaltung' },
            { value: this.GEAR_SEMIAUTO, label: 'Halbautomatik' },
            { value: this.GEAR_AUTO, label: 'Automatik' },
            { value: this.GEAR_MISC, label: 'Sonstiges' },
        ]
    }

    getDrives(addEmpty = true)
    {
        var add = this.addEmpty(addEmpty)
        return [
            ...add,
            { value: this.DRIVE_NONE, label: 'Ohne' },
            { value: this.DRIVE_FRONT, label: 'Vorderrad' },
            { value: this.DRIVE_BACK, label: 'Hinterrad' },
            { value: this.DRIVE_ALL, label: 'Allrad' },
            { value: this.DRIVE_MISC, label: 'Sonstiges' },
        ]
    }

    getFuels(addEmpty = true)
    {
        var add = this.addEmpty(addEmpty)
        return [
            ...add,
            { value: this.FUEL_BENZINE, label: 'Benzin' },
            { value: this.FUEL_DIESEL, label: 'Diesel/Öl' },
            { value: this.FUEL_GAS, label: 'Gas' },
            { value: this.FUEL_WOOD, label: 'Holz/Kohle' },
            { value: this.FUEL_ELECTRIC, label: 'Strom' },
            { value: this.FUEL_KEROSENE, label: 'Kerosin' },
            { value: this.FUEL_MISC, label: 'Sonstiges' },
        ]
    }

    getLayouts(addEmpty = true)
    {
        var add = this.addEmpty(addEmpty)
        return [
            ...add,
            { value: this.LAYOUT_NOENGINE, label: 'Ohne' },
            { value: this.LAYOUT_LINE, label: 'Reihe' },
            { value: this.LAYOUT_V, label: 'V' },
            { value: this.LAYOUT_BOXER, label: 'Boxer' },
            { value: this.LAYOUT_STAR, label: 'Stern' },
            { value: this.LAYOUT_MISC, label: 'Sonstiges' },
        ]
    }

    getGoals(addEmpty = true)
    {
        var add = this.addEmpty(addEmpty)
        return [
            ...add,
            { value: this.GOAL_ORIGINAL, label: 'Originalgetreue Restauration' },
            { value: this.GOAL_MODIFICATION, label: 'Restauration mit Modifikationen' },
            { value: this.GOAL_SELFMADE, label: 'Basis für Eigenbau' },
            { value: this.GOAL_ONLYDRIVE, label: 'Einfach nur fahren' },
            { value: this.GOAL_CONVERSION, label: 'Umbau' },
        ]
    }

    /**
     * Get sub categories
     */
    getCategoriesSub(addEmpty = true, addMainLabel = false)
    {
        var add = this.addEmpty(addEmpty)
        return [
            ...add,
            { label: 'Fahrzeug', options: [
                { value: this.CAT_VEHICLE_MISC, label: addMainLabel ? 'Fahrzeug Allgemein' : 'Allgemein' },
            ] },
            { label: 'Motor/Getriebe', options: [
                { value: this.CAT_ENGINE_MISC, label: addMainLabel ? 'Motor/Getriebe Allgemein' : 'Allgemein' },
                { value: this.CAT_ENGINE_BLOCK, label: 'Motorblock' },
                { value: this.CAT_ENGINE_CYLINDER, label: 'Zylinderkopf' },
                { value: this.CAT_ENGINE_VALVE, label: 'Ventile/Nockenwellen' },
                { value: this.CAT_ENGINE_CRANKSHAFT, label: 'Kurbelwelle' },
                { value: this.CAT_ENGINE_PISTON, label: 'Kolben/Pleul' },
                { value: this.CAT_ENGINE_TIMING, label: 'Steuerzeiten' },
                { value: this.CAT_ENGINE_FUEL, label: 'Kraftstoffversorgung' },
                { value: this.CAT_ENGINE_MIXTURE, label: 'Gemischaufbereitung' },
                { value: this.CAT_ENGINE_COOLING, label: 'Kühlung' },
                { value: this.CAT_ENGINE_GEAR, label: 'Getriebe' },
                { value: this.CAT_ENGINE_CLUTCH, label: 'Kupplung' },
            ] },
            { label: 'Fahrwerk', options: [
                { value: this.CAT_CARRIAGE_MISC, label: addMainLabel ? 'Fahrwerk Allgemein': 'Allgemein' },
                { value: this.CAT_CARRIAGE_FRONTAXLE, label: 'Vorderachse' },
                { value: this.CAT_CARRIAGE_REARTAXLE, label: 'Hinterachse' },
                { value: this.CAT_CARRIAGE_SUSPENSION, label: 'Federung' },
                { value: this.CAT_CARRIAGE_ABSORBER, label: 'Stoßdämpfer' },
                { value: this.CAT_CARRIAGE_FORK, label: 'Gabel' },
                { value: this.CAT_CARRIAGE_SWINGARM, label: 'Schwinge' },
                { value: this.CAT_CARRIAGE_DRIVESHAFT, label: 'Antriebswelle' },
            ] },
            { label: 'Räder', options: [
                { value: this.CAT_WHEELS_MISC, label: addMainLabel ? 'Räder Allgemein' : 'Allgemein' },
                { value: this.CAT_WHEELS_RIMS, label: 'Felgen' },
                { value: this.CAT_WHEELS_TYRES, label: 'Reifen' },
            ] },
            { label: 'Bremsen', options: [
                { value: this.CAT_BRAKES_MISC, label: addMainLabel ? 'Bremsen Allgemein' : 'Allgemein' },
                { value: this.CAT_BRAKES_DISC, label: 'Scheibenbremse' },
                { value: this.CAT_BRAKES_DRUM, label: 'Trommelbremse' },
                { value: this.CAT_BRAKES_PIPE, label: 'Bremsleitung' },
                { value: this.CAT_BRAKES_CYLINDER, label: 'Haupt-/Bremszylinder' },
                { value: this.CAT_BRAKES_BOOSTER, label: 'Bremskraftverstärker' },
            ] },
            { label: 'Elektrik/Zündung', options: [
                { value: this.CAT_ELECTRICS_MISC, label: addMainLabel ? 'Allgemein Elektrik/Zündung' : 'Allgemein' },
                { value: this.CAT_ELECTRICS_BATTERY, label: 'Batterie' },
                { value: this.CAT_ELECTRICS_DYNAMO, label: 'Lichtmaschine' },
                { value: this.CAT_ELECTRICS_IGNITION, label: 'Zündung' },
                { value: this.CAT_ELECTRICS_EXTERIORLIGHT, label: 'Außenbeleuchtung' },
                { value: this.CAT_ELECTRICS_INTERIORLIGHT, label: 'Innenbeleuchtung' },
                { value: this.CAT_ELECTRICS_STOPLIGHT, label: 'Bremslicht' },
                { value: this.CAT_ELECTRICS_HARNESS, label: 'Kabelbaum' },
                { value: this.CAT_ELECTRICS_FUSE, label: 'Sicherung/Sicherungskasten' },
            ] },
            { label: 'Karosserie/Rahmen', options: [
                { value: this.CAT_BODY_MISC, label: addMainLabel ? 'Allgemein Karosserie/Rahmen' : 'Allgemein' },
                { value: this.CAT_BODY_FRAME, label: 'Rahmen/Karosserie' },
                { value: this.CAT_BODY_DOOR, label: 'Türen' },
                { value: this.CAT_BODY_FRONTLID, label: 'Motorhaube' },
                { value: this.CAT_BODY_CARGOBAY, label: 'Kofferraumdeckel' },
                { value: this.CAT_BODY_ROOF, label: 'Dach' },
                { value: this.CAT_BODY_VARNISH, label: 'Grundierung/Lack' },
                { value: this.CAT_BODY_SEALING, label: 'Versiegelung' },
                { value: this.CAT_BODY_WINDOW, label: 'Fenster' },
                { value: this.CAT_BODY_MIRROR, label: 'Spiegel' },
            ] },
            { label: 'Interieur/Armaturenbrett', options: [
                { value: this.CAT_INTERIOR_MISC, label: addMainLabel ? 'Allgemein Interieur/Armaturenbrett' : 'Allgemein' },
                { value: this.CAT_INTERIOR_SEAT, label: 'Sitze' },
                { value: this.CAT_INTERIOR_BENCH, label: 'Sitzbank' },
                { value: this.CAT_INTERIOR_COVER, label: 'Verkleidungen' },
                { value: this.CAT_INTERIOR_DASHBOARD, label: 'Armaturenbrett' },
                { value: this.CAT_INTERIOR_HATRACK, label: 'Hutablage' },
                { value: this.CAT_INTERIOR_CEILING, label: 'Himmel' },
                { value: this.CAT_INTERIOR_CARPET, label: 'Teppiche' },
                { value: this.CAT_INTERIOR_CONSOLE, label: 'Mittelkonsole' },
                { value: this.CAT_INTERIOR_STEERING, label: 'Lenkrad' },
                { value: this.CAT_INTERIOR_MIRROR, label: 'Innenspiegel' },
            ] },
            { label: 'Abgasanlage', options: [
                { value: this.CAT_EXHAUST_MISC, label: addMainLabel ? 'Allgemein Abgasanlage' : 'Allgemein' },
                { value: this.CAT_EXHAUST_MANIFOLD, label: 'Krümmer' },
                { value: this.CAT_EXHAUST_FLUE, label: 'Flammrohr' },
                { value: this.CAT_EXHAUST_POTS, label: 'Töpfe' },
                { value: this.CAT_EXHAUST_CATALYST, label: 'Katalysator' },
            ] },
        ];
    }

    getForums()
    {
        return [
            { value: this.FORUM_OLDTIMER, label: 'Fragen zu Oldtimern' },
            { value: this.FORUM_EVENTS, label: 'Treffen, Events, Rallyes, Ausfahrten' },
            { value: this.FORUM_TOOLS, label: 'Werkzeug' },
            { value: this.FORUM_OFFTOPIC, label: 'Offtopic' },
        ]
    }

    getImageUrl(view, path)
    {
        if (!path) {
            return null;
        }

        return this.API_HOST + '/media/cache/resolve/' + view + '/' + path;
    }

    getFormattedDate(timestamp) {
        // var d = new Date();
        // var date = new Date(timestamp * 1000 + d.getTimezoneOffset() * 60000); // TODO: useful?

        if (!timestamp) {return '—';}
        var date = new Date(timestamp * 1000);
        return date.toLocaleDateString('de-DE');
    }

    getFormattedCurrency(price) {
        if (!price) {return '0,00';}
        return price.toLocaleString('de-DE', { minimumFractionDigits: 2 });
    }

    getFormattedInt(number) {
        if (!number) {return '0';}
        return number.toLocaleString('de-DE', { minimumFractionDigits: 0 });
    }

    buildChoice(items, namefield, addEmpty = true)
    {
        var add = this.addEmpty(addEmpty);
        var choices = [];

        if (typeof items === 'object') {
            items = Object.values(items);
        }

        items.map(function(item) {
            choices.push({ value: item.id, label: item[namefield] });
        })

        return [
            ...add,
            ...choices,
        ];
    }

    countryChoice(addEmpty = true)
    {
        var add = this.addEmpty(addEmpty);
        var choices = [];

        let countries = require("i18n-iso-countries");
        countries.registerLocale(require("i18n-iso-countries/langs/de.json"));
        let names = countries.getNames('de');

        for (let [key, value] of Object.entries(names)) {
            choices.push({ value: key, label: value });
        }

        return [
            ...add,
            ...choices,
        ];
    }

    validateTextField(value, requiered){
        // let patt = new RegExp("/^\S*$/");
        if(value == null) value = ''
        let trim = value.trim();

        if(!requiered) requiered = false;

        if(requiered && !value ){
            return {
                error: true,
                message: "Fülle das Feld aus."
            }
        }
        else if(!trim) {
            return {
                error: true,
                message: "Die Angabe ist falsch. Überprüfe deine Angaben."
            }
        }
        else {
            return {
                error: false,
                message: ''
            }
        }
    }

    numericFields(value, requiered){
        // let patt = new RegExp("^[^-\s][a-zA-Z0-9_\s-]+$");
        let patt = new RegExp('^[0-9]*$')
        if(!requiered) requiered = false;

        if(requiered && !value){
            return {
                error: true,
                message: "Fülle das Feld aus."
            }
        }
        else if(!requiered && value == null){
            return {
                error: false,
                message: ''
            }
        }
        else if(!patt.test(value) ) {
            return {
                error: true,
                message: "Der eingegebener Wert ist falsch. Überprüfe deine Angaben."
            }
        }
        else {
            return {
                error: false,
                message: ''
            }
        }

    }

    validationPointNumbers(value, requiered){
        // let patt = new RegExp("^[^-\s][a-zA-Z0-9_\s-]+$");
        let patt = new RegExp('(^$)|^[0-9]+([,.][0-9]{1,2})?$')
        if(!requiered) requiered = false;
        if(requiered && !value){
            return {
                error: true,
                message: "Fülle das Feld aus."
            }
        }
        else if(!requiered && value == null){
            return {
                error: false,
                message: ''
            }
        }
        else if(!patt.test(value) ) {
            return {
                error: true,
                message: "Der eingegebener Wert ist falsch. Überprüfe deine Angaben."
            }
        }
        else {
            return {
                error: false,
                message: ''
            }
        }

    }

    validateProductionYear(value){
        let patt = new RegExp("^[0-9]+$|^$");
        if(!value) {
            return {
                error: true,
                message: "Fülle die markierte Felder aus."
            }
        }
        else if(!patt.test(value) ) {
            return {
                error: true,
                message: "Der eingegebener Wert ist falsch. Überprüfe deine Angaben."
            }
        }
        else if (parseInt(value) > parseInt(new Date().getFullYear()) - 25) {
            return {
                error: true,
                message: "Das Fahrzeug muss mindestens 25 Jahre alt sein."
            }
        }
        else if(value.length < 4){
            return {
                error: true,
                message: "Der eingegebener Wert ist falsch. Überprüfe deine Angaben."
            }
        }
        else {
            return {
                error: false,
                message: ''
            }
        }
    }

    validateWorkingHoursDate(startDate, endDate){
        let currentDate = Math.floor(Date.now() / 1000);

        let start = parseInt(startDate);
        let end = parseInt(endDate);

        let output = {
            validationDateStart: {
                error: false,
                message: ''
            },
            validationDateEnd: {
                error: false,
                message: ''
            }
        };

        if(start){
            if(start > currentDate){
                output.validationDateStart = {
                    error: true,
                    message: "Das eingegebene Datum darf nicht in der Zukunft liegen."
                }
            }
            else if(start < currentDate){
                output.validationDateStart = {
                    error: false,
                    message: ''
                }
            }
        }

        if(end){
            if(end < start) {
                output.validationDateEnd = {
                    error: true,
                    message: "Das eigegebene Datum darf nicht vor dem Startdatum liegen."
                }
            }
            else if(end > currentDate){
                output.validationDateEnd = {
                    error: true,
                    message: "Das eingegebene Datum darf nicht in der Zukunft liegen."
                }
            }
            else {
                output.validationDateEnd = {
                    error: false,
                    message: ''
                }
            }
        }

        return output;
    }

    validateMedia = (value) => {

        //Multipile items...
        if(Array.isArray(value) && value.length == 0){
            return {
                error: true,
                message: 'Lade die Datei hoch'
            }
        }

        if(!value){
            return {
                error: true,
                message: 'Lade die Datei hoch'
            }
        }
        else {
            return {
                error: false,
                message: ''
            }
        }
    }

    validateSelectInput = (value) => {
        //Check if empty
        if(!value || value == null) {
            return {
                error: true,
                message: 'Wähle eine Option aus'
            }
        }
        else {
            return {
                error: false,
                message: ''
            }
        }
    }

    //Convert point numbers to float
    convertToFloat = (value) => {
        let val = value && parseFloat(value.toString().replace(/,/g, '.'));
        let output = isNaN(val) || !val ? null : val;

        return output;
    }

    convertToPointNumber = (value) => {
        let val = value && value.toString().replace(".", ",");
        let output = !val ? null : val;

        return output;
    }

    handleOrientation = () => {
        let orientation = (window.screen.orientation || {}).type || window.screen.orientation|| window.screen.orientation;

        return orientation;
    }

    getDeviceType = () => {

        if(window.innerWidth) {
            if(window.innerWidth < this.BREAKPOINT_TABLET) {
                return 'mobile'
            }
            else if (window.innerWidth >= this.BREAKPOINT_TABLET && window.innerWidth < this.BREAKPOINT_COMPUTER) {
                return 'tablet'
            }
            else {
                return 'computer'
            }
        }
    }

}

export default config.getInstance()
