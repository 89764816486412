
export default function singleFieldValidation(field, item, customErrMsg){
    
    if(!customErrMsg) customErrMsg = "Fülle das Feld aus";

    if(!item){
        return {
            [field]: {
                error: true,
                errorMsg: customErrMsg
            }
        }
    }

    else {
        return {
            [field]: {
                error: false,
                errorMsg: null
            }
        }
    }

}

