import config from '../config/config';
import {
    CHANGE_FORUMTHREAD,
    API_FORUMTHREAD_REQUEST,
    API_FORUMTHREAD_SUCCESS,
    API_FORUMTHREAD_FAILURE,
    API_FORUMTHREAD_DELETE_SUCCESS,
    API_FORUMTHREAD_LIST_SUCCESS,
    API_FOLLOW_FORUMTHREAD_REQUEST,
    API_FOLLOW_FORUMTHREAD_SUCCESS,
    API_FOLLOW_FORUMTHREAD_FAILURE,
    API_POST_FORUMTHREAD_REQUEST,
    API_POST_FORUMTHREAD_SUCCESS,
    API_POST_FORUMTHREAD_FAILURE,
    CHANGE_MESSAGE,
    API_UPDATE_FORUM_MESSAGE_FAILURE,
    API_UPDATE_FORUM_MESSAGE_REQUEST,
    API_UPDATE_FORUM_MESSAGE_SUCCESS,
    API_USERTHREADS_SUCCESS,
} from '../actions/forumthread'
import {
    RESET_STORE,
} from '../actions/account'
import {actions} from 'ix-redux-file-upload';


export default function forumthreadById(state = {}, action) {

    try {

        if (action.type === RESET_STORE) {
            return {};
        }

        if (action.type === CHANGE_MESSAGE ||
            action.type === API_UPDATE_FORUM_MESSAGE_REQUEST||
            action.type === API_UPDATE_FORUM_MESSAGE_SUCCESS||
            action.type === API_UPDATE_FORUM_MESSAGE_FAILURE) {

                var changesItem = {};
                var changes = {};

                if (action.type === CHANGE_MESSAGE) {
                    changesItem = {
                        inputText: action.payload.text,
                        inputMedia: action.payload.media,
                    };
                }

                else if (action.type === API_UPDATE_FORUM_MESSAGE_REQUEST) {
                    changes = {
                        isUpdating: true,
                        updatingMessage: '',
                    };
                }

                else if (action.type === API_UPDATE_FORUM_MESSAGE_SUCCESS) {
                    changesItem = {
                        ...action.payload.forummessage,
                    };
                    changes = {
                        isUpdating: false,
                        inputText: action.payload.forummessage.text,
                        inputMedia: (action.payload.forummessage ? JSON.parse(JSON.stringify(action.payload.forummessage.media)) : null),
                        updatingMessage: 'SUCCESS',
                    };
                }

                else if (action.type === API_UPDATE_FORUM_MESSAGE_FAILURE) {
                    changesItem = {
                        ...action.payload.forummessage
                    };
                    changes = {
                        isUpdating: false,
                        inputText: action.payload.forummessage.text,
                        inputMedia: (action.payload.forummessage ? JSON.parse(JSON.stringify(action.payload.forummessage.media)) : null),
                        updatingMessage: 'FAILURE',
                    };
                }

                return {
                    ...state,
                    [action.meta.threadId]: {
                        ...state[action.meta.threadId],
                        pages: {
                            ...state[action.meta.threadId].pages,
                            [action.meta.page]: {
                                ...state[action.meta.threadId].pages[action.meta.page],
                                [action.meta.msgId]: {
                                    ...state[action.meta.threadId].pages[action.meta.page][action.meta.msgId],
                                    forummessage: {
                                        ...state[action.meta.threadId].pages[action.meta.page][action.meta.msgId].forummessage,
                                        ...(changesItem ? changesItem : {}),
                                        ...changes
                                    },
                                }
                            }
                        }
                    }
                };
        }

        if (action.type === CHANGE_FORUMTHREAD) {
            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    ...action.payload,
                }
            };
        }

        if (action.type === API_FORUMTHREAD_REQUEST) {
            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    isFetching: true,
                    lastFetch: Date.now(),
                }
            };
        }
        if (
            action.type === API_FORUMTHREAD_SUCCESS ||
            (action.type === API_POST_FORUMTHREAD_SUCCESS && action.meta.id)
        ) {

            var messages = {};

            if (action.type === API_POST_FORUMTHREAD_SUCCESS) {
                state = {
                    ...state,
                    [action.payload.forumthread.id]: {
                        ...state[action.payload.forumthread.id],
                        postingMessage: 'SUCCESS',
                        isPosting: false,
                        inputText: '',
                        inputMedia: [],
                    }
                };

                messages[action.payload.forummessage.id] = {
                    forummessage: {
                        ...action.payload.forummessage,
                        inputMedia: (action.payload.forummessage.media ? JSON.parse(JSON.stringify(action.payload.forummessage.media)) : null),
                    }
                };

            }
            else {
                Object.values(action.payload.list).map((item) => {
                    messages[item.forummessage.id] = {
                        ...item,
                        forummessage: {
                            ...item.forummessage,
                            inputMedia: (item.forummessage.media ? JSON.parse(JSON.stringify(item.forummessage.media)) : null),
                        }
                    };
                });
            }
            //Get parameters
            let threadId = action.payload.forumthread ? action.payload.forumthread.id : action.meta.id;
            let sumPages = action.payload.sumPages ? action.payload.sumPages : (action.meta.sumPages ? action.meta.sumPages : state[threadId].sumPages);
            let currentPage = action.meta.page ? action.meta.page : sumPages;
            var extPages = {};

            if(!action.meta.page) {
                // TODO: Remove newer messages to prevent conflicts with POST
                // Append several messages to the end of the thread
                // Assuming the last page was fetched (Watcher runs only there)
                if (Object.keys(messages).length > 1) {
                    // Find last populated page
                    for (var i = sumPages; i > 0; --i) {
                        if (state[threadId].pages[i] && Object.keys(state[threadId].pages[i]).length) {
                            var currentkey = i;

                            while (Object.keys(messages).length) {
                                if (!extPages[currentkey]) {
                                    extPages[currentkey] = {...(state[threadId].pages[currentkey] ? state[threadId].pages[currentkey] : {})};
                                }

                                // Append messages til page is full
                                if (Object.keys(extPages[currentkey]).length < config.FORUMTHREAD_PAGE_SIZE) {
                                    var msg = messages[Object.keys(messages)[0]];

                                    extPages[currentkey][msg.forummessage.id] = {...msg};
                                    delete messages[Object.keys(messages)[0]];
                                }
                                else {
                                    console.log('increase', Object.keys(extPages[currentkey]).length);
                                    ++currentkey;
                                }
                            }

                            break;
                        }
                    }
                }
            }

            if (!Object.keys(extPages).length) {
                // Default behaviour
                extPages[currentPage] = {
                    ...(state[threadId] && state[threadId].pages && state[threadId].pages[currentPage] ? state[threadId].pages[currentPage] : {}),
                    ...messages,
                };
            }

            return {
                ...state,
                [threadId]: {
                    ...state[threadId],
                    isFetching: false,
                    isFailed: false,
                    hasMore: action.payload.hasMore,
                    sumPages,
                    item: {
                        ...(state[threadId].item ? state[threadId].item: {}),
                        ...action.payload.forumthread ? action.payload.forumthread : {},
                    },
                    pages: {
                        ...(state[threadId] && state[threadId].pages ? state[threadId].pages : {}),
                        ...extPages,
                    },
                    lastUpdated: Date.now(),
                }
            };
        }
        if (action.type === API_FORUMTHREAD_FAILURE) {
            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    isFetching: false,
                    isFailed: true,
                    lastUpdated: Date.now(),
                    code: action.payload.code
                }
            };
        }

        else if (action.type === API_POST_FORUMTHREAD_REQUEST && action.meta.id) {
            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    isPosting: true,
                    postingMessage: '',
                }
            };
        }
        else if (action.type === API_POST_FORUMTHREAD_FAILURE && action.meta.id) {
            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    isPosting: false,
                    postingMessage: 'FAILURE',
                }
            };
        }

        if (action.type === API_FORUMTHREAD_LIST_SUCCESS || action.type === API_USERTHREADS_SUCCESS) {

            action.payload.list.map((pl) => {
                if (!state[pl.forumthread.id]) {
                    state = {
                        ...state,
                        [pl.forumthread.id]: {
                            item: {},
                            messages: {},
                        }
                    }
                }

                var messages = {};
                if(pl.list) {
                    Object.values(pl.list).map((item) => {
                        messages[item.forummessage.id] = {...item};
                    });
                }

                state = {
                    ...state,
                    [pl.forumthread.id]: {
                        ...state[pl.forumthread.id],
                        isFetching: false,
                        isFailed: false,
                        item: {
                            ...pl.forumthread,
                        },
                        messages: {
                            ...messages,
                        },
                        lastUpdated: Date.now(),
                    }
                };
            });

            return state;
        }

        if (action.type === actions.FILE_UPLOAD_PROGRESS) {
            var identificator = action.payload.identificator.split('.');

            if (identificator[0] === 'forumthread') {
                var key = identificator[1];
                var media = state[key][identificator[2]];

                if (!media) {
                    media = [];
                }

                var updated = false;
                if(media){
                    media.map((item, index) => {
                        if(item.id == action.payload.fileType.uid && !updated){
                            media[index].progress = action.payload.progress;
                            updated = true;
                        }
                    })
                }

                if(!updated) {
                    media.push({
                        id: action.payload.fileType.uid,
                        progress: action.payload.progress,
                        path: 'LOADING',
                        previewPath: "LOADING",
                        position: media.length +1,
                        type: action.payload.isImage ? 1 : 2,
                    });
                }

                state = {
                    ...state,
                    [key]: {
                        ...state[key],
                        [identificator[2]]: media,
                    }
                }

                return state;
            }

            if(identificator[3] === 'inputMedia'){
                var media = state[identificator[0]].pages[identificator[1]][identificator[2]].forummessage.inputMedia;
                
                if (!media) {
                    media = [];
                }

        
                var updated = false;
                if(media){
                    media.map((item, index) => {
                        if(item.id == action.payload.fileType.uid && !updated){
                            media[index].progress = action.payload.progress;
                            updated = true;
                        }
                    })
                }

                if(!updated) {
                    media.push({
                        id: action.payload.fileType.uid,
                        progress: action.payload.progress,
                        path: 'LOADING',
                        previewPath: "LOADING",
                        position: media.length +1,
                        type: action.payload.isImage ? 1 : 2,
                    });
                }

                state = {
                    ...state,
                        [identificator[0]]: {
                            ...state[identificator[0]],
                            pages: {
                                ...state[identificator[0]].pages,
                                [identificator[1]]: {
                                    ...state[identificator[0]].pages[identificator[1]],
                                    [identificator[2]]: {
                                        ...state[identificator[0]].pages[identificator[1]][identificator[2]],
                                        forummessage: {
                                            ...state[identificator[0]].pages[identificator[1]][identificator[2]].forummessage,
                                            inputMedia: media
                                        }
                                    }
                                }
                            }
                        }
                }
                return state;
            }
        }
        if (action.type === actions.FILE_UPLOAD_COMPLETE) {
            var identificator = action.payload.identificator.split('.');

            if (identificator[0] === 'forumthread') {
                var key = identificator[1];
                var media = state[key][identificator[2]];

                if (!media) {
                    media = [];
                }

                var removedLoading = false;
                media.map((mv, mk) => {
                    if ((mv.path === 'LOADING' && mk+1 === mv.position && mv.progress === 100) && !removedLoading) {
                        media[mk] = {
                            ...media[mk],
                            id: action.payload.response.id,
                            path: action.payload.response.path,
                            type: action.payload.response.type,
                            previewPath: action.payload.response.type === 2 ? action.payload.response.previewPath : null
                        };
                        removedLoading = true;
                    }
                });


                state = {
                    ...state,
                    [key]: {
                        ...state[key],
                        [identificator[2]]: media,
                    }
                }

                return state;
            }
            if(identificator[3] === 'inputMedia'){
                var media = state[identificator[0]].pages[identificator[1]][identificator[2]] && state[identificator[0]].pages[identificator[1]][identificator[2]].forummessage.inputMedia;

                if (!media) {
                    media = [];
                }

                var removedLoading = false;
                media.map((mv, mk) => {
                    if ((mv.path === 'LOADING' && mk+1 === mv.position && mv.progress === 100) && !removedLoading) {
                        media[mk] = {
                            ...media[mk],
                            id: action.payload.response.id,
                            path: action.payload.response.path,
                            type: action.payload.response.type,
                            previewPath: action.payload.response.type === 2 ? action.payload.response.previewPath : null
                        };
                        removedLoading = true;
                    }
                });

                state = {
                    ...state,
                        [identificator[0]]: {
                            ...state[identificator[0]],
                            pages: {
                                ...state[identificator[0]].pages,
                                [identificator[1]]: {
                                    ...state[identificator[0]].pages[identificator[1]],
                                    [identificator[2]]: {
                                        ...state[identificator[0]].pages[identificator[1]][identificator[2]],
                                        forummessage: {
                                            ...state[identificator[0]].pages[identificator[1]][identificator[2]].forummessage,
                                            inputMedia: media
                                        }
                                    }
                                }
                            }
                        }
                }
                return state;
            }
        }

        return state;

    } catch (e) {
        console.log(e);
        return {
            ...state,
            fatalError: true,
        };

    }
}
