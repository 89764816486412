import React, { Component } from 'react'
import { PayPalButtons } from '@paypal/react-paypal-js';

export class PayPalButton extends Component {
  render() {
      
    const { onApprove, disabled } = this.props;

    return (
        <PayPalButtons
            style={{ layout: "vertical", label: "subscribe" }}
            disabled={disabled}
            createSubscription={function(data, actions) {
                return actions.subscription.create({
                      plan_id: 'P-07J92028V1222594RMI6W4VA'
                    });
                }}
            onApprove={function(data, actions) {
                onApprove(data.subscriptionID) 
            }}
        />
    )
  }
}

export default PayPalButton