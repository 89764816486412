import React, { Component } from 'react'
import { Image } from 'semantic-ui-react';
import ArrowLeft from '../../images/icons/arow-left.svg'
import './CustomArrows.scss';

export default class NextArrow extends Component {

    constructor(props){
        super(props)
    }

    render() {
        const { onClick } = this.props;

        return (
            <div className="custom-arrow prev">
                <Image src={ArrowLeft} onClick={onClick} alt="Slider Arrow Left"/>
            </div>
        )
    }
}