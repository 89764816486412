import React from 'react';
import {
    Header,
    Container,
    Divider,
    Message,
    Segment,
    Grid,
    Label,
    Icon,
    Loader,
 } from 'semantic-ui-react';
import { connect } from 'react-redux';
import Truncate from 'react-truncate';
import Moment from 'react-moment';
import 'moment/locale/de'
import 'moment-timezone';
import BeParent from './be-parent'
import {newExpenditure, postExpenditure, changeExpenditure} from '../../../actions/expenditure';
import config from '../../../config/config'
import ProgressiveImager from '../../../components/helpers/progressive-imager';
import fetchStoryIfNeeded from '../../../actions/story';
import ImageWrapper from '../../../components/helpers/image-wrapper';
import '../../../components/ReactSelect/customSelect.scss'

import './Backend.scss'


class ExpenditureContent extends BeParent {

    constructor(props) {
        super(props)

        this.handleInput = this.handleInput.bind(this);
        this.handleInputSelect = this.handleInputSelect.bind(this);
        this.handleInputCheckbox = this.handleInputCheckbox.bind(this);
        this.removeContent = this.removeContent.bind(this);
        this.addContent = this.addContent.bind(this);
        this.submit = this.submit.bind(this);
    }

    removeContent(id) {
        const { dispatch, expenditures } = this.props;

        expenditures.filter(exp => exp.input.contentSuperclass && exp.input.contentSuperclass.indexOf(id) !== -1).map(function(exp) {
            exp.input.contentSuperclass = exp.input.contentSuperclass.filter(content => content !== id);
            dispatch(changeExpenditure(exp));
        })
    }

    addContent(id) {
        const { dispatch, expenditures, expenditure } = this.props;

        expenditures.filter(exp => exp.id === expenditure).map(function(exp) {
            if (!exp.input.contentSuperclass) {
                exp.input.contentSuperclass = [];
            }
            exp.input.contentSuperclass.push(id);
            dispatch(changeExpenditure(exp));
        })
    }

    handleInput(e, element) {
        const { dispatch, match } = this.props;
        var name = element.name.split('.');
        var entity = name[0];
        var field = name[1];

        dispatch(newExpenditure(match.params.id, field, element.value));
    }

    handleInputSelect(opt, element) {
        const { dispatch, match } = this.props;
        var name = element.name.split('.');
        var entity = name[0];
        var field = name[1];

        dispatch(newExpenditure(match.params.id, field, opt.value));
    }

    handleInputCheckbox(e, element) {
        const { dispatch, match } = this.props;
        var name = element.name.split('.');
        var field = name[1];

        dispatch(newExpenditure(match.params.id, field, element.checked));
    }

    submit(e, element) {
        const { dispatch, match, inputDatestart, inputDateend, inputWorkinghours, inputProjectpart } = this.props;
        var name = element.name.split('.');
        var entity = name[0];

        dispatch(postExpenditure(match.params.id, inputDatestart, inputDateend, inputWorkinghours, inputProjectpart));
    }

    componentDidMount() {
        const { dispatch, story } = this.props;

        dispatch(fetchStoryIfNeeded(story));
    }

    componentDidUpdate(prevProps, prevState) {
        const { dispatch, story } = this.props;

        if (prevProps.story !== story && story) {
            dispatch(fetchStoryIfNeeded(story));
        }
    }

    render(){
        const { isFetching, isFailed, contentList, expenditures, useFilter } = this.props;

        if ((!contentList || !contentList.length) && isFetching) {
            return (
                <Loader active inline="centered"/>
            );
        }
        if (isFailed) {
            return (
                <Container>
                    <Divider hidden/>
                    <Message color="red">
                        Es ist ein Fehler beim Abrufen der Daten aufgetreten.
                    </Message>
                </Container>
            );
        }
        if (useFilter && (!contentList || !contentList.length)) {
            return (
                <Container>
                    <Divider hidden/>
                    <Message color="teal">
                        Für diese Story sind gibt es keine verfügbaren Inhalte.
                    </Message>
                </Container>
            );
        }
        if (!contentList || !contentList.length) {
            return (
                <Container>
                    <Divider hidden/>
                    <Message color="teal">
                        Für diese Story gibt es noch keinen Inhalt.
                    </Message>
                </Container>
            );
        }

        return(
            <>
                {contentList.map(item => {
                    return (
                        <Segment className="expenditure-content">
                            <Grid>
                                <Grid.Column width={16} style={{paddingBottom: 0}}>
                                    <Header as="h3">
                                        <Truncate lines={1} ellipsis={<>...</>}>
                                            {item.string}
                                        </Truncate>
                                    </Header>
                                    <span className="font-small">
                                        <Truncate lines={3} ellipsis={<>...</>}>
                                            {item.text}
                                        </Truncate>
                                    </span>
                                </Grid.Column>
                                <Grid.Column width={16} className="parts-gallery-preview-wrapper">
                                    {item.mediaList.length > 0 ?
                                    <>
                                        <Grid columns={4} className="parts-gallery-preview">
                                            {item.mediaList.slice(0,3).map((m) => (
                                                <Grid.Column>
                                                    <ImageWrapper path={m.path} className="content-galerie-wrapper">
                                                            <ProgressiveImager src={config.getImageUrl('preview', m.path)}/>
                                                    </ImageWrapper>
                                                </Grid.Column>
                                            ))}
                                            {item.mediaList.length > 3 &&
                                                /* Show more images  */
                                                <Grid.Column>
                                                    <div className="more-images">
                                                        <span>+{item.mediaList.length - 3}</span>
                                                    </div>
                                                </Grid.Column>
                                            }
                                        </Grid>
                                        <div className="expenditure-label-gallery">
                                            {expenditures.filter(exp => exp.input.contentSuperclass && exp.input.contentSuperclass.indexOf(item.id) !== -1).map(exp => (
                                                <Label color="olive"><Moment unix format="DD.MM.YYYY">{exp.datestart}</Moment> - <Moment unix format="DD.MM.YYYY">{exp.dateend}</Moment> <Icon name='delete' onClick={() => this.removeContent(item.id)} /></Label>
                                            ))}
                                            {!expenditures.filter(exp => exp.input.contentSuperclass && exp.input.contentSuperclass.indexOf(item.id) !== -1).length &&
                                                <Label color="green" onClick={() => this.addContent(item.id)}>Zuweisen</Label>
                                            }
                                        </div>
                                    </>

                                        :

                                        <div className="expenditure-label">
                                            {expenditures.filter(exp => exp.input.contentSuperclass && exp.input.contentSuperclass.indexOf(item.id) !== -1).map(exp => (
                                                <Label color="olive"><Moment unix format="DD.MM.YYYY">{exp.datestart}</Moment> - <Moment unix format="DD.MM.YYYY">{exp.dateend}</Moment> <Icon name='delete' onClick={() => this.removeContent(item.id)} /></Label>
                                            ))}
                                            {!expenditures.filter(exp => exp.input.contentSuperclass && exp.input.contentSuperclass.indexOf(item.id) !== -1).length &&
                                                <Label color="green" onClick={() => this.addContent(item.id)}>Zuweisen</Label>
                                            }
                                        </div>

                                    }

                                </Grid.Column>
                            </Grid>
                        </Segment>
                    )
                })}
            </>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { storyById, expendituresByParent } = state;

    // Story
    const { item, isFetching, isFailed, contents } = storyById[
        ownProps.story
    ] || {
        item: {},
        contents: {},
        isFetching: true
    }

    // Expenditures
    var expenditures = [];
    if (item && item.project) {
        const { items } = expendituresByParent[
            'project::'+item.project
        ] || {
            items: {},
        }
        expenditures = [].concat(Object.values(items)).sort((a, b) => a.datestart > b.datestart ? 1 : -1);
    }

    // Sort contents
    var contentList = [];

    if (contents) {
        for (var c of Object.values(contents)) {
            var mediaList = [];

            if (c.media && Array.isArray(c.media)) {
                mediaList = c.media;
            }
            else if (c.media && c.previewPath) {
                mediaList.push({
                    id: c.media,
                    path: c.previewPath,
                });
            }
            else if (c.media && c.mediaPath) {
                mediaList.push({
                    id: c.media,
                    path: c.mediaPath,
                });
            }

            if (c.mediaBefore && c.mediaBeforePath) {
                mediaList.push({
                    id: c.mediaBefore,
                    path: c.mediaBeforePath,
                });
            }
            if (c.mediaAfter && c.mediaAfterPath) {
                mediaList.push({
                    id: c.mediaAfter,
                    path: c.mediaAfterPath,
                });
            }

            if (!ownProps.useFilter || !expenditures.filter(exp => exp.input.contentSuperclass && exp.input.contentSuperclass.indexOf(c.id) !== -1).length) {
                contentList.push({
                    ...c,
                    mediaList,
                });
            }
        }
    }
    contentList.sort((a, b) => a.position > b.position ? 1 : -1);

    return {
        isFetching,
        isFailed,
        contentList,
        expenditures,
    }
}

 export default connect(mapStateToProps)(ExpenditureContent);
