import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import './IconBox.scss';

class IconBox extends Component {
    render() {
        const { items } = this.props;
        return (
            <div className="iconbox">
                
                {items.map((item, index) => (
                    <div className="iconbox-item" key={index}>
                        <div className="iconbox-icon">
                            <img src={item.img.src} alt={item.img.alt} />
                        </div>
                        <div className="iconbox-content">
                            <div className="iconbox-header">{item.content.header}</div>
                            <div className="iconbox-description">{item.content.description}</div>
                            {item.content.link && 
                                <div className="iconbox-link">
                                    <Link to={item.content.link.to}>{item.content.link.text}</Link>
                                </div>
                            }
                        </div>
                    </div>
                ))}

            </div>
        )
    }
}

export default IconBox;