import React from 'react'
import { connect } from 'react-redux';
import { Form, Message, Button, Divider, Grid, Loader, Dimmer, Container } from 'semantic-ui-react';
import BeParent from '../../../project/backend/be-parent';
import Moment from 'react-moment';
import config from '../../../../config/config';
import { Redirect, withRouter } from 'react-router-dom';
import DeletePopup from '../../../../components/CustomPopup/DeletePopup';
import { requiredFields } from '../../../../components/DataValidation/ProfessionalValidation';
import { approvePayment, deleteProfessional, directProfessionalUpdate, fetchUserProfessional, resetProfessionalList, resetUserProfessionalList } from './../../../../actions/professional';
import './ProfessionalNew.scss';
import PayPalButton from './PayPalButtons';
import { payPalAuth, payPalSubscriptionDetails, subscriptionActivate, subscriptionDetails, subscriptionSuspend } from '../../../../actions/paypal';
import { subscriptionCancel } from './../../../../actions/paypal';
import ErrorHandler from './../../../../components/ErrorHandler/ErrorHandler';


class Pause extends BeParent {

    state = {
        deletePopup: false,
        successActive: false,
        subscriptionStatus: null,
        redirect: null,
        paymentDisabled: false
    }

    componentDidMount = () => {
        const { paypal, dispatch, match, payment, professional, validateFirst } = this.props;

        window.scrollTo(0, 0);
        //Check if paypal token is avaiable
        if(!paypal) {
            dispatch(payPalAuth());
        }

        //Get Subscription details if authorized
        if((paypal && paypal.data.access_token) && (payment && payment?.extId)) {
            dispatch(subscriptionDetails(payment.extId, match.params.id))
        }

        //Validate first
        if(validateFirst(professional.input) === false){
            this.setState({ paymentDisabled: true }); 
        }  

    }

    componentDidUpdate = (prevProps) => {
        const { professional, paypal, item, dispatch, history, isDeleted, match, listitem, payment } = this.props;

    
        if(paypal.data && (prevProps.paypal.data !== paypal.data) && payment && payment?.extId) {
            dispatch(subscriptionDetails(payment.extId, match.params.id))
        }

        if(payment && (payment && payment?.extId && payment?.extId !== prevProps.payment?.extId)) {
            dispatch(subscriptionDetails(payment.extId, match.params.id))
        }
        
        if(professional.active && (professional.active !== prevProps.professional.active)){
            this.setState({ successActive: true })
        }

        if(isDeleted && (isDeleted !== prevProps.isDeleted)) {
  
            this.setState({ deletePopup: false, redirect: '/backend/professional' });
            history.push('/backend/professional')
            // Update list
            dispatch(resetUserProfessionalList(item.id));
            dispatch(resetProfessionalList(item.id));
            dispatch(fetchUserProfessional(item.id));

        }

        if(listitem.length < prevProps.listitem.length) {
            this.setState({ deletePopup: false });
            // Update list
            dispatch(fetchUserProfessional(item.id));
        }

    }

    fetchSubscription = () => {
        const { match, paypal, dispatch, payment } = this.props;

        dispatch(subscriptionDetails(payment.extId, match.params.id))
    }


    deleteProfessional = () => {
        const { id, dispatch } = this.props;
        dispatch(deleteProfessional(id));
    }

    pauseSubscription = () => {
        const { dispatch, paypal, match, payment } = this.props;

        dispatch(subscriptionSuspend(payment.extId, paypal.data.access_token, match.params.id, match.params.id));
        this.setState({ subscriptionStatus: "SUSPENDED" })
    }

    activateSubscription = () => {
        const { dispatch, paypal, match, payment } = this.props;

        dispatch(subscriptionActivate(payment.extId, paypal.data.access_token, match.params.id));
        this.setState({ subscriptionStatus: "ACTIVE" })
    }

    requestCancelSubscription = () => {
        const { dispatch, paypal, match, payment } = this.props;

        dispatch(subscriptionCancel(payment.extId, paypal.data.access_token, match.params.id));
    }

    cancelSubscription = () => {
        const { dispatch, paypal, match, payment } = this.props;
        const { subscribeID } = this.state;

        dispatch(subscriptionCancel(payment.extId, paypal.data.access_token, match.params.id));
    }

    handleSubscribe = (id) => {
        const { dispatch, paypal, match } = this.props;
        
        dispatch(approvePayment(match.params.id, id))
    }


    render() {

        const { professional, isUpdating, validation, dispatch, isFetching, subscription, paypal } = this.props;
        const { subscriptionStatus, deletePopup, paymentDisabled } = this.state;

        if (this.state && this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }

        return (
            <>

                <div className="new-professional">
                    <Divider hidden/>

                    <Form>
                    {/*<Form.Field>
                        <label>Anzeige pausieren ab:</label>
                    </Form.Field>
                    <Grid verticalAlign="middle">
                        <Grid.Column mobile={16} tablet={8} computer={8}>
                            <Form.Field>
                                <Form.Input
                                    type='date'
                                    name={"professional." + id + ".pauseDate"}
                                    //value={item.input.billdate ? moment.unix(item.input.billdate).format("YYYY-MM-DD") : null}
                                    onChange={this.handleChangeDate}
                                />
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={8} computer={8}>
                            <Button color="red" disabled={!professional.active}>
                                Pause aufheben
                            </Button>
                        </Grid.Column>
                    </Grid>
                    */}


                    {/* Show paypal buttons if not subscribed */}
                    {!subscription && (paypal && !paypal.isFetching) && 
                    <>
                        {validation && Object.keys(validation).length > 0 && 
                            <Container text className='pb-1'>
                                <Divider />
                                <Message negative>
                                    <strong>Fülle alle Pflichtfelder aus um das Abonnement abzuschließen.</strong>
                                </Message>
                            </Container>
                        }
                        <div className="professional-subscribe mb-5">
                            <Grid>
                                <Grid.Column width={16}>
                                    <div><strong>Status: </strong> nicht bezahlt</div>
                                </Grid.Column>
                            </Grid>
                            <Message color="teal">
                                Um deine Anzeigen aktivieren zu können, musst du zuerst das Abonnement abschließen. 
                                Jetzt ganz bequem mit PayPal bezahlen - 1 Monat <strong>kostenlos</strong>, danach 19.99€/monat.
                            </Message>
                            <PayPalButton disabled={paymentDisabled} onApprove={this.handleSubscribe}/>
                        </div>
                    </>
                    }

                    {/* Subscription fetching data */}
                    {((subscription && subscription.isFetching ) || (paypal && paypal.isFetching)) &&
                         <div className="professional-subscribe mb-5">
                             <Dimmer active inverted>
                                
                                {subscription && subscription.isFetching ?
                                    <Loader>
                                        {subscription.isSuspending && "Abonnement wird pausiert..."}
                                        {subscription.isActivating && "Abonnement wird aktiviert..."}
                                        {subscription.isCancelling && "Abonnement wird gekündigt..."}
                                        {subscription.isLoading && "Daten werden geladen..."}
                                    </Loader>
                                
                                    :

                                    <Loader>
                                        Authentifizierung...
                                    </Loader>
                                }

                             </Dimmer>
                        </div>
                    }

                    {/* Subscription fetching data failed */}
                    {subscription && subscription.isFailed &&
                        <ErrorHandler callbackFunction={this.fetchSubscription}/>
                    }


                    {subscription && !subscription.isFetching && subscription.id && 
                        <div className="professional-subscribe">
                            
                            {/* Set user feedback */}
                            {subscriptionStatus === "ACTIVE" && subscription.status === "ACTIVE" &&
                                <Message color="green">
                                    Deine Anzeige wird in kurze <strong>aktiviert</strong>.
                                </Message>
                            }

                            {subscriptionStatus === "SUSPENDED" && subscription.status === "SUSPENDED" &&
                                <Message color="teal">
                                    Deine Anzeige wird in kurze <strong>pausiert</strong>.
                                </Message>
                            }


                            {subscription.status === "ACTIVE" && 
                                 <Message color="teal">
                                    Die aktuelle Anzeige ist bis zum <strong><Moment format="DD.MM.YYYY" locale="de">{subscription.billing_info.next_billing_time}</Moment></strong> bezahlt. Wird diese vorher pausiert, wird die mögliche
                                    Anzeigendauer dadurch nicht verlängert. Wird die Pause für diese Anzeige nach dem <strong><Moment format="DD.MM.YYYY" locale="de">{subscription.billing_info.next_billing_time}</Moment></strong> aufgehoben, beginnt der neue monatliche Zyklus ab diesem Tag.
                                </Message>
                            }

                            <Grid>
                                <Grid.Column width={16}>

                                    {/* Subscription status*/}
                                    <div><strong>Status: </strong>
                                        {subscription.status === "ACTIVE" && "aktiv"}
                                        {subscription.status === "SUSPENDED" && "pausiert"}
                                        {subscription.status === "CANCELLED" && "gekündigt"}
                                    </div>

                                    {/* Subscription date */}
                                    {subscription.status !== "CANCELLED" && 
                                        <div><strong>Abgeschlossen am: </strong><Moment format="DD.MM.YYYY" locale="de">{subscription.create_time}</Moment></div>
                                    }
                                    {/* Subscription next bill */}
                                    {subscription.status === "SUSPENDED" &&
                                        <div><strong>Pausiert am: </strong><Moment format="DD.MM.YYYY" locale="de">{subscription.status_update_time}</Moment></div>
                                    }
                                    {subscription.status === "ACTIVE" && 
                                        <div><strong>Nächstes Fälligkeitsdatum: </strong><Moment format="DD.MM.YYYY" locale="de">{subscription.billing_info.next_billing_time}</Moment></div>
                                    }

                                    <Divider/>
                                    {subscription.status !== "CANCELLED" && 
                                        <div><strong>Kosten:</strong>{subscription.shipping_amount.value == "0.0" ? "Probezeit - 1 Monat kostenlos, danach 19.99€/Monat" : subscription.shipping_amount.value + "€"}</div>
                                    }

                                </Grid.Column>
                            </Grid>
                     

                            {/* Show Paypal Buttons if subscription was cancelled */}
                            {subscription.status === "CANCELLED" && 
                            <>
                                <Message color="teal">
                                    Du kannst deine Anzeige erneut bezahlen und aktivieren.
                                </Message>
                                <PayPalButton onApprove={this.handleSubscribe}/>
                            </>
                            }

                            {/* Subscription status */}
                            <Grid>
                                {subscription.status === "ACTIVE" && 
                                    <Grid.Column width={16}>
                                        <strong>Abo pausieren</strong>
                                        <Message color="teal">
                                            Du kannst dein Abonemment jederzeit pausieren.
                                        </Message>
                                        <Button onClick={this.pauseSubscription} color="red">Pausieren</Button>
                                    </Grid.Column>
                                }

                                {subscription.status === "SUSPENDED" && 
                                    <Grid.Column width={16}>
                                        <strong>Abo reaktivieren</strong>
                                        {validation && Object.keys(validation).length > 0 && 
                                            <Container text className='pb-1'>
                                                <Divider />
                                                <Message negative>
                                                    <strong>Fülle alle Pflichtfelder aus um dein Abonnement zu reaktivieren.</strong>
                                                </Message>
                                            </Container>
                                        }
                                        <Message color="teal">
                                            Du kannst dein Abonnement jederzeit reaktivieren.
                                        </Message>
                                        <Button disabled={paymentDisabled} onClick={this.activateSubscription} color="red">Reaktivieren</Button>
                                    </Grid.Column>
                                }

                                {subscription.status !== "CANCELLED" && 
                                    <Grid.Column width={16}>
                                        <strong>Abo kündigen</strong>
                                        <Message color="teal">
                                            Die aktuelle Anzeige ist bis zum <strong><Moment format="DD.MM.YYYY" locale="de">{subscription.billing_info.next_billing_time}</Moment></strong> bezahlt. Wird diese vorher gekündigt, wird die Anzeige
                                            mit allen Inhalten <strong>sofort und unwiderruflich gelöscht.</strong>
                                        </Message>
                                        <Button color="red" onClick={() => this.setState({ deletePopup: true })}>Anzeige kündigen</Button>
                                    </Grid.Column>
                                }    


                                {/* {!professional.active && subscription.status === "ACTIVE" &&
                                    <Grid.Column width={16}>
                                        <strong>Anzeige sichtbar machen</strong>
                                        <Message negative>
                                            Diese Anzeige ist im Moment nicht veröffentlicht und deshalb für andere unsichtbar. Du solltest die Anzeige veröffentlichen. Du Kannst jederzeit deine Anzeige erneut pausieren.
                                        </Message>
                                        <Button disabled={config.isChanged(professional, requiredFields())} loading={isUpdating} color="green" onClick={submit}>
                                            Anzeige sichtbar machen
                                        </Button>
                                    </Grid.Column>
                                } */}

                            </Grid>


                        </div>
                    }
                    
                    {/* Show delete button if the subscription doesn't exists */}
                    {!subscription &&
                        <Grid>
                            <Grid.Column width={16}>
                                <strong>Anzeige löschen</strong>
                                <Message color="teal">
                                    Die Anzeige wird mit allen Inhalten sofort und unwiderruflich gelöscht.
                                </Message>
                                <Button color="red" onClick={() => this.setState({ deletePopup: true })}>
                                    Anzeige sofort löschen
                                </Button>
                            </Grid.Column>
                        </Grid>
                    }
                    

                    <Divider hidden/>
                    <Divider hidden/>
                </Form>

                <DeletePopup
                    popupIsOpen={deletePopup}
                    onClose={() => this.setState({ deletePopup: false })}
                    popupHeader="Anzeige kündigen"
                    onCancel={() => this.setState({ deletePopup: false })}
                    onDelete={() => subscription ? this.cancelSubscription() : dispatch(deleteProfessional(professional.id))}
                    fetching={isFetching}
                    deleteButtonText={subscription ? "Kündigen und löschen" : "Löschen"}
                    deleteText={
                        subscription ?
                        "Willst Du wirklich diese Anzeige kündigen? Deine Anzeige wird sofort mit allen Inhalten gelöscht."
                        :
                        "Achtung: Deine Anzeige wird sofort mit allen Inhalten gelöscht."
                    }
                />

                </div>
            </>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { professionalsById, account, listByEntity } = state;

    const { isFetching, isFailed, item: professional, isUpdating, isDeleted, subscription, payment } = professionalsById[ownProps.id] || {
        isFetching: true,
        isFailed: false,
        isDeleted: false,
        professional: {},
        payment: {},
        isUpdating,
        subscription: null
    }

    const { item, paypal } = account || {
        item: {},
        paypal: {}
    } 

    const { list } = listByEntity[item.id + '::userProfessional'] || {
        list: {},
    }

    let listitem = [];
    if (list) {
        for (let [key, value] of Object.entries(list)) {
            if (professionalsById[value]) {
                !professionalsById[value].isDeleted && listitem.push(professionalsById[value]);
            }
        }
    }

    return {
        isFetching, 
        isFailed,
        isDeleted,
        isUpdating,
        professional,
        listitem,
        item,
        paypal,
        subscription,
        payment
    };
}

export default connect(mapStateToProps)(withRouter(Pause));
