import React, { Component } from 'react';
import { Container, Image, Grid, Divider, Header } from 'semantic-ui-react';
import { footerActiveItem, handleContainer, handleHeaderItems } from '../actions/global';
import { connect } from 'react-redux'
import notFoundImage from '../images/error-404.svg';

class Notfound extends Component {

    componentDidMount = () => {
        const { dispatch } = this.props;
        dispatch(footerActiveItem("news"))
        dispatch(handleContainer("newsfeed", "Newsfeed"));
        dispatch(handleHeaderItems("newsfeed"));
    }

    render() {
        return (
            <Container className="not-found-page">
                <Divider hidden/>
                <Divider hidden/>
                <Grid reversed="mobile">
                    <Grid.Column mobile={16} tablet={8} computer={6}>
                        <Header as="h2">UUUPS!</Header>
                        <div className="not-found-error">Fehler 404</div>
                        <div className="error-message">Die gesuchten Inhalte wurden nicht gefunden!</div>
                    </Grid.Column>

                    <Grid.Column mobile={16} tablet={8} computer={10}>
                        <Image src={notFoundImage} alt="404"/>
                    </Grid.Column>
                </Grid>
            </Container>
        )
    }
}

export default connect()(Notfound);
