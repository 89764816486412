import config from '../config/config'
import { createAction } from 'redux-api-middleware';

export const GET_LOCATION_BY_CITY_REQUEST = "GET_LOCATION_BY_CITY_REQUEST";
export const GET_LOCATION_BY_CITY_SUCCESS = "GET_LOCATION_BY_CITY_SUCCESS";
export const GET_LOCATION_BY_CITY_FAILED = "GET_LOCATION_BY_CITY_FAILED";
export const GET_LOCATION_BY_COORDS_REQUEST = "GET_LOCATION_BY_COORDS_REQUEST";
export const GET_LOCATION_BY_COORDS_SUCCESS = "GET_LOCATION_BY_COORDS_SUCCESS";
export const GET_LOCATION_BY_COORDS_FAILED = "GET_LOCATION_BY_COORDS_FAILED";
export const SET_USER_COORDS = "SET_USER_COORDS"


export default function getLocation(city){
    return createAction({
        endpoint: `https://nominatim.openstreetmap.org/search?accept-language=de&countrycodes=de%2C%20at%2C%20ch&featuretype=city&q=${city}&format=json` ,
        method: 'GET',
        types: [
            {type: GET_LOCATION_BY_CITY_REQUEST},
            {type: GET_LOCATION_BY_CITY_SUCCESS},
            {type: GET_LOCATION_BY_CITY_FAILED},
        ]
    })
}

export function getReverseLocation(lat, long){
    return createAction({
        endpoint: `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${long}&format=json` ,
        method: 'GET',
        types: [
            {type: GET_LOCATION_BY_COORDS_REQUEST},
            {type: GET_LOCATION_BY_COORDS_SUCCESS},
            {type: GET_LOCATION_BY_COORDS_FAILED},
        ]
    })
}

export function getUserCoords(position, status) {
    return {
        type: SET_USER_COORDS,
        component: "UserCoords",
        payload: {
            position,
            status
        } 
    }
}
