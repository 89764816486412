import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunkMiddleware from 'redux-thunk'
import globalByComponent from '../reducers/global'
import account from '../reducers/account'
import projectById from '../reducers/project'
import storyById from '../reducers/story'
import listByEntity from '../reducers/lists';
import commentsByParent from '../reducers/comments';
import userById from '../reducers/user';
import conversationById from '../reducers/conversation';
import partsByParent from '../reducers/part';
import billsByParent from '../reducers/bill';
import expensesByParent from '../reducers/expense';
import notificationById from '../reducers/notification';
import expendituresByParent from '../reducers/expenditure';
import feedpostById from '../reducers/feedpost';
import todosByParent from '../reducers/todo';
import pageByName from '../reducers/pageByName';
import assemblysByParent from '../reducers/assembly';
import blog from '../reducers/blog';
import forumthreadById from '../reducers/forumthread';
import brandBySlug from '../reducers/brandBySlug';
import professionalsById from '../reducers/professional';
import feedpostByUserId from '../reducers/userFeedpost';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'localforage'
import { composeWithDevTools } from 'redux-devtools-extension';
import { apiMiddleware } from 'redux-api-middleware';
import { reducer as uploadReducer } from 'ix-redux-file-upload';
import refreshApiMiddleware from '../middleware/refreshApiMiddleware';

const persistConfig = {
    key: 'root',
    storage,
}

const rootReducer = combineReducers({
    uploadReducer,
    globalByComponent,
    account,
    projectById,
    storyById,
    listByEntity,
    commentsByParent,
    userById,
    conversationById,
    partsByParent,
    billsByParent,
    expensesByParent,
    notificationById,
    expendituresByParent,
    feedpostById,
    todosByParent,
    assemblysByParent,
    blog,
    forumthreadById,
    brandBySlug,
    professionalsById,
    feedpostByUserId,
    pageByName
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default () => {
    let store = createStore(
        persistedReducer,
        composeWithDevTools(applyMiddleware(refreshApiMiddleware, apiMiddleware, thunkMiddleware))
    )
    let persistor = persistStore(store)

    return { store, persistor }
}
