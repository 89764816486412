import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ProjectListGallery from '../frontend/projectsList/ProjectListGallery';
import { Button, Container, Divider, Grid, Loader } from 'semantic-ui-react';
import { handleUserInputBrand, handleUserInputCategory, handleUserInputCountry, handleUserInputModel, handleUserInputYearFrom, handleUserInputYearTo } from '../../../actions/filter';
import { footerActiveItem, handleHeaderItems } from '../../../actions/global';
import { checkForUpdatesIfNeeded, fetchProjectList, fetchProjectListIfNeeded, resetProjectList } from '../../../actions/project';
import config from '../../../config/config';
import { InfiniteScroll } from '../../../components/helpers/InfiniteScroll';
import { motion} from 'framer-motion';
import ProjectListCardView from '../frontend/projectsList/ProjectListCardView';
import ErrorHandler from '../../../components/ErrorHandler/ErrorHandler';



class ListProjects extends Component {

    state = {
        searchLabels: false,
    }

    constructor(props) {
        super(props);

        this.loadMore = this.loadMore.bind(this);
        this.loadMoreForce = this.loadMoreForce.bind(this);
    }

    componentDidMount = () => {
        const { dispatch, options } = this.props;
        dispatch(checkForUpdatesIfNeeded(options));
        dispatch(fetchProjectListIfNeeded(options));
        dispatch(footerActiveItem("project"));
        dispatch(handleHeaderItems("projects"));
    }

    componentDidUpdate(prevProps) {
        const { dispatch, listitems, options, isFetching, isFailed, hasUpdateLastFetch, currentView, history } = this.props;

        if(!isFetching && !isFailed && prevProps.listitems !== listitems && !listitems.length) {
            dispatch(fetchProjectList(options));
        }

        if (hasUpdateLastFetch && (Date.now() - hasUpdateLastFetch > config.PING_TIMEOUT)) {
            dispatch(checkForUpdatesIfNeeded(options));
        }

        //Check for project view change
        if(prevProps.currentView !== currentView){
            window.scroll(0, 0)
        }

    }

    reloadList = () => {
        const { dispatch, options } = this.props;
        dispatch(resetProjectList());
        dispatch(fetchProjectList(options));
    }

    handleDeleteFilter = (filter) => {
        const { dispatch } = this.props;
        dispatch(resetProjectList());
        switch(filter){
            case "category":
                return dispatch(handleUserInputCategory())
            case "brandmodel":
                return (
                    dispatch(handleUserInputBrand()) && dispatch(handleUserInputModel())
                )
            case "yearfrom":
                return dispatch(handleUserInputYearFrom())
            case "yearTo":
                return dispatch(handleUserInputYearTo())
            case "country":
                return dispatch(handleUserInputCountry())
            default:
                return null
        }
    }

    loadMore() {
        const { dispatch, options, page, isFailed } = this.props;

        if (!isFailed) {
            dispatch(fetchProjectList(options, (page ? page+1 : 1)));
        }
    }

    loadMoreForce() {
        const { dispatch, options, page } = this.props;

        dispatch(fetchProjectList(options, (page ? page+1 : 1)));
    }

    render() {

        const { isFetching, isFailed, listitems } = this.props;

        if (!listitems.length && isFetching) {
            return (
                <>
                    {this.placeholderItem()}
                </>
            )
        }

        else if (listitems.length) {
            const { listitems, hasMore, isFetching, lastFetch, hasUpdate, currentView } = this.props;

            return (
                <>
                    {hasUpdate &&
                        <Grid>
                            <Grid.Column width={16} textAlign="center">
                                <Button
                                    onClick={this.reloadList}
                                    color="red"
                                    basic
                                >
                                    Aktualisieren
                                </Button>
                                <Divider hidden />
                            </Grid.Column>
                        </Grid>
                    }

                
     
                        {currentView === "gallery" && 
                            <motion.div 
                                className="gallery-wrapper"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0, transition: { duration: 0.3 } }}
                                transition={{ duration: 0.3, delay: 0.15 }}
                                style={{ pointerEvents: "auto" }}
                                
                            >
                                <ProjectListGallery hasMore={hasMore} list={listitems}/>
                            </motion.div>
                        }
                        
          

                

                        {currentView === "list" && 
                            <motion.div 
                                className="list-wrapper"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0, transition: { duration: 0.3 } }}
                                transition={{ duration: 0.3, delay: 0.15 }}
                                style={{ pointerEvents: "auto" }}

                            >
                                <Container>
                                    <ProjectListCardView list={listitems} />
                                </Container>
                            </motion.div>
                        }

                 

                    { isFetching && hasMore &&
                        <>
                            {this.placeholderItem()}
                        </>
                    }

                    {isFailed &&
                        <>
                            <Container text>
                                <ErrorHandler 
                                    errorMessage="Beim Abrufen der Daten ist ein Fehler aufgetreten."
                                    callbackFunction={this.loadMoreForce}
                                />
                            </Container>
                        </>
                    }

                    <InfiniteScroll
                        onLoadMore={this.loadMore}
                        loading={isFetching}
                        hasNextPage={hasMore}
                    />                    
                    

                </>
            )
        }

        else {
            return (
                <Container text>
                    <ErrorHandler 
                        errorMessage="Beim Abrufen der Daten ist ein Fehler aufgetreten."
                        callbackFunction={this.loadMoreForce}
                    />
                </Container>
            )
        }

    }

    placeholderItem() {
        return (
            <Container className="gallery-view-loader">
                <Loader active/>
            </Container>
        )
    }
}

function mapStateToProps(state, ownProps){
    const { projectById, listByEntity, globalByComponent } = state;

    const { list, isFetching, lastFetch, isFailed, hasMore, page, hasUpdate, hasUpdateLastFetch } = listByEntity[
        'project'
    ] || {
        list: {},
        isFetching: true,
    }
    const { order, userInputCategory, userInputBrand, userInputModel, userInputYearFrom, userInputYearTo, userInputCountry, projectCount } = globalByComponent[
        'Filter'
    ] || {
        order: null,
        userInputBrand: "",
        userInputCategory: "",
        userInputModel: "",
        userInputYearFrom: "",
        userInputYearTo: "",
        userInputCountry: "",
        projectCount: "",
    }

    const { currentView } = globalByComponent['projectList'] || {
        currentView: "gallery"
    }

    var listitems = [];

    if (list) {
        for (let [key, value] of Object.entries(list)) {
            listitems.push(projectById[value]);
        }
    }

    var options = {};

    if(order) {
        options['project_list_order'] = order;
    }

    if(userInputCategory) {
        options['project_filter[scope]'] = userInputCategory.value;
    }

    if (userInputBrand) {
        options['project_filter[brand]'] = userInputBrand.value;
    }

    if (userInputModel) {
        options['project_filter[vehicle]'] = userInputModel.value;
    }

    if (userInputYearFrom) {
        options['project_filter[vehicleYearSince]'] = userInputYearFrom;
    }

    if (userInputYearTo) {
        options['project_filter[vehicleYearUntil]'] = userInputYearTo;
    }

    if (userInputCountry) {
        options['project_filter[brandCountrycode]'] = userInputCountry.value;
    }

    return {
        listitems,
        isFetching,
        lastFetch,
        hasMore,
        page,
        isFailed,
        hasUpdate,
        hasUpdateLastFetch,
        options,
        userInput: {
            userInputCategory: (userInputCategory ? userInputCategory : ''),
            userInputBrand: (userInputBrand ? userInputBrand : ''),
            userInputModel: (userInputModel ? userInputModel : ''),
            userInputYearFrom: (userInputYearFrom ? userInputYearFrom : ''),
            userInputYearTo: (userInputYearTo ? userInputYearTo : ''),
            userInputCountry: (userInputCountry ? userInputCountry : ''),
        },
        projectCount,
        currentView
    }


}

export default connect(mapStateToProps)(withRouter(ListProjects));
