import React, { Component } from 'react'
import { Button, Dimmer, Grid, Loader, Container } from 'semantic-ui-react';
import { zeroRightClassName } from 'react-remove-scroll-bar';
import Modal from 'react-modal';
import './SaveModal.scss';

Modal.setAppElement('body');

class SaveModal extends Component {
    render() {
        if (this.props.showSaveModal) {
            return (
                <Modal 
                    isOpen={this.props.showSaveModal}
                    bodyOpenClassName={null}
                    overlayClassName="modal-save-story-overlay"
                    className="modal-save-story"
                    shouldFocusAfterRender={false}
                >
                   {this.props.fetching && 
                        <Dimmer.Dimmable>
                            <Dimmer active>
                                <Loader/>
                            </Dimmer>
                        </Dimmer.Dimmable>
                    }

                    {!this.props.fetching && 
                
                        <Container text>
                            {this.props.messageText}
                            <div className="save-story-buttons">
                                <Button color='red' onClick={this.props.revert}>{this.props.revertText}</Button>
                                <Button color='green' onClick={this.props.submit}>{this.props.submitText}</Button>
                            </div>
                        </Container>
                    } 
               
                </Modal>
                /*<div className={this.props.fetching ? "isloading save-modal" : "save-modal"}>
                    <Dimmer.Dimmable className={this.props.fetching ? "save-modal-loader" : null}>
                        {this.props.fetching &&
                            <Dimmer active>
                                <Loader />
                            </Dimmer>
                            }
                        <Container className={"save-modal-btns " + zeroRightClassName}>
                            Du hast Änderungen an Deiner Story vorgenommen. Wenn du mit den Änderungen
                            fertig bist, kannst Du die Änderungen übernehmen(speichern) oder verwerfen.
                            <Grid textAlign="right">
                                <Grid.Column width={16}>
                                    <Button color='red' onClick={this.props.revert}>Verwerfen</Button>
                                    <Button color='green' onClick={this.props.submit}>Speichern</Button>
                                </Grid.Column>
                            </Grid>
                        </Container>
                    </Dimmer.Dimmable>
                        </div>*/
            );
        }

        return null;
    }
}

export default SaveModal;
