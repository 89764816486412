/* NOTE: The Blog uses Wordpress REST API */
import config from '../config/config'
import { createAction } from 'redux-api-middleware';

export const GET_PAGE_REQUEST = 'GET_PAGE_REQUEST';
export const GET_PAGE_SUCCESS  = 'GET_PAGE_SUCCESS';
export const GET_PAGE_FAILED = 'GET_PAGE_FAILED';;


export function getPage(pageId, pageTitle, embed){
    if (!pageId) pageId = 3;
    // if(embed) embed = "&_embed=wp:featuredmedia"
    // else embed = ""
    //Wordpress API fields - specify response fields 
    // let fields = "id,title,content"

    return createAction({
        endpoint: `${config.BLOG_API_HOST}/wp-json/wp/v2/pages/${pageId}?_embed`,
        method: 'GET',
        types: [
            {type: GET_PAGE_REQUEST, meta:{ pageTitle }},
            {type: GET_PAGE_SUCCESS, meta:{ pageTitle }},
            {type: GET_PAGE_FAILED, meta:{ pageTitle }},
        ]
    })
}