import React, { Component } from 'react'
import { Image, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import './AddToHomeScreen.scss';

class AddToHomeScreen extends Component {

    state = {
        homeScreenBannerShow: false,
        chromePrompt: null,
        device: null,
        standalone: false,
        browser: null
    }

    componentDidMount = () => {

        const { browser, device } = this.state;
        const userAgent = navigator.userAgent;
        
        //Set currect device
        if(/android/i.test(userAgent)){
            this.setState({device: 'android'})
        }
        else if(/iPad|iPhone|iPod/i.test(userAgent)){
            this.setState({device: 'ios', homeScreenBannerShow: true}) //iOS Workaround - beforeinstallprompt doesn't work with iOS devices!
        }

        if(/Chrome/.test(userAgent)){
            this.setState({ browser: "chrome" })
        }
        else if(/Safari/.test(userAgent)){
            this.setState({ browser: "safari", homeScreenBannerShow: true })
        }
        // document.body.classList.add("aths-open");
        //Check if standalone...
        if((window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone)) {
            this.setState({standalone: true})
        }

        // // Check for CHROME
        if(browser === "chrome" && device !== 'android') {
            
            window.addEventListener('beforeinstallprompt', (e) => {
                // Prevent the mini-infobar from appearing on mobile
                
                e.preventDefault();
                // Stash the event so it can be triggered later.
                this.setState({ chromePrompt: e })
                // Update UI notify the user they can install the PWA
                this.setState({ homeScreenBannerShow: true })
                
            });
        }

        // //Check if successfully installed - CHROME / ANDROID
        // window.addEventListener('appinstalled', () => {
        //     this.setState({ homeScreenBannerShow: false })
        //     this.setState({ chromePrompt: null });
        //   });
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { homeScreenBannerShow, browser, device, standalone } = this.state;
        const { show } = this.props;

        //Remove body class if homescreenbanner was closed
        if(!homeScreenBannerShow && (prevState.homeScreenBannerShow !== homeScreenBannerShow)) {
            document.body.classList.remove("aths-open");
        }
        else if(!standalone && homeScreenBannerShow && show && device !== 'android' && (prevState.homeScreenBannerShow !== homeScreenBannerShow)){
            document.body.classList.add("aths-open");
        }

        // // Check for CHROME
        if(browser === "chrome" && device !== 'android') {
            
            window.addEventListener('beforeinstallprompt', (e) => {
                // Prevent the mini-infobar from appearing on mobile
                
                e.preventDefault();
                // Stash the event so it can be triggered later.
                this.setState({ chromePrompt: e })
                // Update UI notify the user they can install the PWA
                this.setState({ homeScreenBannerShow: true })
                
            });
        }

    }

    handleInstallOnAndroid = async () => {
        const { chromePrompt } = this.state;

        this.setState({ homeScreenBannerShow: false });
        chromePrompt.prompt() //Show install popup

        //Handle User Choice...
        const { outcome } = await chromePrompt.userChoice;
        this.setState({ chromePrompt: null });

    }

    handleCloseBanner = () => {
        const { dismissCallback } = this.props;
        dismissCallback(); 
        this.setState({ homeScreenBannerShow: false });
        // document.body.classList.remove("aths-open");
    }

    render() {

        const { icon, show } = this.props;
        const { standalone, homeScreenBannerShow, device, browser } = this.state;

        if(!standalone && homeScreenBannerShow && show && device !== 'android'){
            document.body.classList.add("aths-open");
        }
        else {
            document.body.classList.remove("aths-open");
        }

        return (
    
            !standalone && show && homeScreenBannerShow && device !== 'android' && //Disable on android - instead show native install banner
                <div className="add-to-home">
                    <div className="add-to-home-icon">
                        <Image src={icon} alt="Vetero Oldtimer Portal Logo" />
                    </div>
                    
                    {browser === 'chrome' && 
                        <div className="add-to-home-content" onClick={this.handleInstallOnAndroid}>
                            <div className="add-to-home-header">
                                <strong>Vetero als App installieren.</strong>
                            </div>
                        </div>
                    } 

                    {(device === 'ios' || browser === 'safari') &&  

                        <div className="add-to-home-content" onClick={this.handleCloseBanner}>
                            <div className="add-to-home-header">
                                <Link to="/functions/vetero-app">
                                    <strong>Vetero als App installieren.</strong>
                                </Link>
                            </div>
                        </div>
                    }

                    <div className="add-to-home-close" onClick={this.handleCloseBanner}>
                        <Icon name="delete"/>
                    </div>
                </div>
        )
    }
}

export default AddToHomeScreen