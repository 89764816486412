import React, { Component } from 'react';
import config from '../../config/config';
import { Image, Divider, Placeholder, Label } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import ProgressiveImager from '../helpers/progressive-imager';
import Moment from 'react-moment';
import "moment/locale/de";
import "moment-timezone";
import commentIcon from '../../images/icons/frontend/icon-comment.svg';

class ThreadList extends Component {
    

    render() {

        const calendarStrings = {
            lastDay : 'DD.MM.YYYY, LT',
            sameDay : 'DD.MM.YYYY, LT',
            sameElse : 'L',
            nextWeek: 'DD.MM.YYYY, LT',
            lastDay: 'DD.MM.YYYY, LT',
            lastWeek: 'DD.MM.YYYY, LT',
            sameElse : 'DD.MM.YYYY, LT',
        }

        const { threads, isFetching } = this.props;

        return (

            <>

            {isFetching ? 
            <>
                {this.placeholder()}
                {this.placeholder()}
                {this.placeholder()}
                {this.placeholder()}
                {this.placeholder()}
                {this.placeholder()}
                {this.placeholder()}
                {this.placeholder()}
            </>
                :
                
                threads.map((thread, index) => (
                    <div className="thread" key={index}> 
                        <div className="thread-content">
                            <div className="author-avatar">
                                <ProgressiveImager alt={`Profilbild von ${thread.item.userData.name}`} circular placeholder="avatar" src={config.getImageUrl('avatar', thread.item.userData.mediaavatarPath)}/>
                            </div>
                            <Link className="thread-title" to={`/forum/${thread.item.urlslug}/${thread.item.id}`}>
                                <h2>{thread.item.title}</h2>
                                <div className="thread-meta">
                                    {(thread.item.brandData || thread.item.vehicleData || thread.item.forum || thread.item.scope ) &&
                                        <Label>
                                            {
                                                `
                                                ${!thread.item.brandData && !thread.item.vehicleData && !thread.item.forum && thread.item.scope ? config.getDefault(config.getScopes(), thread.item.scope).label :''} 
                                                ${thread.item.brandData && thread.item.brandData.name ? thread.item.brandData.name : '' } 
                                                ${thread.item.vehicleData && thread.item.vehicleData.name ? thread.item.vehicleData.name  : ''}
                                                ${thread.item.forum && thread.item.forum ? config.getDefault(config.getForums(), thread.item.forum).label :''}
                                                `
                                            }
                                        </Label>
                                        }
                                    <span className="user">
                                        {`${thread.item.userData.name} - `}
                                        <Moment locale="de" calendar={calendarStrings} unix>{thread.item.createDate}</Moment>
                                    </span>
                                </div>
                            </Link>
                            
                            <div className="thread-info">
                                {thread.item.countMessage && thread.item.countMessage > 1 &&
                                    <div className="thread-posts-count">
                                        <Image src={commentIcon} alt="Icon Posts"/>
                                        {thread.item.countMessage - 1}
                                    </div>
                                }
                                <div className="thread-last-post">
                                    <Moment locale="de" calendar={calendarStrings} unix>{thread.item.lastMessageDate}</Moment>
                                </div>        
                            </div>
                        </div>
                        <Divider/>
                    </div>
                ))}

            </>

        )
    }
    placeholder(){
        return(
            <div className="thread fetching">
                <div className="thread-content">
                    <div className="author-avatar">
                        <Placeholder>
                            <Placeholder.Image/>
                        </Placeholder>
                    </div>
                    <div className="thread-title">
                        <Placeholder fluid>
                            <Placeholder.Line/>
                            <Placeholder.Line/>
                        </Placeholder>
                    </div>
                </div>
                <Divider/>
            </div>
        )
    }
}

export default ThreadList;
