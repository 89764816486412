import config from '../config/config'
import { createAction } from 'redux-api-middleware';

export const API_NOTIFICATION_UPDATE_REQUEST = 'API_NOTIFICATION_UPDATE_REQUEST'
export const API_NOTIFICATION_UPDATE_SUCCESS = 'API_NOTIFICATION_UPDATE_SUCCESS'
export const API_NOTIFICATION_UPDATE_FAILURE = 'API_NOTIFICATION_UPDATE_FAILURE'
export const API_NOTIFICATION_LIST_REQUEST = 'API_NOTIFICATION_LIST_REQUEST'
export const API_NOTIFICATION_LIST_SUCCESS = 'API_NOTIFICATION_LIST_SUCCESS'
export const API_NOTIFICATION_LIST_FAILURE = 'API_NOTIFICATION_LIST_FAILURE'
export const NEW_FEEDPOST = 'NEW_FEEDPOST'
export const API_POST_FEEDPOST_REQUEST = 'API_POST_FEEDPOST_REQUEST'
export const API_POST_FEEDPOST_SUCCESS = 'API_POST_FEEDPOST_SUCCESS'
export const API_POST_FEEDPOST_FAILURE = 'API_POST_FEEDPOST_FAILURE'
export const RESET_NOTIFICATION_LIST = 'RESET_NOTIFICATION_LIST'
export const API_FEEDPOST_REQUEST = 'API_FEEDPOST_REQUEST'
export const API_FEEDPOST_SUCCESS = 'API_FEEDPOST_SUCCESS'
export const API_FEEDPOST_FAILURE = 'API_FEEDPOST_FAILURE'
export const CHANGE_FEEDPOST = 'CHANGE_FEEDPOST';
export const API_LIKE_FEED_REQUEST = "API_LIKE_FEED_REQUEST"
export const API_LIKE_FEED_SUCCESS = "API_LIKE_FEED_SUCCESS"
export const API_LIKE_FEED_FAILURE = "API_LIKE_FEED_FAILURE"
export const API_UPDATE_FEEDPOST_REQUEST = 'API_UPDATE_FEEDPOST_REQUEST';
export const API_UPDATE_FEEDPOST_SUCCESS = 'API_UPDATE_FEEDPOST_SUCCESS';
export const API_UPDATE_FEEDPOST_FAILURE = 'API_UPDATE_FEEDPOST_FAILURE';


export function changeFeedPost(id, inputText, inputMedias) {
    return {
        type: CHANGE_FEEDPOST,
        meta: {
            id,
        },
        payload: {
            inputText,
            inputMedias,
        }
    }
}

export function updateFeedPost(item) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/notification/${item.id}`,
        method: 'PUT',
        body: JSON.stringify({
            feedpost: {
                ...item,
                text: item.inputText,
                medias: item.inputMedias,
            },
        }),
        types: [
            {type: API_UPDATE_FEEDPOST_REQUEST, meta: { id: item.id }},
            {type: API_UPDATE_FEEDPOST_SUCCESS, meta: { id: item.id }},
            {type: API_UPDATE_FEEDPOST_FAILURE, meta: { id: item.id }},
        ]
    })
}

export function likeFeed(id, like, count){
    return createAction({
        endpoint: `${config.API_HOST}/v1/like`,
        method: 'PUT',
        body: JSON.stringify({
            entity: 'feedpost',
            type: 'like',
            id: id,
            value: like,
        }),
        types: [
            {type: API_LIKE_FEED_REQUEST, meta: { id, like, count }},
            {type: API_LIKE_FEED_SUCCESS, meta: { id, like, count }},
            {type: API_LIKE_FEED_FAILURE, meta: { id, like, count }},
        ],
    })
}

export function fetchFeedpost(id) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/notification/${id}`,
        method: 'GET',
        types: [
            {type: API_FEEDPOST_REQUEST, meta: {id}},
            {type: API_FEEDPOST_SUCCESS, meta: {id}},
            {type: API_FEEDPOST_FAILURE, meta: {id}},
        ]
    })
}

function shouldFetchFeedpost(state, id) {
    const feedpost = state.feedpostById[id]
    if (
        !feedpost ||
        ((feedpost.isFetching || feedpost.isFailed) && Date.now() - feedpost.lastFetch > config.FETCH_TIMEOUT) ||
        (!feedpost.isFetching && Date.now() - (feedpost.lastUpdated ? feedpost.lastUpdated : 0) > config.FETCH_AGAIN)
    ) {
        return true
    }
    return false
}

export function fetchFeedpostIfNeeded(id) {
    return (dispatch, getState) => {
        if (shouldFetchFeedpost(getState(), id)) {
            return dispatch(fetchFeedpost(id))
        }
    }
}

export function resetNotificationList(events, dashboard){
    return {
        type: RESET_NOTIFICATION_LIST,
        meta: {key: simpleKey(events, dashboard)},
    }
}

export function newFeedpost(inputFeedpost) {
    return {
        type: NEW_FEEDPOST,
        payload: {
            inputFeedpost,
        }
    }
}

export function postFeedpost(inputFeedpost) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/notification`,
        method: 'POST',
        body: JSON.stringify({
            text: inputFeedpost.text,
            project: inputFeedpost.project,
            medias: inputFeedpost.media,
        }),
        types: [
            {type: API_POST_FEEDPOST_REQUEST},
            {type: API_POST_FEEDPOST_SUCCESS},
            {type: API_POST_FEEDPOST_FAILURE},
        ]
    })
}

export function simpleKey(events, dashboard) {
    if (!events) {events = []}

    var key = 'NTFCN';
    if (events) {
        events.map(function(event) {
            key += 'e'+parseInt(event);
        });
    }
    if (dashboard) {
        key += 'd1';
    }

    return key;
}

export function checkForUpdates(events, dashboard) {
    if (!events) {events = []}
    var page = 1;
    var size = 1;

    var query = '';
    events.map(function(event) {
        query += '&events[]='+parseInt(event);
    });
    if (dashboard) {
        query += '&dashboard=1';
    }

    return createAction({
        endpoint: `${config.API_HOST}/v1/notification?page=${page}&size=${size}${query}`,
        method: 'GET',
        types: [
            {type: API_NOTIFICATION_UPDATE_REQUEST, meta: { page, key: simpleKey(events, dashboard) }},
            {type: API_NOTIFICATION_UPDATE_SUCCESS, meta: { page, key: simpleKey(events, dashboard) }},
            {type: API_NOTIFICATION_UPDATE_FAILURE, meta: { page, key: simpleKey(events, dashboard) }},
        ]
    })
}

function shouldCheckForUpdates(state, events, dashboard) {
    const notificationlist = state.listByEntity[simpleKey(events, dashboard)];
    if (
        notificationlist &&
        notificationlist.hasUpdate === false &&
        (Date.now() - notificationlist.hasUpdateLastFetch > config.FETCH_TIMEOUT)
    ) {
        return true
    }
    return false
}

export function checkForUpdatesIfNeeded(events, dashboard) {
    return (dispatch, getState) => {
        if (shouldCheckForUpdates(getState(), events, dashboard)) {
            return dispatch(checkForUpdates(events, dashboard))
        }
    }
}

export function fetchNotificationList(events, dashboard, page, size) {
    if (!events) {events = []}
    if (!page) {page = 1}
    if (!size) {size = 20}

    var query = '';
    events.map(function(event) {
        query += '&events[]='+parseInt(event);
    });
    if (dashboard) {
        query += '&dashboard=1';
    }

    return createAction({
        endpoint: `${config.API_HOST}/v1/notification?page=${page}&size=${size}${query}`,
        method: 'GET',
        types: [
            {type: API_NOTIFICATION_LIST_REQUEST, meta: { page, key: simpleKey(events, dashboard) }},
            {type: API_NOTIFICATION_LIST_SUCCESS, meta: { page, key: simpleKey(events, dashboard) }},
            {type: API_NOTIFICATION_LIST_FAILURE, meta: { page, key: simpleKey(events, dashboard) }},
        ]
    })
}

function shouldFetchNotificationList(state, events, dashboard) {
    const notificationlist = state.listByEntity[simpleKey(events, dashboard)];
    if (
        !notificationlist ||
        ((notificationlist.isFetching || notificationlist.isFailed) && Date.now() - notificationlist.lastFetch > config.FETCH_TIMEOUT)
    ) {
        return true
    }
    return false
}

export default function fetchNotificationListIfNeeded(events, dashboard, page, size) {
    return (dispatch, getState) => {
        if (shouldFetchNotificationList(getState(), events, dashboard)) {
            return dispatch(fetchNotificationList(events, dashboard, page, size))
        }
    }
}
