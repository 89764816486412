import React, { Component } from 'react'
import { Container, Grid, Feed, Segment, Divider, Card, Placeholder, Image, Label, Button, Form, Message } from 'semantic-ui-react';
import { handleContainer, handleHeaderItems, setRuntime } from '../../actions/global';
import { withRouter, Link } from 'react-router-dom';
import commentIcon from '../../images/icons/frontend/icon-comment.svg';
import { InfiniteScroll } from '../../components/helpers/InfiniteScroll'
import config from '../../config/config';
import { connect } from 'react-redux'
import Moment from 'react-moment';
import 'moment/locale/de'
import 'moment-timezone';
import { fetchUserThreads, fetchUserThreadsIfNeeded } from '../../actions/forumthread';

const calendarStrings = {
    lastDay : '[Gestern um] LT',
    sameDay : '[Heute um] LT',
    sameElse : 'LL',
}

class ForumMobile extends Component {

    constructor(props){
        super(props);

        this.loadMore = this.loadMore.bind(this);
        this.loadMoreForce = this.loadMoreForce.bind(this);
    }

    componentDidMount = () => {
        const { dispatch, item } = this.props;
        dispatch(handleHeaderItems("dashboard"));
        dispatch(fetchUserThreadsIfNeeded(item.id))
        window.scrollTo(0, 0);
    }

    loadMore() {
        const { dispatch, item, isFailed } = this.props;

        if (!isFailed) {
            dispatch(fetchUserThreads(item.id));
        }
    }

    loadMoreForce() {
        const { dispatch, item } = this.props;

        dispatch(fetchUserThreads(item.id));
    }

    placeholderItem() {
        return (
            <div className="comments-dashboard">
                <Card className="comments-dashboard-placeholder">
                    <Card.Content>
                        <Placeholder className="comments-dashboard-placeholder-img">
                            <Placeholder.Image/>
                        </Placeholder>
                        <Placeholder className="comments-dashabord-placeholder-content">
                            <Placeholder.Line length="short"/>
                            <Placeholder.Line length="long"/>
                            <Placeholder.Line length="very long"/>
                        </Placeholder>
                    </Card.Content>
                </Card>
            </div>
        );
    }

    render() {
        const { listitem, isFetching, isFailed, hasMore } = this.props;

        if ((!listitem || !listitem.length) && isFetching) {
            return (<>
                {this.placeholderItem()}
                {this.placeholderItem()}
                {this.placeholderItem()}
           </> )
        }

        else if (!listitem || !listitem.length) {
            return (
                <Container text>
                    <Divider hidden/>
                    <Message style={{textAlign: "center"}}>
                        Du hast noch keine Frage im Forum gestellt.<br/>
                        <Button style={{marginTop: "1rem"}} as={Link} to="/forum/new" color="red">Jetzt Frage im Forum stellen</Button>
                    </Message>
                </Container>
            )
        }

        else if (!isFailed) {

            return (
                <div>
                    {listitem.map((thread, index) =>
                        <Segment className="thread" key={index}>
                            {/*Fetch data*/}
                                <Grid>
                                    <Grid.Column width={16}>
                                        <Feed as={Link} to={`/forum/${thread.item.urlslug}/${thread.item.id}`}>
                                            <Feed.Event>
                                                <Feed.Content>
                                                    <div className="thread-title">
                                                        {thread.item.title}

                                                        {(thread.item.brandData || thread.item.vehicleData || thread.item.forum || thread.item.scope ) &&
                                                            <div className="thread-label">
                                                                <Label>
                                                                    {
                                                                        `
                                                                            ${!thread.item.brandData && !thread.item.vehicleData && !thread.item.forum && thread.item.scope ? config.getDefault(config.getScopes(), thread.item.scope).label :''} 
                                                                            ${thread.item.brandData && thread.item.brandData.name ? thread.item.brandData.name : '' } 
                                                                            ${thread.item.vehicleData && thread.item.vehicleData.name ? thread.item.vehicleData.name  : ''}
                                                                            ${thread.item.forum && thread.item.forum ? config.getDefault(config.getForums(), thread.item.forum).label :''}
                                                                        `
                                                                    }
                                                                </Label>
                                                            </div>
                                                        }

                                                    </div>
                                                    <Feed.Meta className="date">
                                                        <Moment format="DD.MM.YYYY" calendar={calendarStrings} unix >{thread.item.createDate}</Moment>
                                                        {thread.item.countMessage && thread.item.countMessage > 1 &&
                                                            <div className="thread-posts-count">
                                                                <Image src={commentIcon} alt="Icon Posts"/>
                                                                {thread.item.countMessage - 1}
                                                            </div>
                                                        }
                                                    </Feed.Meta>
                                                </Feed.Content>
                                            </Feed.Event>
                                        </Feed>
                                    </Grid.Column>
                                </Grid>
                        </Segment>
                    )}

                    {isFetching && hasMore &&
                        <>
                            {this.placeholderItem()}
                        </>
                    }

                    {isFailed &&
                        <>
                            <Message negative>Beim Abrufen der Daten ist ein Fehler aufgetreten.</Message>
                            <Grid>
                                <Grid.Column width={16} textAlign="center">
                                    <Form.Button
                                        onClick={this.loadMoreForce}
                                        color="red"
                                    >
                                        Nochmals versuchen
                                    </Form.Button>
                                </Grid.Column>
                            </Grid>
                        </>
                    }

                    <InfiniteScroll
                        onLoadMore={this.loadMore}
                        loading={isFetching}
                        hasNextPage={hasMore}
                    />

                    {/*Workaround for InfiniteScroll*/}
                    &nbsp;
                </div>
            )
        }

        else {
            return (
                <Container text>
                    <Divider hidden/>
                    <Message negative>Es ist ein Fehler aufgetreten.</Message>
                </Container>
            )
        }
    }
}

class Forum extends Component {

    componentDidMount = () => {
        const { dispatch } = this.props;
        dispatch(handleHeaderItems("dashboard"));
        dispatch(setRuntime({page: 'be-forum'}));
        dispatch(handleContainer("secondary", `Deine Fragen`, true));
    }

    render() {
        return (
            <ForumMobile />
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { globalByComponent, account, listByEntity, forumthreadById } = state;

    const { item } = account || {
        item: {}
    } 

    const { list, isFetching } = listByEntity[item.id + '::userThreads'] || {
        list: {},
        isFetching: true
    }

    let listitem = [];
    if (list) {
        for (let [key, value] of Object.entries(list)) {
            if (forumthreadById[value]) {
                listitem.push(forumthreadById[value]);
            }
        }
    }

    return {
        listitem,
        isFetching,
        item
    }
}

Forum = connect(mapStateToProps)(withRouter(Forum));
ForumMobile = connect(mapStateToProps)(withRouter(ForumMobile));

export default {
    Forum,
    ForumMobile,
}
