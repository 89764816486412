import config from '../config/config'
import { createAction } from 'redux-api-middleware';

export const CHANGE_FORUMTHREAD = 'CHANGE_FORUMTHREAD';
export const API_FORUMTHREAD_REQUEST = 'API_FORUMTHREAD_REQUEST';
export const API_FORUMTHREAD_SUCCESS = 'API_FORUMTHREAD_SUCCESS';
export const API_FORUMTHREAD_FAILURE = 'API_FORUMTHREAD_FAILURE';
// export const API_FORUMTHREAD_DELETE_SUCCESS = 'API_FORUMTHREAD_DELETE_SUCCESS';
export const API_FORUMTHREAD_LIST_REQUEST = 'API_FORUMTHREAD_LIST_REQUEST';
export const API_FORUMTHREAD_LIST_SUCCESS = 'API_FORUMTHREAD_LIST_SUCCESS';
export const API_FORUMTHREAD_LIST_FAILURE = 'API_FORUMTHREAD_LIST_FAILURE';
// export const API_FOLLOW_FORUMTHREAD_REQUEST = 'API_FOLLOW_FORUMTHREAD_REQUEST';
// export const API_FOLLOW_FORUMTHREAD_SUCCESS = 'API_FOLLOW_FORUMTHREAD_SUCCESS';
// export const API_FOLLOW_FORUMTHREAD_FAILURE = 'API_FOLLOW_FORUMTHREAD_FAILURE';
export const API_FORUMTHREAD_UPDATE_REQUEST = 'API_FORUMTHREAD_UPDATE_REQUEST';
export const API_FORUMTHREAD_UPDATE_SUCCESS = 'API_FORUMTHREAD_UPDATE_SUCCESS';
export const API_FORUMTHREAD_UPDATE_FAILURE = 'API_FORUMTHREAD_UPDATE_FAILURE';
export const RESET_FORUMTHREAD_LIST = 'RESET_FORUMTHREAD_LIST';
export const API_POST_FORUMTHREAD_REQUEST = 'API_POST_FORUMTHREAD_REQUEST';
export const API_POST_FORUMTHREAD_SUCCESS = 'API_POST_FORUMTHREAD_SUCCESS';
export const API_POST_FORUMTHREAD_FAILURE = 'API_POST_FORUMTHREAD_FAILURE';
export const API_UPDATE_FORUM_MESSAGE_REQUEST = 'API_UPDATE_FORUM_MESSAGE_REQUEST';
export const API_UPDATE_FORUM_MESSAGE_SUCCESS = 'API_UPDATE_FORUM_MESSAGE_SUCCESS';
export const API_UPDATE_FORUM_MESSAGE_FAILURE = 'API_UPDATE_FORUM_MESSAGE_FAILURE';
export const NEW_FORUMTHREAD = "NEW_FORUMTHREAD";
export const CLEAR_NEW_FORUMTHREAD = "CLEAR_NEW_FORUMTHREAD";
export const NEW_FORUMTHREAD_ADD_TAG = "NEW_FORUMTHREAD_ADD_TAG";
export const NEW_FORUMTHREAD_REMOVE_TAG = "NEW_FORUMTHREAD_REMOVE_TAG";
export const NEW_FORUMTHREAD_SAVE_TAG = "NEW_FORUMTHREAD_SAVE_TAG";
export const NEW_FORUMTHREAD_REVERT_TAG = "NEW_FORUMTHREAD_REVERT_TAG";
export const NEW_FORUMTHREAD_DELETE_DIRECT_TAG = "NEW_FORUMTHREAD_DELETE_DIRECT_TAG";
export const CHANGE_MESSAGE = "CHANGE_MESSAGE";
export const API_FORUMTHREAD_NEXT_MSG_REQUEST = "API_FORUMTHREAD_NEXT_MSG_REQUEST";
export const API_FORUMTHREAD_NEXT_MSG_SUCCESS = "API_FORUMTHREAD_NEXT_MSG_SUCCESS";
export const API_FORUMTHREAD_NEXT_MSG_FAILURE = "API_FORUMTHREAD_NEXT_MSG_FAILURE";
export const API_USERTHREADS_REQUEST = "API_USERTHREADS_REQUEST";
export const API_USERTHREADS_SUCCESS = "API_USERTHREADS_SUCCESS";
export const API_USERTHREADS_FAILURE = "API_USERTHREADS_FAILURE"


export function newForumThread(inputForumThread) {
    return {
        type: NEW_FORUMTHREAD,
        payload: {
            inputForumThread,
        }
    }
}

export function clearNewForumThread(){
    return {
        type: CLEAR_NEW_FORUMTHREAD,
    }
}

export function postMessage(message, thread) {
    var id = (thread.id ? thread.id : null);

    return createAction({
        endpoint: `${config.API_HOST}/v1/forum`,
        method: 'POST',
        body: JSON.stringify({
            title: thread.title,
            scope: thread.scope,
            forum: thread.forum,
            forumthread: thread.id,
            project: thread.project,
            projectpart: thread.projectPart,
            brand: thread.brand,
            vehicle: thread.vehicle,
            categories: thread.categories,
            text: message.text,
            media: message.media,
        }),
        types: [
            {type: API_POST_FORUMTHREAD_REQUEST, meta: { id }},
            {type: API_POST_FORUMTHREAD_SUCCESS, meta: { id }},
            {type: API_POST_FORUMTHREAD_FAILURE, meta: { id }},
        ]
    })
}

export function changeMessage(threadId, msgId, page, text, media) {
    return {
        type: CHANGE_MESSAGE,
        meta: {
            threadId,
            msgId,
            page
        },
        payload: {
            text,
            media
        }
    }
}

export function updateMessage(title, message, page) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/forum/message/${message.id}`,
        method: 'PUT',
        body: JSON.stringify({
            //forumthread: { title },
            forummessage: { 
                text: message.inputText,
                media: message.inputMedia
            }
        }),
        types: [
            {type: API_UPDATE_FORUM_MESSAGE_REQUEST, meta: { msgId: message.id, threadId: message.forumThread, page }},
            {type: API_UPDATE_FORUM_MESSAGE_SUCCESS, meta: { msgId: message.id, threadId: message.forumThread, page }},
            {type: API_UPDATE_FORUM_MESSAGE_FAILURE, meta: { msgId: message.id, threadId: message.forumThread, page }},
        ]
    })
}

export function changeThread(id, values) {
    return {
        type: CHANGE_FORUMTHREAD,
        meta: {
            id,
        },
        payload: {
            ...values,
        }
    }
}

export function fetchThread(id, options, page, size) {
    if (!options) {options = {}}
    if (!page) {page = 1}
    if (!size) {size = config.FORUMTHREAD_PAGE_SIZE}

    return createAction({
        endpoint: `${config.API_HOST}/v1/forum/${id}?page=${page}`+(size !== config.FORUMTHREAD_PAGE_SIZE ? `&size=${size}` : ''),
        method: 'GET',
        types: [
            {type: API_FORUMTHREAD_REQUEST, meta: { id, page }},
            {type: API_FORUMTHREAD_SUCCESS, meta: { id, page }},
            {type: API_FORUMTHREAD_FAILURE, meta: { id, page }},
        ]
    })
}

function shouldFetchThread(state) {
    const list = state.listByEntity['forumthread'];
    if (
        !list ||
        ((list.isFetching || list.isFailed) && Date.now() - list.lastFetch > config.FETCH_TIMEOUT)
    ) {
        return true
    }
    return false
}

export function fetchThreadIfNeeded(options, page, size) {
    return (dispatch, getState) => {
        if (shouldFetchThread(getState())) {
            return dispatch(fetchThread(options, page, size))
        }
    }
}

export function resetThreadList() {
    return {
        type: RESET_FORUMTHREAD_LIST
    }
}

export function fetchThreadNextMessage(id, lastMsgId){

    return createAction({
        endpoint: `${config.API_HOST}/v1/forum/${id}?lastId=${lastMsgId}`,
        method: 'GET',
        types: [
            {type: API_FORUMTHREAD_REQUEST, meta: { id }},
            {type: API_FORUMTHREAD_SUCCESS, meta: { id }},
            {type: API_FORUMTHREAD_FAILURE, meta: { id }},
        ]
    })
}

export function fetchThreadList(options, page, size) {
    if (!options) {options = {}}
    if (!page) {page = 1}
    if (!size) {size = 15} //TODO: Use config variable to set page size

    var query = '';
    for (let [key, value] of Object.entries(options)) {
        query += '&'+encodeURIComponent(key)+'='+encodeURIComponent(value);
    }

    return createAction({
        endpoint: `${config.API_HOST}/v1/forum?page=${page}&size=${size}${query}`,
        method: 'GET',
        types: [
            {type: API_FORUMTHREAD_LIST_REQUEST, meta: { page }},
            {type: API_FORUMTHREAD_LIST_SUCCESS, meta: { page }},
            {type: API_FORUMTHREAD_LIST_FAILURE, meta: { page }},
        ]
    })
}

function shouldFetchThreadList(state) {
    const list = state.listByEntity['forumthread'];
    if (
        !list ||
        ((list.isFetching || list.isFailed) && Date.now() - list.lastFetch > config.FETCH_TIMEOUT)
    ) {
        return true
    }
    return false
}

export function fetchThreadListIfNeeded(options, page, size) {
    return (dispatch, getState) => {
        if (shouldFetchThreadList(getState())) {
            return dispatch(fetchThreadList(options, page, size))
        }
    }
}

export function checkForUpdates(options) {
    if (!options) {options = {}}
    var page = 1;
    var size = 1;

    var query = '';
    for (let [key, value] of Object.entries(options)) {
        query += '&'+encodeURIComponent(key)+'='+encodeURIComponent(value);
    }

    return createAction({
        endpoint: `${config.API_HOST}/v1/forum?page=${page}&size=${size}${query}`,
        method: 'GET',
        types: [
            {type: API_FORUMTHREAD_UPDATE_REQUEST, meta: { page, key: 'forumthread' }},
            {type: API_FORUMTHREAD_UPDATE_SUCCESS, meta: { page, key: 'forumthread' }},
            {type: API_FORUMTHREAD_UPDATE_FAILURE, meta: { page, key: 'forumthread' }},
        ]
    })
}

function shouldCheckForUpdates(state) {
    const list = state.listByEntity['forumthread'];
    if (
        list &&
        list.hasUpdate === false &&
        (Date.now() - list.hasUpdateLastFetch > config.FETCH_TIMEOUT)
    ) {
        return true
    }
    return false
}

export function checkForUpdatesIfNeeded(options) {
    return (dispatch, getState) => {
        if (shouldCheckForUpdates(getState())) {
            return dispatch(checkForUpdates(options))
        }
    }
}

export function newForumThreadAddTag(tag){
    return {
        type: NEW_FORUMTHREAD_ADD_TAG,
        payload: {
            tag
        }
    }
}

export function newForumThreadRemoveTag(tag){
    return {
        type: NEW_FORUMTHREAD_REMOVE_TAG,
        payload: {
            tag
        }
    }
}

export function newForumThreadSaveTag(tag){
    return {
        type: NEW_FORUMTHREAD_SAVE_TAG,
        payload: {
            tag
        }
    }
}

export function newForumThreadRevertTag(tag){
    return {
        type: NEW_FORUMTHREAD_REVERT_TAG,
        payload: {
            tag
        }
    }
}

export function newForumThreadDeleteDirectTag(tag){
    return {
        type: NEW_FORUMTHREAD_DELETE_DIRECT_TAG,
        payload: {
            tag
        }
    }
}

function shouldFetchUserThreads(state, id){
    const projectlist = state.listByEntity[id+'::userThreads'];
    if (
        !projectlist ||
        ((projectlist.isFetching || projectlist.isFailed) && Date.now() - projectlist.lastFetch > config.FETCH_TIMEOUT) ||
        (!projectlist.isFetching && Date.now() - (projectlist.lastUpdated ? projectlist.lastUpdated : 0) > config.FETCH_AGAIN)
    ) {
        return true
    }
    return false
}

export function fetchUserThreadsIfNeeded(id) {
    return (dispatch, getState) => {
        if (shouldFetchUserThreads(getState(), id)) {
            return dispatch(fetchUserThreads(id))
        }
    }
}

export function fetchUserThreads(userId){
    return createAction({
        endpoint: `${config.API_HOST}/v1/forum?user=${userId}`,
        method: 'GET',
        types: [
            {type: API_USERTHREADS_REQUEST, meta: { userId }},
            {type: API_USERTHREADS_SUCCESS, meta: { userId }},
            {type: API_USERTHREADS_FAILURE, meta: { userId }},
        ]
    })
}
