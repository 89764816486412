import {
    FETCH_USER_REQUEST,
    FETCH_USER_SUCCESS,
    FETCH_USER_FAILED
} from '../actions/user'
import {
    API_POST_MESSAGE_REQUEST,
    API_POST_MESSAGE_SUCCESS,
    API_POST_MESSAGE_FAILURE,
    NEW_MESSAGE_USER,
} from '../actions/conversation'
import {
    RESET_STORE,
} from '../actions/account'


export default function userById(state = {}, action) {

    try {

        if (action.type === RESET_STORE) {
            return {};
        }

        switch (action.type) {
            case FETCH_USER_REQUEST:
                return {
                    ...state,
                    [action.meta.id]: {
                        ...state[action.meta.id],
                        isFetching: true,
                        isFailed: false
                    }
                }
            case FETCH_USER_SUCCESS:
                return {
                    ...state,
                    [action.meta.id]: {
                        ...state[action.meta.id],
                        user: {
                            ...action.payload,
                        },
                        isFetching: false,
                        isFailed: false
                    }
                }
            case FETCH_USER_FAILED:
                return {
                    ...state,
                    [action.meta.id]: {
                        ...state[action.meta.id],
                        isFetching: false,
                        isFailed: true
                    }
                }
            case NEW_MESSAGE_USER:
                return {
                    ...state,
                    [action.meta.id]: {
                        ...state[action.meta.id],
                        inputText: action.payload.inputText,
                    }
                };
            case API_POST_MESSAGE_REQUEST:
                // Conversation ID
                if (action.meta.userid) {
                    var id = action.meta.userid;
                }
                else {
                    return state;
                }

                return {
                    ...state,
                    [id]: {
                        ...state[id],
                        isPosting: true,
                        postingMessage: '',
                    }
                };
            case API_POST_MESSAGE_SUCCESS:
                // Conversation ID
                if (action.meta.userid) {
                    var id = action.meta.userid;
                }
                else {
                    return state;
                }

                return {
                    ...state,
                    [id]: {
                        ...state[id],
                        isPosting: false,
                        inputText: '',
                        postingMessage: 'SUCCESS',
                    }
                };
            case API_POST_MESSAGE_FAILURE:
                // Conversation ID
                if (action.meta.userid) {
                    var id = action.meta.userid;
                }
                else {
                    return state;
                }

                return {
                    ...state,
                    [id]: {
                        ...state[id],
                        isPosting: false,
                        postingMessage: 'FAILURE',
                    }
                };
            default:
                return state
        }
        
    } catch (e) {

        console.log(e);
        return {
            ...state,
            fatalError: true,
        };

    }
}
