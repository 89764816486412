import {
    CHANGE_STORY,
    NEW_CONTENT,
    DISCARD_CONTENT,
    SAVE_CONTENT,
    API_STORY_REQUEST,
    API_STORY_SUCCESS,
    API_STORY_FAILURE,
    API_STORY_DELETE_SUCCESS,
    API_STORY_LIST_REQUEST,
    API_STORY_LIST_SUCCESS,
    API_STORY_LIST_FAILURE,
    API_LIKE_STORY_REQUEST,
    API_LIKE_STORY_SUCCESS,
    API_LIKE_STORY_FAILURE,
    API_STORY_DIRECT_REQUEST,
    API_STORY_DIRECT_SUCCESS,
    API_STORY_DIRECT_FAILURE,
    API_STORY_SORT_SUCCESS,
} from '../actions/story'
import {
    API_POST_STORY_SUCCESS,
} from '../actions/project'
import {
    RESET_STORE,
} from '../actions/account'
import {
    API_EXPENDITURE_SUCCESS,
    API_EXPENDITURE_DELETE_SUCCESS,
    API_POST_EXPENDITURE_SUCCESS,
} from '../actions/expenditure';
import {
    API_EXPENSE_SUCCESS,
    API_EXPENSE_DELETE_SUCCESS,
    API_POST_EXPENSE_SUCCESS,
} from '../actions/expense';
import {
    API_POST_COMMENT_SUCCESS
} from '../actions/comments'
import {actions} from 'ix-redux-file-upload';


export default function storyById(state = {}, action) {

    try {

        if (action.type === RESET_STORE) {
            return {};
        }

        if (
            action.type === API_EXPENDITURE_SUCCESS ||
            action.type === API_EXPENDITURE_DELETE_SUCCESS ||
            action.type === API_POST_EXPENDITURE_SUCCESS
        ) {
            if (action.payload && action.payload.projectpartentry) {
                // Update current sum
                if (action.payload.projectpartentry.projectPart && state[action.payload.projectpartentry.projectPart]) {
                    state = {
                        ...state,
                        [action.payload.projectpartentry.projectPart]: {
                            ...state[action.payload.projectpartentry.projectPart],
                            item: {
                                ...state[action.payload.projectpartentry.projectPart].item,
                                sumWorkinghours: action.payload.projectpartentry.projectPartSum,
                            }
                        }
                    };
                }
                // Update previous sum
                if (action.payload.projectpartentry.previous_projectPart && state[action.payload.projectpartentry.previous_projectPart]) {
                    state = {
                        ...state,
                        [action.payload.projectpartentry.previous_projectPart]: {
                            ...state[action.payload.projectpartentry.previous_projectPart],
                            item: {
                                ...state[action.payload.projectpartentry.previous_projectPart].item,
                                sumWorkinghours: action.payload.projectpartentry.previous_projectPartSum,
                            }
                        }
                    };
                }
            }

            return state;
        }

        if (
            action.type === API_EXPENSE_SUCCESS ||
            action.type === API_EXPENSE_DELETE_SUCCESS ||
            action.type === API_POST_EXPENSE_SUCCESS
        ) {
            if (action.payload && action.payload.expense) {
                // Update current sum

                 
                if (action.payload.expense.projectPart) {                    
                    state = {
                        ...state,
                        [action.payload.expense.projectPart]: {
                            ...state[action.payload.expense.projectPart],
                            item: {
                                ...state[action.payload.expense.projectPart] ? state[action.payload.expense.projectPart].item : {},
                                sumPrice: action.payload.expense.projectPartSum,
                            }
                        }
                    };
                }
                // Update previous sum
                if (action.payload.expense.previous_projectPart) {
                    state = {
                        ...state,
                        [action.payload.expense.previous_projectPart]: {
                            ...state[action.payload.expense.previous_projectPart],
                            item: {
                                ...state[action.payload.expense.previous_projectPart] ? state[action.payload.expense.previous_projectPart].item : {},
                                sumPrice: action.payload.expense.previous_projectPartSum,
                            }
                        }
                    };
                }
            }

            return state;
        }

        if(action.type === API_POST_COMMENT_SUCCESS){
            //Update comment count 
            if(action.payload.comment.projectPart && state[action.payload.comment.projectPart]){
                return {
                    ...state,
                    [action.payload.comment.projectPart]: {
                        ...state[action.payload.comment.projectPart],
                        item: {
                            ...state[action.payload.comment.projectPart].item,
                            countComment: action.payload.commentcount
                        }
                    }
                }

            }
        }

        if (action.type === API_STORY_DIRECT_REQUEST) {
            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    isUpdating: true,
                }
            };
        }
        if (action.type === API_STORY_DIRECT_SUCCESS) {
            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    item: {
                        ...state[action.meta.id].item,
                        ...action.payload.projectpart,
                    },
                    isUpdating: false,
                }
            };
        }
        if (action.type === API_STORY_DIRECT_FAILURE) {
            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    isUpdating: false,
                }
            };
        }

        else if(action.type === API_LIKE_STORY_REQUEST){
            if(state[action.meta.id]){
                return {
                    ...state,
                    [action.meta.id]: {
                        ...state[action.meta.id],
                        item: {
                            ...state[action.meta.id].item,
                            userWelldone: action.meta.like,
                            isLiking: true,
                        }
                    }
                }
            }
            else {

                return state
            }

        }
        else if(action.type === API_LIKE_STORY_SUCCESS){
            var countWelldone = action.meta.count;
            if(state[action.meta.id]) {
                if (action.meta.like === action.payload) {
                    countWelldone = action.payload ? parseInt(countWelldone) +1 : parseInt(countWelldone) - 1
                }

                    return {
                        ...state,
                        [action.meta.id]: {
                            ...state[action.meta.id],
                            item: {
                                ...state[action.meta.id].item,
                                countWelldone,
                                userWelldone: action.payload,
                                isLiking: false,
                            }
                        }
                    }
            }
            else {
                return state
            }
        }
        else if(action.type === API_LIKE_STORY_FAILURE){

            if(state[action.meta.id]) {
                return {
                    ...state,
                    [action.meta.id]: {
                        ...state[action.meta.id],
                        item: {
                            ...state[action.meta.id].item,
                            userWelldone: (action.meta.like ? false : true),
                            isLiking: false,
                        }
                    }
                }
            }

            else {
                return state;
            }

        }

        if (action.type === CHANGE_STORY) {

            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    isDirty: action.meta.keepDirty,
                    item: {

                        ...action.payload.item,
                    },
                    contents: {

                        ...action.payload.contents,
                    },
                }
            };
        }
        else if (action.type === NEW_CONTENT) {

            var contents = {...state[action.payload.projectPart].contents};

            for (let [k, c] of Object.entries(contents)) {
                if (c.position >= action.payload.position) {
                    ++contents[k].position;
                    contents[k].input.position = contents[k].position;
                }
            };

            return {
                ...state,
                [action.payload.projectPart]: {
                    ...state[action.payload.projectPart],
                    isDirty: true,
                    contents: {
                        ...contents,
                        [action.payload.id]: {
                            ...action.payload,
                        },
                    },
                }
            };
        }
        else if (action.type === DISCARD_CONTENT || action.type === SAVE_CONTENT) {

            return {
                ...state,
                [action.payload.storyId]: {
                    ...state[action.payload.storyId],
                    contents: {
                        ...state[action.payload.storyId].contents,
                        [action.payload.contentId]: {
                            ...action.payload.item,
                        },
                    },
                }
            }
        }
        else if (action.type === API_STORY_REQUEST) {
            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    isFetching: true,
                    lastFetch: Date.now(),
                }
            };
        }
        else if (
            action.type === API_STORY_SUCCESS ||
            action.type === API_POST_STORY_SUCCESS
        ) {

            let contents = {};
            Object.values(action.payload.contents).map((item) => {
                contents[item.id] = {...item, input: JSON.parse(JSON.stringify(item))};
            });

            return {
                ...state,
                [action.payload.projectpart.id]: {
                    ...state[action.payload.projectpart.id],
                    isDirty: false,
                    isFetching: false,
                    isFailed: false,
                    item: {
                        ...action.payload.projectpart,
                    },
                    entries: {
                        ...action.payload.entries,
                    },
                    sumMedias: {
                        ...action.payload.sumMedias,
                    },
                    contents,
                    lastUpdated: Date.now(),
                }
            };
        }
        else if (action.type === API_STORY_FAILURE) {
            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    isFetching: false,
                    isFailed: true,
                    lastUpdated: Date.now(),
                }
            };
        }
        else if (action.type === API_STORY_DELETE_SUCCESS) {
            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    isDirty: false,
                    isFetching: false,
                    isFailed: false,
                    isDeleted: true,
                    item: {},
                    contents: {},
                    sumMedias: {},
                    lastUpdated: Date.now(),
                }
            };
        }
        else if (action.type === actions.FILE_UPLOAD_PROGRESS) {
            var identificator = action.payload.identificator.split('.');

            if (identificator[2] === 'story' && identificator[4] === 'content') {
                var newContents = {...state[identificator[3]].contents};

                for (let [k, c] of Object.entries(newContents)) {
                    if (c.id === parseInt(identificator[5])) {
                        if (newContents[k].input.blockprefix === 'contentgallery' && identificator[6] === 'media') {
                            // Defaults
                            var newPos = 0;
                 
                            
                            if (!newContents[k].input[identificator[6]]) {
                                newContents[k].input[identificator[6]] = [];
                            }

                            
                            // Find last position
                            newContents[k].input[identificator[6]].map(function (mediaitem, index) {
                                if (parseInt(mediaitem.position) >= newPos) {
                                    newPos = parseInt(mediaitem.position) + 1;
                                }
                            });

                            var updated = false;
                            if(newContents[k].input[identificator[6]]){
                                newContents[k].input[identificator[6]].map((item, index) => {
                                    if(item.id == action.payload.fileType.uid && !updated){
                                        item.progress = action.payload.progress;
                                        updated = true;
                                    }
                                })
                            }

                            if(!updated) {
                                // Attach placeholders
                                newContents[k].input[identificator[6]].push({
                                    id: action.payload.fileType.uid,
                                    path: 'LOADING',
                                    type: null,
                                    position: newPos + 1,
                                    uploadPosition: newContents[k].input[identificator[6]].length +1,
                                    progress: action.payload.progress
                                });
                            }
                        }
                        else {
                            newContents[k].input[identificator[6] + 'Path'] = 'LOADING';
                            newContents[k].input[identificator[6] + 'PreviewPath'] = 'LOADING';
                            newContents[k].input[identificator[6] + 'Progress'] = action.payload.progress;
                        }
                    }
                };

                return {
                    ...state,
                    [identificator[3]]: {
                        ...state[identificator[3]],
                        isDirty: true,
                        contents: {
                            ...newContents,
                        },
                    }
                };
            }
            else if (identificator[2] === 'story') {
                return {
                    ...state,
                    [identificator[3]]: {
                        ...state[identificator[3]],
                        isDirty: true,
                        item: {
                            ...state[identificator[3]].item,
                            [identificator[4] + 'Path']: 'LOADING',
                            [identificator[4] + 'Progress']: action.payload.progress
                        },
                    }
                };
            }
        }
        else if (action.type === actions.FILE_UPLOAD_COMPLETE) {
            var identificator = action.payload.identificator.split('.');

            if (identificator[2] === 'story' && identificator[4] === 'content') {
                var newContents = state[identificator[3]].contents;
                var removedLoading = false;

                for (let [k, c] of Object.entries(newContents)) {
                    if (c.id === parseInt(identificator[5])) {
                        if (newContents[k].input.blockprefix === 'contentgallery' && identificator[6] === 'media') {
                            newContents[k].input[identificator[6]].map((mv, mk) => {
                                if (mv.path === 'LOADING' && mv.progress === 100 && mk+1 === mv.uploadPosition && !removedLoading) {
                                    newContents[k].input[identificator[6]][mk] = {
                                        ...mv,
                                        id: action.payload.response.id,
                                        path: action.payload.response.path,
                                        type: action.payload.response.type,
                                    };
                                    removedLoading = true;
                                }
                            });
                        }
                        else {
                            newContents[k].input[identificator[6]] = action.payload.response.id;
                            newContents[k].input[identificator[6] + 'Path'] = action.payload.response.path;
                            newContents[k].input[identificator[6] + 'PreviewPath'] = action.payload.response.previewPath;
                        }
                    }
                };

                return {
                    ...state,
                    [identificator[3]]: {
                        ...state[identificator[3]],
                        isDirty: true,
                        contents: {
                            ...newContents,
                        },
                    }
                };
            }
            else if (identificator[2] === 'story') {
                return {
                    ...state,
                    [identificator[3]]: {
                        ...state[identificator[3]],
                        isDirty: true,
                        item: {
                            ...state[identificator[3]].item,
                            [identificator[4]]: action.payload.response.id,
                            [identificator[4] + 'Path']: action.payload.response.path,
                        },
                    }
                };
            }
        }
        else if (action.type === actions.FILE_UPLOAD_ERROR) {
            var identificator = action.payload.identificator.split('.');

            if (identificator[2] === 'story' && identificator[4] === 'content') {
                var newContents = state[identificator[3]].contents;
                var removedLoading = false;
                for (let [k, c] of Object.entries(newContents)) {
                    if (c.id === parseInt(identificator[5])) {
                        if (newContents[k].input.blockprefix === 'contentgallery' && identificator[6] === 'media') {
                            newContents[k].input[identificator[6]].map((mv, mk) => {
                                if (mv.path === 'LOADING' && mk+1 === mv.uploadPosition && !removedLoading) {
                                    newContents[k].input[identificator[6]][mk] = {
                                        ...mv,
                                        path: "ERROR",
                                    };
                                    removedLoading = true;
                                }
                              
                            });
                        }
                        else {
                            newContents[k].input[identificator[6]] = action.payload.response.id;
                            newContents[k].input[identificator[6] + 'Path'] = "ERROR";
                            newContents[k].input[identificator[6] + 'PreviewPath'] = "ERROR";
                        }
                    }
                };

                return {
                    ...state,
                    [identificator[3]]: {
                        ...state[identificator[3]],
                        isDirty: true,
                        contents: {
                            ...newContents,
                        },
                    }
                };
            }
            else if (identificator[2] === 'story') {
                return {
                    ...state,
                    [identificator[3]]: {
                        ...state[identificator[3]],
                        isDirty: true,
                        item: {
                            ...state[identificator[3]].item,
                            [identificator[4]]: "ERROR",
                            [identificator[4] + 'Path']: "ERROR",
                        },
                    }
                };
            }
        }
        else if (action.type === API_STORY_LIST_SUCCESS) {

            action.payload.list.map((pl) => {
                if (!state[pl.projectpart.id]) {
                    state = {
                        ...state,
                        [pl.projectpart.id]: {
                            item: {},
                            sumMedias: {},
                            contents: {},
                        }
                    }
                }

            let contents = {};
            Object.values(pl.contents).map((item) => {
                contents[item.id] = {...item, input: JSON.parse(JSON.stringify(item))};
            });

                state = {
                    ...state,
                    [pl.projectpart.id]: {
                        ...state[pl.projectpart.id],
                        isDirty: false,
                        isFetching: false,
                        isFailed: false,
                        item: {
                            ...pl.projectpart,
                        },
                        sumMedias: {
                            ...pl.sumMedias,
                        },
                        contents,
                        lastUpdated: Date.now(),
                    }
                }
            }
            );
        }

        else if (action.type === API_STORY_SORT_SUCCESS) {

            for (let [id, pos] of Object.entries(action.payload.projectparts)) {
                state = {
                    ...state,
                    [id]: {
                        ...state[id],
                        item: {
                            ...state[id].item,
                            position: pos,
                        },
                    }
                }
            }
        }

        return state;

    } catch (e) {
        console.log(e);
        return {
            ...state,
            fatalError: true,
        };

    }
}
