import React, { Component } from 'react';
import { connect } from 'react-redux';
import Headroom from 'react-headroom';
import { Container, Dropdown, Menu, Image, Form, Grid, Button, Label, Icon, Divider, Responsive, Tab } from 'semantic-ui-react';
import { handleForumFilter, fetchVehicleData, fetchModelData, fetchThreadsCount, handleFilterTags, handleForumTopicFilter } from '../../actions/filter';
import Select from 'react-select';
import { RemoveScrollBar, zeroRightClassName } from 'react-remove-scroll-bar';
import CustomPopup from '../CustomPopup/CustomPopup';
import config from '../../config/config';
import './FilterSearch.scss';
import filterIcon from '../../images/icons/frontend/icon-filter.svg';
import activeFilterIcon from '../../images/icons/frontend/icon-active-filter.svg';
import AddIcon from '../../images/icons/circle-add-element.svg';
import ForumTags from '../forum/forumTags';
import { fetchThreadList, resetThreadList } from '../../actions/forumthread';

class ForumFilter extends Component {

    state = {
        filterSearchIsOpen: false,
        modalContent: "mainFilter",
        usedTags: [],
        filterPosition: 0,
        activeIndex: 0,
        showTagging: false
    }

    componentDidMount = () => {
        const { dispatch, categories, brand, model, scope, tags, forum } = this.props;
        document.getElementById('root').addEventListener('click', this.handleClick, false);
        dispatch(fetchVehicleData(0, true));
        if(!forum){
            dispatch(fetchThreadsCount(scope, brand, model, tags));
        }
        else {
            dispatch(fetchThreadsCount(null, null, null, null, forum));
            this.setState({ activeIndex: 1 })
        }

        //Set already choosed tags to input field 
        if(categories && categories.length > 0){
            dispatch(handleFilterTags(categories))
        }
        
        //Get Position of headroom element (filter bar)
        if(document.querySelector('.forum-filter-headroom')){
            this.setState({ filterPosition: document.querySelector('.forum-filter-headroom').offsetTop })
        }

        //Show tagging if there is some options already choosen
        if(scope || brand || model || (categories && categories.length > 0) || forum){
            this.setState({ showTagging: true })
        }

    }

    componentDidUpdate = (prevProps) => {
        const { dispatch, categories, scope, brand, model, forum} = this.props;
        //Update choosed tags to input field 
        if(categories !== prevProps.categories){
            dispatch(handleFilterTags(categories))
        }

        //Hide tagging if no options choosen
        if(
            (prevProps.scope !== scope) ||
            (prevProps.brand !== brand) ||
            (prevProps.model !== model) ||
            (categories !== prevProps.categories) ||
            (prevProps.forum !== forum)
          ) {
            if(!scope && !brand && !model && (categories && categories.length === 0) && !forum){
                this.setState({ showTagging: false })
            }
          }
        
    }

    componentWillUnmount = () => {
        document.getElementById('root').removeEventListener('click', this.handleClick, false);
    } 

    handleChangeDropdown = (e, {value, text}) => {
        const { dispatch } = this.props;
    }

    handleCategoryOnChange = (e) => {
        const { dispatch, brand, model, categories } = this.props;

        let tags = categories ? categories : [];

        dispatch(handleForumFilter(e, null, null, tags));
        dispatch(fetchVehicleData(e.value, true));
        dispatch(fetchThreadsCount(e, brand, model, tags));
    }

    handleBrandOnChange = (e) => {
        const { dispatch, scope, model, categories } = this.props;
        let tags = categories ? categories : [];

        dispatch(handleForumFilter(scope, e, '', tags));
        dispatch(fetchModelData(scope ? scope.value : '', e.value));
        dispatch(fetchThreadsCount(scope, e, model, tags));
    }

    handleModelOnChange = (e) => {
        const { dispatch, scope, brand, categories } = this.props;
        let tags = categories ? categories : [];

        dispatch(handleForumFilter(scope, brand, e, tags));
        dispatch(fetchThreadsCount(scope, brand, e, tags));
    }

    handleChangeForumTopic = (e) => {
        const { dispatch } = this.props;

        dispatch(handleForumTopicFilter(e));
        dispatch(fetchThreadsCount(null, null, null, null, e));
    }

    handleAddTag = (tag) => {
        const { dispatch, scope, brand, model, categories, inputTags } = this.props;
        let tags = inputTags ? inputTags : [];
        
        dispatch(handleFilterTags([...tags, tag]))
    }

    handleDeleteTag = (tag) => {
        const { dispatch, scope, brand, model, inputTags } = this.props;
        let tags = inputTags && inputTags.filter(item => item.value !== tag.value);
        dispatch(handleFilterTags(tags))
    }

    handleDirectDeleteTag = (tag) => {
        const { dispatch, scope, brand, model, inputTags } = this.props;
        let tags = inputTags && inputTags.filter(item => item.value !== tag.value);

        dispatch(handleForumFilter(scope, brand, model, tags));
        dispatch(fetchThreadsCount(scope, brand, model, tags));
    }

    handleRevertTag = () => {
        const { dispatch, scope, brand, model, categories } = this.props;
        let tags = categories ? categories : [];

        //Reset all tags 
        dispatch(handleFilterTags(tags))
        dispatch(handleForumFilter(scope, brand, model, tags));
        this.setState({ modalContent: "mainFilter" }) //Back to main filter
    }

    handleSaveTag = () => {
        const { dispatch, scope, brand, model, inputTags } = this.props;
        let tags = inputTags ? inputTags : [];

        dispatch(handleForumFilter(scope, brand, model, tags));
        dispatch(fetchThreadsCount(scope, brand, model, tags));
        this.setState({ modalContent: "mainFilter" }) //Back to main filter
    }

    resetFilter = () => {
        //Reset all filter settings
        const { dispatch } = this.props;

        dispatch(handleForumFilter(null, null, null, null));
        dispatch(fetchThreadsCount());
    }

    handleShowResults = () => {
        //Show Results
        const { dispatch, scope, brand, model, categories, forum } = this.props;

        dispatch(resetThreadList());
        this.setState({ filterSearchIsOpen: false });
        //Show tagging only when options available 
        if(scope || brand || model || (categories && categories.length > 0) || forum){
            this.setState({ showTagging: true })
        } 
    }

    handleTabChange = (e, { activeIndex }) => this.setState({ activeIndex });

    handleShowCurrentTags = () => {
        const { scope, brand, model, categories } = this.props;

        if(!scope && !brand && !model && (categories && categories.length > 0)){
            this.setState({ showTagging: false })
        }        
    }

    render() {
        const { categories, inputTags, dispatch, scope, brand, model, forum } = this.props;
        const { filterSearchIsOpen, modalContent, filterPosition, showTagging } = this.state;

        return (
            <>
                <Headroom
                    disableInlineStyles
                    pinStart={filterPosition}
                    className={filterSearchIsOpen ? "forum-filter-headroom filter-show" : "forum-filter-headroom"}
                >

                    <div className={zeroRightClassName + " headroom-content"}>
                        <div className="top-bar" ref={node => this.node = node}>
                            <Menu
                                secondary
                                className="topheader-sort"
                            >
                                <Container>
                                    <Menu.Item position="left">
                                        {/* <Dropdown TODO: ADD DROPDOWN
                                            inline
                                            options={config.getForumOrdersDropdown()}
                                            onChange={this.handleChangeDropdown}
                                        /> */}
                                    </Menu.Item>
                                    <Menu.Item  className="toggle-filter" onClick={() => this.setState({ filterSearchIsOpen: true })}>
                                        <Image alt="Icon Filter" src={filterSearchIsOpen ? activeFilterIcon : filterIcon}/>
                                        <span className={filterSearchIsOpen ? "active-filter" : null}>Filter</span>
                                    </Menu.Item>
                                </Container>
                            </Menu>

                        </div>
                    </div>

                </Headroom>

                {/* Show choosed tagging */}
                    <Container text className="choosed-tags">
                        {showTagging &&
                        <>
                            {((scope && scope.value )|| (brand && brand.value )|| (model && model.value) || (forum && forum.value) || (categories && categories.length > 0)) && 
                                <span><strong>Tags: </strong></span>
                            }
                            {scope && 
                                <Label 
                                    as='a' 
                                    onClick={() => {
                                        this.handleCategoryOnChange('') 
                                        dispatch(resetThreadList())
                                        this.handleShowCurrentTags()
                                        }
                                    }
                                >
                                    {scope.label}
                                    <Icon name="delete"/>
                                </Label>
                            }

                            {brand && 
                                <Label 
                                    as='a' 
                                    onClick={() => {
                                        this.handleBrandOnChange('') 
                                        dispatch(resetThreadList())
                                        this.handleShowCurrentTags()
                                        }
                                    }
                                >
                                    {brand.label} {model.label}
                                    <Icon name="delete"/>
                                </Label>
                            }
                            {forum && 
                                <Label 
                                    as='a' 
                                    onClick={() => {
                                        this.handleChangeForumTopic('') 
                                        dispatch(resetThreadList())
                                        this.handleShowCurrentTags()
                                        }
                                    }
                                >
                                    {forum.label}
                                    <Icon name="delete"/>
                                </Label>
                            }

                            {categories && categories.length > 0 && 
                                categories.map((tag, index) => (
                                    <Label 
                                        as='a' 
                                        onClick={() => {
                                            this.handleDirectDeleteTag(tag)
                                            dispatch(resetThreadList())
                                            this.handleShowCurrentTags()
                                        }}
                                    >
                                        {tag.label}
                                        <Icon name="delete"/>
                                    </Label>
                                ))}
                       </> }
                    </Container>
                

                {/* Tags popup */}
                <CustomPopup
                    popupOpen={filterSearchIsOpen}
                    onClose={() => modalContent === "mainFilter" ? this.setState({ filterSearchIsOpen: false }) : this.setState({ modalContent: "mainFilter" })}
                    popupHeader={modalContent === "mainFilter" ? "Fragen filtern" : "Tags hinzufügen"}
                    headerBackgroundWhite
                    showCloseIcon={false}  
                >
                    {modalContent === "mainFilter" ? 
                        this.mainFilter()
                        :
                        <ForumTags
                            onRequestClose={this.handleRevertTag}
                            onRequestSave={this.handleSaveTag}
                            handleTag={this.handleAddTag}
                            handleDeleteTag={this.handleDeleteTag}
                            userTags={categories}
                            inputTags={inputTags}
                            messageText="Wähle hier mittels Tags die Themen aus die Du im Forum angezeigt bekommen möchtest."
                        />
                    }
                </CustomPopup>
            </>
        )
    }
    mainFilter(){

        const { count, categories } = this.props;
        const filterPanes=[
            {
                menuItem: 'Oldtimer Forum',
                render: () => this.vehicleFilter(),
            },
            {
                menuItem: 'Allgemeine Foren',
                render: () => this.topicFilter(),
            },
        ]

        return (
            <div className="filter-options forum-filter">
                <RemoveScrollBar/>
                {/* Tab menu */}
                <Tab
                    activeIndex={this.state.activeIndex}
                    className="custom-tab-menu"
                    menu={{ pointing: true }}
                    onTabChange={this.handleTabChange}
                    panes={filterPanes}
                />

                <Divider hidden/>
                                
                                <Grid>
                                    <Grid.Column textAlign="center" width={16}>
                                        <Button basic color="red" onClick={() => this.resetFilter()}>Zurücksetzen</Button>
                                        <Button 
                                            color="red" 
                                            onClick={count && count === "0" ? null : this.handleShowResults}
                                            disabled={count && count === "0" ? true : false}
                                        >
                                            {count && count}
                                            {count && count === "0" ? " Fragen gefunden" : 
                                                parseInt(count) === 1 ? " Frage gefunden" : " Fragen gefunden"
                                            }
                                        </Button>
                                    </Grid.Column>
                                </Grid>

                                <Container className="filter-tags">
                                    <strong>Filter mit Tags verfeinern:</strong>
                                    {categories && categories.length > 0 ?
                                        categories.map((tag, index) => (
                                            <Label as='a' key={index} onClick={() => this.handleDirectDeleteTag(tag)}>
                                                {tag.label}
                                                <Icon name="delete"/>
                                            </Label>
                                        ))
                                        :
                                        "Es wurden keine Tags hinzugefügt."
                                    }
                                    
                                    <div className="add-tag" onClick={() => this.setState({ modalContent: "AddTagging" })}>
                                        <Image lazy title="Tag hinzufügen" alt="Icon Circle Add" src={AddIcon} />
                                    </div>
                                </Container>
            </div>
        )
    }
    vehicleFilter(){

        const { scope, brand, model, vehicle, vehicleModel, count, categories, forum } = this.props;

        return (
            
                <Container>
                <Form>
                    <Form.Group widths="3">
                        <Form.Field>
                            <label>Fahrzeugart</label>
                            <Select menuPlacement="auto"
                                className="inline-select"
                                maxMenuHeight="200"
                                value={scope}
                                onChange={this.handleCategoryOnChange}
                                                options={config.getScopes()}
                                                placeholder="Auswählen..."
                                                isSearchable
                                                noOptionsMessage={() => "Keine Ergebnisse gefunden"}
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Marke</label>
                                            <Select menuPlacement="auto"
                                                className="inline-select"
                                                value={brand}
                                                maxMenuHeight="200px"
                                                onChange={this.handleBrandOnChange}
                                                options={vehicle}
                                                placeholder="Auswählen..."
                                                isSearchable
                                                noOptionsMessage={() => "Keine Ergebnisse gefunden"}
                                            />
                                        </Form.Field>
                                        <Form.Field inline>
                                            <label>Modell</label>
                                            <Select menuPlacement="auto"
                                                className="inline-select"
                                                value={model}
                                                onChange={this.handleModelOnChange}
                                                options={vehicleModel}
                                                placeholder="Auswählen..."
                                                noOptionsMessage={() => "Keine Ergebnisse gefunden"}
                                                isDisabled={!brand && true }
                                                isSearchable
                                            />
                                        </Form.Field>
                                    </Form.Group>
                                </Form>

                            </Container>

        )
    }
    topicFilter(){

        const { forum } = this.props;

        return (
            <Container className="topic-filter">
                <Form>
                    <Form.Group widths="2">
                        <Form.Field>
                            <label>Foren</label>
                            <Select
                                menuPlacement="auto"
                                classNamePrefix="custom-select"
                                className="custom-select-wrapper"
                                onChange={this.handleChangeForumTopic}
                                value={forum}
                                options={config.getForums()}
                                placeholder="Auswählen..."
                            />
                        </Form.Field>
                    </Form.Group>
                </Form>
            </Container>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { globalByComponent } = state

    const { vehicle, vehicleModel } = globalByComponent[
        'Filter'
    ] || {
        vehicle: '',
        vehicleModel: ''
    }

    const { scope, brand, model, count, categories, inputTags, forum } = globalByComponent[
        'ForumFilter'
    ] || {
        scope: '',
        brand: '',
        model: '',
        count: {},
        categories: [],
        inputTags: [],
        forum: ''
    }
    return {
        scope,
        brand,
        model,
        vehicle,
        vehicleModel,
        count,
        categories,
        inputTags,
        forum
    }

}

export default connect(mapStateToProps)(ForumFilter);
