import React, { Component } from 'react';
import './Comments.scss';
import { Comment, Container, Image, Responsive, Divider, Header, Placeholder, Button, Form, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import CustomSlidingPane  from '../CustomSlidingPane/CustomSlidingPane';
import TextareaAutosize from 'react-textarea-autosize';
import CustomPopup from '../CustomPopup/CustomPopup';
import { toggleSlidingPaneSecondary, getElementId, handleComments } from '../../actions/global';
import fetchCommentsIfNeeded, {fetchPreviousComments, fetchComments, fetchNextComments, newComment, postComment} from '../../actions/comments'
import ImageWrapper from '../../components/helpers/image-wrapper';
import config from '../../config/config'
import sendIconWhite from '../../images/icons/icon-send-white.svg';
import ProgressiveImager from '../../components/helpers/progressive-imager';

/* Icons */
import sendIcon from '../../images/icons/frontend/icon-send.svg';
//import cameraIcon from '../../images/icons/frontend/icon-camera-red.svg'; - Picture upload icon

class CommentsInput extends Component {

    constructor(props) {
        super(props)

        this.handleInput = this.handleInput.bind(this);
        this.submit = this.submit.bind(this);

    }

    componentDidMount = () => {
        const { inputText } = this.props;

        if(inputText){
            this.setState({enableCommentField: true})
        }

    }

    componentDidUpdate = () => {
        if (this.textarea) {
            this.textarea.focus({ preventScroll: true })
        }
    }

    handleInput(e, element) {
        const { dispatch, parent, inputText } = this.props;
        dispatch(newComment(e.target.value, parent));
    }

    submit(e, element) {
        const { dispatch, inputText, parent } = this.props;
        dispatch(postComment(inputText, parent));
    }

    render() {
        const { inputText, isPosting, parent, type, user } = this.props;

        var answer = (parent.indexOf('comment::') === 0 ? true : false);

            return (
                <div className= {type === "blog" ? "textfield-comment-wrapper blog-comment-field" : "textfield-comment-wrapper"}>
                    
                    {/* Render User Avatar for blog comments */}
                    {type === "blog" && 
                        <div className="user-avatar">
                            <ImageWrapper avatar path={user.mediaavatarPath}>
                                <ProgressiveImager circular placeholder="avatar" src={config.getImageUrl('avatar', user.mediaavatarPath)}/>
                            </ImageWrapper>
                        </div>
                    }
                    
                    <div className="textfield">
                        <TextareaAutosize
                            placeholder={answer ? "Antworten..." : "Kommentieren..."}
                            textContentType="oneTimeCode"
                            minRows="1"
                            maxRows="10"
                            autoComplete="nope"
                            value={inputText}
                            
                            autoFocus={true}
                            disabled={isPosting ? true : false} /* disable when sending*/
                            onChange={(e) => {
                                this.handleInput(e)
                            }}
                        />
                    </div>
                    <div
                        className="send comment-icon"
                        onClick={inputText && inputText.trim() ? this.submit : null}
                    >

                        {isPosting ? 
                            <Loader active />
                        :
                            <Image className={!inputText && "disabled" } src={type === "blog" ? sendIconWhite : sendIcon} alt="Icon Send" />
                        }
                        
                    </div>
                </div>
            )
    }

}

function mapStateToProps(state, ownProps){
    const { commentsByParent, account } = state

    const { inputText, isPosting } = commentsByParent[
        ownProps.parent
    ] || {
        inputText: '',
        isPosting: false,
    }

    return {
        user: (account.item ? account.item : {}),
        inputText,
        isPosting,
    }
}

export default connect(mapStateToProps)(CommentsInput);
