import config from '../../config/config';
import VeteroLogoPNG from '../../images/Vetero Bild-Wort-Marke quer RGB.png';
import * as moment from "moment";

//Note: Use moment package to get correct date type (ISO)

//Organization Object Data
let organization = {
    "publisher": {
        "@type": "Organization",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Lautertal, Deutschland",
          "postalCode": "64686",
          "streetAddress": "Nibelungenstraße 449",
        },
        "sameAs": [
            "https://www.facebook.com/oldtimerprojekte",
            "https://www.linkedin.com/company/vetero-oldtimer",
            "https://www.instagram.com/vetero_oldtimer"
        ],
        "email": "info@vetero.de",
        "name": "Vetero OHG",
        "telephone": "+49 6254/9439-10",
        "logo": "https://www.vetero.de" + VeteroLogoPNG
    }
}

//Forum Detail
export const StructuredDataForumDetail = (item, message) => {
    
    const data = {
        "@context":"https://schema.org",
        "@type":"DiscussionForumPosting",
        "@id":`https://www.vetero.de/forum/${item.urlslug}/${item.id}`,
        "mainEntityOfPage": `https://www.vetero.de/forum/${item.urlslug}/${item.id}`,
        "headline": item.title,
        "text": message ? message[0].forummessage.text : item.title,
        "datePublished": moment.unix(item.createDate),
        "commentCount": parseInt(item.countMessage)-1, // First message doesn't count because it's a question
        ...item.editDate ? {"dateModified": moment.unix(item.editDate)} : {"dateModified": moment.unix(item.createDate)},
        "author": {
            "@type": "Person",
            "name": item.userData.name
        },
        ...organization,
        "interactionStatistic": {
            "@type": "InteractionCounter",
            "interactionType": "https://schema.org/CommentAction",
            "userInteractionCount": parseInt(item.countMessage)-1 // First message doesn't count because it's a question
        },
        /*...parseInt(item.countMessage) > 1 && 
        message && message.map(msg => (
            {"comment": {
                "@type": "https://schema.org/Comment",
                "@id": msg.forummessage.id,
                "dateCreated": moment.unix(msg.forummessage.createDate),
                "text": msg.forummessage.text,
                "author": {
                    "@type": "Person",
                    "name": item.userData.name
                },
            }}
        ))*/
    }

    return JSON.stringify(data);
}

//Forum Listing
export const StructuredDataForumListing = (item) => {
    const data = {
        "@context": "https://schema.org",
        "@type": "ItemList",
        //...organization,
        "itemListElement": [
            item.map((item, index) => (
                {
                    "@type": "ListItem",
                    "position": index,
                
                        "name": item.item.title,
                        "url": `https://www.vetero.de/forum/${item.item.urlslug}/${item.item.id}`,
                    
                }
            ))
        ]
    }

    return JSON.stringify(data);
}

//Vehicle Structured Data
export const StructuredVehicleData = (vehicle, brand, td, item) => {

    /*let vehicleType;
    if(vehicle.scope === config.SCOPE_BIKE) {
        vehicleType = "Motorcycle"
    } else {
        vehicleType = "Car"
    }*/

    //Just use Vehicle...

    const data = {
        "@context":"https://schema.org/",
        "@type": "Vehicle",
        "name": vehicle.name, //Model
        "manufacturer": brand.name,  //Brand (Company/Organization)
        "brand":  brand.name,
        ...td.year && {"productionDate": td.year},
        ...td.year && {"modelDate": td.year},
        ...vehicle.variant && {"bodyType": vehicle.variant},
        "steeringPosition":{"@id":"https://schema.org/LeftHandDriving"},              
        ...td.gear && {"vehicleTransmission": config.getDefault(config.getGears(), td.gear).label},
        ...td.gearno && {"numberOfForwardGears" : td.gearno},
        "image": `https://api.vetero.de/media/cache/mainDesktop/${item.mediamainPath}`,
        "vehicleEngine":[
            {
            "@type":"EngineSpecification",
            ...td.fuel && {"engineType":config.getDefault(config.getFuels(), td.fuel).label},
            ...td.displacement && {
                "engineDisplacement":{
                    "@type":"QuantitativeValue",
                    "value": td.displacement,
                    "unitCode":"CMQ"},
            },  
            ...td.performance && {
                "enginePower":{
                    "@type":"QuantitativeValue",
                    "value": td.performance,
                    "unitCode":"BHP"}
            },
            }
        ], 
        "offers":{
            "@type":"Offer",
            "availability": "https://schema.org/OutOfStock",
            "price": 0
         }
    }

    return JSON.stringify(data);
}

//Blog Listing 
export const StructuredBlogData = (item) => {
    
    const data = {
        "@context":"https://schema.org/",
        "@type":"BlogPosting",
        "@id": `https://www.vetero.de/oldtimer-blog/${item.slug}/${item.id}`,
        "mainEntityOfPage": `https://www.vetero.de/oldtimer-blog/${item.slug}/${item.id}`,
        "image": item._embedded['wp:featuredmedia'][0].media_details.sizes.medium_large.source_url,
        "datePublished": item.modified,
        "headline": item.title.rendered,
        "name": item.title.rendered,
        "author": {
            "@type": "Person",
            "name": "Vetero",
        },
        ...organization,
      }

    return JSON.stringify(data);
}