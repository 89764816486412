import React, { Component } from 'react'
import { Container, Grid, Image, Divider, Header, Responsive, Placeholder, Message } from 'semantic-ui-react'
import { connect } from 'react-redux';
import fetchStoryIfNeeded, {likeStory, fetchStory}  from '../../actions/story';
import fetchProjectIfNeeded  from '../../actions/project';
import { footerActiveItem, handleContainer, handleHeaderItems, setRuntime } from '../../actions/global'
import CustomSlidingPane from '../../components/CustomSlidingPane/CustomSlidingPane';
import Helmet from 'react-helmet'
import ImageWrapper from '../../components/helpers/image-wrapper'
import { Link as RouterLink } from "react-router-dom";
import FeContentElement from  './contentElement/fe-content-element';
import config from '../../config/config';
import ProgressiveImager from '../../components/helpers/progressive-imager';
import Comments from '../../components/Comments/Comments';
import NumericLabel from 'react-pretty-numbers'
import Account from '../../components/account/Account';
/* ICONS */
import heartIcon from '../../images/icons/frontend/icon-heart.svg';
import commentIcon from '../../images/icons/frontend/icon-comment.svg';
import eurIcon from '../../images/icons/frontend/icon-euro-circle.svg';
import clockIcon from '../../images/icons/frontend/icon-clock-circle.svg';
import heartIconDark from '../../images/icons/frontend/icon-heart-dark.svg';
import heartIconActive from '../../images/icons/frontend/icon-active-heart.svg';
import ErrorHandler from '../../components/ErrorHandler/ErrorHandler';

import './stories.scss';

const nl2br = require('react-nl2br');

class FeStory extends Component {

    state = {
        galleryOpen: false,
        currentSlide: 1,
        slidingPane: {
            isOpen: false,
            title: '',
            content: '',
        },
        loginPopup: false
    }

    componentDidMount(){
        window.scrollTo(0, 0);
        const { dispatch, match, item, projectItem, location } = this.props;
        dispatch(footerActiveItem("project"));
        dispatch(handleHeaderItems("projects"));
        dispatch(fetchStoryIfNeeded(match.params.storyid));
        // dispatch(fetchProjectIfNeeded(match.params.id));
        if (projectItem && projectItem.id) {
            dispatch(handleContainer("secondary", 'SPAN#Story', null, null, null, null, {'title': projectItem.title+' – '+item.title}));
            dispatch(setRuntime({
                parent: {page: 'fe-project-id-'+projectItem.id, path: `/oldtimer/${projectItem.urlslug}/${projectItem.id}`},
            }));
        }
        else {
            dispatch(handleContainer("secondary", 'SPAN#Story'));
        }
        dispatch(setRuntime({
            page: 'fe-story-id-'+match.params.storyid,
        }));
        // Fetch project
        if (projectItem && projectItem.id) {
            dispatch(fetchProjectIfNeeded(projectItem.id));
        }
        //Show slidingPane on mount

        if(location.hash === '#comments'){
            this.handleSlidingPanes(true, 'Kommentare', 'comments')
        }
    }

    componentDidUpdate(prevProps) {
        const { dispatch, match, item, projectItem } = this.props;
        if (match.params.storyid !== prevProps.match.params.storyid) {
            dispatch(fetchStoryIfNeeded(match.params.storyid));
            window.scrollTo(0, 0);
        }
        if ((!prevProps.projectItem || !prevProps.projectItem.id) && projectItem && projectItem.id) {
            dispatch(handleContainer("secondary", 'SPAN#Story', null, null, null, null, {'title': projectItem.title+' – '+item.title}));
            dispatch(setRuntime({
                parent: {page: 'fe-project-id-'+projectItem.id, path: `/oldtimer/${projectItem.urlslug}/${projectItem.id}`},
            }));
        }
        // Fetch project
        if ((!prevProps.item || !prevProps.item.id) && item && item.id) {
            dispatch(fetchProjectIfNeeded(item.project));
        }
    }

    componentWillUnmount() {
        const { dispatch, match } = this.props;

        dispatch(setRuntime({
            backUser: {page: 'fe-story-id-'+match.params.storyid, path: this.props.location},
        }));
    }

    handleSlidingPanes = (isOpen, title, content) => {
      this.setState({
          slidingPane: {
              isOpen,
              title,
              content,
          }
      })
    }

    handleSlidingPanesContent = () => {
        const { match } = this.props;
        if(this.state.slidingPane.content === "comments") {
            return <Comments parent={"projectpart::" + match.params.storyid} />
        }
    }

    handleCloseMobileComments = () => {
        this.setState({mobileComments: {show: false}})
    }

    handleLikeStory = (id, value) => {
      const { dispatch, isLiking, item } = this.props;

      if(!isLiking) {
          dispatch(likeStory(id, value, item.countWelldone));
      }
    }

    handleGalleryToggle = (slide) => {
        this.setState({galleryOpen: !this.state.galleryOpen});
        if(slide) {
            this.setState({currentSlide: slide});
        }
    }

    reloadStory = () => {
        const { dispatch, match } = this.props;
        dispatch(fetchStory(match.params.storyid));
    }

    render() {
        const { item, contentList, isFetching, isFailed, accountItem, projectItem, brand, vehicle, td, projectIsFetching } = this.props;
            return (
                <>
                {(projectIsFetching || isFetching) && !(projectItem && item) &&
                    <div className="fetching-story">
                        <div className="story-header">
                            <div className="image">
                                <Placeholder>
                                    <Placeholder.Image/>
                                </Placeholder>
                            </div>
                            <div className="project-details">
                                <Container>
                                    <Grid>
                                        <Grid.Row>

                                            <Grid.Column width={4} textAlign="center">
                                                <div className="detail">
                                                    <Image src={clockIcon} alt="Icon Clock"/>
                                                    <span className="detail-sum">-</span>
                                                </div>
                                            </Grid.Column>

                                            <Grid.Column width={4} textAlign="center">
                                                <div className="detail">
                                                    <Image src={eurIcon} alt="Icon Euro"/>
                                                    <span className="detail-sum">-</span>
                                                </div>
                                            </Grid.Column>

                                            <Grid.Column width={4} textAlign="center">
                                                <div className="detail">
                                                    <Image src={heartIcon} alt="Icon Like" title="Gefällt mir"/>
                                                    <span className="detail-sum">-</span>
                                                </div>
                                            </Grid.Column>

                                            <Grid.Column width={4} textAlign="center">
                                                <div className="detail">
                                                    <Image src={commentIcon} alt="Icon Kommentieren" title="Kommentieren"/>
                                                    <span className="detail-sum">-</span>
                                                </div>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Container>
                            </div>
                        </div>
                        <Container>
                            <Placeholder className="story-content-placeholder">
                                <Placeholder.Line length="very long"/>
                                <Placeholder.Line length="very long"/>
                                <Placeholder.Line length="medium"/>
                                <Placeholder.Line length="short"/>
                                <Placeholder.Image/>
                            </Placeholder>
                        </Container>
                    </div>
                }


                {!isFetching && !isFailed && item && item.id && projectItem &&
                    <>

                    {projectItem && projectItem.urlslug &&
                        <Helmet>
                            <meta name="description" content={"Story "+item.title+" zum Oldtimer "+brand.name+" "+vehicle.name+" ("+td.year+") von "+projectItem.userData.name+". Auf Vetero findest Du viele weitere Storys zu anderen Oldtimern. Jetzt Vetero besuchen."} />
                        </Helmet>
                    }

                    <div className="story-header">

                    <Responsive {...Responsive.onlyMobile} className="user-label">
                            <RouterLink to={`/user/${projectItem.userData.urlslug}/${projectItem.user}`}>
                                <div className="user-image-wrapper">
                                    <ImageWrapper avatar circular path={projectItem.userData.mediaavatarPath}>
                                        <ProgressiveImager placeholder="avatar" circular src={config.getImageUrl('avatar', projectItem.userData.mediaavatarPath)} alt={projectItem.userData.name} />
                                    </ImageWrapper>
                                </div>
                                <span className="user-name">
                                    {projectItem.userData.name}
                                </span>
                            </RouterLink>
                        </Responsive>

                        <Responsive as={Container} minWidth={Responsive.onlyTablet.minWidth} style={{position: "relative"}}>
                            <div className="user-label">
                                <RouterLink to={`/user/${projectItem.userData.urlslug}/${projectItem.user}`}>
                                    <div className="user-image-wrapper">
                                        <ImageWrapper avatar circular path={projectItem.userData.mediaavatarPath}>
                                            <ProgressiveImager placeholder="avatar" circular src={config.getImageUrl('avatar', projectItem.userData.mediaavatarPath)} alt={projectItem.userData.name} />
                                        </ImageWrapper>
                                    </div>
                                    <span className="user-name">
                                        {projectItem.userData.name}
                                    </span>
                                </RouterLink>
                            </div>
                        </Responsive>

                        <Responsive {...Responsive.onlyMobile}>
                            <ProgressiveImager backgroundImage className="image" src={config.getImageUrl('main', item.mediamainPath)}/>
                        </Responsive>

                        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                            <ProgressiveImager backgroundImage className="image" src={config.getImageUrl('mainDesktop', item.mediamainPath)}/>
                        </Responsive>

                        <div className="project-details">
                            <Container>
                                <Grid>
                                    <Grid.Row>

                                        <Grid.Column textAlign="center" width={4}>
                                            <div className="detail">
                                                <Image src={clockIcon} alt="Icon Clock" />
                                                <span className="detail-sum">{item.sumWorkinghours ? config.getFormattedInt(item.sumWorkinghours)+'h' : '–'}</span>
                                            </div>
                                        </Grid.Column>

                                        <Grid.Column  textAlign="center" width={4}>
                                            <div className="detail">
                                                <Image src={eurIcon} alt="Icon Euro" />
                                                <span className="detail-sum">{item.sumPrice ? config.getFormattedInt(item.sumPrice) + '€' : '–'}</span>
                                            </div>
                                        </Grid.Column>

                                        <Grid.Column textAlign="center" width={4}>
                                            <div className="detail detail-like" onClick={() => accountItem && accountItem.id ? this.handleLikeStory(item.id, !item.userWelldone) : this.setState({loginPopup: true})}>
                                                <Image alt="Icon Like"  src={item.userWelldone ? heartIconActive : heartIconDark} />
                                                <span className="detail-sum">
                                                    {item.countWelldone ?
                                                        <NumericLabel style={{ display: "inline-block" }} params={config.PRETTY_CONFIG}>{item.countWelldone}</NumericLabel>
                                                        :
                                                        '0'
                                                    }
                                                </span>
                                            </div>
                                        </Grid.Column>

                                        <Grid.Column textAlign="center" width={4}>
                                            <div className="detail detail-comment" onClick={() => this.handleSlidingPanes(true, 'Kommentare', 'comments')}>
                                                <Image src={commentIcon} alt="Icon Kommentieren" title="Kommentieren"/>
                                                <span className="detail-sum">
                                                    {item.countComment ?
                                                        <NumericLabel style={{display: "inline-block"}}  params={config.PRETTY_CONFIG}>{item.countComment}</NumericLabel>
                                                        :
                                                        '0'
                                                    }
                                                </span>
                                            </div>
                                        </Grid.Column>

                                    </Grid.Row>
                                </Grid>
                            </Container>
                        </div>
                    </div>

                    <Container className="story-element text-element pt-3 sm-pt-3">
                        {item.title ? <Header textAlign="center" className="story-main-header" style={{textTransform: 'unset'}} as="h1">{projectItem.title && <><RouterLink to={`/oldtimer/${projectItem.urlslug}/${projectItem.id}`}>{projectItem.title}</RouterLink> – </>}{item.title}</Header> : '' }
                        {item.description ?
                            <span className="storys-description">
                                {nl2br(item.description)}
                            </span> : ''
                        }
                    </Container>


                    {!isFetching && item && !item.description || (contentList.length === 0) && 
                        <Container>
                            <Message color="teal">
                                Diese Story enthält zur Zeit keine Inhalte.
                            </Message>
                        </Container>
                    }

                    {contentList.map((content, index) =>
                    <>
                        <FeContentElement content={content} key={index} contentItem={index} altbase={projectItem.title+' – '+item.title+' – '} />
                    </>
                    )}

                    <Account loginPopupVisible={this.state.loginPopup} loginPopupClose={() => this.setState({loginPopup: false})}/>


                    <Divider hidden/>
                    {/* If project has stories... */}


                {/*Include Comments*/}

                <Responsive
                    as={CustomSlidingPane}
                    maxWidth={Responsive.onlyTablet.maxWidth}
                    isOpen={this.state.slidingPane.isOpen}
                    title={this.state.slidingPane.title}
                    onRequestClose={() => this.setState({slidingPane: { isOpen: false }})}
                >
                    {this.handleSlidingPanesContent()}
                </Responsive>

                <Responsive
                    as={CustomSlidingPane}
                    minWidth={Responsive.onlyComputer.minWidth}
                    isOpen={this.state.slidingPane.isOpen}
                    title={this.state.slidingPane.title}
                    from='right'
                    onRequestClose={() => this.setState({slidingPane: { isOpen: false }})}
                >
                    {this.handleSlidingPanesContent()}
                </Responsive>

            </>
        }
        {isFailed && ((!item || !item.id) || !projectItem) &&
            <Container text>
                <ErrorHandler callbackFunction={this.reloadStory} />
            </Container>
        }
    </>
    )
    }
}

function mapStateToProps(state, ownProps) {
    const { globalByComponent, storyById, projectById, account } = state
    const { item: accountItem } = account || {
        accountItem: {}
    }
    const { item, contents, sumMedias, isFetching, isLiking, isFailed } = storyById[
        ownProps.match.params.storyid
    ] || {
        item: {},
        contents: {},
        sumMedias: {},
        isFetching: true,
        isFailed: false
    }

    const { item: projectItem, isFetching: projectIsFetching, brand, vehicle, td } = projectById[
        ((item && item.project) ? item.project : null)
    ] || {
        projectItem: {},
        projectIsFetching: true,
    }

    const { galleryOpen } = globalByComponent[
        'Gallery'
    ] || {
        galleryOpen: false
    }

    let otherStories = [];
    if (projectItem) {
        Object.values(projectItem.projectpartsData).filter(stories => stories.id !== ownProps.match.params.storyid && stories.active === true).map((item, index) => (
            otherStories.push(item)
        ))
        otherStories.sort((a, b) => a.position > b.position ? 1 : -1)
    }

    let contentList = [];

    if (contents) {
        for (let [key, value] of Object.entries(contents)) {
            if (value.blockprefix === 'contentgallery') {
                if (!value.media) {
                    value.media = [];
                }
                value.media.sort((a, b) => {
                    if (a.position > b.position) {
                        return 1;
                    }
                    else if (a.position < b.position) {
                        return -1;
                    }
                    else {
                        return (a.id > b.id ? 1 : -1);
                    }
                });
            }
            contentList.push(value);
        }
    }
    contentList.sort((a, b) => a.position > b.position ? 1 : -1);

    return {
        accountItem,
        projectItem,
        projectIsFetching,
        galleryOpen,
        item,
        contentList,
        sumMedias,
        isFetching,
        isFailed,
        otherStories,
        isLiking,
        brand,
        vehicle,
        td,
    }

}

export default connect(mapStateToProps)(FeStory)
