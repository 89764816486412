import React from 'react'
import { connect } from 'react-redux';
import { Container, Divider, Header, Grid, Loader, Image, Button, Icon } from 'semantic-ui-react';
import { setRuntime, handleContainer, showFooter, editHeader } from '../../../../actions/global';
import { fetchProfessional } from '../../../../actions/professional';
import BeParent from '../../../project/backend/be-parent';
import '../../dashboard.scss';
import config from '../../../../config/config';
import Moment from 'react-moment';
import { hideFooter } from '../../../../actions/global';
import HeaderImage from './../../../../components/HeaderImage/HeaderImage';
import Truncate from 'react-truncate';
import { Link } from 'react-router-dom';

const inputFields = [
    "name", "description", "street", "zip", "city", "countrycode", "owner", "website", "phone", "headline", "intro", "scopes", "types", "categories", "media", "mediamain", "medialogo", "mediavideo"
]

class Detail extends BeParent {

    state = {
        tagModelIsOpen: false,
        currentStep: "contact",
        validation: {},
        validationMsg: "",
        isUploading: false,
        hideSaveModal: null,
        redirectToMainPage: false,
        confirmModal: {
            isOpen: false
        }
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);
        const { dispatch, history, inputProfessional, match } = this.props;

        dispatch(setRuntime({
            page: 'fe-professional-new',
        }));
        dispatch(fetchProfessional(match.params.id))
        dispatch(hideFooter());
        dispatch(handleContainer("secondary", 'Spezialisten verwalten', false, "professional"));
        //Set edit hedaer

    }

    componentWillUnmount = () => {
        const { dispatch } = this.props;
        dispatch(showFooter());
    }


    render() {

        const { professional, isFetching } = this.props;

        return (
            <>
            {isFetching && 
                <>
                    <Loader active/>
                </>
            }

            {!isFetching && professional && 
                <Container className='mt-3'>
                    <div className="bg-grey-light px-2 py-2">
                        <div className="professional-admin">
                            <Grid>
                                <Grid.Column mobile={8} tablet={10} computer={12}>
                                    <div className="professional-title">
                                        <h1>{professional.name}</h1>
                                    </div>
                                    <div className="professional-description mb-1">
                                        <Truncate lines={2}>{professional.intro}</Truncate>
                                    </div>
                                    <div className="professional-status">
                                        <strong>Status: </strong>
                                        <span>{professional.active ? "Veröffentlicht" : "Geparkt"}</span>
                                    </div>
                                    <div className="professional-payment">
                                        <strong>Anzeige bezahlt:</strong> 
                                        <span>Ja / Nein / Kostenlose Probezeit?</span>
                                    </div>
                                    <div className="professional-date">
                                        <strong>Anzeige angelegt: </strong>
                                        <span><Moment format="DD.MM.YY" locale="de" unix>{professional.createDate}</Moment></span>
                                    </div>
                                    <div className="professional-preview mt-2">
                                        <Button icon="hyperlink" to={`/professionals/${professional.urlslug}/${professional.id}/preview`} as={Link} color="green" target="_blank">
                                            Vorschau
                                            <Icon className="pl-1" name="external alternate"></Icon>
                                        </Button>
                                    </div>
                                </Grid.Column>
                                <Grid.Column mobile={8} tablet={6} computer={4}>
                                    <div className="professional-logo">
                                        {professional.medialogoPath && 
                                                <div className="item-logo">
                                                    <Image loading="lazy" src={config.getImageUrl('halfwidth', professional.medialogoPath)} alt={`Logo ${professional.name}`} />
                                                </div>
                                        }
                                    </div>
                                </Grid.Column>
                                <Grid.Column width={16}>
                                    <Divider/>
                                    <h2>Benutzer- und Firmeninformationen</h2>
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    <div className="professional-user">
                                        <div className="professional-username">
                                            <strong>Benutzername:</strong> 
                                            <span>{professional.user} //Todo: User-Objekt fehlt!</span>
                                        </div>
                                        <div className="professional-owner">
                                            <strong>Besitzer: </strong> 
                                            <span>{professional.owner}</span>
                                        </div>
                                        <div className="professional-mail">
                                            <strong>E-Mail: </strong> 
                                            <a href={`mailto:${professional.email}`}>{professional.email}</a>
                                        </div>
                                        {professional.phone &&
                                            <div className="professional-phone">
                                                <strong>Telefon: </strong> 
                                                <span>{professional.phone}</span>
                                            </div>
                                        }
                                        {professional.website && 
                                            <div className="professional-zip">
                                                <strong>PLZ: </strong> 
                                                <span>{professional.website}</span>
                                            </div>
                                        }
                                    </div>
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    <div className="professional-street">
                                        <strong>Straße: </strong> 
                                        <span>{professional.street}</span>
                                    </div>
                                    <div className="professional-city">
                                        <strong>Ort: </strong> 
                                        <span>{professional.city}</span>
                                    </div>
                                    <div className="professional-zip">
                                        <strong>PLZ: </strong> 
                                        <span>{professional.zip}</span>
                                    </div>
                                </Grid.Column>
                                <Grid.Column width={16}>
                                    <Divider/>
                                    <Grid columns="equal" textAlign='center'>
                                        <Grid.Column>
                                            <Button fluid color="red">Nachricht schicken</Button>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Button fluid color="red">Anzeige parken</Button>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Button fluid color="red">Anzeige löschen</Button>
                                        </Grid.Column>
                                    </Grid>
                                </Grid.Column>
                            </Grid>
                        </div>
                    </div>
                </Container>
            }

                
            </>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { professionalsById, account } = state;

    const { isFetching, isFailed, item: professional, isDeleted, isUploading } = professionalsById[ownProps.match.params.id] || {
        professional: {},
        isFetching: true,
        isFailed: false,
    }

    const { item } = account || {}

    return {
        isFetching, 
        isFailed,
        isUploading,
        professional,
        isFetching,
        item
    };
}

export default connect(mapStateToProps)(Detail);
