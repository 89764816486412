import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
    Link
} from "react-router-dom";
import {
    Responsive
} from 'semantic-ui-react';
import ProgressiveImager from '../../../components/helpers/progressive-imager';
import config from '../../../config/config';
//User placeholder image
import userAvatar from '../../../images/icon-avatar.svg';


/**
* Listitem for projects
*/
class ListitemProject extends Component {

    render() {
        const { projectId, projectTitle, projectMainImage, userImg, projectSlug, linktype } = this.props;

        let projectLink = linktype === "stories" ? `/oldtimer/${projectSlug}/${projectId}/tab/stories` : linktype === "vehicle" ? `/oldtimer/${projectSlug}/${projectId}/tab/vehicle` : `/oldtimer/${projectSlug}/${projectId}/tab/interview`;


        return (
            <div className="project">
                <Link to={projectLink}>
                    <div className="own-project-wrapper">
                        <Responsive maxWidth={Responsive.onlyMobile.maxWidth} className="project-image-wrapper">
                            <ProgressiveImager className="project-img" src={config.getImageUrl('preview', projectMainImage)} circular bordered />
                            <div className="own-project-wrapper-user">
                                <ProgressiveImager placeholder="avatar" className="user-img" src={userImg ? config.getImageUrl('avatar', userImg) : userAvatar} circular/>
                            </div>
                        </Responsive>
                        <Responsive minWidth={Responsive.onlyTablet.minWidth} className="project-image-wrapper">
                            <ProgressiveImager className="project-img" src={config.getImageUrl('preview', projectMainImage)} />
                            <div className="own-project-wrapper-user">
                                <ProgressiveImager placeholder="avatar" className="user-img" src={userImg ? config.getImageUrl('avatar', userImg) : userAvatar} circular/>
                            </div>
                        </Responsive>
                    </div>
                    <span className="project-title">{projectTitle}</span>
                    {/* <div className="update" style={{marginTop: "15px"}}>
                        {update &&
                            <Label color={update === 'Neue Story' ? 'orange' : 'olive'}>{update}</Label>
                        }
                    </div> */}
                </Link>
            </div>
        )
    }
}

ListitemProject.propTypes = {
    project: PropTypes.object,
    update: PropTypes.string,
}

export default ListitemProject;
