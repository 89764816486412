import React, { Component } from 'react'
import { Placeholder, Grid, Container, Image } from 'semantic-ui-react';

class DetailPagePlaceholder extends Component {
    render() {
        return (
            <div className="fetching-story">
                <div className="story-header">
                    <div className="image">
                        <Placeholder>
                            <Placeholder.Image/>
                        </Placeholder>
                    </div>
                            
                    </div>
                        <Container>
                            <Placeholder className="story-content-placeholder">
                                <Placeholder.Line length="very long"/>
                                <Placeholder.Line length="very long"/>
                                <Placeholder.Line length="medium"/>
                                <Placeholder.Line length="short"/>
                                <Placeholder.Image/>
                            </Placeholder>
                        </Container>
                    </div>
        )
    }
}

export default DetailPagePlaceholder
