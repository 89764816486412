import {
    API_FEEDPOST_REQUEST,
    API_FEEDPOST_SUCCESS,
    API_FEEDPOST_FAILURE,
    API_POST_FEEDPOST_SUCCESS,
    CHANGE_FEEDPOST,
    API_UPDATE_FEEDPOST_REQUEST,
    API_UPDATE_FEEDPOST_SUCCESS,
    API_UPDATE_FEEDPOST_FAILURE,
    API_LIKE_FEED_REQUEST,
    API_LIKE_FEED_SUCCESS
} from '../actions/notification'
import {
    RESET_STORE,
} from '../actions/account'
import {actions} from 'ix-redux-file-upload';


export default function feedpostById(state = {}, action) {

    try {

        if (action.type === RESET_STORE) {
            return {};
        }

        if (
            action.type === CHANGE_FEEDPOST ||
            action.type === API_UPDATE_FEEDPOST_REQUEST ||
            action.type === API_UPDATE_FEEDPOST_SUCCESS ||
            action.type === API_UPDATE_FEEDPOST_FAILURE
        ) {
            var changesItem = {};
            var changes = {};

            if (action.type === CHANGE_FEEDPOST) {
                changesItem = {
                    inputText: action.payload.inputText,
                    inputMedias: action.payload.inputMedias,
                };
            }

            else if (action.type === API_UPDATE_FEEDPOST_REQUEST) {
                changes = {
                    isUpdating: true,
                    updatingMessage: '',
                };
            }

            else if (action.type === API_UPDATE_FEEDPOST_SUCCESS) {
                changesItem = {
                    ...action.payload.feedpost,
                };
                changes = {
                    isUpdating: false,
                    inputText: action.payload.feedpost.text,
                    inputMedias: (action.payload.feedpost.medias ? JSON.parse(JSON.stringify(action.payload.feedpost.medias)) : null),
                    updatingMessage: 'SUCCESS',
                };
            }

            else if (action.type === API_UPDATE_FEEDPOST_FAILURE) {
                changes = {
                    isUpdating: false,
                    updatingMessage: 'FAILURE',
                };
            }

            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    item: {
                        ...state[action.meta.id].item,
                        ...(changesItem ? changesItem : {}),
                    },
                    ...changes,
                }
            };
        }

        else if(action.type === API_LIKE_FEED_REQUEST){

            if(state[action.meta.id]) {
                return {
                    ...state,
                    [action.meta.id]: {
                        ...state[action.meta.id],
                        item: {
                            ...state[action.meta.id].item,
                            userLike: action.meta.like,
                            isLiking: true,
                        }
                    }
                }
            }
            else {
                return state
            }
        }
        else if(action.type === API_LIKE_FEED_SUCCESS){
            var countLike = action.meta.count;
            if(state[action.meta.id]) {
                if (action.meta.like === action.payload) {
                    countLike = action.payload ? parseInt(countLike) +1 : parseInt(countLike) - 1
                }

                return {
                    ...state,
                    [action.meta.id]: {
                        ...state[action.meta.id],
                        item: {
                            ...state[action.meta.id].item,
                            countLike,
                            userLike: action.payload,
                            isLiking: false,
                        }
                    }
                }
            }

            return state;

        }

        if (action.type === API_FEEDPOST_REQUEST) {
            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    isFetching: true,
                    lastFetch: Date.now(),
                }
            };
        }
        else if (
            action.type === API_FEEDPOST_SUCCESS ||
            action.type === API_POST_FEEDPOST_SUCCESS
        ) {
            return {
                ...state,
                [action.payload.feedpost.id]: {
                    ...state[action.payload.feedpost.id],
                    isFetching: false,
                    isFailed: false,
                    isDeleted: false,
                    item: {
                        ...action.payload.feedpost,
                        inputText: action.payload.feedpost.text,
                        inputMedias: (action.payload.feedpost.medias ? JSON.parse(JSON.stringify(action.payload.feedpost.medias)) : null),
                    },
                    lastUpdated: Date.now(),
                }
            };
        }
        else if (action.type === API_FEEDPOST_FAILURE) {
            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    isFetching: false,
                    isFailed: true,
                    lastUpdated: Date.now(),
                }
            };
        }

        else if ((action.type === actions.FILE_UPLOAD_ADD_UPLOADING_IMAGES || action.type === actions.FILE_UPLOAD_ADD_UPLOADING_DOCUMENTS)) {
            var identificator = action.meta.identificator.split('.');

            if (identificator[0] === 'feedpost') {
                var key = identificator[1];
                var media = state[key].item[identificator[2]];

                if (!media) {
                    media = [];
                }

                for (var i = 0; i < action.meta.amount; ++i) {
                    media.push({
                        id: null,
                        path: 'LOADING',
                        type: null,
                        description: '',
                    });
                }

                state = {
                    ...state,
                    [key]: {
                        ...state[key],
                        item: {
                            ...state[key].item,
                            [identificator[2]]: media,
                        }
                    }
                }

                return state;
            }
        }
        else if (action.type === actions.FILE_UPLOAD_COMPLETE) {
            identificator = action.payload.identificator.split('.');

            if (identificator[0] === 'feedpost') {
                var key = identificator[1];
                var media = state[key].item[identificator[2]];

                if (!media) {
                    media = [];
                }

                media.push({
                    id: action.payload.response.id,
                    path: action.payload.response.path,
                    type: action.payload.response.type,
                    description: action.payload.file.name,
                });

                var removedLoading = false;
                media.map((mv, mk) => {
                    if (mv.path === 'LOADING' && !removedLoading) {
                        media.splice(mk, 1);
                        removedLoading = true;
                    }
                });

                state = {
                    ...state,
                    [key]: {
                        ...state[key],
                        item: {
                            ...state[key].item,
                            [identificator[2]]: media,
                        }
                    }
                }

                return state;
            }
        }

        return state;

    } catch (e) {

        console.log(e);
        return {
            ...state,
            fatalError: true,
        };

    }
}
