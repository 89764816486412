import React from 'react';
import InView from 'react-intersection-observer';

interface Props {
  onLoadMore?: () => void;
  loading?: boolean;
  hasNextPage?: boolean;
  offset?: number;
}

export const InfiniteScroll: React.FC<Props> = ({
  onLoadMore,
  loading,
  hasNextPage,
  offset = 1000,
}) => {
  const handleInView = (isInView: boolean) => {
    if (isInView && hasNextPage && !loading && onLoadMore) {
      onLoadMore();
    }
  };

  if (loading) return null;

  return (
    <InView rootMargin={`${offset}px`} onChange={handleInView}>
      {({ ref }) => <div ref={ref} />}
    </InView>
  );
};
