import React, { Component } from 'react'
import { Image } from 'semantic-ui-react';
import './CustomArrows.scss';
import ArrowRight from '../../images/icons/arow-right.svg'

export default class NextArrow extends Component {

    render() {
        const { onClick } = this.props;
        return (
            <div className="custom-arrow next">
                <Image src={ArrowRight} onClick={onClick} alt="Slider Arrow Right"/>
            </div>
        )
    }
}
