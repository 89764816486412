import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
    Button, Container, Divider, Grid, Header, Loader } from "semantic-ui-react";
import {
    footerActiveItem,
    handleContainer,
    handleHeaderItems,
    setRuntime
} from "../../actions/global";
import Helmet from 'react-helmet';

//Images /
import HeaderImg from '../../images/funktionen-header.jpg';
import Arbeitszeit from '../../images/icons/about/icon-arbeitszeit.svg';
import Aufgaben from '../../images/icons/about/icon-aufgaben.svg';
import Ausgaben from '../../images/icons/about/icon-ausgaben.svg';
import ComingSoon from '../../images/icons/about/icon-coming-soon.svg';
import Einstellungen from '../../images/icons/about/icon-einstellungen.svg';
import Ersatzteile from '../../images/icons/about/icon-ersatzteile.svg';
import Gutscheine from '../../images/icons/about/icon-gutscheine.svg';
import Like from '../../images/icons/about/icon-heart.svg';
import Kommentare from '../../images/icons/about/icon-kommentare.svg';
import Montagehilfe from '../../images/icons/about/icon-montagehilfe.svg';
import Fahrzeug from '../../images/icons/about/icon-projekte.svg';
import Rechnungen from '../../images/icons/about/icon-rechnungen.svg';
import Storys from '../../images/icons/about/icon-storys.svg';
import VeteroApp from '../../images/icons/about/icon-vetero-app.svg';
import Account from "../../components/account/Account";
import HeaderImage from "../../components/HeaderImage/HeaderImage";
import IconBox from '../../components/IconBox/IconBox';
import FunctionsGifSection from './functionsGifSection';


const iconBoxItems = [
    {img: {src: Fahrzeug, alt: "Icon Fahrzeug"}, content: { header: "Fahrzeug mit Interview" } },
    {img: {src: Arbeitszeit, alt: "Icon Arbeitszeit"}, content: { header: "Aufwand/Arbeitszeit" } },
    {img: {src: Kommentare, alt: "Icon Kommentare"}, content: { header: "Kommentare / PN" } },
    {img: {src: Storys, alt: "Icon Storys"}, content: { header: "Storys" } },
    {img: {src: Aufgaben, alt: "Icon Aufgaben"}, content: { header: "Aufgaben" } },
    {img: {src: Like, alt: "Icon Like"}, content: { header: "„Gefällt mir“ Angaben / Follower" } },
    {img: {src: Ersatzteile, alt: "Icon Ersatzteile"}, content: { header: "Ersatzteile" } },
    {img: {src: Montagehilfe, alt: "Icon Montagehilfe"}, content: { header: "Montagehilfen" } },
    {img: {src: VeteroApp, alt: "Icon Mobile"}, content: { header: "Vetero als App" } },
    {img: {src: Ausgaben, alt: "Icon Ausgaben"}, content: { header: "Ausgaben" } },
    {img: {src: Einstellungen, alt: "Icon Einstellungen"}, content: { header: "Einstellungen" } },
    {img: {src: ComingSoon, alt: "Icon Clock"}, content: { header: "Coming soon" } },
    {img: {src: Rechnungen, alt: "Icon Rechnungen"}, content: { header: "Rechnungen" } },
    {img: {src: Gutscheine, alt: "Icon Gutscheine"}, content: { header: "Gutscheine" } },
]

const iconBoxItemsClosing = [
    {
        img: {src: Kommentare, alt: "Icon Kommentare"}, 
        content: { 
            header: "Kommentare / PN" ,
            description: "Andere Vetero Nutzer können Deine Storys und Projekte kommentieren oder Dir direkt Nachrichten schreiben. Hier findest Du alle Kommentare und persönliche Nachrichten. Du findest die Bereiche in Deinem Dashboard."
        } 
    },
    {
        img: {src: Like, alt: "Icon Gefällt mir"}, 
        content: { 
            header: "„Gefällt mir“ Angaben / Follower",
            description: "Wem gefallen Deine Projekte oder Storys und wer möchte an Deinen Projekten dran bleiben? Antworten findest Du in diesen Bereichen in Deinem Dashboard."
        } 
    },
    {
        img: {src: Fahrzeug, alt: "Icon Fahrzeug"}, 
        content: { 
            header: "Deine Fahrzeuge",
            description: "Du hast viele Fahrzeuge und auf dem Dashboard musst Du daher immer blättern? Kein Problem. Du findest alle Fahrzeuge als Liste im Menüpunkt „Fahrzeuge“."
        } 
    },
    {
        img: {src: VeteroApp, alt: "Icon Mobile"}, 
        content: { 
            header: "Vetero als APP",
            description: 
                <span>
                    Vetero kannst Du direkt als APP installieren. Wie eine PWA mit Chrome installiert wird, 
                    erfahrt ihr <Link to="/functions/vetero-app"><strong>hier</strong></Link>.
                </span>, 
        } 
    },
    {
        img: {src: ComingSoon, alt: "Icon Clock"}, 
        content: { 
            header: "Coming soon",
            description: "Vetero wird weiterentwickelt. Neben Verbesserungen an bestehenden Features sind auch komplett neue Dinge geplant.",
            //link: { text: "Was kommt noch alles", to: "about/coming-soon" }
        } 
    },
]


class Functions extends Component {
  state = {
    loginPopupVisible: false,
  };

  componentDidMount = () => {
    const { dispatch } = this.props;

    dispatch(footerActiveItem('footermenu'))
    dispatch(
      handleContainer("newsfeed", "Funktionen", null, null, null, null, {
        description: "Erfahre hier mit welchen Funktionen Vetero nicht nur Oldtimer Schrauber unterstützt. Von der Präsentation deiner Oldtimer, den Austausch mit anderen Oldtimer Begeisterten bis zur Auswertung der Arbeitszeit und der Kosten."
      })
    );
    dispatch(handleHeaderItems("functions"));
    // Set page
    dispatch(setRuntime({
        page: 'functions'
    }));
  };

  componentWillUnmount = () => {
    const { dispatch } = this.props;

    dispatch(setRuntime({
        backFunctions: {page: 'functions', path: this.props.location, scroll: window.pageYOffset},
    }));
}

  render() {
    const { item } = this.props;

    return (
      <div className="functions-page pt-3 sm-pt-3">

        <Helmet>
          <title>Vetero - Alle Funktionen des Oldtimer Portals</title>
        </Helmet>

          {/* <HeaderImage src={HeaderImg} overlay>
            <Grid>
                <Grid.Column width={16} textAlign="center">
                    <Header as="h2">Vetero Funktionen</Header>
                    <p>Was Du mit Vetero alles machen kannst</p>
                </Grid.Column>
            </Grid>
          </HeaderImage> */}

          <Container>
            <Header as="h1">Vetero Funktionen</Header>
            {/* <Header as="h2">Über Vetero</Header> */}

            <Divider />

            <div className="introduction-content">
                <p>
                Vetero unterstützt Dich bei Deinen Schraubertätigkeit an
                Oldtimern. Es ist die Plattform für alle Oldtimer Fans. Aber auch
                wenn Du nur wenig selbst machst und hauptsächlich fährst, bist Du
                mit Deinem Oldtimer bei Vetero gut aufgehoben.
                </p>

                <p>
                Mit Vetero hast Du alle Arbeiten, Kosten, Ersatzteile,
                Arbeitszeiten und noch einiges mehr im Griff. Du kannst Dich mit
                anderen austauschen oder Vetero nur für Dich allein nutzen. Den
                Projektfortschritt kannst Du bis ins kleinste Detail in Storys
                dokumentieren – auch wenn es etwas mal wieder länger dauert;-)
                </p>

                <p>
                Und das Beste ist – <strong>Vetero ist kostenlos!</strong>
                </p>

                {item && item.id ? (
                <Button as={Link} color="red" to="/backend/new_project/firststep">
                    Starte jetzt kostenlos dein Projekt
                </Button>
                ) : (
                <Button
                    color="red"
                    onClick={() => this.setState({ loginPopupVisible: true })}
                >
                    Jetzt kostenlos anmelden
                </Button>
                )}
            </div>
          </Container>
        
          <div className="iconbox-wrapper pt-3 sm-pt-3">
            <div className="bg-grey-light py-3 sm-py-3">
              <Header as="h3" className="mb-3" textAlign="center">Alle Funktionen von Vetero</Header>
              <Container>
                <IconBox items={iconBoxItems}/>
              </Container>
            </div>
          </div>

          <FunctionsGifSection/>
        
        <div className="iconbox-wrapper closing-section">
            <Container>
                <IconBox items={iconBoxItemsClosing}/>
            </Container>
        </div>

        <Account
          loginPopupVisible={this.state.loginPopupVisible}
          loginPopupClose={() => this.setState({ loginPopupVisible: false })}
          accountStep="register"
        />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { account } = state;
  const { item } = account || {
    item: {},
  };

  return {
    item,
  };
}

export default connect(mapStateToProps)(withRouter(Functions));
