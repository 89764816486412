import React, { Component } from 'react';
import { Container, Grid, Header, Button, Divider, Message, Label, Icon } from 'semantic-ui-react';
import config from '../../config/config';

class ForumTags extends Component {

    render() {

        const { onRequestClose, onRequestSave, handleTag, handleDeleteTag, inputTags, messageText } = this.props;

        return (
            <div className="forum-tags">
                <Container>

                    <div className="forum-tags-action">
                        <Button basic color="red" onClick={onRequestClose}>
                            Änderungen verwerfen 
                        </Button>
                        <Button color="red" onClick={onRequestSave}>
                            Übernehmen
                        </Button>
                        <Divider/>
                    </div>

                    {messageText && 
                        <Message color="teal">
                            {messageText}
                        </Message>
                    }
                    
                    {inputTags && inputTags.length > 0 &&
                        <div className="choosed-tags" key={inputTags}>
                            <Header as="h2">Verwendete Tags</Header>
                            {inputTags.map((tag, index) => (
                                <Label as='a' key={index} onClick={() => handleDeleteTag(tag)}>
                                    {tag.label}
                                    <Icon name="delete"/>
                                </Label>
                            ))}
                        </div>
                    }

                    <Divider/>
                    
                    <div className="available-tags">
                        <Header as="h2">Verfügbare Tags</Header>
                  
                        {config.getCategoriesSub(false, true).map((category, index) => (
                            <>
                                <Header key={index} as="h3">{category.label}</Header>
                                {category.options && 
                                    <div className="tags">
                                         {category.options.map((tags) => (
                                            <Label
                                                as='a' 
                                                onClick={() => inputTags && inputTags.filter(item => item.value === tags.value).length !== 0 ? null : handleTag(tags)}
                                                className={inputTags && inputTags.filter(item => item.value === tags.value).length !== 0 && "disabled"}
                                            >
                                                {tags.label}
                                            </Label>
                                        ))}
                                    </div>
                                }
                            </>
                        ))}
                    </div>


                </Container>
            </div>
        )
    }
}

export default ForumTags;
