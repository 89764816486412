import React, { Component } from 'react'
import {
    Image,
} from 'semantic-ui-react';
import ProgressiveImage from 'react-progressive-image';
import ImageFadeIn from 'react-image-fade-in';

import config from '../../config/config';

import staticPlaceholder from '../../images/placeholder.svg';
import transparentPlaceholder from '../../images/placeholder-transp.svg';
import userAvatar from '../../images/icon-avatar.svg'


/**
* Replacement for Image component
*/
class ProgressiveImager extends Component {

    isCached(src) {
        var img = document.createElement("img");
        img.src = src;
        var complete = img.complete;
        img.src = "";
        return complete;
    }

    render() {
        const { src, alt, className, placeholder, circular, bordered, avatar, onClick, key, style, floated, size, backgroundImage, loadingClassName } = this.props;

        var ph = (placeholder === 'avatar' ? userAvatar : (placeholder === 'transparent' ? transparentPlaceholder : staticPlaceholder));

        if (!src) {
            if (backgroundImage) {
                return (
                    <div className={className} style={{backgroundImage: `url(${ph})`}}/>
                );
            }

            return (
                <Image circular={circular} bordered={bordered} avatar={avatar} onClick={onClick} key={key} style={style} floated={floated} size={size} className={className} src={ph} alt={alt} />
            );
        }

        if (this.isCached(config.getDirectSrc(src))) {
            if (backgroundImage) {
                return (
                    <div className={className} style={{backgroundImage: `url(${config.getDirectSrc(src)})`}}/>
                );
            }

            return (
                <Image circular={circular} bordered={bordered} avatar={avatar} onClick={onClick} key={key} style={style} floated={floated} size={size} className={className} src={config.getDirectSrc(src)} alt={alt} />
            );
        }

        return (
            <ProgressiveImage src={src} placeholder={ph}>
              {(src, loading) => (
                  <>
                    {backgroundImage && loading &&
                        <div className={className} style={{backgroundImage: `url(${src})`}}/>
                    }
                    {backgroundImage && !loading &&
                        <ImageFadeIn opacityTransition={0.2} loadAsBackgroundImage className={className} src={config.getDirectSrc(src)} />
                    }
                    {!backgroundImage && loading &&
                        <Image circular={circular} bordered={bordered} avatar={avatar} onClick={onClick} key={key} style={style} floated={floated} size={size} className={className + ' ' + loadingClassName} src={src} alt={alt} />
                    }
                    {!backgroundImage && !loading &&
                        <ImageFadeIn opacityTransition={0.2} onClick={onClick} key={key} style={style} className={"ui image "+className+(circular ? ' circular' : '')+(bordered ? ' bordered' : '')+(avatar ? ' avatar' : '')+(floated === 'left' ? ' left floated' : (floated === 'right' ? ' right floated' : ''))+(size ? ' '+size : '')} src={config.getDirectSrc(src)} alt={alt} />
                    }
                  </>
              )}
            </ProgressiveImage>
        );
    }
}

export default ProgressiveImager;
