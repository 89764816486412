import React, { Component } from 'react'
import { Container, Image, Grid, Button, Header, Responsive, Divider, Segment, Placeholder, Message } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { handleHeaderItems, footerActiveItem, handleContainer, setRuntime } from '../actions/global';
import {fetchUser, fetchUserIfNeeded, fetchUserFeedPosts} from '../actions/user';
import { fetchUserProjects, fetchProjectListIfNeeded } from '../actions/project'
import ListitemOwnProject from './project/list/listitem-own-project';
import Slider from "react-slick";
import Helmet from 'react-helmet'
import NextArrow from '../components/SlicksliderCustomArrows/NextArrow';
import PrevArrow from '../components/SlicksliderCustomArrows/PrevArrow';
import config from '../config/config'
import ProgressiveImager from '../components/helpers/progressive-imager';
import Moment from 'react-moment';
import 'moment/locale/de'
import 'moment-timezone';
import Account from '../components/account/Account'
import MessagesInputUser from './dashboard/Messages/MessagesInputUser';
import FeedCard from '../components/Card/FeedCard';

//User placeholder image
import userAvatar from '../images/icon-avatar.svg'

//Header placeholder image
import placeholderHeader from '../images/header-empty.jpg';
import Gallery from '../components/Gallery/Gallery';


const nl2br = require('react-nl2br');

//Slick slider settings...
const settings = {
    dots: true,
    arrows: true,
    infinite: false,
    lazdyload: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    nextArrow: <NextArrow/>,
    prevArrow: <PrevArrow/>,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                dots: true,
                arrows: false,
            }
        },
        {
            breakpoint: 1025,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                dots: true,
                arrows: false,
            }
        }
    ]
  };

class User extends Component {

    state = {
        msgPopup: false,
        livePostsLightbox: false,
        postId: null
    }

    componentDidMount = () => {
        const { dispatch, match, user } = this.props;
        dispatch(footerActiveItem(null))
        dispatch(handleHeaderItems("user"));
        dispatch(setRuntime({page: 'fe-user-id'+match.params.id}));
        dispatch(fetchUser(match.params.id));
        dispatch(fetchUserProjects(match.params.id));
        dispatch(fetchProjectListIfNeeded());
        dispatch(fetchUserIfNeeded(match.params.id));
        //dispatch(fetchUserFeedPosts(match.params.id, 3))
        window.scrollTo(0, 0);

        if (user && user.name) {
            dispatch(handleContainer("secondary", "Profil von "+user.name));
        }
        else {
            dispatch(handleContainer("secondary", "Profil"));
        }
    }

    componentDidUpdate(prevProps) {
        const { dispatch, user } = this.props;

        if (
            (!prevProps.user || !prevProps.user.name) &&
            user && user.name
        ) {
            dispatch(handleContainer("secondary", "Profil von "+user.name));
        }
    }

    handleGallery = (id, index) => {
        this.setState({
            postId: id,
            livePostsLightbox: index
        })
    }

    render() {
        const { user, isFetching, activeProjects, item, match, liveposts, livepostsIsFetching } = this.props;
        const { livePostsLightbox, postId } = this.state;

        if (!(user && user.id) && isFetching){
            return (
                <div>
                    <div className="user-data-container">

                        <div className="user-profile-header">
                            <div className="image">
                                <Placeholder fluid>
                                    <Placeholder.Image />
                                </Placeholder>
                            </div>
                        </div>

                        <Container className="user-info-section">

                            <Grid>

                                <Grid.Row>

                                    <Grid.Column mobile={16} tablet={8} computer={10}>
                                        <div className="user-image">

                                            <div className="user-image-placeholder">
                                                <Placeholder>
                                                    <Placeholder.Image />
                                                </Placeholder>
                                            </div>

                                        </div>
                                        <Responsive {...Responsive.onlyMobile}>
                                            <Divider />
                                        </Responsive>
                                    </Grid.Column>

                                    <Grid.Column mobile={8} tablet={4} computer={3} className="follow-wrapper">
                                        <Placeholder>
                                            <Placeholder.Line length='full'/>
                                        </Placeholder>
                                    </Grid.Column>

                                    <Grid.Column mobile={8} tablet={4} computer={3} className="send-msg">
                                        <Button fluid disabled color="red">Nachricht senden</Button>
                                    </Grid.Column>

                                    <Grid.Column width={16}>
                                        <Divider/>
                                    </Grid.Column>
                                </Grid.Row>



                                <Grid.Row className="user-personal-info">

                                    <Grid.Column mobile={8} tablet={4} computer={4}>
                                        <div className="user-info">
                                            <Header as="h2">Baujahr</Header>
                                            <span className="font-semi fontsize-medium-s">-</span>
                                        </div>
                                    </Grid.Column>

                                    <Grid.Column mobile={8} tablet={4} computer={4}>
                                        <div className="user-info">
                                            <Header as="h2">PLZ</Header>
                                            <span className="font-semi fontsize-medium-s">-</span>
                                        </div>
                                    </Grid.Column>

                                    <Grid.Column mobile={8} tablet={4} computer={4}>
                                        <div className="user-info">
                                            <Header as="h2">Land</Header>
                                            <span className="font-semi fontsize-medium-s">-</span>
                                        </div>
                                    </Grid.Column>

                                    <Grid.Column mobile={8} tablet={4} computer={4}>
                                        <div className="user-info">
                                            <Header as="h2">Dabei seit</Header>
                                            <span className="font-semi fontsize-medium-s">
                                                -
                                            </span>
                                        </div>
                                    </Grid.Column>

                                </Grid.Row>

                            </Grid>

                        </Container>


                        <Responsive {...Responsive.onlyMobile}>
                            <Container>
                                <Placeholder>
                                    <Placeholder.Image style={{height: "300px"}}/>
                                </Placeholder>
                            </Container>
                        </Responsive>

                    </div>

                    <Responsive minWidth={Responsive.onlyTablet.minWidth}>

                        <div className="slider-placeholder">

                            <Segment className="user-projects-wrapper">
                                <Container>
                                    <Placeholder className="user-projects-placeholder">
                                        <Placeholder.Line/>
                                    </Placeholder>
                                    <Divider hidden />

                                    <Grid columns="equal">

                                        <Grid.Column>
                                            <Placeholder className="slides-placeholder">
                                                <Placeholder.Image />
                                            </Placeholder>
                                        </Grid.Column>

                                        <Grid.Column>
                                            <Placeholder className="slides-placeholder">
                                                <Placeholder.Image />
                                            </Placeholder>
                                        </Grid.Column>

                                        <Grid.Column>
                                            <Placeholder className="slides-placeholder">
                                                <Placeholder.Image />
                                            </Placeholder>
                                        </Grid.Column>

                                    </Grid>

                                </Container>
                            </Segment>

                        </div>
                    </Responsive>
                </div>
            )
        }
        else if (user && user.id) {
            return (
                <div>
                    <Helmet>
                        <meta name="description" content={user.description === null || user.description === '' ? "Vetero Userprofil von "+user.name : user.description.substring(0, 150)} />
                    </Helmet>

                    <div className="user-data-container">

                        <div className="user-profile-header">
                            <div className="image">
                                {activeProjects && activeProjects[0] ?
                                    <ProgressiveImager src={config.getImageUrl('mainDesktop', activeProjects[0].item.mediamainPath)} alt={activeProjects[0].item.title}/>

                                    :

                                    <Image src={placeholderHeader} alt="Vetero Profil Platzhalter"/>
                                }
                            </div>
                        </div>

                        <Container className="user-info-section">

                            <Grid>

                                <Grid.Row>

                                    <Grid.Column mobile={16} tablet={8} computer={10}>
                                        <div className="user-image">

                                            <ProgressiveImager placeholder="avatar"
                                                src={
                                                user &&
                                                user.mediaavatarPath ? config.getImageUrl('avatar', user.mediaavatarPath) : userAvatar} 
                                                circular 
                                                alt={user.mediaavatarPath ? "Profilbild von " + user.name : "Vetero Profilbild Platzhalter"}
                                                />
                                        </div>
                                        <div className="user-name">{user.name}</div>
                                        <Responsive {...Responsive.onlyMobile}>
                                            <Divider />
                                        </Responsive>
                                    </Grid.Column>

                                    {/* Show if user is logged in */}
                                    {item && item.id ?
                                        <>

                                            {item.id == match.params.id ?
                                                <>
                                                <Grid.Column width={8}  className="follow-wrapper" textAlign="left" only="mobile">
                                                    <span className="followers">{user.countFollowers} Follower</span>
                                                {/* <span className="follow-user"><Image src={followIcon}/></span> */}
                                                </Grid.Column>

                                                <Grid.Column tablet={6} computer={6} className="follow-wrapper" textAlign="right" only="tablet computer">
                                                    <span className="followers">{user.countFollowers} Follower</span>
                                                {/* <span className="follow-user"><Image src={followIcon}/></span> */}
                                                </Grid.Column>
                                                </>

                                                :

                                                <>
                                                <Grid.Column width={8}  className="follow-wrapper" textAlign="left" only="mobile">
                                                    <span className="followers">{user.countFollowers} Follower</span>
                                                {/* <span className="follow-user"><Image src={followIcon}/></span> */}
                                                </Grid.Column>

                                                <Grid.Column tablet={4} computer={3} className="follow-wrapper" textAlign="right" only="tablet computer">
                                                    <span className="followers">{user.countFollowers} Follower</span>
                                                {/* <span className="follow-user"><Image src={followIcon}/></span> */}
                                                </Grid.Column>

                                                <Grid.Column mobile={8} tablet={4} computer={3} className="send-msg">
                                                    {!user.deleted &&
                                                        <MessagesInputUser userid={user.id} />
                                                    }
                                                </Grid.Column>
                                                </>
                                            }

                                        </>
                                        :
                                        <>
                                            <Grid.Column width={8}  className="follow-wrapper" textAlign="left" only="mobile">
                                                    <span className="followers">{user.countFollowers} Follower</span>
                                                {/* <span className="follow-user"><Image src={followIcon}/></span> */}
                                                </Grid.Column>

                                                <Grid.Column tablet={4} computer={3} className="follow-wrapper" textAlign="right" only="tablet computer">
                                                    <span className="followers">{user.countFollowers} Follower</span>
                                                {/* <span className="follow-user"><Image src={followIcon}/></span> */}
                                            </Grid.Column>
                                            <Grid.Column mobile={8} tablet={4} computer={3} className="send-msg">
                                                {!user.deleted &&
                                                    <Button fluid onClick={() => this.setState({loginPopupVisible: true})} color="red">Nachricht senden</Button>
                                                }
                                                {/* Login Popup include */}
                                            </Grid.Column>
                                            <Account
                                                loginPopupVisible={this.state.loginPopupVisible}
                                                loginPopupClose={()=> this.setState({loginPopupVisible: false})}
                                            />
                                        </>
                                    }

                                    <Grid.Column width={16}>
                                        <Divider/>
                                    </Grid.Column>
                                </Grid.Row>



                                <Grid.Row className="user-personal-info">

                                    <Grid.Column mobile={8} tablet={4} computer={4}>
                                        <div className="user-info">
                                            <Header as="h2">Baujahr</Header>
                                            <span className="font-semi fontsize-medium-s">{user.birthyear}</span>
                                        </div>
                                    </Grid.Column>

                                    <Grid.Column mobile={8} tablet={4} computer={4}>
                                        <div className="user-info">
                                            <Header as="h2">PLZ</Header>
                                            <span className="font-semi fontsize-medium-s">{user.zipcode}</span>
                                        </div>
                                    </Grid.Column>

                                    <Grid.Column mobile={8} tablet={4} computer={4}>
                                        <div className="user-info">
                                            <Header as="h2">Land</Header>
                                            <span className="font-semi fontsize-medium-s">{user.countrycode}</span>
                                        </div>
                                    </Grid.Column>

                                    <Grid.Column mobile={8} tablet={4} computer={4}>
                                        <div className="user-info">
                                            <Header as="h2">Dabei seit</Header>
                                            <span className="font-semi fontsize-medium-s">
                                                <Moment local unix format="DD.MM.YYYY" >{user.createDate}</Moment>
                                            </span>
                                        </div>
                                    </Grid.Column>

                                </Grid.Row>

                            </Grid>

                        </Container>


                    </div>

                    {user.description &&
                        <Container>
                            <Responsive {...Responsive.onlyMobile}>
                                <Divider/>
                            </Responsive>
                            <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                                <Divider hidden/>
                            </Responsive>
                            <div className="user-description-wrapper">
                                <Header as="h2">Über mich</Header>
                                <div className="user-description">{nl2br(user.description)}</div>
                            </div>
                            <Divider hidden/>
                        </Container>
                    }

                    {/*User Projects - Mobile*/}

                    <Responsive {...Responsive.onlyMobile}>

                        <Segment className="user-projects-wrapper">
                            {activeProjects.length ?
                                <div>
                                    <Header as="h2">{user.name}s Fahrzeuge</Header>
                                    <Divider hidden />
                                        <div className="user-projects">

                                            <Slider {...settings}>
                                                {activeProjects.map((item, index) => (
                                                    <div key={index}>
                                                        <ListitemOwnProject
                                                            projectId={item.item.id}
                                                            projectTitle={item.item.title}
                                                            projectMainImage={item.item.mediamainPath}
                                                            path='oldtimer'
                                                            active={item.item.active}
                                                            projectSlug={item.item.urlslug}
                                                            linktype={item.item.linktype}
                                                            // TODO: Likes count missing...
                                                        />
                                                    </div>
                                                ))}
                                            </Slider>

                                        </div>
                                </div>

                                :
                                <div>
                                    <Header as="h2">{user.name}s Fahrzeuge</Header>
                                    <span>{user.name} hat noch kein Fahrzeug oder noch keins veröffentlicht.</span>
                                </div>
                            }
                        </Segment>

                    </Responsive>

                    {/*User Projects - Desktop*/}

                    <Responsive minWidth={Responsive.onlyTablet.minWidth}>

                        <Segment className="user-projects-wrapper">
                            <Container>
                            {activeProjects.length ?
                            <div>
                                <Header as="h2">{user.name}s Fahrzeuge</Header>
                                <Divider hidden />
                                <div className="user-projects">

                                    <Slider {...settings}>
                                        {activeProjects.map((item, index) => (
                                            <div key={index}>
                                                <ListitemOwnProject
                                                    projectId={item.item.id}
                                                    projectTitle={item.item.title}
                                                    projectMainImage={item.item.mediamainPath}
                                                    linktype={item.item.linktype}
                                                    active={item.item.active}
                                                    projectSlug={item.item.urlslug}
                                                    path='oldtimer'
                                                    // TODO: Likes count missing...
                                                />
                                            </div>

                                        ))}
                                    </Slider>

                                </div>
                            </div>

                            :

                            <div>
                                <Header as="h2">{user.name}s Fahrzeuge</Header>
                                <Divider hidden />
                                <span>{user.name} hat noch kein Fahrzeug oder noch keins veröffentlicht.</span>
                            </div>

                            }
                            </Container>
                        </Segment>

                    </Responsive>

                        <Divider hidden/>

                        <Container>
                            <Header textAlign="center" className="mb-3" as="h2">Live-Posts</Header>
                            {user.posts && !user.posts.length &&
                                <span>{user.name} hat noch nichts gepostet.</span>
                            }
                                {user.posts &&
                                    user.posts.map((post, index) => (
                                    <Grid key={index} className="live-posts">
                                        {post.medias.length > 0 ?
                                            <Grid.Row key={index}>
                                                <Grid.Column mobile={16} tablet={4} computer={4}>
                                                    <div className="text-grey fontsize-medium-s">
                                                        <Moment locale="de" unix format="LLL" >{post.createDate}</Moment>
                                                    </div>
                                                </Grid.Column>
                                                <Grid.Column mobile={16} tablet={4} computer={4} className="live-posts-image">
                                                    {post.medias.map((img, index) =>
                                                    <div className="live-post-img" key={index}>
                                                        <ProgressiveImager 
                                                            src={config.getImageUrl('main', img.path)} 
                                                            onClick={() => this.handleGallery(post.id, index)}
                                                            alt={
                                                                (post.text
                                                                ? post.text.substring(
                                                                    0,
                                                                    50
                                                                    )
                                                                : "Newsfeed") +
                                                                " – Bild: " +
                                                                post.id
                                                            }
                                                            />
                                                        </div>
                                                    )}

                                                        <Responsive {...Responsive.onlyMobile}>
                                                            <Gallery
                                                                galleryOpen={this.state.postId === post.id}
                                                                slide={livePostsLightbox}
                                                                onClose={() => this.setState({ postId: false })}
                                                                images={post.medias.map(img =>
                                                                config.getImageUrl('zoom', img.path)
                                                                )}
                                                                onMovePrevRequest={() =>
                                                                    this.setState({
                                                                        livePostsLightbox: (livePostsLightbox + post.medias.length - 1) % post.medias.length,
                                                                    })
                                                                }
                                                                onMoveNextRequest={() =>
                                                                    this.setState({
                                                                        livePostsLightbox: (livePostsLightbox + 1) % post.medias.length,
                                                                    })
                                                                }
                                                            />
                                                        </Responsive>

                                                        <Responsive minWidth={Responsive.onlyTablet.minWidth}>

                                                            <Gallery
                                                                galleryOpen={this.state.postId === post.id}
                                                                slide={livePostsLightbox}
                                                                onClose={() => this.setState({ postId: false })}
                                                                images={post.medias.map(img =>
                                                                    config.getImageUrl('zoomDesktop', img.path)
                                                                )}
                                                                onMovePrevRequest={() =>
                                                                    this.setState({
                                                                        livePostsLightbox: (livePostsLightbox + post.medias.length - 1) % post.medias.length,
                                                                    })
                                                                }
                                                                onMoveNextRequest={() =>
                                                                    this.setState({
                                                                        livePostsLightbox: (livePostsLightbox + 1) % post.medias.length,
                                                                    })
                                                                }
                                                            />

                                                        </Responsive>

                                                </Grid.Column>
                                                <Grid.Column mobile={16} tablet={8} computer={8}>
                                                    <div className="fontsize-medium-s">
                                                        {post.text}
                                                    </div>
                                                </Grid.Column>
                                            </Grid.Row>
                                            :
                                            <Grid.Row>
                                                <Grid.Column mobile={16} tablet={4} computer={4}>
                                                    <div className="text-grey fontsize-medium-s">
                                                        <Moment locale="de" unix format="LLL" >{post.createDate}</Moment>
                                                    </div>
                                                </Grid.Column>
                                                <Grid.Column mobile={16} tablet={12} computer={12}>
                                                    <div className="fontsize-medium-s">
                                                    {post.text}
                                                    </div>
                                                </Grid.Column>
                                            </Grid.Row>
                                        }
                                        <Divider/>
                                    </Grid>
                                     ))}
                                     {/*liveposts && liveposts.map((post, index) => (
                                         <>
                                            <FeedCard 
                                                type="primary" 
                                                item={post.feedPostData}
                                                handleLightboxGallery={this.handleGallery}
                                            />
                                            {post.feedPostData.medias.length > 0 && 
                                            <>
                                                <Responsive {...Responsive.onlyMobile}>
                                                    <Gallery
                                                        galleryOpen={this.state.postId === post.feedPostData.id}
                                                        slide={livePostsLightbox}
                                                        onClose={() => this.setState({ postId: false })}
                                                        images={
                                                            post.feedPostData.medias.map(img =>
                                                                config.getImageUrl('zoom', img.path)
                                                        )}
                                                                    onMovePrevRequest={() =>
                                                                        this.setState({
                                                                            livePostsLightbox: (livePostsLightbox + post.feedPostData.medias.length - 1) % post.medias.length,
                                                                        })
                                                                    }
                                                                    onMoveNextRequest={() =>
                                                                        this.setState({
                                                                            livePostsLightbox: (livePostsLightbox + 1) % post.feedPostData.medias.length,
                                                                        })
                                                                    }
                                                                />
                                                        </Responsive>

                                                        <Responsive minWidth={Responsive.onlyTablet.minWidth}>

                                                                <Gallery
                                                                    galleryOpen={this.state.postId === post.feedPostData.id}
                                                                    slide={livePostsLightbox}
                                                                    onClose={() => this.setState({ postId: false })}
                                                                    images={post.feedPostData.medias.map(img =>
                                                                        config.getImageUrl('zoomDesktop', img.path)
                                                                    )}
                                                                    onMovePrevRequest={() =>
                                                                        this.setState({
                                                                            livePostsLightbox: (livePostsLightbox + post.feedPostData.medias.length - 1) % post.medias.length,
                                                                        })
                                                                    }
                                                                    onMoveNextRequest={() =>
                                                                        this.setState({
                                                                            livePostsLightbox: (livePostsLightbox + 1) % post.feedPostData.medias.length,
                                                                        })
                                                                    }
                                                                />

                                                        </Responsive>
                                                        </>
                                                }
                                        </>
                                           

                                    <Grid className="my-2">
                                        <Grid.Column textAlign="center" width={16}>
                                            <Button as={Link} to={`/user/${match.params.slug}/${match.params.id}/feeds`} color="red">
                                                Alle Live-Posts ansehen
                                            </Button>
                                        </Grid.Column>
                                            </Grid> */}
                        </Container>

                </div>
            )
        }
        else {
            return (
                <Container text>
                    <Divider hidden/>
                    <Message negative>Es ist ein Fehler aufgetreten.</Message>
                </Container>
            )
        }
    }
}

function mapStateToProps(state, ownProps){
    const { globalByComponent, userById, listByEntity, projectById, account, feedpostByUserId } = state
    const { user, isFetching, isFailed } = userById[ownProps.match.params.id]
    || {
        isFetching: true,
        user: {}
    }
    const { list, userProjectsIsFetching } = listByEntity[
        ownProps.match.params.id+'::userProjects'
    ] || {
        list: {},
        userProjectsIsFetching: true
    }
    const { item } = account || {
        item: {},
        isFetching: true
    }

    const { list: liveposts, isFetching: livepostsIsFetching } = feedpostByUserId[ownProps.match.params.id] || {
        liveposts: {},
        livepostsIsFetching: true
    }

    let listitem = [];
    let activeProjects = [];

    if (list) {
        for (let [key, value] of Object.entries(list)) {
            listitem.push(projectById[value]);
        }
    }

    //Show only active projects
    listitem.filter(listitem => listitem.item.active === true).map((item, index) =>
        activeProjects.push(item)
    )

    return {
        user,
        isFetching,
        isFailed,
        activeProjects,
        userProjectsIsFetching,
        item,
        liveposts,
        livepostsIsFetching
    }
}

export default connect(mapStateToProps)(User);
