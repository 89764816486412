import config from '../config/config'
import { createAction } from 'redux-api-middleware';

export const CHANGE_BILL = 'CHANGE_BILL'
export const NEW_BILL = 'NEW_BILL'
export const API_BILL_REQUEST = 'API_BILL_REQUEST'
export const API_BILL_SUCCESS = 'API_BILL_SUCCESS'
export const API_BILL_FAILURE = 'API_BILL_FAILURE'
export const API_BILL_DELETE_REQUEST = 'API_BILL_DELETE_REQUEST'
export const API_BILL_DELETE_SUCCESS = 'API_BILL_DELETE_SUCCESS'
export const API_BILL_DELETE_FAILURE = 'API_BILL_DELETE_FAILURE'
export const API_BILL_LIST_REQUEST = 'API_BILL_LIST_REQUEST'
export const API_BILL_LIST_SUCCESS = 'API_BILL_LIST_SUCCESS'
export const API_BILL_LIST_FAILURE = 'API_BILL_LIST_FAILURE'
export const API_POST_BILL_REQUEST = 'API_POST_BILL_REQUEST'
export const API_POST_BILL_SUCCESS = 'API_POST_BILL_SUCCESS'
export const API_POST_BILL_FAILURE = 'API_POST_BILL_FAILURE'


export function discardBill(id, project) {
    return (dispatch, getState) => {
        const billlist = getState().billsByParent['project::'+project];
        var item = Object.values(billlist.items).filter(item => item.id === id)[0];

        delete item['input'];
        item['input'] = JSON.parse(JSON.stringify(item));

        dispatch(changeBill(item));
    }
}

export function newBill(project, name, value) {
    return {
        type: NEW_BILL,
        meta: { project },
        payload: {
            [name]: value,
        }
    }
}

export function postBill(project, inputTitle, inputMercant, inputBilldate, inputMedia) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/bill`,
        method: 'POST',
        body: JSON.stringify({
            project,
            title: inputTitle,
            mercant: inputMercant,
            billdate: inputBilldate,
            media: inputMedia,
        }),
        types: [
            {type: API_POST_BILL_REQUEST, meta: {project}},
            {type: API_POST_BILL_SUCCESS, meta: {project}},
            {type: API_POST_BILL_FAILURE, meta: {project}},
        ]
    })
}

export function changeBill(item) {
    return {
        type: CHANGE_BILL,
        meta: {
            project: item.project,
        },
        payload: {
            item,
        }
    }
}

export function updateBill(item) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/bill/${item.id}`,
        method: 'PUT',
        body: JSON.stringify({
            bill: item,
        }),
        types: [
            {type: API_BILL_REQUEST, meta: { project: item.project }},
            {type: API_BILL_SUCCESS, meta: { project: item.project }},
            {type: API_BILL_FAILURE, meta: { project: item.project }},
        ]
    })
}

export function deleteBill(project, id) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/bill/${id}`,
        method: 'DELETE',
        types: [
            {type: API_BILL_DELETE_REQUEST, meta: { project, id }},
            {type: API_BILL_DELETE_SUCCESS, meta: { project, id }},
            {type: API_BILL_DELETE_FAILURE, meta: { project, id }},
        ]
    })
}

export function fetchBillList(project) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/bill?project=${project}`,
        method: 'GET',
        types: [
            {type: API_BILL_LIST_REQUEST, meta: { project }},
            {type: API_BILL_LIST_SUCCESS, meta: { project }},
            {type: API_BILL_LIST_FAILURE, meta: { project }},
        ]
    })
}

function shouldFetchBillList(state, project) {
    const billlist = state.billsByParent['project::'+project];
    if (
        !billlist ||
        ((billlist.isFetching || billlist.isFailed) && Date.now() - billlist.lastFetch > config.FETCH_TIMEOUT) ||
        (!billlist.isDirty && !billlist.isFetching && Date.now() - (billlist.lastUpdated ? billlist.lastUpdated : 0) > config.FETCH_AGAIN)
    ) {
        return true
    }
    return false
}

export default function fetchBillListIfNeeded(project) {
    return (dispatch, getState) => {

        if (shouldFetchBillList(getState(), project)) {
            return dispatch(fetchBillList(project))
        }
    }
}

export function fetchBillListIfDirty(project) {
    return (dispatch, getState) => {
        const billlist = getState().billsByParent['project::'+project];

        if (!billlist || billlist.isDirty) {
            return dispatch(fetchBillList(project));
        }
    }
}
