import React, { Component } from 'react';
import './Comments.scss';
import { Comment, Container, Image, Message, Icon, Loader, Button, Divider } from 'semantic-ui-react';
import { connect } from 'react-redux';
import CustomSlidingPane  from '../CustomSlidingPane/CustomSlidingPane';
import Account from '../account/Account';
import fetchCommentsIfNeeded, {fetchPreviousComments, fetchComments, fetchNextComments, changeComment, updateComment} from '../../actions/comments'
import ImageWrapper from '../../components/helpers/image-wrapper';
import config from '../../config/config'
import CommentsInput from '../Comments/CommentsInput';
import Moment from 'react-moment';
import 'moment/locale/de'
import 'moment-timezone';
import { Link as RouterLink } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';
import { Link, Element } from 'react-scroll'
import ProgressiveImager from '../../components/helpers/progressive-imager';
import Answers from './Answers'
import commentIcon from '../../images/icons/frontend/icon-comment.svg';


class CommentsMeta extends Component {
    render() {
        const {comment} = this.props;

        const calendarStrings = {
            lastDay : '[Gestern um] HH:mm',
            sameDay : '[Heute um] HH:mm',
            nextWeek: 'DD.MM.YYYY',
            lastDay: 'DD.MM.YYYY',
            lastWeek: 'DD.MM.YYYY',
            sameElse : 'DD.MM.YYYY',
        }

        var calendarStringsEdit = {
            lastDay : '[Gestern um] HH:mm',
            sameDay : '[Heute um] HH:mm',
            nextWeek: '[am] DD.MM.YYYY',
            lastDay: '[am] DD.MM.YYYY',
            lastWeek: '[am] DD.MM.YYYY',
            sameElse : '[am] DD.MM.YYYY',
        }

        // Same date
        var showedittime = true;
        var create = new Date(comment.createDate * 1000).setHours(0, 0, 0, 0);
        var edit = new Date(comment.editDate * 1000).setHours(0, 0, 0, 0);
        var days = (new Date(create)).setDate((new Date(create)).getDate() + 2);
        if(create === edit) {
            calendarStringsEdit = {
                lastDay : '[um] HH:mm',
                sameDay : '[um] HH:mm',
                nextWeek: '[um] HH:mm',
                lastDay: '[um] HH:mm',
                lastWeek: '[um] HH:mm',
                sameElse : '[um] HH:mm',
            }

            if((new Date()) > days){
                showedittime = false;
            }
        }

        return (
            <Comment.Metadata>
                <Moment local calendar={calendarStrings} unix>{comment.createDate}</Moment>
                {comment.editDate > 0 &&
                    <span style={{fontSize: '0.75em'}}>[Bearbeitet{showedittime && <> <Moment local calendar={calendarStringsEdit} unix>{comment.editDate}</Moment></>}]</span>
                }
            </Comment.Metadata>
        );
    }
}

export { CommentsMeta }

class CommentsText extends Component {

    state = {
        edit: false,
    }

    constructor(props) {
        super(props);

        this.handleInput = this.handleInput.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        const {comment} = this.props;

        if (prevProps.comment.isUpdating && !comment.isUpdating && comment.updatingMessage === 'SUCCESS') {
            this.setState({
                edit: false,
            });
        }
    }

    render() {
        const {edit} = this.state;
        const {dispatch, comment, userItem} = this.props;

        var isOwn = false;
        if (userItem && comment.user === userItem.id) {
            isOwn = true;
        }

        if (!edit) {
            return (
                <Comment.Text>
                    {comment.text}
                    {isOwn &&
                        <Icon className="iconedit" name="pencil" circular inverted color="grey" onClick={() => this.setState({edit: true})} />
                    }
                </Comment.Text>
            );
        }

        return (
            <Comment.Text>
                <TextareaAutosize
                    className="fullWidth"
                    minRows="1"
                    maxRows="10"
                    autoComplete="nope"
                    autoFocus
                    type="text"
                    value={(comment.inputText ? comment.inputText : comment.text)}
                    ref={(tag) => (this.textarea = tag)}
                    onChange={(e) => {
                        this.handleInput(e, comment.id)
                    }}
                    disabled={comment.isUpdating ? true : false}
                />
                {comment.updatingMessage === 'FAILURE' &&
                    <Message negative>Beim Übertragen der Daten ist ein Fehler aufgetreten.</Message>
                }
                <Button
                    color="red"
                    size="mini"
                    loading={comment.isUpdating ? true : false}
                    disabled={!comment.inputText || comment.text === comment.inputText ? true : false}
                    onClick={(e) => {
                        dispatch(updateComment(comment));
                    }}
                >
                    Speichern
                </Button>
            </Comment.Text>
        );
    }

    handleInput(e, id) {
        const { dispatch } = this.props;
        dispatch(changeComment(id, e.target.value));
    }
}

function mapStateToCommentsTextProps(state, ownProps){
    const { account } = state;

    const { item } = account;

    return {
        userItem: item,
    }
}

const CommentsTextConnected = connect(mapStateToCommentsTextProps)(CommentsText);
export { CommentsTextConnected }


class Comments extends Component {

    state = {
        animationClass: "hide",
        answerField: false,
        showAnswerMobile: false,
        showAnswerField: false,
        parentId: 0,
        commentId: null,
        loginPopup: false
    }

    answerField = React.createRef();

    constructor(props) {
        super(props)

        this.watchNewComments = this.watchNewComments.bind(this);
        this.scrollToWrapper = this.scrollToWrapper.bind(this);
    }

    componentDidMount = () => {
        const { dispatch, parent } = this.props;

        dispatch(fetchCommentsIfNeeded(parent)); // TODO: Only if element is "open"
        document.getElementById('root').addEventListener('click', this.handleClickOutside, false);
        // Watch for new comments
        const watcher = setInterval(this.watchNewComments, config.COMMENT_UPDATETIME);
        this.setState({'watcher': watcher});
        // Watch for scroll reference
        if (window.location.hash === '#comments') {
            const scroller = setInterval(this.scrollToWrapper, 100);
            this.setState({'scroller': scroller});
        }
    }

    componentWillUnmount = () => {
        document.getElementById('root').addEventListener('click', this.handleClickOutside, false);
        clearInterval(this.state.watcher);
        if (this.state && this.state.scroller) {
            clearInterval(this.state.scroller);
        }
    }

    handleClickOutside = () => {
        const { answerFieldFocus } = this.state;
        this.setState({answerFieldFocus: false})
    }

    handlePrevious(parent, id) {
        const { dispatch } = this.props;
        dispatch(fetchPreviousComments(parent, id));
    }

    watchNewComments() {

        const { account, dispatch, comments, isFetching, isFailed, parent } = this.props;

        if (!isFetching && !isFailed) {

            var watchthis = [];

            comments.map(function(comment) {

                if (!comment.load) {

                    if (comment.answers && comment.answers.length) {

                        for (var watchkey = comment.answers.length - 1; watchkey >= 0; --watchkey) {
                            // Ignore just posted own messages
                            // TODO: Optimize
                            if (!comment.answers[watchkey].load && (!account.item || comment.answers[watchkey].user !== account.item.id)) {
                                watchthis.push(comment.answers[watchkey].id);
                                break;
                            }
                        }
                    }
                    else {
                        watchthis.push(comment.id);
                    }
                }
            })

            var watchbase = [];
            for (var watchkey = comments.length - 1; watchkey >= 0; --watchkey) {
                // Ignore just posted own messages
                // TODO: Optimize
                if (!comments[watchkey].load && (!account.item || comments[watchkey].user !== account.item.id)) {
                    watchbase.push('comment::'+comments[watchkey].id);
                    break;
                }
            }

            if (watchthis.length || watchbase.length) {
                dispatch(fetchNextComments(parent, watchthis, watchbase));
            }
            if (!watchbase.length) {
                dispatch(fetchComments(parent));
            }
        }
    }

    scrollToWrapper() {
        const { parentref, isFetching } = this.props;

        if(parentref && parentref.current && !isFetching){
            parentref.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              })
            clearInterval(this.state.scroller);
        }
    }

    render() {
        const { comments, isFetching, lastUpdated, isFailed, parent, type } = this.props;
        const { showAnswerField } = this.state;

        const calendarStrings = {
            lastDay : '[Gestern um] LT',
            sameDay : '[Heute um] LT',
            sameElse : 'L'
        }
        {/* Fetching data */}
        if (!comments.length && isFetching && !lastUpdated) {
            return (
                <div className="comments-wrapper">
                    <Loader active inline="centered"/>
                </div>
            )
        }
        else if (comments.length) {
            const { account } = this.props;
            return (
            <>
                {/* Show textare field if user is logged in */}
                <Container>
                    <div className={type === "blog" ? "comments-wrapper blog-comments" : "comments-wrapper"}>
                        
                        {account.item && account.item.id ?
                            <CommentsInput type={type} parent={parent} />
                            :
                            <div className="not-logged" onClick={() => type === "blog" ?  this.setState({ loginPopup: true }) : null}>
                                Melde dich an um einen Kommentar zu verfassen.
                            </div>
                        }

                        {/* Account popup */}
                        <Account loginDashboard="none" loginPopupVisible={this.state.loginPopup} loginPopupClose={() => this.setState({loginPopup: false})}/>

                        {type === "blog" && <Divider/>}

                        {comments.map((comment, index) => (
                            <Comment.Group key={index}>

                                {comment.load &&
                                    <Comment>
                                        <span className="comment-show-all" onClick={() => {this.handlePrevious(comment.load.parent, comment.load.id)}}>Vorherige Kommentare anzeigen</span>
                                    </Comment>
                                }

                                {!comment.load &&
                                    <Comment>
                                        <div class="avatar">
                                            <RouterLink to={`/user/${comment.userData.urlslug}/${comment.user}`}>
                                                <ImageWrapper avatar={true} path={comment.userData.mediaavatarPath}>
                                                    <ProgressiveImager placeholder="avatar" src={config.getImageUrl('avatar', comment.userData.mediaavatarPath)}/>
                                                </ImageWrapper>
                                            </RouterLink>
                                        </div>
                                        <Comment.Content>
                                            <div className="comment-inner-wrapper">
                                                <Comment.Author key={comment.userData.name}>
                                                    <RouterLink to={`/user/${comment.userData.urlslug}/${comment.user}`}>
                                                        {comment.userData.name}
                                                    </RouterLink>
                                                </Comment.Author>
                                                <CommentsTextConnected comment={comment} />
                                            </div>

                                            <CommentsMeta comment={comment} />

                                            {account.item && account.item.id && 
                                                <>
                                                    {type === "blog" ?
                                                        <Link isDynamic offset={-250} smooth className="answer-btn" to={"input-answer-"+comment.id} onClick={() => this.setState({showAnswerField: true, parentId: comment.id})}>
                                                            Antworten
                                                        </Link>
                                                        :
                                                        <Comment.Action className="answer-btn" onClick={() => this.setState({showAnswerMobile: true, commentId: comment.id})}>
                                                            Antworten
                                                        </Comment.Action>
                                                    }
                                                </>
                                            }

                                        </Comment.Content>

                                        {/*If comment has answer...*/}

                                        {comment.answers &&
                                            <Comment.Group>
                                                {comment.answers.map((answer, answerindex) => (<>
                                                    {answer.load &&
                                                        <Comment key={index+'::'+answerindex}>
                                                            <span className="comment-answer-show-all" onClick={() => {this.handlePrevious(answer.load.parent, answer.load.id)}}>Alle antworten anzeigen</span>
                                                        </Comment>
                                                    }

                                                    {!answer.load &&
                                                        <Comment>
                                                            <div class="avatar">
                                                                <RouterLink to={`/user/${answer.userData.urlslug}/${answer.user}`}>
                                                                    <ImageWrapper avatar path={answer.userData.mediaavatarPath}>
                                                                        <ProgressiveImager placeholder="avatar" src={config.getImageUrl('avatar', answer.userData.mediaavatarPath)}/>
                                                                    </ImageWrapper>
                                                                </RouterLink>
                                                            </div>
                                                            <Comment.Content>
                                                                <div className="comment-inner-wrapper">
                                                                    <Comment.Author key={answer.userData.name}>
                                                                        <RouterLink to={`/user/${answer.userData.urlslug}/${answer.user}`}>
                                                                            {answer.userData.name}
                                                                        </RouterLink>
                                                                    </Comment.Author>
                                                                    <CommentsTextConnected comment={answer} />
                                                                </div>
                                                                <CommentsMeta comment={answer} />

                                                                {/*account.item && account.item.id &&
                                                                <>
                                                                    {type === "blog" ?
                                                                        <Comment.Action className="answer-btn" onClick={() => this.setState({ showAnswerField: true, parentId: comment.id })}>
                                                                            Antworten
                                                                        </Comment.Action>
                                                                        :
                                                                        <Comment.Action className="answer-btn" onClick={() => this.setState({showAnswerMobile: true, commentId: comment.id})}>
                                                                            Antworten
                                                                        </Comment.Action>
                                                                    }
                                                                </>
                                                                */}

                                                            </Comment.Content>
                                                        </Comment>}

                                                    </> ))}

                                                    {/* Comment Answer - secondary SlidingPane / Modal */}
                                                    {type !== "blog" && 
                                                        <CustomSlidingPane
                                                            title="Antworten"
                                                            className="answer-slidingpane"
                                                            isOpen={this.state.showAnswerMobile}
                                                            closeIcon={<Icon name="angle left" onClick={() => this.setState({showAnswerMobile: false})}/>}
                                                            overlayClassName="answer-slidingpane-overlay"
                                                            from="right"
                                                        >
                                                            <Container className="comments-wrapper">

                                                                {account.item && account.item.id &&
                                                                   
                                                                    <CommentsInput type={type} parent={'comment::'+this.state.commentId} />
                                                                 
                                                                }
                                                                {comments.filter(comment => comment.id === this.state.commentId).map((comment, index) => (
                                                                    <Answers comment={comment} index={index}/>
                                                                ))}
                                                            </Container>

                                                        </CustomSlidingPane>
                                                    }

                                                    {/* Answer for blog comments */}
                                                    {type === "blog" && 
                                                        <>
                                                            <Element name={"input-answer-"+comment.id}>
                                                                {account.item && account.item.id && showAnswerField && this.state.parentId === comment.id &&
                                                                    <CommentsInput type={type} parent={'comment::'+this.state.parentId} />
                                                                }
                                                            </Element>
                                                            {comments.filter(comment => comment.id === this.state.commentId).map((comment, index) => (
                                                                <Answers comment={comment} index={index}/>
                                                            ))}
                                                        </>
                                                    }

                                                </Comment.Group>
                                            }

                                        </Comment>
                                    }

                                </Comment.Group>

                            ))}

                        </div>
                    </Container>
            </>)
        }
        else if (!isFailed) {
            const { account } = this.props;
            return (
                <div className={type === "blog" ? "comments-wrapper blog-comments" : "comments-wrapper"}>
             
                        {account.item && account.item.id ?
                            <CommentsInput type={type} parent={parent} />
                            :
                            <div className="not-logged" onClick={() => type === "blog" ?  this.setState({ loginPopup: true }) : null}>
                                Melde dich an um einen Kommentar zu verfassen.
                            </div>
                        }

                        {/* Account popup */}
                        <Account loginDashboard="none" loginPopupVisible={this.state.loginPopup} loginPopupClose={() => this.setState({loginPopup: false})}/>

                        {/* Show if no comments...  */}
                        <Message icon className="no-comments-message">
                            <Icon size="massive" name='comments outline' />
                            <Message.Content>
                               <Message.Header>Noch keine Kommentare vorhanden.</Message.Header>
                            </Message.Content>
                        </Message>

                </div>
            )
        }
        else {
            return (
                <div style={{padding: '15px'}}>
                    <Message color="red">
                        Ein Verbindungsfehler ist aufgetreten.
                    </Message>
                </div>
            )
        }
    }

}

function mapStateToProps(state, ownProps){
    const { account, globalByComponent, commentsByParent } = state
    const { slidingPaneId, title, open } = globalByComponent[
        'SlidingPane'
    ] || {
        slidingPaneId: null,
        title: "",
        open: false
    }
    const { slidingPaneIdSec, titleSecondary, openSecondary } = globalByComponent[
        'CustomSlidingPane'
    ] || {
        slidingPaneIdSec: null,
        titleSecondary: "",
        openSecondary: false
    }
    const { elementId } = globalByComponent[
        'Project'
    ] || {
        elementId: null
    }
    const { showComments, showAnswers } = globalByComponent[
        'Comments'
    ] || {
        showComments: false,
        showAnswers: false
    }

    const { comments, isFetching, lastUpdated, isFailed } = commentsByParent[
        ownProps.parent
    ] || {
        comments: {},
        isFetching: true,
        isFailed: false,
        lastUpdated: null,
    }

    // Prepare comments
    var carr = [];
    if (comments) {
        for (var [kcomment, vcomment] of Object.entries(comments)) {
            var aarr = [];

            if (vcomment && vcomment.load) {
                vcomment['id'] = kcomment;
            }
            else if (commentsByParent['comment::'+vcomment.id] && commentsByParent['comment::'+vcomment.id].comments) {

                // Find answers
                for (var [kanswer, vanswer] of Object.entries(commentsByParent['comment::'+vcomment.id].comments)) {
                    if (vanswer && vanswer.load) {
                        vanswer['id'] = kanswer;
                    }
                    if (vanswer && vanswer.id) {
                        aarr.push(vanswer);
                    }
                }
                if (aarr.length) {
                    aarr = [].concat(aarr).sort((a, b) => a.id > b.id ? 1 : -1);
                }

            }
            if (vcomment && vcomment.id) {
                carr.push({
                    ...vcomment,
                    answers: aarr,
                });
            }
        }
        if (carr.length) {
            carr = [].concat(carr).sort((a, b) => a.id > b.id ? 1 : -1);
        }
    }

    return {
        account,
        comments: carr,
        isFetching,
        isFailed,
        lastUpdated,
    }
}

export default connect(mapStateToProps)(Comments);
