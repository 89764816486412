import config from '../config/config'
import { createAction } from 'redux-api-middleware';

export const CHANGE_EXPENDITURE = 'CHANGE_EXPENDITURE'
export const NEW_EXPENDITURE = 'NEW_EXPENDITURE'
export const API_EXPENDITURE_REQUEST = 'API_EXPENDITURE_REQUEST'
export const API_EXPENDITURE_SUCCESS = 'API_EXPENDITURE_SUCCESS'
export const API_EXPENDITURE_FAILURE = 'API_EXPENDITURE_FAILURE'
export const API_EXPENDITURE_DELETE_REQUEST = 'API_EXPENDITURE_DELETE_REQUEST'
export const API_EXPENDITURE_DELETE_SUCCESS = 'API_EXPENDITURE_DELETE_SUCCESS'
export const API_EXPENDITURE_DELETE_FAILURE = 'API_EXPENDITURE_DELETE_FAILURE'
export const API_EXPENDITURE_LIST_REQUEST = 'API_EXPENDITURE_LIST_REQUEST'
export const API_EXPENDITURE_LIST_SUCCESS = 'API_EXPENDITURE_LIST_SUCCESS'
export const API_EXPENDITURE_LIST_FAILURE = 'API_EXPENDITURE_LIST_FAILURE'
export const API_POST_EXPENDITURE_REQUEST = 'API_POST_EXPENDITURE_REQUEST'
export const API_POST_EXPENDITURE_SUCCESS = 'API_POST_EXPENDITURE_SUCCESS'
export const API_POST_EXPENDITURE_FAILURE = 'API_POST_EXPENDITURE_FAILURE'


export function discardExpenditure(id, project) {
    return (dispatch, getState) => {
        const expenditurelist = getState().expendituresByParent['project::'+project];
        var item = Object.values(expenditurelist.items).filter(item => item.id === id)[0];

        delete item['input'];
        item['input'] = JSON.parse(JSON.stringify(item));

        dispatch(changeExpenditure(item));
    }
}

export function newExpenditure(project, name, value) {
    return {
        type: NEW_EXPENDITURE,
        meta: { project },
        payload: {
            [name]: value,
        }
    }
}

export function postExpenditure(project, inputDatestart, inputDateend, inputWorkinghours, inputProjectpart) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/projectpartentry`,
        method: 'POST',
        body: JSON.stringify({
            project,
            datestart: inputDatestart,
            dateend: inputDateend,
            workinghours: inputWorkinghours,
            projectpart: inputProjectpart,
        }),
        types: [
            {type: API_POST_EXPENDITURE_REQUEST, meta: {project}},
            {type: API_POST_EXPENDITURE_SUCCESS, meta: {project}},
            {type: API_POST_EXPENDITURE_FAILURE, meta: {project}},
        ]
    })
}

export function changeExpenditure(item) {
    return {
        type: CHANGE_EXPENDITURE,
        meta: {
            project: item.project,
        },
        payload: {
            item,
        }
    }
}

export function updateExpenditure(item) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/projectpartentry/${item.id}`,
        method: 'PUT',
        body: JSON.stringify({
            projectpartentry: item,
        }),
        types: [
            {type: API_EXPENDITURE_REQUEST, meta: { project: item.project }},
            {type: API_EXPENDITURE_SUCCESS, meta: { project: item.project }},
            {type: API_EXPENDITURE_FAILURE, meta: { project: item.project }},
        ]
    })
}

export function deleteExpenditure(project, id) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/projectpartentry/${id}`,
        method: 'DELETE',
        types: [
            {type: API_EXPENDITURE_DELETE_REQUEST, meta: { project, id }},
            {type: API_EXPENDITURE_DELETE_SUCCESS, meta: { project, id }},
            {type: API_EXPENDITURE_DELETE_FAILURE, meta: { project, id }},
        ]
    })
}

export function fetchExpenditureList(project) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/projectpartentry?project=${project}`,
        method: 'GET',
        types: [
            {type: API_EXPENDITURE_LIST_REQUEST, meta: { project }},
            {type: API_EXPENDITURE_LIST_SUCCESS, meta: { project }},
            {type: API_EXPENDITURE_LIST_FAILURE, meta: { project }},
        ]
    })
}

function shouldFetchExpenditureList(state, project) {
    const expenditurelist = state.expendituresByParent['project::'+project];
    if (
        !expenditurelist ||
        ((expenditurelist.isFetching || expenditurelist.isFailed) && Date.now() - expenditurelist.lastFetch > config.FETCH_TIMEOUT) ||
        (!expenditurelist.isFetching && Date.now() - (expenditurelist.lastUpdated ? expenditurelist.lastUpdated : 0) > config.FETCH_AGAIN)
    ) {
        return true
    }
    return false
}

export default function fetchExpenditureListIfNeeded(project) {
    return (dispatch, getState) => {

        if (shouldFetchExpenditureList(getState(), project)) {
            return dispatch(fetchExpenditureList(project))
        }
    }
}

export function fetchExpenditureListIfDirty(project) {
    return (dispatch, getState) => {
        const expenditurelist = getState().expendituresByParent['project::'+project];

        if (!expenditurelist || expenditurelist.isDirty) {
            return dispatch(fetchExpenditureList(project));
        }
    }
}
