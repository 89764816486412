import React, { Component } from 'react';
import { Image, Responsive, Divider, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import TextareaAutosize from 'react-textarea-autosize';
import { newMessage, postMessage } from '../../../actions/conversation'

/* Icons */
import sendIcon from '../../../images/icons/frontend/icon-send.svg';
//import cameraIcon from '../../../images/icons/frontend/icon-camera-red.svg'; - Picture upload icon


class MessagesInput extends Component {

    state = {
        enableSendButton: false,
    }

    constructor(props) {
        super(props)

        this.handleInput = this.handleInput.bind(this);
        this.submit = this.submit.bind(this);
    }

    handleInput(e, element) {
        const { dispatch, conversationid } = this.props;
        dispatch(newMessage(e.target.value, conversationid));
    }

    submit(e, element) {
        const { dispatch, inputText, conversationid } = this.props;
        dispatch(postMessage(inputText, null, conversationid));
    }

    render() {
        const { inputText, isPosting, conversationid } = this.props;

        return (<>
            <Responsive minWidth={Responsive.onlyTablet.maxWidth}>
                <Divider/>
                <div className="send-msg-wrapper">
                            {/* TODO: Add image as message / to your message */}
                            {/*<div className="message-picture message-icon">
                                <Image src={cameraIcon} />
                                </div>
                            */}
                            <div className="textfield">
                                <TextareaAutosize
                                    placeholder={isPosting ? "Wird geschickt..." : "Gib Deine Nachricht ein..."}
                                    onChange={this.handleInput}
                                    value={inputText}
                                    minRows="1"
                                    maxRows="10"
                                    disabled={isPosting ? true : false}
                                />
                            </div>
                            <div
                                className="send-msg-button"

                                >
                                <Button
                                    onClick={inputText && this.submit}
                                    inverted
                                    disabled={!inputText}
                                    loading={isPosting ? true : false}
                                >
                                    {!isPosting &&
                                    <Image src={sendIcon} />
                                    }
                                </Button>
                            </div>

                </div>
            </Responsive>
            <Responsive maxWidth={Responsive.onlyTablet.maxWidth}>
                <div className="send-msg-wrapper">
                    <div className="textfield">
                            <TextareaAutosize
                                placeholder={isPosting ? "Wird geschickt..." : "Gib Deine Nachricht ein..."}
                                onChange={this.handleInput}
                                value={inputText}
                                minRows="1"
                                maxRows="10"
                                disabled={isPosting ? true : false}
                            />
                    </div>
                <div
                    className="send-msg-button"
                >
                    <Button
                        onClick={inputText && this.submit}
                        inverted
                        disabled={!inputText}
                        loading={isPosting ? true : false}
                    >
                        {!isPosting &&
                            <Image src={sendIcon} />
                         }
                    </Button>
                </div>

                </div>
            </Responsive>
        </>)
    }

}

function mapStateToProps(state, ownProps){
    const { conversationById } = state

    const { inputText, isPosting } = conversationById[
        ownProps.conversationid
    ] || {
        inputText: '',
        isPosting: false,
    }

    return {
        inputText,
        isPosting,
    }
}

export default connect(mapStateToProps)(MessagesInput);
