import React, { Component } from 'react'
import { Header, Container, Grid, Divider, List, Responsive, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { footerActiveItem, handleContainer, handleHeaderItems } from '../actions/global';
import { getPage } from '../actions/page';
import ErrorHandler from '../components/ErrorHandler/ErrorHandler';

class Updates extends Component {

    componentDidMount = () => {
        const { dispatch } = this.props;
        dispatch(handleContainer("main", "Updates", null, null, null, null, {description: 'Auf dieser Seite informieren wir dich über Updates und neue Funktionen bei Vetero. Hast Du auch einen Oldtimer? Dann ist Vetero genau das Richtige für Dich.'}));
        dispatch(handleHeaderItems("dots-menu"))
        dispatch(footerActiveItem('footermenu'))
        this.fetchPageContent()
        window.scrollTo(0, 0);
    }

    fetchPageContent = () => {
        const { dispatch } = this.props;
        dispatch(getPage(290, 'updates'))
    }

    render() {

        const { title, content, isFetching, isFailed } = this.props;

        return (
            <Container className="about-page">
             
                <Divider hidden />

                {isFetching && 
                    <Loader active/>
                }

                {isFailed && 
                    <ErrorHandler callbackFunction={this.fetchPageContent}/>
                }

                {!isFetching && title && content && 
                <>
                    <Responsive {...Responsive.onlyMobile}>
                    <   Header as="h2">{title.rendered}</Header>
                    </Responsive>
                    <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                        <Header as="h1">{title.rendered}</Header>
                    </Responsive>
                    <Divider/>

                    <div dangerouslySetInnerHTML={{__html: content.rendered}}/>
                </>
                }

                
                <Divider hidden/>
            </Container>
        )
    }
}

function mapStateToProps(state, ownProps){
    const { pageByName } = state
    const { title, content, isFetching, isFailed } = pageByName['updates']
    || {
        isFetching: true,
        isFailed: false,
        content: '',
        title: ''
    }
    
    return {
        content,
        title,
        isFailed,
        isFetching
    }
}

export default connect(mapStateToProps)(Updates);
