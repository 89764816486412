import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { Container, List, Header, Divider, Loader, Responsive } from 'semantic-ui-react';
import Helmet from 'react-helmet';
import { fetchBrandAll, fetchBrandProject, footerActiveItem, handleContainer, resetBrandProjectsList } from '../../../actions/global';
import  '../../Pages.scss';


class BrandDetail extends Component {

    state = {
        currentLetter: "",
        lastLetter: ""
    }

    componentDidMount(){
        const { dispatch, options, match, label, value } = this.props;
        dispatch(resetBrandProjectsList());
        dispatch(fetchBrandAll());
        dispatch(footerActiveItem(''));
        //Fetch on mount if value is available 
        if(value){
            dispatch(fetchBrandProject(options));
        }

        dispatch(
            handleContainer("newsfeed", "SPAN#Marken", null, null, null, null, {
                description: "Hier findest du alle Marken die in Vetero Oldtimer Portal angelegt wurden.",
                title: label ? label : match.params.slug
            }))

    }

    componentDidUpdate = (prevProps) => {
        const { label, dispatch, value, options } = this.props;

        if(prevProps.label !== label){
            dispatch(
                handleContainer("newsfeed", "SPAN#Marken", null, null, null, null, {
                    description: "Hier findest du alle Marken die in Vetero Oldtimer Portal angelegt wurden.",
                    title: label
                }))
        }

        //Fetch on mount if value is available 
        if(prevProps.value !== value){
            dispatch(fetchBrandProject(options));
        }

    }

    render() {
        const { label, listitems, isFetching } = this.props;

        return (
            isFetching ? 
            <Loader active/>
            :
            <Container className="brand-detail">

                <Helmet>
                    {/* <title>{label}{config.TITLE_SUFFIX}</title>   */}
                    <meta name="description" content={`Hier findest du alle ${label}-Projekte die in Vetero Oldtimer Portal angelegt wurden.`} />
                </Helmet>

                <Header as="h1">{label}</Header>

                <Divider/>
                {listitems && listitems.length > 0 && 
                <List bulleted>
                    {listitems.map((item) => (
                        <List.Item key={item.item.id}>
                            <Link
                                to={
                                    item.item.linktype === "stories" ? `/oldtimer/${item.item.urlslug}/${item.item.id}/tab/stories` : item.item.linktype === "vehicle" ? `/oldtimer/${item.item.urlslug}/${item.item.id}/tab/vehicle` : `/oldtimer/${item.item.urlslug}/${item.item.id}/tab/interview` 
                                    
                                }
                            >
                                {item.item.title} 
                            </Link> 
                        </List.Item>
                    ))
                    }
                </List>
                }
            </Container>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { brandBySlug, listByEntity, projectById } = state;
    const { label, value } = brandBySlug[ownProps.match.params.slug] || {
        label: "",
        value: ""
    }

    const { list, isFetching } = listByEntity[
        'brandProjects'
    ] || {
        list: {},
        isFetching: true,
    }

    let options = {};

    if (value) {
        options['project_filter[brand]'] = value;
    }

    let listitems = [];

    if (list) {
        for (let [key, value] of Object.entries(list)) {
            listitems.push(projectById[value]);
        }
    }

    return {
        options,
        label,
        listitems,
        isFetching,
        value
    }

}

export default connect(mapStateToProps)(BrandDetail);
