import {
    REQUEST_ACCOUNT,
    LOGIN_ACCOUNT,
    LOGOUT_ACCOUNT,
    FAIL_ACCOUNT,
    RECEIVE_ACCOUNT,
    SET_ACCESS_TOKEN,
    FETCH_USER_SUCCESS,
    FETCH_USER_REQUEST,
    FETCH_USER_FAILED,
    FETCH_USER_NOTIFICATIONS_FAILED,
    FETCH_USER_NOTIFICATIONS_REQUEST,
    FETCH_USER_NOTIFICATIONS_SUCCESS,
    NEW_PROJECT,
    API_POST_PROJECT_REQUEST,
    API_POST_PROJECT_SUCCESS,
    API_POST_PROJECT_FAILURE,
    FETCH_USER_VOUCHER_REQUEST,
    FETCH_USER_VOUCHER_SUCCESS,
    FETCH_USER_VOUCHER_FAILED,
    SELECT_USER_VOUCHER_REQUEST,
    SELECT_USER_VOUCHER_SUCCESS,
    SELECT_USER_VOUCHER_FAILED,
    API_POST_ACCOUNT_REQUEST,
    API_POST_ACCOUNT_SUCCESS,
    API_POST_ACCOUNT_FAILURE,
    API_POST_ACTIVATION_REQUEST,
    API_POST_ACTIVATION_SUCCESS,
    API_POST_ACTIVATION_FAILURE,
    API_POST_REQUEST_REQUEST,
    API_POST_REQUEST_SUCCESS,
    API_POST_REQUEST_FAILURE,
    API_POST_RECOVER_REQUEST,
    API_POST_RECOVER_SUCCESS,
    API_POST_RECOVER_FAILURE,
    RESET_MESSAGES,
    CHANGE_ACCOUNT,
    API_UPDATE_ACCOUNT_REQUEST,
    API_UPDATE_ACCOUNT_SUCCESS,
    API_UPDATE_ACCOUNT_FAILURE,
    API_DELETE_ACCOUNT_REQUEST,
    API_DELETE_ACCOUNT_SUCCESS,
    API_DELETE_ACCOUNT_FAILURE,
    API_POST_EMAILCHANGE_REQUEST,
    API_POST_EMAILCHANGE_SUCCESS,
    API_POST_EMAILCHANGE_FAILURE,
    RESET_STORE,
} from '../actions/account';
import {
    API_CONVERSATION_LIST_REQUEST,
    API_CONVERSATION_LIST_SUCCESS,
    API_CONVERSATION_LIST_FAILURE,
} from '../actions/conversation';
import {
    NEW_FEEDPOST,
    API_POST_FEEDPOST_REQUEST,
    API_POST_FEEDPOST_SUCCESS,
    API_POST_FEEDPOST_FAILURE,
} from '../actions/notification';
import {
    NEW_FORUMTHREAD,
    CLEAR_NEW_FORUMTHREAD,
    API_POST_FORUMTHREAD_REQUEST,
    API_POST_FORUMTHREAD_SUCCESS,
    API_POST_FORUMTHREAD_FAILURE,
    NEW_FORUMTHREAD_ADD_TAG,
    NEW_FORUMTHREAD_REMOVE_TAG,
    NEW_FORUMTHREAD_REVERT_TAG,
    NEW_FORUMTHREAD_SAVE_TAG,
    NEW_FORUMTHREAD_DELETE_DIRECT_TAG
} from '../actions/forumthread';
import {
    NEW_PROFESSIONAL,
    CLEAR_NEW_PROFESSIONAL,
    API_POST_PROFESSIONAL_REQUEST,
    API_POST_PROFESSIONAL_SUCCESS,
    API_POST_PROFESSIONAL_FAILURE,
    NEW_PROFESSIONAL_ADD_TAG,
    NEW_PROFESSIONAL_DELETE_DIRECT_TAG,
    NEW_PROFESSIONAL_REVERT_TAG,
    NEW_PROFESSIONAL_SAVE_TAG,
    NEW_PROFESSIONAL_REMOVE_TAG
} from '../actions/professional';
import { API_PAYPAL_AUTH_REQUEST, API_PAYPAL_AUTH_SUCCESS, API_PAYPAL_AUTH_FAILURE } from '../actions/paypal';
import {actions} from 'ix-redux-file-upload';

export default function account(state = {}, action) {

    try {

        if (action.type === RESET_STORE) {
            return {
                token: state.token,
            };
        }

        /* Paypal auth */
        if(action.type === API_PAYPAL_AUTH_REQUEST){
            return {
                ...state,
                ['paypal']: {
                    ...state['paypal'],
                    isFetching: true
                }
            }
        }

        else if(action.type === API_PAYPAL_AUTH_SUCCESS) {
            return {
                ...state, 
                ['paypal']: {
                    ...state['paypal'],
                    isFetching: false,
                    lastFetch: Date.now(),
                    data: action.payload
                }
            }
        }

        else if(action.type === API_PAYPAL_AUTH_FAILURE) {
            return {
                ...state, 
                ['paypal']: {
                    ...state['paypal'],
                    isFetching: false,
                    isFailed: true,
                    data: action.payload
                }
            }
        }


        if ((action.type === actions.FILE_UPLOAD_ADD_UPLOADING_IMAGES || action.type === actions.FILE_UPLOAD_ADD_UPLOADING_DOCUMENTS )) {            
            var identificator =  action.meta.identificator.split('.');

            if (identificator[0] === 'inputProject') {
                return {
                    ...state,
                    ['inputProject']: {
                        ...state['inputProject'],
                        [identificator[1] + 'Path']: 'LOADING',
                        
                    }
                };
            }
            /*else if (identificator[0] === 'inputFeedpost') {
                var media = state['inputFeedpost'][identificator[1]];

                if (!media) {
                    media = [];
                }

                for (var i = 0; i < action.meta.amount; ++i) {
                    media.push({
                        id: null,
                        path: 'LOADING',
                        type: null,
                    });
                }

                state = {
                    ...state,
                    ['inputFeedpost']: {
                        ...state['inputFeedpost'],
                        [identificator[1]]: media,
                        mediaAmount: action.meta.amount
                    }
                }

                return state;
            }*/
            else if (identificator[0] === 'account') {
                return {
                    ...state,
                    ['item']: {
                        ...state['item'],
                        [identificator[1] + 'Path']: 'LOADING',
                    }
                };
            }
            /*else if (identificator[0] === 'inputForumThread') {
                var media = state['inputForumThread'][identificator[1]][identificator[2]];

                if (!media) {
                    media = [];
                }

                for (var i = 0; i < action.meta.amount; ++i) {
                    media.push({
                        id: null,
                        path: 'LOADING',
                        type: null,
                    });
                }

                state = {
                    ...state,
                    ['inputForumThread']: {
                        ...state['inputForumThread'],
                        [identificator[1]]: {
                            ...state['inputForumThread'][identificator[1]],
                            [identificator[2]]: media,
                            mediaAmount: action.meta.amount
                        }
                    }
                }

                return state;
            }*/
            else if (identificator[0] === 'inputProfessional') {
                return {
                    ...state,
                    ['inputProfessional']: {
                        ...state['inputProfessional'],
                        [identificator[1] + 'Path']: 'LOADING',
                    }
                };
            }
        }

        else if (action.type === actions.FILE_UPLOAD_PROGRESS){
            var identificator = action.payload.identificator.split('.');
            
            if (identificator[0] === 'inputProject') {
                return {
                    ...state,
                    ['inputProject']: {
                        ...state['inputProject'],
                        [identificator[1] + 'Progress']: action.payload.progress,
                    }
                };
            }
            else if (identificator[0] === 'inputFeedpost') {
                var media = state['inputFeedpost'][identificator[1]];

                if (!media) {
                    media = [];
                }
                
                var updated = false;
                if(media){
                    media.map((item, index) => {
                        if(item.id == action.payload.fileType.uid && !updated){
                            media[index].progress = action.payload.progress;
                            updated = true;
                        }
                    })
                }

                if(!updated) {
                    media.push({
                        id: action.payload.fileType.uid,
                        progress: action.payload.progress,
                        position: media.length +1,
                        path: 'LOADING',
                        type: null,
                    });
                }

                state = {
                    ...state,
                    ['inputFeedpost']: {
                        ...state['inputFeedpost'],
                        [identificator[1]]: media,
                    }
                }

                return state;
            }
            else if (identificator[0] === 'account') {
                return {
                    ...state,
                    ['item']: {
                        ...state['item'],
                        [identificator[1] + 'Progress']: action.payload.progress,
                    }
                };
            }
            else if (identificator[0] === 'inputForumThread') {
                var media = state['inputForumThread'][identificator[1]][identificator[2]];

                if(!media){
                    media = [];
                }
                
                var updated = false;
                if(media){
                    media.map((item, index) => {
                        if(item.id == action.payload.fileType.uid && !updated){
                            media[index].progress = action.payload.progress;
                            updated = true;
                        }
                    })
                }

                if(!updated) {
                    media.push({
                        id: action.payload.fileType.uid,
                        progress: action.payload.progress,
                        position: media.length +1,
                        path: 'LOADING',
                        previewPath: 'LOADING',
                        type: action.payload.isImage ? 1 : 2,
                    });
                }

                state = {
                    ...state,
                    ['inputForumThread']: {
                        ...state['inputForumThread'],
                        [identificator[1]]: {
                            ...state['inputForumThread'][identificator[1]],
                            [identificator[2]]: media,
                        }
                    }
                }

                return state;
            }
            else if (identificator[0] === 'inputProfessional') {
                return {
                    ...state,
                    ['inputProfessional']: {
                        ...state['inputProfessional'],
                        [identificator[1] + 'Progress']: action.payload.progress,
                    }
                };
            }
            
        }

        else if (action.type === actions.FILE_UPLOAD_COMPLETE) {
            var identificator = action.payload.identificator.split('.');
            if (identificator[0] === 'inputProject') {
                return {
                    ...state,
                    ['inputProject']: {
                        ...state['inputProject'],
                        [identificator[1]]: action.payload.response.id,
                        [identificator[1] + 'Path']: action.payload.response.path,
                    }
                };
            }
            else if (identificator[0] === 'inputFeedpost') {
                var media = state['inputFeedpost'][identificator[1]];

                if (!media) {
                    media = [];
                }

                var removedLoading = false;
                media.map((mv, mk) => {
                    if ((mv.path === 'LOADING' && mk+1 === mv.position && mv.progress === 100) && !removedLoading) {
                        media[mk] = {
                            ...media[mk],
                            id: action.payload.response.id,
                            path: action.payload.response.path,
                            type: action.payload.response.type,
                        };
                        removedLoading = true;
                    }
                });

                state = {
                    ...state,
                    ['inputFeedpost']: {
                        ...state['inputFeedpost'],
                        isDirty: true,
                        [identificator[1]]: media,
                    }
                }

                return state;
            }
            else if (identificator[0] === 'account') {
                return {
                    ...state,
                    ['item']: {
                        ...state['item'],
                        [identificator[1]]: action.payload.response.id,
                        [identificator[1] + 'Path']: action.payload.response.path,
                    },
                    isDirty: true,
                };
            }
            else if (identificator[0] === 'inputForumThread') {
                var media = state['inputForumThread'][identificator[1]][identificator[2]];

                if (!media) {
                    media = [];
                }

                var removedLoading = false;
                media.map((mv, mk) => {
                    if ((mv.path === 'LOADING' && mk+1 === mv.position && mv.progress === 100) && !removedLoading) {
                        media[mk] = {
                            ...media[mk],
                            id: action.payload.response.id,
                            path: action.payload.response.path,
                            type: action.payload.response.type,
                            previewPath: action.payload.response.type === 2 ? action.payload.response.previewPath : null
                        };
                        removedLoading = true;
                    }
                });

                state = {
                    ...state,
                    ['inputForumThread']: {
                        ...state['inputForumThread'],
                        [identificator[1]]: {
                            ...state['inputForumThread'][identificator[1]],
                            [identificator[2]]: media,
                        }
                    }
                }

                return state;
            }
            else if (identificator[0] === 'inputProfessional') {

                    var media = state['inputProfessional'][identificator[1]];

                    if (!media) {
                        media = [];
                    }
                    
                    var removedLoading = false;
                    media.map((mv, mk) => {
                        if ((mv.path === 'LOADING' && mk+1 === mv.position && mv.progress === 100) && !removedLoading) {
                            media[mk] = {
                                ...media[mk],
                                id: action.payload.response.id,
                                path: action.payload.response.path,
                                type: action.payload.response.type,
                                previewPath: action.payload.response.type === 2 ? action.payload.response.previewPath : null
                            };
                            removedLoading = true;
                        }
                    });

                    state = {
                        ...state,
                        ['inputProfessional']: {
                            ...state['inputProfessional'],
                            isDirty: true,
                            [identificator[1]]: media,
                        }
                    }

                    return state;


            }
        }

        else if (action.type === API_POST_PROJECT_REQUEST) {
            return {
                ...state,
                ['inputProject']: {
                    ...state['inputProject'],
                    isPosting: true,
                    postingMessage: '',
                }
            };
        }
        else if (action.type === API_POST_PROJECT_SUCCESS) {
            state = {
                ...state,
                ['inputProject']: {
                    isPosting: false,
                    postingMessage: 'SUCCESS',
                    newId: action.payload.project.id,
                }
            };
        }
        else if (action.type === API_POST_PROJECT_FAILURE) {
            return {
                ...state,
                ['inputProject']: {
                    ...state['inputProject'],
                    isPosting: false,
                    postingMessage: 'FAILURE',
                }
            };
        }

        else if (action.type === API_POST_FEEDPOST_REQUEST) {
            return {
                ...state,
                ['inputFeedpost']: {
                    ...state['inputFeedpost'],
                    isPosting: true,
                    postingMessage: '',
                }
            };
        }
        else if (action.type === API_POST_FEEDPOST_SUCCESS) {
            state = {
                ...state,
                ['inputFeedpost']: {
                    isPosting: false,
                    postingMessage: 'SUCCESS',
                    newId: action.payload.feedpost.id,
                }
            };
        }
        else if (action.type === API_POST_FEEDPOST_FAILURE) {
            return {
                ...state,
                ['inputFeedpost']: {
                    ...state['inputFeedpost'],
                    isPosting: false,
                    postingMessage: 'FAILURE',
                }
            };
        }

        else if (action.type === API_POST_ACCOUNT_REQUEST) {
            return {
                ...state,
                isRegistering: true,
                registeringMessage: '',
            };
        }
        else if (action.type === API_POST_ACCOUNT_SUCCESS) {
            state = {
                ...state,
                isRegistering: false,
                registeringMessage: (action.payload.error ? action.payload.error : 'SUCCESS'),
            };
        }
        else if (action.type === API_POST_ACCOUNT_FAILURE) {
            return {
                ...state,
                isRegistering: false,
                registeringMessage: 'FAILURE',
            };
        }

        else if (action.type === API_POST_ACTIVATION_REQUEST) {
            return {
                ...state,
                isActivating: true,
                activatingMessage: '',
            };
        }
        else if (action.type === API_POST_ACTIVATION_SUCCESS) {
            state = {
                ...state,
                isActivating: false,
                activatingMessage: (action.payload.error ? action.payload.error : 'SUCCESS'),
            };
        }
        else if (action.type === API_POST_ACTIVATION_FAILURE) {
            return {
                ...state,
                isActivating: false,
                activatingMessage: 'FAILURE',
            };
        }

        else if (action.type === API_POST_EMAILCHANGE_REQUEST) {
            return {
                ...state,
                isChangingEmail: true,
                changingEmailMessage: '',
            };
        }
        else if (action.type === API_POST_EMAILCHANGE_SUCCESS) {
            state = {
                ...state,
                isChangingEmail: false,
                changingEmailMessage: (action.payload.error ? action.payload.error : 'SUCCESS'),
                item: (
                    state.item && state.item.id ?
                    {
                        ...state.item,
                        mail: action.payload.mail,
                    } :
                    {}
                ),
            };
            // Update token
            if (action.payload.token) {
                state = {
                    ...state,
                    token: action.payload.token,
                };
            }
        }
        else if (action.type === API_POST_EMAILCHANGE_FAILURE) {
            return {
                ...state,
                isChangingEmail: false,
                changingEmailMessage: 'FAILURE',
            };
        }

        else if (action.type === API_POST_REQUEST_REQUEST) {
            return {
                ...state,
                isRequesting: true,
                requestingMessage: '',
            };
        }
        else if (action.type === API_POST_REQUEST_SUCCESS) {
            state = {
                ...state,
                isRequesting: false,
                requestingMessage: (action.payload.error ? action.payload.error : 'SUCCESS'),
            };
        }
        else if (action.type === API_POST_REQUEST_FAILURE) {
            return {
                ...state,
                isRequesting: false,
                requestingMessage: 'FAILURE',
            };
        }

        else if (action.type === API_POST_RECOVER_REQUEST) {
            return {
                ...state,
                isRecovering: true,
                recoveringMessage: '',
            };
        }
        else if (action.type === API_POST_RECOVER_SUCCESS) {
            state = {
                ...state,
                isRecovering: false,
                recoveringMessage: (action.payload.error ? action.payload.error : 'SUCCESS'),
            };
        }
        else if (action.type === API_POST_RECOVER_FAILURE) {
            return {
                ...state,
                isRecovering: false,
                recoveringMessage: 'FAILURE',
            };
        }

        else if (action.type === RESET_MESSAGES) {
            return {
                ...state,
                ['inputProject']: {
                    ...state['inputProject'],
                    postingMessage: '',
                },
                ['inputFeedpost']: {
                    ...state['inputFeedpost'],
                    postingMessage: '',
                },
                registeringMessage: '',
                activatingMessage: '',
                requestingMessage: '',
                recoveringMessage: '',
                updatingMessage: '',
                deletingMessage: '',
                voucherMessage: '',
            };
        }

        else if (action.type === CHANGE_ACCOUNT) {
            return {
                ...state,
                ['item']: {
                    ...state['item'],
                    ...action.payload,
                },
                isDirty: true,
            };
        }
        else if (action.type === API_UPDATE_ACCOUNT_REQUEST) {
            return {
                ...state,
                isFetching: true,
                lastFetch: Date.now(),
                updatingMessage: '',
            };
        }
        else if (action.type === API_UPDATE_ACCOUNT_SUCCESS) {
            if (action.payload.error) {
                state = {
                    ...state,
                    updatingMessage: action.payload.error,
                    isFetching: false,
                    isFailed: false,
                };
            }
            else {
                state = {
                    ...state,
                    item: {
                        ...action.payload.account,
                    },
                    isDirty: false,
                    isFetching: false,
                    isFailed: false,
                    lastUpdated: Date.now(),
                    updatingMessage: (action.meta.inputEmail && action.meta.inputEmail !== action.payload.account.email ? 'SUCCESSWITHCHANGE' : 'SUCCESS'),
                };
                // Update token
                if (action.payload.token) {
                    state = {
                        ...state,
                        token: action.payload.token,
                    };
                }
            }

            return state;
        }
        else if (action.type === API_UPDATE_ACCOUNT_FAILURE) {
            return {
                ...state,
                isFetching: false,
                isFailed: true,
                lastUpdated: Date.now(),
                updatingMessage: 'FAILURE',
            };
        }

        else if (action.type === API_DELETE_ACCOUNT_REQUEST) {
            return {
                ...state,
                isDeleting: true,
                deletingMessage: '',
            };
        }
        else if (action.type === API_DELETE_ACCOUNT_SUCCESS) {
            state = {
                ...state,
                isDeleting: false,
                deletingMessage: (action.payload && action.payload.error ? action.payload.error : 'SUCCESS'),
            };
        }
        else if (action.type === API_DELETE_ACCOUNT_FAILURE) {
            return {
                ...state,
                isDeleting: false,
                deletingMessage: 'FAILURE',
            };
        }

        else if (action.type === API_POST_FORUMTHREAD_REQUEST && !action.meta.id) {
            return {
                ...state,
                ['inputForumThread']: {
                    ...state['inputForumThread'],
                    isPosting: true,
                    postingMessage: '',
                }
            };
        }
        else if (action.type === API_POST_FORUMTHREAD_SUCCESS && !action.meta.id) {
            state = {
                ...state,
                ['inputForumThread']: {
                    isPosting: false,
                    postingMessage: 'SUCCESS',
                    newId: action.payload.forumthread.id,
                    message: {},
                    thread: {},
                }
            };
        }
        else if (action.type === API_POST_FORUMTHREAD_FAILURE && !action.meta.id) {
            return {
                ...state,
                ['inputForumThread']: {
                    ...state['inputForumThread'],
                    isPosting: false,
                    postingMessage: 'FAILURE',
                }
            };
        }
        else if (action.type === API_POST_PROFESSIONAL_REQUEST) {
            return {
                ...state,
                ['inputProfessional']: {
                    ...state['inputProfessional'],
                    isPosting: true,
                    postingMessage: '',
                }
            };
        }
        else if (action.type === API_POST_PROFESSIONAL_SUCCESS) {
            state = {
                ...state,
                ['inputProfessional']: {
                    isPosting: false,
                    postingMessage: 'SUCCESS',
                    newId: action.payload.professional.id,
                    newUrlslug: action.payload.professional.urlslug
                }
            };
        }
        else if (action.type === API_POST_PROFESSIONAL_FAILURE) {
            return {
                ...state,
                ['inputProfessional']: {
                    ...state['inputProfessional'],
                    isPosting: false,
                    postingMessage: 'FAILURE',
                }
            };
        }
        else if(action.type === NEW_FORUMTHREAD_ADD_TAG){
            return {
                ...state,
                inputForumThread: {
                    ...state.inputForumThread,
                    thread: {
                        ...state.inputForumThread.thread,
                        inputTag: [
                            ...state.inputForumThread.thread.inputTag ? state.inputForumThread.thread.inputTag : [],
                            action.payload.tag
                        ]
                    }
                }
            }
        }
        else if(action.type === NEW_FORUMTHREAD_REMOVE_TAG){
            return {
                ...state,
                inputForumThread: {
                    ...state.inputForumThread,
                    thread: {
                        ...state.inputForumThread.thread,
                        inputTag: [
                            ...state.inputForumThread.thread.inputTag.filter(item => action.payload.tag.value !== item.value)
                        ]
                    }
                }
            }
        }
        else if(action.type === NEW_FORUMTHREAD_SAVE_TAG){
            return {
                ...state,
                inputForumThread: {
                    ...state.inputForumThread,
                    thread: {
                        ...state.inputForumThread.thread,
                        categories: [
                            ...action.payload.tag
                        ]
                    }
                }
            }
        }
        else if(action.type === NEW_FORUMTHREAD_REVERT_TAG){
            return {
                ...state,
                inputForumThread: {
                    ...state.inputForumThread,
                    thread: {
                        ...state.inputForumThread.thread,
                        inputTag: action.payload.tag
                    }
                }
            }
        }
        else if(action.type === NEW_FORUMTHREAD_DELETE_DIRECT_TAG){
            return {
                ...state,
                inputForumThread: {
                    ...state.inputForumThread,
                    thread: {
                        ...state.inputForumThread.thread,
                        inputTag: [
                            ...state.inputForumThread.thread.inputTag.filter(item => action.payload.tag !== item.value)
                        ],
                        categories: [
                            ...state.inputForumThread.thread.categories.filter(item => action.payload.tag !== item)
                        ]
                    }
                }
            }
        }

        else if(action.type === NEW_PROFESSIONAL_ADD_TAG){
            return {
                ...state,
                inputProfessional: {
                    ...state.inputProfessional,
                    inputTag: [
                        ...state.inputProfessional.inputTag ? state.inputProfessional.inputTag : [],
                        action.payload.tag
                    ]
                }
            }
        }
        else if(action.type === NEW_PROFESSIONAL_REMOVE_TAG){
            return {
                ...state,
                inputProfessional: {
                    ...state.inputProfessional,
                    inputTag: [
                        ...state.inputProfessional.inputTag.filter(item => action.payload.tag.value !== item.value)
                    ]
                }
            }
        }
        else if(action.type === NEW_PROFESSIONAL_SAVE_TAG){
            return {
                ...state,
                inputProfessional: {
                    ...state.inputProfessional,
                    categories: [
                        ...action.payload.tag
                    ]
                }
            }
        }
        else if(action.type === NEW_PROFESSIONAL_REVERT_TAG){
            return {
                ...state,
                inputProfessional: {
                    ...state.inputProfessional,
                    inputTag: action.payload.tag
                }
            }
        }
        else if(action.type === NEW_PROFESSIONAL_DELETE_DIRECT_TAG){
            return {
                ...state,
                inputProfessional: {
                    ...state.inputProfessional,
                    inputTag: [
                        ...state.inputProfessional.inputTag.filter(item => action.payload.tag !== item.value)
                    ],
                    categories: [
                        ...state.inputProfessional.categories.filter(item => action.payload.tag !== item)
                    ]
                }
            }
        }

        switch (action.type) {
            case REQUEST_ACCOUNT:
            case LOGIN_ACCOUNT:
            case LOGOUT_ACCOUNT:
            case RECEIVE_ACCOUNT:
            case FAIL_ACCOUNT:
                return Object.assign({}, state, action.data)
            case SET_ACCESS_TOKEN:
                return {
                    ...state,
                    token: action.payload.token,
                    lastUpdated: Date.now(),
                }
            case FETCH_USER_REQUEST:
                return {
                    ...state,
                    user: {},
                    isFetching: true,
                    isFailed: false
                }
            case FETCH_USER_SUCCESS:
                return {
                    ...state,
                    user: {
                        ...state.user,
                        id: action.payload.id,
                        name: action.payload.name,
                        countrycode: action.payload.countrycode,
                        zipcode: action.payload.zipcode,
                        description: action.payload.description,
                        birthyear: action.payload.birthyear,
                        mediaavatar: action.payload.mediaavatar,
                        mediaavatarPath: action.payload.mediaavatarPath,
                        createDate: action.payload.createDate,
                        countFollowers: action.payload.countFollowers,
                        urlslug: action.payload.countFollowers,
                    },
                    isFetching: false,
                    isFailed: false
                }
            case FETCH_USER_FAILED:
                return {
                    ...state,
                    user: {},
                    isFetching: false,
                    isFailed: false
                }

            case API_CONVERSATION_LIST_REQUEST:
                return {
                    ...state,
                    isFetchingConversations: true,
                    isFailedConversations: false,
                    lastFetchConversations: Date.now(),
                }
            case API_CONVERSATION_LIST_SUCCESS:
                return {
                    ...state,
                    isFetchingConversations: false,
                    isFailedConversations: false,
                }
            case API_CONVERSATION_LIST_FAILURE:
                return {
                    ...state,
                    isFetchingConversations: false,
                    isFailedConversations: true,
                }
            case FETCH_USER_NOTIFICATIONS_REQUEST:
                return {
                    ...state,
                    ['notifications']: {
                        ...state['notifications'],
                        isFetching: true,
                        lastFetch: Date.now()
                    }
                }
            case FETCH_USER_NOTIFICATIONS_SUCCESS:
                state = {
                    ...state,
                    ['notifications']: {
                        ...state['notifications'],
                        count: action.payload.count,
                        isFetching: false,
                        lastUpdated: Date.now()
                    }
                };
                // Update token
                if (action.payload.token) {
                    state = {
                        ...state,
                        token: action.payload.token,
                    };
                }

                return state;
            case FETCH_USER_NOTIFICATIONS_FAILED:
                return {
                    ...state,
                    ['notifications']: {
                        ...state['notifications'],
                        isFetching: false,
                        isFailed: false,
                        lastUpdated: Date.now(),
                    }
                }
            case FETCH_USER_VOUCHER_REQUEST:
                return {
                    ...state,
                    ['voucher']: {
                        ...state['voucher'],
                        isFetching: true,
                        lastFetch: Date.now()
                    }
                }
            case FETCH_USER_VOUCHER_SUCCESS:
                return {
                    ...state,
                    ['voucher']: {
                        ...state['voucher'],
                        vouchers: [
                            ...action.payload.vouchers,
                        ],
                        partners: [
                            ...action.payload.partners,
                        ],
                        requirements: action.payload.requirements,
                        isFetching: false,
                        lastUpdated: Date.now()
                    }
                }
            case FETCH_USER_VOUCHER_FAILED:
                return {
                    ...state,
                    ['voucher']: {
                        ...state['voucher'],
                        isFetching: false,
                        isFailed: false,
                        lastUpdated: Date.now(),
                    }
                }
            case SELECT_USER_VOUCHER_REQUEST:
                return {
                    ...state,
                    ['voucher']: {
                        ...state['voucher'],
                        isFetching: true,
                        lastFetch: Date.now(),
                        voucherMessage: '',
                        messagePosition: null,
                    }
                }
            case SELECT_USER_VOUCHER_SUCCESS:
                var vouchers = [];
                if (state['voucher']['vouchers']) {
                    vouchers = [...state['voucher']['vouchers']];
                }

                if (action.payload.voucher && action.payload.voucher.partner) {
                    // Remove old voucher for same partner
                    vouchers = vouchers.filter(v => v.partner != action.payload.voucher.partner);
                    // Add new
                    vouchers.push(action.payload.voucher);
                }

                return {
                    ...state,
                    ['voucher']: {
                        ...state['voucher'],
                        vouchers: vouchers,
                        isFetching: false,
                        lastUpdated: Date.now(),
                        voucherMessage: (action.payload.error ? action.payload.error : 'SUCCESS'),
                        messagePosition: action.meta.id,
                    }
                }
            case SELECT_USER_VOUCHER_FAILED:
                return {
                    ...state,
                    ['voucher']: {
                        ...state['voucher'],
                        isFetching: false,
                        isFailed: false,
                        lastUpdated: Date.now(),
                        voucherMessage: 'FAILURE',
                        messagePosition: action.meta.id,
                    }
                }
            case NEW_PROJECT:
                return {
                    ...state,
                    ['inputProject']: {
                        ...action.payload.inputProject,
                    }
                }
            case NEW_FEEDPOST:
                return {
                    ...state,
                    ['inputFeedpost']: {
                        ...action.payload.inputFeedpost,
                    }
                }
            case NEW_FORUMTHREAD:
                return {
                    ...state,
                    ['inputForumThread']: {
                        ...state['inputForumThread'],
                        ...action.payload.inputForumThread,
                    },
                }
            case NEW_PROFESSIONAL:
                return {
                    ...state,
                    ['inputProfessional']: {
                        ...state['inputProfessional'],
                        ...action.payload.inputProfessional,
                    },
                }
            case CLEAR_NEW_FORUMTHREAD:
                return {
                    ...state,
                    ['inputForumThread']: {
                        message: {},
                        thread: {},
                    },
                }
            case CLEAR_NEW_PROFESSIONAL:
                return {
                    ...state,
                    ['inputProfessional']: {},
                }
            default:
                return state
        }

    } catch (e) {

        console.log(e);
        return {
            ...state,
            fatalError: true,
        };

    }


}
