import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Header, Container, Grid, Responsive, Divider, Image, List, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { setAccessToken, fetchAccount } from '../actions/account';
import { footerActiveItem, handleContainer, handleHeaderItems } from '../actions/global';
import FlexGallery from '../components/FlexGallery/FlexGallery';
import Account from '../components/account/Account';
import Aos from 'aos'
import "aos/dist/aos.css"

//Images
import gallery1 from '../images/homepage/gallery/gallery_6.jpg';
import gallery2 from '../images/homepage/gallery/gallery_13.jpg';
import gallery3 from '../images/homepage/gallery/gallery_16.jpg';
import gallery4 from '../images/homepage/gallery/gallery_2.jpg';
import gallery5 from '../images/homepage/gallery/gallery_9.jpg';
import gallery6 from '../images/homepage/gallery/gallery_5.jpg';
import gallery7 from '../images/homepage/gallery/gallery_11.jpg';
import gallery8 from '../images/homepage/gallery/gallery_12.jpg';
import gallery9 from '../images/homepage/gallery/gallery_1.jpg';
import gallery10 from '../images/homepage/gallery/gallery_8.jpg';
import gallery11 from '../images/homepage/gallery/gallery_7.jpg';
import gallery12 from '../images/homepage/gallery/gallery_14.jpg';
import gallery13 from '../images/homepage/gallery/gallery_4.jpg';
import gallery14 from '../images/homepage/gallery/gallery_3.jpg';
import gallery15 from '../images/homepage/gallery/gallery_10.jpg';
//List icons
import Dokumentation from '../images/homepage/list-icons/icon-dokumentation.svg';
import Arbeitszeit from '../images/homepage/list-icons/icon-arbeitszeit.svg';
import Kosten from '../images/homepage/list-icons/icon-kosten-rechnungen.svg';
import Kostenlos from '../images/homepage/list-icons/icon-kostenlos.svg';
import Ersatzteile from '../images/homepage/list-icons/icon-ersatzteile.svg';
import Montagehilfe from '../images/homepage/list-icons/icon-montagehilfe.svg';
import { Helmet } from 'react-helmet';



class Homepage extends Component {

    state = {
        loginPopupVisible: false
    }

    //Flex gallery images
    images = [
        {img: gallery1, alt: "Volvo PV 544 1965"},
        {img: gallery2, alt: "Ford V8 Deluxe Fordor 1940"},
        {img: gallery3, alt: "Simson AWO 425 1958"},
        {img: gallery4, alt: "Ford Mustang 1967"},
        {img: gallery5, alt: "Triumph Gt6 1967"},
        {img: gallery6, alt: "Riley Adelphi 1938"},
        {img: gallery7, alt: "Unimog 404 1964"},
        {img: gallery8, alt: "Austin-Healey 100/4 1954"},
        {img: gallery9, alt: "Opel Manta A 1975"},
        {img: gallery10, alt: "Opel Kadett C Coupe 1978"},
        {img: gallery11, alt: "Steyr T84 E 1959"},
        {img: gallery12, alt: "MZ RT 125 1960"},
        {img: gallery13, alt: "Daihatsu Charade 1981"},
        {img: gallery14, alt: "Honda CRX 1987"},
        {img: gallery15, alt: "Alfa Romeo 164 QV 3.0 12V 1991"},
    ]

    componentDidMount = () => {
        const { dispatch, history, runtimedata } = this.props;
        window.scrollTo(0, 0);
        //Init AOS - Animation library
        Aos.init({ duration: 2000, once: true, anchorPlacement: "top-bottom" });

        dispatch(footerActiveItem(""))
        dispatch(handleContainer("newsfeed", "", null, null, null, null, {description: 'Das Portal für Oldtimer Schrauber und Oldtimer Fans. Auf Vetero kannst Du Deinen Oldtimer präsentieren, Restaurationen dokumentieren und vieles mehr.'}));
        dispatch(handleHeaderItems(""));

        // Login + redirect OAuth logins (Facebook/Google)
        if (queryString.parse(this.props.location.search).token) {
            dispatch(setAccessToken({
                token: queryString.parse(this.props.location.search).token,
            }));
            dispatch(fetchAccount());
            if (runtimedata && runtimedata.currentLocation) {
                history.push(runtimedata.currentLocation);
            }
            else {
                history.push('/');
            }
        }

    }

    render() {

        const { item } = this.props;


        return (
            <>

            <Helmet>
                <title>Vetero - Das Oldtimer Portal für Schrauber und Fans</title>
            </Helmet>

            <div className="homepage">

                <div className="homepage-header">
                    <div className="homepage-header-content">
                        <Container>
                            <Grid textAlign="center">
                                <Grid.Column width={16}>
                                    <Header as="h1">Fühle Benzin, Blut und Leidenschaft</Header>
                                    <p>bei spannenden Oldtimern und Oldtimer-Projekten</p>
                                    {
                                        item && item.id ?
                                        <>
                                        <Responsive {...Responsive.onlyMobile} as={Container}>
                                            <Button as={Link} size="large" to="/backend" fluid color="red">Starte jetzt</Button>
                                        </Responsive>
                                        <Responsive to="/backend"  minWidth={Responsive.onlyTablet.minWidth} as={Container}>
                                            <Button as={Link} size="massive" to="/backend"  color="red">Starte jetzt</Button>
                                        </Responsive>
                                        </>
                                        :
                                        <>
                                        <Responsive {...Responsive.onlyMobile} as={Container}>
                                            <Button color="red" size="large" onClick={() => this.setState({ loginPopupVisible: true })}>
                                                Starte jetzt
                                            </Button>
                                        </Responsive>

                                        <Responsive  minWidth={Responsive.onlyTablet.minWidth} as={Container}>
                                            <Button color="red" size="massive" onClick={() => this.setState({ loginPopupVisible: true })}>
                                                Starte jetzt
                                            </Button>
                                        </Responsive>
                                        </>
                                    }

                                </Grid.Column>
                            </Grid>
                        </Container>
                    </div>
                </div>

                <Header className="mt-3" textAlign="center" as="h2">Lass dich inspirieren</Header>
                <div className="flex-gallery-init" data-aos="fade-in">
                    <FlexGallery images={this.images}/>
                </div>

                <div className="project-button-section mb-5" data-aos="fade-in" >
                    <Grid textAlign="center">
                        <Grid.Column width={16}>
                            <Button as={Link} to="/oldtimer" color="red">Alle Oldtimer und Projekte anschauen</Button>
                        </Grid.Column>
                    </Grid>
                    <Divider hidden/>
                </div>

                <section className="banner-register">

                    <Container>
                        <div className="banner-content" data-aos="fade-in">
                            <Grid textAlign="center">
                                <Grid.Column width={16}>
                                    <Header textAlign="center" as="h2">Starte jetzt mit deinem Oldtimer</Header>
                                    <p>Zeig allen, was du (drauf) hast und behalte den Durchblick bei deinen Projekten. Vom Fahrrad bis zum LKW.</p>
                                    {
                                        item && item.id ?
                                        <Button as={Link} inverted to="/backend">
                                           Zum Dashboard
                                        </Button>
                                        :
                                        <Button color="red" onClick={() => this.setState({ loginPopupVisible: true })}>
                                            Jetzt kostenlos anmelden
                                        </Button>
                                    }
                                </Grid.Column>
                            </Grid>
                        </div>
                    </Container>

                </section>

                <div className="list-section">

                    <Header textAlign="center" as="h2">So unterstützt dich Vetero</Header>

                    <div className="list-benefits" data-aos="fade-in">
                        <Container>
                            <Divider hidden/>
                            <List>

                                <List.Item data-aos="zoom-in"  data-aos-duration="600">
                                    <div className="list-custom-icon">
                                        <Image src={Dokumentation} alt="Icon Dokumentation" title="Dokumentation"/>
                                    </div>
                                    <List.Content>
                                        <List.Header>Dokumentation</List.Header>
                                        <List.Description>Mit Storys kannst Du alles dokumentieren. Egal ob Restauration, Ausflug oder Rallyes. Bis ins kleinste Detail - für immer.</List.Description>
                                    </List.Content>
                                </List.Item>

                                <List.Item data-aos="zoom-in"  data-aos-delay="300"  data-aos-duration="600">
                                    <div className="list-custom-icon">
                                        <Image src={Arbeitszeit} alt="Icon Arbeitszeit" title="Arbeitszeit"/>
                                    </div>
                                    <List.Content>
                                        <List.Header>Arbeitszeit</List.Header>
                                        <List.Description>Ab jetzt weißt Du genau wann Du wie viel Zeit in Dein Projekt gesteckt hast - und wofür. </List.Description>
                                    </List.Content>
                                </List.Item>

                                <List.Item data-aos="zoom-in"  data-aos-delay="400"  data-aos-duration="600">
                                    <div className="list-custom-icon">
                                        <Image src={Kosten} alt="Icon Kosten Rechnungen" title="Kosten und Rechnungen"/>
                                    </div>
                                    <List.Content>
                                        <List.Header>Kosten &amp; Rechnungen</List.Header>
                                        <List.Description>Über die Zeit kommt einiges zusammen. Jetzt behältst Du den Überblick - inklusive Rechnungen.</List.Description>
                                    </List.Content>
                                </List.Item>

                                <List.Item data-aos="zoom-in"  data-aos-delay="500" data-aos-duration="600">
                                    <div className="list-custom-icon">
                                        <Image src={Ersatzteile} alt="Icon Ersatzteile" title="Ersatzteile"/>
                                    </div>
                                    <List.Content>
                                        <List.Header>Ersatzteile</List.Header>
                                        <List.Description>Welche Teile benötige ich noch mal? Wie sehen diese genau aus und welche Maße haben sie? Ab jetzt hast Du immer alles dabei.</List.Description>
                                    </List.Content>
                                </List.Item>

                                <List.Item data-aos="zoom-in"  data-aos-delay="600" data-aos-duration="600">
                                    <div className="list-custom-icon">
                                        <Image src={Montagehilfe} alt="Icon Montagehilfe" title="Montagehilfe"/>
                                    </div>
                                    <List.Content>
                                        <List.Header>Montagehilfe</List.Header>
                                        <List.Description>Lang ist es her. Wie war das ursprünglich in welcher Reihenfolge zusammengebaut? Notizen mit Fotos schaffen Abhilfe.</List.Description>
                                    </List.Content>
                                </List.Item>

                                <List.Item data-aos="zoom-in"  data-aos-delay="700" data-aos-duration="600">
                                    <div className="list-custom-icon">
                                        <Image src={Kostenlos} alt="Icon Kostenlos" title="Kostenlos"/>
                                    </div>
                                    <List.Content>
                                        <List.Header>Kostenlos</List.Header>
                                        <List.Description>Vetero ist und bleibt kostenlos. Du kannst beliebig viele Deiner Oldtimer hinzufügen und Projekte dokumentieren.</List.Description>
                                    </List.Content>
                                </List.Item>

                            </List>

                            <Grid textAlign="center">
                                <Responsive minWidth={Responsive.onlyTablet.minWidth} as={Divider} hidden/>
                                <Grid.Column mobile={16} tablet={8} computer={16}>
                                    <Button as={Link} to="/functions" color="red">Alle Funktionen anschauen</Button>
                                </Grid.Column>
                                <Divider hidden/>
                            </Grid>

                        </Container>

                    </div>

                </div>

            </div>

            <Account
                loginPopupVisible={this.state.loginPopupVisible}
                loginPopupClose={() => this.setState({ loginPopupVisible: false })}
                accountStep="register"
            />
        </>
        )
    }
}

function mapStateToProps(state, ownProps){
    const { globalByComponent, account } = state;
    const { item  } = account || {
        item: {},
    }

    const { page: runtimepage, backFeedPost, backProject, backStory, backUser } = globalByComponent[
        'Runtimedata'
    ] || {}

    return {
        item,
        runtimepage,
        backFeedPost,
        backProject,
        backStory,
        backUser
    }
}

export default connect(mapStateToProps)(withRouter(Homepage));
