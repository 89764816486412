import React, { Component } from 'react'
import { Menu, Container, Dropdown, Image, Form, Grid, Divider, Input, Button, Responsive, Label, Icon } from 'semantic-ui-react';
import {RemoveScrollBar} from 'react-remove-scroll-bar';
import { zeroRightClassName } from 'react-remove-scroll-bar';
import Headroom from 'react-headroom';
import config from '../../config/config'
import './FilterSearch.scss';
import { connect } from 'react-redux';
import Select from 'react-select';
import { toggleProjectListView } from '../../actions/global';
import { resetProjectList } from '../../actions/project';
import { handleFilterInput, fetchVehicleData, fetchCountriesData, fetchModelData, fetchCount, handleUserInputBrand, handleUserInputCategory, handleUserInputModel, handleUserInputYearFrom, handleUserInputYearTo, handleUserInputCountry, handleUserInput, getCount, getResults } from '../../actions/filter';
import filterIcon from '../../images/icons/frontend/icon-filter.svg';
import activeFilterIcon from '../../images/icons/frontend/icon-active-filter.svg';
import galleryIcon from '../../images/icons/frontend/icon-kacheln.svg';
import ListIcon from '../../images/icons/frontend/icon-liste.svg';
import galleryIconActive from '../../images/icons/frontend/icon-kacheln-mo.svg';
import ListIconActive from '../../images/icons/frontend/icon-liste-mo.svg';


class ProjectFilter extends Component {

    state = {
        filterSearchIsOpen: false,
        dropDownValue: "popular"
    }

    componentDidMount = () => {
        const { dispatch, userInputCategory, userInputBrand, userInputModel, userInputYearFrom, userInputYearTo, userInputCountry } = this.props;
        document.getElementById('root').addEventListener('click', this.handleClick, false);
        dispatch(fetchCountriesData());
        dispatch(fetchVehicleData());
        dispatch(fetchCount(userInputCategory.value, userInputBrand.value, userInputModel.value, userInputYearFrom, userInputYearTo, userInputCountry));
    }

    componentWillUnmount = () => {
        document.getElementById('root').removeEventListener('click', this.handleClick, false);
    }

    handleClickOutside = () => {
        this.setState({filterSearchIsOpen: false})
    }

    toggleFilter = () => {
        this.setState({filterSearchIsOpen: !this.state.filterSearchIsOpen})
    }

    handleClick = (e) => {
        document.getElementById('root').addEventListener('click', this.handleClick, false);
        if(this.node.contains(e.target)){
            return;
        }

        this.handleClickOutside();
    }

    handleCategoryOnChange = (e) => {
        const { dispatch, userInputCategory, userInputBrand, userInputModel, userInputYearFrom, userInputYearTo, userInputCountry } = this.props;
        dispatch(handleUserInputCategory(e));
        dispatch(handleUserInputBrand(""));
        dispatch(handleUserInputModel(""));
        dispatch(fetchVehicleData(e.value));
        dispatch(fetchCount(e.value, userInputBrand.value, userInputModel.value, userInputYearFrom, userInputYearTo, userInputCountry.value));
    }

    handleBrandOnChange = (e) => {
        const { dispatch, userInputCategory, userInputBrand, userInputModel, userInputYearFrom, userInputYearTo, userInputCountry } = this.props;
        dispatch(handleUserInputBrand(e));
        dispatch(handleUserInputModel(""));
        dispatch(fetchModelData(userInputCategory.value, e.value));
        dispatch(fetchCount(userInputCategory.value, e.value, userInputModel.value, userInputYearFrom, userInputYearTo, userInputCountry.value));
    }

    handleModelOnChange = (e) => {
        const { dispatch, userInputCategory, userInputBrand, userInputModel, userInputYearFrom, userInputYearTo, userInputCountry } = this.props;
        dispatch(handleUserInputModel(e));
        dispatch(fetchCount(userInputCategory.value, userInputBrand.value ,e.value, userInputYearFrom, userInputYearTo, userInputCountry.value));
    }

    handleYearFromOnChange = (e) => {
        const { dispatch, userInputCategory, userInputBrand, userInputModel, userInputYearFrom, userInputYearTo, userInputCountry } = this.props;
        dispatch(handleUserInputYearFrom(e.target.value));
        dispatch(fetchCount(userInputCategory.value, userInputBrand.value, userInputModel.value, e.target.value, userInputYearTo, userInputCountry.value));
    }

    handleYearToOnChange = (e) => {
        const { dispatch, userInputCategory, userInputBrand, userInputModel, userInputYearFrom, userInputYearTo, userInputCountry } = this.props;
        dispatch(handleUserInputYearTo(e.target.value));
        dispatch(fetchCount(userInputCategory.value, userInputBrand.value , userInputModel.value, userInputYearFrom, e.target.value, userInputCountry.value));
    }

    handleCountryOnChange = (e) => {
        const { dispatch, userInputCategory, userInputBrand, userInputModel, userInputYearFrom, userInputYearTo, userInputCountry } = this.props;
        dispatch(handleUserInputCountry(e));
        dispatch(fetchCount(userInputCategory.value, userInputBrand.value, userInputModel.value, userInputYearFrom, userInputYearTo, e.value));
    }

    resetFilter = () => {
        //Reset all filter settings
        const { dispatch } = this.props;
        dispatch(handleUserInputCategory(""));
        dispatch(handleUserInputBrand(""));
        dispatch(handleUserInputModel(""));
        dispatch(handleUserInputYearFrom(""));
        dispatch(handleUserInputYearTo(""));
        dispatch(handleUserInputCountry(""));
        dispatch(fetchCount())
    }

    handleShowResults = () => {
        const { dispatch } = this.props;
        dispatch(resetProjectList());
        this.setState({filterSearchIsOpen: false})
    }

    //Show popular or newest
    handleChangeDropdown = (e, {value, text}) => {
        const { dispatch, order } = this.props;

        if (order !== value) {
            dispatch(handleFilterInput({order: value}));
            dispatch(resetProjectList());
        }
    }

    handleDeleteFilter = (filter) => {
        const { dispatch } = this.props;
        dispatch(resetProjectList());
        switch(filter){
            case "category":
                return dispatch(handleUserInputCategory())
            case "brandmodel":
                return (
                    dispatch(handleUserInputBrand()) && dispatch(handleUserInputModel())
                )
            case "yearfrom":
                return dispatch(handleUserInputYearFrom())
            case "yearTo":
                return dispatch(handleUserInputYearTo())
            case "country":
                return dispatch(handleUserInputCountry())
            default:
                return null
        }
    }

    handleProjectListView = (view) => {
        const { dispatch } = this.props;
        dispatch(toggleProjectListView(view));
    }
    
    render() {

        const { dispatch, filterVisible, projectCount, userInputBrand, userInputCategory, userInputModel, userInputYearFrom, userInputYearTo, userInputCountry, order, currentView } = this.props
        const { filterSearchIsOpen } = this.state;
        return (
            <div>
                <div className={filterSearchIsOpen ? "filter-overlay" : null}></div>
                <Headroom
                    disableInlineStyles
                    pinStart={66}
                    className={filterSearchIsOpen ? "filter-show" : null}
                >
                    <div className={zeroRightClassName + " headroom-content"}>
                        <div className="top-bar" ref={node => this.node = node}>
                            <Menu
                                secondary
                                className="topheader-sort"
                            >
                                <Container>
                                    <Menu.Item position="left">
                                        <Dropdown
                                            inline
                                            options={config.getOrdersDropdown()}
                                            value={order}
                                            onChange={this.handleChangeDropdown}
                                        />
                                    </Menu.Item>
                                    <Menu.Item className="gallery-view" onClick={() => this.handleProjectListView("gallery")}>
                                        <Image alt="Icon Kacheln" src={currentView === "gallery" ? galleryIconActive : galleryIcon} />
                                    </Menu.Item>
                                    <Menu.Item className="list-view" onClick={() => this.handleProjectListView("list")}>
                                        <Image alt="Icon Liste" src={currentView === "list" ? ListIconActive : ListIcon}/>
                                    </Menu.Item>
                                    <div className="topheader-divider"></div>
                                    <Menu.Item className="toggle-filter" onClick={this.toggleFilter}>
                                        
                                        <Image alt="Icon Filter" src={filterSearchIsOpen ? activeFilterIcon : filterIcon}/>
                                        <span className={filterSearchIsOpen ? "active-filter" : null}>Filter</span>
                                    </Menu.Item>
                                </Container>
                            </Menu>
                            {filterSearchIsOpen &&
                                    <div className={"filter-options"}>
                                        <RemoveScrollBar/>
                                    <Responsive {...Responsive.onlyMobile}>
                                        <Container>
                                            <Form>
                                                <Form.Field>
                                                    <label>Kategorie</label>
                                                    <Select menuPlacement="auto"
                                                        className="inline-select"
                                                        value={userInputCategory}
                                                        onChange={this.handleCategoryOnChange}
                                                        options={config.getScopes()}
                                                        placeholder="Auswählen..."
                                                        isSearchable={ false }
                                                        noOptionsMessage={() => "Keine Ergebnisse gefunden"}
                                                    />
                                                </Form.Field>
                                                <Form.Field>
                                                    <label>Marke</label>
                                                    <Select menuPlacement="auto"
                                                        className="inline-select"
                                                        value={userInputBrand}
                                                        onChange={this.handleBrandOnChange}
                                                        options={this.props.vehicle}
                                                        placeholder="Auswählen..."
                                                        isSearchable={ false }
                                                        noOptionsMessage={() => "Keine Ergebnisse gefunden"}
                                                    />
                                                </Form.Field>
                                                <Form.Field inline>
                                                    <label>Modell</label>
                                                    <Select menuPlacement="auto"
                                                        className="inline-select"
                                                        value={userInputModel}
                                                        onChange={this.handleModelOnChange}
                                                        options={this.props.vehicleModel}
                                                        placeholder="Auswählen..."
                                                        noOptionsMessage={() => "Keine Ergebnisse gefunden"}
                                                        isDisabled={!userInputBrand && true }
                                                        isSearchable={ false }
                                                    />
                                                </Form.Field>

                                                <Grid>
                                                        <Grid.Column width={8}>
                                                            <Form.Field>
                                                                <label>Baujahr ab</label>
                                                                <Input
                                                                    value={this.props.userInputYearFrom}
                                                                    onChange={this.handleYearFromOnChange}
                                                                    type="number"
                                                                />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                        <Grid.Column width={8}>
                                                            <Form.Field>
                                                                <label>Baujahr bis</label>
                                                                <Input

                                                                    value={this.props.userInputYearTo}
                                                                    onChange={this.handleYearToOnChange}
                                                                />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                </Grid>

                                                <Form.Field>
                                                    <label>Land(Marke)</label>
                                                    <Select menuPlacement="auto"
                                                        className="inline-select"
                                                        value={this.props.userInputCountry}
                                                        onChange={this.handleCountryOnChange}
                                                        options={this.props.countries}
                                                        placeholder="Auswählen..."
                                                        noOptionsMessage={() => "Keine Ergebnisse gefunden"}
                                                        isSearchable={ false }
                                                    />
                                                </Form.Field>

                                            </Form>

                                            <Divider hidden/>

                                            <div className="buttons-center">
                                                <Button basic color="red" onClick={() => this.resetFilter()}>Zurücksetzen</Button>
                                                <Button color="red" onClick={this.handleShowResults}>
                                                    {this.props.projectCount.count}
                                                    {parseInt(this.props.projectCount.count) > 1 || this.props.projectCount.count === "0" ? " Projekte" : " Projekt"}
                                                </Button>
                                            </div>

                                        </Container>

                                    </Responsive>

                                        {/*Desktop*/}
                                        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                                            <Grid columns="equal" className="inline-searchform">
                                                <Grid.Column>
                                                    <Form>
                                                        <Form.Field inline>
                                                            <label>Fahrzeugart</label>
                                                            <Select menuPlacement="auto"
                                                                className="inline-select"
                                                                value={this.props.userInputCategory}
                                                                onChange={this.handleCategoryOnChange}
                                                                options={config.getScopes()}
                                                                placeholder="Auswählen..."
                                                                noOptionsMessage={() => "Keine Ergebnisse gefunden"}
                                                            />
                                                        </Form.Field>
                                                        <Form.Field inline>
                                                            <label>Marke</label>
                                                            <Select menuPlacement="auto"
                                                                className="inline-select"
                                                                value={this.props.userInputBrand}
                                                                onChange={this.handleBrandOnChange}
                                                                options={this.props.vehicle}
                                                                name="brand"
                                                                placeholder="Auswählen..."
                                                                noOptionsMessage={() => "Keine Ergebnisse gefunden"}
                                                            />
                                                        </Form.Field>
                                                        <Form.Field inline>
                                                            <label>Modell</label>
                                                            <Select menuPlacement="auto"
                                                                className="inline-select"
                                                                value={this.props.userInputModel}
                                                                onChange={this.handleModelOnChange}
                                                                options={this.props.vehicleModel}
                                                                placeholder="Auswählen..."
                                                                noOptionsMessage={() => "Keine Ergebnisse gefunden"}
                                                                isDisabled={!userInputBrand && true }
                                                            />
                                                        </Form.Field>
                                                    </Form>
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <Form>
                                                        <Form.Field inline>
                                                            <label>Baujahr ab</label>
                                                            <Input
                                                                className="inline-input"
                                                                value={this.props.userInputYearFrom}
                                                                onChange={this.handleYearFromOnChange}
                                                                type="number"
                                                            />
                                                        </Form.Field>
                                                        <Form.Field inline>
                                                            <label>Baujahr bis</label>
                                                            <Input
                                                                className="inline-input"
                                                                value={this.props.userInputYearTo}
                                                                onChange={this.handleYearToOnChange}
                                                            />
                                                        </Form.Field>
                                                        <Form.Field inline>
                                                            <label>Land(Marke)</label>
                                                            <Select menuPlacement="auto"
                                                                className="inline-select"
                                                                value={this.props.userInputCountry}
                                                                onChange={this.handleCountryOnChange}
                                                                options={this.props.countries}
                                                                placeholder="Auswählen..."
                                                                noOptionsMessage={() => "Keine Ergebnisse gefunden"}
                                                            />
                                                        </Form.Field>
                                                    </Form>
                                                </Grid.Column>
                                            </Grid>

                                            <Divider hidden/>
                                            <div className="buttons-center">
                                                <Button.Group >
                                                    <Button basic color="red" onClick={() => this.resetFilter()}>Zurücksetzen</Button>
                                                    <Button color="red" onClick={this.handleShowResults}>
                                                        {this.props.projectCount.count}
                                                        {parseInt(this.props.projectCount.count) > 1 || this.props.projectCount.count === "0" ? " Projekte" : " Projekt"}                                                    </Button>
                                                </Button.Group>
                                            </div>

                                        </Responsive>
                                    </div>
                                }
                        </div>
                    </div>
                </Headroom>

                <Container text>

                    <div className="filter-labels">
                        {
                            !userInputCategory && 
                            !userInputBrand &&
                            !userInputYearFrom &&
                            !userInputYearTo &&
                            !userInputCountry &&
                            <Label as='a' icon={<Icon name='delete' />} style={{opacity: 0}} content="Label"/>
                        }
                        {/* Search tags */}
                        {userInputCategory &&
                            <Label as='a' onClick={() => this.handleDeleteFilter('category')}>
                                {userInputCategory.label}
                                <Icon name='delete'/>
                            </Label>
                        }

                        {userInputBrand &&
                            <Label as='a' onClick={() => this.handleDeleteFilter('brandmodel')}>
                                {userInputBrand.label} {userInputModel.label}
                                <Icon name='delete' />
                            </Label>
                        }

                        {userInputYearFrom &&
                            <Label as='a' onClick={() => this.handleDeleteFilter('yearfrom')}>
                                Ab: {userInputYearFrom}
                                <Icon name='delete' />
                            </Label>
                        }

                        {userInputYearTo &&
                            <Label as='a' onClick={() => this.handleDeleteFilter('yearTo')}>
                                Bis: {userInputYearTo}
                                <Icon name='delete' />
                            </Label>
                        }

                        {userInputCountry &&
                            <Label as='a' onClick={() => this.handleDeleteFilter('country')}>
                                {userInputCountry.label}
                                <Icon name='delete' />
                            </Label>
                        }

                    </div>

                </Container>

            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { globalByComponent } = state
    const { currentView } = globalByComponent[
        'projectList'
    ] || {
        currentView: "gallery"
    }
    const { order, filterVisible, vehicle, vehicleModel, countries, projectCount, userInputBrand, userInputCategory, userInputModel, userInputYearFrom, userInputYearTo, userInputCountry, brand, model, variant, yearFrom, yearTo, country, filterResults } = globalByComponent[
        'Filter'
    ] || {
        order: null,
        filterVisible: false,
        vehicle: "",
        vehicleModel: "",
        countries: "",
        projectCount: "",
        userInputBrand: "",
        userInputCategory: "",
        userInputModel: "",
        userInputYearFrom: "",
        userInputYearTo: "",
        userInputCountry: "",
        filterResults: "",
    }
    return {
        order: (order ? order : config.PROJECT_DEFAULT_ORDER),
        filterVisible,
        vehicle,
        vehicleModel,
        countries,
        projectCount,
        userInputBrand: (userInputBrand ? userInputBrand : ''),
        userInputCategory: (userInputCategory ? userInputCategory : ''),
        userInputModel: (userInputModel ? userInputModel : ''),
        userInputYearFrom: (userInputYearFrom ? userInputYearFrom : ''),
        userInputYearTo: (userInputYearTo ? userInputYearTo : ''),
        userInputCountry: (userInputCountry ? userInputCountry : ''),
        filterResults,
        currentView
    }

}

export default connect(mapStateToProps)(ProjectFilter);
