import config from '../config/config'
import { createAction } from 'redux-api-middleware';

export const API_COMMENTS_REQUEST = 'API_COMMENTS_REQUEST';
export const API_COMMENTS_SUCCESS = 'API_COMMENTS_SUCCESS';
export const API_COMMENTS_FAILURE = 'API_COMMENTS_FAILURE';
export const API_POST_COMMENT_REQUEST = 'API_POST_COMMENT_REQUEST';
export const API_POST_COMMENT_SUCCESS = 'API_POST_COMMENT_SUCCESS';
export const API_POST_COMMENT_FAILURE = 'API_POST_COMMENT_FAILURE';
export const NEW_COMMENT = 'NEW_COMMENT';
export const CHANGE_COMMENT = 'CHANGE_COMMENT';
export const API_UPDATE_COMMENT_REQUEST = 'API_UPDATE_COMMENT_REQUEST';
export const API_UPDATE_COMMENT_SUCCESS = 'API_UPDATE_COMMENT_SUCCESS';
export const API_UPDATE_COMMENT_FAILURE = 'API_UPDATE_COMMENT_FAILURE';


export function changeComment(id, inputText) {
    return {
        type: CHANGE_COMMENT,
        meta: {
            id,
        },
        payload: {
            inputText,
        }
    }
}

export function updateComment(item) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/comment/${item.id}`,
        method: 'PUT',
        body: JSON.stringify({
            comment: {
                ...item,
                text: item.inputText,
            },
        }),
        types: [
            {type: API_UPDATE_COMMENT_REQUEST, meta: { id: item.id }},
            {type: API_UPDATE_COMMENT_SUCCESS, meta: { id: item.id }},
            {type: API_UPDATE_COMMENT_FAILURE, meta: { id: item.id }},
        ]
    })
}

export function newComment(text, parent) {
    return {
        type: NEW_COMMENT,
        meta: {
            parent,
        },
        payload: {
            inputText: text,
        }
    }
}

export function postComment(text, parent) {
    var p = parent.split('::');

    return createAction({
        endpoint: `${config.API_HOST}/v1/comment`,
        method: 'POST',
        body: JSON.stringify({
            [p[0]]: p[1],
            text,
        }),
        types: [
            {type: API_POST_COMMENT_REQUEST, meta: { parent }},
            {type: API_POST_COMMENT_SUCCESS, meta: { parent }},
            {type: API_POST_COMMENT_FAILURE, meta: { parent }},
        ]
    })
}

export function fetchNextComments(parent, ids, baseids) {
    var query = [];

    ids.map(function(id) {
        query.push('parentcomment[]='+id);
    })
    baseids.map(function(id) {
        query.push('basecomment[]='+id);
    })

    query = query.join('&');

    return createAction({
        endpoint: `${config.API_HOST}/v1/comment?${query}`,
        method: 'GET',
        types: [
            {type: API_COMMENTS_REQUEST, meta: { parent }},
            {type: API_COMMENTS_SUCCESS, meta: { parent }},
            {type: API_COMMENTS_FAILURE, meta: { parent }},
        ]
    })
}

export function fetchPreviousComments(parent, id) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/comment?loadcomment[]=${id}`,
        method: 'GET',
        types: [
            {type: API_COMMENTS_REQUEST, meta: { parent }},
            {type: API_COMMENTS_SUCCESS, meta: { parent }},
            {type: API_COMMENTS_FAILURE, meta: { parent }},
        ]
    })
}

export function fetchComments(parent) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/comment?basecomment[]=${parent}`,
        method: 'GET',
        types: [
            {type: API_COMMENTS_REQUEST, meta: { parent }},
            {type: API_COMMENTS_SUCCESS, meta: { parent }},
            {type: API_COMMENTS_FAILURE, meta: { parent }},
        ]
    })
}

function shouldFetchComments(state, id) {
    const comments = state.commentsByParent[id]
    if (
        !comments ||
        ((comments.isFetching || comments.isFailed) && Date.now() - comments.lastFetch > config.FETCH_TIMEOUT) ||
        // TODO: May be removed if update of (edited) comments is implemented
        (!comments.isFetching && Date.now() - (comments.lastUpdated ? comments.lastUpdated : 0) > config.FETCH_AGAIN)
    ) {
        return true
    }
    return false
}

export default function fetchCommentsIfNeeded(id) {
    return (dispatch, getState) => {
        if (shouldFetchComments(getState(), id)) {
            return dispatch(fetchComments(id))
        }
    }
}
