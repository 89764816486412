import fetch from 'cross-fetch';
import config from '../config/config';

export const GET_VEHICLE_BRAND = "GET_VEHICLE_BRAND";
export const GET_VEHICLE_MODEL = "GET_VEHICLE_MODEL";
export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_COUNT = "GET_COUNT";
export const GET_THREADS_COUNT = "GET_THREADS_COUNT";
export const HANDLE_INPUT_CATEGORY = "HANDLE_INPUT_CATEGORY";
export const HANDLE_INPUT_BRAND = "HANDLE_INPUT_BRAND";
export const HANDLE_INPUT_MODEL = "HANDLE_INPUT_MODEL";
export const HANDLE_INPUT_YEAR_FROM = "HANDLE_INPUT_YEAR_FROM";
export const HANDLE_INPUT_YEAR_TO = "HANDLE_INPUT_YEAR_TO";
export const HANDLE_INPUT_COUNTRY = "HANDLE_INPUT_COUNTRY";
export const HANDLE_FORUM_FILTER = "HANDLE_FORUM_FILTER";
export const HANDLE_FILTER_TAGS = "HANDLE_FILTER_TAGS"
export const HANDLE_PROFESSIONALS_FILTER_TAGS = "HANDLE_PROFESSIONALS_FILTER_TAGS";
export const HANDLE_PROFESSIONAL_FILTER = "HANDLE_PROFESSIONAL_FILTER";
export const HANDLE_PROFESSIONAL_FILTER_TAGS = "HANDLE_PROFESSIONAL_FILTER_TAGS";
export const GET_RESULTS = "GET_RESULTS";

// TODO: Save all input fields in one object
// export const HANDLE_INPUT = "HANDLE_INPUT";
export const HANDLE_INPUT_GENERIC = "HANDLE_INPUT_GENERIC";


export function fetchVehicleData(scope, all){
    if(!scope) {scope = 0}

    if(all) all = `&all=1`
    else all = '';

    return dispatch => {
        fetch(`${config.API_HOST}/v1/filter/brand?scope=` + scope + all)
            .then(response => response.json())
            .then(json => {
                dispatch(getVehicleBrand(json))
            })
    }
}

export function fetchModelData(scope, brand){
    return dispatch => {
        fetch(`${config.API_HOST}/v1/filter/vehicle?scope=`+ (scope ? scope : '') + '&brand=' + brand)
            .then(response => response.json())
            .then(json => {
                dispatch(getVehicleModel(json))
            })
    }
}

export function fetchCountriesData(){
    return dispatch => {
        fetch(`${config.API_HOST}/v1/filter/country`)
            .then(response => response.json())
            .then(json => {
                dispatch(getCountries(json))
            })
    }
}

export function fetchCount(scope, brand, vehicle, yearFrom, yearTo ,country){
    //Default values
    if(!scope) scope = ''
    if(!brand) brand = ''
    if(!vehicle) vehicle = ''
    if(!yearFrom) yearFrom = ''
    if(!yearTo) yearTo = ''
    if(!country) country = ''

    return dispatch => {
        fetch(`${config.API_HOST}/v1/project?count=1&project_filter[scope]=${scope}&project_filter[brand]=${brand}&project_filter[vehicle]=${vehicle}&project_filter[vehicleYearSince]=${yearFrom}&project_filter[vehicleYearUntil]=${yearTo}&project_filter[brandCountrycode]=${country}`)
        .then(response => response.json())
            .then(json => {
                dispatch(getCount(json))
            })
    }
}

export function fetchThreadsCount(scope, brand, vehicle, tags, forum){
    //Default values

    if(scope) scope = `&forumthread_filter[scope]=${scope.value}`
    else scope = '';

    if(brand) brand = `&forumthread_filter[brand]=${brand.value}`
    else brand = '';

    if(vehicle) vehicle = `&forumthread_filter[vehicle]=${vehicle.value}`
    else vehicle = '';
    
    if(tags && tags.length > 0) {
        let arr = [];
        
        tags.map(item => arr.push(item.value));
        tags = `&forumthread_filter[categories]=${arr}`;        
    }
    else tags = ''

    if(forum) forum = `&forumthread_filter[forum]=${forum.value}`
    

    return dispatch => {
        fetch(`${config.API_HOST}/v1/forum?count=1${scope}${brand}${vehicle}${tags}${forum}`)
        .then(response => response.json())
            .then(json => {
                dispatch(getThreadsCount(json))
            })
    }
}

export function getVehicleBrand(data){
    return {
        type: GET_VEHICLE_BRAND,
        component: 'Filter',
        data: {
            vehicle: data
        }
    }
}

export function getVehicleModel(data){
    return {
        type: GET_VEHICLE_MODEL,
        component: 'Filter',
        data: {
            vehicleModel: data
        }
    }
}

export function getCountries(data){
    return {
        type: GET_COUNTRIES,
        component: 'Filter',
        data: {
            countries: data
        }
    }
}

export function getCount(count) {
    return {
        type: GET_COUNT,
        component: 'Filter',
        data: {
            projectCount: count
        }
    }
}

export function getThreadsCount(count){
    return {
        type:  GET_THREADS_COUNT,
        component: 'ForumFilter',
        data: {
            count
        }
    }
}

//Handle user input
export function handleUserInputCategory(input){
    return {
        type: HANDLE_INPUT_CATEGORY,
        component: 'Filter',
        data: {
            userInputCategory: input
        }
    }
}

export function handleUserInputBrand(input){
    return {
        type: HANDLE_INPUT_BRAND,
        component: 'Filter',
        data: {
            userInputBrand: input
        }
    }
}

export function handleUserInputModel(input){
    return {
        type: HANDLE_INPUT_MODEL,
        component: 'Filter',
        data: {
            userInputModel: input
        }
    }
}

export function handleUserInputYearFrom(input){
    return {
        type: HANDLE_INPUT_YEAR_FROM,
        component: 'Filter',
        data: {
            userInputYearFrom: input
        }
    }
}

export function handleUserInputYearTo(input){
    return {
        type: HANDLE_INPUT_YEAR_TO,
        component: 'Filter',
        data: {
            userInputYearTo: input
        }
    }
}

export function handleUserInputCountry(input){
    return {
        type: HANDLE_INPUT_COUNTRY,
        component: 'Filter',
        data: {
            userInputCountry: input
        }
    }
}

export function handleFilterInput(input){
    return {
        type: HANDLE_INPUT_GENERIC,
        component: 'Filter',
        payload: input,
    }
}

export function getResults(scope='', brand='', vehicle='', yearFrom='', yearTo='' ,country=''){
    return dispatch => {
        fetch(`${config.API_HOST}/v1/project?project_filter[scope]=${scope}&project_filter[brand]=${brand}&project_filter[vehicle]=${vehicle}&project_filter[vehicleYearSince]=${yearFrom}&project_filter[vehicleYearUntil]=${yearTo}&project_filter[brandCountrycode]=${country}`)
            .then(response => response.json())
            .then(json => {
                dispatch(showResults(json))
            })
    }
}

export function getForumResults(scope, brand, vehicle, tags){

    if(scope) scope = `forumthread_filter[scope]=${scope.value}`
    else scope = '';

    if(brand) brand = `&forumthread_filter[brand]=${brand.value}`
    else brand = '';

    if(vehicle) vehicle = `&forumthread_filter[vehicle]=${vehicle.value}`
    else vehicle = '';
    
    if(tags && tags.length > 0) {
        let arr = [];
        tags.map(item => arr.push(item.value));
        tags = `&forumthread_filter[categories]=${arr}`
    }
    else tags = ''

    return dispatch => {
        fetch(`${config.API_HOST}/v1/forum?${scope}${brand}${vehicle}${tags}`)
            .then(response => response.json())
            .then(json => {
                dispatch(showForumResults(json))
            })
    } 
}

export function showResults(results) {
    return {
        type: GET_RESULTS,
        component: 'Filter',
        data: {
            filterResults: results.map(project => (
                project.project.id
            ))
        }
    }
}

export function showForumResults(results){
    return {
        type: GET_RESULTS,
        component: 'Filter',
        data: {
            forumFilterResults: results.map(thread => (
                thread.item.id
            ))
        }
    }
}

export function handleForumFilter(scope, brand, model, categories){    
    return {
        type: HANDLE_FORUM_FILTER,
        component: 'ForumFilter',
        payload: {
            scope,
            brand,
            model,
            categories
        }
    }
}

export function handleForumTopicFilter(forum){
    return {
        type: HANDLE_FORUM_FILTER,
        component: 'ForumFilter',
        payload: {
            forum
        }
    }
}

export function handleFilterTags(inputTags) {
    return {
        type: HANDLE_FILTER_TAGS,
        component: 'ForumFilter',
        data: {
            inputTags
        }
    }
}

export function handleProfessionalsFilter(inputTags) {
    return {
        type: HANDLE_PROFESSIONALS_FILTER_TAGS,
        component: 'ProfessionalFilter',
        data: {
            inputTags
        }
    }
}

export function handleProfessionalFilter(type, scope, city, coords, radius, currentCity){    
    return {
        type: HANDLE_PROFESSIONAL_FILTER,
        component: 'ProfessionalFilter',
        payload: {
            type,
            scope,
            city,
            coords,
            radius,
            currentCity
        }
    }
}

export function handleProfessionalFilterTags(inputTags) {
    return {
        type: HANDLE_PROFESSIONAL_FILTER_TAGS,
        component: 'ProfessionalFilter',
        data: {
            inputTags
        }
    }
}

export function getProfessionalResult(types, scope, brand, vehicle, tags, minDistance, maxDistance){

    if(types && types.length > 0) {
        let arr = [];
        types.map(item => arr.push(item.value));
        types = `&professional_filter[types]=${arr}`
    }
    else types = '';

    if(scope) scope = `professional_filter[scopes]=${scope.value}`
    else scope = '';

    if(brand) brand = `&professional_filter[brand]=${brand.value}`
    else brand = '';

    if(vehicle) vehicle = `&professional_filter[vehicle]=${vehicle.value}`
    else vehicle = '';
    
    if(tags && tags.length > 0) {
        let arr = [];
        tags.map(item => arr.push(item.value));
        tags = `&professional_filter[categories]=${arr}`
    }
    else tags = ''

    let min;
    let max;
    if(maxDistance){
        min = `&professional_filter[minDistance]=${minDistance}`
        max = `&professional_filter[maxDistance]=${maxDistance}`
    }
    else {
        min = '';
        max = '';
    }

    return dispatch => {
        fetch(`${config.API_HOST}/v1/professional?${types}${scope}${brand}${vehicle}${tags}${min}`)
            .then(response => response.json())
            .then(json => {
                dispatch(showProfessionalResults(json))
            })
    } 
}

export function showProfessionalResults(results){
    return {
        type: GET_RESULTS,
        component: 'Filter',
        data: {
            professionalFilterResults: results.map(professional => (
                professional.item.id
            ))
        }
    }
}
