import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  Header,
  Container,
  Grid,
  Divider,
  Image,
  Button,
  Responsive,
  List,
} from "semantic-ui-react";
import { connect } from "react-redux";
import {
  footerActiveItem,
  handleContainer,
  handleHeaderItems,
  setRuntime
} from "../../actions/global";
import './functions.scss';
import HeaderImage from "../../components/HeaderImage/HeaderImage";
import Account from "../../components/account/Account";
import IconBox from '../../components/IconBox/IconBox';
import CustomFrontendNavigation from '../../components/layout/CustomFrontendNavigation';

import BeforeAfter from '../../images/icons/about/story/icon-element-before-after.svg';
import Video from '../../images/icons/about/story/icon-element-video.svg';
import TextImage from '../../images/icons/about/story/icon-element-text-image.svg';
import ImageIcon from '../../images/icons/about/story/icon-element-picture.svg';
import GalleryIcon from '../../images/icons//about/story/icon-element-gallery.svg';
import TextIcon from '../../images/icons/about/story/icon-element-text.svg';

import HeaderImg from '../../images/funktionen-header.jpg'

//Videos
import StoryVid from '../../images/about-page/functions/story-bearbeiten1.mp4';
import StoryElementVid from '../../images/about-page/functions/story-element.mp4';
import { Helmet } from "react-helmet";


const iconBoxItemsClosing = [
    {
        img: {src: BeforeAfter, alt: "Icon Vorher/Nachher"}, 
        content: { 
            header: "Vorher / Nachher" ,
            description: "Perfekt geeignet um ein Teil vor und nach einer Instandsetzung zu zeigen. Im Idealfall zeigen beide Bilder den gleichen Ausschnitt und haben den gleichen Aufnahmewinkel. Ein kurzer Text vervollständigt die Präsentation.."
        } 
    },
    {
        img: {src: Video, alt: "Icon Video"}, 
        content: { 
            header: "Video",
            description: "Wie der Name bereits sagt, kann damit ein Video zur Story hinzugefügt werden. Das Video erscheint dann recht groß in der Story."
        } 
    },
    {
        img: {src: TextImage, alt: "Icon Text/Bild"}, 
        content: { 
            header: "Text / Bild ",
            description: "Text und Bild teilen sich die Breite. Der Text sollte nicht zu kurz sein (6 bis 14 Zeilen), da das Element sonst keine gute Wirkung erzielt."
        } 
    },
    {
        img: {src: ImageIcon, alt: "Icon Bild"}, 
        content: { 
            header: "Bild",
            description: "Wie Video – nur mit eben ein Bild:-)"
        } 
    },
    {
        img: {src: GalleryIcon, alt: "Icon Galerie"}, 
        content: { 
            header: "Galerie",
            description: "Eine Galerie besteht aus mehreren Bildern, von denen bis zu 6 direkt in der Story, zusammen mit der Anzahl der verbleibenden Bilder (z. B. +10), angezeigt werden. Jedes Bild der Galerie kann optional einen kurzen Text enthalten. In der Story kann die Galerie durchgeblättert werden. "
        } 
    },
    {
        img: {src: TextIcon, alt: "Icon Text"}, 
        content: { 
            header: "Text",
            description: "Ein wichtiger Baustein einer Story sind Texte. Deshalb solltest Du dieses Inhaltselement wirklich nutzen. Eine Überschrift ist hier Pflicht. "
        } 
    },
]


class StoryInfo extends Component {
  state = {
    loginPopupVisible: false,
  };

  componentDidMount = () => {
    const { dispatch } = this.props;
    window.scrollTo(0, 0);
    dispatch(footerActiveItem('footermenu'))
    dispatch(
      handleContainer("functions", "Storys", null, null, null, null, {
        description:
          "Mit Vetero Storys kannst Du die Restauration Deines Oldtimers von A bis Z erzählen. Du kannst daher in beliebig vielen Storys jede Phase einer Restauration, mit allen Höhen und Tiefen, für die Nachwelt erhalten.",
      })
    );
    dispatch(handleHeaderItems("functions"));
    // Set page
    dispatch(setRuntime({
        page: 'functions'
    }));
  };

  componentWillUnmount = () => {
    const { dispatch } = this.props;

    dispatch(setRuntime({
        backFunctions: {page: 'functions', path: this.props.location, scroll: window.pageYOffset},
    }));
}

  render() {
    const { item } = this.props;

    return (
      <div className="functions-page detail-page">

            <Helmet>
                <title>Vetero Storys - Geschichten zu Oldtimer-Restaurationen und mehr</title>
            </Helmet>

            <HeaderImage src={HeaderImg} overlay>
                <Grid>
                    <Grid.Column width={16} textAlign="center">
                        <Header as="h2">Vetero Funktionen</Header>
                        <p>Storys zum Fahrzeug</p>
                    </Grid.Column>
                </Grid>
            </HeaderImage>

            <CustomFrontendNavigation title="Storys"/>

          <Container>

            <Responsive {...Responsive.onlyMobile} as={Header}>
                <Divider hidden/>
                <h2>Storys</h2>
                <Divider/>
            </Responsive>

            <div className="introduction-content">
                <p>
                  <strong>
                    Storys sind das am meisten unterschätzte Werkzeug in Vetero. Mit Storys kannst Du zum Beispiel die Fortschritte einer Restauration, eine Rallye oder andere 
                    Ereignisse bis ins kleinste Detail in Text, Bild und Video dokumentieren. 
                  </strong>
                </p>

                <p>
                    Storys sind kleinere oder große Geschichten, die eines Deiner Fahrzeuge betreffen. In der Regel handelt es sich um einen Teil einer Restauration, eine Tour oder etwas anderes erwähnenswertes. 
                    Storys eignen sich auch hervorragend, um Wissen oder Tipps und Tricks weiterzugeben.
                </p>

                <Grid>
                    <Grid.Column width={16} textAlign="center">
                        <Divider hidden/>
                        <video autoPlay loop>
                            <source src={StoryVid} type="video/mp4" />
                        </video>
                        <Divider hidden/>
                    </Grid.Column>
                </Grid>

                <p>
                    Eine Story gehört dabei immer zu einem Fahrzeug. Du musst also zuerst einen Deiner Oldtimer in Vetero anlegen, um für diesen Storys erstellen zu können. Man sollte sich etwas Zeit nehmen, Bilder und Videos sammeln und diese zusammen mit ernsten oder lustigen Texten in die Story einfließen lassen.  
                </p>
                <p>  
                    Du solltest Dir auf jeden Fall ein gutes „Hauptbild“ im Querformat aussuchen. Beim Anlegen einer Story wirst Du danach gefragt. Das Hauptbild repräsentiert die Story an verschiedenen Stellen in Vetero und ist eine Pflichtangabe. Übrigens: Nachdem Du „Story erstellen“ gewählt hast, ist diesen nicht veröffentlicht. Das kannst Du nach dem Hinzufügen weiterer Inhalte tun.
                </p>
                <p>
                    Rom wurde auch nicht an einem Tag gebaut. Du kannst eine Story daher jederzeit mit den unten aufgeführten Inhaltselementen erweitern um z. B. den Fortschritt einer Motorrevision zu dokumentieren. Bereits bestehende Inhalte können natürlich korrigiert, gelöscht oder gegen andere ersetzt werden.
                </p>
                <p>
                    Damit das alles schnell geht und am Ende gut aussieht, haben wir für wichtige Inhalte eigene Werkzeuge (Inhaltselemente) entwickelt. Diese kannst Du ganz einfach und auch mehrfach an verschiedenen Positionen einer Story hinzufügen.
                </p>

            </div>
          </Container>
        
        
        <div className="iconbox-wrapper closing-section">
            <Container>
                <Header as="h2">Inhaltselemente für Storys</Header>
                <Divider hidden/>
                <IconBox items={iconBoxItemsClosing}/>
            </Container>
        </div>

        <Container textAlign="center">
            <Divider hidden/>
            <video autoPlay loop>
                <source src={StoryElementVid} type="video/mp4" />
            </video>            
            <Divider hidden/>
        </Container>

        <Container className="closing-text detail-page">
            <span>Tipp</span>
            <List>
                <List.Item>1. Eins Story sollte Du offline vorbereiten</List.Item>
                <List.Item>2. Du kannst bei jedem Inhaltselement optional eine Überschrift und einen einleitenden Text angeben. Richtig eingesetzt, kann ein einzelnes Inhaltselement daher schon ein großer Teil einer Geschichte sein. In den meisten Fällen ist es sinnvoll vor dem Hauptelement eine passende Einleitung zu schreiben.</List.Item>
            </List>

            <p>
                Übrigens. Es gibt den VRS (Vetero-Ranking-Score), der sich unter anderem aus Deinen Story-Aktivitäten berechnet. Je „aktiver“ Du bist und auch abhängig von den gewählten Elementen oder Inhalten (Texte und Videos bringen mehr Punkte), desto besser der VRS. 
                Der Wert des VRS hat Einfluss auf die Platzierung des betreffenden Fahrzeugs in „Oldtimer / Projekte“.
            </p>

            <Divider hidden/>

            <Header as="h3">Story veröffentlichen</Header>

            <p>
                Nachdem die Story angelegt und mit einigen Inhaltselementen bestückt wurde, solltest Du diese veröffentlichen. Solange eine Story noch nicht veröffentlicht wurde, kann sie von anderen nicht gesehen werden.  Über unveröffentlichten Storys wird immer der Button  „Diese Story veröffentlichen“ angezeigt. 
            </p>

        </Container>

        <Grid>
            <Divider hidden/>
            {item && item.id ? (
                <Grid.Column width={16} textAlign="center">
                    <Button as={Link} color="red" to="/backend/new_project/firststep">
                        Starte jetzt kostenlos dein Projekt
                    </Button>
                </Grid.Column>
                ) : (
                <Grid.Column width={16} textAlign="center">
                    <Button
                        color="red"
                        onClick={() => this.setState({ loginPopupVisible: true })}
                    >
                        Jetzt kostenlos anmelden
                    </Button>
                    <Divider hidden/>
                </Grid.Column>
            )}
            <Divider hidden/>
        </Grid>

        <Account
          loginPopupVisible={this.state.loginPopupVisible}
          loginPopupClose={() => this.setState({ loginPopupVisible: false })}
          accountStep="register"
        />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { account } = state;
  const { item } = account || {
    item: {},
  };

  return {
    item,
  };
}

export default connect(mapStateToProps)(withRouter(StoryInfo));
