import React, { Component, Suspense } from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import { connect } from 'react-redux';
import GTM from 'react-tag-manager'
import Webpush from 'webpush-client'
import { Container, Loader, Button, Header} from 'semantic-ui-react';
import { showFooter, fetchMetadata, setRuntime, pwaInstallBanner } from './actions/global';
import { resetStore, fetchAccount, fetchUserNotificationsIfNeeded } from './actions/account';
import Modal from 'react-modal';
import MainHeader from './components/layout/Header/MainHeader';
import DashboardHeader from './components/layout/Header/DashboardHeader';
import Footer from './components/layout/Footer/footer';
import ErrorBoundary from './ErrorBoundary';
import config from './config/config';
import CustomPopup from './components/CustomPopup/CustomPopup';
//import Notfound from './pages/404';
import FrontendRoutes from './routes/frontendRoutes';
import LogoVetero from './images/vetero-bildmarke.svg';
// import AddToHomescreen from 'react-add-to-homescreen';
import AddToHomeScreen from './components/AddToHomeScreen/AddToHomeScreen';
import SWUpdater from './SWUpdater';
import ConsentBanner from './components/ConsentBanner/ConsentBanner'
import BackendRoutes from './routes/backendRoutes';

// const BackendRoutes = React.lazy(() => import('./routes/backendRoutes'));
import Ping from './Ping';

/**
* Initialize app
*/
class App extends Component {

    state = {
        reload: false,
        scrollToPosition: null,
        scroller: null,
        reloadButton: false,
        homeScreenBannerShow: false,
        deferredPrompt: "",
        athsPopup: false
    }
    webpush = null;

    constructor(props) {
        super(props);
        const { dispatch } = this.props;

        //this.ping = this.ping.bind(this);
        this.setDatalayer = this.setDatalayer.bind(this);
        this.initPush = this.initPush.bind(this);
        this.scrollToPosition = this.scrollToPosition.bind(this);

        //Reload timer
        this.reloadTimer = 0;

        dispatch(fetchMetadata());
    }

    componentDidMount() {
        const { ConsentBanner, dispatch } = this.props;

        //Show mobile menu footer
        dispatch(showFooter());

        Modal.setAppElement('body');

        // const pinger = setInterval(this.ping, config.PING_TIMEOUT);
        // this.setState({'pinger': pinger});

        if (config.GTM_ENABLED && ConsentBanner && ConsentBanner.consent) {
            const gtmWatcher = setInterval(this.setDatalayer, 100);
            this.setState({'gtmWatcher': gtmWatcher});
        }

        this.initPush();

    }

    componentDidUpdate(prevProps) {
        const { dispatch, metadata, token, item, isFailed, ConsentBanner, currentLocation, scrollTo } = this.props;
        // Metadata change
        if (prevProps.metadata && prevProps.metadata.build && prevProps.metadata.build !== metadata.build) {
            console.log(prevProps.metadata.build, metadata.build);

            /*dispatch(resetStore());
            if (account.token) {
                dispatch(fetchAccount());
                dispatch(showFooter());
            } */

            //this.setState({reload: true});
        }

        // Reload app
        if ( this.state.reload && (!token || item || isFailed )) {
            this.reloadTimer = setTimeout(() => {
                window.location.reload();
            }, 500)
        }
        else {
            clearTimeout(this.reloadTimer);
        }

        //If reload failed - show manuall button
        if(this.state.reload){
            setTimeout(() => {
                this.setState({ reloadButton: true })
            }, 3000)
        }

        // Consent change
        if (prevProps.ConsentBanner && prevProps.ConsentBanner.consent && (!ConsentBanner || !ConsentBanner.consent)) {
            setTimeout(function() {
                window.location.reload();
            }, 500);
        }
        else if (config.GTM_ENABLED && ConsentBanner && ConsentBanner.consent && (!prevProps.ConsentBanner || !prevProps.ConsentBanner.consent)) {
            this.setDatalayer();
        }

        // Token change
        if (
            (!prevProps.token && token) ||
            (prevProps.token !== token)
        ) {
            if (!this.webpush && token) {
                this.initPush();
            }
            else if (token) {
                this.webpush.subscribe();
            }
            else if (this.webpush) {
                this.webpush.unsubscribe();
            }
        }

        // Scroll to position
        if (prevProps.currentLocation !== currentLocation) {
            var scrollPosition = scrollTo;

            // Set scrollHeight
            if (!(scrollPosition > 0)) {
                scrollPosition = 0;
            }

            if (this.state.scroller) {
                // Clear interval if it exists already
                clearInterval(this.state.scroller);
            }
            const scroller = setInterval(this.scrollToPosition, 250);
            this.setState({
                'scrollToPosition': scrollPosition,
                'scroller': scroller,
            });
            dispatch(setRuntime({scrollTo: null}));
        }
    }

    componentWillUnmount = () => {
        //clearInterval(this.state.pinger);
        if (this.state.gtmWatcher) {
            clearInterval(this.state.gtmWatcher);
        }
        if (this.state.scroller) {
            clearInterval(this.state.scroller);
        }
    }

    scrollToPosition() {
        const { scroller, scrollToPosition } = this.state;

        if (document.body.scrollHeight >= scrollToPosition) {
            window.scrollTo(0, scrollToPosition);
            clearInterval(scroller);
        }
    }

    initPush() {
        const { token } = this.props;

        if (token) {
            Webpush.create({
                serviceWorkerPath: '/js/webpush-sw.js', // Public path to the service worker
                serverKey: config.API_PUSH_KEY, // https://developers.google.com/web/fundamentals/push-notifications/web-push-protocol#application_server_keys
                subscribeUrl: config.API_HOST+'/webpush/?bearer='+token, // Optional - your application URL to store webpush subscriptions
            })
                .then(WebPushClient => {
                    if (WebPushClient.isSupported()) {
                        this.webpush = WebPushClient;
                        this.webpush.subscribe();
                    }
                })
            ;
        }
    }

    /*ping() {
        const { dispatch, account } = this.props;

        // Check for new build
        dispatch(fetchMetadata());

        // Fetch dashboard updates
        if (account && account.token) {
            dispatch(fetchUserNotificationsIfNeeded());
        }
    }*/

    setDatalayer() {
        if (window.dataLayer) {
            window.dataLayer.push({'event': 'acceptAll'});

            if (this.state.gtmWatcher) {
                clearInterval(this.state.gtmWatcher);
            }
        }
    }

    handleAddToHomeBanner = () => {
        const { dispatch } = this.props;
        dispatch(pwaInstallBanner(false));
    }

    render() {

        const { showAppInstallBanner, dispatch, token } = this.props;
        const { athsPopup } = this.state;

        // Soft reload
        if (this.props.softReload) {
            this.props.dispatch(resetStore());
            this.props.dispatch(showFooter())
            if (token) {
                this.props.dispatch(fetchAccount());
            }

            return (<></>);
        }

        // Reload
        if (this.state.reload) {
            return (
                <Container className="app-reload">
                    <Loader size="big" active>
                        Neustart/Aktualisierung...
                        {/* Reload manually */}
                        {this.state.reloadButton &&
                            <Button basic onClick={() => window.location.reload()}>Jetzt aktualisieren</Button>
                        }
                    </Loader>
                </Container>
            );
        }

        // Default

        return (
            <GTM
                 gtm={{
                     id: config.TAG_MANAGER_ID,
                 }}

             >
                <SWUpdater icon={LogoVetero} resetStore={() => dispatch(resetStore())}/>

                <Router>

                  

                    <AddToHomeScreen icon={LogoVetero} show={showAppInstallBanner} dismissCallback={this.handleAddToHomeBanner} />

                    <CustomPopup
                        popupOpen={athsPopup}
                        onRequestClose={() => this.setState({ athsPopup: false })}
                    >
                        <Header as="h2">Vetero als App</Header>
                            Anleitung...
                    </CustomPopup>
                 
                    <ConsentBanner/>
        
                    <Ping/>

                     <Switch>

                         {/* BACKEND ROUTES */}
                        <Route path="/backend">
                            <ErrorBoundary backend={true}>
                                <DashboardHeader>
                                    <BackendRoutes/>
                                </DashboardHeader>
                            </ErrorBoundary>
                        </Route>

                        {/* FRONTEND ROUTES */}
                        <Route path="/">
                             <ErrorBoundary>
                                 <MainHeader>
                                 <Suspense fallback={<Loader active/>}>
                                    <FrontendRoutes/>
                                    </Suspense>
                                 </MainHeader>
                                 <Footer/>
                             </ErrorBoundary>
                         </Route>

                     </Switch>
                 </Router>

             </GTM>
        );
    }
}

function mapStateToProps(state, ownProps) {
    const { globalByComponent, account } = state;

    const { currentLocation, scrollTo, softReload } = globalByComponent[
        'Runtimedata'
    ] || {}

    const { item, token, notifications, isFailed } = account || {
        item: {},
        token: '',
        isFailed: false,
        notifications: {}
    }

    const { showAppInstallBanner } = globalByComponent[
        'PWA Banner'
    ] || {
        showAppInstallBanner: true
    }

    return {
        metadata: globalByComponent['Metadata'],
        item,
        token,
        isFailed,
        notifications,
        ConsentBanner: globalByComponent['ConsentBanner'],
        currentLocation,
        scrollTo,
        softReload,
        showAppInstallBanner
    }
}

export default connect(mapStateToProps)(App);
