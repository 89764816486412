import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Grid, Image, Card, Button, Header, Responsive } from 'semantic-ui-react'
import ProgressiveImager from '../helpers/progressive-imager';
import NumericLabel from 'react-pretty-numbers';
import config from '../../config/config';

import userAvatar from '../../images/icon-avatar.svg';
import clockIcon from '../../images/icons/frontend/icon-clock-circle.svg';
import commentIcon from '../../images/icons/frontend/icon-comment.svg';
import eurIcon from '../../images/icons/frontend/icon-euro-circle.svg';
import heartIcon from '../../images/icons/frontend/icon-heart.svg';
import closeIcon from '../../images/icons/close-cross-white.svg'

import './Card.scss';
import Truncate from 'react-truncate';
const nl2br = require('react-nl2br');

class ProjectCard extends Component {

    render() {

        const { sumMedias, item, closeButton, onClose } = this.props;

        return (

            <Card className="project-item">
                <Grid>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                        <div className="project-images">
                            {sumMedias.length >= 1 && sumMedias.length <= 2 &&
                            <>
                                <Responsive {...Responsive.onlyMobile} className="main-image full">
                                    <ProgressiveImager
                                        src={config.getImageUrl('main', item.mediamainPath)}
                                        alt={item.title+' von '+item.userData.name+' – Bild: '+item.mediamain}
                                    />
                                </Responsive>

                                <Responsive minWidth={Responsive.onlyTablet.minWidth} className="main-image full">
                                    <ProgressiveImager
                                        src={config.getImageUrl('main', item.mediamainPath)}
                                        alt={item.title+' von '+item.userData.name+' – Bild: '+item.mediamain}
                                    />
                                </Responsive>
                            </>
                            }
                            {sumMedias.length >= 3 &&
                            <>

                                <Responsive {...Responsive.onlyMobile} className="main-image">
                                    <ProgressiveImager
                                        src={config.getImageUrl('halfwidth', item.mediamainPath)}
                                        alt={item.title+' von '+item.userData.name+' – Bild: '+item.mediamain}
                                    />
                                </Responsive>

                                <Responsive minWidth={Responsive.onlyTablet.minWidth} className="main-image">
                                    <ProgressiveImager
                                        src={config.getImageUrl('main', item.mediamainPath)}
                                        alt={item.title+' von '+item.userData.name+' – Bild: '+item.mediamain}
                                    />
                                </Responsive>

                                <div className="story-images">
                                <div className="img">
                                        <ProgressiveImager
                                            src={config.getImageUrl('halfwidth', sumMedias[1].path)}
                                            alt={item.title+' von '+item.userData.name+' – Bild: '+sumMedias[1].id}
                                        />
                                </div>
                                <div className="img last">
                                        <ProgressiveImager
                                            src={config.getImageUrl('halfwidth', sumMedias[sumMedias.length-1].path)}
                                            alt={item.title+' von '+item.userData.name+' – Bild: '+sumMedias[sumMedias.length-1].id}
                                        />
                                        <div className="image-count-overlay">
                                            <div className="image-count">+{item.imgCount -2}</div>
                                        </div>
                                </div>
                                </div>
                            </>
                            }
                            <Responsive {...Responsive.onlyMobile} className="user-label">
                                <Link to={`/user/${item.userData.urlslug}/${item.user}`}>
                                    <div className="user-image-wrapper">
                                        {item.userData.mediaavatarPath === null ?
                                            <Image alt="User placeholder image" src={userAvatar} circular/> : <ProgressiveImager alt={"Profilbild von " + item.userData.name} placeholder="avatar" src={config.getImageUrl('avatar', item.userData.mediaavatarPath)} circular/>
                                        }
                                    </div>
                                    <span className="user-name">
                                        {item.userData.name}
                                    </span>
                                </Link>
                            </Responsive>
                            {closeButton &&
                                <div className="card-close-btn mobile" onClick={onClose}>
                                    <Image src={closeIcon} alt="Icon Times"/>
                                </div>
                            }
                        </div>

                        {/* Project meta info - likes count etc. */}
                        <Card.Meta className="project-info">
                            <div className="meta-item">
                                <Image src={clockIcon} className="icon-social" title="Stunden" alt="Stunden" />
                                <span>
                                    {item.showWorkinghours ?
                                        <>
                                            {item.sumWorkinghours ?
                                                <>
                                                    <NumericLabel params={config.PRETTY_NUMERIC_CONFIG}>{item.sumWorkinghours}</NumericLabel>
                                                        <span>h</span>
                                                </>
                                                    :
                                                    '0h'
                                            }
                                        </>
                                            :
                                        '-'
                                    }
                                </span>
                            </div>
                            <div className="meta-item">
                                <Image src={eurIcon} className="icon-social" title="Euro" alt="Euro" />
                                <span>
                                    {item.showExpenses ?
                                        <>
                                            {item.sumPrice ?
                                            <>
                                                <NumericLabel params={config.PRETTY_NUMERIC_CONFIG}>{item.sumPrice}</NumericLabel>
                                                <span>€</span>
                                            </>
                                                :
                                                '0€'
                                            }
                                        </>
                                        :
                                        '-'
                                    }
                                </span>
                            </div>
                            <div className="meta-item">
                                <Image src={heartIcon} className="icon-social" title="Gefällt mir" alt="Gefällt mir" />
                                <span>
                                    {item.countLike ?
                                        <NumericLabel params={config.PRETTY_CONFIG}>{item.countLike}</NumericLabel>
                                        :
                                        '0'
                                    }
                                </span>
                            </div> 
                            <div className="meta-item">
                                <Image src={commentIcon} className="icon-social" title="Kommentare" alt="Kommentare" />
                                <span>
                                    {item.countComment ?
                                        <NumericLabel params={config.PRETTY_CONFIG}>{item.countComment}</NumericLabel>
                                        :
                                        '0'
                                    }
                                </span>
                            </div>        
                        </Card.Meta>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={8} className="description-column">
                        {/* Project description */}
                        <Card.Description>
                            <div className="project-card-description">
                                <Responsive minWidth={Responsive.onlyTablet.minWidth} className="user-label">
                                    <Link to={`/user/${item.userData.urlslug}/${item.user}`}>
                                        <div className="user-image-wrapper">
                                            {item.userData.mediaavatarPath === null ?
                                                <Image alt="User placeholder image" src={userAvatar} circular/> : <ProgressiveImager alt={item.userData.name} placeholder="avatar" src={config.getImageUrl('avatar', item.userData.mediaavatarPath)} circular/>
                                            }
                                        </div>
                                        <span className="user-name">
                                            {item.userData.name}
                                        </span>
                                    </Link>
                                </Responsive>
                                {closeButton &&
                                    <div className="card-close-btn desktop" onClick={onClose}>
                                        <Image src={closeIcon} alt="Icon Times"/>
                                    </div>
                                }
                                <div className="project-title">
                                    <Header as="h2">{item.title}</Header>
                                </div>
                                <div className="project-description">
                                    {/* TODO: Use media queries?  */}
                                    <Responsive maxWidth={Responsive.onlyTablet.maxWidth}>
                                        <Truncate lines={4}>
                                            {<>

                                            {item.description1 !== null ? 
                                            <>
                                                    <span>
                                                
                                                        {item.description1}
                                                        
                                                        <br/>
                                                    </span> 
                                                    
                                                    </>
                                                    : ''
                                                }

                                                {item.description2 !== null ? 
                                                    <span>
                                                        {item.description2}
                                                        <br/>
                                                    </span> 
                                                    : ''
                                                }

                                                {item.description3 !== null ? 
                                                    <span>
                                                        {item.description3}
                                                        <br/>
                                                    </span> 
                                                    : ''
                                                }

                                                {item.description4 !== null ? 
                                                    <span>
                                                        {item.description4}
                                                        <br/>
                                                    </span> 
                                                    : ''
                                                }

                                                {item.description5 !== null ? 
                                                    <span>
                                                        {item.description5}
                                                        <br/>
                                                    </span> 
                                                    : ''
                                                }

                                                {item.description !== null ? 
                                                    <span>
                                                        {item.description}
                                                        <br/>
                                                    </span> 
                                                    : ''
                                                }
                                            
                                            
                                            </>}
                                        </Truncate>
                                    </Responsive>
                                    <Responsive minWidth={Responsive.onlyComputer.minWidth}>
                                        <Truncate lines={8}>
                                            {<>

                                            {item.description1 !== null ? 
                                            <>
                                                    <span>
                                                
                                                        {item.description1}
                                                        
                                                        <br/>
                                                    </span> 
                                                    
                                                    </>
                                                    : ''
                                                }

                                                {item.description2 !== null ? 
                                                    <span>
                                                        {item.description2}
                                                        <br/>
                                                    </span> 
                                                    : ''
                                                }

                                                {item.description3 !== null ? 
                                                    <span>
                                                        {item.description3}
                                                        <br/>
                                                    </span> 
                                                    : ''
                                                }

                                                {item.description4 !== null ? 
                                                    <span>
                                                        {item.description4}
                                                        <br/>
                                                    </span> 
                                                    : ''
                                                }

                                                {item.description5 !== null ? 
                                                    <span>
                                                        {item.description5}
                                                        <br/>
                                                    </span> 
                                                    : ''
                                                }

                                                {item.description !== null ? 
                                                    <span>
                                                        {item.description}
                                                        <br/>
                                                    </span> 
                                                    : ''
                                                }
                                            
                                            
                                            </>}
                                        </Truncate>
                                    </Responsive>
                                    {/* No description... */}
                                    {
                                        !item.description1 &&
                                        !item.description2 &&
                                        !item.description3 &&
                                        !item.description4 &&
                                        !item.description5 &&
                                        !item.description &&
                                        `${item.userData.name} hat noch keine allgemeinen Informationen für dieses Projekt preisgegeben.`
                                    }

                                </div>
                            </div>
                            <div className="card-button">
                                <Button 
                                    as={Link} 
                                    color="red"
                                    to={item.linktype === "stories" ? `/oldtimer/${item.urlslug}/${item.id}/tab/stories` : item.linktype === "vehicle" ? `/oldtimer/${item.urlslug}/${item.id}/tab/vehicle` : `/oldtimer/${item.urlslug}/${item.id}/tab/interview` }

                                >
                                    Mehr zum Oldtimer
                                </Button>
                            </div>
                        </Card.Description>
                    </Grid.Column>
                </Grid>
            </Card>

            /*<div className="projects-list">
                <div className="project-motion-wrapper">
                    <Link to={`/oldtimer/${item.item.urlslug}/${item.item.id}`}>
                        <div className="project-wrapper">
                            <div className="project-images">

                            {item.sumMedias.length >= 1 && item.sumMedias.length <= 2 &&
                          
                                <div className="top-image" >
                                    <Image alt="User placeholder image" src={userAvatar} circular/> : <ProgressiveImager alt={item.item.userData.name} placeholder="avatar" src={config.getImageUrl('avatar', item.item.userData.mediaavatarPath)} circular/>
                                </div>
                                
                            }

                            {item.sumMedias.length >= 3 &&
                                <>
                                    <div className="top-image">
                                        <ProgressiveImager
                                            src={config.getImageUrl('main', item.item.mediamainPath)}
                                            alt={item.item.title+' von '+item.item.userData.name+' – Bild: '+item.item.mediamain}
                                            className="responsive"
                                        />
                                    </div>
                                    <div className="bottom-images" > 
                                        <div className="first">
                                            <ProgressiveImager
                                                src={config.getImageUrl('halfwidth', item.sumMedias[1].path)}
                                                alt={item.item.title+' von '+item.item.userData.name+' – Bild: '+item.sumMedias[1].id}
                                            />
                                        </div>
                                        <div className="second">
                                            <ProgressiveImager
                                                src={config.getImageUrl('halfwidth', item.sumMedias[Object.keys(item.sumMedias).length-1].path)}
                                                alt={item.item.title+' von '+item.item.userData.name+' – Bild: '+item.sumMedias[Object.keys(item.sumMedias).length-1].id}
                                            />
                                            <div className="image-count-overlay">
                                                <div className="image-count">+{item.item.imgCount -2}</div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                }
                            </div>
                        </div>
                            <div className="user-header">
                                <div className="user-image-circular">
                                    {item.item.userData.mediaavatarPath === null ?
                                        <Image alt="User placeholder image" src={userAvatar} circular/> : <ProgressiveImager alt={item.item.userData.name} placeholder="avatar" src={config.getImageUrl('avatar', item.item.userData.mediaavatarPath)} circular/>
                                    }
                                </div>
                                <div className="user-info">
                                    <div className="project-name">{item.item.title}</div>
                                    <div className="user-name">von {item.item.userData.name}</div>
                                    </div>
                                </div>
                        </Link>
                        <div className="social-buttons project-social-buttons">
                                <Grid columns="equal">
                                    <Grid.Column>
                                        <span className="icons-social" >
                                            <Image src={clockIcon} className="icon-social" title="Stunden" alt="Stunden" />
                                            <span className="social-amount">
                                                {item.item.showWorkinghours ?
                                                    <>
                                                        {item.item.sumWorkinghours ?
                                                        <>
                                                            <NumericLabel params={config.PRETTY_NUMERIC_CONFIG}>{item.item.sumWorkinghours}</NumericLabel>
                                                            <span>h</span>
                                                        </>
                                                            :
                                                            '0h'
                                                        }
                                                    </>
                                                :
                                                    '-'
                                                }
                                            </span>
                                        </span>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <span className="icons-social" >
                                            <Image src={eurIcon} className="icon-social" title="Euro" alt="Euro" />
                                            <span className="social-amount">
                                                {item.item.showExpenses ?
                                                    <>
                                                        {item.item.sumPrice ?
                                                        <>
                                                            <NumericLabel params={config.PRETTY_NUMERIC_CONFIG}>{item.item.sumPrice}</NumericLabel>
                                                            <span>€</span>
                                                        </>
                                                        :
                                                            '0€'
                                                        }
                                                    </>
                                                :
                                                    '-'
                                                }
                                            </span>
                                        </span>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <span className="icons-social" >
                                            <Image src={heartIcon} className="icon-social" title="Gefällt mir" alt="Gefällt mir" />
                                            <span className="social-amount">
                                                {item.item.countLike ?
                                                    <NumericLabel params={config.PRETTY_CONFIG}>{item.item.countLike}</NumericLabel>
                                                    :
                                                    '0'
                                                }
                                            </span>
                                        </span>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <span className="icons-social" >
                                            <Image src={commentIcon} className="icon-social" title="Kommentare" alt="Kommentare" />
                                            <span className="social-amount">
                                                {item.item.countComment ?
                                                    <NumericLabel params={config.PRETTY_CONFIG}>{item.item.countComment}</NumericLabel>
                                                    :
                                                    '0'
                                                }
                                            </span>
                                        </span>
                                    </Grid.Column>
                                </Grid>
                            </div>
                    </div>
            </div>*/
        )
    }
}

export default ProjectCard
