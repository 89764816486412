import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
    Image,
    Placeholder,
    Progress,
    Icon
} from 'semantic-ui-react';
import staticPlaceholder from '../../images/placeholder.svg';
//User placeholder image
import userAvatar from '../../images/icon-avatar.svg';


/**
* Wrapper for dynamic images to display loading and placeholder states
*/
class ImageWrapper extends Component {

    render() {
        const { path, children, avatar, className, circular, progress } = this.props;
        
        if(avatar && !path){
            return (
                <div className={className}>
                    <Image className={className} circular={circular} src={userAvatar} alt="Platzhalter" />
                </div>
            )
        }

        else if (path === "LOADING" && progress) {            
            return (
                <div class="uploading-media">
                    <Progress percent={progress} indicating />
                    <Image className={className} circular={circular} src={staticPlaceholder} alt="Platzhalter Upload" />
                </div>
            )
        }

        else if(path === "ERROR"){
            return (
                <dic class="failed-upload">
                    <div className="failed-upload-icon">
                        <Icon name="times circle outline"/>
                    </div>
                </dic>
            )
        }

        else if (!path) {
            return (
                <div className={"img-wrapper-placeholder " + className}>
                    <Image className={className} circular={circular} src={staticPlaceholder} alt="Platzhalter" />
                </div>

            )
        }
        else {
            return (
                <div className={className}>
                    {children}
                </div>
            )
        }
    }
}

ImageWrapper.propTypes = {
    path: PropTypes.string,
    children: PropTypes.node,
}

export default ImageWrapper;
