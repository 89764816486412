import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import { setRuntime } from './actions/global';

import Error500 from './pages/500';
import feedpostByUserId from './reducers/userFeedpost';


class ErrorBoundary extends Component {
    state = {
        error: '',
        errorInfo: '',
        hasError: false,
    }

    constructor(props) {
        super(props);

        const { dispatch, item, history, backend } = this.props;

        if (backend && (!item || !item.id)) {
            history.push('/');
            dispatch(setRuntime({softReload: true}));
        }
    }

    componentDidUpdate(prevProps) {
        const { dispatch, token, history, backend  } = this.props;

        if (prevProps.token && !token) {
            if (backend) {
                history.push('/');
            }
            dispatch(setRuntime({softReload: true}));
        }
        else if (token && !prevProps.token) {
            dispatch(setRuntime({softReload: true}));
        }
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }

    componentDidCatch(error, errorInfo) {
        console.log({ error, errorInfo });
        this.setState({ errorInfo });
    }

    render() {
        const { hasError, errorInfo } = this.state;
        const { fatalError } = this.props;

        if (hasError || fatalError) {
            return (<Error500/>);
        }

        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.oneOfType([ PropTypes.object, PropTypes.array ]).isRequired,
};

function mapStateToProps(state, ownProps) {
    const {
        globalByComponent,
        account,
        projectById,
        storyById,
        listByEntity,
        commentsByParent,
        userById,
        conversationById,
        partsByParent,
        billsByParent,
        expensesByParent,
        notificationById,
        expendituresByParent,
        feedpostById,
        blog,
        forumthreadById,
        brandBySlug,
        professionalsById,
        feedpostByUserId
    } = state;

    const { fatalError: fatalError_globalByComponent } = globalByComponent;
    const { item, token, fatalError: fatalError_account } = account;
    const { fatalError: fatalError_projectById } = projectById;
    const { fatalError: fatalError_storyById } = storyById;
    const { fatalError: fatalError_listByEntity } = listByEntity;
    const { fatalError: fatalError_commentsByParent } = commentsByParent;
    const { fatalError: fatalError_userById } = userById;
    const { fatalError: fatalError_conversationById } = conversationById;
    const { fatalError: fatalError_partsByParent } = partsByParent;
    const { fatalError: fatalError_billsByParent } = billsByParent;
    const { fatalError: fatalError_expensesByParent } = expensesByParent;
    const { fatalError: fatalError_notificationById } = notificationById;
    const { fatalError: fatalError_expendituresByParent } = expendituresByParent;
    const { fatalError: fatalError_feedpostById } = feedpostById;
    const { fatalError: fatalError_blog } = blog;
    const { fatalError: fatalError_forumthreadById } = forumthreadById;
    const { fatalError: fatalError_brandBySlug } = brandBySlug;
    const { fatalError: fatalError_professionalsById } = professionalsById;
    const { fatalError: fatalError_feedpostByUserId } = feedpostByUserId;

    return {
        item,
        token,
        fatalError : (
            fatalError_globalByComponent ||
            fatalError_account ||
            fatalError_projectById ||
            fatalError_storyById ||
            fatalError_listByEntity ||
            fatalError_commentsByParent ||
            fatalError_userById ||
            fatalError_conversationById ||
            fatalError_partsByParent ||
            fatalError_billsByParent ||
            fatalError_expensesByParent ||
            fatalError_notificationById ||
            fatalError_expendituresByParent ||
            fatalError_feedpostById ||
            fatalError_blog ||
            fatalError_forumthreadById ||
            fatalError_brandBySlug ||
            fatalError_professionalsById ||
            fatalError_feedpostByUserId
        )
    }
}

export default connect(mapStateToProps)(withRouter(ErrorBoundary))
