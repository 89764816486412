import React, {Component} from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Label } from 'semantic-ui-react';


class TagFilter extends Component {

    render() {

        const { tags, activeTag, handleTags } = this.props;

        return (
            <>
                <Label
                    as={Link}
                    size="large"
                    to={`/oldtimer-blog`}
                    active={activeTag === "alle"}
                    onClick={
                        activeTag === "alle" ?
                            () => false
                        :
                            () => handleTags()
                    }
                >
                    alle                   
                </Label>
                {tags.map(item => (
                    <Label
                        key={item.id}
                        as={Link} 
                        to={`/oldtimer-blog/tag/${item.slug}/${item.id}`}
                        size="large"
                        active={activeTag === item.slug}
                        onClick={
                            activeTag === item.slug ?
                                () => false
                            :
                                () => handleTags(item.slug, item.id)
                        }
                    >
                        {item.name}
                    </Label>
                ))}
            </>
        )
    }
}

export default withRouter(TagFilter);
