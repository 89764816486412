import React from 'react'
import {
    Header,
    Container,
    Divider,
    Image,
    Label,
    Icon,
    Button,
    Message,
    Grid,
    Segment,
    Responsive,
    Dropdown,
    Placeholder,
 } from 'semantic-ui-react';
import { Link, withRouter} from 'react-router-dom'
import CustomPopup from '../../../../components/CustomPopup/CustomPopup';
import Swipeout from 'rc-swipeout';
import 'rc-swipeout/assets/index.css'
import { connect } from 'react-redux';
import ProjectNav from '../../../../components/layout/ProjectNav';
import PlusButton from '../../../../components/PlusButton/PlusButton';
import fetchProjectIfNeeded, {fetchProject} from '../../../../actions/project'
import {fetchStoryListIfNeeded, deleteStory, directStoryUpdate, sortStories} from '../../../../actions/story'
import { handleContainer, setRuntime } from '../../../../actions/global';
import config from '../../../../config/config'
import ProgressiveImager from '../../../../components/helpers/progressive-imager';
import BeParent from '../be-parent';
import 'moment/locale/de'
import 'moment-timezone';

//Icons
import visibleIcon from '../../../../images/icons/icon-swipe-visible.svg';
import inVisibleIcon from '../../../../images/icons/icon-swipe-invisible.svg';
import trashIcon from '../../../../images/icons/icon-swipe-trash.svg';
import heartIcon from '../../../../images/icons/frontend/icon-heart.svg';
import commentIcon from '../../../../images/icons/frontend/icon-comment.svg';
import eurIcon from '../../../../images/icons/frontend/icon-euro-circle.svg';
import clockIcon from '../../../../images/icons/frontend/icon-clock-circle.svg'
import menuDotsIcon_2 from '../../../../images/icons/footer-menu-dots_2.svg';
import ArrowUp from '../../../../images/icons/arow-arrange-up-white.svg';
import ArrowDown from '../../../../images/icons/arow-arrange-down-white.svg';


class Storys extends BeParent {

    state = {
        popupIsOpen: false,
        popupContent: "",
        deletestory: null,
    }

    componentDidMount() {
        const { dispatch, match, item } = this.props
        dispatch(fetchProjectIfNeeded(match.params.id))
        dispatch(fetchStoryListIfNeeded(match.params.id));
        dispatch(handleContainer("secondary", item.title, false, "dashboard"));
        dispatch(setRuntime({page: 'be-story-list'}));
        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps) {
        const { dispatch, match, listitems } = this.props;

        if(listitems.length < prevProps.listitems.length) {
            this.setState({popupIsOpen: false, deletestory: null});
            // Update project
            dispatch(fetchProject(match.params.id));
        }
    }

    handlePopup = (isOpen, content) => {
        this.setState({popupIsOpen: isOpen, popupContent: content})
    }

    handlePopupContent = () => {
        const { popupContent } = this.state;
        if (popupContent === "delete-confirm") {
            return this.confirmDelete();
        }
    }

    handleSort = (position, direction) => {
        const { dispatch, listitems, match } = this.props;
        var sorted = {};

        listitems.map((li) => {
            if(li.item.position === position) {
                sorted[li.item.id] = position+direction;
            }
            else if(li.item.position === position+direction) {
                sorted[li.item.id] = position;
            }
            else {
                sorted[li.item.id] = li.item.position;
            }
        })

        dispatch(sortStories(match.params.id, sorted));
    }

    render(){
        const {isFetching, listitems, dispatch, match } = this.props;
        if((!listitems || !listitems.length) && isFetching) {
            return (
                <div>

                    <ProjectNav active="Storys"  project={{id: match.params.id}}/>
                    <Container style={{margin: "15px 0"}}>
                        <Grid>
                            <Grid.Column floated="left" width={5}>
                                <Header as="h2">Storys</Header>
                            </Grid.Column>
                            <Grid.Column floated="right" width={7} textAlign="right">
                                <PlusButton
                                    title="Neue Story"
                                    color="red"
                                    disabled={true}
                                />
                            </Grid.Column>
                        </Grid>
                    </Container>

                    {this.placeholder()}
                    {this.placeholder()}
                    {this.placeholder()}
                </div>
            )
        }
        else {
            return(
                <div>
                    <ProjectNav active="Storys"  project={{id: match.params.id}}/>
                    <Container style={{margin: "15px 0"}}>
                        <Grid>
                            <Grid.Column floated="left" width={5}>
                                <Header as="h2">Storys</Header>
                            </Grid.Column>
                            <Grid.Column floated="right" width={7} textAlign="right">
                                <PlusButton
                                    title="Neue Story"
                                    color="red"
                                    as={Link}
                                    to={`/backend/project/${match.params.id}/stories/new_story`}
                                />
                            </Grid.Column>
                        </Grid>
                    </Container>

                    <Responsive maxWidth={Responsive.onlyTablet.maxWidth}>
                        {this.props.listitems.map(item => (
                            <>
                            <div style={{marginBottom: "1rem"}}>
                                <Swipeout
                                autoClose
                                right={[
                                    {
                                        text: <div
                                            className="swipeout-img-container"
                                            style={{textAlign: "center"}}
                                            onTouchEnd={() => {
                                                if (item.item.active) {
                                                    dispatch(directStoryUpdate(item.item.id, 'active', false));
                                                }
                                                else {
                                                    dispatch(directStoryUpdate(item.item.id, 'active', true));
                                                }
                                            }}
                                            >
                                                <div className="swipeout-img">
                                                    <Image alt="active icon" src={item.item.active ? inVisibleIcon : visibleIcon} style={{width: "32px", height:"32px"}} />
                                                    {item.item.active ? <span>Parken</span> : <span>Veröffentlichen</span>}
                                                </div>
                                            </div>,
                                        style: {
                                            backgroundColor: !item.item.active ? '#00D83C' : "rgba(54, 34, 15, 0.20)",
                                            color: "white",
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            width: "126px",
                                            height: "100%"
                                            }
                                    },
                                    {
                                        text: <div
                                                className="swipeout-img-container"
                                                onTouchEnd={() => this.setState({popupIsOpen: true, deletestory: item.item.id})}
                                              >
                                                  <div className="swipeout-img">
                                                    <Image alt="Delete icon" title="Löschen" src={trashIcon} style={{width: "32px", height:"32px"}}/>
                                                    <span>Löschen</span>
                                                </div>
                                            </div>,
                                        style: {
                                            backgroundColor: "#E7344C",
                                            color: "white",
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            width: "92px",
                                            height: "100%"
                                            }
                                    }
                                ]}
                                left={[
                                    {
                                        text: <div
                                                className="swipeout-img-container"
                                                onTouchEnd={() => this.handleSort(item.item.position, -1)}
                                              >
                                                  <div className="swipeout-img">
                                                    <Image alt="Sort Icon" src={ArrowUp}/>
                                                  </div>
                                              </div>,
                                        style: {
                                            color: "white",
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            width: "92px",
                                            height: "100%",
                                            display: item.item.position !== 0 ? "flex" : "none",
                                        },
                                        className: "sort-btn up",

                                    },
                                    {
                                        text: <div
                                                className="swipeout-img-container"
                                                onTouchEnd={() => this.handleSort(item.item.position, 1)}
                                              >
                                                  <div className="swipeout-img">
                                                    <Image alt="Sort Icon" src={ArrowDown} style={{width: "24px", height:"27px", margin: "0 auto 0.5rem", textAlign:"center"}}/>
                                                  </div>
                                              </div>,
                                        style: {
                                            color: "white",
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            width: "92px",
                                            height: "100%",
                                            display: item.item.position === listitems.length-1 ? "none" : "flex",
                                        },
                                        className: "sort-btn down",
                                    },

                                ]}
                            >
                                <Link to={`/backend/project/${match.params.id}/stories/${item.item.id}/edit_story`}>
                                    <div className="storys">
                                            <Segment>
                                                <Grid>
                                                    <Grid.Column mobile={7} tablet={6} computer={6}>
                                                        <div className="storys-img">
                                                            <ProgressiveImager src={config.getImageUrl('halfwidth', item.item.mediamainPath)}/>
                                                            {item.item.active ? <Label color="green">Veröffentlicht</Label>  : <Label color="red">Geparkt</Label> }
                                                        </div>
                                                    </Grid.Column>
                                                    <Grid.Column mobile={9} tablet={10} computer={10} className="stories-info-wrapper">
                                                        <Grid>
                                                            <Grid.Column width={16}>
                                                                <div className="storys-description">
                                                                    {item.item.title}
                                                                </div>
                                                            </Grid.Column>
                                                        </Grid>

                                                        <div className="stories-info">
                                                            <Grid>
                                                                <Grid.Column mobile={7} tablet={4} computer={4}>
                                                                    <span><Image alt="Clock Icon" title="Arbeitszeiten" className="storys-icon" src={clockIcon}/>{config.getFormattedInt(item.item.sumWorkinghours)}</span>
                                                                </Grid.Column>
                                                                <Grid.Column mobile={9} tablet={4} computer={4}>
                                                                    <span><Image alt="Euro Icon" title="Kosten" className="storys-icon" src={eurIcon}/>{config.getFormattedInt(item.item.sumPrice)}</span>
                                                                </Grid.Column>
                                                                <Grid.Column mobile={7} tablet={4} computer={4}>
                                                                    <span><Image alt="Likes Icon" title="Gefällt mir Angaben" className="storys-icon" src={heartIcon}/>{config.getFormattedInt(item.item.countWelldone)}</span>
                                                                </Grid.Column>
                                                                <Grid.Column mobile={9} tablet={4} computer={4}>
                                                                    <span><Image alt="Comment Icon" title="Kommentare" className="storys-icon" src={commentIcon}/>{config.getFormattedInt(item.item.countComment)}</span>
                                                                </Grid.Column>
                                                            </Grid>
                                                        </div>
                                                    </Grid.Column>
                                                </Grid>
                                            </Segment>
                                    </div>
                                </Link>
                            </Swipeout>
                        </div>
                        </>
                        ))}
                    </Responsive>

                    <Responsive minWidth={Responsive.onlyComputer.minWidth}>
                        {this.props.listitems.map(item => (
                            <div className="storys">
                                <Segment>
                                    <Link to={`/backend/project/${match.params.id}/stories/${item.item.id}/edit_story`}>
                                        <Grid>
                                            <Grid.Column mobile={7} tablet={6} computer={6}>
                                                <div className="storys-img">
                                                    <ProgressiveImager src={config.getImageUrl('halfwidth', item.item.mediamainPath)}/>
                                                    <Label color={item.item.active ? "green" : "red"}>
                                                        {item.item.active ? "Veröffentlicht" : "Geparkt"}
                                                    </Label>
                                                </div>
                                            </Grid.Column>
                                            <Grid.Column mobile={9} tablet={10} computer={10}>
                                                <Grid>
                                                    <Grid.Column mobile={16} tablet={16} computer={14}>
                                                        <Link to={`/backend/project/${match.params.id}/stories/${item.item.id}/edit_story`}>
                                                            <div className="storys-description">
                                                                {item.item.title}
                                                            </div>
                                                        </Link>
                                                    </Grid.Column>
                                                    <Grid.Column className="elements-dots-menu" only="" computer={2}>

                                                        <div className="elements-options-menu">
                                                            <Dropdown onClick={(e) => e.preventDefault()} icon={<Image alt="Sort Icon" className="elements-options-menu-dots" src={menuDotsIcon_2}/>}>
                                                                <Dropdown.Menu style={{left: 'auto', right: 0}}>
                                                                    <Dropdown.Item className="sort-btn up" onClick={() => this.handleSort(item.item.position, -1)} style={{display: item.item.position !== 0 ? "flex" : "none"}}>
                                                                        <Image src={ArrowUp} />
                                                                        Nach oben verschieben
                                                                    </Dropdown.Item>

                                                                    <Dropdown.Item className="sort-btn down" onClick={() => this.handleSort(item.item.position, 1)} style={{display: item.item.position === listitems.length-1 ? "none" : "flex"}}>
                                                                        <Image src={ArrowDown} alt="Sort Icon" />
                                                                        Nach unten verschieben
                                                                    </Dropdown.Item>

                                                                    <Dropdown.Item
                                                                        className="delete-story"
                                                                        onClick={(e) => {
                                                                            this.setState({popupIsOpen: true, deletestory: item.item.id})
                                                                        }}
                                                                    >
                                                                        <Image alt="Delete Icon" className="elements-options-menu-icon" src={trashIcon}/>
                                                                        <span>Löschen</span>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item className={!item.item.active ? 'visible-story publish' : 'visible-story'} onClick={ () => {
                                                                        if (item.item.active) {
                                                                            dispatch(directStoryUpdate(item.item.id, 'active', false));
                                                                        }
                                                                        else {
                                                                            dispatch(directStoryUpdate(item.item.id, 'active', true));
                                                                        }
                                                                    }}>
                                                                        <Image alt="Active Icon" className="elements-options-menu-icon " src={item.item.active ? inVisibleIcon : visibleIcon}/>
                                                                        <span>{item.item.active ? "Parken" : "Veröffentlichen"}</span>
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </Grid.Column>
                                                </Grid>

                                                <div className="stories-info">
                                                    <Grid>
                                                        <Grid.Column mobile={7} tablet={4} computer={4}>
                                                            <span><Image alt="Clock Icon" title="Arbeitszeiten" className="storys-icon" src={clockIcon}/>{config.getFormattedInt(item.item.sumWorkinghours)}</span>
                                                        </Grid.Column>
                                                        <Grid.Column mobile={9} tablet={4} computer={4}>
                                                            <span><Image alt="Euro Icon" title="Kosten" className="storys-icon" src={eurIcon}/>{config.getFormattedInt(item.item.sumPrice)}</span>
                                                        </Grid.Column>
                                                        <Grid.Column mobile={7} tablet={4} computer={4}>
                                                            <span><Image alt="Likes Icon" title="Gefällt mir Angaben" className="storys-icon" src={heartIcon}/>{config.getFormattedInt(item.item.countWelldone)}</span>
                                                        </Grid.Column>
                                                        <Grid.Column mobile={9} tablet={4} computer={4}>
                                                            <span><Image alt="Comment Icon" title="Kommentare" className="storys-icon" src={commentIcon}/>{config.getFormattedInt(item.item.countComment)}</span>
                                                        </Grid.Column>
                                                    </Grid>
                                                </div>
                                            </Grid.Column>
                                        </Grid>
                                    </Link>
                                </Segment>
                            </div>
                        ))}
                    </Responsive>

                    {(!this.props.listitems.length ) &&
                    <Container>
                        <Message color="teal">
                            Du hast noch keine Storys hinzugefügt.
                        </Message>
                    </Container>
                    }

                     {/* Popup include */}
                     <CustomPopup
                        popupHeader="Story löschen"
                        popupOpen={this.state.popupIsOpen}
                        onClose={() => this.setState({popupIsOpen: false})}
                        >
                            {this.confirmDelete()}
                    </CustomPopup>

                </div>
            )
        }
    }

    confirmDelete(){
        const { dispatch } = this.props;
        return (
            <Container>
                <Divider hidden/>
                <div style={{textAlign: "center", margin: "1rem 0"}}>
                    <Icon name="warning sign" color="red" size="huge"/>
                </div>
                <div style={{textAlign: "center"}}>Willst du die Story wirklich löschen?</div>
                <Divider hidden/>
                <div className="buttons-center">
                    <Button color="red" onClick={() => {
                            if (this.state.deletestory) {
                                this.setState({popupIsOpen: false, deletestory: null});
                            }
                        }}>
                        Zurück
                    </Button>
                    <Button basic color="red" loading={!this.state.deletestory} onClick={() => {
                            if (this.state.deletestory) {
                                dispatch(deleteStory(this.state.deletestory));
                                this.setState({deletestory: null});
                            }
                        }}>
                        Löschen
                    </Button>
                </div>
                <Divider hidden/>
            </Container>
        )
    }

    placeholder() {
        return (
            <div className="storys storys-placeholder">
                <Segment>
                    <Grid>
                        <Grid.Column mobile={7} tablet={6} computer={6}>
                            <div className="storys-img">
                                <Placeholder>
                                    <Placeholder.Image/>
                                </Placeholder>
                            </div>
                        </Grid.Column>
                        <Grid.Column mobile={9} tablet={9} computer={9} className="stories-info-wrapper">
                            <Grid>
                                <Grid.Column width={16}>
                                    <div className="storys-description">
                                        <Placeholder>
                                            <Placeholder.Line/>
                                            <Placeholder.Line/>
                                        </Placeholder>
                                    </div>
                                </Grid.Column>
                            </Grid>

                            <div className="stories-info">
                                <span><Image alt="Clock Icon" title="Arbeitszeiten" className="storys-icon" src={clockIcon}/>-</span>
                                <span><Image alt="Euro Icon" title="Kosten" className="storys-icon" src={eurIcon}/>-</span>
                                <Responsive className="stories-feed" minWidth={Responsive.onlyTablet.minWidth}>
                                    <span><Image alt="Likes Icon" title="Gefällt mir Angaben" className="storys-icon" src={heartIcon}/>-</span>
                                    <span><Image alt="Comment Icon" title="Kommentare" className="storys-icon" src={commentIcon}/>-</span>
                                </Responsive>
                            </div>
                        </Grid.Column>
                    </Grid>
                </Segment>
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { projectById, storyById, listByEntity } = state
    const { item, isDirty, isFetching, isFailed, isDeleted } = projectById[
        ownProps.match.params.id
    ] || {
        item: {},
        isDirty:false,
        isFetching: true,
        isFailed: false,
        isDeleted: false,
    }

    const { list, isFetching: isFetching2, isFailed: isFailed2 } = listByEntity[
        ownProps.match.params.id+'::story'
    ] || {
        list: {},
        isFetching: true,
        isFailed: false,
    }

    var listitems = [];

    if (list) {
        for (let [key, value] of Object.entries(list)) {
            if (storyById[value] && !storyById[value].isDeleted) {
                var listitem = {...storyById[value]};
                var contents = [];
                for (var k in listitem.contents) {
                    contents.push(listitem.contents[k]);
                };
                listitem.contents = [].concat(contents).sort((a, b) => a.position > b.position ? 1 : -1);
                listitems.push(listitem);
            }
        }
    }
    listitems.sort((a, b) => a.item.position > b.item.position ? 1 : -1);

    // Fix corrupt positions
    for (var i = 0; i < listitems.length; ++i) {
        listitems[i].item.position = i;
    }

    return {
        listitems,
        item,
        isDirty,
        isFetching: (isFetching || isFetching2),
        isFailed: (isFailed || isFailed2),
        isDeleted,
    }

}

export default connect(mapStateToProps)(withRouter(Storys));
