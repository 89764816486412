import React from 'react';
import {
    Header,
    Container,
    Divider,
    Message,
    Segment,
    Grid,
    Button,
    Image,
    Form,
    Placeholder,
    Icon,
    Responsive,
    Dropdown
 } from 'semantic-ui-react';
import ProjectNav from '../../../components/layout/ProjectNav';
import CustomSlidingPane from '../../../components/CustomSlidingPane/CustomSlidingPane'
import Swipeout from 'rc-swipeout';
import PlusButton from '../../../components/PlusButton/PlusButton'
import 'rc-swipeout/assets/index.css'
import { connect } from 'react-redux';
import Select from 'react-select'
import { FileUpload } from 'ix-redux-file-upload';
import TextareaAutosize from 'react-textarea-autosize';
import Gallery from '../../../components/Gallery/Gallery';
import BeParent from './be-parent'
import { handleContainer, setRuntime } from '../../../actions/global';
import fetchProjectIfNeeded from '../../../actions/project';
import fetchAssemblyListIfNeeded, {newAssembly, postAssembly, deleteAssembly, changeAssembly, discardAssembly, updateAssembly} from '../../../actions/assembly';
import config from '../../../config/config'
import ProgressiveImager from '../../../components/helpers/progressive-imager';
import ImageWrapper from '../../../components/helpers/image-wrapper';
import CustomPopup from '../../../components/CustomPopup/CustomPopup';
import Moment from 'react-moment';

import menuDotsIcon_2 from '../../../images/icons/footer-menu-dots_2.svg';
import trashIcon from '../../../images/icons/icon-swipe-trash.svg';
import './Backend.scss'
import PreviewGallery from '../../../components/PreviewGallery/PreviewGallery';


class Assembly extends BeParent {

    inputFields = ['title', 'description', 'sortingCategory', 'projectPart', 'media'];

    constructor(props) {
        super(props)

        this.handleInput = this.handleInput.bind(this);
        this.handleInputAlt = this.handleInputAlt.bind(this);
        this.handleInputSelect = this.handleInputSelect.bind(this);
        this.submitValidate = this.submitValidate.bind(this);
        this.submit = this.submit.bind(this);
        this.discard = this.discard.bind(this);
        this.showButton = this.showButton.bind(this);

        this.state = {
            galleryOpen: false,
            currentSlide: 0,
            confirmModal: {
                isOpen: false
            },
            assemblyItem: {
                isOpen: false,
                title: "",
                content: "",
                itemId: null
            },
            popup: {
                isOpen: false
            },
            formValidation: {
                check: true,
                checkMsg: '',
                validationTitle: {
                    error: false,
                    message: ''
                },
            },
            closeAfterSave: false,
            windowWidth: 0,
        }
    }

    componentDidMount() {
        this.setDimensions();
        window.addEventListener('resize', this.setDimensions);
    }

    handleInput(e, element) {
        const { dispatch, match } = this.props;
        var name = element.name.split('.');
        var field = name[1];

        dispatch(newAssembly(match.params.id, field, element.value));
    }

    handleInputAlt(e) {
        const element = e.currentTarget;
        this.handleInput(null, element);
    }

    handleInputSelect(opt, element) {
        const { dispatch, match } = this.props;
        var name = element.name.split('.');
        var field = name[1];

        dispatch(newAssembly(match.params.id, field, opt.value));
    }

    handleMediaDelete = (id) => {
        const { dispatch, assemblys, item, inputMedia } = this.props;
        const { assemblyItem } = this.state;

        let updatedArray = [];
        if(assemblyItem.content === 'new-assembly'){
            inputMedia.filter(assemblyMedia => assemblyMedia.id !== id).map(item => updatedArray.push(item))
            dispatch(newAssembly(item.id, 'inputMedia', updatedArray));
        }
        else if(assemblyItem.content === 'edit-assembly'){
            assemblys.filter(assembly => assembly.id === assemblyItem.itemId).map(item => {
                item.input.media.filter(assemblyMedia => assemblyMedia.id !== id).map(item => updatedArray.push(item))
                item.input.media = updatedArray;

                dispatch(changeAssembly(item));
            })
        }

    }

    submitValidate() {

        const { dispatch, match, inputTitle, assemblys } = this.props;
        const { formValidation, assemblyItem } = this.state;

        // New
        if (assemblyItem.content === "new-assembly") {
            this.setState({
                formValidation: {
                    ...formValidation,
                    validationTitle: config.validateTextField(inputTitle, true),
                }
            }, this.submit)
        }
        // Edit
        else if (assemblyItem.itemId) {
            var assembly = assemblys.filter((item) => item.id === assemblyItem.itemId)[0];
            this.setState({
                formValidation: {
                    ...formValidation,
                    validationTitle: config.validateTextField(assembly.input.title, true),
                }
            }, this.submit)
        }
    }

    submit() {

        const { dispatch, match, inputTitle, inputDescription, inputSortingcategory, inputProjectpart, inputMedia, assemblys, isPosting } = this.props;
        const { formValidation, assemblyItem } = this.state;

        if (
            !formValidation.validationTitle.error
        ) {
            this.setState({
                formValidation: {
                    ...formValidation,
                    check: true,
                    checkMsg: "",
                    validationTitle: {
                        error: false
                    },
                }
            });

            if (assemblyItem.content === "new-assembly") {
                dispatch(postAssembly(match.params.id, inputTitle, inputDescription, inputSortingcategory, inputProjectpart, inputMedia));
            }
            else {
                var assembly = assemblys.filter((item) => item.id === assemblyItem.itemId)[0];
                dispatch(updateAssembly(assembly.input));
                this.setState({closeAfterSave: true});
            }
        }
    }

    componentDidMount() {
        const { dispatch, match, item } = this.props;

        dispatch(fetchProjectIfNeeded(match.params.id));
        dispatch(fetchAssemblyListIfNeeded(match.params.id));
        dispatch(handleContainer("secondary", item.title, false, "dashboard"));
        dispatch(setRuntime({page: 'be-assemblies'}));
    }

    componentDidUpdate(prevProps, prevState) {
        const { isPosting, isFetching, isDeleting, postingMessage } = this.props;
        const { closeAfterSave } = this.state;

        if (prevProps.isPosting !== isPosting && !isPosting && postingMessage === 'SUCCESS') {
            this.handleAssemblyItem(false);
        }

        if (closeAfterSave && prevProps.isFetching && !isFetching) {
            this.handleAssemblyItem(false);
            this.setState({closeAfterSave: false});
        }

        if (prevProps.isDeleting && !isDeleting) {
            this.setState({popup: {isOpen: false}});
        }
    }

    handleAssemblyItem = (isOpen, title, content, itemId) => {
        this.setState({
            assemblyItem: {
                isOpen,
                title,
                content,
                itemId,
            }
        })
    }

    handleAssemblyContent = () => {
        const { assemblyItem, content, itemId } = this.state;

        switch(assemblyItem.content){
            case "new-assembly":
                return this.assembly(false)
            case "edit-assembly":
                return this.assembly(true, assemblyItem.itemId)
            default:
                return null
        }
    }

    discard() {
        const { dispatch, item } = this.props;
        const { assemblyItem } = this.state;

        // Discard changes
        if (assemblyItem.content === "new-assembly") {
            dispatch(newAssembly(item.id, 'inputTitle', null));
            dispatch(newAssembly(item.id, 'inputDescription', null));
            dispatch(newAssembly(item.id, 'inputSortingcategory', null));
            dispatch(newAssembly(item.id, 'inputProjectpart', null));
            dispatch(newAssembly(item.id, 'inputMedia', null));
            dispatch(newAssembly(item.id, 'postingMessage', ''));
        }
        else {
            dispatch(discardAssembly((assemblyItem.itemId ? assemblyItem.itemId : null), item.id));
        }

        // Reset validation
        this.setState({
            formValidation: {
                ...this.state.formValidation,
                check: true,
                checkMsg: "",
                validationTitle: {
                    error: false
                },
            }
        });

        // Close popup/pane
        this.handleAssemblyItem(false);
        this.setState({
            confirmModal: {
                isOpen: false
            },
        });
    }

    editAssemblyClose = () => {
        const { inputTitle, inputDescription, inputSortingcategory, inputProjectpart, inputMedia, assemblys, item } = this.props;
        const { assemblyItem } = this.state;

        if (assemblyItem.content === "new-assembly") {
            if (inputTitle || inputDescription ||inputSortingcategory || inputProjectpart || inputMedia) {
                this.setState({
                    confirmModal: {
                        isOpen: true
                    },
                })
            }
            else {
                this.handleAssemblyItem(false);
            }
        }

        if (assemblyItem.itemId) {
            if (config.isChanged(assemblys.filter((item) => item.id === assemblyItem.itemId)[0], this.inputFields)) {
                this.setState({
                    confirmModal: {
                        isOpen: true
                    },
                })
            }
            else {
                this.handleAssemblyItem(false);
            }
        }
    }

    showButton() {
        const { inputTitle, inputDescription, inputSortingcategory, inputProjectpart, inputMedia, assemblys } = this.props;
        const { assemblyItem } = this.state;

        if (assemblyItem.content === "new-assembly" && (inputTitle || inputDescription ||inputSortingcategory || inputProjectpart || inputMedia)) {
            return true;
        }

        if (assemblyItem.itemId && (config.isChanged(assemblys.filter((item) => item.id === assemblyItem.itemId)[0], this.inputFields))) {
            return true;
        }

        return false;
    }

    render(){
         const { match, isFetching, isFailed, assemblys } = this.props;
         const { formValidation } = this.state;

         if ((!assemblys || !assemblys.length) && isFetching) {
             return (<>

                <ProjectNav active="montagehilfe"  project={{id: match.params.id}}/>
                <Container style={{margin: "15px 0"}}>
                    <Grid>
                        <Grid.Column floated="left" width={4}>
                            <Header as="h2" className="text-placeholder">Montagehilfen</Header>
                        </Grid.Column>
                        <Grid.Column floated="right" width={8} textAlign="right">
                            <PlusButton
                                title="Neue Montagehilfe"
                                color="red"
                                disabled={true}
                            />
                        </Grid.Column>
                    </Grid>
                    <Message color="teal" className="text-placeholder">
                        <Placeholder>
                            <Placeholder.Line length='full' />
                            <Placeholder.Line length='short' />
                        </Placeholder>
                    </Message>
                 </Container>
                    <Segment className="part-cost-placeholder">
                        <Placeholder>
                            <Placeholder.Line length='full' />
                            <Placeholder.Line length='very long' />
                            <Placeholder.Line length='short' />
                        </Placeholder>
                    </Segment>
                    <Segment className="part-cost-placeholder">
                        <Placeholder>
                            <Placeholder.Line length='full' />
                            <Placeholder.Line length='very long' />
                            <Placeholder.Line length='short' />
                        </Placeholder>
                    </Segment>
                    <Segment className="part-cost-placeholder">
                        <Placeholder>
                            <Placeholder.Line length='full' />
                            <Placeholder.Line length='very long' />
                            <Placeholder.Line length='short' />
                        </Placeholder>
                    </Segment>
                </>);
         }
         if (isFailed) {
             return (
                 <Container>
                     <Divider hidden/>
                     <Message color="red">
                         Es ist ein Fehler beim Abrufen der Daten aufgetreten.
                     </Message>
                 </Container>
             );
         }

         return(
             <div>
                 {this.confirmModal(this.discard)}

                 <ProjectNav active="montagehilfe" project={{id: this.props.match.params.id}}/>
                 <Container style={{margin: "15px 0"}}>
                    <Grid>
                        <Grid.Column floated="left" width={4}>
                            <Header as="h2">Montagehilfen</Header>
                        </Grid.Column>
                        <Grid.Column floated="right" width={8} textAlign="right">
                            <PlusButton
                                title="Neue Montagehilfe"
                                color="red"
                                onClick={() => this.handleAssemblyItem(true, "Montagehilfe anlegen", "new-assembly")}
                            />
                        </Grid.Column>
                    </Grid>
                    <Message color="teal">
                        Hier kannst du dir Anleitungen, Bilder und Videos speichern, die dir dabei helfen bei der Montage alles wieder korrekt zusammen zu bekommen.
                    </Message>
                 </Container>
                 <Divider hidden/>
                 {(!assemblys || !assemblys.length) &&
                     <Container>
                         <Divider hidden/>
                         <Message color="teal">
                             Für dieses Projekt wurden noch keine Montagehilfen angelegt.
                         </Message>
                     </Container>
                 }
                 {assemblys &&
                     <>
                         {assemblys.map(item =>(
                             <div style={{marginBottom: "1rem"}}>
                                <Swipeout
                                    autoClose
                                    right={[
                                        {
                                            text: <div
                                                    className="swipeout-img-container"
                                                    onTouchEnd={() => this.setState({popup: {isOpen: true, deleteid: item.id}})}
                                                   >
                                                       <div className="swipeout-img">
                                                            <Image src={trashIcon}/>
                                                            {item.media.length > 0 ? <span>Löschen</span> : ''}
                                                       </div>
                                                  </div>,
                                            style: {
                                                backgroundColor: "#E7344C",
                                                color: "white",
                                                textAlign: "center",
                                                fontWeight: "bold",
                                                width: "92px",
                                                height: "100%",
                                                cursor: "pointer"
                                            },

                                        }
                                    ]}
                                >
                                    <Segment onClick={() => this.handleAssemblyItem(true, "Montagehilfe bearbeiten", "edit-assembly", item.id)} className="assembly-item editable-list-item">
                                        <Grid columns={3}>
                                            <Grid.Column width="12">
                                                {item.done && <Icon name="check circle outline" color="green" size="large" style={{verticalAlign: 'baseline'}} />}
                                                <span className="assembly-title">{item.title}</span>
                                                {item.projectPart && this.props.item.projectpartsData.filter(pp => pp.id === item.projectPart).map(pp => (
                                                    <span className="sub-title">{pp.title}</span>
                                                ))}
                                                {item.sortingCategory &&
                                                    <span className="sub-title category">{config.getLabel(config.getCategoriesSub(), item.sortingCategory)}</span>
                                                }
                                            </Grid.Column>
                                            <Grid.Column mobile={4} tablet={4} computer={2} textAlign="right">
                                                {item.createDate > 0 &&
                                                    <span className="date">
                                                        <Moment unix format="DD.MM.YY">{item.createDate}</Moment>
                                                    </span>
                                                }
                                            </Grid.Column>
                                            <Grid.Column
                                                className="elements-dots-menu"
                                                textAlign="right"
                                                only="computer"
                                                width={2}
                                                style={{
                                                    lineHeight: 0,
                                                    marginTop: "-0.25rem"
                                                }}
                                            >
                                                <div className="elements-options-menu">
                                                    <Dropdown icon={<Image className="elements-options-menu-dots" src={menuDotsIcon_2}/>}>
                                                        <Dropdown.Menu style={{left: 'auto', right: 0}}>
                                                            <Dropdown.Item className="delete-story" onClick={() => this.setState({popup: {isOpen: true, deleteid: item.id}})}>
                                                                <Image className="elements-options-menu-icon" src={trashIcon}/>
                                                                <span>Löschen</span>
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </Grid.Column>
                                        </Grid>
                                        {item.media && item.media.length > 0 &&
                                            <>
                                                <Divider hidden style={{margin: '0.5rem 0'}} />
                                                {this.mediaPreview(item.media)}
                                            </>
                                        }
                                    </Segment>
                                </Swipeout>
                             </div>
                         ))}
                     </>
                 }

                {/* Handle content - mobile sliding pane */}

                <Responsive
                    as={CustomSlidingPane}
                    {...Responsive.onlyMobile}
                    isOpen={this.state.assemblyItem.isOpen}
                    title={this.state.assemblyItem.title}
                    onRequestClose={this.editAssemblyClose}
                    saveButtonCallback={this.submitValidate}
                    saveButton={this.showButton()}
                    isLoading={this.props.isPosting || this.props.isFetching}
                >
                    {this.handleAssemblyContent()}
                </Responsive>

                {/* Handle content - desktop popup */}

                <Responsive
                    as={CustomPopup}
                    minWidth={Responsive.onlyTablet.minWidth}
                    popupOpen={this.state.assemblyItem.isOpen}
                    popupHeader={this.state.assemblyItem.title}
                    onClose={this.editAssemblyClose}
                    saveButtonCallback={this.submitValidate}
                    saveButton={this.showButton()}
                    isLoading={this.props.isPosting || this.props.isFetching}
                    onDocumentClick={
                        formValidation.validationTitle.error ||
                        !formValidation.check ?
                        false : true
                    }
                    showCloseIcon
                    className="larger-popup"
                >
                    {this.handleAssemblyContent()}
                </Responsive>

                {/* Delete item confirm popup */}

                <CustomPopup
                    popupOpen={this.state.popup.isOpen}
                    popupHeader="Montagehilfe löschen"
                    onClose={() => this.setState({popup: {isOpen: false}})}
                    showCloseIcon
                >
                    {this.confirmDelete()}
                </CustomPopup>

             </div>
         )
     }

     confirmDelete(){
         const { isDeleting } = this.props;
         return (
            <Container>
                <Divider hidden/>
                    <div style={{textAlign: "center", margin: "1rem 0"}}>
                        <Icon name="warning sign" color="red" size="huge"/>
                    </div>
                    <div style={{textAlign: "center"}}>Willst du die Montagehilfe wirklich löschen?</div>
                    <Divider hidden/>
                    <div className="buttons-center">
                        <Button color="red" onClick={isDeleting ? null : () => {
                            this.setState({popup: {isOpen: false}});
                        }}>
                            Zurück
                        </Button>
                        <Button
                            loading={isDeleting}
                            basic
                            color="red"
                            onClick={isDeleting ? null : () => {
                                this.props.dispatch(deleteAssembly(this.props.match.params.id, this.state.popup.deleteid));
                            }
                        }>
                            Löschen
                        </Button>
                    </div>
                    <Divider hidden/>
            </Container>
         )
     }

     assembly(edit, id) {
         const { item: project, token, assemblys, match, inputTitle, inputDescription, inputSortingcategory, inputProjectpart, inputMedia, postingMessage } = this.props;
         const { formValidation, galleryOpen, currentSlide } = this.state;
         let mobileGallery = [];
         let gallery = [];

        //Push gallery images
        assemblys.filter(item => item.id === id).map(item => {
            item.input.media.map((m, index) => mobileGallery.push({src: config.getImageUrl('zoom', m.path), type: m.type}))
            item.input.media.map((m, index) => gallery.push({src: config.getImageUrl('zoomDesktop', m.path), type: m.type}))
        });

         if (edit) {
             return (
                 <div>
                     {assemblys.filter(item => item.id === id).map(item => (
                         <div>
                             <Container>
                                 <Form>
                                     <Form.Input
                                         label="Bezeichnung"
                                         value={item.input.title}
                                         name={"assembly." + item.id + ".title"}
                                         onChange={this.handleChangeInput}
                                         error={formValidation.validationTitle.error}
                                     />

                                     {formValidation.validationTitle.error &&
                                         <Message color="red">
                                             {formValidation.validationTitle.message}
                                         </Message>
                                     }

                                     <Form.Field>
                                         <label>Anleitung (optional)</label>
                                         <TextareaAutosize
                                             minRows="5"
                                             maxRows="15"
                                             name={"assembly." + item.id + ".description"}
                                             onChange={this.handleChangeInputAlt}
                                             value={item.input.description}
                                         />
                                     </Form.Field>

                                     <Form.Field>
                                         <label>Kategorie (optional)</label>
                                         <Select menuPlacement="auto"
                                             options={config.getCategoriesSub()}
                                             name={"assembly."+item.id+".sortingCategory"}
                                             onChange={this.handleChangeSelect}
                                             value={config.getDefault(config.getCategoriesSub(), item.input.sortingCategory)}
                                             classNamePrefix="custom-select"
                                             className="custom-select-wrapper"
                                         />
                                     </Form.Field>

                                     <Form.Field>
                                         <label>Story zuweisen (optional)</label>
                                         <Select menuPlacement="auto"
                                             options={config.buildChoice(project.projectpartsData, 'title')}
                                             name={"assembly."+item.id+".projectPart"}
                                             classNamePrefix="custom-select"
                                             className="custom-select-wrapper"
                                             onChange={this.handleChangeSelect}
                                             value={config.getDefault(config.buildChoice(project.projectpartsData, 'title'), item.input.projectPart)}
                                         />
                                     </Form.Field>

                                 </Form>
                             </Container>
                             {/* PDFs/Images */}
                             <Divider hidden />
                             {/* Images */}
                             <FileUpload
                                 headers={{ 'Authorization': 'Bearer ' + token }}
                                 allowedFileTypes={['jpg', 'jpeg', 'mp4', 'video/quicktime']}
                                 dropzoneId={"assemblysByProject." + match.params.id + ".assembly." + item.id + ".media"}
                                 multiple={true}
                                 url={config.API_HOST + config.API_UPLOAD}
                             >
                                 <div style={{ marginTop: "2rem", textAlign: "center" }}>
                                     <Button color="red">Bilder/Videos hinzufügen</Button>
                                 </div>
                             </FileUpload>
                             <Divider hidden />
                             {item.input.media &&
                                <PreviewGallery
                                    imagesPerRow={3}
                                    deletable
                                    images={item.input.media}
                                    requestDelete={this.handleMediaDelete}
                                />
                             }

                            {/* Include lightbox */}
                            <Responsive {...Responsive.onlyMobile}>
                                <Gallery
                                    images={mobileGallery.filter(m => m.type === config.FILE_IMAGE).map(item => item.src)}
                                    galleryOpen={galleryOpen === item.id}
                                    slide={currentSlide}
                                    onClose={() => this.setState({ galleryOpen: false })}
                                    onMovePrevRequest={() =>
                                        this.setState({
                                            currentSlide: (currentSlide + mobileGallery.filter(m => m.type === config.FILE_IMAGE).length - 1) % mobileGallery.filter(m => m.type === config.FILE_IMAGE).length,
                                        })
                                    }
                                    onMoveNextRequest={() =>
                                        this.setState({
                                            currentSlide: (currentSlide + 1) % mobileGallery.filter(m => m.type === config.FILE_IMAGE).length,
                                        })
                                    }
                                />
                            </Responsive>

                            <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                               <Gallery
                                    images={gallery.filter(m => m.type === config.FILE_IMAGE).map(item => item.src)}
                                    galleryOpen={galleryOpen === item.id}
                                    slide={currentSlide}
                                    onClose={() => this.setState({ galleryOpen: false })}
                                    onMovePrevRequest={() =>
                                        this.setState({
                                            currentSlide: (currentSlide + gallery.filter(m => m.type === config.FILE_IMAGE).length - 1) % gallery.filter(m => m.type === config.FILE_IMAGE).length,
                                        })
                                    }
                                    onMoveNextRequest={() =>
                                        this.setState({
                                            currentSlide: (currentSlide + 1) % gallery.filter(m => m.type === config.FILE_IMAGE).length,
                                        })
                                    }
                               />
                            </Responsive>

                             <Divider hidden />
                         </div>
                     ))}

                 </div>
             )
         }
         else {
             return (
                 <>
                 <Container>
                     {postingMessage === 'FAILURE' &&
                         <Message negative>Beim Senden ist ein Fehler aufgetreten.</Message>
                     }

                     <Form>
                         <Form.Input
                             label="Bezeichnung"
                             required
                             value={inputTitle}
                             name={"assembly.inputTitle"}
                             error={formValidation.validationTitle.error}
                             onChange={this.handleInput}
                         />

                         {formValidation.validationTitle.error &&
                             <Message color="red">
                                 {formValidation.validationTitle.message}
                             </Message>
                         }

                         <Form.Field>
                             <label>Anleitung (optional)</label>
                             <TextareaAutosize
                                 minRows="5"
                                 maxRows="15"
                                 name={"assembly.inputDescription"}
                                 onChange={this.handleInputAlt}
                                 value={inputDescription}
                             />
                         </Form.Field>

                         <Form.Field>
                             <label>Kategorie (optional)</label>
                             <Select menuPlacement="auto"
                                 options={config.getCategoriesSub()}
                                 name={"assembly.inputSortingcategory"}
                                 onChange={this.handleInputSelect}
                                 value={config.getDefault(config.getCategoriesSub(), inputSortingcategory)}
                                 classNamePrefix="custom-select"
                                 className="custom-select-wrapper"
                             />
                         </Form.Field>

                         <Form.Field>
                             <label>Story zuweisen (optional)</label>
                             <Select menuPlacement="auto"
                                 options={config.buildChoice(project.projectpartsData, 'title')}
                                 name="assembly.inputProjectpart"
                                 onChange={this.handleInputSelect}
                                 classNamePrefix="custom-select"
                                 className="custom-select-wrapper"
                                 value={config.getDefault(config.buildChoice(project.projectpartsData, 'title'), inputProjectpart)}
                             />
                         </Form.Field>

                     </Form>
                     </Container>
                     <Divider hidden />
                     {/* Images */}
                     <FileUpload
                         headers={{ 'Authorization': 'Bearer ' + token }}
                         allowedFileTypes={['jpg', 'jpeg', 'mp4', 'video/quicktime']}
                         dropzoneId={"assemblysByProject." + match.params.id + ".inputMedia"}
                         multiple={true}
                         url={config.API_HOST + config.API_UPLOAD}
                     >
                         <div style={{ marginTop: "2rem", textAlign: "center" }}>
                             <Button color="red">Bilder/Videos hinzufügen</Button>
                         </div>
                     </FileUpload>
                     <Divider hidden />

                     {inputMedia && 
                        <PreviewGallery
                            imagesPerRow={3}
                            deletable
                            images={inputMedia}
                            requestDelete={this.handleMediaDelete}
                        />
                     
                     }
                     <Divider hidden />
            </> )
         }
     }
 }

function mapStateToProps(state, ownProps) {
    const { globalByComponent, projectById, assemblysByParent, storyById, account } = state;
    // var collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });

    const { item, isFetching, isFailed, isDeleted } = projectById[
        ownProps.match.params.id
    ] || {
        item: {},
        isFetching: true,
        isFailed: false,
        isDeleted: false,
    }

    const { items, isFetching: isFetching2, isFailed: isFailed2, isPosting, postingMessage, inputTitle, inputDescription, inputSortingcategory, inputProjectpart, inputMedia, isDeleting } = assemblysByParent[
        'project::'+ownProps.match.params.id
    ] || {
        items: {},
        isFetching: true,
        isFailed: false,
        isDeleting: false
    }
    var assemblys = [];
    if (items) {
        assemblys = [].concat(Object.values(items)).sort((a, b) => a.id < b.id ? 1 : -1);
    }

    const { token } = account || {
        token: null
    }

    return {
        token,
        assemblys,
        item,
        isFetching: (isFetching || isFetching2),
        isFailed: (isFailed || isFailed2),
        isDeleted,
        isDeleting,
        isPosting,
        postingMessage,
        inputTitle,
        inputDescription,
        inputSortingcategory,
        inputProjectpart,
        inputMedia,
        isDeleting,
    }
}

 export default connect(mapStateToProps)(Assembly);
