import React, { Component } from 'react';
import { Container, Responsive } from 'semantic-ui-react';
import config from '../../../../config/config';
import ProgressiveImager from '../../../../components/helpers/progressive-imager';
import ProjectCard from '../../../../components/Card/ProjectCard';
import Modal from 'react-modal';
import { motion, AnimatePresence } from "framer-motion";
import { RemoveScrollBar } from 'react-remove-scroll-bar'
import '../Frontend.scss';

//Render react-modal on body element
Modal.setAppElement('body');

class ProjectListGalleryWrapper extends Component {

    render(){

        const { list, loading } = this.props;

        return (
            <>
                {/* RENDER for mobile - 3 items per row */}
                <Responsive {...Responsive.onlyMobile}>
                    <ProjectListGallery list={list} loading={loading} itemsPerRow={3}/>
                </Responsive>

                {/* RENDER for tablet/desktop - 4 items per row */}
                <Responsive minWidth={Responsive.onlyTablet.minWidth} maxWidth={Responsive.onlyLargeScreen.minWidth - 1}>
                    <ProjectListGallery list={list} loading={loading} itemsPerRow={4}/>
                </Responsive>

                {/* RENDER for desktop - large screen - 6 items per row */}
                <Responsive minWidth={Responsive.onlyLargeScreen.minWidth}>
                    <ProjectListGallery list={list} loading={loading} itemsPerRow={6}/>
                </Responsive>
            </>
        )
    }
}

class ProjectListGallery extends Component {

    state = {
        projectLightbox: {
            isOpen: false,
            projectId: 0
        }
    }

    componentDidMount = () => {
        //Handle closing project lightbox by clicking on overlay 
        document.getElementById('root').addEventListener('click', this.handleClick, false);
    }

    componentWillUnmount = () => {
        document.getElementById('root').removeEventListener('click', this.handleClick, false);
    }

    //Handle closing project lightbox by clicking on overlay 
    handleClickOutside = () => {
        this.setState({projectLightbox: { isOpen: false, projectId: 0 }})
    }

    //Handle closing project lightbox by clicking on overlay 
    handleClick = (e) => {
        document.getElementById('root').addEventListener('click', this.handleClick, false);
        if(this.node && this.node.contains(e.target) && e.target.className !== "project-lightbox" ){
            return;
        }

        this.handleClickOutside();
    }

    handleProjectLightbox = (id) => {
        const { projectLightbox } = this.state;

        this.setState({ 
            projectLightbox: {
                isOpen: !projectLightbox.isOpen,
                projectId: id
            }
        });

    }

    render() {

        const { list, itemsPerRow, hasMore, loading } = this.props;
        const { projectLightbox } = this.state;

        let itemsSize = itemsPerRow;
        let items;
        if(list) {
            items = list.map((item) => {
                return (
                    <>
                        <div 
                            className={`${item.item.id === projectLightbox.projectId ? 'project-item active': 'project-item'}`}
                            onClick={() => this.handleProjectLightbox(item.item.id)}
                            key={item.item.id}
                        >
                            <Responsive {...Responsive.onlyMobile}>
                                <ProgressiveImager
                                    src={config.getImageUrl('halfwidth', item.item.mediamainPath)}
                                    alt={item.item.title+' von '+item.item.userData.name+' – Bild: '+item.item.mediamain}
                                    className="responsive"
                                />
                            </Responsive>

                            <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                                <ProgressiveImager
                                    src={config.getImageUrl('main', item.item.mediamainPath)}
                                    alt={item.item.title+' von '+item.item.userData.name+' – Bild: '+item.item.mediamain}
                                    className="responsive"
                                />
                            </Responsive>
                            
                        </div>
                    </>
                )    
            }).reduce((arr, el, index) => {
                index % itemsSize === 0 && arr.push([]);
                arr[arr.length-1].push(el)
                return arr;
            }, []).map((rowContent, index) => {
                //Render for mobile
                if(itemsSize === 3 && (index+1) % 2 === 0){
                    
                    return (
                        <div 
                            key={index}
                            className={
                                `
                                    row 
                                    ${!hasMore && (index+3) % 4 === 0 ? 'reversed' : ''}
                                    ${hasMore && !rowContent[2] ? 'half' : ''}
                                    ${hasMore && !rowContent[1] ? 'full' : ''}
                                `
                            }
                        >
                            <div className="left">
                                {rowContent[0]}
                            </div>
                            {rowContent[1] && 
                                <div className="right">
                                    {rowContent[1]}
                                    {rowContent[2]}
                                </div>
                            }
                        </div>
                    )
                }
                //Render for tablet/desktop
                else if(itemsSize === 4 && (index+1) % 2 === 0){
                    return (
                        <div key={index} className={`row ${(index+3) % 4 === 0 && hasMore && rowContent[1] ? 'reversed' : ''}`}>
                            <div className="left">
                                {rowContent[0]}
                            </div>
                            <div className="right">
                                <div className={`top-image ${hasMore && !rowContent[2] ? 'full' : ''}`}>
                                    {rowContent[1]}
                                </div>

                                {rowContent[2] &&
                                    <div className={`bottom-images ${!hasMore && !rowContent[3] ? 'one-image' : ''}`}>
                                        {rowContent[2]}
                                        {rowContent[3]}
                                    </div>
                                }
                            </div>
                        </div>
                    )
                }
                //Render for desktop - large screen
                else if(itemsSize === 6 && (index+1) % 2 === 0){
                    return (
                        <div key={index} className={`row ${(index+3) % 4 === 0 && !hasMore && rowContent[1] ? 'reversed' : ''}`}>
                            <div className="left">
                                {rowContent[0]}
                                {rowContent[1]}
                            </div>
                            <div className="right">
                                <div className={`top-image ${!hasMore && !rowContent[2] ? 'full' : ''}`}>
                                    {rowContent[2]}
                                    {rowContent[3]}
                                </div>

                                {rowContent[4] &&
                                    <div className={`bottom-images ${!hasMore && !rowContent[3] ? 'one-image' : ''}`}>
                                        {rowContent[4]}
                                        {rowContent[5]}
                                    </div>
                                }
                            </div>
                        </div>
                    )
                }
                else {
                    return (
                        <div key={index} className={`row default-row`}>
                            {rowContent}
                        </div>
                    )
                }
            })
        }

        //Output

        return (
            <>
            <div className="gallery-view-wrapper"  ref={node => this.node = node}>

                <div className="gallery-view">
                    {items}
                </div>

                {/* Project info - lightbox */}
                <AnimatePresence>
                    {projectLightbox.isOpen && 
                        <motion.div 
                            className={`project-lightbox`} 
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0, transition: { duration: 0.3 } }}
                            transition={{ duration: 0.3, delay: 0.15 }}
                            style={{ pointerEvents: "auto" }}
                        >
                            
                           
                            {list.filter(project => project.item.id === projectLightbox.projectId).map(item => (
                                <Container className="project-card-wrapper" key={item.item.id}>
                                    <RemoveScrollBar/>
                                    <ProjectCard onClose={() => this.handleClickOutside()} closeButton sumMedias={item.sumMedias} item={item.item} />
                                </Container>
                            ))}
                        </motion.div>
                    }
                </AnimatePresence>
            </div>
            </>
        )

    }
}



export default ProjectListGalleryWrapper;
