import React from 'react';
import { Container, Image, Responsive, Divider, Form, Button, Loader, Message, Icon, Header } from 'semantic-ui-react';
import Select from 'react-select'
import { connect } from 'react-redux';
import {RemoveScrollBar} from 'react-remove-scroll-bar';
import { FileUpload } from 'ix-redux-file-upload';
import CustomSlidingPane  from '../../components/CustomSlidingPane/CustomSlidingPane';
import CustomPopup from '../../components/CustomPopup/CustomPopup';
import {newFeedpost, postFeedpost, resetNotificationList, fetchNotificationList} from '../../actions/notification'
import ImageWrapper from '../../components/helpers/image-wrapper';
import config from '../../config/config'
import ProgressiveImager from '../../components/helpers/progressive-imager';
import BeParent from '../project/backend/be-parent'
import {fetchUserProjectsIfNeeded} from '../../actions/project';
import TextareaAutosize from 'react-textarea-autosize';
/* Icons */
import cameraIcon from '../../images/icons/frontend/icon-camera.svg';
import cameraIconRed from '../../images/icons/frontend/icon-camera-red.svg';
import { Component } from 'react';
import ReactVisibilitySensor from 'react-visibility-sensor';
import PreviewGallery from '../../components/PreviewGallery/PreviewGallery';

class NewsfeedInput extends BeParent {

    constructor() {
        super();

        this.state = {
            galleryOpen: false,
            currentSlide: 1,
            showCommentsDesktop: false,
            postPopup: false,
            enableSend: false,
            formValidation: {
                post: {
                    error: false,
                    message: ''
                }
            },
            postInput: '',
            modal: {
                isOpen: false,
                title: "",
                content: "",
                buttonLabel: "Veröffentlichen"
            },
            confirmModal: {
                isOpen: false
            },
        }

        this.submit = this.submit.bind(this);
        this.showButton = this.showButton.bind(this);
        this.feedpostClose = this.feedpostClose.bind(this);
        this.discard = this.discard.bind(this);
    }

    componentDidMount(){
        const { dispatch, account } = this.props;

        dispatch(fetchUserProjectsIfNeeded(account.item.id));
    }

    componentDidUpdate(prevProps, prevState) {
        const { dispatch, inputFeedpost } = this.props;
        const { modal } = this.state;
        if(inputFeedpost.newId && inputFeedpost.newId !== prevProps.inputFeedpost.newId) {
            dispatch(resetNotificationList());
            dispatch(fetchNotificationList());
            this.setState({postPopup: false});
            this.handleModal(false);
        }
        
        if(modal.isOpen && modal.isOpen !== prevState.modal.isOpen){
            document.body.style.position = "fixed";
        }
        else {
            document.body.style.position = "";
        }

    }

    showButton() {
        const { inputFeedpost } = this.props;

        if (inputFeedpost.text || inputFeedpost.project || (inputFeedpost.media && inputFeedpost.media.length > 0)) {
            return true;
        }

        return false;
    }

    handleInput = (e) => {
        const { postInput } = this.state;
        this.setState({postInput: e.target.value})
    }

    discard() {
        const { dispatch } = this.props;

        // Discard changes
        dispatch(newFeedpost({
            'text': null,
            'project': null,
            'media': null,
        }));

        // TODO: Reset validation

        // Close popup/pane
        this.setState({
            confirmModal: {
                isOpen: false
            },
            modal: { isOpen: false },
            formValidation: {
                post: {
                    error: false,
                    message: ''
                }
            }
        });
    }

    submit(e, element) {
        const { dispatch, inputFeedpost } = this.props;

        if (inputFeedpost.isPosting) {
            return false;
        }

        this.setState({
            formValidation: {
                ...this.state.formValidation,
                post: config.validateTextField(inputFeedpost.text, true)
            }
        })

        !config.validateTextField(inputFeedpost.text, true).error && dispatch(postFeedpost(inputFeedpost));

    }

    feedpostClose = () => {
        const { inputFeedpost } = this.props;

        if (this.state.modal.isOpen && (inputFeedpost.text || inputFeedpost.project || (inputFeedpost.media && inputFeedpost.media.length > 0))) {
            this.setState({
                confirmModal: {
                    isOpen: true
                },
            })
            return false;
        }
        else {
            this.setState({
                modal: { isOpen: false }
            })
            return true;
        }
    }

    handleModal = (isOpen, title, content, parent) => {
        this.setState({
            modal: {
                ...this.state.modal,
                isOpen,
                title,
                content,
                parent,
            }
        })
    }

    handleDeleteImage = (id) => {
        const { dispatch, inputFeedpost } = this.props;
        let updatedArray = [];

        inputFeedpost.media.filter(item => item.id !== id).map((item, i) => {
            updatedArray.push(item);
        })

        dispatch(newFeedpost({
            'media': updatedArray,
            'text': inputFeedpost.text,
            'project': inputFeedpost.project,
        }));

    }

    render() {
        const { account, inputFeedpost, projects, userProjectsIsFetching } = this.props;

        return (
            <div className="newsfeed-post">
                {this.confirmModal(this.discard)}

                <div className="add-post">
                    <div className="user-img">
                        <ImageWrapper avatar circular path={account.item.mediaavatarPath}>
                            <ProgressiveImager placeholder="avatar" src={config.getImageUrl('avatar', account.item.mediaavatarPath)} circular/>
                        </ImageWrapper>
                    </div>

                    <div className="post-input" onClick={() => this.handleModal(true, "Beitrag posten", "add-post")}>
                        <span className="post-input-text">Was machst Du gerade? Hast Du eine Frage?</span>
                        <span className="post-input-icon">
                            <Image src={cameraIcon} alt="Icon Kamera" />
                        </span>
                    </div>
                </div>

                <Responsive
                    as={CustomSlidingPane}
                    {...Responsive.onlyMobile}
                    isOpen={this.state.modal.isOpen}
                    hideHeader={true}
                    onRequestClose={this.feedpostClose}
                    className="feedpost-slidingpane"
                    overlayClassName="feedpost"
                    saveButtonCallback={this.submit}
                    saveButton={this.showButton()}
                    saveButtonLabel={this.state.modal.buttonLabel}
                    isLoading={inputFeedpost.isPosting}
                >
                    {this.addPost()}
                </Responsive>

                <Responsive
                    as={CustomPopup}
                    minWidth={Responsive.onlyTablet.minWidth}
                    popupOpen={this.state.modal.isOpen}
                    onClose={this.feedpostClose}
                    saveButton={this.showButton()}
                    showCloseIcon={false}
                    isLoading={inputFeedpost.isPosting}
                >
                    {this.addPost()}
                </Responsive>
            </div>
        )
    }
    addPost(){
        const { account, inputFeedpost, projects, userProjectsIsFetching } = this.props;
        const { formValidation } = this.state;

        return (
            <>
            <RemoveScrollBar/>
            <Container className="add-feedpost">
                <div className="add-feedpost-header">
                    <div className="user-image-circular">
                        <ImageWrapper avatar circular path={account.item.mediaavatarPath}>
                            <ProgressiveImager placeholder="avatar" src={config.getImageUrl('avatar', account.item.mediaavatarPath)} circular/>
                        </ImageWrapper>
                    </div>
                    <div className="feedpost-modal-title">Beitrag erstellen</div>
                    <div className="feedpost-modal-close" onClick={() => this.feedpostClose()} >
                        <img style={{width: "14px", height: "14px"}} src={process.env.PUBLIC_URL + '/images/close-cross.svg'} alt="Icon close"/>
                    </div>
                </div>
                <Divider hidden/>

                {inputFeedpost.postingMessage === 'FAILURE' &&
                    <Message negative>Beim Senden ist ein Fehler aufgetreten.</Message>
                }

                <>
                    <div style={{position: 'relative'}}>
                        <Form>

                        <Responsive maxWidth={Responsive.onlyTablet.maxWidth}>
                            <Form.Field error={formValidation.post.error}>
                                <label>Was machst Du? Wo bist Du? Hast Du etwas entdeckt oder eine Frage?</label>
                                <TextareaAutosize
                                    name={"inputFeedpost.text"}
                                    onChange={this.handleChangeInputAlt}
                                    value={inputFeedpost.text}
                                    minRows="5"
                                    maxRows="15"
                                    className="add-feedpost-textarea"
                                />
                            </Form.Field>
                        </Responsive>

                        <Responsive minWidth={Responsive.onlyComputer.minWidth}>
                            <Form.Field error={formValidation.post.error}>
                                <label>Was machst Du? Wo bist Du? Hast Du etwas entdeckt oder eine Frage?</label>
                                <TextareaAutosize
                                    name={"inputFeedpost.text"}
                                    onChange={this.handleChangeInputAlt}
                                    value={inputFeedpost.text}
                                    minRows="5"
                                    maxRows="15"
                                    className="add-feedpost-textarea"
                                    autoFocus 
                                />
                            </Form.Field>
                        </Responsive>

                            {formValidation.post.error &&
                                <Message color="red">{formValidation.post.message}</Message>
                            }

                            <Form.Field>
                                <label >Betrifft Projekt (optional) {userProjectsIsFetching && <Loader active inline size='tiny' style={{verticalAlign: 'text-bottom'}} />}</label>
                                <Select menuPlacement="auto"
                                    placeholder="Auswahl..."
                                    options={config.buildChoice(projects, 'title')}
                                    name="inputFeedpost.project"
                                    onChange={this.handleChangeSelect}
                                    value={config.getDefault(config.buildChoice(projects, 'title'), inputFeedpost.project)}
                                    style={{zIndex: '2'}}
                                    classNamePrefix="custom-select"
                                    className="custom-select-wrapper"
                                />
                            </Form.Field>
                        </Form>
                    </div>
                    <Divider hidden/>
                    {/* Images */}
                    <FileUpload
                      headers={{ 'Authorization': 'Bearer '+account.token }}
                      allowedFileTypes={['jpg', 'jpeg']}
                      dropzoneId={"inputFeedpost.media"}
                      multiple={true}
                      url={config.API_HOST + config.API_UPLOAD}
                    >
                        <div className="button-element feed-add-images">
                            <Button basic color="red" className="single-button">
                                <Image src={cameraIconRed} alt="Icon Kamera" className="button-icon"/>
                                <span>Fotos hinzufügen</span>
                            </Button>
                        </div>
                    </FileUpload>
                    <Divider hidden/>

                    {inputFeedpost.media &&
                        <PreviewGallery
                            imagesOnly={inputFeedpost.media}
                            deletable
                            imagesPerRow={3}
                            requestDelete={this.handleDeleteImage}
                        />
                    }


                </>
                <Divider hidden/>

                <Button 
                    fluid 
                    disabled={
                        !inputFeedpost.text ? true : false
                    } 
                    loading={inputFeedpost.isPosting}
                    onClick={this.submit} 
                    color={!inputFeedpost.text ? "grey" : "green"}
                    className="post-button"
                >
                    Veröffentlichen
                </Button>
            </Container>
            </>
        )
    }
}

class NewsfeedPost extends Component {

    state = {
        formValidation: {
            post: {
                error: false,
                message: ''
            }
        },
    }

    handleDeleteImage = (id) => {
        const { dispatch, inputFeedpost } = this.props;
        let updatedArray = [];

        inputFeedpost.media.filter(item => item.id !== id).map((item, i) => {
            updatedArray.push(item);
        })

        dispatch(newFeedpost({
            'media': updatedArray,
            'text': inputFeedpost.text,
            'project': inputFeedpost.project,
        }));

    }

    render(){
        const { account, inputFeedpost, projects, userProjectsIsFetching, feedpostClose, handleChangeInputAlt, submit } = this.props;
        const { formValidation } = this.state;
        
        return (
            <div className="add-feedpost-modal">
            <Container className="add-feedpost">
                <div className="add-feedpost-header">
                    <div className="user-image-circular">
                        <ImageWrapper avatar circular path={account.item.mediaavatarPath}>
                            <ProgressiveImager placeholder="avatar" src={config.getImageUrl('avatar', account.item.mediaavatarPath)} circular/>
                        </ImageWrapper>
                    </div>
                    <div className="feedpost-modal-title">Beitrag erstellen</div>
                    <div className="feedpost-modal-close" onClick={feedpostClose} >
                        <img style={{width: "14px", height: "14px"}} src={process.env.PUBLIC_URL + '/images/close-cross.svg'} alt="Icon close"/>
                    </div>
                </div>
                <Divider hidden/>

                {inputFeedpost.postingMessage === 'FAILURE' &&
                    <Message negative>Beim Senden ist ein Fehler aufgetreten.</Message>
                }

                <>
                    <div style={{position: 'relative'}}>
                        <Form>

                        <Responsive maxWidth={Responsive.onlyTablet.maxWidth}>
                            <Form.Field error={formValidation.post.error}>
                                <label>Was machst Du? Wo bist du? Hast du etwas entdeckt?</label>
                                <TextareaAutosize
                                    name={"inputFeedpost.text"}
                                    onChange={handleChangeInputAlt}
                                    value={inputFeedpost.text}
                                    minRows="5"
                                    maxRows="15"
                                    className="add-feedpost-textarea"
                                />
                            </Form.Field>
                        </Responsive>

                        <Responsive minWidth={Responsive.onlyComputer.minWidth}>
                            <Form.Field error={formValidation.post.error}>
                                <label>Was machst Du? Wo bist du? Hast du etwas entdeckt?</label>
                                <TextareaAutosize
                                    name={"inputFeedpost.text"}
                                    onChange={handleChangeInputAlt}
                                    value={inputFeedpost.text}
                                    minRows="5"
                                    maxRows="15"
                                    className="add-feedpost-textarea"
                                    autoFocus 
                                />
                            </Form.Field>
                        </Responsive>

                            {formValidation.post.error &&
                                <Message color="red">{formValidation.post.message}</Message>
                            }

                            <Form.Field>
                                <label >Betrifft Projekt (optional) {userProjectsIsFetching && <Loader active inline size='tiny' style={{verticalAlign: 'text-bottom'}} />}</label>
                                <Select menuPlacement="auto"
                                    placeholder="Auswahl..."
                                    options={config.buildChoice(projects, 'title')}
                                    name="inputFeedpost.project"
                                    onChange={this.handleChangeSelect}
                                    value={config.getDefault(config.buildChoice(projects, 'title'), inputFeedpost.project)}
                                    style={{zIndex: '2'}}
                                    classNamePrefix="custom-select"
                                    className="custom-select-wrapper"
                                />
                            </Form.Field>
                        </Form>
                    </div>
                    <Divider hidden/>
                    {/* Images */}
                    <FileUpload
                      headers={{ 'Authorization': 'Bearer '+account.token }}
                      allowedFileTypes={['jpg', 'jpeg']}
                      dropzoneId={"inputFeedpost.media"}
                      multiple={true}
                      url={config.API_HOST + config.API_UPLOAD}
                    >
                        <div className="button-element feed-add-images">
                            <Button basic color="red" className="single-button">
                                <Image src={cameraIconRed} alt="Icon Kamera" className="button-icon"/>
                                <span>Fotos hinzufügen</span>
                            </Button>
                        </div>
                    </FileUpload>
                    <Divider hidden/>

                   
                        {inputFeedpost.media &&
                            <PreviewGallery
                                imagesOnly={inputFeedpost.media}
                                deletable
                                requestDelete={this.handleDeleteImage}
                                imagesPerRow={3}
                            />
                        }
                        


                </>
                <Divider hidden/>

                <Button 
                    fluid 
                    disabled={
                        !inputFeedpost.text ? true : false
                    } 
                    loading={inputFeedpost.isPosting}
                    onClick={submit} 
                    color={!inputFeedpost.text ? "grey" : "green"}
                    className="post-button"
                >
                    Veröffentlichen
                </Button>
            </Container>
            </div>
        )
    }
}

function mapStateToProps(state, ownProps){
    const { account, listByEntity, projectById } = state

    const inputFeedpost = account['inputFeedpost'] || {}

    const { list, isFetching: userProjectsIsFetching } = listByEntity[account.item.id+ '::userProjects'] || {
        list: {},
        userProjectsIsFetching: true
    }

    let projects = [];
    if (list) {
        for (let value of Object.values(list)) {
            if (projectById[value]) {
                projects.push(projectById[value].item);
            }
        }
    }

    return {
        account,
        inputFeedpost,
        projects,
        userProjectsIsFetching,
    }
}
export default connect(mapStateToProps)(NewsfeedInput);
