import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { handleContainer } from '../actions/global';
import { resetMessages, putRecover } from '../actions/account';
import { Container, Grid, Form, Header, Input, Divider , Button, Message, Icon } from 'semantic-ui-react';
import './Pages.scss';

class Recovery extends Component {

    state = {
        recover: {
            plainPassword: '',
            plainPassword2: '',
        }
    }

    constructor(props) {
        super(props);
        // this.redirectTimeout = null;

        this.handleInputRec = this.handleInputRec.bind(this);
        this.handleSubmitRec = this.handleSubmitRec.bind(this);
    }

    componentDidMount = () => {
        const { dispatch, history } = this.props;

        dispatch(handleContainer("newsfeed", ""));
        dispatch(resetMessages());

        //Redirect after 5 sec
        // this.redirectTimeout = setTimeout(() => {
        //     history.push('/')
        // }, 5000);
    }

    componentWillUnmount = () => {
        // clearTimeout(this.redirectTimeout);
    }

    handleInputRec(e, element) {
        this.setState({
            recover: {
                ...this.state.recover,
                [element.name]: element.value,
            },
        });
    }

    handleSubmitRec = event => {
        event.preventDefault()
        const { dispatch, isRecovering, match } = this.props;
        const { recover } = this.state;

        if (!isRecovering && recover.plainPassword) {
            // TODO: Check client side
            dispatch(putRecover(recover, match.params.user, match.params.code));
        }
    }

    render() {
        const { isRecovering, recoveringMessage } = this.props;

        return (
            <Container className="password-recovery">
                {recoveringMessage !== 'SUCCESS' && recoveringMessage !== 'wrongcode' &&
                    <>
                        <Divider hidden/>
                        <Header as="h2" textAlign="center">
                            Neues Passwort vergeben
                        </Header>
                    </>
                }

                <Divider hidden/>

                <Container text>
                    <Form>

                        {recoveringMessage !== 'SUCCESS' && recoveringMessage !== 'wrongcode' &&
                            <>
                                <Form.Field>
                                    <label>Neues Passwort</label>
                                    <Input
                                        name="plainPassword"
                                        type="password"
                                        fluid
                                        className="pw-input"
                                        onChange={this.handleInputRec}
                                        autoComplete="new-password"
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <label>Wiederholen</label>
                                    <Input
                                        name="plainPassword2"
                                        type="password"
                                        fluid
                                        className="pw-input"
                                        onChange={this.handleInputRec}
                                        autoComplete="new-password"
                                    />
                                </Form.Field>

                                {recoveringMessage === 'FAILURE' &&
                                    <Message negative>Beim Senden ist ein Fehler aufgetreten.</Message>
                                }
                                {recoveringMessage === 'fieldsempty' &&
                                    <Message negative>Es wurden nicht alle Formularfelder ausgefüllt.</Message>
                                }
                                {recoveringMessage === 'pwmissmatch' &&
                                    <Message negative>Die Passwörter stimmen nicht überein.</Message>
                                }

                                <Button
                                    color="red"
                                    fluid
                                    loading={isRecovering}
                                    onClick={this.handleSubmitRec}
                                >
                                    Passwort zurücksetzen
                                </Button>
                            </>
                        }

                        {recoveringMessage === 'SUCCESS' &&
                            <Grid>
                                <Grid.Column width={16} textAlign="center">

                                    <Icon name="check circle" size="massive" color="green"/>
                                    <Header as="h2">Das Passwort wurde zurückgesetzt</Header>
                                    <p>Das neue Passwort ist ab sofort gültig.</p>
                                    <Divider hidden/>
                                    <Button as="a" href="/">Zur Startseite</Button>

                                </Grid.Column>
                            </Grid>
                        }

                        {recoveringMessage === 'wrongcode' &&
                            <Grid>
                                <Grid.Column width={16} textAlign="center">

                                    <Icon name="warning circle" size="massive" color="red"/>
                                    <Header as="h2">Der Wiederherstellungscode stimmt nicht</Header>
                                    <p>Bitte prüfe, ob der Link aus unserer E-Mail korrekt und vollständig aufgerufen wurde.</p>
                                    <Divider hidden/>
                                    <Button as="a" href="/">Zur Startseite</Button>

                                </Grid.Column>
                            </Grid>
                        }

                    </Form>
                </Container>

            </Container>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { account } = state;

    const { isRecovering, recoveringMessage } = account || {
        isRecovering: false,
        recoveringMessage: '',
    }

    return {
        isRecovering,
        recoveringMessage,
    }
}

export default connect(mapStateToProps)(withRouter(Recovery));
