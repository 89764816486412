import React, { Component } from 'react'
import { Container, Button, Message, Form, Image, Divider, Pagination, Header, Grid, Label, Loader, Segment } from 'semantic-ui-react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import tinymce from 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/emoticons/js/emojis';
import 'tinymce/plugins/paste';
import { Editor } from '@tinymce/tinymce-react';
import '../../tinymce/langs/de';
import MetaTag from 'react-meta-tags';
import { Link as ScrollLink, Element, scroller } from 'react-scroll'
import parse from 'html-react-parser';
import { FileUpload } from 'ix-redux-file-upload';
import BeParent from '../project/backend/be-parent';
import { StructuredDataForumDetail } from '../../components/StructuredData/StructuredData';
import {fetchThreadIfNeeded, fetchThread, changeThread, postMessage, fetchThreadNextMessage} from "../../actions/forumthread";
// import fetchProjectIfNeeded  from '../../actions/project';
import { handleContainer, handleHeaderItems, setRuntime } from '../../actions/global'
// import CustomSlidingPane from '../../components/CustomSlidingPane/CustomSlidingPane';
// import Helmet from 'react-helmet'
import ImageWrapper from '../../components/helpers/image-wrapper'
// import { Link as RouterLink } from "react-router-dom";
// import FeContentElement from  '../../components/project/Stories/fe-content-element';
import config from '../../config/config';
// import CommentsWrapper from '../../components/Comments/CommentsWrapper';
// import NumericLabel from 'react-pretty-numbers'
// import Account from '../../components/account/Account';
// /* ICONS */
// import heartIcon from '../../images/icons/frontend/icon-heart.svg';
// import commentIcon from '../../images/icons/frontend/icon-comment.svg';
// import eurIcon from '../../images/icons/frontend/icon-euro-circle.svg';
// import clockIcon from '../../images/icons/frontend/icon-clock-circle.svg';
// import heartIconDark from '../../images/icons/frontend/icon-heart-dark.svg';
// import heartIconActive from '../../images/icons/frontend/icon-active-heart.svg';
import ErrorHandler from '../../components/ErrorHandler/ErrorHandler';
import Post from '../../components/forum/post';
import PreviewGallery from '../../components/PreviewGallery/PreviewGallery';
import Moment from 'react-moment';
import "moment/locale/de";
import "moment-timezone";
import cameraIconRed from '../../images/icons/frontend/icon-camera-red.svg';
import Account from '../../components/account/Account';
import { Helmet } from 'react-helmet';

const calendarStrings = {
    lastDay : 'DD.MM.YYYY, LT',
    sameDay : 'DD.MM.YYYY, LT',
    sameElse : 'L, LT',
    nextWeek: 'DD.MM.YYYY, LT',
    lastDay: 'DD.MM.YYYY, LT',
    lastWeek: 'DD.MM.YYYY, LT',
    sameElse : 'DD.MM.YYYY, LT',
}

class ForumDetail extends BeParent {

    state = {
        galleryOpen: false,
        loginPopup: false,
        activePage: 0,
        loginPopupVisible: false,
        watcher: '',
        isSwitchingPage: false,
        newMessageIsFading: false,
    }

    constructor(props) {
        super(props);

        this.reloadThread = this.reloadThread.bind(this);
        this.handleEditorChange = this.handleEditorChange.bind(this);
        this.submit = this.submit.bind(this);
        this.handleDeleteImage = this.handleDeleteImage.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const { dispatch, match, item, sumPages } = this.props;
        const { activePage } = this.state;

        //Set Navigation Active item
        dispatch(handleHeaderItems("Forum"));

        // TODO: Maybe save lastUpdated per page and use this to determine if refetch is needed
        //Handle Thread Pages on mount
        if(match.params.page) {
            dispatch(fetchThread(match.params.id, {}, match.params.page));
            this.setState({ activePage: parseInt(match.params.page) })
        } else {
            dispatch(fetchThread(match.params.id));
            this.setState({ activePage: 1 })
        }

        // dispatch(fetchThreadIfNeeded(match.params.id));
        dispatch(setRuntime({
            page: 'fe-forumthread-id-'+match.params.id,
        }));

        if (item && item.id) {
            dispatch(handleContainer("secondary", 'SPAN#Frage', null, null, null, null, {'title': item.title, description:
            `Forum Beitrag: ${item.title}`}));
        }
        else {
            dispatch(handleContainer("secondary", 'SPAN#Frage'));
        }

    }

    componentDidUpdate(prevProps, prevState) {
        const { dispatch, item, isPosting, match, history, accountItem, sumPages, pages, currentPageMsgs, isFetching } = this.props;
        const { activePage } = this.state;

        if ((!prevProps.item || !prevProps.item.id) && item && item.id) {
            dispatch(handleContainer("secondary", 'SPAN#Frage', null, null, null, null, {'title': item.title, description:
            `Forum Beitrag: ${item.title}`}));
        }

        //Message post - switch to the next page if needed
        if((isPosting !== prevProps.isPosting) && activePage !== sumPages){
            //Fetch last page if not exist
            if(!pages[sumPages]){
                dispatch(fetchThread(match.params.id, {}, sumPages));
            }
            history.push(`/forum/${match.params.slug}/${match.params.id}/page/${sumPages}`)
            this.setState({ activePage: sumPages });
        }

        //Handle fade in animation on new message - only if last page is active
        if(prevState.activePage === prevProps.sumPages && prevProps.currentPageMsgs &&  currentPageMsgs && (prevProps.currentPageMsgs.length !== currentPageMsgs.length)) {
            this.setState({ newMessageIsFading: true });

            setTimeout(() => {
                this.setState({ newMessageIsFading: false });
            }, 300)

        }

        //Scroll back to editor if user logged in
        if((accountItem && accountItem.id) && prevProps.accountItem !== accountItem){
            scroller.scrollTo('tinymce-editor', {
                duration: 800,
                delay: 0,
                smooth: 'easeInOutQuart'
              })
        }
        //Init watcher if last page
        if(
            (prevState.activePage !== activePage || prevProps.sumPages !== sumPages) &&
            activePage === sumPages
        )
            {
                const watcher = setInterval(this.watchNewMessages, 15000);
                this.setState({'watcher': watcher});
            }
        //Disable watcher if not last
        else if(
            (prevState.activePage !== activePage || prevProps.sumPages !== sumPages) &&
            activePage !== sumPages && this.state.watcher
        ) {
            clearInterval(this.state.watcher);
        }

    }

    componentWillUnmount() {
        const { dispatch, match } = this.props;

        dispatch(setRuntime({
            backUser: {page: 'fe-forumthread-id-'+match.params.id, path: this.props.location},
        }));

        clearInterval(this.state.watcher);
    }

    handleEditorChange(content, editor) {
        const { dispatch, match } = this.props;

        dispatch(changeThread(match.params.id, {inputText: content}));
    }

    submit = (e, element) => {
        const { dispatch, inputText, inputMedia, item, isPosting } = this.props;

        if (!isPosting) {
            dispatch(postMessage(
                {text: inputText, media: inputMedia},
                {id: item.id},
            ))
        }
    }

    // handleFollowThread(id, value) {
    //   const { dispatch, isThreadFollowing } = this.props;
    //
    //   if (!isThreadFollowing) {
    //     dispatch(followThread(id, value));
    //   }
    // };

    handleGalleryToggle(slide) {
        this.setState({galleryOpen: !this.state.galleryOpen});
        if(slide) {
            this.setState({currentSlide: slide});
        }
    }

    reloadThread() {
        const { dispatch, match } = this.props;
        dispatch(fetchThread(match.params.id));
    }

    handleDeleteImage = (id) => {
        const { dispatch, inputMedia, match } = this.props;

        let updatedArray = inputMedia.filter(item => item.id !== id);

        dispatch(changeThread(match.params.id, {inputMedia: updatedArray}));
    }

    handleQuote = (user, content) => {
        const { inputText } = this.props;

        let quote = `
            <blockquote>
                <strong>Zitat ${user}:</strong>
                <span>${content}</span>
            </blockquote>
            <br/>
        `
        let updatedContent;

        if(inputText){
            updatedContent = inputText + quote;
        }
        else {
            updatedContent = quote
        }

        this.handleEditorChange(updatedContent);
    }

    handlePageChange = (e, {activePage}) => {
        const { dispatch, match, page, history, isFetching } = this.props;

        this.setState({ isSwitchingPage: true }) //Handle opacity fadein/fadeout

        //Delay changing page to get fadein/fadeout effect
        setTimeout(() => {
            !isFetching && this.setState({ isSwitchingPage: false });

            dispatch(fetchThread(match.params.id, {}, activePage));
            //Handle URL
            history.push(`/forum/${match.params.slug}/${match.params.id}/page/${activePage}`);
            window.scrollTo(0, 0); // Scroll to the top
        }, 300)

        this.setState({ activePage })

    }

    watchNewMessages = () => {
        const { dispatch, currentPageMsgs, isFetching, isFailed, match } = this.props;
        const { activePage } = this.state;

        if (!isFetching && !isFailed) {
            var watchthis = [];
            for (var watchkey = currentPageMsgs.length - 1; watchkey >= 0; --watchkey) {
                // Ignore just posted own messages
                // TODO: Optimize
                    watchthis.push(currentPageMsgs[watchkey].forummessage.id);
                    break;

            }

            if (!watchthis.length) {
                dispatch(fetchThread(match.params.id, {}, activePage));
            }
            else {
                dispatch(fetchThreadNextMessage(match.params.id, watchthis));
            }
        }
    }

    render() {
        const { item, isFetching, lastUpdated, currentPageMsgs } = this.props;

        if (isFetching && !lastUpdated && (!currentPageMsgs)) {
            return (
                <Container text className="forum-post-detail">
                    <Header as="h1"></Header>
                    <Post isFetching/>
                </Container>
            )
        }
        else if (item && item.id) {
            return this.renderThread();
        }
        else {
            return (
                <Container text>
                    <ErrorHandler callbackFunction={this.reloadThread} />
                </Container>
            );
        }
    }

    renderThread() {
        const { item, isFetching, isFailed, sumPages, accountItem, currentPageMsgs, match } = this.props;
        const { activePage, isSwitchingPage, newMessageIsFading } = this.state;

        return (
            <Container text className="forum-post-detail">

                {isFailed &&
                    <Message negative>Beim Aktualisieren der Daten ist ein Fehler aufgetreten.</Message>
                }

                <div className="forum-post-title">
                    <Header as="h1">
                        {item.title} {match.params.page && parseInt(match.params.page) > 1 && ' - Seite ' + match.params.page }
                    </Header>
                    {(item.brandData || item.vehicleData || item.forum || item.scope ) &&
                        <Label>
                            {
                                `
                                    ${!item.brandData && !item.vehicleData && !item.forum && item.scope ? config.getDefault(config.getScopes(), item.scope).label :''}
                                    ${item.brandData && item.brandData.name ? item.brandData.name : '' }
                                    ${item.vehicleData && item.vehicleData.name ? item.vehicleData.name  : ''}
                                    ${item.forum && item.forum ? config.getDefault(config.getForums(), item.forum).label :''}
                                `
                            }
                        </Label>
                    }
                </div>

                <article>
                    {/* Insert Schema.org markup */}
                    <MetaTag>
                        <script type="application/ld+json">
                            {StructuredDataForumDetail(item, currentPageMsgs)}
                        </script>
                    </MetaTag>
                        {currentPageMsgs && currentPageMsgs.map((msg, index) => (
                            <div
                                className="forum-post"
                                key={index}
                                style={{
                                    transition: index === currentPageMsgs.length-1 && newMessageIsFading ? "0ms" : "300ms",
                                    opacity: isSwitchingPage || index === currentPageMsgs.length-1 && newMessageIsFading ? 0 : 1
                                }}
                            >
                                <Post
                                    handleQuote={() => this.handleQuote(msg.forummessage.userData.name, msg.forummessage.text)}
                                    message={msg.forummessage}
                                    ownPost={accountItem && accountItem.id && msg.forummessage.user === accountItem.id ? true : false}
                                    projectId={item.project}
                                    projectData={item.projectData}
                                    projectPartData={item.projectPartData}
                                    projectPartId={item.projectPart}
                                    enableQuotes={(accountItem && accountItem.id) ? true : false}
                                    showBreadcrumb={index === 0 && activePage === 1 ? true : false}
                                    threadItem={item}

                                />
                            </div>
                        ))}
                </article>

                {sumPages && sumPages > 1 &&
                    <Grid>
                        <Grid.Column width={16} textAlign="center">
                            <Pagination
                                boundaryRange={1}
                                showEllipsis
                                firstItem={null}
                                lastItem={null}
                                siblingRange={1}
                                totalPages={sumPages}
                                activePage={activePage}
                                onPageChange={this.handlePageChange}
                                showPreviousAndNextNav
                            />
                        </Grid.Column>
                    </Grid>
                }

                {this.renderAnswerForm()}

            </Container>
        );
    }

    renderAnswerForm() {
        const { item, isPosting, postingMessage, inputText, inputMedia, accountItem, token } = this.props;
        const { loginPopupVisible } = this.state;

        if (accountItem && accountItem.id) {
            return (
                <div className="post-answer-section">
                    <h2>{`Antworte auf den Beitrag „${item.title}“`}</h2>
                    <Form.Field>
                        <Element name="tinymce-editor">
                            <Editor
                                value={inputText}
                                init={{...config.TINY_INIT}}
                                onEditorChange={this.handleEditorChange}
                                disabled={isPosting ? true : false}
                            />
                        </Element>
                    </Form.Field>

                    <Form.Field>
                        <FileUpload
                          headers={{ 'Authorization': 'Bearer '+token }}
                          allowedFileTypes={['jpg', 'jpeg', 'mp4']}
                          dropzoneId={"forumthread."+item.id+".inputMedia"}
                          multiple={true}
                          url={config.API_HOST + config.API_UPLOAD}
                        >
                            <div className="button-element">
                                <Button basic color="red" className="single-button">
                                    <Image src={cameraIconRed} alt="Icon Kamera" className="button-icon"/>
                                    <span>Bilder/Videos hinzufügen</span>
                                </Button>
                            </div>
                        </FileUpload>

                        {inputMedia &&
                        <>
                            <PreviewGallery
                                images={inputMedia}
                                deletable
                                requestDelete={this.handleDeleteImage}
                            />
                            <Divider hidden/>
                         </>
                        }
                    </Form.Field>

                    {postingMessage === 'FAILURE' &&
                        <Message negative>Beim Senden ist ein Fehler aufgetreten.</Message>
                    }

                    <Button
                        color="red"
                        loading={isPosting}
                        disabled={!inputText ? true : false}
                        onClick={this.submit}
                    >
                        Antwort veröffentlichen
                    </Button>
                    <Divider hidden/>
                </div>
            );
        }

        return (
            <>
            <Message style={{cursor: "pointer"}} onClick={() => this.setState({ loginPopupVisible: true })} color="teal">Melde dich an um zu antworten.</Message>
            <Account
                loginPopupVisible={loginPopupVisible}
                loginPopupClose={() => this.setState({ loginPopupVisible: false })}
                loginDashboard={false}
            />
            </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    const { globalByComponent, forumthreadById, account } = state;

    const { item: accountItem, token } = account || {
        item: {},
    }

    const { item, messages, isFetching, hasMore, lastUpdated, pages, sumPages, isFollowing, isFailed, isPosting, postingMessage, inputText, inputMedia } = forumthreadById[
        ownProps.match.params.id
    ] || {
        item: {},
        messages: {},
        pages: {},
        isFetching: true,
        isFailed: false,
        hasMore: false,
        sumPages: 1,
        lastUpdated: 0
    }

    const { galleryOpen } = globalByComponent[
        'Gallery'
    ] || {
        galleryOpen: false,
    }

    var msgList = [];
    if (messages && Object.keys(messages).length) {
        msgList = Object.values(messages);
    }
    msgList.sort((a, b) => a.forummessage.id > b.forummessage.id ? 1 : -1);

    //Set messages to current page
    let currentPageMsgs;
    if(pages && Object.keys(pages).length > 0){
        if(ownProps.match.params.page){
            currentPageMsgs = pages[ownProps.match.params.page] && Object.values(pages[ownProps.match.params.page]);
        }
        else {
            currentPageMsgs = Object.values(pages[1]); //Default - first page
        }
    }

    return {
        accountItem,
        token,
        galleryOpen,
        item,
        msgList,
        isFetching,
        isFailed,
        isFollowing,
        isPosting,
        postingMessage,
        inputText,
        inputMedia,
        hasMore,
        sumPages,
        pages,
        currentPageMsgs
    };
}

export default withRouter(connect(mapStateToProps)(ForumDetail));
