import React from 'react'
import { Container, Form, Divider, Grid, Button, Header, Icon, Message, TextArea } from 'semantic-ui-react';
import Select from 'react-select';
import { selectError } from '../../../../components/ReactSelect/customStyles';
import { withRouter } from 'react-router-dom'
import { FileUpload } from 'ix-redux-file-upload';
import { connect } from 'react-redux';
import TextareaAutosize from 'react-textarea-autosize';
import BeParent from '../be-parent';
import config from '../../../../config/config'
import ProgressiveImager from '../../../../components/helpers/progressive-imager';
import ImageWrapper from '../../../../components/helpers/image-wrapper';
import SingleMedia from '../../../../components/PreviewGallery/SingleMedia'
import SortableGallery from '../../../../components/PreviewGallery/SortableGallery';
import './Stories.scss'
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';

class StoryElements extends BeParent {

    state = {
        newOrderList: [],
        countLoading: 0,
        reloadSortable: false,
    }

    componentDidUpdate(prevProps) {
        const {element, content} = this.props;
        const {countLoading} = this.state;

        // Check if loading finished
        // Workaround to make newly uploaded items sortable
        if (element === "contentgallery") {
            var count = 0;
            content.input.media.map((media) => {
                if (media.path === 'LOADING') {
                    ++count;
                }
            });
            if (countLoading && !count) {
                this.setState({
                    reloadSortable: true,
                });
            }
            else if (count && count !== countLoading) {
                this.setState({
                    countLoading: count,
                });
            }
        }
    }

    onSortEnd = ({oldIndex, newIndex}) => {
        const { storyId, sortedMedia, content } = this.props;

        this.setState({
            newOrderList: arrayMove(sortedMedia, oldIndex, newIndex)
        }, this.onSortEnd2)
    };

    onSortEnd2 = () => {
        const { storyId, content } = this.props;

        let updatedList = [];

        this.state.newOrderList.map((item, index) => {
            item.position = index
            updatedList.push({...item})
        })

        this.handleChange("story."+storyId+".content."+content.id+".media", updatedList);
    };

    handleDeleteItem = (index) => {
        const { sortedMedia, storyId, content } = this.props;
        let updatedList = [];

        sortedMedia.filter((item) => {
            if(item.position === index){
                return false;
            }
            return true;
        }).map((item, i) => {
            item.position = i
            updatedList.push(item)
        });

        this.handleChange("story."+storyId+".content."+content.id+".media", updatedList);
    }

    render() {
        switch(this.props.element){
            case "contentbeforeafter":
                return this.editBeforeAfterElement()
            case "contentgallery":
                return this.editGalleryElement()
            case "contentimagetext":
                return this.editTextImage()
            case "contentvideo":
                return this.editVideo()
            case "contentimage":
                return this.editImage()
            case "contentheadline":
            case "contenttext":
                return this.editText()
            default:
                return null
        }
    }
    //Edit elements
    editBeforeAfterElement(){
        const {edit, account, content, isFetching, modalFetching, item, storyId, validation} = this.props;


        return (
            <>
                <Container className="edit-before-after-element">
                    <Form>
                        <Form.Input
                            label="Überschrift (optional)"
                            name={"story."+storyId+".content."+content.id+".string"}
                            onChange={this.handleChangeInput}
                            value={content.input.string}
                        />
                        <Form.Field>
                            <label>Text (optional)</label>
                            <TextareaAutosize
                                minRows="5"
                                maxRows="15"
                                name={"story."+storyId+".content."+content.id+".text"}
                                onChange={this.handleChangeInputAlt}
                                value={content.input.text}
                            />
                        </Form.Field>
                    </Form>
                    <Divider hidden/>
                    <Grid columns="equal" className="edit-before-after-element-content">
                        <Grid.Column>
                            <Header textAlign="center" as="h3">Vorher</Header>
                            <FileUpload
                              headers={{ 'Authorization': 'Bearer '+account.token }}
                              allowedFileTypes={['jpg', 'jpeg', 'png']}
                              dropzoneId={"project."+item.id+".story."+storyId+".content."+content.id+".mediaBefore"}
                              url={config.API_HOST + config.API_UPLOAD}
                            >
                                {/* <div className="image_container">
                                    <ImageWrapper path={content.input.mediaBeforePath} className="before-after-img">
                                        <ProgressiveImager src={config.getImageUrl('main', content.input.mediaBeforePath)}/>
                                        <Icon name="pencil" circular inverted color="grey"/>
                                    </ImageWrapper>
                                </div> */}
                                <SingleMedia
                                    editable
                                    image={{
                                        path: content.input.mediaBeforePath,
                                        progress: content.input.mediaBeforeProgress,
                                        id: content.input.mediaBefore
                                    }}
                                />
                            </FileUpload>
                        </Grid.Column>
                        <Grid.Column>
                            <Header textAlign="center" as="h3">Nachher</Header>
                            <FileUpload
                              headers={{ 'Authorization': 'Bearer '+account.token }}
                              allowedFileTypes={['jpg', 'jpeg', 'png']}
                              dropzoneId={"project."+item.id+".story."+storyId+".content."+content.id+".mediaAfter"}
                              url={config.API_HOST + config.API_UPLOAD}
                            >
                                {/* <div className="image_container">
                                    <ImageWrapper path={content.input.mediaAfterPath} className="before-after-img">
                                        <ProgressiveImager src={config.getImageUrl('main', content.input.mediaAfterPath)}/>
                                        <Icon name="pencil" circular inverted color="grey"/>
                                    </ImageWrapper>
                                </div> */}
                                <SingleMedia
                                    editable
                                    image={{
                                        path: content.input.mediaAfterPath,
                                        progress: content.input.mediaAfterProgress,
                                        id: content.input.mediaAfter
                                    }}
                                />
                            </FileUpload>
                        </Grid.Column>
                    </Grid>
                    <Divider hidden/>

                    {validation.contentMedia.error &&

                        <Message color="red">
                            {validation.contentMedia.message}
                        </Message>

                    }

                </Container>
            </>
        )
    }

    editGalleryElement(){
        const {content, account, sortedMedia, isFetching, modalFetching, item, storyId, validation} = this.props;
        return (
            <>
                <Container className="edit-gallery-element">
                    <Form>
                        <Form.Input
                            label="Überschrift (optional)"
                            name={"story."+storyId+".content."+content.id+".string"}
                            onChange={this.handleChangeInput}
                            value={content.input.string}
                        />
                        <Form.Field>
                            <label>Text (optional)</label>
                            <TextareaAutosize
                                minRows="5"
                                maxRows="15"
                                name={"story."+storyId+".content."+content.id+".text"}
                                onChange={this.handleChangeInputAlt}
                                value={content.input.text}
                            />
                        </Form.Field>
                    </Form>
                    <Divider hidden/>
                    <FileUpload
                      headers={{ 'Authorization': 'Bearer '+account.token }}
                      allowedFileTypes={['jpg', 'jpeg', 'png']}
                      dropzoneId={"project."+item.id+".story."+storyId+".content."+content.id+".media"}
                      multiple={true}
                      url={config.API_HOST + config.API_UPLOAD}
                    >
                        <div style={{marginTop: "2rem", textAlign: "center"}}>
                            <Button color="red">Bilder hinzufügen</Button>
                        </div>
                    </FileUpload>
                    <Divider hidden/>

                    {validation.contentMedia.error &&
                        <Message color="red">
                            {validation.contentMedia.message}
                        </Message>
                    }

                    <div className="storys-content edit-content">
                        <div className="content-gallery-imgs">
                            {this.state.reloadSortable &&
                                <>
                                    {this.setState({
                                        countLoading: 0,
                                        reloadSortable: false,
                                    })}
                                </>
                            }
                            {!this.state.reloadSortable &&
                                <SortableGallery
                                    handleDeleteItem={this.handleDeleteItem}
                                    images={content.input.media}
                                    onSortEnd={this.onSortEnd}
                                    storyId={storyId}
                                    contentId={content.id}
                                    showDescription
                                    handleChangeImageDescription={this.handleChangeInputAlt}
                                />
                                // <SortableList helperClass='sortableHelper' distance={1}  handleClick={this.handleDeleteItem} items={content.input.media} onSortEnd={this.onSortEnd} storyId={storyId} contentId={content.id}  />
                            }
                        </div>
                    </div>
                    <Divider hidden/>
                </Container>
            </>
        )
    }

    editTextImage() {
        const {edit, account, content, isFetching, modalFetching, item, storyId, validation} = this.props;
        return(
            <>
                <Container className="edit-text-image-element">
                    <Form>
                        <Form.Input
                            label="Überschrift (optional)"
                            name={"story."+storyId+".content."+content.id+".string"}
                            onChange={this.handleChangeInput}
                            value={content.input.string}
                        />
                        <Form.Field>
                            <label>Text</label>
                            <TextareaAutosize
                                minRows="5"
                                maxRows="15"
                                name={"story."+storyId+".content."+content.id+".text"}
                                onChange={this.handleChangeInputAlt}
                                value={content.input.text}
                                error={validation.contentText.error}
                            />
                        </Form.Field>

                        {validation.contentText.error &&
                            <Message color="red">
                                {validation.contentText.message}
                            </Message>
                        }

                        <Form.Field error={validation.contentMediaOrientation.error}>
                            <label>Bildausrichtung</label>
                            <Select menuPlacement="auto"
                                options={config.getOrientations()}
                                name={"story."+storyId+".content."+content.id+".mediaorientation"}
                                onChange={this.handleChangeSelect}
                                value={config.getDefault(config.getOrientations(), content.input.mediaorientation)}
                                defaultValue={config.getDefault(config.getOrientations(), 1)}
                                classNamePrefix="custom-select"
                                className="custom-select-wrapper"
                                styles={validation.contentMediaOrientation.error && selectError}
                            />
                        </Form.Field>

                        {validation.contentMediaOrientation.error &&
                            <Message color="red">
                                {validation.contentMediaOrientation.message}
                            </Message>
                        }

                    </Form>
                    <Header as="h3">Bild</Header>
                    <Grid textAlign="center">
                        <Grid.Column width={16} textAlign="center">
                            <FileUpload
                                headers={{ 'Authorization': 'Bearer '+account.token }}
                                allowedFileTypes={['jpg', 'jpeg', 'png']}
                                dropzoneId={"project."+item.id+".story."+storyId+".content."+content.id+".media"}
                                url={config.API_HOST + config.API_UPLOAD}
                                >
                                    <SingleMedia
                                        editable
                                        image={{
                                            path: content.input.mediaPath,
                                            progress: content.input.mediaProgress,
                                            id: content.input.mediaPath
                                        }}
                                    />
                            </FileUpload>
                        </Grid.Column>
                    </Grid>
                    <Divider hidden/>

                    {validation.contentMedia.error &&
                    <>
                        <Message color="red">
                            {validation.contentMedia.message}
                        </Message>
                        <Divider hidden/>
                    </>
                    }

                </Container>
            </>
        )
    }

    editVideo(){
        const {edit, content, account, isFetching, modalFetching, item, storyId, validation} = this.props;
        return (
            <>
                <Container className="edit-video">
                    <Form>
                        <Form.Input
                            label="Überschrift (optional)"
                            name={"story."+storyId+".content."+content.id+".string"}
                            onChange={this.handleChangeInput}
                            value={content.input.string}
                        />
                        <Form.Field>
                            <label>Text (optional)</label>
                            <TextareaAutosize
                                minRows="5"
                                maxRows="15"
                                name={"story."+storyId+".content."+content.id+".text"}
                                onChange={this.handleChangeInputAlt}
                                value={content.input.text}
                            />
                        </Form.Field>
                    </Form>
                    <Header as="h3">Video</Header>
                    <FileUpload
                      headers={{ 'Authorization': 'Bearer '+account.token }}
                      allowedFileTypes={['mp4', 'video/quicktime']}
                      dropzoneId={"project."+item.id+".story."+storyId+".content."+content.id+".media"}
                      url={config.API_HOST + config.API_UPLOAD}
                    >
                        <div class="w-50 mx-auto">
                            <SingleMedia
                                editable
                                video={{
                                    previewPath: content.input.mediaPreviewPath,
                                    progress: content.input.mediaProgress,
                                    id: content.input.mediaPath
                                }}
                            />
                        </div>
                    </FileUpload>
                    <Divider hidden/>

                    {validation.contentMedia.error &&
                        <Message color="red">
                            {validation.contentMedia.message}
                        </Message>
                    }

                </Container>
            </>
        )
    }

    editImage(){
        const {edit, account, content, isFetching, modalFetching, item, storyId, validation} = this.props;
        return (
            <>
                <Container className="edit-image-element">
                    <Form>
                        <Form.Input
                            label="Überschrift (optional)"
                            name={"story."+storyId+".content."+content.id+".string"}
                            onChange={this.handleChangeInput}
                            value={content.input.string}
                        />
                        <Form.Field>
                            <label>Text (optional)</label>
                            <TextareaAutosize
                                minRows="5"
                                maxRows="15"
                                name={"story."+storyId+".content."+content.id+".text"}
                                onChange={this.handleChangeInputAlt}
                                value={content.input.text}
                            />
                        </Form.Field>
                    </Form>
                    <Header as="h3">Bild</Header>
                    <FileUpload
                      headers={{ 'Authorization': 'Bearer '+account.token }}
                      allowedFileTypes={['jpg', 'jpeg', 'png']}
                      dropzoneId={"project."+item.id+".story."+storyId+".content."+content.id+".media"}
                      url={config.API_HOST + config.API_UPLOAD}
                    > 
                        <SingleMedia
                            editable
                            image={{
                                path: content.input.mediaPath,
                                progress: content.input.mediaProgress,
                                id: content.input.mediaPath
                            }}
                        />
                    </FileUpload>
                    <Divider hidden/>

                    {validation.contentMedia.error &&
                        <Message color="red">
                            {validation.contentMedia.message}
                        </Message>
                    }

                </Container>
            </>
        )
    }

    editText(){
        const {edit, content, isFetching, modalFetching, storyId, validation} = this.props;

        return (
            <>
                <Container className="edit-text-element">
                    <Form>
                        <Form.Input
                            label="Überschrift"
                            name={"story."+storyId+".content."+content.id+".string"}
                            onChange={this.handleChangeInput}
                            value={content.input.string}
                            error={validation.contentText.error}
                        />

                        <Form.Field>
                            <label>Text</label>
                            <TextareaAutosize
                                minRows="5"
                                maxRows="15"
                                name={"story."+storyId+".content."+content.id+".text"}
                                onChange={this.handleChangeInputAlt}
                                value={content.input.text}
                                error={validation.contentText.error}
                            />
                        </Form.Field>

                        {validation.contentText.error &&
                            <Message color="red">
                                {validation.contentText.message}
                            </Message>
                        }

                    </Form>
                    <Divider hidden/>
                </Container>
            </>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { globalByComponent, listByEntity, storyById, account, projectById } = state;
    const { openSecondary } = globalByComponent[
        'SlidingPane'
    ] || {
        openSecondary: false,
    }

    const { item: storyItem, contents, isFetching } = storyById[
        ownProps.storyId
    ] || {
        storyItem: {},
        contents: {},
        isFetching: false
    }

    const { item, isFetching: modalFetching } = projectById[
        ownProps.projId
    ] || {
        item: {},
        modalFetching: false,
    }

    var edit = {};

    var contentList = [];

    if (contents) {
        for (let [key, value] of Object.entries(contents)) {
            contentList.push(value);
        }
    }

    let sortedMedia = [];
    //Check if multiple items...
    if (ownProps.content && Array.isArray(ownProps.content.input.media)) {
        ownProps.content.input.media.map((item, index) => {
            if(item.position === null){
                item.position = index;
            }
            sortedMedia.push(item);
        })
        sortedMedia.sort((a, b) => a.position > b.position ? 1 : -1);
    }


    return {
        account,
        openSecondary,
        edit,
        contentList,
        sortedMedia,
        listitems: [{
            storyItem,
            contents,
        }],
        isFetching,
        item,
        modalFetching
    }
}


export default connect(mapStateToProps)(withRouter(StoryElements));
