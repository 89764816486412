import React, { Component, createRef } from 'react';
import { Responsive, Sidebar, Menu, Icon, Image, Container, Grid, Header, Dimmer, Popup, Dropdown, Ref, Sticky, Rail } from 'semantic-ui-react';
import SidebarMenu from '../../SidebarMenu/SidebarMenu';
import Account from '../../account/Account';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Helmet from 'react-helmet'
import LogoVeteroWhite from '../../../images/Logo-Vetero-komplett-weiss-quer.svg';
import loginIcon from '../../../images/icons/icon-login-white.svg';
import {zeroRightClassName,fullWidthClassName, noScrollbarsClassName} from 'react-remove-scroll-bar';
import { handleHeaderItems, loginPopup, setCanonicalTag, setRuntime } from '../../../actions/global';
import { fetchUserNotificationsIfNeeded } from '../../../actions/account'
import FooterMenu from '../Footer/footerMenu';
import config from '../../../config/config';
import ProgressiveImager from '../../../components/helpers/progressive-imager';
import userAvatar from '../../../images/icon-avatar.svg';
import './Header.scss';
import { THUMBNAIL_HEIGHT } from 'ix-redux-file-upload/lib/actions';
import menuDotsIcon_2 from '../../../images/icons/icon-menu-dots-white.svg'
import PreviewGallery from '../../PreviewGallery/PreviewGallery';
import EditHeader from './EditHeader';

//Type of headers: dashboard and secondary
//Use path props to set arrow button direction path

class DashboardHeader extends Component {

    constructor(props){
        super(props);

        this.state = {
            sidebarIsOpen: false,
            loginPopupVisible: false,
            menuHeight: null,
            dropdownMenuOpen: false,
            menuHeight: 0,
            windowWidth: 0,
        }

        this.setDimensions = this.setDimensions.bind(this);
    }

    contextRef = createRef()

    componentDidMount = () => {
        const { dispatch, headerTitle, location, ConsentBanner } = this.props;
        dispatch(fetchUserNotificationsIfNeeded());
        this.setDimensions();

        window.addEventListener('resize', this.setDimensions);
       // document.getElementById('root').addEventListener('click', this.handleClickDropdown, false);
        document.querySelector('body').classList.add('dashboard');

        dispatch(setRuntime({currentLocation: window.location.pathname}));
        //Set datalayer on url change
        if(window.dataLayer && location && headerTitle) {
            window.dataLayer.push({
                'PageTitle': headerTitle + " – Vetero Oldtimer Portal",
                'PageURL': window.location.href,
                'event': 'Pageview'
            });

            //Set GTM Event if cookies accepted
            if (config.GTM_ENABLED && ConsentBanner && ConsentBanner.consent) {
                window.dataLayer.push({'event': 'acceptAll'});
            }

        }

        //Set canonical tag on mount
        dispatch(setCanonicalTag(window.location.href))

    }

    componentDidUpdate = (prevProps) => {
        const { dispatch, currentLocation, location, headerTitle } = this.props;

        if (currentLocation !== window.location.pathname) {
            dispatch(setRuntime({currentLocation: window.location.pathname}));
        }

        //Update canonical tag on page change
        if(location.pathname !== prevProps.location.pathname){
            dispatch(setCanonicalTag(window.location.href))
        }

        //Set datalayer on url change
        if(config.GTM_ENABLED && window.dataLayer && location && (location.pathname !== prevProps.location.pathname)) {
            window.dataLayer.push({
                'virtualPageTitle': headerTitle + " – Vetero Oldtimer Portal",
                'virtualPageURL': window.location.href,
                'event': 'Pageview'
            });
        }

    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setDimensions);
        //document.getElementById('root').removeEventListener('click', this.handleClickDropdown, false);
        document.querySelector('body').classList.remove('dashboard');
    }

    setDimensions = () => {
        //Workaround - doesn't work without setTimeout
        // Set height of header
        let bodyHeight;
        let scope = this;
        setTimeout(function(){
            let menuHeight;
            if (document.querySelector('.header-menu.dashboard-header')) {
                menuHeight = document.querySelector('.header-menu.dashboard-header').offsetHeight;
                scope.setState({menuHeight: menuHeight});
                bodyHeight = 'calc(100%' + " - " + menuHeight + "px)";
                // bodyHeight = document.body.offsetHeight + menuHeight;
                // document.body.style.height = bodyHeight;
            }

            // Set width of window
            scope.setState({windowWidth: window.innerWidth});
        }, 0)
    }

    handleSidebar = (isOpen) => {
        this.setState({sidebarIsOpen: isOpen})
    }

    handleMenuItem = () => {
        this.handleSidebar(false)
    }

    checkResponsive(resp) {
        if (this.state.windowWidth >= resp.minWidth && (!resp.maxWidth || this.state.windowWidth <= resp.maxWidth)) {
            return true;
        }
        return false;
    }

    render() {
        const { children, item, type, showSidebar, notificationsSum, path, location, canonicalTagUrl } = this.props;
        let sidebarWidth = "300px";

        return (
            <>
                {this.props.headerTitle &&
                    <Helmet htmlAttributes={{"lang": "de"}}>
                        <title>{this.props.headerTitle}{config.TITLE_SUFFIX}</title>
                        <link rel="canonical" href={canonicalTagUrl} />
                    </Helmet>
                }

                {type === "edit" ? 
                    <>
                        {children}
                    </>

                    :
                

                <Sidebar.Pushable style={{transform: "none"}}>

                    <Responsive className="sidebar" maxWidth={Responsive.onlyTablet.maxWidth}>
                        <Sidebar
                             as={Menu}
                             animation='overlay'
                             className={"custom-sidebar" + (this.checkResponsive(Responsive.onlyTablet) ? ' hideable' : '')}
                             onHide={()=>this.handleSidebar(false)}
                             vertical
                             visible={this.state.sidebarIsOpen}
                             secondary
                             fixed="left"
                             style={this.checkResponsive(Responsive.onlyMobile) ? {width: sidebarWidth} : {top: this.state.menuHeight}}
                        >
                            <SidebarMenu onClose={this.handleSidebar}/>
                        </Sidebar>
                    </Responsive>

                    {/* Tablet/Desktop header */}

                    <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                        <header>
                            <Menu
                                as="nav"
                                secondary
                                className={(this.checkResponsive(Responsive.onlyComputer) ? zeroRightClassName : '') + " header-menu dashboard-header"}
                            >
                                <Container fluid={this.checkResponsive(Responsive.onlyComputer) ? null : true}>
                                    <Menu.Item position="left" as={Link} to="/" className="logo">
                                        <Image width="132px" height="30px" alt="Vetero Logo" src={LogoVeteroWhite}/>
                                    </Menu.Item>
                                    <Menu.Item className="menu-item" as={Link} to="/newsfeed" onClick={()=>this.props.dispatch(handleHeaderItems("newsfeed"))} active={this.props.headerActiveItem === "newsfeed"}>
                                        <span className="text-uppercase">Newsfeed</span>
                                    </Menu.Item>
                                    <Menu.Item className="menu-item" as={Link} to="/oldtimer" onClick={()=>this.props.dispatch(handleHeaderItems("projects"))} active={this.props.headerActiveItem === "projects"}>
                                        <span className="text-uppercase">Oldtimer / Projekte</span>
                                    </Menu.Item>
                                    <Menu.Item className="menu-item" as={Link} to="/about" onClick={()=>this.props.dispatch(handleHeaderItems("about"))} active={this.props.headerActiveItem === "about"}><span className="text-uppercase">Über Vetero</span></Menu.Item>
                                    <Responsive style={{margin: 0, padding: 0}} className="item menu-item" minWidth={Responsive.onlyComputer.minWidth}><Menu.Item className="menu-item" as={Link} to="/functions" onClick={() => this.props.dispatch(handleHeaderItems("functions"))} active={this.props.headerActiveItem === "functions"}><span className="text-uppercase">Funktionen</span></Menu.Item></Responsive>
                                    {/* <Responsive style={{margin: 0, padding: 0}} className="item menu-item" minWidth={Responsive.onlyComputer.minWidth}><Menu.Item className="menu-item" as={Link} to="/professionals" onClick={() => this.props.dispatch(handleHeaderItems("professional"))}><span className="text-uppercase">Spezialisten</span></Menu.Item></Responsive>  */}
                                    <Responsive style={{margin: 0, padding: 0}} className="item menu-item" minWidth={Responsive.onlyComputer.minWidth}><Menu.Item className="menu-item" as={Link} to="/forum" onClick={() => this.props.dispatch(handleHeaderItems("forum"))}><span className="text-uppercase">Forum</span></Menu.Item></Responsive>
                                    

                                    <Menu.Item className="header-dropdown">
                                        <Dropdown onClick={(e) => e.preventDefault()} icon={<Image src={menuDotsIcon_2}/>}>
                                            <Dropdown.Menu>
                                                <Responsive {...Responsive.onlyTablet} as={Dropdown.Item}>
                                                    <Responsive {...Responsive.onlyTablet} as={Link} to={"/functions"}>Funktionen</Responsive>
                                                </Responsive>
                                                <Responsive {...Responsive.onlyTablet} as={Dropdown.Item}>
                                                    <Responsive {...Responsive.onlyTablet} as={Link} to={"/forum"}>Forum</Responsive>
                                                </Responsive>
                                                <Dropdown.Item>
                                                    <Link to={`/oldtimer-blog`}>Oldtimer-Blog</Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                    <Link to={`/partner`}>Partner</Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                    <Link to={`/updates`}>Updates</Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                    <Link to={`/impressum`}>Impressum</Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                    <Link to={`/privacy`}>Datenschutz</Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                    <Link to={`/conditions`}>AGB</Link>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Menu.Item>

                                    {!(item && item.id) ?
                                    /* User not logged in */
                                        <Menu.Item position="right" className="top-header-user" onClick={() => this.setState({loginPopupVisible: true})}>
                                            <div className="login-item">
                                                <Image src={loginIcon} className="top-header-icon"/>
                                            </div>
                                        </Menu.Item>
                                        : //Or
                                        /* User logged in */
                                        <Menu.Item position="right" className="top-header-user" as={Link} to="/backend" onClick={()=> this.props.dispatch(handleHeaderItems("dashboard"))} active={this.props.headerActiveItem === "dashboard"}>
                                            { notificationsSum > 0 ?
                                                <div className="notifications">
                                                    <div className="notifications-amount">{notificationsSum}</div>
                                                </div>
                                                :
                                                ''
                                            }
                                            <div className="user-image"
                                                style={notificationsSum > 0 ? {marginLeft: 0} : null}
                                            >
                                                <ProgressiveImager
                                                    placeholder="avatar"
                                                    src={item.mediaavatarPath ? config.getImageUrl('avatar', item.mediaavatarPath) : userAvatar}
                                                    circular
                                                    bordered
                                                    className="user-img"
                                                />
                                            </div>
                                        </Menu.Item>
                                    }
                                    <Account
                                        loginPopupVisible={this.state.loginPopupVisible}
                                        loginPopupClose={()=> this.setState({loginPopupVisible: false})}
                                    />
                                </Container>
                            </Menu>
                        </header>
                    </Responsive>

                    <Sidebar.Pusher
                        dimmed={this.checkResponsive(Responsive.onlyMobile) ? this.state.sidebarIsOpen : null}
                        style={this.checkResponsive(Responsive.onlyMobile) ? {minHeight: this.state.sidebarIsOpen && (document.body.clientHeight)+'px'} : {marginTop: this.state.menuHeight === 0 ? 64 : this.state.menuHeight}}
                        className={this.checkResponsive(Responsive.onlyMobile) && this.state.sidebarIsOpen ? "sidebar-open" : null}
                    >
                        {/*Dashboard overview menu*/}

                        {type === "backend" && !this.checkResponsive(Responsive.onlyMobile) &&
                            <Container fluid={this.checkResponsive(Responsive.onlyComputer) ? false : true}>
                                <Menu
                                    secondary
                                    className="sub-menu"
                                    widths={3}
                                >
                                    <Menu.Item position="left">
                                        <Responsive as="div" {...Responsive.onlyTablet} className="sub-menu-buttons">
                                            <Icon name="bars" onClick={()=>this.handleSidebar(true)} size="large" />
                                        </Responsive>
                                    </Menu.Item>
                                    <Menu.Item className="top-header-title">
                                        <Header as="h1">{this.props.headerTitle}</Header>
                                    </Menu.Item>
                                    <Menu.Item position="right"></Menu.Item>
                                </Menu>
                            </Container>
                        }

                        {/*Secondary menu*/}

                        {type === "secondary" && !this.checkResponsive(Responsive.onlyMobile) &&

                            <Container fluid={this.checkResponsive(Responsive.onlyComputer) ? false : true}>
                                <Menu
                                    secondary
                                    className="sub-menu"
                                    widths={3}
                                >
                                    <Menu.Item position="left">
                                        <div className="sub-menu-buttons">
                                            {this.checkResponsive(Responsive.onlyTablet) &&
                                            <>
                                                <Icon name="bars" onClick={()=>this.handleSidebar(true)} size="large" />
                                                {notificationsSum > 0 ?
                                                    <div className="notifications">
                                                        <div className="notifications-amount">{notificationsSum}</div>
                                                    </div>
                                                    :
                                                    ''
                                                }
                                            </>
                                            }

                                            {!path || path == "dashboard" &&

                                                <Link to='/backend'>
                                                    <Icon name="angle left" size="large"/>
                                                </Link>

                                            }

                                            {path == "goback" && // Set go back direction

                                                <Icon name='angle left' size="large" onClick={() => this.props.history.goBack()}></Icon>

                                            }

                                            {path == "professional" && // Set go back direction

                                                <Link to='/backend/professional'>
                                                    <Icon name='angle left' size="large"/>
                                                </Link>
                                            }

                                             {/* New Project - handle back arrow */}
                                            {path ==  "new-project" && <>

                                                {location.pathname == "/backend/new_project/firststep" &&
                                                    <Link to='/backend'>
                                                        <Icon name='angle left' size="large"/>
                                                    </Link>
                                                }

                                                {location.pathname == "/backend/new_project/secondstep" &&
                                                    <Link to='/backend/new_project/firststep' >
                                                        <Icon name='angle left' size="large"/>
                                                    </Link>
                                                }

                                                {location.pathname == "/backend/new_project/laststep" &&
                                                    <Link to='/backend/new_project/secondstep'>
                                                        <Icon name='angle left' size="large"/>
                                                    </Link>
                                                }

                                            </>}


                                        </div>

                                    </Menu.Item>
                                    <Menu.Item className="top-header-title">
                                        <Header as="h1">{this.props.headerTitle}</Header>
                                    </Menu.Item>
                                    <Menu.Item position="right"></Menu.Item>
                                </Menu>
                            </Container>
                        }

                        {/* Secondary mobile */}

                        {type === "secondary" && this.checkResponsive(Responsive.onlyMobile) &&
                            //Render for dashboard - Arrow menu
                             <Menu
                                pointing
                                secondary
                                size='mini'
                                color="red"
                                style={{width: "100%"}}
                                className="dashboard-header header-menu menu-mobile"
                                widths={3}
                                as="nav"
                             >

                                 {!path || path == "dashboard" &&
                                     <Menu.Item position="left" as={Link} to='/backend' className="sidebar-item">
                                         <Icon name='angle left' size="large" inverted/>
                                     </Menu.Item>
                                 }
                                 {path == "goback" && // Set go back direction

                                     <Menu.Item position="left" onClick={() => this.props.history.goBack()} className="sidebar-item">
                                         <Icon name='angle left' size="large" inverted/>
                                     </Menu.Item>

                                 }

                                {path == "professional" && // Set go back direction
                                    <Menu.Item position="left" as={Link} to='/backend/professional' className="sidebar-item">
                                        <Icon name='angle left' size="large" inverted/>
                                    </Menu.Item>
                                }

                                 {/* New Project - handle back arrow */}
                                 {path ==  "new-project" && <>

                                     {location.pathname == "/backend/new_project/firststep" &&
                                         <Menu.Item position="left" as={Link} to='/backend' className="sidebar-item">
                                             <Icon name='angle left' size="large" inverted/>
                                         </Menu.Item>
                                     }

                                     {location.pathname == "/backend/new_project/secondstep" &&
                                         <Menu.Item position="left" as={Link} to='/backend/new_project/firststep'  className="sidebar-item">
                                             <Icon name='angle left' size="large" inverted/>
                                         </Menu.Item>
                                     }

                                     {location.pathname == "/backend/new_project/laststep" &&
                                         <Menu.Item position="left" as={Link} to='/backend/new_project/secondstep' className="sidebar-item">
                                             <Icon name='angle left' size="large" inverted/>
                                         </Menu.Item>
                                     }

                                 </>}

                                 <Menu.Item className="top-header-title">
                                     <h1>{this.props.headerTitle &&  this.props.headerTitle.length >= 25 ? this.props.headerTitle.substring(0, 22) + "..." : this.props.headerTitle }</h1>
                                 </Menu.Item>

                                 <Menu.Item position="right"/>

                             </Menu>
                        }

                        {type !== "secondary" && this.checkResponsive(Responsive.onlyMobile) &&
                             <Menu
                                 pointing
                                 secondary
                                 size='mini'
                                 color="red"
                                 style={{width: "100%"}}
                                 className="dashboard-header header-menu menu-mobile"
                                 widths={3}
                                 as="nav"
                                 >

                                 <Menu.Item position="left" onClick={()=>this.handleSidebar(true)} className="sidebar-item">
                                     <Icon name='sidebar' size="large" inverted/>
                                     {notificationsSum > 0 ?
                                        <div className="notifications mobile-notifications">
                                            <div className="notifications-amount">{notificationsSum}</div>
                                        </div>
                                        :
                                        ''
                                     }
                                 </Menu.Item>
                                 <Menu.Item className="top-header-title">
                                    <h1>{this.props.headerTitle &&  this.props.headerTitle.length >= 25 ? this.props.headerTitle.substring(0, 22) + "..." : this.props.headerTitle }</h1>
                                 </Menu.Item>
                                 <Menu.Item position="right"/>

                             </Menu>
                        }

                        {/* Desktop sidebar/Content */}

                        <Container className={this.checkResponsive(Responsive.onlyMobile) ? 'mobileDashboardContainer' : ''}>
                            <Grid columns={showSidebar && this.checkResponsive(Responsive.onlyComputer) ? 2 : 1} padded="horizontally">
                                {showSidebar && this.checkResponsive(Responsive.onlyComputer) &&
                                    <Grid.Column width="4">
                                        <Ref innerRef={this.contextRef}>
                                            <Rail>
                                                <Sticky context={this.contextRef} offset={this.state.menuHeight + 16} style={{marginTop: "1em"}}>
                                                    <Menu
                                                        vertical
                                                        secondary
                                                        className="custom-sidebar"
                                                    >
                                                        <SidebarMenu/>
                                                    </Menu>
                                                </Sticky>
                                            </Rail>
                                        </Ref>
                                    </Grid.Column>
                                }
                                {type !== "fullwidth" && !showSidebar && this.checkResponsive(Responsive.onlyComputer) &&
                                    <Grid.Column width="2"></Grid.Column>
                                }
                                <Grid.Column width={type === "fullwidth" ? 16 : (!this.checkResponsive(Responsive.onlyComputer) ? 16 : 12)} style={!this.checkResponsive(Responsive.onlyComputer) ? {paddingLeft: 0, paddingRight: 0} : {}}>
                                    {children}
                                </Grid.Column>
                                {type !== "fullwidth" && !showSidebar && this.checkResponsive(Responsive.onlyComputer) &&
                                    <Grid.Column width="2"></Grid.Column>
                                }
                            </Grid>
                        </Container>

                    </Sidebar.Pusher>

                    <Responsive
                        {...Responsive.onlyMobile}
                    >
                        <FooterMenu/>
                    </Responsive>

                </Sidebar.Pushable>
            }
            </>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { globalByComponent, account } = state
    const { type, headerTitle, headerActiveItem, showSidebar, path } = globalByComponent[
        'Container'
    ] || {
        type: null,
        headerTitle: null,
        showSidebar: true,
        path: null
    }

    const { canonicalTagUrl } = globalByComponent[
        'CanonicalTag'
    ] || {
        canonicalTagUrl: ''
    }

    const { currentLocation } = globalByComponent[
        'Runtimedata'
    ] || {}
    const { isFailed, isDirty, lastFetch, item, token, notifications } = account || {
        item: {},
        notifications: {}
    }

    //Notifications sum
    let notificationsSum;
    let likes, messages, followers, comments;
    if(notifications){
        if(notifications.count) {
            messages = notifications.count.countMessages ? parseInt(notifications.count.countMessages) : 0;
            comments = notifications.count.countComments && parseInt(notifications.count.countComments);
            followers = notifications.count.countFollowers && parseInt(notifications.count.countFollowers);
            likes = notifications.count.countLikes && parseInt(notifications.count.countLikes)
            notificationsSum = messages + comments + followers + likes;
        }
    }

    return {
        type,
        headerTitle,
        headerActiveItem,
        showSidebar,
        path,
        item,
        isFailed,
        isDirty,
        lastFetch,
        token,
        notificationsSum,
        currentLocation,
        ConsentBanner: globalByComponent['ConsentBanner'],
        canonicalTagUrl
    }
}

export default connect(mapStateToProps)(withRouter(DashboardHeader))
