import React, { Component } from 'react';
import { Container, Divider, Header, Responsive, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { handleContainer, footerActiveItem } from '../actions/global';
import { getPage } from '../actions/page';
import ErrorHandler from '../components/ErrorHandler/ErrorHandler'

class Imprint extends Component {

    componentDidMount = () => {
        const { dispatch } = this.props;
        dispatch(handleContainer("main", "Impressum", null, null, null, null, {description: 'Das Impressum von Vetero. Hast Du auch einen Oldtimer? Dann ist Vetero genau das Richtige für Dich.'}));
        dispatch(footerActiveItem('footermenu'));
        window.scrollTo(0, 0);
        this.fetchPageContent()
    }

    fetchPageContent = () => {
        const { dispatch } = this.props;
        dispatch(getPage(295, 'imprint'))
        
    }

    render() {

        const { title, content, isFailed, isFetching } = this.props;

        return (
            <Container className="imprint">
                <Divider hidden />

                {isFetching && 
                    <Loader active/>
                }

                {isFailed && 
                    <ErrorHandler callbackFunction={this.fetchPageContent}/>
                }

                {!isFetching && title && content && 
                <>
                    <Responsive {...Responsive.onlyMobile}>
                    <   Header as="h2">{title.rendered}</Header>
                    </Responsive>
                    <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                        <Header as="h1">{title.rendered}</Header>
                    </Responsive>
                    <Divider/>

                    <div dangerouslySetInnerHTML={{__html: content.rendered}}/>
                </>
                }
                <Divider hidden/>
            </Container>
        )
    }
}

function mapStateToProps(state, ownProps){
    const { pageByName } = state
    const { title, content, isFetching, isFailed } = pageByName['imprint']
    || {
        isFetching: true,
        isFailed: false,
        content: '',
        title: ''
    }
    
    return {
        content,
        title,
        isFailed,
        isFetching
    }
}

export default connect(mapStateToProps)(Imprint)
