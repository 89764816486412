import {
    FETCH_USER_FEEDPOST_REQUEST,
    FETCH_USER_FEEDPOST_SUCCESS,
    FETCH_USER_FEEDPOST_FAILURE
} from '../actions/user'
import { RESET_STORE } from '../actions/account';

export default function feedpostByUserId(state = {}, action) {

    try {

        if (action.type === RESET_STORE) {
            return {};
        }

        if(action.type === FETCH_USER_FEEDPOST_REQUEST) {
            return {
                ...state,
                [action.meta.userId]: {
                    ...state[action.meta.userId],
                    isFetching: true,
                    isFailed: false
                }
            }
        }

        else if(action.type === FETCH_USER_FEEDPOST_SUCCESS) {
            return {
                ...state,
                [action.meta.userId]: {
                    ...state[action.meta.userId],
                    isFetching: false,
                    isFailed: false,
                    ...action.payload
                }
            }
        }

        else if(action.type === FETCH_USER_FEEDPOST_FAILURE) {
            return {
                ...state,
                [action.meta.userId]: {
                    ...state[action.meta.userId],
                    isFetching: false,
                    isFailed: true
                }
            }
        }


        return state;

    } catch (e) {

        console.log(e);
        return {
            ...state,
            fatalError: true,
        };

    }
}
