export function requiredFields(){
    let requiredFields = [
        "name", "owner", "street", "streetnum", "countrycode", "zip",
        "city", "email", "phone", "headline", "intro", "benefits", "scopes", "types",
        "mediamain", "medialogo", 
    ];

    return requiredFields;
}

export default function ProfessionalValidation(item) {
    let validationObject = {};

    let itemKeys = Object.keys(item);
    let defaultMessage = "Fülle das Feld aus."


    //Validate all fields
    itemKeys.map(field => {
       if(requiredFields().includes(field)){

            //Default validation - check if field is empty
            !item[field] && (
                validationObject = {
                    ...validationObject,
                    [field]: {
                        error: true,
                        errorMsg: defaultMessage
                    }
                }
            )

            //Main image validation
            !item['mediamain'] && (
                validationObject = {
                    ...validationObject,
                    mediamain: {
                        error: true,
                        errorMsg: "Lade das Hauptbild hoch."
                    }
                }
            )

            //Logo image validation
            !item['medialogo'] && (
                validationObject = {
                    ...validationObject,
                    medialogo: {
                        error: true,
                        errorMsg: "Lade das Logo hoch."
                    }
                }
            )

            //Scopes and types validation
            item['scopes'] && item['scopes'].length === 0 && (
                validationObject = {
                    ...validationObject,
                    scopes: {
                        error: true,
                        errorMsg: "Wähle die Kategorie aus."
                    }
                }
            )

            item['types'] && item['types'].length === 0 && (
                validationObject = {
                    ...validationObject,
                    types: {
                        error: true,
                        errorMsg: "Wähle die Kategorie aus."
                    }
                }
            )


       }
    })

    //Check for failed validation steps
    if(validationObject.name || validationObject.owner || validationObject.street || validationObject.streetnum ||
        validationObject.countrycode || validationObject.zip || validationObject.city || validationObject.phone || validationObject.email    
    )
        {
            validationObject = {
                ...validationObject,
                contact: true
            }
        }

    if(validationObject.headline || validationObject.benefits || validationObject.scopes || validationObject.types)
        {
            validationObject = {
                ...validationObject,
                description: true
            }
        }
    if(validationObject.mediamain || validationObject.medialogo)
        {
            validationObject = {
                ...validationObject,
                medias: true
            }
        }
    
    return validationObject;

}