import React from 'react'
import {
    Container,
    Divider,
    Icon,
    Button,
    Input,
    Message,
    Form,
    Grid,
    Responsive,
    Placeholder,
    Image,
    Dropdown,
 } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom'
import Select from 'react-select';
import TextareaAutosize from 'react-textarea-autosize';
import { FileUpload } from 'ix-redux-file-upload';
import ImageWrapper from '../../../../components/helpers/image-wrapper';
import config from '../../../../config/config';
import ProgressiveImager from '../../../../components/helpers/progressive-imager';
import { handleContainer, setRuntime } from '../../../../actions/global';
import { createNewContent, directStoryUpdate, updateStory, discardContent, saveContent } from '../../../../actions/story'
import StoryElements from './StoryElements';
import BeContentElement from './be-content-element'
import BeParent from '../be-parent';
import CustomPopup from '../../../../components/CustomPopup/CustomPopup';
import CustomSlidingPane from '../../../../components/CustomSlidingPane/CustomSlidingPane';
import fetchStoryIfNeeded, { fetchStory } from '../../../../actions/story';
import SaveModal from '../../../../components/SaveModal/SaveModal';
import Swipeout from 'rc-swipeout';
import 'rc-swipeout/assets/index.css'
import './Stories.scss'

/* Icons */
import circleAdd from '../../../../images/icons/circle-add-element.svg';
import beforeAfterIcon from '../../../../images/icons/elements/icon-element-before-after.svg';
import galleryIcon from '../../../../images/icons/elements/icon-element-gallery.svg';
import pictureIcon from '../../../../images/icons/elements/icon-element-picture.svg';
import textImageIcon from '../../../../images/icons/elements/icon-element-text-image.svg';
import textIcon from '../../../../images/icons/elements/icon-element-text.svg';
import videoIcon from '../../../../images/icons/elements/icon-element-video.svg';
import trashIcon from '../../../../images/icons/icon-swipe-trash.svg';
import ArrowUp from '../../../../images/icons/arow-arrange-up-white.svg';
import ArrowDown from '../../../../images/icons/arow-arrange-down-white.svg';
import menuDotsIcon_2 from '../../../../images/icons/footer-menu-dots_2.svg';
import SingleMedia from '../../../../components/PreviewGallery/SingleMedia';

class EditStory extends BeParent {

    inputFields = ['string', 'text', 'media', 'mediaorientation', 'mediaAfter', 'mediaBefore'];

    state = {
        confirmModal: {
            isOpen: false
        },
        popup: {
            popupIsOpen: false,
            popupContent: "",
            popupTitle: "",
            position: "",
        },
        modal: {
            title: "",
            content: "",
            isOpen: "",
            isEdited: false,
            isChanged: false
        },
        newOrderList: [],
        formValidation: {
            validationStoryTitle: {
                error: false,
                message: ''
            },
            contentText: {
                error: false,
                message: ''
            },
            contentMediaOrientation: {
                error: false,
                message: ''
            },
            contentMedia: {
                error: false,
                message: ''
            },
        },
        nextLocation: null,
        leavenow: false,
    }

    componentDidMount = () => {
        const { dispatch, match } = this.props;
        dispatch(handleContainer("secondary", "Story bearbeiten", false));
        dispatch(setRuntime({page: 'be-story-id-'+match.params.storyId}));
        dispatch(fetchStoryIfNeeded(match.params.storyId));
        window.scrollTo(0, 0);

    }

    componentDidUpdate(prevProps, prevState) {
        const { dispatch, contentList, storyItem, isDirty } = this.props;
        const { modal, leavenow, nextLocation } = this.state;

        if (leavenow && !isDirty) {
            this.setState({
                leavenow: false,
            });
            if (nextLocation) {
                this.props.history.push(nextLocation);
            }
        }

        //Reset validation fields
        if(!modal.isOpen && prevState.modal.isOpen !== modal.isOpen){
            this.setState({
                formValidation: {
                    ...this.state.formValidation,
                    contentText: {
                        error: false,
                        message: ''
                    },
                    contentImageText: {
                        text: {
                            error: false,
                            message: ''
                        },
                        orientation: {
                            error: false,
                            message: ''
                        }
                    },
                    contentMedia: {
                        error: false,
                        message: ''
                    },
                    contentMediaOrientation: {
                        error: false,
                        message: ''
                    },
                }
            })
        }

        if(prevProps.storyItem !== storyItem){
            dispatch(handleContainer("secondary", "Story bearbeiten", false));
        }

        if(contentList.length > prevProps.contentList.length) {
            var newest = null;
            contentList.map(function(item) {
                // Is new content
                if (item.id < 0) {
                    if (!newest || newest.id > item.id) {
                        newest = item;
                    }
                }
            })

            if (newest) {

                this.setState({
                    popup: {
                        ...this.state.popup,
                        popupIsOpen: false
                    }
                });

                this.handleModal(true, "Neuen Inhalt bearbeiten", newest.id, newest.blockprefix, false);
            }
        }
    }

    handlePopupContent = () => {
        const { popup } = this.state;
        if(popup['popupContent'] === "choose-element") {
            return this.newElementPopup();
        }
        else if (popup['popupContent'] === "delete-confirm") {
            return this.confirmDelete();
        }
    }

    handleModal = (isOpen, title, elementId, element, isEdited) => {
        this.setState({
            modal: {
                isOpen: true,
                title: title,
                content: element,
                itemId: elementId,
                isEdited: isEdited,
                isChanged: false
            }
        })
    }

    handleStoryElementModal = (element) => {
        const { dispatch, storyItem } = this.props;
        const { popup } = this.state;

        dispatch(createNewContent(element, popup.position, storyItem.id));
    }

    handleDeleteContent = (i) => {
        const { contentList } = this.props;

        let updatedList = {};
        let contentId;
        contentList.filter((item) => {
            if(item.id === i) {
                contentId = item.id;
                return false;
            }
            return true;
        }).map((item, index) => {
            item.position = index;
            updatedList[item.id] = item;
        });

        if(i > 0 ) {
            this.deleteStoryContent(updatedList, false);
        }

        else {
            this.deleteStoryContent(updatedList, true);
        }


    }

    handleSortContentUp = (position) => {
        const { contentList, match } = this.props;

        contentList.map((item) => {
            if(item.input.position === position) {
                this.handleChange("story."+match.params.storyId+".content."+item.id+".position", (position-1));
            }
            else if(item.input.position === position-1) {
                this.handleChange("story."+match.params.storyId+".content."+item.id+".position", position);
            }
        })
    }

    handleSortContentDown = (position) => {
        const { contentList, match } = this.props;

        contentList.map((item) => {
            if(item.input.position === position) {
                this.handleChange("story."+match.params.storyId+".content."+item.id+".position", (position+1));
            }
            else if(item.input.position === position+1) {
                this.handleChange("story."+match.params.storyId+".content."+item.id+".position", position);
            }
        });
    }

    projectValidation = () => {
        const { storyItem, contentList, dispatch } = this.props;

        if(config.validateTextField(storyItem.title, true).error){
            this.setState({
                formValidation: {
                    ...this.state.formValidation,
                    validationStoryTitle: config.validateTextField(storyItem.title, true)
                }
            })
            return false
        }
        else {
            this.setState({
                formValidation: {
                    ...this.state.formValidation,
                    validationStoryTitle: config.validateTextField(storyItem.title, true)
                }
            })
            dispatch(updateStory(storyItem, contentList));
        }

    }

    revertChangeValidation = () => {
        const { dispatch, storyItem } = this.props;

        // this.revertChange();
        dispatch(fetchStory(storyItem.id));

        this.setState({
            formValidation: {
                ...this.state.formValidation,
                validationStoryTitle: {
                    error: false,
                    message: ''
                }
            }
        })

    }

    confirmLeaveModal = () => {
        const { contentList } = this.props;
        const { modal } = this.state;

        var content = contentList.filter(contentList => contentList.id === this.state.modal.itemId)[0];

        if (content && config.isChanged(content, this.inputFields)) {
            this.setState({
                confirmModal: {
                    isOpen: true
                },
            })
        }
        else if (content) {
            if(!modal.isEdited){
                this.handleDeleteContent(modal.itemId);
                this.setState({modal: {
                    ...this.state.modal,
                    itemId: null,
                    isOpen: false,
                }})
            }
            else {
                this.setState({modal: {
                    ...this.state.modal,
                    isOpen: false,
                }})
            }
        }
    }

    modalCancelData = () => {
        const { dispatch, contentList, match } = this.props;
        const { modal } = this.state;

        //Delete new content element if leaving without saving
        if(!modal.isEdited){
            this.handleDeleteContent(modal.itemId)
        }
        else {
            dispatch(discardContent(match.params.storyId, modal.itemId, contentList));

        }

        this.setState({
            modal: {
                ...this.state.modal,
                isOpen: false,
            },
            confirmModal: {
                isOpen: false
            }
        })

    }

    validateStoryElements = () => {

        const { contentList } = this.props;

        let current = contentList.filter(contentList => contentList.id === this.state.modal.itemId)[0];

        /* Text element */

        if(
            current.blockprefix === 'contenttext' ||
            current.blockprefix === 'contentheadline'
        ){
            {!current.input.string && !current.input.text ?
                this.setState({
                    formValidation: {
                        ...this.state.formValidation,
                        contentText: {
                            error: true,
                            message: 'Fülle eins von den beiden Felder aus'
                        }
                    }
                }, this.acceptStoryElements)

                :

                this.setState({
                    formValidation: {
                        ...this.state.formValidation,
                        contentText: {
                            error: false,
                            message: ''
                        }
                    }
                }, this.acceptStoryElements)

            }

        }

        if(
            current.blockprefix === 'contentvideo' ||
            current.blockprefix === 'contentimage'
        ){

            this.setState({
                formValidation: {
                    ...this.state.formValidation,
                    contentMedia: config.validateMedia(current.input.media)
                }
            }, this.acceptStoryElements)
        }


        /* Before / After element */

        else if(current.blockprefix === 'contentbeforeafter') {


            this.setState({
                formValidation: {
                    ...this.state.formValidation,
                    contentMedia: config.validateMedia(current.input.mediaAfter)
                }
            }, this.acceptStoryElements)

            this.setState({
                formValidation: {
                    ...this.state.formValidation,
                    contentMedia: config.validateMedia(current.input.mediaAfter)
                }
            }, this.acceptStoryElements)
        }

        /* Gallery / Image element */

        else if(current.blockprefix === 'contentgallery' || current.blockprefix === 'contentimage') {

            this.setState({
                formValidation: {
                    ...this.state.formValidation,
                    contentMedia: config.validateMedia(current.input.media)
                }
            }, this.acceptStoryElements)

        }

        /* Text / Image */

        else if (current.blockprefix === 'contentimagetext') {

            this.setState({
                formValidation: {
                    ...this.state.formValidation,
                    contentMedia: config.validateMedia(current.input.media),
                    contentText: config.validateTextField(current.input.text, true),
                    contentMediaOrientation: config.validateSelectInput(current.input.mediaorientation)
                }
            }, this.acceptStoryElements)

        }

    }


    acceptStoryElements = () => {
        const { formValidation, modal } = this.state;
        const { contentList, match, dispatch } = this.props;

        if(
            !formValidation.contentText.error &&
            !formValidation.contentMedia.error &&
            !formValidation.contentMediaOrientation.error
        ) {

            //Save changes
            dispatch(saveContent(match.params.storyId, modal.itemId, contentList));
            this.setState({
                modal: {
                    ...this.state.modal,
                    isOpen: false,
                    isEdited: true,
                }
            })

        }
        else {
            return false;
        }

    }


    render() {
        const { isFetching, storyItem} = this.props;
        if((!storyItem || !storyItem.id) && isFetching) {
            return (
                <Container className="edit-story">
                    <Form className="placeholder-form">
                        <Form.Field>
                            <label>Titel der Story</label>
                            <Form.Input
                                value="-"
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Kategorie</label>
                            <Select menuPlacement="auto"
                                placeholder="-"
                                className="custom-select"
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Warum hast Du das in der Story beschriebene gemacht?</label>
                            <Form.TextArea
                                rows={6}
                                value="-"
                            />
                        </Form.Field>

                    </Form>
                    <Divider hidden/>


                    <Image src={circleAdd} className="add-element-btn btn-placeholder"/>
                    <Placeholder>
                        <Placeholder.Image style={{width: "100%"}}/>
                    </Placeholder>
                    <Image src={circleAdd} className="add-element-btn btn-placeholder"/>
                    <Placeholder>
                        <Placeholder.Image style={{width: "100%"}}/>
                    </Placeholder>
                </Container>
            )
        }
        else {
            const { dispatch, token, storyItem, contentList, isDirty, isUpdating, isFetching } = this.props;
            const { confirmModal, modal, popup } = this.state;
            return (
                <>
                {storyItem &&
                <>
                <Container className="edit-story" key="edit-story">
                    {/* Show Savemodal only when all popups/modals are closed and Story isDirty */}
                    {!confirmModal.isOpen && !modal.isOpen && !popup.popupIsOpen &&
                        <SaveModal 
                            key="savemodal"
                            showSaveModal={isDirty} 
                            fetching={isFetching} 
                            submitText="Änderungen speichern"
                            revertText="Änderungen verwerfen"
                            revert={this.revertChangeValidation} 
                            submit={this.projectValidation}
                            messageText="Du bist dabei, Änderungen an Deiner Story vorzunehmen. Nimm weiter Änderungen vor, bis Du fertig bist. Danach kannst Du alle Änderungen speichern oder verwerfen."
                        />
                    }
                    {(!storyItem.active && contentList && contentList.length > 0) &&
                        <Grid>
                            <Grid.Column textAlign="center">
                                <Divider hidden/>
                                <Message negative>
                                    Diese Story ist geparkt und deshalb für andere unsichtbar. Du solltest die Story veröffentlichen. Du kannst sie jederzeit erneut parken.
                                </Message>
                                <Button loading={isUpdating} color="green" onClick={() => {
                                        if (!isUpdating) {
                                            dispatch(directStoryUpdate(storyItem.id, 'active', true));
                                        }
                                }}>
                                    <span>Diese Story veröffentlichen</span>
                                </Button>
                            </Grid.Column>
                        </Grid>
                    }

                    {(storyItem.active && (!contentList || !contentList.length)) &&
                        <Grid>
                            <Grid.Column textAlign="center">
                                <Divider hidden/>
                                <Message negative>
                                    Diese Story hat noch keinen Inhalt. Du kannst Texte, Bilder und Videos hinzufügen, um deine Tätigkeiten zu beschreiben.
                                </Message>
                                <Button color="red" onClick={() => {
                                    this.setState({ popup: { popupIsOpen: true, popupContent: "choose-element", popupTitle: "Neuen Inhalt hinzufügen", position: 0 } });
                                    window.scrollTo(0,document.body.scrollHeight);
                                }}>
                                    <span>Jetzt Inhalt hinzufügen</span>
                                </Button>
                            </Grid.Column>
                        </Grid>
                    }

                    {(!storyItem.active && (!contentList || !contentList.length)) &&
                        <>
                            <Divider hidden/>
                            <Message negative>
                                Diese Story ist geparkt (für andere nicht sichtbar) und enthält noch keine echten Inhalte. Du kannst Texte, Bilder und Videos hinzufügen, um deine Tätigkeiten zu beschreiben und die Story danach veröffentlichen. Du kannst die Story jederzeit erneut parken.
                            </Message>
                            <Grid>
                                <Grid.Column textAlign="right" only="tablet computer" width={8}>
                                    <Button loading={isUpdating} color="green" onClick={() => {
                                            if (!isUpdating) {
                                                dispatch(directStoryUpdate(storyItem.id, 'active', true));
                                            }
                                    }}>
                                        <span>Diese Story veröffentlichen</span>
                                    </Button>
                                    <div style={{marginBottom: '1em'}}></div>
                                </Grid.Column>
                                <Grid.Column textAlign="left" only="tablet computer" width={8}>
                                    <Button color="red" onClick={() => {
                                        this.setState({ popup: { popupIsOpen: true, popupContent: "choose-element", popupTitle: "Neuen Inhalt hinzufügen", position: 0 } });
                                        window.scrollTo(0,document.body.scrollHeight);
                                    }}>
                                        <span>Jetzt Inhalt hinzufügen</span>
                                    </Button>
                                </Grid.Column>
                                <Grid.Column textAlign="center" only="mobile" width={16}>
                                    <Button loading={isUpdating} color="green" onClick={() => {
                                            if (!isUpdating) {
                                                dispatch(directStoryUpdate(storyItem.id, 'active', true));
                                            }
                                    }}>
                                        <span>Diese Story veröffentlichen</span>
                                    </Button>
                                </Grid.Column>
                                <Grid.Column textAlign="center" only="mobile" width={16} style={{paddingTop: '0'}}>
                                    <Button color="red" onClick={() => {
                                        this.setState({ popup: { popupIsOpen: true, popupContent: "choose-element", popupTitle: "Neuen Inhalt hinzufügen", position: 0 } });
                                        window.scrollTo(0,document.body.scrollHeight);
                                    }}>
                                        <span>Jetzt Inhalt hinzufügen</span>
                                    </Button>
                                </Grid.Column>
                            </Grid>
                        </>
                    }
                    {isDirty ?
                        <div key="test">
                            <Responsive  {...Responsive.onlyMobile} key="divider" as={Divider} style={{paddingBottom: "90px"}}/>
                        </div> :
                            null
                        }

                    <Form>
                        <Divider hidden/>
                        <Form.Field
                            error={this.state.formValidation.validationStoryTitle.error}
                            required
                            key="storytitle"
                        >
                            <label>Titel der Story</label>
                            <Input
                                name={"story."+storyItem.id+".title"}
                                onChange={this.handleChangeInput}
                                value={storyItem.title}
                                key={"story."+storyItem.id+".title"}
                            />
                        </Form.Field>
                        {this.state.formValidation.validationStoryTitle.error &&
                        <Message negative>Bitte fülle den Titel aus.</Message>
                        }
                        <Form.Field>
                            <label>Kategorie</label>
                            <Select menuPlacement="auto"
                                options={config.getCategoriesSub()}
                                name={"story."+storyItem.id+".category"}
                                onChange={this.handleChangeSelect}
                                value={config.getDefault(config.getCategoriesSub(), storyItem.category)}
                                classNamePrefix="custom-select"
                                className="custom-select-wrapper"
                            />
                        </Form.Field>

                        <Form.Field>
                            <label>Hauptbild</label>
                        </Form.Field>

                        <FileUpload
                          headers={{ 'Authorization': 'Bearer '+token }}
                          allowedFileTypes={['jpg', 'jpeg']}
                          dropzoneId={'project.'+storyItem.project+'.story.' + storyItem.id + '.mediamain'}
                          url={config.API_HOST + config.API_UPLOAD}
                        >

                            <SingleMedia
                                editable
                                image={{
                                    path: storyItem.mediamainPath,
                                    progress: storyItem.mediamainProgress,
                                    id: storyItem.mediamain
                                }}
                            />

                        </FileUpload>
                        <Divider hidden/>

                        <Form.Field>
                            <label>Warum hast Du das in der Story beschriebene gemacht?</label>
                            <TextareaAutosize
                                minRows="5"
                                maxRows="15"
                                name={"story."+storyItem.id+".description"}
                                onChange={this.handleChangeInputAlt}
                                value={storyItem.description}
                            />
                        </Form.Field>
                    </Form>
                    <Divider hidden/>

                    <Divider hidden/>
                    <Divider hidden/>

                    </Container>

                    {/* Story elements */}

                    <CustomPopup
                        popupOpen={this.state.popup.popupIsOpen}
                        onClose={() => this.setState({popup: {popupIsOpen: false}})}
                        popupHeader={this.state.popup.popupTitle}
                        className="new-element-popup-content "
                    >
                        {this.handlePopupContent()}
                    </CustomPopup>

                    {contentList && contentList.length ?
                        <Image src={circleAdd} onClick={() => this.setState({ popup: { popupIsOpen: true, popupContent: "choose-element", popupTitle: "Neuen Inhalt hinzufügen", position: 0 } })} className="add-element-btn"/>
                    :
                        <Grid>
                            <Grid.Column textAlign="center">
                                <Button color="red" onClick={() => this.setState({ popup: { popupIsOpen: true, popupContent: "choose-element", popupTitle: "Neuen Inhalt hinzufügen", position: 0 } })}>
                                    <span>Jetzt Inhalt hinzufügen</span>
                                </Button>
                                <Divider hidden/>
                                <Divider hidden/>
                                <Divider hidden/>
                                <Divider hidden/>
                            </Grid.Column>
                        </Grid>
                    }

                    {/* Mobile */}

                    <Responsive maxWidth={Responsive.onlyTablet.maxWidth}>

                        {contentList.map((content, index) =>
                        <>
                            <Swipeout key={index} autoClose
                                right={[
                                    {
                                        text: <div
                                                onTouchEnd={() => this.handleDeleteContent(content.id)}
                                                className="swipeout-img-container"
                                                >
                                                    <div className="swipeout-img">
                                                        <Image src={trashIcon}/>
                                                    </div>
                                                </div>,
                                        style: {
                                            backgroundColor: "#E7344C",
                                            color: "white",
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            width: "92px",
                                            height: "100%"
                                        },
                                    },

                                ]}
                                left={[
                                    {
                                        text: <div
                                                className="swipeout-img-container"
                                                onTouchEnd={() => this.handleSortContentUp(content.position)}
                                              >
                                                  <div className="swipeout-img">
                                                    <Image src={ArrowUp}/>
                                                  </div>
                                              </div>,
                                        style: {
                                            color: "white",
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            width: "92px",
                                            height: "100%",
                                            display: index !== 0 ? "flex" : "none",
                                        },
                                        className: "sort-btn up",

                                    },
                                    {
                                        text: <div
                                                className="swipeout-img-container"
                                                onTouchEnd={() => this.handleSortContentDown(content.position)}
                                              >
                                                  <div className="swipeout-img">
                                                    <Image src={ArrowDown} style={{width: "24px", height:"27px", margin: "0 auto 0.5rem", textAlign:"center"}}/>
                                                  </div>
                                              </div>,
                                        style: {
                                            color: "white",
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            width: "92px",
                                            height: "100%",
                                            display: index == contentList.length-1 ? "none" : "flex",
                                        },
                                        className: "sort-btn down",
                                    },

                                ]}
                            >
                                <>
                                    {/* {content.position} */}
                                    <BeContentElement content={content} popup={this.handleModal}/>
                                </>
                            </Swipeout>
                            <Image src={circleAdd} onClick={() => this.setState({ popup: { popupIsOpen: true, popupContent: "choose-element", popupTitle: "Neuen Inhalt hinzufügen", position: (content.position + 1) } })} className="add-element-btn"/>
                        </>
                        )}

                    </Responsive>

                    {/* Desktop */}

                    <Responsive minWidth={Responsive.onlyComputer.minWidth}>

                        {contentList.map((content, index) =>
                        <>
                            {/* {content.position} */}
                            <div className="content-element-wrapper">

                                <BeContentElement content={content} popup={this.handleModal}/>

                                <div className="content-element-menu">
                                    <Dropdown
                                        icon={
                                            <div className="content-element-menu-icon-wrapper">
                                                <Image className="content-element-menu-icon" src={menuDotsIcon_2}/>
                                            </div>
                                        }
                                    >
                                        <Dropdown.Menu style={{left: 'auto', right: 0}}>
                                            <Dropdown.Item className="sort-btn up" onClick={() => this.handleSortContentUp(content.position)} style={{display: index !== 0 ? "flex" : "none"}}>
                                                <Image src={ArrowUp} />
                                                Nach oben verschieben
                                            </Dropdown.Item>

                                            <Dropdown.Item className="sort-btn down" onClick={() => this.handleSortContentDown(content.position)} style={{display: index == contentList.length-1 ? "none" : "flex"}}>
                                                <Image src={ArrowDown} />
                                                Nach unten verschieben
                                            </Dropdown.Item>

                                            <Dropdown.Item className="delete-element" onClick={() => this.handleDeleteContent(content.id)}>
                                                <Image src={trashIcon} />
                                                Löschen
                                            </Dropdown.Item>

                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>

                            </div>

                            <Image src={circleAdd} onClick={() => this.setState({ popup: { popupIsOpen: true, popupContent: "choose-element", popupTitle: "Neuen Inhalt hinzufügen", position: (content.position + 1) } })} className="add-element-btn"/>
                        </>
                        )}

                        <Divider hidden/>

                    </Responsive>

                    <Divider hidden/>
                    <Divider hidden/>
                    <Divider hidden/>

                    <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                        <Divider hidden />
                        <Divider hidden />
                    </Responsive>

                    {/* Story elements - slidingPane mobile */}
                    <Responsive maxWidth={Responsive.onlyTablet.maxWidth}>

                        <CustomSlidingPane
                            isOpen={this.state.modal.isOpen}
                            title={this.state.modal.title}
                            // onRequestClose={() => this.setState({slidingPane: { isOpen: false }})}
                            onRequestClose={this.confirmLeaveModal}
                            saveButtonCallback={this.validateStoryElements}
                            saveButtonLabel="Übernehmen"
                            saveButton={this.state.modal.itemId && contentList.filter(contentList => contentList.id === this.state.modal.itemId )[0] && config.isChanged(contentList.filter(contentList => contentList.id === this.state.modal.itemId )[0], this.inputFields)}
                        >
                            <>
                            {contentList.filter(contentList => contentList.id === this.state.modal.itemId).map(content =>
                                <>
                                    <StoryElements
                                        content={content}
                                        element={this.state.modal.content}
                                        edited={this.state.modal.isEdited}
                                        projId={this.props.match.params.id}
                                        storyId={this.props.match.params.storyId}
                                        validation={this.state.formValidation}

                                    />
                                </>
                            )}
                            </>
                        </CustomSlidingPane>

                    </Responsive>

                    {/* Story elements - popup desktop */}
                    <Responsive minWidth={Responsive.onlyComputer.minWidth}>

                        <CustomPopup
                            popupOpen={this.state.modal.isOpen}
                            onClose={this.confirmLeaveModal}
                            popupHeader={this.state.modal.title}
                            className="larger-popup"
                            saveButtonCallback={this.validateStoryElements}
                            saveButtonLabel="Übernehmen"
                            saveButton={this.state.modal.itemId && contentList.filter(contentList => contentList.id === this.state.modal.itemId )[0] && config.isChanged(contentList.filter(contentList => contentList.id === this.state.modal.itemId )[0], this.inputFields)}
                        >
                            <>
                            {contentList.filter(contentList => contentList.id === this.state.modal.itemId).map(content =>
                                <>
                                    <StoryElements
                                        content={content}
                                        element={this.state.modal.content}
                                        edited={this.state.modal.isEdited}
                                        projId={this.props.match.params.id}
                                        storyId={this.props.match.params.storyId}
                                        validation={this.state.formValidation}
                                    />
                                </>
                            )}

                            </>
                        </CustomPopup>

                    </Responsive>

                    {this.confirmModal(() => {
                            if (!this.state.modal.isOpen) {
                                this.revertChangeValidation();
                                this.setState({
                                    leavenow: true,
                                });
                            }
                            else {
                                this.modalCancelData();
                            }
                        })}
                        <Prompt when={this.props.isDirty} message={(location, action) => {
                            this.setState({
                                confirmModal: {
                                    isOpen: true,
                                },
                                nextLocation: location,
                            });

                            return false;
                        }} />
                    </>
                }
                </>
            )
        }
    }

    newElementPopup(position){
        return (
            <> {/* Popup - Mobile */}

            <Container className="popup-content small-popup-content">
                <Message color="teal">Wähle den passenden Inhaltstyp. Du kannst zusätzlich bei jedem eine Überschrift und einen Text hinzufügen.</Message>
                <Divider hidden/>

                <Grid>
                    <Grid.Row columns={2}>
                        {/* Mobile */}
                        <Grid.Column style={{paddingRight: "7.5px"}} only="mobile tablet">
                            <Button basic color="red" onClick={() => this.handleStoryElementModal("contentbeforeafter")}>
                                <Image src={beforeAfterIcon} style={{float: "left", marginRight: "0.5rem"}}/>
                                <div>Vorher / Nachher</div>
                            </Button>
                            <Button basic color="red" onClick={() => this.handleStoryElementModal("contentimagetext")}>
                                <Image src={textImageIcon}/>
                                <span>Text / Bild</span>
                            </Button>
                            <Button basic color="red" onClick={() => this.handleStoryElementModal("contentgallery")}>
                                <Image src={galleryIcon}/>
                                <span>Galerie</span>
                            </Button>
                        </Grid.Column>

                        <Grid.Column style={{paddingLeft: "7.5px"}} only="mobile tablet">
                            <Button basic color="red" onClick={() => this.handleStoryElementModal("contentvideo")}>
                                <Image src={videoIcon}/>
                                <span>Video</span>
                            </Button>
                            <Button basic color="red" onClick={() => this.handleStoryElementModal("contentimage")}>
                                <Image src={pictureIcon}/>
                                    <span>Bild</span>
                            </Button>
                            <Button basic color="red" onClick={() => this.handleStoryElementModal("contenttext")}>
                                <Image src={textIcon}/>
                                <span>Text</span>
                            </Button>
                        </Grid.Column>

                        {/* Desktop */}
                        <Grid.Column only="computer" style={{paddingRight: "7.5px"}}>
                            <Button basic color="red" onClick={() => this.handleStoryElementModal("contentbeforeafter")}>
                                <Image src={beforeAfterIcon} style={{float: "left", marginRight: "0.5rem"}}/>
                                <div>Vorher / Nachher</div>
                            </Button>
                            <Button basic color="red" onClick={() => this.handleStoryElementModal("contentimagetext")}>
                                <Image src={textImageIcon}/>
                                <span>Text / Bild</span>
                            </Button>
                            <Button basic color="red" onClick={() => this.handleStoryElementModal("contentgallery")}>
                                <Image src={galleryIcon}/>
                                <span>Galerie</span>
                            </Button>
                        </Grid.Column>

                        <Grid.Column only="computer" style={{paddingLeft: "7.5px"}}>
                            <Button basic color="red" onClick={() => this.handleStoryElementModal("contentvideo")}>
                                <Image src={videoIcon}/>
                                <span>Video</span>
                            </Button>
                            <Button basic color="red" onClick={() => this.handleStoryElementModal("contentimage")}>
                                <Image src={pictureIcon}/>
                                    <span>Bild</span>
                            </Button>
                            <Button basic color="red" onClick={() => this.handleStoryElementModal("contenttext")}>
                                <Image src={textIcon}/>
                                <span>Text</span>
                            </Button>
                        </Grid.Column>

                    </Grid.Row>
                </Grid>

             </Container>

             </>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { projectById, storyById, account } = state
    const { item: storyItem, isFetching, contents, isUpdating, isDirty } = storyById[
        ownProps.match.params.storyId
    ] || {
        storyItem: {},
        contents: {},
        isFetching: true,
        isDirty: false,
    }
    const { item } = projectById[
        ownProps.match.params.id
    ] || {
        item: {},
    }

    // Sort contents
    var contentList = [];

    if (contents) {
        contentList = Object.values(contents);
    }
    contentList.sort((a, b) => a.input.position > b.input.position ? 1 : -1);
    // contentList.map(item => Object.values(item.input).sort((a, b) => a.position > b.position ? 1 : -1))

    // Fix corrupt positions
    for (var i = 0; i < contentList.length; ++i) {
        contentList[i].position = i;


        if (contentList[i].input.blockprefix === 'contentgallery') {
            // Original
            if (!contentList[i].media) {
                contentList[i].media = [];
            }
            contentList[i].media.sort((a, b) => a.position > b.position ? 1 : -1);

            for (var j = 0; j < contentList[i].length; ++j) {
                contentList[i].media[j].position = j;
            }

            // Input
            if (!contentList[i].input.media) {
                contentList[i].input.media = [];
            }
            contentList[i].input.media.sort((a, b) => a.position > b.position ? 1 : -1);

            for (var j = 0; j < contentList[i].input.length; ++j) {
                contentList[i].input.media[j].position = j;
            }
        }
    }

    const { item: accountItem, token } = account || {
        accountItem: {},
        token: null
    }


    return {
        accountItem,
        token,
        isFetching,
        storyItem,
        contentList,
        listitems: [{
            storyItem,
            contents: contentList,
        }],
        item,
        isDirty,
        isUpdating,
    }

}

export default connect(mapStateToProps)(EditStory);
