import React, { Component } from 'react';
import { Container, Image, Grid, Divider, Header, Button } from 'semantic-ui-react';
import { footerActiveItem, handleContainer, handleHeaderItems } from '../actions/global';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { setMetadata } from '../actions/global';
import errorImage from '../images/error-500.svg';
import { resetStore } from '../actions/account';

class Error500 extends Component {

    componentDidMount = () => {
        const { dispatch } = this.props;
        dispatch(footerActiveItem("news"))
        dispatch(handleContainer("newsfeed", "Newsfeed"));
        dispatch(handleHeaderItems("newsfeed"));
    }

    reloadApp = () => {
        const { dispatch } = this.props;
        dispatch(resetStore());
        window.location.reload();
    }

    render() {
        const { dispatch, history } = this.props;

        return (
            <Container className="not-found-page">
                <Divider hidden/>
                <Divider hidden/>
                <Grid reversed="mobile">
                    <Grid.Column mobile={16} tablet={8} computer={6}>
                        <Header as="h2">UUUPS!</Header>
                        <div className="not-found-error">Fehler 500</div>
                        <div className="error-message">Da ist wohl etwas schief gelaufen!</div>
                    </Grid.Column>

                    <Grid.Column mobile={16} tablet={8} computer={10}>
                        <Image src={errorImage} alt="500"/>
                    </Grid.Column>
                    <Divider hidden/>

                    <Grid.Column mobile={16} tablet={8} computer={10} textAlign="center">
                        <Button
                            as={Link}
                            to='/'
                            onClick={this.reloadApp}
                            color="red"
                            >
                            Durchstarten
                        </Button>
                    </Grid.Column>
                </Grid>
            </Container>
        )
    }
}

export default connect()(Error500);
