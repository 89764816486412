import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Container, Form, Message, Button, Icon, Image, Divider, Label, Grid } from 'semantic-ui-react';
import { setRuntime, handleContainer } from '../../../../actions/global';
import { clearNewProfessional, postProfessional, newProfessional, newProfessionalRemoveTag, newProfessionalAddTag, newProfessionalDeleteDirectTag, newProfessionalRevertTag, newProfessionalSaveTag} from '../../../../actions/professional';
import BeParent from '../../../project/backend/be-parent';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/emoticons/js/emojis';
import 'tinymce/plugins/paste';
import { Editor } from '@tinymce/tinymce-react';
import '../../../../tinymce/langs/de';
import Select from 'react-select';
import AddIcon from '../../../../images/icons/circle-add-element.svg';
import CustomPopup from '../../../../components/CustomPopup/CustomPopup';
import TextareaAutosize from 'react-textarea-autosize';
import { FileUpload } from 'ix-redux-file-upload';
import config from '../../../../config/config';
import { Redirect, Link } from 'react-router-dom';
import { changeProfessional } from './../../../../actions/professional';


class ContactData extends BeParent {

    state = {
        tagModelIsOpen: false
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);
    }

    submit = () => {
        const { dispatch, inputProfessional } = this.props;
        dispatch(changeProfessional(inputProfessional)); 
    }

    render() {

        const { professional, id, validation, requestValidateSingle } = this.props;

        return (
                <>

                <div className="new-professional">
                    <Divider hidden/>

                    <Message color="teal">
                        Alle unten angegebene Inhalte werden im Inserat für alle Besucher angezeigt. 
                        Die Inhalte können hier jederzeit angepasst werden. Es kann allerdings ein paar Stunden dauern bis geänderte Inhalte im Inserat angezeigt werden.
                    </Message>

                    <Form>

                        <Form.Field error={validation.name && validation.name.error}>
                            <label>Unternehmen</label>
                            <Form.Input
                                type="text"
                                name={`professional.${id}.input.name`}
                                onChange={this.handleChangeInput}
                                value={professional.input.name}
                            />
                        </Form.Field>

                        <Form.Field error={validation.owner && validation.owner.error}>
                            <label>Ansprechpartner</label>
                            <Form.Input
                                type="text"
                                name={`professional.${id}.input.owner`}
                                onChange={this.handleChangeInput}
                                value={professional.input.owner}
                            />
                        </Form.Field>

                        <Form.Group>

                            <Form.Field width="16"  error={validation.street && validation.street.error}>
                                <label>Straße</label>
                                <Form.Input
                                    type="text"
                                    name={`professional.${id}.input.street`}
                                    onChange={this.handleChangeInput}
                                    value={professional.input.street ? professional.input.street : ""}
                                 
                                />
                            </Form.Field>

                            {/*<Form.Field width="6" error={validation.streetnum && validation.streetnum.error}>
                                <label>Nummer/Zusatz</label>
                                <Form.Input
                                    type="text"
                                    name={`professional.${id}.input.streetnum`}
                                    onChange={this.handleChangeInput}
                                    value={professional.input.streetnum}
                                />
                        </Form.Field>*/}

                        </Form.Group>

                        <Form.Field width="6" error={validation.countrycode && validation.countrycode.error}>
                            <label>Land</label>
                            <Select
                                type="text"
                                name={`professional.${id}.input.countrycode`}
                                options={config.COUNTRY_CODES}
                                onChange={this.handleChangeSelect}
                                placeholder="Auswahl..."
                                isSearchable={false}
                                classNamePrefix="custom-select"
                                value={config.getDefault(config.COUNTRY_CODES, professional.input.countrycode)}
                            />
                        </Form.Field>

                        <Form.Group>
                            <Form.Field width="6" error={validation.zip && validation.zip.error}>
                                <label>PLZ</label>
                                <Form.Input
                                    type="text"
                                    name={`professional.${id}.input.zip`}
                                    onChange={this.handleChangeInput}
                                    value={professional.input.zip}
                                    maxLength="5"
                                />
                            </Form.Field>
                            <Form.Field width="10" error={validation.city && validation.city.error}>
                                <label>Ort</label>
                                <Form.Input
                                    type="text"
                                    name={`professional.${id}.input.city`}
                                    onChange={this.handleChangeInput}
                                    value={professional.input.city}
                                />
                            </Form.Field>
                        </Form.Group>

                        <Form.Field error={validation.phone && validation.phone.error}>
                            <label>Telefonnummer</label>
                            <Form.Input
                                type="text"
                                name={`professional.${id}.input.phone`}
                                onChange={this.handleChangeInput}
                                value={professional.input.phone}
                            />
                        </Form.Field>

                        <Form.Field error={validation.email && validation.email.error}>
                            <label>E-mail</label>
                            <Form.Input
                                type="text"
                                name={`professional.${id}.input.email`}
                                onChange={this.handleChangeInput}
                                value={professional.input.email}
                            />
                        </Form.Field>

                        <Form.Field>
                            <label>Website</label>
                            <Form.Input
                                type="text"
                                name={`professional.${id}.input.website`}
                                onChange={this.handleChangeInput}
                                value={professional.input.website}
                            />
                        </Form.Field>
                       
                    </Form>
                    
                    <Divider hidden/>

                </div>
                </>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { account, professionalsById } = state;

    const { item: accountItem, token } = account || {
        item: {},
    }

    const { isFetching, isFailed, item: professional, inputProfessional } = professionalsById[ownProps.id] || {
        professional: {},
        isFetching: true,
        isFailed: false,
        inputProfessional: {}
    }
    return {
        accountItem,
        token,
        isFetching, 
        isFailed,
        professional,
        inputProfessional
    };
}

export default connect(mapStateToProps)(ContactData);
