import React, { Component } from 'react';
import { Button, Image } from 'semantic-ui-react';
import './PlusButton.scss'
import plusIcon from '../../images/icons/plus-symbol.svg';

class PlusButton extends Component {
    render() {

        const { color, onClick, disabled, loading, as, to, title } = this.props;

        return (
            <Button
                className="custom-plus-button"
                color={color}
                onClick={onClick}
                disabled={disabled}
                loading={loading}
                as={as}
                to={to}
            >
                <span>{title}</span>
                {!loading && <Image src={plusIcon}/> }
            </Button>
        )
    }
}

export default PlusButton;