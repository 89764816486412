import React, { Component } from 'react';
import { Container, Button, Icon, Divider } from 'semantic-ui-react'
import CustomPopup from './CustomPopup';

class DeletePopup extends Component {
    render(){

        const { deleteText, onDelete, onCancel, popupIsOpen, onClose, popupHeader, fetching, deleteButtonText } = this.props;

        return(
            <CustomPopup
                popupOpen={popupIsOpen}
                onClose={onClose}
                popupHeader={popupHeader}
            >
                <Container>
                    <Divider hidden/>
                    <div style={{textAlign: "center", margin: "1rem 0"}}>
                        <Icon name="warning sign" color="red" size="huge"/>
                    </div>
                    <div style={{textAlign: "center"}}>{deleteText}</div>
                    <Divider hidden/>
                    <div className="buttons-center">
                        <Button color="red" onClick={onCancel}>
                            Zurück
                        </Button>
                        <Button basic color="red" loading={fetching} onClick={onDelete}>
                            {deleteButtonText ? deleteButtonText : "Löschen"}
                        </Button>
                    </div>
                    <Divider hidden/>
                </Container>
            </CustomPopup>
        )
    }
}

export default DeletePopup;