import React, { Component } from 'react'
import { Container, Grid, Header, Icon, Responsive } from 'semantic-ui-react';
import config from '../../../config/config'
import ProgressiveImager from '../../../components/helpers/progressive-imager';
import ImageWrapper from '../../../components/helpers/image-wrapper';
import Truncate from 'react-truncate';

import Gallery from "../../../components/Gallery/Gallery";
import '../stories.scss';

const nl2br = require('react-nl2br');


export default class FeContentElement extends Component {

    state = {
        storyGallery: null,
        storySlide: 0,
        videoLightboxIsOpen: false
    }

    handleGallery = (contentItem, slide) => {
        if(!slide) {
            slide = 0;
        }
        this.setState({storyGallery: contentItem, storySlide: slide});
    }

    render() {
        switch(this.props.content.blockprefix){
            case "contentbeforeafter":
                return this.beforeAfterElement();
            case "contentgallery":
                return this.galleryElement();
            case "contentimagetext":
                return this.textImage();
            case "contentvideo":
                return this.video();
            case "contentimage":
                return this.image();
            case "contentheadline":
            case "contenttext":
                return this.text();
            default:
                return (<div>{this.props.content.blockprefix}</div>)
        }
    }

    beforeAfterElement(){
        const {content, contentItem} = this.props;
        let beforeAfterImagesMobile = [config.getImageUrl('zoom', content.mediaBeforePath), config.getImageUrl('zoom', content.mediaAfterPath) ];
        let beforeAfterImagesDesktop = [config.getImageUrl('zoomDesktop', content.mediaBeforePath), config.getImageUrl('zoomDesktop', content.mediaAfterPath) ];

        // Skip incomplete element
        if (!content.mediaBeforePath || !content.mediaAfterPath) {
            return null;
        }

        return (
            <Container className="story-element before-after-element pt-3 sm-pt-3">
                {content.string ? <Header as="h2">{content.string}</Header> : '' }
                {content.text ? <span className="storys-description">{nl2br(content.text)}</span> : '' }
                {/* {content.string || content.text ? <Divider hidden/> : '' } */}
                <Grid>
                    <Grid.Column width={8}>
                        <ImageWrapper path={content.mediaBeforePath}>
                            <ProgressiveImager src={config.getImageUrl('main', content.mediaBeforePath)} onClick={() => this.handleGallery(contentItem, 0)} alt={this.props.altbase+(content.string ? content.string.substring(0, 50)+' – ' : '')+'Bild: '+content.mediaBefore} />
                        </ImageWrapper>
                        <div className="before-text">Vorher</div>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <ImageWrapper path={content.mediaAfterPath}>
                            <ProgressiveImager src={config.getImageUrl('main', content.mediaAfterPath)} onClick={() => this.handleGallery(contentItem, 1)} alt={this.props.altbase+(content.string ? content.string.substring(0, 50)+' – ' : '')+'Bild: '+content.mediaAfter} />
                        </ImageWrapper>
                        <div className="after-text">Nachher</div>
                    </Grid.Column>
                </Grid>
                {/* Include Gallery */}
                <Responsive {...Responsive.onlyMobile}>
                    <Gallery
                       images={beforeAfterImagesMobile}
                       galleryOpen={this.state.storyGallery === contentItem}
                       onClose={() => this.setState({storyGallery: false, storySlide: 0})}
                       slide={this.state.storySlide}
                       onMovePrevRequest={() =>
                           this.setState({
                               storySlide: (this.state.storySlide + beforeAfterImagesMobile.length - 1) % beforeAfterImagesMobile.length,
                           })
                         }
                       onMoveNextRequest={() =>
                           this.setState({
                               storySlide: (this.state.storySlide + 1) % beforeAfterImagesMobile.length,
                           })
                       }
                    />
                </Responsive>

                <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                    <Gallery
                        images={beforeAfterImagesDesktop}
                        galleryOpen={this.state.storyGallery === contentItem}
                        onClose={() => this.setState({storyGallery: false, storySlide: 0})}
                        slide={this.state.storySlide}
                        onMovePrevRequest={() =>
                            this.setState({
                                storySlide: (this.state.storySlide + beforeAfterImagesMobile.length - 1) % beforeAfterImagesMobile.length,
                            })
                          }
                        onMoveNextRequest={() =>
                            this.setState({
                                storySlide: (this.state.storySlide + 1) % beforeAfterImagesMobile.length,
                            })
                        }
                    />
                </Responsive>
            </Container>
        )
    }

    galleryElement(){
        const {content, contentItem} = this.props;
        let imagesMobile = [];
        content.media.map((m, index) => {
            imagesMobile.push({ src: config.getImageUrl('zoom', m.path), description: m.description })
        });

        let images = [];
        content.media.map((m, index) => {
            images.push({ src: config.getImageUrl('zoomDesktop', m.path), description: m.description })
        });

        // Skip incomplete element
        if (!content.media || !content.media.length) {
            return null;
        }

        return (
                <Container className="story-element gallery-element pt-3 sm-pt-3">
                    {content.string ? <Header className="header-normal" as="h2">{content.string}</Header> : '' }
                    {content.text ? <span className="storys-description">{nl2br(content.text)}</span> : '' }

                        <div className="story-gallery-wrapper">

                            {/* One Image */}
                            {content.media.length === 1 &&
                                content.media.slice(0,1).map((m, index) => (
                                    <div className="top-image" onClick={() => this.handleGallery(contentItem, 0)}>
                                        <ProgressiveImager key={index} src={config.getImageUrl('main', m.path)} alt={this.props.altbase+(m.description ? m.description.substring(0, 50)+' – ' : (content.string ? content.string.substring(0, 50)+' – ' : ''))+'Bild: '+m.id} />
                                    </div>
                                ))
                            }

                            {/* Two Images... */}
                            {content.media.length === 2 &&
                                <div className="two-images-gallery">
                                {content.media.map((m, index) => (
                                    <div className="image" onClick={() => this.handleGallery(contentItem, index)}>
                                        <ProgressiveImager key={index} src={config.getImageUrl('main', m.path)} alt={this.props.altbase+(m.description ? m.description.substring(0, 50)+' – ' : (content.string ? content.string.substring(0, 50)+' – ' : ''))+'Bild: '+m.id} />
                                    </div>
                                ))}
                                </div>
                            }

                            {/* Three Images.. */}
                            {content.media.length === 3 &&
                                <div className="three-images-gallery">
                                    {content.media.slice(0,1).map((m, index) => (
                                        <div className="top-image" onClick={() => this.handleGallery(contentItem, 0)}>
                                            <ProgressiveImager key={index} src={config.getImageUrl('main', m.path)} alt={this.props.altbase+(m.description ? m.description.substring(0, 50)+' – ' : (content.string ? content.string.substring(0, 50)+' – ' : ''))+'Bild: '+m.id} />
                                        </div>
                                    ))}
                                    <div className="two-images">
                                    {content.media.slice(1,3).map((m, index) => (
                                        <div className="image" onClick={() => this.handleGallery(contentItem, index+1)}>
                                            <ProgressiveImager key={index} src={config.getImageUrl('main', m.path)} alt={this.props.altbase+(m.description ? m.description.substring(0, 50)+' – ' : (content.string ? content.string.substring(0, 50)+' – ' : ''))+'Bild: '+m.id} />
                                        </div>
                                    ))}
                                    </div>
                                </div>
                            }

                             {/* Four Images or more... */}
                             {content.media.length === 4 &&
                                <div className="four-images-gallery">
                                    <div className="top-images">
                                        {content.media.slice(0,2).map((m, index) => (
                                            <div className="image" onClick={() => this.handleGallery(contentItem, index)}>
                                                <ProgressiveImager key={index} src={config.getImageUrl('main', m.path)} alt={this.props.altbase+(m.description ? m.description.substring(0, 50)+' – ' : (content.string ? content.string.substring(0, 50)+' – ' : ''))+'Bild: '+m.id} />
                                            </div>
                                        ))}
                                    </div>
                                    <div className="two-images">
                                        {content.media.slice(2,4).map((m, index) => (
                                            <div className="image" onClick={() => this.handleGallery(contentItem, index+2)}>
                                                <ProgressiveImager key={index} src={config.getImageUrl('main', m.path)} alt={this.props.altbase+(m.description ? m.description.substring(0, 50)+' – ' : (content.string ? content.string.substring(0, 50)+' – ' : ''))+'Bild: '+m.id} />
                                            </div>
                                        ))}
                                    </div>

                                </div>
                            }

                            {content.media.length === 5 &&
                                <div className="five-images-gallery">
                                    <div className="top-images">
                                        {content.media.slice(0,2).map((m, index) => (
                                            <div className="image" onClick={() => this.handleGallery(contentItem, index)}>
                                                <ProgressiveImager key={index} src={config.getImageUrl('main', m.path)} alt={this.props.altbase+(m.description ? m.description.substring(0, 50)+' – ' : (content.string ? content.string.substring(0, 50)+' – ' : ''))+'Bild: '+m.id} />
                                            </div>
                                        ))}
                                        </div>
                                    <div className="four-images">
                                        {content.media.slice(2,5).map((m, index) => (
                                            <div className="image" onClick={() => this.handleGallery(contentItem, index+2)}>
                                                <ProgressiveImager key={index} src={config.getImageUrl('main', m.path)} alt={this.props.altbase+(m.description ? m.description.substring(0, 50)+' – ' : (content.string ? content.string.substring(0, 50)+' – ' : ''))+'Bild: '+m.id} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            }

                            {content.media.length >= 6 &&
                            <>
                               { content.media.slice(0,1).map((m, index) => (
                                    <div className="top-image" onClick={() => this.handleGallery(contentItem, 0)}>
                                        <ProgressiveImager key={index} src={config.getImageUrl('main', m.path)} alt={this.props.altbase+(m.description ? m.description.substring(0, 50)+' – ' : (content.string ? content.string.substring(0, 50)+' – ' : ''))+'Bild: '+m.id} />
                                    </div>
                                ))}
                                <div className="images-wrapper">
                                    <div className="middle-images">

                                    {content.media.slice(1,4).map((m, index) => (
                                        <div className="image" key={index} onClick={() => this.handleGallery(contentItem, index+1)}>
                                            <ProgressiveImager src={config.getImageUrl('main', m.path)} alt={this.props.altbase+(m.description ? m.description.substring(0, 50)+' – ' : (content.string ? content.string.substring(0, 50)+' – ' : ''))+'Bild: '+m.id} />
                                        </div>
                                    ))}

                                    </div>

                                    <div className="bottom-images">

                                        {content.media.slice(4,5).map((m, index) => (
                                            <div className="image" key={index} onClick={() => this.handleGallery(contentItem, index+4)}>
                                                <ProgressiveImager src={config.getImageUrl('main', m.path)} alt={this.props.altbase+(m.description ? m.description.substring(0, 50)+' – ' : (content.string ? content.string.substring(0, 50)+' – ' : ''))+'Bild: '+m.id} />
                                            </div>
                                        ))}

                                        {content.media.slice(5,6).map((m, index) => (
                                            <div className="last image" key={index} onClick={() => this.handleGallery(contentItem, (content.media.length > 6 ? 0 : index+5))}>
                                                <ProgressiveImager src={config.getImageUrl('main', m.path)} alt={this.props.altbase+(m.description ? m.description.substring(0, 50)+' – ' : (content.string ? content.string.substring(0, 50)+' – ' : ''))+'Bild: '+m.id} />
                                                {content.media.length > 6 &&
                                                    <div className="image-count-overlay">
                                                        <div className="image-count">+{content.media.length - 6}</div>
                                                    </div>
                                                }
                                            </div>
                                        ))}

                                    </div>
                                </div>
                            </>
                            }


                        </div>
                        {/* Include Gallery */}
                        <Responsive {...Responsive.onlyMobile}>
                            <Gallery
                                images={imagesMobile.map(img => img.src)}
                                description={imagesMobile.filter((img, index) => index === this.state.storySlide).map(m => m.description)}
                                galleryOpen={this.state.storyGallery === contentItem}
                                onClose={() => this.setState({storyGallery: false, storySlide: 0})}
                                slide={this.state.storySlide}
                                onMovePrevRequest={() =>
                                    this.setState({
                                        storySlide: (this.state.storySlide + images.length - 1) % images.length,
                                    })
                                  }
                                onMoveNextRequest={() =>
                                    this.setState({
                                        storySlide: (this.state.storySlide + 1) % images.length,
                                    })
                                }
                            />
                        </Responsive>

                        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                            <Gallery
                                images={images.map(img => img.src)}
                                description={images.filter((img, index) => index === this.state.storySlide).map(m => m.description)}
                                galleryOpen={this.state.storyGallery === contentItem}
                                onClose={() => this.setState({storyGallery: false, storySlide: 0})}
                                slide={this.state.storySlide}
                                onMovePrevRequest={() =>
                                    this.setState({
                                        storySlide: (this.state.storySlide + images.length - 1) % images.length,
                                    })
                                  }
                                onMoveNextRequest={() =>
                                    this.setState({
                                        storySlide: (this.state.storySlide + 1) % images.length,
                                    })
                                }
                            />
                        </Responsive>
                    </Container>
        )
    }

    textImage() {
        const {content, contentItem} = this.props;
        let imageMobile = [];
        imageMobile.push(config.getImageUrl('zoom', content.mediaPath));
        let image = [];
        image.push(config.getImageUrl('zoomDesktop', content.mediaPath));

        // Skip incomplete element
        if (!content.mediaPath || !content.text) {
            return null;
        }

        return (
            <Container className={`story-element text-image-element pt-3 sm-pt-3 ${content.mediaorientation === config.ORIENTATION_LEFT ? 'image-left' : 'image-right'}`}>
                {/* {content.string || content.text ? <Divider hidden/> : '' } */}

                <Responsive {...Responsive.onlyMobile}>
                    <div className={`text-image-wrapper ${content.string ? '' : 'no-headline'}`}>
                        <ImageWrapper path={content.mediaPath}>
                            <ProgressiveImager src={config.getImageUrl('main', content.mediaPath)} onClick={() => this.handleGallery(contentItem, 0)} alt={this.props.altbase+(content.string ? content.string.substring(0, 50)+' – ' : '')+'Bild: '+content.media} />
                        </ImageWrapper>
                        {content.string ? <Header as="h2">{content.string}</Header> : '' }
                        {content.text ? <span className="storys-description">{nl2br(content.text)}</span> : '' }
                    </div>
                </Responsive>

                <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                    {content.mediaorientation === config.ORIENTATION_LEFT ?
                        <Grid>
                            <Grid.Column mobile={16} tablet={8} computer={8} style={{paddingRight: 0}}>
                                <ImageWrapper path={content.mediaPath}>
                                    <ProgressiveImager src={config.getImageUrl('main', content.mediaPath)} onClick={() => this.handleGallery(contentItem, 0)} alt={this.props.altbase+(content.string ? content.string.substring(0, 50)+' – ' : '')+'Bild: '+content.media} />
                                </ImageWrapper>
                            </Grid.Column>
                            <Grid.Column mobile={16} tablet={8} computer={8} verticalAlign="middle" className="image-left-description">
                                {content.string ? <Header as="h2">{content.string}</Header> : '' }
                                {content.text ? <span className="storys-description">{nl2br(content.text)}</span> : '' }
                            </Grid.Column>
                        </Grid>
                    :
                        <Grid>
                            <Grid.Column mobile={16} tablet={8} computer={8} verticalAlign="middle" className="image-right-description">
                                {content.string ? <Header as="h2">{content.string}</Header> : '' }
                                {content.text ? <span className="storys-description">{nl2br(content.text)}</span> : '' }
                            </Grid.Column>
                            <Grid.Column mobile={16} tablet={8} computer={8} style={{paddingLeft: 0}}>
                                <ImageWrapper path={content.mediaPath}>
                                    <ProgressiveImager src={config.getImageUrl('main', content.mediaPath)} onClick={() => this.handleGallery(contentItem, 0)} alt={this.props.altbase+(content.string ? content.string.substring(0, 50)+' – ' : '')+'Bild: '+content.media} />
                                </ImageWrapper>
                            </Grid.Column>
                        </Grid>
                    }
                </Responsive>
                {/* Include Gallery */}
                <Responsive {...Responsive.onlyMobile}>
                    <Gallery
                        images={imageMobile}
                        galleryOpen={this.state.storyGallery === contentItem}
                        onClose={() => this.setState({ storyGallery: false, storySlide: 0 })}
                        slide={this.state.storySlide}
                        onMovePrevRequest={() =>
                            this.setState({
                                storySlide: (this.state.storySlide + imageMobile.length - 1) % imageMobile.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                storySlide: (this.state.storySlide + 1) % imageMobile.length,
                            })
                        }
                    />
                </Responsive>

                <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                    <Gallery
                        images={image}
                        galleryOpen={this.state.storyGallery === contentItem}
                        onClose={() => this.setState({ storyGallery: false, storySlide: 0 })}
                        slide={this.state.storySlide}
                        onMovePrevRequest={() =>
                            this.setState({
                                storySlide: (this.state.storySlide + image.length - 1) % image.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                storySlide: (this.state.storySlide + 1) % image.length,
                            })
                        }
                    />
                </Responsive>
            </Container>
        )
    }

    video(){
        const {content, contentItem} = this.props;
        let video = [];
        video.push({src: config.getDirectUrl(content.mediaPath)});

        // Skip incomplete element
        if (!content.mediaPath || !content.mediaPreviewPath) {
            return null;
        }

        return (
            <Container className="story-element video-element pt-3 sm-pt-3">
                {content.string ? <Header as="h2">{content.string}</Header> : '' }
                {content.text ? <span className="storys-description">{nl2br(content.text)}</span> : '' }
                {/* {content.string || content.text ? <Divider hidden/> : '' } */}
                <div className="video-preview" onClick={() => this.handleGallery(contentItem)}>
                    <ImageWrapper path={content.mediaPreviewPath}>
                        <ProgressiveImager src={config.getImageUrl('main', content.mediaPreviewPath)}  alt={this.props.altbase+(content.string ? content.string.substring(0, 50)+' – ' : '')+'Video: '+content.media} />
                    </ImageWrapper>
                    <Icon name="play circle outline" inverted/>
                </div>

                {/* Include Gallery */}
                <Gallery
                    images={video}
                    galleryOpen={this.state.storyGallery === contentItem}
                    onClose={() => this.setState({storyGallery: false, storySlide: 0})}
                    slide={this.state.storySlide}
                    isVideo={true}
                />
            </Container>
        )
    }

    image(){
        const {content, contentItem} = this.props;
        let imageMobile = [];
        imageMobile.push(config.getImageUrl('zoom', content.mediaPath));
        let image = [];
        image.push(config.getImageUrl('zoomDesktop', content.mediaPath));

        // Skip incomplete element
        if (!content.mediaPath) {
            return null;
        }

        return (
            <Container className="story-element image-element pt-3 sm-pt-3">
                {content.string ? <Header as="h2">{content.string}</Header> : '' }
                {content.text ? <span className="storys-description">{nl2br(content.text)}</span> : '' }
                {/* {content.string || content.text ? <Divider hidden/> : '' } */}
                <ImageWrapper path={content.mediaPath}>
                    <ProgressiveImager src={config.getImageUrl('main', content.mediaPath)} onClick={() => this.handleGallery(contentItem, 0)} alt={this.props.altbase+(content.string ? content.string.substring(0, 50)+' – ' : '')+'Bild: '+content.media} />
                </ImageWrapper>

                {/* Include Gallery */}
                <Responsive {...Responsive.onlyMobile}>
                    <Gallery
                        images={imageMobile}
                        galleryOpen={this.state.storyGallery === contentItem}
                        onClose={() => this.setState({ storyGallery: false, storySlide: 0 })}
                        slide={this.state.storySlide}
                        onMovePrevRequest={() =>
                            this.setState({
                                storySlide: (this.state.storySlide + imageMobile.length - 1) % imageMobile.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                storySlide: (this.state.storySlide + 1) % imageMobile.length,
                            })
                        }
                    />
                </Responsive>

                <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                    <Gallery
                        images={image}
                        galleryOpen={this.state.storyGallery === contentItem}
                        onClose={() => this.setState({ storyGallery: false, storySlide: 0 })}
                        slide={this.state.storySlide}
                        onMovePrevRequest={() =>
                            this.setState({
                                storySlide: (this.state.storySlide + image.length - 1) % image.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                storySlide: (this.state.storySlide + 1) % image.length,
                            })
                        }
                    />
                </Responsive>
            </Container>
        )
    }

    text(){
        const {content } = this.props;

        // Skip incomplete element
        // Skip if both empty
        if (!content.string && !content.text) {
            return null;
        }

        return (
            <Container className={`story-element text-element pt-3 sm-pt-3 ${content.string && !content.text ? 'only-headline' : ''} ${content.string ? '' : 'no-headline'}` }>
                {content.string ? <Header as="h2"><Truncate lines={3}>{content.string}</Truncate></Header> : '' }
                {content.text ?
                    <span className="storys-description">
                        {nl2br(content.text)}
                    </span> : ''
                }

            </Container>
        )
    }
}
