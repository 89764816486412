import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Container, Divider, Header, Label, Placeholder, Button } from 'semantic-ui-react'
import CustomFrontendNavigation from '../../components/layout/CustomFrontendNavigation';
import MetaTags from 'react-meta-tags';
import Comments from '../../components/Comments/Comments'
import Helmet from 'react-helmet';
import config from '../../config/config'
import MetaTag from 'react-meta-tags';
import { StructuredBlogData } from '../../components/StructuredData/StructuredData';
import {
    handleContainer,
    handleHeaderItems,
    setRuntime
} from "../../actions/global";
import { getSinglePostIfNeeded, getBlogTagsIfNeeded, postComment, getPostComments } from '../../actions/blog';
import HeaderImage from '../../components/HeaderImage/HeaderImage';
import HeaderImg from '../../images/funktionen-header.jpg';

var striptags = require('striptags');

class Singlepost extends Component {

    state = {
        commentInput: ''
    }

    componentDidMount = () => {
        const { dispatch, match, postItem } = this.props;
        //Fetch post
        dispatch(getSinglePostIfNeeded(match.params.postId));
        dispatch(getBlogTagsIfNeeded())
        //TODO: Fetch post comments
        //dispatch(getPostComments(match.params.postId));
        dispatch(handleHeaderItems("blog"));
        {/* TODO: Meta descriptions */}
        dispatch(
            handleContainer("functions", "SPAN#Beitrag", null, null, null, null, {
                title: postItem && postItem.title.rendered ? postItem.title.rendered : 'Blog'
             })
          );
        // Set page
        dispatch(setRuntime({
            page: 'blog'
        }));

    }

    componentDidUpdate = (prevProps) => {
        const { postItem, dispatch } = this.props;

        if(postItem && !postItem.title.rendered && prevProps.postItem.title.rendered !== postItem.title.rendered){
            dispatch(
                handleContainer("blog", "SPAN#Beitrag", null, null, null, null, { title: postItem.title.rendered })
              );
        }
    }

    componentWillUnmount = () => {
        const { dispatch } = this.props;
    
        dispatch(setRuntime({
            backBlog: {page: 'blog'},
        }));
    }

    handleInputChange = (e) => {
        this.setState({
            commentInput: e.target.value
        })
    }

    render() {

        const { postItem, tagItems, isFetching, match, item } = this.props;

        return (
            <>

                {/* Fetching data...*/}
                {/* ********************************** */}
                {isFetching && 
                <div className="post-fetching">
                    <HeaderImage>
                        <Placeholder>
                            <Placeholder.Image/>
                        </Placeholder>
                    </HeaderImage>
                    <div className="blog-post-wrapper">
                        <Container>
                            <article className="blog-post fetching">
                                <div className="blog-post-title">
                                    <Placeholder>
                                        <Placeholder.Header>
                                            <Placeholder.Line/>
                                        </Placeholder.Header>
                                    </Placeholder>
                                    <Divider/>
                                </div>
                                <div className="blog-post-content">
                                    <Placeholder>
                                        <Placeholder.Paragraph>
                                            <Placeholder.Line/>
                                            <Placeholder.Line/>
                                            <Placeholder.Line/>
                                            <Placeholder.Line/>
                                            <Placeholder.Line/>
                                            <Placeholder.Line/>
                                        </Placeholder.Paragraph>
                                    </Placeholder>
                                    <Divider hidden/>
                                    <Placeholder>
                                        <Placeholder.Paragraph>
                                            <Placeholder.Line/>
                                            <Placeholder.Line/>
                                            <Placeholder.Line/>
                                            <Placeholder.Line/>
                                            <Placeholder.Line/>
                                            <Placeholder.Line/>
                                        </Placeholder.Paragraph>
                                    </Placeholder>
                                    <Placeholder>
                                        <Placeholder.Image/>
                                    </Placeholder>
                                </div>
                            </article>
                        </Container>
                    </div>
                </div>
                }

                {postItem && !isFetching &&

                <>  

                    <Helmet>
                        <title>{postItem.title.rendered} – {config.TITLE_SUFFIX}</title>
                        <meta name="description" content={striptags(postItem.excerpt.rendered, 'p')} />
                    </Helmet>
               
                        <HeaderImage 
                            overlay
                            src={
                                postItem._embedded && postItem._embedded['wp:featuredmedia'] &&  postItem._embedded['wp:featuredmedia'][0] ? 
                                postItem._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url
                                : HeaderImg
                            }
                        >
                            <Container>
                                <div className="blog-post-tag">
                                    {tagItems && tagItems.map(item => (<>
                                        
                                            <Label 
                                                size="large" 
                                                key={item.id}
                                            >
                                                {item.name}
                                            </Label>
                                    </>))}
                                    </div>
                                <div className="blog-post-title">
                                    <Header itemProp="name headline" as="h1">{postItem.title.rendered}</Header>
                                </div>
                                <div className="blog-post-author">
                                    von Vetero
                                </div>
                            </Container>
                        </HeaderImage>                                     
                                                                            
                    
                    <CustomFrontendNavigation/> {/* TODO - error? */}
                    <div className="blog-post-wrapper">
                        <Container>
                            <article className="blog-post" itemProp="blogPost" itemScope itemType="https://schema.org/BlogPosting">
                                <script type="application/ld+json">
                                    {StructuredBlogData(postItem)}
                                </script>
                                {/* Schema.org meta info - TODO*/}
                                <meta itemProp="mainEntityOfPage" content={`https://vetero.de/${`oldtimer-blog/${postItem.slug}/${postItem.id}`}`}/>
                                <meta itemProp="image" content=""/>
                                <meta itemProp="dateModified" content={postItem.modified}/>
                                            
                                <meta itemProp="author" content="Vetero" />
                                <span itemProp="publisher" itemScope itemType="https://schema.org/Organization">
                                    <meta itemProp="name" content="Vetero Oldtimer Projekte"/>
                                    <span itemProp="logo" itemScope itemType="https://schema.org/ImageObject">
                                        <meta itemProp="url" content="https://www.vetero.de/static/media/Logo-Vetero-weiss-quer.45fca5ec.svg"/>
                                        <meta itemProp="height" content="400"/>
                                    </span>
                                </span>
                 
                                <div 
                                    className="blog-post-content"
                                    dangerouslySetInnerHTML={{__html: postItem.content.rendered}}
                                />
                            </article>
                        </Container>
                    </div>
                </>
                }
           
                {/*<div className="blog-comments-container">
                    <Container>
                        <Header as="h2">Kommentare</Header>
                        <Divider/>
                        <Comments type="blog" parent={"ext_wordpress::" + match.params.postId} />
                    </Container>
                </div>*/}
                
            </>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { blog, globalByComponent, account } = state;
    const { post, tag, comments } = blog || {
        post: {},
        tag: {},
        comments: {}
    };

    const { item } = account || {
        item: {},
      };

    const { page, backBlog } = globalByComponent[
        'Runtimedata'
    ] || {}

    let isFetching = post && post.isFetching

      let postItem;
      if(post && post.items){
        postItem = Object.values(post.items).filter(item => item.id === parseInt(ownProps.match.params.postId))[0]
      }

      let tagItems = [];
      if(postItem && tag && !tag.isFetching && tag.items){
        postItem.tags.map(item => {
            tag.items[item] && tagItems.push(tag.items[item])
        })
      }

      let commentItems = [];
      if(postItem && comments && !comments.isFetching && comments.items){
        commentItems = Object.values(comments.items).filter(item => item.post === parseInt(ownProps.match.params.postId))
      }

      return {
        postItem,
        tagItems,
        page,
        backBlog,
        isFetching,
        item,
        commentItems
      };
  }

export default connect(mapStateToProps)(withRouter(Singlepost))
