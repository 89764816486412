import React, { Component } from 'react';
import {
    Route, Switch
} from "react-router-dom";
import { Loader } from 'semantic-ui-react';
import Assembly from './assembly';
import BeProject from './be-project';
import Expenditure from './expenditure';
import PartsCost from './partsCosts';
import Settings from './settings';
import EditStory from './stories/EditStory';
import NewStory from './stories/NewStory';
import Storys from './stories/storys';
import Todo from './todo';


class ProjectRouter extends Component {

    render(){

        return (
            <Switch>
                <Route exact path="/backend/project/:id/assemblies" component={Assembly}/>
                <Route exact path="/backend/project/:id/todos" component={Todo}/>
                <Route exact path="/backend/project/:id/settings" component={Settings}/>
                <Route exact path="/backend/project/:id/workinghours" component={Expenditure}/>
                <Route exact path="/backend/project/:id/expenses/" component={PartsCost} />
                <Route exact path="/backend/project/:id/stories/" component={Storys}/>
                <Route exact path="/backend/project/:id/stories/new_story" component={NewStory}/>
                <Route exact path="/backend/project/:id/stories/:storyId/edit_story" component={EditStory}/>
                <Route exact path="/backend/project/:id" component={BeProject}/>
            </Switch>
        )
    }
}


export default ProjectRouter;
