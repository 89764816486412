import React, { Component } from 'react';
import { Comment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { CommentsTextConnected, CommentsMeta } from './Comments'
import ImageWrapper from '../../components/helpers/image-wrapper';
import ProgressiveImager from '../../components/helpers/progressive-imager';
import config from '../../config/config'

class Answers extends Component {
    render() {

        const { comment, index } = this.props;

        return (
            <Comment.Group>

                {comment.load &&
                    <Comment>
                        <span className="comment-show-all" onClick={() => { this.handlePrevious(comment.load.parent, comment.load.id) }}>Vorherige Kommentare anzeigen</span>
                    </Comment>
                }

                {!comment.load &&
                    <Comment>
                        <div class="avatar">
                            <Link to={`/user/${comment.userData.urlslug}/${comment.user}`}>
                                <ImageWrapper avatar={true} path={comment.userData.mediaavatarPath}>
                                    <ProgressiveImager placeholder="avatar" src={config.getImageUrl('avatar', comment.userData.mediaavatarPath)} />
                                </ImageWrapper>
                            </Link>
                        </div>
                        <Comment.Content>
                            <div className={"comment-inner-wrapper"}>
                                <Comment.Author key={comment.userData.name}>
                                    <Link to={`/user/${comment.userData.urlslug}/${comment.user}`}>
                                        {comment.userData.name}
                                    </Link>
                                </Comment.Author>
                                <CommentsTextConnected comment={comment} />

                            </div>
                            <CommentsMeta comment={comment} />

                        </Comment.Content>
                        {/*If comment has answer...*/}

                        {comment.answers &&
                            <Comment.Group>
                                {comment.answers.map((answer, answerindex) => (<>
                                    {answer.load &&
                                        <Comment key={index + '::' + answerindex}>
                                            <span className="comment-answer-show-all" onClick={() => { this.handlePrevious(answer.load.parent, answer.load.id) }}>Alle antworten anzeigen</span>
                                        </Comment>
                                    }

                                    {!answer.load &&
                                        <Comment>
                                            <div class="avatar">
                                                <Link to={`/user/${answer.userData.urlslug}/${answer.user}`}>
                                                    <ImageWrapper avatar path={answer.userData.mediaavatarPath}>
                                                        <ProgressiveImager placeholder="avatar" src={config.getImageUrl('avatar', answer.userData.mediaavatarPath)} />
                                                    </ImageWrapper>
                                                </Link>
                                            </div>
                                            <Comment.Content>
                                                <div className={"comment-answer-inner-wrapper"}>
                                                    <Comment.Author key={answer.userData.name}>
                                                        <Link to={`/user/${answer.userData.urlslug}/${answer.user}`}>
                                                            {answer.userData.name}
                                                        </Link>
                                                    </Comment.Author>
                                                    <CommentsTextConnected comment={answer} />
                                                </div>
                                                <CommentsMeta comment={answer} />

                                            </Comment.Content>
                                        </Comment>}

                                </>))}

                            </Comment.Group>
                        }
                    </Comment>
                }
            </Comment.Group>
        )
    }
}

export default Answers;