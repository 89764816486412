import React, { Component } from 'react';
import {
    Container,
    Menu,
    Responsive,
    Image,
    Dropdown
} from 'semantic-ui-react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Helmet from 'react-helmet'
import Account from '../../account/Account';
import { fetchUserNotificationsIfNeeded } from '../../../actions/account'
import LogoVetero from '../../../images/Logo-Vetero-weiss-quer.svg';
import loginIcon from '../../../images/icons/icon-login-white.svg';
import FooterMenu from '../Footer/footerMenu';
import { zeroRightClassName } from 'react-remove-scroll-bar';
import { handleHeaderItems, setActionAfterLogin, setCanonicalTag, setRuntime } from '../../../actions/global';
import './Header.scss';
//User placeholder image
import userAvatar from '../../../images/icon-avatar.svg';
import config from '../../../config/config'
import ProgressiveImager from '../../../components/helpers/progressive-imager';
import menuDotsIcon_2 from '../../../images/icons/icon-menu-dots-white.svg'
import GoBackBtn from '../goBackBtn';


class MainHeader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loginPopupVisible: false,
            menuHeight: 0,
            dropdownMenuOpen: false,
            clickedLoginButton: false,
            dashboardLogin: true
        }

        this.headerRef = React.createRef();

        this.setDimensions = this.setDimensions.bind(this);
    }

    componentDidMount = () => {
        const { dispatch, item, location, headerTitle } = this.props;

        this.setDimensions();
        window.addEventListener('resize', this.setDimensions);
        //document.getElementById('root').addEventListener('click', this.handleClickDropdown, false);

        if (item && item.id) {
            dispatch(fetchUserNotificationsIfNeeded());
        }

        dispatch(setRuntime({ currentLocation: window.location.pathname }));

        // Dirty iPad workaround
        setTimeout(this.setHeightOfHeader, 5000);


        //Set datalayer on url change
        if(config.GTM_ENABLED && window.dataLayer && location && headerTitle) {
            window.dataLayer.push({
                'PageTitle': headerTitle + " – Vetero Oldtimer Portal",
                'PageURL': window.location.href,
                'event': 'Pageview'
            });
        }
        
        dispatch(setCanonicalTag(config.BASE_LIVE_URL + location.pathname))
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { dispatch, currentLocation, item, history, location, headerTitle, ConsentBanner } = this.props;
        const { clickedLoginButton } = this.state;

        //if(item && item.id && clickedLoginButton) history.push('/backend')

        window.addEventListener('resize', this.setDimensions);

        if (currentLocation !== window.location.pathname) {
            dispatch(setRuntime({ currentLocation: window.location.pathname }));
        }

        //Update canonical tag on page change
        if(location.pathname !== prevProps.location.pathname){
            dispatch(setCanonicalTag(window.location.href))
        }

        //Set datalayer on url change
        if(window.dataLayer && location && (location.pathname !== prevProps.location.pathname)) {

            window.dataLayer.push({
                'PageTitle': headerTitle + " – Vetero Oldtimer Portal",
                'PageURL': window.location.href,
                'event': 'Pageview'
            });

            //Set GTM Event if cookies accepted
            if (config.GTM_ENABLED && ConsentBanner && ConsentBanner.consent) {
                window.dataLayer.push({'event': 'acceptAll'});
            }

        }

    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setDimensions);
        //document.getElementById('root').removeEventListener('click', this.handleClickDropdown, false);
    }

    handleLoginItemDashboard = (isOpen) => {
        const { dispatch } = this.props;

        this.setState({ loginPopupVisible: isOpen });
        dispatch(setActionAfterLogin("/backend")); //Redirect after login to user dashboard
    }

    setDimensions = () => {
        //Workaround - doesn't work without setTimeout
        // Set height of header
        let bodyHeight;
        let scope = this;
        setTimeout(function () {
            let menuHeight;
            if (document.querySelector('.header-menu')) {
                menuHeight = document.querySelector('.header-menu').offsetHeight;
                scope.setState({ menuHeight: menuHeight });
                bodyHeight = 'calc(100%' + " - " + menuHeight + "px)";
                // bodyHeight = document.body.offsetHeight + menuHeight;
                //document.body.style.height = bodyHeight;
            }

            // Set width of window
            scope.setState({ windowWidth: window.innerWidth });

        }, 0)
    }

    render() {
        const { item, children, type, notificationsSum, headermeta, canonicalTagUrl } = this.props

        return (
            <>

                {headermeta && headermeta.title &&
                    <Helmet htmlAttributes={{ "lang": "de" }}>
                        <title>{headermeta.title}{config.TITLE_SUFFIX}</title>
                        <link rel="canonical" href={canonicalTagUrl} />
                    </Helmet>
                }
                {(!headermeta || !headermeta.title) && this.props.headerTitle &&
                    <Helmet htmlAttributes={{ "lang": "de" }}>
                        <title>{this.props.headerTitle}{config.TITLE_SUFFIX}</title>
                        <link rel="canonical" href={canonicalTagUrl} />
                    </Helmet>
                }
                {headermeta && headermeta.description &&
                    <Helmet htmlAttributes={{ "lang": "de" }}>
                        <meta name="description" content={headermeta.description && headermeta.description.length >= 160 && headermeta.desctype !== 'fixed' ? headermeta.description.substring(0, 157) + "..." : headermeta.description} />
                        <link rel="canonical" href={canonicalTagUrl} />
                    </Helmet>
                }

           
                <Account
                    loginPopupVisible={this.state.loginPopupVisible}
                    loginPopupClose={() => this.handleLoginItemDashboard(false)}
                    loginDashboard
                />


                {/*Mobile view*/}

                <Responsive as="header" {...Responsive.onlyMobile}>
                    {type === "secondary" || type === "functions" ?
                        <Menu
                            as="nav"
                            pointing
                            ref={this.headerRef}
                            secondary
                            size='mini'
                            color="red"
                            style={{ width: "100%" }}
                            className="main-header main-header-secondary header-menu menu-mobile"
                            dimmed={this.state.sidebarIsOpen}
                            widths={3}
                        >


                            <GoBackBtn mobile className="sidebar-item"/>


                            <Menu.Item className="top-header-title" as={this.props.headerlink ? Link : 'span'} to={this.props.headerlink}>
                                {this.props.headlineTag === 'span' ?
                                    <span className="h1">{this.props.headerTitle && this.props.headerTitle.length >= 25 ? this.props.headerTitle.substring(0, 22) + "..." : this.props.headerTitle}</span>
                                :
                                    <h1>{this.props.headerTitle && this.props.headerTitle.length >= 25 ? this.props.headerTitle.substring(0, 22) + "..." : this.props.headerTitle}</h1>
                                }
                            </Menu.Item>
                            <Menu.Item position="right" />

                        </Menu>

                        :
                        <Menu
                            pointing
                            secondary
                            size='mini'
                            color="red"
                            style={{ width: "100%" }}
                            className="main-header header-menu menu-mobile"
                            widths={3}
                        >

                            <Menu.Item position="left" as={Link} to="/" className="logo">
                                <Image width="100px" height="24px" src={LogoVetero} alt="Vetero" title="vetero.de" />
                            </Menu.Item>
                            <Menu.Item className="top-header-title">
                                {this.props.headlineTag === 'span' ?
                                    <span className="h1">{this.props.headerTitle && this.props.headerTitle.length >= 25 ? this.props.headerTitle.substring(0, 22) + "..." : this.props.headerTitle}</span>
                                :
                                    <h1>{this.props.headerTitle && this.props.headerTitle.length >= 25 ? this.props.headerTitle.substring(0, 22) + "..." : this.props.headerTitle}</h1>
                                }
                            </Menu.Item>
                            <Menu.Item position="right" />

                        </Menu>


                    }

                </Responsive>

                {/* Tablet */}

                <Responsive as='header' {...Responsive.onlyTablet}>
                    <Menu
                        as="nav"
                        secondary
                        ref={this.headerRef}
                        className={zeroRightClassName + " main-header header-menu"}
                    >
                        <Menu.Item position="left" as={Link} to="/" className="logo">
                            <Image width="132px" height="30px" src={LogoVetero} alt="Vetero" title="vetero.de" />
                        </Menu.Item>
                        <Menu.Item className="menu-item" as={Link} to="/newsfeed" onClick={() => this.props.dispatch(handleHeaderItems("newsfeed"))} active={this.props.headerActiveItem === "newsfeed"}>
                            <span className="text-uppercase">Newsfeed</span>
                        </Menu.Item>
                        <Menu.Item className="menu-item" as={Link} to="/oldtimer" onClick={() => this.props.dispatch(handleHeaderItems("projects"))} active={this.props.headerActiveItem === "projects"}>
                            <span className="text-uppercase">Oldtimer / Projekte</span>
                        </Menu.Item>
                        <Menu.Item className="menu-item" as={Link} to="/about" onClick={() => this.props.dispatch(handleHeaderItems("about"))} active={this.props.headerActiveItem === "about"}><span className="text-uppercase">Über Vetero</span></Menu.Item>
                        <Menu.Item
                            onClick={() => this.setState({ dropdownMenuOpen: !this.state.dropdownMenuOpen })}
                            className="dropdown-item"
                            active={this.props.headerActiveItem === "dots-menu"}
                        >
                            <Image src={menuDotsIcon_2} alt="Icon More Menu"/>

                            <div className="header-dropdown-wrapper" ref={node => this.node = node}>
                                {this.state.dropdownMenuOpen &&
                                    <Responsive minWidth={Responsive.onlyTablet.minWidth} className="header-dropdown">
                                        <Menu vertical fluid className="header-dropdown-menu">
                                            <Menu.Item className="header-dropdown-menu-item" as={Link} to="/functions"><span>Funktionen</span></Menu.Item>
                                            <Menu.Item className="header-dropdown-menu-item" as={Link} to="/forum"><span>Forum</span></Menu.Item> 
                                            {/* <Menu.Item className="header-dropdown-menu-item" as={Link} to="/professional"><span>Spezialisten</span></Menu.Item>   */}
                                            <Menu.Item className="header-dropdown-menu-item" as={Link} to="/oldtimer-blog"><span>Oldtimer-Blog</span></Menu.Item>
                                            <Menu.Item className="header-dropdown-menu-item" as={Link} to="/partner"><span>Partner</span></Menu.Item>
                                            <Menu.Item className="header-dropdown-menu-item" as={Link} to="/updates"><span>Updates</span></Menu.Item>
                                            <Menu.Item className="header-dropdown-menu-item" as={Link} to="/impressum"><span>Impressum</span></Menu.Item>
                                            <Menu.Item className="header-dropdown-menu-item" as={Link} to="/privacy"><span>Datenschutz</span></Menu.Item>
                                            <Menu.Item className="header-dropdown-menu-item" as={Link} to="/conditions"><span>AGB</span></Menu.Item>
                                        </Menu>
                                    </Responsive>
                                }
                            </div>

                        </Menu.Item>

                        {!(item && item.id) ?
                            /* User not logged in */
                            <Menu.Item position="right" className="top-header-user" onClick={() => this.handleLoginItemDashboard(true)}>
                                <div className="login-item">
                                    <Image alt="Login Icon" title="Einloggen" src={loginIcon} className="top-header-icon" />
                                </div>
                            </Menu.Item>
                            : //Or
                            /* User logged in */
                            <Menu.Item position="right" className="top-header-user" as={Link} to="/backend" onClick={() => this.props.dispatch(handleHeaderItems("dashboard"))} active={this.props.headerActiveItem === "dashboard"}>
                                {notificationsSum > 0 ?
                                    <div className="notifications">
                                        <div className="notifications-amount">{notificationsSum}</div>
                                    </div>
                                    :
                                    ''
                                }
                                <div className="user-image"
                                    style={notificationsSum > 0 ? { marginLeft: 0 } : null}
                                >
                                    <ProgressiveImager
                                        placeholder="avatar"
                                        src={item.mediaavatarPath ? config.getImageUrl('avatar', item.mediaavatarPath) : userAvatar}
                                        circular
                                        bordered
                                        className="user-img"
                                    />
                                </div>
                            </Menu.Item>
                        }

                    </Menu>

                    {/* Secondary nav */}
                    {this.props.type === "secondary" &&
                        <Menu
                            secondary
                            className="sub-menu sub-menu-one-item"
                            style={{ paddingTop: this.state.menuHeight }}
                            widths={3}
                        >
                            <Container>
                                <Menu.Item position="left">
                                    <div className="sub-menu-buttons">
                                        <GoBackBtn />
                                    </div>
                                </Menu.Item>
                                <Menu.Item className="top-header-title" as={this.props.headerlink ? Link : 'span'} to={this.props.headerlink}>
                                    {this.props.headlineTag === 'span' ?
                                        <span className="h1">{this.props.headerTitle}</span>
                                    :
                                        <h1>{this.props.headerTitle}</h1>
                                    }
                                </Menu.Item>
                                <Menu.Item position="right"></Menu.Item>
                            </Container>
                        </Menu>
                    }
                    {/* Functions nav*/}
                    {this.props.type === "functions" && null}

                </Responsive>

                {/*Desktop view*/}

                <Responsive as='header' minWidth={Responsive.onlyComputer.minWidth}>
                    <Menu
                        as="nav"
                        secondary
                        ref={this.headerRef}
                        className={zeroRightClassName + " main-header header-menu"}
                    >
                        <Container>
                            <Menu.Item position="left" as={Link} to="/" className="logo">
                                <Image src={LogoVetero} width="132px" height="30px" alt="Vetero" title="vetero.de" />
                            </Menu.Item>
                            <Menu.Item className="menu-item" as={Link} to="/newsfeed" onClick={() => this.props.dispatch(handleHeaderItems("newsfeed"))} active={this.props.headerActiveItem === "newsfeed"}>
                                <span className="text-uppercase">Newsfeed</span>
                            </Menu.Item>
                            <Menu.Item className="menu-item" as={Link} to="/oldtimer" onClick={() => this.props.dispatch(handleHeaderItems("projects"))} active={this.props.headerActiveItem === "projects"}>
                                <span className="text-uppercase">Oldtimer / Projekte</span>
                            </Menu.Item>
                            <Menu.Item className="menu-item" as={Link} to="/about" onClick={() => this.props.dispatch(handleHeaderItems("about"))} active={this.props.headerActiveItem === "about"}><span className="text-uppercase">Über Vetero</span></Menu.Item>
                            <Menu.Item className="menu-item" as={Link} to="/functions" onClick={() => this.props.dispatch(handleHeaderItems("functions"))} active={this.props.headerActiveItem === "functions"}><span className="text-uppercase">Funktionen</span></Menu.Item>
                            {/* <Menu.Item className="menu-item" as={Link} to="/professionals" onClick={() => this.props.dispatch(handleHeaderItems("professional"))} active={this.props.headerActiveItem === "professional"}><span className="text-uppercase">Spezialisten</span></Menu.Item>  */}
                            <Menu.Item className="menu-item" as={Link} to="/forum" onClick={() => this.props.dispatch(handleHeaderItems("forum"))} active={this.props.headerActiveItem === "forum"}><span className="text-uppercase">Forum</span></Menu.Item>
                            <Menu.Item className="header-dropdown">
                                        <Dropdown onClick={(e) => e.preventDefault()} icon={<Image src={menuDotsIcon_2}/>}>
                                            <Dropdown.Menu>
                                                <Responsive {...Responsive.onlyTablet} as={Dropdown.Item}>
                                                    <Responsive {...Responsive.onlyTablet} as={Link} to={"/functions"}>Funktionen</Responsive>
                                                </Responsive>
                                                <Responsive {...Responsive.onlyTablet} as={Dropdown.Item}>
                                                    <Responsive {...Responsive.onlyTablet} as={Link} to={"/forum"}>Forum</Responsive>
                                                </Responsive>
                                                <Dropdown.Item>
                                                    <Link to={`/oldtimer-blog`}>Oldtimer-Blog</Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                    <Link to={`/partner`}>Partner</Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                    <Link to={`/updates`}>Updates</Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                    <Link to={`/impressum`}>Impressum</Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                    <Link to={`/privacy`}>Datenschutz</Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                    <Link to={`/conditions`}>AGB</Link>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Menu.Item>


                            {!(item && item.id) ?
                                /* User not logged in */
                                <Menu.Item position="right" className="top-header-user" onClick={() => this.handleLoginItemDashboard(true, true)}>
                                    <div className="login-item">
                                        <Image alt="Login Icon" title="Einloggen" src={loginIcon} className="top-header-icon" />
                                    </div>
                                </Menu.Item>
                                : //Or
                                /* User logged in */
                                <Menu.Item position="right" className="top-header-user" as={Link} to="/backend" onClick={() => this.props.dispatch(handleHeaderItems("dashboard"))} active={this.props.headerActiveItem === "dashboard"}>
                                    {notificationsSum > 0 ?
                                        <div className="notifications">
                                            <div className="notifications-amount">{notificationsSum}</div>
                                        </div>
                                        :
                                        ''
                                    }
                                    <div className="user-image"
                                        style={notificationsSum > 0 ? { marginLeft: 0 } : null}
                                    >
                                        <ProgressiveImager
                                            placeholder="avatar"
                                            src={item.mediaavatarPath ? config.getImageUrl('avatar', item.mediaavatarPath) : userAvatar}
                                            circular
                                            bordered
                                            className="user-img"
                                        />
                                    </div>
                                </Menu.Item>
                            }
                        </Container>
                    </Menu>

                    {/* Secondary nav */}
                    {this.props.type === "secondary" &&
                        <Menu
                            secondary
                            className="sub-menu sub-menu-one-item"
                            widths={3}
                        >
                            <Container style={{ paddingTop: this.state.menuHeight }}>
                                <Menu.Item position="left">
                                    <div className="sub-menu-buttons">
                                        <GoBackBtn />
                                        {/* <Icon name="angle left" size="large"/> */}
                                    </div>
                                </Menu.Item>
                                <Menu.Item className="top-header-title" as={this.props.headerlink ? Link : 'span'} to={this.props.headerlink}>
                                    {this.props.headlineTag === 'span' ?
                                        <span className="h1">{this.props.headerTitle}</span>
                                    :
                                        <h1>{this.props.headerTitle}</h1>
                                    }
                                </Menu.Item>
                                <Menu.Item position="right"></Menu.Item>
                            </Container>
                        </Menu>
                    }

                    {/* Functions nav*/}
                    {this.props.type === "functions" && null}

                </Responsive>

                {/* App content*/}
                <main style={{ paddingTop: this.props.type === "secondary" && config.getDeviceType() !== 'mobile' ? null : this.state.menuHeight }}>
                    {children}
                </main>

                <Responsive as={FooterMenu} {...Responsive.onlyMobile}/>
            </>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { globalByComponent, account } = state
    const { type, headerTitle, headerActiveItem, headerlink, headermeta, headlineTag } = globalByComponent[
        'Container'
    ] || {
            type: null,
            headerTitle: null
        }
    const { currentLocation } = globalByComponent[
        'Runtimedata'
    ] || {}
    const { isFetching, isFailed, isDirty, lastUpdated, lastFetch, item, token, notifications } = account || {
        item: {},
        notifications: {}
    }

    const { canonicalTagUrl } = globalByComponent[
        'CanonicalTag'
    ] || {
        canonicalTagUrl: ''
    }

    //Notifications sum
    let notificationsSum;
    let likes, messages, followers, comments;
    if (notifications) {
        if (notifications.count) {
            messages = notifications.count.countMessages ? parseInt(notifications.count.countMessages) : 0;
            comments = notifications.count.countComments && parseInt(notifications.count.countComments);
            followers = notifications.count.countFollowers && parseInt(notifications.count.countFollowers);
            likes = notifications.count.countLikes && parseInt(notifications.count.countLikes)
            notificationsSum = messages + comments + followers + likes;
        }
    }

    return {
        type,
        headerTitle,
        headerActiveItem,
        item,
        isFetching,
        isFailed,
        isDirty,
        lastUpdated,
        lastFetch,
        token,
        notificationsSum,
        headerlink,
        headermeta,
        headlineTag,
        currentLocation,
        ConsentBanner: globalByComponent['ConsentBanner'],
        canonicalTagUrl
    }
}

export default connect(mapStateToProps)(withRouter(MainHeader))
