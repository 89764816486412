import {
    API_CONVERSATION_REQUEST,
    API_CONVERSATION_SUCCESS,
    API_CONVERSATION_FAILURE,
    API_POST_MESSAGE_REQUEST,
    API_POST_MESSAGE_SUCCESS,
    API_POST_MESSAGE_FAILURE,
    NEW_MESSAGE,
    API_CONVERSATION_LIST_SUCCESS,
    CHANGE_PRIVATE_MESSAGE,
    API_UPDATE_MESSAGE_REQUEST,
    API_UPDATE_MESSAGE_SUCCESS,
    API_UPDATE_MESSAGE_FAILURE,
} from '../actions/conversation'
import {
    RESET_STORE,
} from '../actions/account'
// import {actions} from 'ix-redux-file-upload';


export default function conversationById(state = {}, action) {

    try {

        if (action.type === RESET_STORE) {
            return {};
        }

        if (
            action.type === CHANGE_PRIVATE_MESSAGE ||
            action.type === API_UPDATE_MESSAGE_REQUEST ||
            action.type === API_UPDATE_MESSAGE_SUCCESS ||
            action.type === API_UPDATE_MESSAGE_FAILURE
        ) {
            // Find ID
            for (var [parent, data] of Object.entries(state)) {
                for (var [id, message] of Object.entries(data.messages)) {
                    if (message.id === action.meta.id) {
                        // Found

                        var changes = {};

                        if (action.type === CHANGE_PRIVATE_MESSAGE) {
                            changes = {
                                inputText: action.payload.inputText,
                            };
                        }

                        else if (action.type === API_UPDATE_MESSAGE_REQUEST) {
                            changes = {
                                isUpdating: true,
                                updatingMessage: '',
                            };
                        }

                        else if (action.type === API_UPDATE_MESSAGE_SUCCESS) {
                            changes = {
                                ...action.payload.conversation_message,
                                isUpdating: false,
                                inputText: null,
                                updatingMessage: 'SUCCESS',
                            };
                        }

                        else if (action.type === API_UPDATE_MESSAGE_FAILURE) {
                            changes = {
                                isUpdating: false,
                                updatingMessage: 'FAILURE',
                            };
                        }

                        return {
                            ...state,
                            [parent]: {
                                ...state[parent],
                                messages: {
                                    ...state[parent]['messages'],
                                    [message.id]: {
                                        ...state[parent]['messages'][message.id],
                                        ...changes,
                                    }
                                }
                            }
                        };

                    }
                }
            }
        }

        if (action.type === NEW_MESSAGE) {
            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    inputText: action.payload.inputText,
                }
            };
        }

        else if (action.type === API_CONVERSATION_REQUEST) {
            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    isFetching: true,
                    lastFetch: Date.now(),
                }
            };
        }
        else if (
            action.type === API_CONVERSATION_SUCCESS ||
            action.type === API_POST_MESSAGE_SUCCESS
        ) {

            var pl = (action.payload.basemessage ? action.payload.basemessage : (action.payload.loadmessage ? action.payload.loadmessage : action.payload.messagedata));

            if (!pl && action.payload.message) {
                // Fake structure for single message
                pl = {
                    'Level1': {
                        [action.payload.message.id]: action.payload.message,
                    },
                };
            }

            // Conversation ID
            if (action.meta.id) {
                var id = action.meta.id;
            }
            else if (action.payload.membership) {
                var id = action.payload.membership.conversation;
            }
            else {
                return state;
            }

            if (pl) {
                for (var [kconv, vconv] of Object.entries(pl)) {
                    for (var [kmsg, vmsg] of Object.entries(vconv)) {

                        // Process message
                        if (!state[id] || !state[id]['messages']) {
                            state = {
                                ...state,
                                [id]: {
                                    ...(state[id] ? state[id] : {}),
                                    messages: {},
                                }
                            }
                        }

                        state = {
                            ...state,
                            [id]: {
                                ...state[id],
                                isFetching: false,
                                isFailed: false,
                                messages: {
                                    ...state[id]['messages'],
                                    ...{
                                        [(vmsg.load ? vmsg.load.id : vmsg.id)]: {
                                            ...state[id]['messages'][(vmsg.load ? vmsg.load.id : vmsg.id)],
                                            ...vmsg,
                                        },
                                    },
                                },
                                lastUpdated: Date.now(),
                            }
                        };
                    }
                }
            }

            if (action.payload.membership) {
                state = {
                    ...state,
                    [id]: {
                        ...state[id],
                        membership: action.payload.membership,
                    }
                };
            }

            state = {
                ...state,
                [id]: {
                    ...state[id],
                    isFetching: false,
                    isFailed: false,
                    lastUpdated: Date.now(),
                }
            };

            if (action.type === API_POST_MESSAGE_SUCCESS) {
                state = {
                    ...state,
                    [id]: {
                        ...state[id],
                        isPosting: false,
                        inputText: '',
                    }
                };
            }

            return state;
        }
        else if (action.type === API_CONVERSATION_FAILURE) {
            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    isFetching: false,
                    isFailed: true,
                    lastUpdated: Date.now(),
                }
            };
        }

        else if (action.type === API_POST_MESSAGE_REQUEST) {
            // Conversation ID
            if (action.meta.id) {
                var id = action.meta.id;
            }
            else {
                return state;
            }

            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    isPosting: true,
                }
            };
        }
        else if (action.type === API_POST_MESSAGE_FAILURE) {
            // Conversation ID
            if (action.meta.id) {
                var id = action.meta.id;
            }
            else {
                return state;
            }

            return {
                ...state,
                [action.meta.id]: {
                    ...state[action.meta.id],
                    isPosting: false,
                }
            };
        }

        else if (action.type === API_CONVERSATION_LIST_SUCCESS) {

            for (var [kconv, vconv] of Object.entries(action.payload.conversations)) {
                state = {
                    ...state,
                    [vconv.membership.conversation]: {
                        ...state[vconv.membership.conversation],
                        membership: vconv.membership,
                        messages: vconv.messages,
                    }
                };
            }

            return state;
        }

        return state;

    } catch (e) {

        console.log(e);
        return {
            ...state,
            fatalError: true,
        };

    }
}
