import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Container, Divider, Message, Responsive, Header, Grid, Placeholder} from 'semantic-ui-react';
import Slider from "react-slick";

import { fetchNewProjectListIfNeeded } from '../../actions/project';
import ListitemProject from '../project/list/listitem-project';
import config from '../../config/config';


class DashboardModuleNew extends Component {

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(fetchNewProjectListIfNeeded());
    }

    render() {
        const { newProjects, isFetching, isFailed } = this.props;

        if (!newProjects.length && isFetching) {
            return (
                <div>
                    <Divider className="custom-divider"/>
                    {this.placeholder()}
                </div>
            );
        }

        if (isFailed) {
            return (
                <Container text>
                    <Divider className="custom-divider"/>
                    <Message negative>Beim Abrufen der Daten ist ein Fehler aufgetreten.</Message>
                </Container>
            )
        }

        return (
            <div className="dashboard-projects-slideshow">
                <Divider className="custom-divider"/>
                <Responsive {...Responsive.onlyMobile}>
                    <Container>
                        <Header as='h2' >Neue Projekte</Header>
                        <Divider hidden />
                    </Container>
                </Responsive>

                <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                    <Header as='h2'>Neue Projekte</Header>
                    <Divider hidden />
                </Responsive>

                <Slider {...config.SLIDE_DB_SETTINGS}>
                    {newProjects.map((item, index) => (
                        <div key={index}>
                            <ListitemProject
                                projectId={item.item.id}
                                projectTitle={item.item.title}
                                userImg={item.item.userData.mediaavatarPath}
                                projectMainImage={item.item.mediamainPath}
                                projectSlug={item.item.urlslug}
                                linktype={item.item.linktype}
                            />
                        </div>
                        ))}
                </Slider>
            </div>
        );
    }

    placeholder(){
        return (
            <>
                <div className="dashboard-slider-placeholder">
                    <Container>
                        <Header as='h2' className="text-placeholder" >Neue Projekte</Header>
                        <Divider hidden />
                        <Grid columns="equal">

                            <Grid.Column>
                                <Placeholder className="slides-placeholder">
                                    <Placeholder.Image />
                                </Placeholder>
                            </Grid.Column>

                            <Grid.Column>
                                <Placeholder className="slides-placeholder">
                                    <Placeholder.Image />
                                    </Placeholder>
                            </Grid.Column>

                            <Grid.Column>
                                <Placeholder className="slides-placeholder">
                                    <Placeholder.Image />
                                    </Placeholder>
                            </Grid.Column>

                        </Grid>

                    </Container>
                </div>
            </>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { listByEntity,  projectById} = state;

    const { list: newProjectsList, isFetching, isFailed } = listByEntity['newProjectsList'] || {};

    let newProjects = [];

    //New Projects
    if(newProjectsList) {
        for (let [key, value] of Object.entries(newProjectsList)) {
            newProjects.push(projectById[value]);
        }
    }

    return {
        newProjects,
        isFetching,
        isFailed,
    }
}

export default connect(mapStateToProps)(DashboardModuleNew);
