import { FileUpload } from 'ix-redux-file-upload';
import * as moment from "moment";
import 'moment-timezone';
import 'moment/locale/de';
import Swipeout from 'rc-swipeout';
import 'rc-swipeout/assets/index.css';
import React from 'react';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import Select from 'react-select';
import Truncate from 'react-truncate';
import {
    Button, Container, Dimmer, Divider, Dropdown, Form, Grid, Header, Icon, Image, Input, Loader, Message, Placeholder, Responsive, Segment, Tab } from 'semantic-ui-react';
import { autosuggestion, resetAutosuggestion } from '../../../actions/autosuggestion';
import fetchBillListIfNeeded, { changeBill, deleteBill, discardBill, newBill, postBill, updateBill } from '../../../actions/bill';
import fetchExpenseListIfNeeded, { changeExpense, deleteExpense, discardExpense, newExpense, postExpense, updateExpense } from '../../../actions/expense';
import { handleContainer, setRuntime } from '../../../actions/global';
import fetchPartListIfNeeded, { deletePart, discardPart, newPart, postPart, updatePart } from '../../../actions/part';
import fetchProjectIfNeeded from '../../../actions/project';
import ImageWrapper from '../../../components/helpers/image-wrapper';
import ProgressiveImager from '../../../components/helpers/progressive-imager';
import config from '../../../config/config';
import menuDotsIcon_2 from '../../../images/icons/footer-menu-dots_2.svg';
import billIcon from '../../../images/icons/icon-bill.svg';
import documentIcon from '../../../images/icons/icon-document.svg';
import pictureIcon from '../../../images/icons/icon-footer-gallery.svg';
import purchasedIcon from '../../../images/icons/icon-purchased.svg';
import trashIcon from '../../../images/icons/icon-swipe-trash.svg';
import CustomPopup from '../../../components/CustomPopup/CustomPopup';
import CustomSlidingPane from '../../../components/CustomSlidingPane/CustomSlidingPane';
import Gallery from "../../../components/Gallery/Gallery";
import ProjectNav from '../../../components/layout/ProjectNav';
import PlusButton from '../../../components/PlusButton/PlusButton';
import BeParent from './be-parent';
import PreviewGallery from './../../../components/PreviewGallery/PreviewGallery';
import { Link } from 'react-router-dom';


class PartsCosts extends BeParent {

    inputFieldsParts = ['name', 'description', 'media'];
    inputFieldsBills = ['billdate', 'mercant', 'title', 'media'];
    inputFieldsExpenses = ['title', 'description', 'part', 'bill', 'price', 'mercant', 'media', 'projectPart'];

    constructor(props) {
        super(props)

        this.state = {
            activeIndex: 0,
            galleryOpen: false,
            currentSlide: 0,
            date: '',
            item: {
                isOpen: false,
                title: '',
                content: '',
                itemId: null,
            },
            popup: {
                isOpen: false,
                title: '',
                content: '',
                itemId: '',
                mediaId: ''
            },
            validation: {
                error: false,
                message: 'Das Feld muss ausgefüllt werden.'
            },
            createEnable: false,
            confirmModal: {
                isOpen: false
            },
            closeAfterSave: false,
            formValidation: {
                validationName: {
                    error: false,
                    message: ''
                },
                validationCost: {
                    error: false,
                    message: ''
                }
            },
            expandedBill: null,
            windowWidth: 0,
        }

        this.handleDate = this.handleDate.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.handleInputAc = this.handleInputAc.bind(this);
        this.handleInputSelect = this.handleInputSelect.bind(this);
        this.handleCloseSlidingPane = this.handleCloseSlidingPane.bind(this);
        this.submit = this.submit.bind(this);
        this.discard = this.discard.bind(this);
        this.showButton = this.showButton.bind(this);

        // Reset
        this.props.dispatch(resetAutosuggestion('mercant'));
    }

    showButton() {
        const { parts, bills, expenses } = this.props;
        const { item: currentItem } = this.state;

        if (currentItem.content == 'new-part' && parts.inputTitle || parts.inputDesc || parts.inputMedia) {
            return true;
        }

        if (currentItem.itemId && currentItem.content == 'edit-part' && config.isChanged(parts.items.filter((item) => item.id === currentItem.itemId)[0], this.inputFieldsParts)) {
            return true;
        }

        if (currentItem.content == 'new-bill' && bills.inputTitle || bills.inputMercant || bills.inputBilldate || bills.inputMedia) {
            return true;
        }

        if (currentItem.itemId && currentItem.content == 'edit-bill' && config.isChanged(bills.items.filter((item) => item.id === currentItem.itemId)[0], this.inputFieldsBills)) {
            return true;
        }

        if (currentItem.content == 'new-expenses' && expenses.inputTitle || expenses.inputDesc || expenses.inputPrice || expenses.inputBill || expenses.inputPart || expenses.inputMercant || expenses.inputMedia || expenses.inputProjectpart) {
            return true;
        }

        if (currentItem.itemId && currentItem.content == 'edit-expenses' && config.isChanged(expenses.items.filter((item) => item.id === currentItem.itemId)[0], this.inputFieldsExpenses)) {
            return true;
        }

        return false;
    }

    discard() {
        const { dispatch, item } = this.props;
        const { item: currentItem } = this.state;

        // Reset
        this.props.dispatch(resetAutosuggestion('mercant'));

        // Discard changes
        if (currentItem.content == "new-part") {
            dispatch(newPart(item.id, 'inputTitle', null));
            dispatch(newPart(item.id, 'inputDesc', null));
            dispatch(newPart(item.id, 'inputMedia', null));
        }
        else if (currentItem.content == "edit-part") {
            dispatch(discardPart((currentItem.itemId ? currentItem.itemId : null), item.id));
        }
        else if (currentItem.content == "new-expenses") {
            dispatch(newExpense(item.id, 'inputTitle', null));
            dispatch(newExpense(item.id, 'inputDesc', null));
            dispatch(newExpense(item.id, 'inputPrice', null));
            dispatch(newExpense(item.id, 'inputBill', null));
            dispatch(newExpense(item.id, 'inputPart', null));
            dispatch(newExpense(item.id, 'inputMercant', null));
            dispatch(newExpense(item.id, 'inputMedia', null));
            dispatch(newExpense(item.id, 'inputProjectpart', null));
        }
        else if (currentItem.content == "edit-expenses") {
            dispatch(discardExpense((currentItem.itemId ? currentItem.itemId : null), item.id));
        }
        else if (currentItem.content == "new-bill") {
            dispatch(newBill(item.id, 'inputTitle', null));
            dispatch(newBill(item.id, 'inputMercant', null));
            dispatch(newBill(item.id, 'inputBilldate', null));
            dispatch(newBill(item.id, 'inputMedia', null));
        }
        else if (currentItem.content == "edit-bill") {
            dispatch(discardBill((currentItem.itemId ? currentItem.itemId : null), item.id));
        }

        // Reset validation
        this.setState({
            formValidation: {
                validationName: {
                    error: false,
                    message: ''
                },
                validationCost: {
                    error: false,
                    message: ''
                }
            }
        });

        // Close popup/pane
        this.setState({
            item: {
                isOpen: false,
            },
            confirmModal: {
                isOpen: false,
            },
        });
    }

    handleDate = (event, {name, value}) => {
        const { dispatch, match } = this.props;

        name = name.split('.');
        var entity = name[0];
        var field = name[1];

        if (entity === 'bill') {
            dispatch(newBill(match.params.id, field, moment(value, "YYYY-MM-DD").format('X')));
        }
    }

    handleInput(e, element) {
        const { dispatch, match } = this.props;
        const { item, isOpen } = this.state;
        var name = element.name.split('.');
        var entity = name[0];
        var field = name[1];

        if (!item.isOpen) {
            element.value = ""
        }

        if (entity === 'part') {
            dispatch(newPart(match.params.id, field, element.value));
        }
        else if (entity === 'bill') {
            dispatch(newBill(match.params.id, field, element.value));
        }
        else if (entity === 'expense') {
            dispatch(newExpense(match.params.id, field, element.value));
        }
    }

    handleInputAc(e) {
        const element = e.currentTarget;
        var name = element.name.split('.');
        var key = name[name.length - 1];

        if (key === 'inputMercant') {
            key = 'mercant';
        }

        this.props.dispatch(autosuggestion(key, element.value));
        this.handleInput(e, element);
    }

    handleInputSelect(opt, element) {
        const { dispatch, match } = this.props;
        var name = element.name.split('.');
        var entity = name[0];
        var field = name[1];

        if (entity === 'expense') {
            dispatch(newExpense(match.params.id, field, opt.value));
        }
    }

    handleTabChange = (e, { activeIndex }) => this.setState({ activeIndex })

    submit(e, element) {
        const { dispatch, match, parts, bills, expenses } = this.props;
        const { item: currentItem } = this.state;

        if (parts.isPosting || bills.isPosting || expenses.isPosting) {
            return false;
        }

        // Reset
        this.props.dispatch(resetAutosuggestion('mercant'));

        if (currentItem.content == 'new-part') {

            this.setState({
                formValidation: {
                    ...this.state.formValidation,
                    validationName: config.validateTextField(parts.inputTitle, true)
                }
            })

            !config.validateTextField(parts.inputTitle, true).error && dispatch(postPart(match.params.id, parts.inputTitle, parts.inputDesc, parts.inputMedia));

        }
        else if (currentItem.itemId && currentItem.content == 'edit-part') {
            var part = parts.items.filter((item) => item.id === currentItem.itemId)[0];

            this.setState({
                formValidation: {
                    ...this.state.formValidation,
                    validationName: config.validateTextField(part.input.name, true)
                }
            })

            if (config.isChanged(part, this.inputFieldsParts)) {

                if(!config.validateTextField(part.input.name, true).error) {
                    dispatch(updatePart(part.input));
                    this.setState({closeAfterSave: true});
               }
               else {
                    return false
                }

            }
            else {
                this.setState({
                    item: {
                        isOpen: false,
                    },
                })
            }

        }
        else if (currentItem.content == 'new-bill') {

            this.setState({
                formValidation: {
                    ...this.state.formValidation,
                    validationName: config.validateTextField(bills.inputTitle, true)
                }
            })

            !config.validateTextField(bills.inputTitle, true).error && dispatch(postBill(match.params.id, bills.inputTitle, bills.inputMercant, bills.inputBilldate, bills.inputMedia));

        }
        else if (currentItem.itemId && currentItem.content == 'edit-bill') {
            var bill = bills.items.filter((item) => item.id === currentItem.itemId)[0];

            this.setState({
                formValidation: {
                    ...this.state.formValidation,
                    validationName: config.validateTextField(bill.input.title, true)
                }
            })

            if (config.isChanged(bill, this.inputFieldsBills)) {
                // TODO: Validation
                if(!config.validateTextField(bill.input.title, true).error){
                    dispatch(updateBill(bill.input));
                    this.setState({closeAfterSave: true});
                }
                else {
                    return false
                }
            }
            else {
                this.setState({
                    item: {
                        isOpen: false,
                    },
                })
            }
        }
        else if (currentItem.content == 'new-expenses') {

            this.setState({
                formValidation: {
                    ...this.state.formValidation,
                    validationName: config.validateTextField(expenses.inputTitle, true),
                    validationCost: config.validationPointNumbers(expenses.inputPrice, true)
                }
            })

            if (!config.validateTextField(expenses.inputTitle, true).error && !config.validationPointNumbers(expenses.inputPrice, true).error) {
                //Convert to float number
                expenses.inputPrice = config.convertToFloat(expenses.inputPrice);
                dispatch(postExpense(match.params.id, expenses.inputTitle, expenses.inputDesc, expenses.inputPrice, expenses.inputBill, expenses.inputPart, expenses.inputMercant, expenses.inputMedia, expenses.inputProjectpart));
            }
        }
        else if (currentItem.itemId && currentItem.content == 'edit-expenses') {
            var expense = expenses.items.filter((item) => item.id === currentItem.itemId)[0];

            this.setState({
                formValidation: {
                    ...this.state.formValidation,
                    validationName: config.validateTextField(expense.input.title, true),
                    validationCost: config.validationPointNumbers(expense.input.price, true)
                }
            })

            if (config.isChanged(expense, this.inputFieldsExpenses)) {
                // TODO: Validation
                if(!config.validateTextField(expense.input.title, true).error && !config.validationPointNumbers(expense.input.price, true).error){
                    //Convert to float number
                    expense.input.price = config.convertToFloat(expense.input.price);
                    // expense.input.price = config.convertToFloat(expense.input.price);
                    dispatch(updateExpense(expense.input));
                    this.setState({closeAfterSave: true});
                }
                else {
                    return false
                }
            }
            else {
                this.setState({
                    item: {
                        isOpen: false,
                    },
                })
            }
        }

    }

    componentDidMount() {
        const { dispatch, match, item, location } = this.props;
        const { activeIndex } = this.state;

        dispatch(fetchProjectIfNeeded(match.params.id));
        dispatch(fetchPartListIfNeeded(match.params.id));
        dispatch(fetchBillListIfNeeded(match.params.id));
        dispatch(fetchExpenseListIfNeeded(match.params.id));
        dispatch(handleContainer("secondary", item.title, false, "dashboard"));
        dispatch(setRuntime({page: 'be-partscosts'}));

        this.setDimensions()
        window.addEventListener('resize', this.setDimensions);

        if(location.hash === '#parts') {
            this.setState({ activeIndex: 0 })
        }
        else if(location.hash === '#expenses') {
            this.setState({ activeIndex: 1 })
        }
        else if(location.hash === '#bills'){
            this.setState({ activeIndex: 2 })
        }
        else {
            this.setState({ activeIndex: 0 })
        }

    }

    componentDidUpdate(prevProps, prevState) {
        const { parts, bills, expenses } = this.props;
        const { activeIndex } = this.state;

        //Handle tab menu
        if(!prevState.activeIndex !== activeIndex) {
            activeIndex === 0 && (window.location.hash = 'parts')
            activeIndex === 1 && (window.location.hash = 'expenses')
            activeIndex === 2 && (window.location.hash = 'bills')
        }

        if (
            (prevProps.parts.isPosting !== parts.isPosting && !parts.isPosting) ||
            (prevProps.bills.isPosting !== bills.isPosting && !bills.isPosting) ||
            (prevProps.expenses.isPosting !== expenses.isPosting && !expenses.isPosting)
        ) {
            this.handleItems(false);
        }

        if (this.state.closeAfterSave && !parts.isFetching && !bills.isFetching && !expenses.isFetching) {
            this.handleItems(false);
            this.setState({closeAfterSave: false});
        }

        if ((prevProps.parts.isDeleting || prevProps.bills.isDeleting || prevProps.expenses.isDeleting) && !parts.isDeleting && !bills.isDeleting && !expenses.isDeleting) {
            this.setState({popup: {isOpen: false}});
        }
    }

    handleItems = (isOpen, title, content, id) => {
        this.setState({
            item: {
                isOpen: isOpen,
                title: title,
                content: content,
                itemId: id
            }
        })
    }


    handleMediaDelete = (mediaid, entityid, entity) => {
        const { dispatch, match, bills, expenses } = this.props;

        if (entity === 'bill' && entityid === 'NEW') {
            bills.inputMedia.map((mv, mk) => {
                if (mv.id === mediaid) {
                    bills.inputMedia.splice(mk, 1);
                }
            });
            dispatch(newBill(match.params.id, 'inputMedia', bills.inputMedia));
        }
        else if (entity === 'bill') {
            bills.items.filter(item => item.id === entityid).map(item => {
                item.input.media.map((mv, mk) => {
                    if (mv.id === mediaid) {
                        item.input.media.splice(mk, 1);
                    }
                });
                dispatch(changeBill(item));
            });
        }
        else if (entity === 'expense' && entityid === 'NEW') {
            expenses.inputMedia.map((mv, mk) => {
                if (mv.id === mediaid) {
                    expenses.inputMedia.splice(mk, 1);
                }
            });
            dispatch(newExpense(match.params.id, 'inputMedia', expenses.inputMedia));
        }
        else if (entity === 'expense') {
            expenses.items.filter(item => item.id === entityid).map(item => {
                item.input.media.map((mv, mk) => {
                    if (mv.id === mediaid) {
                        item.input.media.splice(mk, 1);
                    }
                });
                dispatch(changeExpense(item));
            });
        }
    }

    handlePopup = (isOpen, title, content, itemId, mediaId) => {
        this.setState(
            {
                popup: {
                    isOpen: true,
                    title: title,
                    content: content,
                    itemId: itemId,
                    mediaId: null
                }
            }
        )
    }

    handlePopupContent = () => {
        const { popup, content, itemId } = this.state;

        switch (popup.content) {
            case 'deletePart':
                return this.confirmDeletePart(popup.itemId);
            case 'deleteBill':
                return this.confirmDeleteBill(popup.itemId);
            case 'deleteCost':
                return this.confirmDeleteCost(popup.itemId);
            default:
                return null
        }
    }

    handleItemsContent = () => {
        switch (this.state.item.content) {
            case "new-part":
                return this.part(false)
            case "edit-part":
                return this.part(true, this.state.item.itemId)
            case "new-expenses":
                return this.expenses(false)
            case "edit-expenses":
                return this.expenses(true, this.state.item.itemId)
            case "new-bill":
                return this.bills(false)
            case "edit-bill":
                return this.bills(true, this.state.item.itemId)
            default:
                return null
        }
    }

    handleCloseSlidingPane = () => {
        const { dispatch, match, parts, bills, expenses } = this.props;
        const { item: currentItem } = this.state;

        if (currentItem.content == 'new-part') {
            if (parts.inputTitle || parts.inputDesc || parts.inputMedia) {
                this.setState({
                    confirmModal: {
                        isOpen: true
                    },
                })
            }
            else {
                this.setState({
                    item: {
                        isOpen: false,
                    },
                })
            }
        }
        else if (currentItem.itemId && currentItem.content == 'edit-part') {
            if (config.isChanged(parts.items.filter((item) => item.id === currentItem.itemId)[0], this.inputFieldsParts)) {
                this.setState({
                    confirmModal: {
                        isOpen: true,
                    },
                })
            }
            else {
                this.setState({
                    item: {
                        isOpen: false,
                    },
                })
            }
        }
        else if (currentItem.content == 'new-bill') {
            if (bills.inputTitle || bills.inputMercant || bills.inputBilldate || bills.inputMedia) {
                this.setState({
                    confirmModal: {
                        isOpen: true
                    },
                })
            }
            else {
                this.setState({
                    item: {
                        isOpen: false,
                    },
                })
            }
        }
        else if (currentItem.itemId && currentItem.content == 'edit-bill') {
            if (config.isChanged(bills.items.filter((item) => item.id === currentItem.itemId)[0], this.inputFieldsBills)) {
                this.setState({
                    confirmModal: {
                        isOpen: true,
                    },
                })
            }
            else {
                this.setState({
                    item: {
                        isOpen: false,
                    },
                })
            }
        }
        else if (currentItem.content == 'new-expenses') {
            if (expenses.inputTitle || expenses.inputDesc || expenses.inputPrice || expenses.inputBill || expenses.inputPart || expenses.inputMercant || expenses.inputMedia || expenses.inputProjectpart) {
                this.setState({
                    confirmModal: {
                        isOpen: true
                    },
                })
            }
            else {
                this.setState({
                    item: {
                        isOpen: false,
                    },
                })
            }
        }
        else if (currentItem.itemId && currentItem.content == 'edit-expenses') {
            if (config.isChanged(expenses.items.filter((item) => item.id === currentItem.itemId)[0], this.inputFieldsExpenses)) {
                this.setState({
                    confirmModal: {
                        isOpen: true,
                    },
                })
            }
            else {
                this.setState({
                    item: {
                        isOpen: false,
                    },
                })
            }
        }
    }

    handleDeleteItem = (id) => {
        const { parts, dispatch, match } = this.props;

        let updatedArray = [];

        parts.inputMedia.filter(item => item.id != id).map((item, i) => {
            updatedArray.push(item);
        })

        dispatch(newPart(match.params.id, "inputMedia", updatedArray));

    }

    handleDeleteEditedItem = (id, itemId) => {
        const { parts, dispatch, match } = this.props;

        let updatedArray = [];
        let partsArray = [];

        var current = parts.items.filter(filteredItem => filteredItem.id === itemId)[0];
        var newMedia = current.input.media.filter(media => media.id !== id);

        this.handleChange("part." + itemId + ".media", newMedia);

    }

    render() {
        const { dispatch, match, isDirty, isFetching, parts, bills, expenses } = this.props;
        const { activeIndex } = this.state;

        const openparts = parts.items.filter(item => item.done === false);

        //Content
        const panes = [
            {
                menuItem: 'Benötigte Teile', render: () =>
                    <div className="parts">
                        <Container>
                            <Grid columns="equal">
                                <Grid.Column floated="left" >
                                    <Header as="h2">Benötigte Teile</Header>
                                </Grid.Column>
                                <Grid.Column floated="right" textAlign="right">
                                    <PlusButton
                                        title="Neues Teil"
                                        color="red"
                                        onClick={() => this.handleItems(true, "Neues Teil", "new-part")}
                                    />
                                </Grid.Column>
                            </Grid>
                        </Container>
                        <Divider hidden />

                        {(!openparts || !Object.keys(openparts).length) && parts.isFetching ?
                            <>
                                <Segment className="part-cost-placeholder">
                                    <Placeholder>
                                        <Placeholder.Line length='full' />
                                        <Placeholder.Line length='very long' />
                                        <Placeholder.Line length='short' />
                                    </Placeholder>
                                </Segment>
                                <Segment className="part-cost-placeholder">
                                    <Placeholder>
                                        <Placeholder.Line length='full' />
                                        <Placeholder.Line length='very long' />
                                        <Placeholder.Line length='short' />
                                    </Placeholder>
                                </Segment>
                                <Segment className="part-cost-placeholder">
                                    <Placeholder>
                                        <Placeholder.Line length='full' />
                                        <Placeholder.Line length='very long' />
                                        <Placeholder.Line length='short' />
                                    </Placeholder>
                                </Segment>
                            </>
                            :
                            <>
                                {parts.isFailed ?
                                    <Container>
                                        <Message color="red">
                                            Es ist ein Fehler beim Abrufen der Daten aufgetreten.
                                        </Message>
                                    </Container>
                                    :
                                    <>
                                        {!openparts || !Object.keys(openparts).length ?
                                            <Container>
                                                <Message color="teal">
                                                    Dieses Projekt hat noch keine benötigten Teile.
                                                </Message>
                                            </Container>
                                            :
                                            <>
                                                {parts.isDeleting &&
                                                    <Dimmer className="deleting-loader" active>
                                                        <Loader />
                                                    </Dimmer>
                                                }
                                                {openparts.map(item => (
                                                    <>
                                                        <Responsive className="parts-item" maxWidth={Responsive.onlyTablet.maxWidth} style={{ marginBottom: "16px" }}>
                                                            <Swipeout
                                                                autoClose
                                                                right={[
                                                                    {
                                                                        text: <div
                                                                                onTouchEnd={(e) => { this.buyPart(item); }}
                                                                                style={{ textAlign: "center" }}
                                                                                className="swipeout-img-container"
                                                                              >
                                                                                  <div className="swipeout-img">
                                                                                        <Image src={purchasedIcon} style={{ height: "27px", margin: "0 auto 0.5rem" }} />
                                                                                        {item.description || item.media.length > 0 ? <span>Gekauft</span> : ''}
                                                                                  </div>
                                                                        </div>,
                                                                        style: {
                                                                            backgroundColor: "#00D83C",
                                                                            color: "white",
                                                                            textAlign: "center",
                                                                            fontWeight: "bold",
                                                                            width: !item.done ? "92px" : "0px",
                                                                            height: "100%",

                                                                        }
                                                                    },
                                                                    {
                                                                        text: <div
                                                                                onTouchEnd={(e) => { this.handlePopup(true, 'Teil löschen', 'deletePart', item.id)}}
                                                                                style={{ textAlign: "center" }}
                                                                                className="swipeout-img-container"
                                                                               >
                                                                                   <div className="swipeout-img">
                                                                                        <Image src={trashIcon} style={{ height: "27px", margin: "0 auto 0.5rem" }} />
                                                                                        {item.description || item.media.length > 0 ? <span>Löschen</span> : ''}
                                                                                   </div>
                                                                        </div>,
                                                                        style: {
                                                                            backgroundColor: "#E7344C",
                                                                            color: "white",
                                                                            textAlign: "center",
                                                                            fontWeight: "bold",
                                                                            width: "92px",
                                                                            height: "100%"
                                                                        }
                                                                    }
                                                                ]
                                                                }
                                                            >


                                                                <Segment className="parts-item" onClick={() => this.handleItems(true, "Benötigtes Teil bearbeiten", "edit-part", item.id)}>
                                                                    {/* <Label color="green">Gekauft </Label> */}
                                                                    <Grid>
                                                                        <Grid.Column width={12}>

                                                                            <Header as="h5">{item.name}</Header>
                                                                            {item.description != 0 &&
                                                                                <Truncate lines={2} ellipsis={<>...</>} className="description">
                                                                                    <span>{item.description}</span>
                                                                                </Truncate>
                                                                            }

                                                                            {/*item.done != 0 &&
                                                                                <div className="buyed-date">Gekauft am: <Moment unix format="DD.MM.YYYY">{item.donedate}</Moment></div>

                                                                            */}

                                                                        </Grid.Column>
                                                                        <Grid.Column textAlign="right" mobile={4} tablet={4} computer={3}>
                                                                            <div className="date">
                                                                                <Moment unix format="DD.MM.YYYY">{item.createDate}</Moment>
                                                                            </div>
                                                                        </Grid.Column>

                                                                        {this.mediaPreview(item.media)}

                                                                    </Grid>

                                                                </Segment>
                                                            </Swipeout>
                                                        </Responsive>

                                                        <Responsive className="parts-item" as={Segment} minWidth={Responsive.onlyComputer.minWidth} onClick={() => this.handleItems(true, "Benötigtes Teil bearbeiten", "edit-part", item.id)}>
                                                            {/* <Label color="green">Gekauft </Label> */}
                                                            <Grid>
                                                                <Grid.Column width={12}>

                                                                    <Header as="h5">{item.name}</Header>
                                                                    {item.description != 0 &&
                                                                        <Truncate lines={2} ellipsis={<>...</>} className="description">
                                                                            <span>{item.description}</span>
                                                                        </Truncate>
                                                                    }

                                                                    {item.done != 0 &&
                                                                        <div className="buyed-date">Gekauft am: <Moment unix format="DD.MM.YYYY">{item.donedate}</Moment></div>
                                                                    }

                                                                </Grid.Column>
                                                                <Grid.Column textAlign="right" mobile={4} tablet={4} computer={3}>
                                                                    <div className="date">
                                                                        <Moment unix format="DD.MM.YYYY">{item.createDate}</Moment>
                                                                    </div>
                                                                </Grid.Column>

                                                                <Grid.Column className="elements-dots-menu" only="computer" computer={1} textAlign="right">

                                                                    <div className="elements-options-menu" style={{ marginTop: "-0.25rem", lineHeight: 0 }}>
                                                                        <Dropdown icon={<Image className="elements-options-menu-dots" src={menuDotsIcon_2} />}>
                                                                            <Dropdown.Menu style={{left: 'auto', right: 0}}>

                                                                                <Dropdown.Item className="delete-story" onClick={() => this.handlePopup(true, 'Teil löschen', 'deletePart', item.id)} >
                                                                                    <Image className="elements-options-menu-icon" src={trashIcon} />
                                                                                    <span>Löschen</span>
                                                                                </Dropdown.Item>
                                                                                {!item.done &&

                                                                                    <Dropdown.Item className="purchase-part" onClick={() => { this.buyPart(item); }}>
                                                                                        <Image className="elements-options-menu-icon" src={purchasedIcon} />
                                                                                        <span>Gekauft</span>
                                                                                    </Dropdown.Item>

                                                                                }

                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </div>

                                                                </Grid.Column>

                                                                {this.mediaPreview(item.media)}

                                                            </Grid>

                                                        </Responsive>
                                                    </>
                                                ))}
                                                <Divider hidden />
                                            </>
                                        }
                                    </>
                                }
                            </>
                        }
                    </div>


            },

            {
                menuItem: 'Ausgaben', render: () =>
                    <div className="costs">
                        <Divider hidden />
                        <div className="total-costs">
                            <div className="total">{config.getFormattedCurrency(expenses.count)}</div>
                            <div className="eur">Euro</div>
                        </div>
                        <Divider hidden />
                        <Container>
                            <Grid>
                                <Grid.Column floated="left" width={6}>
                                    <Header as="h2">Ausgaben</Header>
                                </Grid.Column>
                                <Grid.Column floated="right" width={10} textAlign="right">
                                    <PlusButton
                                        title="Neue Ausgabe"
                                        color="red"
                                        onClick={() => this.handleItems(true, "Neue Ausgabe", "new-expenses")}
                                    />
                                </Grid.Column>
                            </Grid>
                        </Container>
                        <Divider hidden />

                        {(!expenses.items || !Object.keys(expenses.items).length) && expenses.isFetching ?
                            <>
                                <Segment className="part-cost-placeholder">
                                    <Placeholder>
                                        <Placeholder.Line length='full' />
                                        <Placeholder.Line length='very long' />
                                        <Placeholder.Line length='short' />
                                    </Placeholder>
                                </Segment>
                                <Segment className="part-cost-placeholder">
                                    <Placeholder>
                                        <Placeholder.Line length='full' />
                                        <Placeholder.Line length='very long' />
                                        <Placeholder.Line length='short' />
                                    </Placeholder>
                                </Segment>
                                <Segment className="part-cost-placeholder">
                                    <Placeholder>
                                        <Placeholder.Line length='full' />
                                        <Placeholder.Line length='very long' />
                                        <Placeholder.Line length='short' />
                                    </Placeholder>
                                </Segment>
                            </>
                            :
                            <>
                                {expenses.isFailed ?
                                    <Container>
                                        <Message color="red">
                                            Es ist ein Fehler beim Abrufen der Daten aufgetreten.
                                        </Message>
                                    </Container>
                                    :
                                    <>

                                        {expenses.isDeleting &&
                                            <Dimmer className="deleting-loader" active>
                                                <Loader />
                                            </Dimmer>
                                        }

                                        {!expenses.items || !Object.keys(expenses.items).length ?
                                            <Container>
                                                <Message color="teal">
                                                    Dieses Projekt hat noch keine Ausgaben.
                                                </Message>
                                            </Container>
                                            :
                                            <>
                                                {expenses.items.map(item => (
                                                    <div style={{ marginBottom: "1rem" }}>
                                                        <Swipeout
                                                            autoClose
                                                            right={[
                                                                {
                                                                    text: <div
                                                                            onClick={() => this.handlePopup(true, 'Ausgabe löschen', 'deleteCost', item.id)}
                                                                            style={{ textAlign: "center" }}
                                                                            className="swipeout-img-container"
                                                                          >
                                                                              <div className="swipeout-img">
                                                                                <Image src={trashIcon}/>
                                                                                <span>Löschen</span>
                                                                              </div>
                                                                    </div>,
                                                                    style: {
                                                                        backgroundColor: "#E7344C",
                                                                        color: "white",
                                                                        textAlign: "center",
                                                                        fontWeight: "bold",
                                                                        width: "92px",
                                                                        height: "100%"
                                                                    }
                                                                }
                                                            ]}
                                                        >
                                                            <Segment className="costs-item" onClick={() => this.handleItems(true, "Ausgabe bearbeiten", "edit-expenses", item.id)}>
                                                                <Grid columns={2}>
                                                                    <Grid.Row style={{ paddingBottom: "0.25rem" }}>

                                                                        <Grid.Column width={12}>
                                                                            <Header as="h5">{item.title}</Header>
                                                                            <div className="describtion grey">
                                                                                {item.bill &&
                                                                                    <>
                                                                                        {bills.items.filter(bill => bill.id === item.bill).map(bill => <>{bill.mercant}</>)}
                                                                                    </>
                                                                                }
                                                                                {!item.bill &&
                                                                                    <>
                                                                                        {item.mercant}
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                        </Grid.Column>

                                                                        <Grid.Column mobile={4} tablet={4} computer={3} textAlign="center">
                                                                            <Image src={billIcon} className={'bill-icon'+(item.bill ? ' has-bill' : '')} onClick={(e) => {
                                                                                if (item.bill) {
                                                                                    e.preventDefault();
                                                                                    e.stopPropagation();
                                                                                    this.handleItems(true, "Rechnung bearbeiten", "edit-bill", item.bill);
                                                                                }
                                                                            }} />
                                                                        </Grid.Column>

                                                                        {/* Desktop dropdown menu */}
                                                                        <Grid.Column className="elements-dots-menu" only="computer" computer={1} textAlign="right" style={{ paddingLeft: 0 }}>

                                                                            <div className="elements-options-menu" style={{ marginTop: "-0.25rem", lineHeight: 0 }}>
                                                                                <Dropdown icon={<Image className="elements-options-menu-dots" src={menuDotsIcon_2} />}>
                                                                                    <Dropdown.Menu style={{left: 'auto', right: 0}}>

                                                                                        <Dropdown.Item className="delete-story" onClick={() => this.handlePopup(true, 'Ausgabe löschen', 'deleteCost', item.id)} >
                                                                                            <Image className="elements-options-menu-icon" src={trashIcon} />
                                                                                            <span>Löschen</span>
                                                                                        </Dropdown.Item>

                                                                                    </Dropdown.Menu>
                                                                                </Dropdown>
                                                                            </div>

                                                                        </Grid.Column>

                                                                    </Grid.Row>
                                                                    <Grid.Row style={{ paddingTop: "0.25rem" }}>
                                                                        <Grid.Column mobile={11} tablet={12} computer={12}>
                                                                            <div className="describtion">{item.description}</div>
                                                                        </Grid.Column>
                                                                        <Grid.Column mobile={5} tablet={4} computer={3}>
                                                                            <div className="amount">
                                                                                {config.getFormattedCurrency(item.price)}
                                                                                <span><Icon name="euro sign" /></span>
                                                                            </div>
                                                                        </Grid.Column>
                                                                        {/* empty space */}
                                                                        <Grid.Column only="computer" width={1}/>
                                                                    </Grid.Row>

                                                                    {this.mediaPreview([...item.media, ...(this.getItem('part', item.part) ? this.getItem('part', item.part).media : [])])}

                                                                </Grid>
                                                            </Segment>
                                                        </Swipeout>
                                                    </div>
                                                ))}
                                            </>
                                        }
                                    </>
                                }
                            </>
                        }
                    </div>
            },

            {
                menuItem: 'Rechnungen', render: () =>

                    <section className="bills">
                        <Divider style={{ marginTop: "6px" }} hidden />
                        <Container>
                            <Message color="teal">
                                Hier kannst Du reale Rechnungen zur Dokumentation als PDF oder Bild hinterlegen. Du kannst in „Ausgaben“ beliebige Positionen einer Rechnung zuordnen.
                            </Message>
                            <Grid>
                                <Grid.Column floated="left" width={6}>
                                    <Header as="h2">Rechnungen</Header>
                                </Grid.Column>
                                <Grid.Column floated="right" width={10} textAlign="right">
                                    <PlusButton
                                        title="Neue Rechnung"
                                        color="red"
                                        onClick={() => this.handleItems(true, "Neue Rechnung", "new-bill")}
                                    />
                                </Grid.Column>
                            </Grid>
                        </Container>
                        <Divider hidden />

                        {(!bills.items || !Object.keys(bills.items).length) && bills.isFetching ?
                            <>
                                <Segment className="part-cost-placeholder">
                                    <Placeholder>
                                        <Placeholder.Line length='full' />
                                        <Placeholder.Line length='very long' />
                                        <Placeholder.Line length='short' />
                                    </Placeholder>
                                </Segment>
                                <Segment className="part-cost-placeholder">
                                    <Placeholder>
                                        <Placeholder.Line length='full' />
                                        <Placeholder.Line length='very long' />
                                        <Placeholder.Line length='short' />
                                    </Placeholder>
                                </Segment>
                                <Segment className="part-cost-placeholder">
                                    <Placeholder>
                                        <Placeholder.Line length='full' />
                                        <Placeholder.Line length='very long' />
                                        <Placeholder.Line length='short' />
                                    </Placeholder>
                                </Segment>
                            </>
                            :
                            <>
                                {bills.isFailed ?
                                    <Container>
                                        <Message color="red">
                                            Es ist ein Fehler beim Abrufen der Daten aufgetreten.
                                        </Message>
                                    </Container>
                                    :
                                    <>

                                        {bills.isDeleting &&

                                            <Dimmer className="deleting-loader" active>
                                                <Loader />
                                            </Dimmer>

                                        }

                                        {!bills.items || !Object.keys(bills.items).length ?
                                            <Container>
                                                <Message color="teal">
                                                    Dieses Projekt hat noch keine Rechnung.
                                                </Message>
                                            </Container>
                                            :
                                            <>
                                                {bills.items.map(item => (
                                                    <div style={{ marginBottom: "16px" }}>
                                                        <Swipeout
                                                            autoClose
                                                            right={[
                                                                {
                                                                    text: <div
                                                                            onClick={() => this.handlePopup(true, 'Rechnung löschen', 'deleteBill', item.id, item.media.id)}
                                                                            style={{ textAlign: "center" }}
                                                                            className="swipeout-img-container"
                                                                            >
                                                                                <div className="swipeout-img">
                                                                                    <Image src={trashIcon} />
                                                                                </div>
                                                                      </div>,
                                                                    style: {
                                                                        backgroundColor: "#E7344C",
                                                                        color: "white",
                                                                        textAlign: "center",
                                                                        fontWeight: "bold",
                                                                        width: "70px",
                                                                        height: "100%"
                                                                    }
                                                                }
                                                            ]}
                                                        >
                                                            <Segment onClick={() => this.handleItems(true, "Rechnung bearbeiten", "edit-bill", item.id)} className="bill-item">
                                                                <Grid columns={2}>
                                                                    <Grid.Row>
                                                                        <Grid.Column width={11}>

                                                                            <Header as="h5">{item.title}</Header>
                                                                            <div className="describtion grey">{item.mercant}</div>
                                                                            {item.billdate > 0 &&
                                                                                <div className="date"><Moment unix format="DD.MM.YY">{item.billdate}</Moment></div>
                                                                            }

                                                                        </Grid.Column>
                                                                        <Grid.Column style={{lineHeight: 1}} mobile={5} tablet={5} computer={4} className={"files-quantity" + (expenses.items.filter(exp => exp.bill === item.id).length ? ' has-items' : '')} textAlign="right" onClick={(e) => {
                                                                                if (expenses.items.filter(exp => exp.bill === item.id).length) {
                                                                                    e.stopPropagation();
                                                                                    this.setState({
                                                                                        expandedBill: (this.state.expandedBill === item.id ? null : item.id),
                                                                                    });
                                                                                }
                                                                            }}>

                                                                            <span className="quantity">{expenses.items.filter(exp => exp.bill === item.id).length}x</span>

                                                                            <Image src={billIcon} style={{ width: "28px", display: "inline-block" }} />

                                                                        </Grid.Column>
                                                                        <Grid.Column className="elements-dots-menu" only="computer" computer={1} textAlign="right" style={{ paddingLeft: 0 }}>

                                                                            <div className="elements-options-menu" style={{ marginTop: "-0.25rem", lineHeight: 0 }}>
                                                                                <Dropdown icon={<Image className="elements-options-menu-dots" src={menuDotsIcon_2} />}>
                                                                                    <Dropdown.Menu style={{left: 'auto', right: 0}}>

                                                                                        <Dropdown.Item className="delete-story" onClick={() => this.handlePopup(true, 'Rechnung löschen', 'deleteBill', item.id)} >
                                                                                            <Image className="elements-options-menu-icon" src={trashIcon} />
                                                                                            <span>Löschen</span>
                                                                                        </Dropdown.Item>

                                                                                    </Dropdown.Menu>
                                                                                </Dropdown>
                                                                            </div>

                                                                        </Grid.Column>

                                                                    </Grid.Row>
                                                                    {this.state.expandedBill === item.id &&
                                                                        <Grid.Row style={{paddingTop: 0}}>
                                                                            <Grid.Column width="16">
                                                                                {expenses.items.filter(exp => exp.bill === item.id).map(exp => (
                                                                                    <div
                                                                                        className="bill-expenses"
                                                                                        onClick={(e) => {
                                                                                            e.stopPropagation();
                                                                                            this.handleItems(true, "Ausgabe bearbeiten", "edit-expenses", exp.id);
                                                                                        }
                                                                                    }>
                                                                                        {exp.title} <span>{config.getFormattedCurrency(exp.price)} €</span>
                                                                                    </div>
                                                                                ))}
                                                                            </Grid.Column>
                                                                        </Grid.Row>
                                                                    }

                                                                    {this.mediaPreview(item.media)}

                                                                </Grid>
                                                            </Segment>
                                                        </Swipeout>
                                                    </div>
                                                ))}
                                            </>
                                        }
                                    </>
                                }
                            </>
                        }
                    </section>

            }
        ]
        //Output
        return (

            <>
                {this.confirmModal(this.discard)}

                <ProjectNav active="teileKosten" project={{ id: this.props.match.params.id }} />
                <Tab onTabChange={this.handleTabChange} activeIndex={activeIndex} className="custom-tab-menu" menu={{ pointing: true }} panes={panes} />

                <Responsive
                    as={CustomSlidingPane}
                    isOpen={this.state.item.isOpen}
                    title={this.state.item.title}
                    onRequestClose={this.handleCloseSlidingPane}
                    maxWidth={Responsive.onlyTablet.maxWidth}
                    saveButtonCallback={this.submit}
                    saveButton={this.showButton()}
                    isLoading={this.props.parts.isPosting || this.props.bills.isPosting || this.props.expenses.isPosting || this.props.parts.isFetching || this.props.bills.isFetching || this.props.expenses.isFetching}
                >
                    {this.handleItemsContent()}
                </Responsive>

                <Responsive
                    as={CustomPopup}
                    popupOpen={this.state.item.isOpen}
                    popupHeader={this.state.item.title}
                    onClose={this.handleCloseSlidingPane}
                    saveButtonCallback={this.submit}
                    saveButton={this.showButton()}
                    isLoading={this.props.parts.isPosting || this.props.bills.isPosting || this.props.expenses.isPosting || this.props.parts.isFetching || this.props.bills.isFetching || this.props.expenses.isFetching}
                    showCloseIcon
                    className="larger-popup"
                    minWidth={Responsive.onlyComputer.minWidth}
                >
                    {this.handleItemsContent()}
                </Responsive>

                {/* Confirm delete popup */}
                <CustomPopup
                    popupOpen={this.state.popup.isOpen}
                    popupHeader={this.state.popup.title}
                    onClose={() => this.setState({ popup: { isOpen: false } })}
                    showCloseIcon
                >
                    {this.handlePopupContent()}
                </CustomPopup>

            </>
        )
    }

    // SlidingPanes
    part(edit, id) {
        const { token, parts, match } = this.props;
        const { formValidation, galleryOpen, currentSlide } = this.state;

        if (edit) {
            return (
                <div>
                    {parts.items.filter(item => item.id === id).map(item => (
                        <>
                        <Container>
                            <Form>
                                <Form.Input
                                    label="Bezeichnung"
                                    value={item.input.name}
                                    required
                                    name={"part." + item.id + ".name"}
                                    onChange={this.handleChangeInput}
                                    error={formValidation.validationName.error}
                                />

                                {formValidation.validationName.error &&
                                    <Message color="red">
                                        {formValidation.validationName.message}
                                    </Message>
                                }

                                <Form.Input
                                    label="Beschreibung (optional)"
                                    value={item.input.description}
                                    name={"part." + item.id + ".description"}
                                    onChange={this.handleChangeInput}
                                />
                            </Form>
                            <Divider hidden />
                            {/* Images */}
                            <FileUpload
                                headers={{ 'Authorization': 'Bearer ' + token }}
                                allowedFileTypes={['jpg', 'jpeg']}
                                dropzoneId={"partsByProject." + match.params.id + ".part." + item.id + ".media"}
                                multiple={true}
                                url={config.API_HOST + config.API_UPLOAD}
                            >
                                <div style={{ marginTop: "2rem", textAlign: "center" }}>
                                    <Button color="red">Bilder hinzufügen</Button>
                                </div>
                            </FileUpload>
                            <Divider hidden />
                            {item.input.media &&
                                <PreviewGallery 
                                    deletable
                                    imagesPerRow={3}
                                    imagesOnly={item.input.media}
                                    itemId={item.input.id}
                                    requestDelete={this.handleDeleteEditedItem}
                                />
                            }

                             {/* Include lightbox */}
                            <Responsive {...Responsive.onlyMobile}>
                                <Gallery
                                    galleryOpen={galleryOpen === item.id}
                                    slide={currentSlide}
                                    onClose={() => this.setState({ galleryOpen: false })}
                                    images={item.input.media.map(img =>
                                        config.getImageUrl('zoom', img.path)
                                    )}
                                    onMovePrevRequest={() =>
                                        this.setState({
                                            currentSlide: (currentSlide + item.input.media.length - 1) % item.input.media.length,
                                        })
                                    }
                                    onMoveNextRequest={() =>
                                        this.setState({
                                            currentSlide: (currentSlide + 1) % item.input.media.length,
                                        })
                                    }
                                />
                            </Responsive>

                            <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                                <Gallery
                                    galleryOpen={galleryOpen === item.id}
                                    slide={currentSlide}
                                    onClose={() => this.setState({ galleryOpen: false })}
                                    images={item.input.media.map(img =>
                                        config.getImageUrl('zoomDesktop', img.path)
                                    )}
                                    onMovePrevRequest={() =>
                                        this.setState({
                                            currentSlide: (currentSlide + item.input.media.length - 1) % item.input.media.length,
                                        })
                                    }
                                    onMoveNextRequest={() =>
                                        this.setState({
                                            currentSlide: (currentSlide + 1) % item.input.media.length,
                                        })
                                    }
                                />
                            </Responsive>

                        </Container>
                        </>
                    ))}
                </div>
            )
        }
        else {
            return (
                <Container>
                    <Form>

                        <Form.Input
                            label="Bezeichnung"
                            value={parts.inputTitle}
                            name={"part.inputTitle"}
                            onChange={this.handleInput}
                            required
                            error={formValidation.validationName.error}
                        />

                        {formValidation.validationName.error &&
                            <Message color="red">
                                {formValidation.validationName.message}
                           </Message>
                        }

                        <Form.Input
                            label="Beschreibung (optional)"
                            value={parts.inputDesc}
                            name={"part.inputDesc"}
                            onChange={this.handleInput}
                        />
                    </Form>
                    <Divider hidden />
                    {/* Images */}
                    <FileUpload
                        headers={{ 'Authorization': 'Bearer ' + token }}
                        allowedFileTypes={['jpg', 'jpeg']}
                        dropzoneId={"partsByProject." + match.params.id + ".inputMedia"}
                        multiple={true}
                        url={config.API_HOST + config.API_UPLOAD}
                    >
                        <div style={{ marginTop: "2rem", textAlign: "center" }}>
                            <Button color="red">Bilder hinzufügen</Button>
                        </div>
                    </FileUpload>
                    <Divider hidden />

                        {parts.inputMedia &&
                            <PreviewGallery 
                                imagesPerRow={3}
                                imagesOnly={parts.inputMedia}
                                requestDelete={this.handleDeleteItem}
                                deletable
                            />
                        }

                </Container>
            )
        }
    }

    expenses(edit, id) {
        const { item: project, token, expenses, parts, bills, match } = this.props;
        const { formValidation, currentSlide, galleryOpen } = this.state;
        let mobileGallery = [];
        let gallery = [];
        let partsMobileGallery = [];
        let partsGallery = [];

        expenses.items.filter(item => item.id === id).map(item => {
            item.input.media.map((m, index) => mobileGallery.push({src: config.getImageUrl('zoom', m.path)}))
            item.input.media.map((m, index) => gallery.push({src: config.getImageUrl('zoomDesktop', m.path)}))
            //Parts gallery
            this.getItem('part', item.input.part) && this.getItem('part', item.input.part).media.map((m, index) => (partsMobileGallery.push({src: config.getImageUrl('zoom', m.path)})))
            this.getItem('part', item.input.part) && this.getItem('part', item.input.part).media.map((m, index) => (partsGallery.push({src: config.getImageUrl('zoomDesktop', m.path)})))
        });

        if (edit) {
            return (
                <div>
                    {expenses.items.filter(item => item.id === id).map(item => (
                        <Container>
                            <Form>
                                <Form.Input
                                    label="Bezeichnung"
                                    required
                                    value={item.input.title}
                                    name={"expense." + item.id + ".title"}
                                    onChange={this.handleChangeInput}
                                    error={formValidation.validationName.error}
                                />

                                {formValidation.validationName.error &&
                                    <Message color="red">
                                        {formValidation.validationName.message}
                                    </Message>
                                }

                                <Form.Input
                                    label="Beschreibung (optional)"
                                    value={item.input.description}
                                    name={"expense." + item.id + ".description"}
                                    onChange={this.handleChangeInput}
                                />
                                <div className="autosuggestion">
                                    <label>Gekauft bei (optional)</label>
                                    <input
                                        onChange={this.getItem('bill', item.input.bill) ? null : this.handleChangeInputAc}
                                        autoComplete="off"
                                        className={this.getItem('bill', item.input.bill) ? 'transparent-border' : ''}
                                        value={this.getItem('bill', item.input.bill) ? this.getItem('bill', item.input.bill).mercant : item.input.mercant}
                                        name={"expense." + item.id + ".mercant"}
                                    />

                                {item.input.mercant && this.props.acMercant && Object.values(this.props.acMercant).length > 0 &&
                                        <div className="autosuggestion-list show">
                                            <ul>
                                                {Object.values(this.props.acMercant).map((option, index) => (
                                                    <li onClick={() => {
                                                        this.handleChange("expense." + item.id + ".mercant", option);
                                                        this.props.dispatch(resetAutosuggestion('mercant'));
                                                    }} key={index}>{option}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    }
                                </div>
                                <Form.Field error={formValidation.validationCost.error}>
                                    <label>Kosten</label>
                                    <Input
                                        value={item.input.price || item.input.price === 0 ? config.convertToPointNumber(item.input.price) : '' }
                                        name={"expense." + item.id + ".price"}
                                        label={{ basic: true, content: '€' }}
                                        labelPosition='right'
                                        fluid
                                        type='text'
                                        style={{ width: "auto", maxWidth: '150px' }}
                                        onChange={this.handleChangeInput}

                                    />
                                </Form.Field>

                                {formValidation.validationCost.error &&
                                    <Message color="red">
                                        {formValidation.validationCost.message}
                                    </Message>
                                }

                                <Form.Field>
                                    <label>Rechnung zuweisen (Überschreibt "Gekauft bei")</label>
                                    <Select menuPlacement="auto"
                                        options={config.buildChoice(bills.items, 'title')}
                                        name={"expense." + item.id + ".bill"}
                                        onChange={this.handleChangeSelect}
                                        value={config.getDefault(config.buildChoice(bills.items, 'title'), item.input.bill)}
                                        classNamePrefix="custom-select"
                                        className="custom-select-wrapper"
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <label>Story zuweisen (optional)</label>
                                    <Select menuPlacement="auto"
                                        options={config.buildChoice(project.projectpartsData, 'title')}
                                        name={"expense."+item.id+".projectPart"}
                                        classNamePrefix="custom-select"
                                        className="custom-select-wrapper"
                                        onChange={this.handleChangeSelect}
                                        value={config.getDefault(config.buildChoice(project.projectpartsData, 'title'), item.input.projectPart)}
                                    />
                                </Form.Field>

                                <Divider hidden />
                                {/* Images */}
                                <FileUpload
                                    headers={{ 'Authorization': 'Bearer ' + token }}
                                    allowedFileTypes={['jpg', 'jpeg']}
                                    dropzoneId={"expensesByProject." + match.params.id + ".expense." + item.id + ".media"}
                                    multiple={true}
                                    url={config.API_HOST + config.API_UPLOAD}
                                >
                                    <div style={{ marginTop: "2rem", textAlign: "center" }}>
                                        <Button color="red">Bilder hinzufügen</Button>
                                    </div>
                                </FileUpload>
                                <Divider hidden />
                                {item.input.media &&
                                      
                                    <PreviewGallery 
                                        deletable
                                        imagesPerRow={3}
                                        imagesOnly={item.input.media}
                                        requestDelete={this.handleMediaDelete}
                                    />
                                
                                }

                                {this.getItem('part', item.input.part) && this.getItem('part', item.input.part).media.length > 0 &&

                                    <>
                                        <Divider />
                                        <Header as="h2">Übernommen von "Benötigte Teile"</Header>
                                        <div className="content-gallery-imgs">
                                            {this.getItem('part', item.input.part).media.map((m, index) => (

                                                    <ImageWrapper path={m.path} className="content-gallery-wrapper no-description">
                                                        <ProgressiveImager style={{cursor: 'default'}} src={config.getImageUrl('preview', m.path)} onClick={() => this.setState({ galleryOpen: 'parts-gallery', currentSlide: index})}/>
                                                    </ImageWrapper>

                                            ))}
                                        </div>

                                        {/* Item part gallery */}
                                        <Responsive {...Responsive.onlyMobile}>
                                            <Gallery
                                                galleryOpen={galleryOpen === 'parts-gallery'}
                                                slide={currentSlide}
                                                onClose={() => this.setState({ galleryOpen: false })}
                                                images={this.getItem('part', item.input.part).media.map((img, index) =>
                                                    config.getImageUrl('zoom', img.path)
                                                )}
                                                onMovePrevRequest={() =>
                                                    this.setState({
                                                        currentSlide: (currentSlide + item.input.media.length - 1) % item.input.media.length,
                                                    })
                                                }
                                                onMoveNextRequest={() =>
                                                    this.setState({
                                                        currentSlide: (currentSlide + 1) % item.input.media.length,
                                                    })
                                                }
                                            />
                                        </Responsive>

                                        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                                            <Gallery
                                                galleryOpen={galleryOpen === 'parts-gallery'}
                                                slide={currentSlide}
                                                onClose={() => this.setState({ galleryOpen: false })}
                                                images={this.getItem('part', item.input.part).media.map((img, index) =>
                                                    config.getImageUrl('zoomDesktop', img.path)
                                                )}
                                                onMovePrevRequest={() =>
                                                    this.setState({
                                                        currentSlide: (currentSlide + this.getItem('part', item.input.part).media.length - 1) % this.getItem('part', item.input.part).media.length,
                                                    })
                                                }
                                                onMoveNextRequest={() =>
                                                    this.setState({
                                                        currentSlide: (currentSlide + 1) % this.getItem('part', item.input.part).media.length,
                                                    })
                                                }
                                            />
                                        </Responsive>
                                    </>

                                }
                            </Form>
                            <Divider hidden />

                            {/* Include lightbox */}
                            <Responsive {...Responsive.onlyMobile}>
                                <Gallery
                                    galleryOpen={galleryOpen === item.id}
                                    slide={currentSlide}
                                    onClose={() => this.setState({ galleryOpen: false })}
                                    images={item.input.media.map((img, index) =>
                                        config.getImageUrl('zoom', img.path)
                                    )}
                                    onMovePrevRequest={() =>
                                        this.setState({
                                            currentSlide: (currentSlide + item.input.media.length - 1) % item.input.media.length,
                                        })
                                    }
                                    onMoveNextRequest={() =>
                                        this.setState({
                                            currentSlide: (currentSlide + 1) % item.input.media.length,
                                        })
                                    }
                                />
                            </Responsive>

                            <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                                <Gallery
                                    galleryOpen={galleryOpen === item.id}
                                    slide={currentSlide}
                                    onClose={() => this.setState({ galleryOpen: false })}
                                    images={item.input.media.map((img, index) =>
                                        config.getImageUrl('zoomDesktop', img.path)
                                    )}
                                    onMovePrevRequest={() =>
                                        this.setState({
                                            currentSlide: (currentSlide + item.input.media.length - 1) % item.input.media.length,
                                        })
                                    }
                                    onMoveNextRequest={() =>
                                        this.setState({
                                            currentSlide: (currentSlide + 1) % item.input.media.length,
                                        })
                                    }
                                />
                            </Responsive>

                        </Container>
                    ))}
                </div>
            )
        }
        else {
            return (

                <Container>
                    <Form>
                        <Form.Input
                            label="Bezeichnung"
                            required
                            value={expenses.inputTitle}
                            name={"expense.inputTitle"}
                            onChange={this.handleInput}
                            error={formValidation.validationName.error}
                        />

                        {formValidation.validationName.error &&
                            <Message color="red">
                                {formValidation.validationName.message}
                            </Message>
                        }

                        <Form.Input
                            label="Beschreibung (optional)"
                            value={expenses.inputDesc}
                            name={"expense.inputDesc"}
                            onChange={this.handleInput}
                        />

                        <div className="autosuggestion">
                            <label>Gekauft bei (optional)</label>
                            <input
                                onChange={this.getItem('bill', expenses.inputBill) ? null : this.handleInputAc}
                                autoComplete="off"
                                className={this.getItem('bill', expenses.inputBill) ? 'transparent-border' : ''}
                                value={this.getItem('bill', expenses.inputBill) ? this.getItem('bill', expenses.inputBill).mercant : expenses.inputMercant}
                                name={"expense.inputMercant"}
                            />

                            {expenses.inputMercant && this.props.acMercant && Object.values(this.props.acMercant).length > 0 &&
                                <div className="autosuggestion-list show">
                                    <ul>
                                        {Object.values(this.props.acMercant).map((option, index) => (
                                            <li onClick={() => {
                                                this.handleInput(null, {name: "expense.inputMercant", value: option});
                                                this.props.dispatch(resetAutosuggestion('mercant'));
                                            }} key={index}>{option}</li>
                                        ))}
                                    </ul>
                                </div>
                            }
                        </div>

                        <Form.Field error={formValidation.validationCost.error}>
                            <label>Kosten</label>
                            <Input
                                value={expenses.inputPrice || expenses.inputPrice === 0 ? config.convertToPointNumber(expenses.inputPrice) : '' }
                                name={"expense.inputPrice"}
                                label={{ basic: true, content: '€' }}
                                labelPosition='right'
                                fluid
                                type='text'
                                style={{ width: "auto", maxWidth: '150px' }}
                                onChange={this.handleInput}
                            />
                        </Form.Field>

                        {formValidation.validationCost.error &&
                            <Message color="red">
                                {formValidation.validationCost.message}
                            </Message>
                        }

                        <Form.Field>
                            <label>Rechnung zuweisen (Überschreibt "Gekauft bei")</label>
                            <Select menuPlacement="auto"
                                options={config.buildChoice(bills.items, 'title')}
                                name="expense.inputBill"
                                onChange={this.handleInputSelect}
                                value={config.getDefault(config.buildChoice(bills.items, 'title'), expenses.inputBill)}
                                classNamePrefix="custom-select"
                                className="custom-select-wrapper"
                            />
                        </Form.Field>

                        <Form.Field>
                            <label>Story zuweisen (optional)</label>
                            <Select menuPlacement="auto"
                                options={config.buildChoice(project.projectpartsData, 'title')}
                                name="expense.inputProjectpart"
                                onChange={this.handleInputSelect}
                                classNamePrefix="custom-select"
                                className="custom-select-wrapper"
                                value={config.getDefault(config.buildChoice(project.projectpartsData, 'title'), expenses.inputProjectpart)}
                            />
                        </Form.Field>

                        <Divider hidden />
                        {/* Images */}
                        <FileUpload
                            headers={{ 'Authorization': 'Bearer ' + token }}
                            allowedFileTypes={['jpg', 'jpeg']}
                            dropzoneId={"expensesByProject." + match.params.id + ".inputMedia"}
                            multiple={true}
                            url={config.API_HOST + config.API_UPLOAD}
                        >
                            <div style={{ marginTop: "2rem", textAlign: "center" }}>
                                <Button color="red">Bilder hinzufügen</Button>
                            </div>
                        </FileUpload>
                        <Divider hidden />

                            {expenses.inputMedia && 

                                <PreviewGallery 
                                    deletable
                                    imagesPerRow={3}
                                    imagesOnly={expenses.inputMedia}
                                    entity="expense"
                                    itemId="NEW"
                                    requestDelete={this.handleMediaDelete}
                                />

                            }

                        {this.getItem('part', expenses.inputPart) && this.getItem('part', expenses.inputPart).media.length > 0 &&

                            <>
                                <Divider />
                                <Header as="h2">Übernommen von "Benötigte Teile"</Header>
                                <div className="content-gallery-imgs">
                                    {this.getItem('part', expenses.inputPart).media.map((m, index) => (

                                            <ImageWrapper path={m.path} className="content-gallery-wrapper no-description">
                                                <ProgressiveImager style={{cursor: 'default'}} src={config.getImageUrl('preview', m.path)} />
                                            </ImageWrapper>

                                    ))}
                                </div>
                            </>

                        }
                    </Form>

                </Container>
            )
        }
    }

    bills(edit, id) {
        const { token, bills, match } = this.props;
        const { formValidation } = this.state;

        if (edit) {
            return (
                <div>
                    {bills.items.filter(item => item.id === id).map(item => (
                        <div>
                            <Container>
                                <Form>
                                    <Form.Input
                                        label="Bezeichnung"
                                        value={item.input.title}
                                        name={"bill." + item.id + ".title"}
                                        onChange={this.handleChangeInput}
                                        error={formValidation.validationName.error}
                                    />

                                    {formValidation.validationName.error &&
                                        <Message color="red">
                                            {formValidation.validationName.message}
                                        </Message>
                                    }

                                    <div className="autosuggestion">
                                        <label>Gekauft bei (optional)</label>
                                        <input
                                            onChange={this.handleChangeInputAc}
                                            autoComplete="off"
                                            value={item.input.mercant}
                                            name={"bill." + item.id + ".mercant"}
                                        />

                                        {item.input.mercant && this.props.acMercant && Object.values(this.props.acMercant).length > 0 &&
                                            <div className="autosuggestion-list show">
                                                <ul>
                                                    {Object.values(this.props.acMercant).map((option, index) => (
                                                        <li onClick={() => {
                                                            this.handleChange("bill." + item.id + ".mercant", option);
                                                            this.props.dispatch(resetAutosuggestion('mercant'));
                                                        }} key={index}>{option}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                    <Form.Group widths={3}>
                                        <Form.Field>
                                            <label>Rechnungsdatum</label>
                                            <Form.Input

                                                type='date'
                                                name={"bill." + item.id + ".billdate"}
                                                value={item.input.billdate ? moment.unix(item.input.billdate).format("YYYY-MM-DD") : null}
                                                onChange={this.handleChangeDate}
                                            />
                                        </Form.Field>
                                    </Form.Group>

                                </Form>
                            </Container>
                            {/* PDFs/Images */}
                            <Divider hidden />
                            <FileUpload
                                headers={{ 'Authorization': 'Bearer ' + token }}
                                allowedFileTypes={['jpg', 'jpeg', 'pdf']}
                                dropzoneId={"billsByProject." + match.params.id + ".bill." + item.id + ".media"}
                                multiple={true}
                                url={config.API_HOST + config.API_UPLOAD}
                            >
                                <div style={{ marginTop: "2rem", textAlign: "center" }}>
                                    <Button color="red">PDFs / Bilder hinzufügen</Button>
                                </div>
                            </FileUpload>
                            <Divider hidden />
                            {item.input.media &&
                                item.input.media.map(m => (
                                    <div style={{ marginBottom: "0.5rem" }}>
                                        {m.path === 'LOADING' ?
                                            <Placeholder style={{height: "54px", maxWidth: "100%"}}>
                                                <Placeholder.Image length='full'/>
                                            </Placeholder>
                                            :
                                            <Swipeout
                                                autoClose
                                                right={[
                                                    {
                                                        text: <div  className="swipeout-img-container" style={{ textAlign: "center" }} onTouchEnd={() => this.handleMediaDelete(m.id, item.id, 'bill')}>
                                                            <div className="swipeout-img">
                                                                <Image src={trashIcon} style={{ height: "27px", margin: "0 auto 0.5rem" }} />
                                                            </div>
                                                        </div>,
                                                        style: {
                                                            backgroundColor: "#E7344C",
                                                            color: "white",
                                                            textAlign: "center",
                                                            fontWeight: "bold",
                                                            width: "70px",
                                                            height: "100%"
                                                        }
                                                    }
                                                ]}
                                            >
                                               
                                                    <Segment style={{cursor: "pointer"}}>
                                                        <a download target="blank" to={config.getDirectUrl(m.path)}></a>
                                                        <Grid columns={2}>
                                                            <Grid.Row style={{ paddingBottom: "0.25rem" }}>
                                                                <Grid.Column width={3}>
                                                                    <Image
                                                                        src={m.type === config.FILE_IMAGE ? pictureIcon : documentIcon}
                                                                        className="custom-icon"
                                                                    />
                                                                </Grid.Column>
                                                                <Grid.Column mobile={13} tablet={13} computer={10}>
                                                                    <span className="segment-text" style={{ fontWeight: 600 }}>{m.type === config.FILE_IMAGE ? 'Bild' : 'PDF'} - </span>
                                                                        <Responsive {...Responsive.onlyMobile} as={Truncate} lines={1} width="200" ellipsis={<>...</>} className="segment-text">
                                                                            <span>{m.description}</span>
                                                                        </Responsive>

                                                                        <Responsive minWidth={Responsive.onlyTablet.minWidth} as={Truncate} lines={1} width="500" ellipsis={<>...</>} className="segment-text">
                                                                            <span>{m.description}</span>
                                                                        </Responsive>

                                                                </Grid.Column>
                                                                <Grid.Column only="computer" textAlign="right" width={3} className="elements-dots-menu">
                                                                    <div className='delete-icon' onClick={() => this.handleMediaDelete(m.id, item.id, 'bill')}>
                                                                        <Icon name="times" style={{cursor: "pointer"}}/>
                                                                    </div>
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        </Grid>
                                                    </Segment>
                                                
                                            </Swipeout>
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    ))}

                </div>
            )
        }
        else {
            return (
                <>
                <Container>
                    <Form>
                        <Form.Input
                            label="Bezeichnung"
                            required
                            value={bills.inputTitle}
                            name={"bill.inputTitle"}
                            error={formValidation.validationName.error}
                            onChange={this.handleInput}
                        />

                        {formValidation.validationName.error &&
                            <Message color="red">
                                {formValidation.validationName.message}
                            </Message>
                        }

                        <div className="autosuggestion">
                            <label>Gekauft bei (optional)</label>
                            <input
                                onChange={this.handleInputAc}
                                autoComplete="off"
                                value={bills.inputMercant}
                                name={"bill.inputMercant"}
                            />

                            {bills.inputMercant && this.props.acMercant && Object.values(this.props.acMercant).length > 0 &&
                                <div className="autosuggestion-list show">
                                    <ul>
                                        {Object.values(this.props.acMercant).map((option, index) => (
                                            <li onClick={() => {
                                                this.handleInput(null, {name: "bill.inputMercant", value: option});
                                                this.props.dispatch(resetAutosuggestion('mercant'));
                                            }} key={index}>{option}</li>
                                        ))}
                                    </ul>
                                </div>
                            }
                        </div>
                        <Form.Group widths={3}>
                            <Form.Field>
                                <label>Rechnungsdatum</label>
                                <Form.Input
                                    type='date'
                                    name={"bill.inputBilldate"}
                                    value={bills.inputBilldate ? moment.unix(bills.inputBilldate).format("YYYY-MM-DD") : null}
                                    onChange={this.handleDate}
                                />
                            </Form.Field>
                        </Form.Group>

                    </Form>
                    </Container>
                    <Divider hidden />
                    {/* Images */}
                    <FileUpload
                        headers={{ 'Authorization': 'Bearer ' + token }}
                        allowedFileTypes={['jpg', 'jpeg', 'pdf']}
                        dropzoneId={"billsByProject." + match.params.id + ".inputMedia"}
                        multiple={true}
                        url={config.API_HOST + config.API_UPLOAD}
                    >
                        <div style={{ marginTop: "2rem", textAlign: "center" }}>
                            <Button color="red">PDFs / Bilder hinzufügen</Button>
                        </div>
                    </FileUpload>
                    <Divider hidden />
                    {bills.inputMedia &&
                        bills.inputMedia.map(m => (
                            <div style={{ marginBottom: "0.5rem" }}>
                                {m.path === 'LOADING' ?
                                    <Placeholder>
                                        <Placeholder.Image length='full' />
                                    </Placeholder>
                                    :
                                    <Swipeout
                                        autoClose
                                        right={[
                                            {
                                                text: <div className="swipeout-img-container" style={{ textAlign: "center" }} onTouchEnd={() => this.handleMediaDelete('bill', 'NEW', m.id)}>
                                                    <div className="swipeout-img">
                                                        <Image src={trashIcon} style={{ height: "27px", margin: "0 auto 0.5rem" }} />
                                                    </div>
                                                </div>,

                                                style: {
                                                    backgroundColor: "#E7344C",
                                                    color: "white",
                                                    textAlign: "center",
                                                    fontWeight: "bold",
                                                    width: "70px",
                                                    height: "100%"
                                                }
                                            }
                                        ]}
                                    >
                                        <Segment>
                                            <Grid columns={2}>
                                                <Grid.Row style={{ paddingBottom: "0.25rem" }}>
                                                    <Grid.Column width={3}>
                                                        <Image
                                                            src={m.type === config.FILE_IMAGE ? pictureIcon : documentIcon}
                                                            className="custom-icon"
                                                        />
                                                    </Grid.Column>
                                                    <Grid.Column width={13}>
                                                        <span className="segment-text" style={{ fontWeight: 600 }}>{m.type === config.FILE_IMAGE ? 'Bild' : 'PDF'} - </span>

                                                        <Responsive {...Responsive.onlyMobile} as={Truncate} lines={1} width="200" ellipsis={<>...</>} className="segment-text">
                                                            <span>{m.description}</span>
                                                        </Responsive>

                                                        <Responsive minWidth={Responsive.onlyTablet.minWidth} as={Truncate} lines={1} width="500" ellipsis={<>...</>} className="segment-text">
                                                            <span>{m.description}</span>
                                                        </Responsive>

                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Segment>
                                    </Swipeout>
                                }
                            </div>
                        ))
                    }

           </> )
        }
    }

    confirmDeletePart(itemId) {
        const { dispatch, item, match } = this.props;
        const { popup } = this.state;
        return (
            <Container>
                <Divider hidden />
                <div style={{ textAlign: "center", margin: "1rem 0" }}>
                    <Icon name="warning sign" color="red" size="huge" />
                </div>
                <div style={{ textAlign: "center" }}>Willst du das Teil wirklich löschen?</div>
                <Divider hidden />
                <div className="buttons-center">
                    <Button color="red" onClick={() => this.setState({ popup: { isOpen: false } })}>
                        Zurück
                        </Button>
                    <Button basic color="red" onClick={() => {
                        dispatch(deletePart(match.params.id, itemId))
                        this.setState({ popup: { isOpen: false } })
                    }}>
                        Löschen
                        </Button>
                </div>
                <Divider hidden />
            </Container>
        )
    }

    confirmDeleteCost() {
        const { dispatch, item, match } = this.props;
        const { popup, itemId } = this.state;
        return (
            <Container>
                <Divider hidden />
                <div style={{ textAlign: "center", margin: "1rem 0" }}>
                    <Icon name="warning sign" color="red" size="huge" />
                </div>
                <div style={{ textAlign: "center" }}>Willst du die Ausgabe wirklich löschen?</div>
                <Divider hidden />
                <div className="buttons-center">
                    <Button color="red" onClick={() => this.setState({ popup: { isOpen: false } })}>
                        Zurück
                        </Button>
                    <Button basic color="red" onClick={() => {
                        dispatch(deleteExpense(match.params.id, popup.itemId));
                        this.setState({ popup: { isOpen: false } })
                    }}>
                        Löschen
                        </Button>
                </div>
                <Divider hidden />
            </Container>
        )
    }

    confirmDeleteBill() {
        const { dispatch, item, match } = this.props;
        const { popup, mediaId, itemId } = this.state;
        return (
            <Container>
                <Divider hidden />
                <div style={{ textAlign: "center", margin: "1rem 0" }}>
                    <Icon name="warning sign" color="red" size="huge" />
                </div>
                <div style={{ textAlign: "center" }}>Willst du die Rechnung wirklich löschen?</div>
                <Divider hidden />
                <div className="buttons-center">
                    <Button color="red" onClick={() => this.setState({ popup: { isOpen: false } })}>
                        Zurück
                        </Button>
                    <Button basic color="red" onClick={() => {
                            dispatch(deleteBill(match.params.id, popup.itemId))
                            this.setState({ popup: { isOpen: false } })
                        }}>
                        Löschen
                        </Button>
                </div>
                <Divider hidden />
            </Container>
        )
    }

    getItem(entity, id) {
        const { parts, bills } = this.props;

        if (entity === 'bill') {
            return bills.items.filter(item => item.id === id)[0];
        }

        if (entity === 'part') {
            return parts.items.filter(item => item.id === id)[0];
        }
    }

    buyPart(part) {
        const { dispatch, item } = this.props;

        // Prefill
        dispatch(newExpense(item.id, 'inputTitle', part.name));
        dispatch(newExpense(item.id, 'inputDesc', part.description));
        dispatch(newExpense(item.id, 'inputPrice', null));
        dispatch(newExpense(item.id, 'inputBill', null));
        dispatch(newExpense(item.id, 'inputPart', part.id));
        dispatch(newExpense(item.id, 'inputMercant', null));
        dispatch(newExpense(item.id, 'inputMedia', null));
        dispatch(newExpense(item.id, 'inputProjectpart', null));
        // Open new expense
        this.handleItems(true, "Neue Ausgabe", "new-expenses");
    }
}

function mapStateToProps(state, ownProps) {
    const { globalByComponent, account, projectById, partsByParent, billsByParent, expensesByParent } = state
    var collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });

    // Components
    const { open, openSecondary, slidingPaneId, title } = globalByComponent[
        'SlidingPane'
    ] || {
            open: true,
            openSecondary: true,
            slidingPaneId: null,
            title: "",
        }
    const { openPopup } = globalByComponent[
        'CustomPopup'
    ] || {
            openPopup: false
        }
    const { elementId } = globalByComponent[
        'Project'
    ] || {
            elementId: null
        }

    // Autocomplete
    const acMercant = (globalByComponent['Autocomplete'] ? globalByComponent['Autocomplete']['mercant'] : null);

    // Project
    const { item, isDirty, isFetching } = projectById[
        ownProps.match.params.id
    ] || {
            isDirty: false,
            isFetching: true,
        }

    // Parts
    const {
        items: part_items,
        isFetching: part_isFetching,
        isFailed: part_isFailed,
        isDeleting: part_isDeleting,
        isPosting: part_isPosting,
        inputTitle: part_inputTitle,
        inputDesc: part_inputDesc,
        inputMedia: part_inputMedia,
    } = partsByParent[
    'project::' + ownProps.match.params.id
    ] || {
            items: {},
            isFetching: true,
            isFailed: false,
            isPosting: false,
            isDeleting: false,
            inputTitle: '',
            inputDesc: '',
            inputMedia: [],
        }

    var part_items_array = [];
    if (part_items) {
        part_items_array = [].concat(Object.values(part_items)).sort((a, b) => {
            if (a.done && !b.done) { return 1; }
            if (!a.done && b.done) { return -1; }
            return collator.compare(a.createDate > b.createDate);
        });
    }

    // Bills
    const {
        items: bill_items,
        isFetching: bill_isFetching,
        isFailed: bill_isFailed,
        isDeleting: bill_isDeleting,
        isPosting: bill_isPosting,
        inputTitle: bill_inputTitle,
        inputMercant: bill_inputMercant,
        inputBilldate: bill_inputBilldate,
        inputMedia: bill_inputMedia,
    } = billsByParent[
    'project::' + ownProps.match.params.id
    ] || {
            items: {},
            isFetching: true,
            isFailed: false,
            isPosting: false,
            isDeleting: false,
            inputTitle: '',
            inputMercant: '',
            inputBilldate: null,
            inputMedia: [],
        }

    var bill_items_array = [];
    if (bill_items) {

        bill_items_array = [].concat(Object.values(bill_items)).sort((a, b) => {
            if (a.billdate > b.billdate) { return -1; }
            if(!a.billdate && !b.billdate) {
                return collator.compare(a.createDate > b.createDate);
            }
        });
    }

    // Expenses
    const {
        items: expense_items,
        isFetching: expense_isFetching,
        isFailed: expense_isFailed,
        isPosting: expense_isPosting,
        isDeleting: expense_isDeleting,
        inputTitle: expense_inputTitle,
        inputDesc: expense_inputDesc,
        inputPrice: expense_inputPrice,
        inputBill: expense_inputBill,
        inputPart: expense_inputPart,
        inputMercant: expense_inputMercant,
        inputMedia: expense_inputMedia,
        inputProjectpart: expense_inputProjectpart,
    } = expensesByParent[
    'project::' + ownProps.match.params.id
    ] || {
            items: {},
            isFetching: true,
            isFailed: false,
            isPosting: false,
            isDeleting: false,
            inputTitle: '',
            inputDesc: '',
            inputPrice: 0,
            inputBill: null,
            inputPart: null,
            inputMercant: '',
            inputMedia: [],
            inputProjectpart: null,
        }

    var expense_items_array = [];
    if (expense_items) {
        expense_items_array = [].concat(Object.values(expense_items)).sort((a, b) => {
            return collator.compare(a.createDate > b.createDate);
        });
    }

    var expense_count = 0;
    expense_items_array.map(function (item) {
        if (item.price) {
            expense_count += parseFloat(item.price);
        }
    })

    const { token } = account || {
        token: null
    }

    return {
        open,
        openSecondary,
        slidingPaneId,
        title,
        openPopup,
        elementId,
        token,
        item,
        isDirty,
        isFetching,
        acMercant,
        parts: {
            items: part_items_array,
            isFetching: part_isFetching,
            isFailed: part_isFailed,
            isPosting: part_isPosting,
            isDeleting: part_isDeleting,
            inputTitle: part_inputTitle,
            inputDesc: part_inputDesc,
            inputMedia: part_inputMedia,
        },
        bills: {
            items: bill_items_array,
            isFetching: bill_isFetching,
            isFailed: bill_isFailed,
            isDeleting: bill_isDeleting,
            isPosting: bill_isPosting,
            inputTitle: bill_inputTitle,
            inputMercant: bill_inputMercant,
            inputBilldate: bill_inputBilldate,
            inputMedia: bill_inputMedia,
        },
        expenses: {
            items: expense_items_array,
            isFetching: expense_isFetching,
            isFailed: expense_isFailed,
            isPosting: expense_isPosting,
            isDeleting: expense_isDeleting,
            inputTitle: expense_inputTitle,
            inputDesc: expense_inputDesc,
            inputPrice: expense_inputPrice,
            inputBill: expense_inputBill,
            inputPart: expense_inputPart,
            inputProjectpart: expense_inputProjectpart,
            count: expense_count,
            inputMercant: expense_inputMercant,
            inputMedia: expense_inputMedia,
        },
    }
}

export default connect(mapStateToProps)(PartsCosts);
