import config from '../config/config';
import { createAction } from 'redux-api-middleware';

export const FOOTER_SHOW = "FOOTER_SHOW";
export const FOOTER_HIDE = "FOOTER_HIDE";
export const CONTAINER_MAIN = "CONTAINER_MAIN";
export const CONTAINER_SECONDARY = "CONTAINER_SECONDARY";
export const HANDLE_CONTAINER = "HANDLE_CONTAINER";
export const HANDLE_POPUP = "HANDLE_POPUP";
export const POPUP_CONTENT = "POPUP_CONTENT";
export const FOOTER_ACTIVE_ITEM = "FOOTER_ACTIVE_ITEM";
export const TOGGLE_SLIDING_PANE = "TOGGLE_SLIDING_PANE";
export const TOGGLE_SLIDING_PANE_SECONDARY = "TOGGLE_SLIDING_PANE_SECONDARY";
export const HANDLE_SLIDINGPANE = "HANDLE_SLIDINGPANE";
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const SLIDINGPANE_CONTENT = "SLIDINGPANE_CONTENT";
export const SLIDINGPANE_SECONDARY_CONTENT = "SLIDINGPANE_SECONDARY_CONTENT";
export const INPUT_SEARCH = "INPUT_SEARCH";
export const SELECTED_ELEMENT_ITEM = "SELECTED_ELEMENT_ITEM";
export const GET_ELEMENT_ID = "GET_ELEMENT_ID";
export const HANDLE_FIRST_SLIDINGPANE = "HANDLE_FIRST_SLIDINGPANE";
export const HANDLE_SECONDARY_SLIDINGPANE = "HANDLE_SECONDARY_SLIDINGPANE";
export const FILE_UPLOAD = "FILE_UPLOAD";
export const HANDLE_SIDEBAR_SLIDINGPANE = "HANDLE_SIDEBAR_SLIDINGPANE";
export const CONTAINER_FRONTEND = "CONTAINER_FRONTEND";
export const TOGGLE_FILTER = "TOGGLE_FILTER";
export const GALLERY_HANDLE = "GALLERY_HANDLE";
export const HANDLE_LOGIN_POPUP = "HANDLE_LOGIN_POPUP";
export const HANDLE_HEADER_MENU = "HANDLE_HEADER_MENU";
export const HANDLE_COMMENTS = "HANDLE_COMMENTS";
export const HANDLE_ANSWERS = "HANDLE_ANSWERS";
export const LAYOUT_TYPE = "LAYOUT_TYPE";
export const CONSENT = "CONSENT";
export const RUNTIME = "RUNTIME";
export const SET_PREVIOUS_PATH = "SET_PREVIOUS_PATH";
export const METADATA_REQUEST = "METADATA_REQUEST";
export const METADATA_SUCCESS = "METADATA_SUCCESS";
export const METADATA_FAILURE = "METADATA_FAILURE";
export const TOGGLE_PROJECT_LIST_VIEW = "TOGGLE_PROJECT_LIST_VIEW";
export const TOGGLE_PROFESSIONAL_LIST_VIEW = "TOGGLE_PROFESSIONAL_LIST_VIEW";
export const SET_CANNONICAL_TAG = "SET_CANNONICAL_TAG";
export const FETCH_BRAND_ALL = "FETCH_BRAND_ALL";
export const FETCH_BRAND_PROJECTS_REQUEST = "FETCH_BRAND_PROJECTS_REQUEST";
export const FETCH_BRAND_PROJECTS_SUCCESS = "FETCH_BRAND_PROJECTS_SUCCESS";
export const FETCH_BRAND_PROJECTS_FAILED = "FETCH_BRAND_PROJECTS_FAILED";
export const RESET_BRAND_PROJECTS_LIST = "RESET_BRAND_PROJECTS_LIST";
export const PWA_INSTALL_BANNER = "PWA_INSTALL_BANNER ";
export const DASHBOARD_MESSAGES = "DASHBOARD_MESSAGES" // Top banner message 
export const LOGIN_WORKFLOW = "LOGIN_WORKFLOW";
export const EDIT_HEADER = "EDIT_HEADER";
export const SHOW_TOOLTIP = "SHOW_TOOLTIP";
export const HIDE_TOOLTIP = "HIDE_TOOLTIP";
export const GET_USER_COORDS = "GET_USER_COORDS";

export function setRuntime(values){
    return (dispatch, getState) => {
        const Runtimedata = getState().globalByComponent.Runtimedata;

        // Process location
        if(Runtimedata && Runtimedata.currentLocation) {
            if (Runtimedata.currentLocation !== values.currentLocation) {
                values.lastLocation = Runtimedata.currentLocation;
            }
        }

        return dispatch(setRuntime2(values));
    }
}

function setRuntime2(values){
    return {
        type: RUNTIME,
        component: "Runtimedata",
        payload: {
            ...values,
        }
    }
}

export function setConsent(consent){
    return {
        type: CONSENT,
        component: "ConsentBanner",
        data: {
            consent,
        }
    }
}

export function setMetadata(metadata){
    return {
        type: METADATA_SUCCESS,
        payload: {
            ...metadata,
        }
    }
}

export function fetchMetadata(){
    return createAction({
        endpoint: `/metadata.json?${Date.now()}`,
        method: 'GET',
        types: [
            {type: METADATA_REQUEST},
            {type: METADATA_SUCCESS},
            {type: METADATA_FAILURE},
        ]
    })
}

//Container
export function containerMain(){
    return {
        type: CONTAINER_MAIN,
        component: "Container",
        data: {
            containerMain: true,
            containerSecondary: false,
            containerFrontend: false
        }
    }
}

export function containerSecondary(){
    return {
        type: CONTAINER_SECONDARY,
        component: "Container",
        data: {
            containerMain: false,
            containerSecondary: true,
            containerFrontend: false
        }
    }
}

export function containerFrontend(){
    return {
        type: CONTAINER_FRONTEND,
        component: "Container",
        data: {
            containerMain: false,
            containerSecondary: false,
            containerFrontend: true
        }
    }
}

export function handleContainer(type, title, show, path, item, headerlink, headermeta) {
    var headlineTag = 'h1';
    if (title.substring(0, 5) === 'SPAN#') {
        headlineTag = 'span';
        title = title.substring(5);
    }
    if(!path){
        path = "goback"
    }
    return {
        type: HANDLE_CONTAINER,
        component: 'Container',
        data: {
            type: type,
            headerTitle: title,
            showSidebar: show,
            path: path,
            sidebarItem: item,
            headerlink,
            headermeta,
            headlineTag,
        }
    }
}

//Footer

export function showFooter() {
    return {
        type: FOOTER_SHOW,
        component: 'Footer',
        data: {
            isVisible: true,
        }
    }
}

export function hideFooter() {
    return {
        type: FOOTER_SHOW,
        component: 'Footer',
        data: {
            isVisible: false,
        }
    }
}

//Footer active menu item
export function footerActiveItem(item) {
    return {
        type: FOOTER_ACTIVE_ITEM,
        component: 'Footer',
        data: {
            activeItem: item
        }
    }
}

//HANDLE HEADER MENU
export function handleHeaderItems(item) {
    return {
        type: HANDLE_HEADER_MENU,
        component: "Container",
        data: {
            headerActiveItem: item
        }
    }
}

//MAINTAIN_STATE_POPUP
export function handlePopup() {
    return {
        type: HANDLE_POPUP,
        component: "Popup",
        data: {
            isOpen: false,
            title: "",
            content: "",
            itemId: null
        }
    }
}

//Handle Frontend Project list view
export function toggleProjectListView(currentView){
    return {
        type: TOGGLE_PROJECT_LIST_VIEW,
        component: "projectList",
        data: {
            currentView
        }
    }
}

//Handle Frontend Project list view
export function toggleProfessionalListView(currentView){
    return {
        type: TOGGLE_PROFESSIONAL_LIST_VIEW,
        component: "ProfessionalList",
        data: {
            currentView
        }
    }
}

//Set canonical tag 
export function setCanonicalTag(canonicalTagUrl) {
    return {
        type: SET_CANNONICAL_TAG,
        component: "CanonicalTag",
        data: {
            canonicalTagUrl
        }
    }
}

export function fetchBrandAll(){

    return dispatch => {
        fetch(`${config.API_HOST}/v1/filter/brand?slug=1`)
            .then(response => response.json())
            .then(json => {
                dispatch(getVehicleBrand(json))
            })
    }
}

export function getVehicleBrand(data){
    return {
        type: FETCH_BRAND_ALL,
        component: 'BrandIndex',
        payload: {
            brand: data
        }
    }
}

export function fetchBrandProject(options, slug) {
    if (!options) {options = {}}
    // if (!page) {page = 1}
    // if (!size) {size = 20}

    var query = '';
    for (let [key, value] of Object.entries(options)) {
        query += '&'+encodeURIComponent(key)+'='+encodeURIComponent(value);
    }
    //`${config.API_HOST}/v1/project?page=${page}&size=${size}${query}`, TODO: Pagination?
    return createAction({
        endpoint: `${config.API_HOST}/v1/project?${query}`,
        method: 'GET',
        types: [
            { type: FETCH_BRAND_PROJECTS_REQUEST, meta: slug },
            { type: FETCH_BRAND_PROJECTS_SUCCESS, meta: slug },
            { type: FETCH_BRAND_PROJECTS_FAILED, meta: slug },
        ]
    })
}

export function resetBrandProjectsList(){
    return {
        type: RESET_BRAND_PROJECTS_LIST
    }
}

export function pwaInstallBanner(show){
    return {
        type: PWA_INSTALL_BANNER,
        component: "PWA Banner",
        payload: {
            showAppInstallBanner: show
        }
    }
}

export function setActionAfterLogin(redirect){
    return {
        type: LOGIN_WORKFLOW,
        component: "AccountAction",
        data: {
            redirectToDashboard: redirect
        }
    }
}

export function dashboardMesssages(show, msg, last){
    if (msg === "updateMessage"){
        return {
            type: DASHBOARD_MESSAGES,
            component: "DashboardMessage",
            payload: {
                showDashboardMessage: show,
                type: "updateMessage",
                message: 'Es gibt ein neues Update. Hier erfährst du mehr.',
                showedDate: Date.now(),
                lastMsg: last
            }
        }
    }
    else if(msg === "storyMessage"){
        return {
            type: DASHBOARD_MESSAGES,
            component: "DashboardMessage",
            payload: {
                showDashboardMessage: show,
                type: "storyMessage",
                message: "Tipp: Du solltest zu jedem deiner Fahrzeuge Storys anlegen, um Fortschritte sowie Kosten und Arbeitszeit zu dokumentieren.",
                showedDate: Date.now(),
                lastMsg: last
            }
        }
    }
    else if(msg === "appMessage"){
        return {
            type: DASHBOARD_MESSAGES,
            component: "DashboardMessage",
            payload: {
                showDashboardMessage: show,
                type: "appMessage",
                message: "Vetero als App installieren - Hier erfährst du mehr.",
                showedDate: Date.now(),
                
            }
        }
    }
    else if(msg === "vehicleMessage"){
        return {
            type: DASHBOARD_MESSAGES,
            component: "DashboardMessage",
            payload: {
                showDashboardMessage: show,
                type: "vehicleMessage",
                message: "Du hast noch keine Fahrzeuge angelegt...",
                showedDate: Date.now()
            }
        }
    }
    else {
        return {
            type: DASHBOARD_MESSAGES,
            component: "DashboardMessage",
            payload: {
                showDashboardMessage: false,
                type: "",
                message: "",
                showedDate: Date.now()
            }
        }
    }
}

//Handle edit header
export function editHeader(backPath, callback){
    return {
        type: EDIT_HEADER,
        component: "EditHeader",
        data: {
            backPath,
            callback
        }
    }
}

export function showTooltip(parent, tooltip){
    return {
        type: SHOW_TOOLTIP,
        component: "Tooltip",
        payload: {
            parent,
            tooltip
        }
    }
}

export function hideTooltip(parent, tooltip){
    return {
        type: HIDE_TOOLTIP,
        component: "Tooltip",
        payload: {
            parent,
            tooltip
        }
    }
}

export function getUserCoords(position, status){
    return {
        type: GET_USER_COORDS,
        component: "UserCoords",
        payload: {
            position,
            status
        }
    }
}