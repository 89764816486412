import React, { Component } from 'react'
import { Breadcrumb, Image, Popup } from 'semantic-ui-react';
import { Link } from 'react-router-dom'
import config from '../../config/config'
import ImageWrapper from '../../components/helpers/image-wrapper';
import ProgressiveImager from '../../components/helpers/progressive-imager';
import './UserBreadcrumb.scss';


class UserBreadcrumb extends Component {
    render() {
        const { user, date, userId, project, projectPart } = this.props;

        return (
            <div className="user-breadcrumb">
                <Link to={`/user/${user.urlslug}/${userId}`}>
                    <div className="user-image">
                        <ImageWrapper avatar circular path={user.mediaavatarPath}>
                            <ProgressiveImager alt={"Profilbild von " + user.name} placeholder="avatar" circular src={config.getImageUrl('avatar', user.mediaavatarPath)}/>
                        </ImageWrapper> 
                    </div>
                </Link>
                <Breadcrumb className="custom-breadcrumb">
                    <Breadcrumb.Section>
                        <Link to={`/user/${user.urlslug}/${userId}`}>
                            {user.name}
                        </Link>
                    </Breadcrumb.Section>
                    {project && project.title && project.urlslug &&
                        <>
                            <Breadcrumb.Divider icon="triangle right"/>
                            <Breadcrumb.Section>
                            {project.active ? 
                                <Link to={project.linktype === "stories" ? `/oldtimer/${project.urlslug}/${project.id}/tab/stories` : project.linktype === "vehicle" ? `/oldtimer/${project.urlslug}/${project.id}/tab/vehicle` : `/oldtimer/${project.urlslug}/${project.id}/tab/interview` }
                                >
                                    {project.title}
                                </Link>
                                :
                                project.active
                            }
                            </Breadcrumb.Section>
                        </>
                    }
                    {projectPart && projectPart.title && projectPart.urlslug && 
                        <>
                            <Breadcrumb.Divider icon="triangle right"/>
                            <Breadcrumb.Section>
                                {projectPart.active ? 
                                    <Link to={`/oldtimer/${project.urlslug}/${projectPart.urlslug}/${projectPart.id}`}>
                                        {projectPart.title}
                                    </Link>
                                    :
                                    projectPart.title
                                }
                            </Breadcrumb.Section>
                        </>
                    }
                </Breadcrumb>
                {this.props.meta &&
                    <div className="breadcrumb-metadate text-grey">{date}</div>
                }
                <div className="clearfix"></div>
            </div>
        )
    }
}

export default UserBreadcrumb;
