import React from 'react'
import { Grid, Container, Icon, Form, Input, Message, Divider, Button } from 'semantic-ui-react'
import { connect } from 'react-redux';
import Select from 'react-select'
import TextareaAutosize from 'react-textarea-autosize';
import CustomPopup from '../../components/CustomPopup/CustomPopup'
import { FileUpload } from 'ix-redux-file-upload';
import { handleContainer, handleHeaderItems, setRuntime } from '../../actions/global';
import ImageWrapper from '../../components/helpers/image-wrapper';
import config from '../../config/config'
import ProgressiveImager from '../../components/helpers/progressive-imager';
import {updateAccount, deleteAccount, logoutAccount} from '../../actions/account'
import BeParent from '../project/backend/be-parent'


class AccountSettings extends BeParent {

    state = {
        popup: false,
        deletePw: '',
        formValidation: {
            validationName: {
                error: false,
                message: ''
            },
            validationEmail: {
                error: false,
                message: ''
            },
            validationBirthYear: {
                error: false,
                message: ''
            },
            validationZipCode: {
                error: false,
                message: ''
            },
            validationSubmit: {
                error: false,
            }
        }
    }

    constructor(props) {
        super(props);

        this.submit = this.submit.bind(this);
        this.handleDeletePw = this.handleDeletePw.bind(this);
        this.submitDelete = this.submitDelete.bind(this);
    }

    componentDidMount = () => {
        const { dispatch } = this.props;
        dispatch(handleContainer("secondary", "Profil-/Kontoeinstellungen", true));
        dispatch(handleHeaderItems("dashboard"));
        dispatch(setRuntime({page: 'be-account'}));
    }

    componentDidUpdate = (prevProps) => {
        const { dispatch, deletingMessage } = this.props;

        if (prevProps.deletingMessage !== deletingMessage && deletingMessage === 'SUCCESS') {
            dispatch(logoutAccount());
        }
    }

    componentWillUnmount = () => {
        const { dispatch } = this.props;
        dispatch(handleContainer("dashboard", "Dashboard", true));
    }

    submit(e, element) {
        const { dispatch, item, isFetching, isDirty } = this.props;
        const { formValidation } = this.state;

        if (!isFetching && isDirty) {
            if(!config.numericFields(item.birthyear).error && !config.numericFields(item.zipcode).error){
                dispatch(updateAccount(item));
            }
            else {
                this.setState({
                    formValidation: {
                        ...formValidation,
                        validationSubmit: {
                            error: true
                        }
                    }
                 })
            }
        }
    }

    handleDeletePw(e, element) {
        this.setState({deletePw: element.value});
    }

    submitDelete(e, element) {
        const { dispatch, item, isDeleting } = this.props;

        if (!isDeleting) {
            // TODO: Validate
            dispatch(deleteAccount(item, this.state.deletePw));
        }
    }

    render() {
        const { account, item, isFetching, isDirty, updatingMessage } = this.props;
        const { formValidation } = this.state;

        return (
            <div className="account-settings">
                <Container>
                    <Grid>
                        <Grid.Column width={3}>
                            <label className="input-label">Profilbild</label>
                        </Grid.Column>
                        <Grid.Column width={10}>
                            <div className="user-profil-img">

                                <FileUpload
                                    headers={{ 'Authorization': 'Bearer ' + account.token }}
                                    allowedFileTypes={['jpg', 'jpeg']}
                                    dropzoneId={'account.mediaavatar'}
                                    url={config.API_HOST + config.API_UPLOAD}
                                >
                                    <ImageWrapper avatar circular path={item.mediaavatarPath}>
                                        <ProgressiveImager placeholder="avatar" circular src={config.getImageUrl('avatar', item.mediaavatarPath)}/>
                                        <div className="user-profil-edit">
                                            <Icon name="pencil alternate" circular inverted/>
                                        </div>
                                    </ImageWrapper>
                                </FileUpload>

                            </div>
                        </Grid.Column>
                    </Grid>
                    <Divider/>
                    <Form>
                        <Grid>
                            <Grid.Column width={8}>
                                <Form.Field>
                                    <Form.Input
                                        label="Benutzername"
                                        value={ item.inputName !== undefined ? item.inputName : item.name }
                                        name="account.inputName"
                                        onChange={this.handleChangeInput}
                                        error={formValidation.validationName.error}
                                        onBlur={(e) => this.setState({
                                            formValidation: {
                                                ...this.state.formValidation,
                                                validationName: config.validateTextField(e.target.value)
                                            }
                                        })}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Form.Input
                                        label="E-Mail Adresse"
                                        value={ item.inputEmail !== undefined ? item.inputEmail : item.email }
                                        name="account.inputEmail"
                                        onChange={this.handleChangeInput}
                                        error={formValidation.validationEmail.error}
                                        onBlur={(e) => this.setState({
                                            formValidation: {
                                                ...this.state.formValidation,
                                                validationEmail: config.validateTextField(e.target.value)
                                            }
                                        })}
                                    />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <Form.Field>
                                    <label className="input-label">Neues Passwort</label>
                                    <Input
                                        name="account.plainPassword"
                                        type="password"
                                        fluid
                                        className="pw-input"
                                        onChange={this.handleChangeInput}
                                        autoComplete="new-password"
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label className="input-label">Passwort wiederholen</label>
                                    <Input
                                        name="account.plainPassword2"
                                        type="password"
                                        fluid
                                        className="pw-input"
                                        onChange={this.handleChangeInput}
                                        autoComplete="new-password"
                                    />
                                </Form.Field>
                            </Grid.Column>
                        </Grid>
                    </Form>
                    <Divider style={{margin: "1.5rem 0 1rem 0"}}/>
                    <Form>
                        <Form.Field>
                            <label>Wie bist Du zum Hobby „Oldtimer“ gekommen?</label>
                            <TextareaAutosize
                                placeholder="Erzähle etwas über dich..."
                                minRows="5"
                                maxRows="15"
                                name="account.description"
                                value={item.description}
                                onChange={this.handleChangeInputAlt}
                            />
                        </Form.Field>
                    </Form>
                    {/* TODO: Message toggle
                    <Message color="teal">
                        Die nachfolgenden Informationen helfen uns dabei, interessante Projekte und Informationen bereitzustellen.
                        Du kannst die Veröffentlichung deaktivieren.
                    </Message> */}
                    <Divider hidden/>
                    <Grid className="user-info">
                        <Grid.Column width={8}>
                            <Form>
                                <Form.Input
                                    label="Geburtsjahr"
                                    value={item.birthyear}
                                    name="account.birthyear"
                                    onChange={this.handleChangeNumericInput}
                                    maxLength="4"
                                    error={formValidation.validationBirthYear.error}
                                    onBlur={(e) => this.setState({
                                        formValidation: {
                                            ...this.state.formValidation,
                                            validationBirthYear: config.numericFields(e.target.value)
                                        }
                                    })}
                                />
                            </Form>
                        </Grid.Column>
                        {/*<Grid.Column width={8}>
                            <Checkbox toggle className="settings-checkbox-toggle"/>
                        </Grid.Column>*/}

                        <Grid.Column width={8}>
                            <Form>
                                <Form.Input
                                    label="PLZ"
                                    value={item.zipcode}
                                    name="account.zipcode"
                                    onChange={this.handleChangeNumericInput}
                                    error={formValidation.validationZipCode.error}
                                    maxLength="5"
                                    onBlur={(e) => this.setState({
                                        formValidation: {
                                            ...this.state.formValidation,
                                            validationZipCode: config.numericFields(e.target.value)
                                        }
                                    })}
                                />
                            </Form>
                        </Grid.Column>
                        {/*<Grid.Column width={8}>
                            <Checkbox toggle className="settings-checkbox-toggle"/>
                        </Grid.Column>*/}

                        <Grid.Column width={8}>
                            <Form>
                                <Form.Field>
                                    <label>Land</label>
                                    <Select menuPlacement="auto"
                                        classNamePrefix="select-custom"
                                        options={config.countryChoice()}
                                        name="account.countrycode"
                                        onChange={this.handleChangeSelect}
                                        value={config.getDefault(config.countryChoice(), item.countrycode)}
                                    />
                                </Form.Field>
                            </Form>
                        </Grid.Column>
                        {/*<Grid.Column width={8}>
                            <Checkbox toggle className="settings-checkbox-toggle"/>
                        </Grid.Column>*/}
                    </Grid>
                    <Divider style={{margin: "1.5rem 0 1rem 0"}}/>
                    <Grid>
                        <Grid.Row columns={2}>
                            <Grid.Column width={8}>
                                <span className="font-small">E-Mail Benachrichtigungen</span>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <Select menuPlacement="auto"
                                    classNamePrefix="select-custom"
                                    options={config.getIntervals()}
                                    name="account.notificationMailInterval"
                                    onChange={this.handleChangeSelect}
                                    value={config.getDefault(config.getIntervals(), item.notificationMailInterval)}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Divider hidden/>
                    {updatingMessage === 'SUCCESS' &&
                        <Message success>Dein Profil wurde aktualisiert.</Message>
                    }
                    {updatingMessage === 'SUCCESSWITHCHANGE' &&
                        <Message success>Dein Profil wurde aktualisiert. Um die Änderung der E-Mailadresse abzuschließen hast du einen Bestätigungslink an die neue Adresse geschickt bekommen.</Message>
                    }
                    {updatingMessage === 'FAILURE' &&
                        <Message negative>Beim Senden ist ein Fehler aufgetreten.</Message>
                    }
                    {updatingMessage === 'pwmissmatch' &&
                        <Message negative>Die Passwörter stimmen nicht überein.</Message>
                    }
                    {updatingMessage === 'namealreadyused' &&
                        <Message negative>Der gewünschte Name wird bereits verwendet.</Message>
                    }
                    {formValidation.validationSubmit.error &&
                        <Message negative>Überprüfe deine Angaben.</Message>
                    }
                    <div className="delete-account">
                        <Button disabled={formValidation.validationBirthYear.error || formValidation.validationZipCode.error || !isDirty} loading={isFetching} onClick={this.submit} color="red">Änderungen speichern</Button>
                    </div>
                    <Divider hidden/>
                    <Divider hidden/>
                    <Divider hidden/>
                    <Divider hidden/>
                    <div className="delete-account">
                        <Button basic onClick={() => this.setState({popup: true})} className="delete-account">Konto löschen</Button>
                    </div>
                    <Divider hidden/>
                </Container>

                {/* Popup include */}
                <CustomPopup
                    popupHeader="Konto löschen"
                    popupOpen={this.state.popup}
                    onClose={() => this.setState({popup: false})}
                >
                    {this.confirmDelete()}
                </CustomPopup>
            </div>
        )
    }

    confirmDelete(){
        const { isDeleting, deletingMessage } = this.props;

        return (
            <Container>
                <Divider hidden/>
                <div style={{textAlign: "center", margin: "1rem 0"}}>
                    <Icon name="warning sign" color="red" size="huge"/>
                </div>
                <div style={{textAlign: "center"}}>Willst du dein Konto wirklich löschen?</div>
                <Divider hidden/>
                <Form.Field>
                    <label className="input-label">Gib dein Passwort zur Bestätigung ein</label>
                    <Input
                        type="password"
                        fluid
                        className="pw-input"
                        onChange={this.handleDeletePw}
                        autoComplete="new-password"
                    />
                </Form.Field>
                <Divider hidden/>
                {deletingMessage === 'SUCCESS' &&
                    <Message success>Dein Profil wurde gelöscht!</Message>
                }
                {deletingMessage === 'FAILURE' &&
                    <Message negative>Beim Löschen ist ein Fehler aufgetreten.</Message>
                }
                {deletingMessage === 'wrongpw' &&
                    <Message negative>Das eingegebene Passwort ist falsch.</Message>
                }
                <div className="buttons-center">
                    <Button color="red" onClick={() => this.setState({popup: false})}>
                        Zurück
                    </Button>
                    <Button loading={isDeleting} basic color="red" onClick={this.submitDelete} >
                        Löschen
                    </Button>
                </div>
                <Divider hidden/>
            </Container>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { account } = state;

    const { isFetching, isFailed, isDirty, lastUpdated, lastFetch, item, token, updatingMessage, isDeleting, deletingMessage } = account || {
        item: {},
    }

    return {
        account,
        item,
        isFetching,
        isFailed,
        isDirty,
        lastUpdated,
        lastFetch,
        token,
        updatingMessage,
        isDeleting,
        deletingMessage,
    }
}

export default connect(mapStateToProps)(AccountSettings)
