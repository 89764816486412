import React, { Component } from 'react';
import './HeaderImage.scss';
import ProgressiveImager from '../helpers/progressive-imager';
import config from '../../config/config';

class HeaderImage extends Component {
    render() {

        const { children, overlay, height, src, detail } = this.props;

        if(detail){
            return (
                <div className="header-image detail">
                    <ProgressiveImager backgroundImage className="image" src={config.getImageUrl('mainDesktop', src)}/>
                    {children && 
                        <div className={detail ? "header-image-content-detail" : "header-image-content"}>
                            {children}
                        </div>
                    }
                </div>
            )
        }

        else {

            return (

                <div 
                    className={
                        detail ? 'header-image detail' : overlay ? 'header-image img-overlay' : 'header-image ' 
                        
                        } 
                    style={{
                        backgroundImage: `url(${src})`,
                        height: height
                    }}>

                    {children && 
                        <div className={detail ? "header-image-content-detail" : "header-image-content"}>
                            {children}
                        </div>
                    }

                </div>
            )
        }
    }
}

export default HeaderImage;
