import React, { Component } from 'react'
import { Container, Button, Message, Form, Input, Image, Divider, Icon, Loader, Label } from 'semantic-ui-react'
import { connect } from 'react-redux';
import ForumTags from '../../components/forum/forumTags';
import tinymce from 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/emoticons/js/emojis';
import 'tinymce/plugins/paste';
import { Editor } from '@tinymce/tinymce-react';
import '../../tinymce/langs/de';
import Select from 'react-select';
import { FileUpload } from 'ix-redux-file-upload';
import BeParent from '../project/backend/be-parent';
import SearchModule from '../../components/SearchModule/SearchModule';
import {postMessage, newForumThread, clearNewForumThread, newForumThreadAddTag, newForumThreadRemoveTag, newForumThreadSaveTag, newForumThreadRevertTag, newForumThreadDeleteDirectTag, resetThreadList} from "../../actions/forumthread";
import { fetchUserProjectsIfNeeded } from '../../actions/project'
import { fetchStoryListIfNeeded, fetchStoryList } from '../../actions/story';
import { handleContainer, setRuntime } from '../../actions/global'
// import CustomSlidingPane from '../../components/CustomSlidingPane/CustomSlidingPane';
// import Helmet from 'react-helmet'
import ImageWrapper from '../../components/helpers/image-wrapper'
import { Redirect } from "react-router-dom";
// import FeContentElement from  '../../components/project/Stories/fe-content-element';
import config from '../../config/config';
import ProgressiveImager from '../../components/helpers/progressive-imager';
// import CommentsWrapper from '../../components/Comments/CommentsWrapper';
// import NumericLabel from 'react-pretty-numbers'
// import Account from '../../components/account/Account';
// /* ICONS */
// import heartIcon from '../../images/icons/frontend/icon-heart.svg';
// import commentIcon from '../../images/icons/frontend/icon-comment.svg';
// import eurIcon from '../../images/icons/frontend/icon-euro-circle.svg';
// import clockIcon from '../../images/icons/frontend/icon-clock-circle.svg';
// import heartIconDark from '../../images/icons/frontend/icon-heart-dark.svg';
// import heartIconActive from '../../images/icons/frontend/icon-active-heart.svg';
import cameraIconRed from '../../images/icons/frontend/icon-camera-red.svg';
import PreviewGallery from '../../components/PreviewGallery/PreviewGallery';
import AddIcon from '../../images/icons/circle-add-element.svg';
import CustomPopup from '../../components/CustomPopup/CustomPopup';
import { clearSelectedFields } from '../../actions/autosuggestion';


class ForumNew extends BeParent {

    constructor(props) {
        super(props);

        this.handleEditorChange = this.handleEditorChange.bind(this);
        this.submit = this.submit.bind(this);

        this.state = {
            fieldValidationBrand: false,
            fieldValidationModel: false,
            fieldValidationVariant: false,
            tagModelIsOpen: false
        }

    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const { dispatch, accountItem, inputForumThread } = this.props;

        dispatch(setRuntime({
            page: 'fe-forumthread-new',
        }));

        dispatch(handleContainer("secondary", 'Frage im Vetero-Forum stellen'));

        // Init if empty
        if (!inputForumThread || !inputForumThread.message || !inputForumThread.thread) {
            dispatch(clearNewForumThread());
        }
        //Fetch user Projects
        dispatch(fetchUserProjectsIfNeeded(accountItem.id));

        //Fetch project stories
        if(inputForumThread && inputForumThread.thread.project){
            dispatch(fetchStoryList(inputForumThread.thread.project));
        }

        //Set already choosed tags to input field
        if(inputForumThread && inputForumThread.thread.categories && inputForumThread.thread.categories.length > 0){
            dispatch(newForumThread({inputTag: inputForumThread.thread.categories}));
        }

    }

    componentDidUpdate(prevProps) {
        const { inputForumThread, dispatch } = this.props;

        if(inputForumThread.newId && inputForumThread.newId !== prevProps.inputForumThread.newId) {
            this.setState({ redirect: '/forum/thread/'+inputForumThread.newId });
            dispatch(resetThreadList());
        }

        //Fetch project stories if project was selected
        if(inputForumThread.thread.project && (prevProps.inputForumThread.thread.project !== inputForumThread.thread.project)){
            dispatch(fetchStoryList(inputForumThread.thread.project));
        }

    }

    handleEditorChange(content, editor) {
        const { dinputForumThread } = this.props;

        this.handleChange("inputForumThread.message.text", content);
    }

    submit(e, element) {
        const { dispatch, inputForumThread } = this.props;

        if (!inputForumThread.isPosting) {
            dispatch(postMessage(inputForumThread.message, inputForumThread.thread));
            dispatch(clearSelectedFields())
        }

    }

    handleDeleteImage = (id) => {
        const { dispatch, inputForumThread } = this.props;

        let updatedArray = inputForumThread.message.media.filter(item => item.id !== id);

        dispatch(newForumThread({
            message: {
                ...inputForumThread.message,
                media: updatedArray,
            }
        }));
    }

    handleAddTag = (tag) => {
        const { dispatch } = this.props;
        dispatch(newForumThreadAddTag(tag));
    }

    handleDeleteTag = (tag) => {
        const { dispatch } = this.props;
        dispatch(newForumThreadRemoveTag(tag));
    }

    handleDirectDeleteTag = (tag) => {
        const { dispatch, inputForumThread } = this.props;
        dispatch(newForumThreadDeleteDirectTag(tag));
    }

    handleRevertTag = () => {
        const { dispatch, inputForumThread } = this.props;
        let arr = [];
        inputForumThread.thread.categories && inputForumThread.thread.categories.map(item => (
            config.getCategoriesSub(false).map((tag, index) => (
                tag.options.filter(itemTag => itemTag.value === item).map((category) => (
                   category.value === item && arr.push(category)
                ))
             ))
        ))

        dispatch(newForumThreadRevertTag(arr))
        this.setState({ tagModelIsOpen: false })
    }

    handleSaveTag = () => {
        const { dispatch, inputForumThread } = this.props;
        let arr = [];
        inputForumThread.thread.inputTag.map(item => arr.push(item.value))

        dispatch(newForumThreadSaveTag(arr))
        this.setState({ tagModelIsOpen: false })
    }

    handleProjectChange = (e) => {
        const { dispatch } = this.props;
        this.handleChange("inputForumThread.thread.model", e.target.value)
    }

    render() {
        const { accountItem, inputForumThread } = this.props;

        if (this.state && this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }

        if (accountItem && accountItem.id && inputForumThread) {
            return (
                <Container text className="forum-create-post">

                    <Message color="teal">
                        Hier kannst Du anderen Vetero-Usern eine Frage zu Oldtimern stellen bzw. Dein Problem schildern. Falls es sich um eine Frage zu einem Deiner Oldtimer auf Vetero handelt, kannst Du das betreffende Fahrzeug und eine Story mit angeben.
                        Du wirst dadurch bessere Antworten bekommen.
                    </Message>

                    {this.stepBaseType()}
                    {this.stepBaseData()}
                </Container>
            );
        }

        return (<Container text><Message color="teal">Logg dich ein um eine Frage im Forum zu stellen.</Message></Container>);
    }

    stepBaseType() {
        const { dispatch, inputForumThread } = this.props;

        return (
            <>
                <h2>Auf was bezieht sich Deine Frage?</h2>
                <div className="question-type">
                    <Button
                        color="red"
                        basic={(inputForumThread.base === 'PROJECT' ? false : true)}
                        onClick={() => {
                            dispatch(clearNewForumThread());
                            dispatch(newForumThread({base: 'PROJECT'}));
                        }}
                    >
                        Mein Vetero Projekt
                    </Button>
                    <Button
                        color="red"
                        basic={(inputForumThread.base === 'VEHICLE' ? false : true)}
                        onClick={() => {
                            dispatch(clearNewForumThread());
                            dispatch(newForumThread({base: 'VEHICLE'}));
                        }}
                    >
                        Anderer Oldtimer
                    </Button>
                    <Button
                        color="red"
                        basic={(inputForumThread.base === 'FORUM' ? false : true)}
                        onClick={() => {
                            dispatch(clearNewForumThread());
                            dispatch(newForumThread({base: 'FORUM'}));
                        }}
                    >
                        Allgemeine Frage
                    </Button>
                </div>
            </>
        );
    }

    stepBaseData() {
        const { inputForumThread, projects, dispatch, userProjectsIsFetching, storiesIsFetching, stories } = this.props;

        if (inputForumThread.base === 'PROJECT') {
            return (
                <>
                <Form className="choose-project">
                    <Form.Group widths="2">
                        <Form.Field fluid>
                            <label>Dein Fahrzeug</label>
                            <Select
                                menuPlacement="auto"
                                placeholder="Auswahl..."
                                isLoading={userProjectsIsFetching}
                                options={config.buildChoice(projects, 'title')}
                                name="inputForumThread.thread.project"
                                onChange={this.handleChangeSelect}
                                value={config.getDefault(config.buildChoice(projects, 'title'), inputForumThread.thread.project)}
                                style={{zIndex: '2'}}
                                classNamePrefix="custom-select"
                                className="custom-select-wrapper"
                                />
                        </Form.Field>

                        {inputForumThread.thread.project &&
                            <Form.Field>
                                <label>Story (optional)</label>
                                <Select
                                    menuPlacement="auto"
                                    placeholder="Auswahl..."
                                    isLoading={storiesIsFetching}
                                    options={config.buildChoice(stories, 'title')}
                                    name="inputForumThread.thread.projectPart"
                                    onChange={this.handleChangeSelect}
                                    value={config.getDefault(config.buildChoice(stories, 'title'), inputForumThread.thread.projectPart)}
                                    style={{zIndex: '2'}}
                                    classNamePrefix="custom-select"
                                    className="custom-select-wrapper"
                                    />
                            </Form.Field>
                        }
                    </Form.Group>
                </Form>

                {inputForumThread.thread.project &&
                    this.stepQuestion()
                }

                </>
            );
        }

        if (inputForumThread.base === 'VEHICLE') {
            return (
                <>
                <Form className="choose-vehicle">
                    <Form.Group widths="2">
                        <Form.Field>
                            <label>Kategorie</label>
                            <Select menuPlacement="auto"
                                className="inline-select"
                                value={config.getDefault(config.getScopes(), inputForumThread.thread.scope)}
                                name="inputForumThread.thread.scope"
                                onChange={this.handleChangeSelect}
                                options={config.getScopes()}
                                placeholder="Auswählen..."
                                isSearchable={ false }
                                noOptionsMessage={() => "Keine Ergebnisse gefunden"}
                            />
                        </Form.Field>
                    </Form.Group>
                    {inputForumThread.thread.scope &&
                        <Form.Group widths="2">
                            <Form.Field>
                                <SearchModule
                                    validation={{
                                        brand: this.state.fieldValidationBrand,
                                    }}
                                    brandInput
                                    brandFieldName="inputForumThread.thread.brand"
                                    brandInputValue={inputForumThread.thread.brand}
                                />
                            </Form.Field>
                            <Form.Field>
                                <SearchModule
                                    validation={{
                                        model: this.state.fieldValidationModel,
                                        }}
                                    modelInput
                                    modelFieldName="inputForumThread.thread.vehicle"
                                />
                            </Form.Field>
                        </Form.Group>
                    }
                </Form>
                {inputForumThread.thread.scope &&
                    this.stepQuestion()
                }
                </>
            )
        }

        if (inputForumThread.base === 'FORUM') {
            return (
                <>
                <Form>
                    <Form.Group widths="2">
                        <Form.Field>
                            <label>Foren (hier wird Deine Frage gezeigt)</label>
                            <Select
                                menuPlacement="auto"
                                classNamePrefix="custom-select"
                                className="custom-select-wrapper"
                                name="inputForumThread.thread.forum"
                                onChange={this.handleChangeSelect}
                                value={config.getDefault(config.getForums(), inputForumThread.thread.forum)}
                                options={config.getForums()}
                            />
                        </Form.Field>
                    </Form.Group>
                </Form>

                {inputForumThread.thread.forum &&
                    this.stepQuestion()
                }

                </>
            );
        }
    }

    stepQuestion() {
        const { dispatch, inputForumThread, token } = this.props;
        const { tagModelIsOpen } = this.state;


        return (
                <>
                <h2>Deine Frage</h2>
                <Form className="question-content">
                    <Form.Field>
                        <label>Überschrift</label>
                        <Form.Input
                            type="text"
                            name="inputForumThread.thread.title"
                            onChange={this.handleChangeInput}
                            value={inputForumThread.thread.title}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Beschreibung</label>
                        <Editor
                            value={inputForumThread.message.text}
                            init={{...config.TINY_INIT}}
                            onEditorChange={this.handleEditorChange}
                            disabled={inputForumThread.isPosting ? true : false}
                        />
                    </Form.Field>
                    <h2>Bilder und Videos</h2>
                    <Form.Field>
                        <FileUpload
                          headers={{ 'Authorization': 'Bearer '+token }}
                          allowedFileTypes={['jpg', 'jpeg', 'mp4']}
                          dropzoneId={"inputForumThread.message.media"}
                          multiple={true}
                          url={config.API_HOST + config.API_UPLOAD}
                        >
                            <div className="button-element">
                                <Button basic color="red" className="single-button">
                                    <Image src={cameraIconRed} alt="Icon Kamera" className="button-icon"/>
                                    <span>Bilder/Videos hinzufügen</span>
                                </Button>
                            </div>
                        </FileUpload>
                        <Divider hidden/>

                            <PreviewGallery
                                images={inputForumThread.message.media}
                                deletable
                                requestDelete={this.handleDeleteImage}
                            />
                        
                    </Form.Field>

                </Form>

                {/* Tagging */}
                <div className="post-tags">
                    <h2>Tags</h2>
                    <p className="post-tags-description">
                        Füge Tags hinzu um das Thema einzugrenzen und dadurch besser gefunden zu werden
                    </p>

                    <div className="tags">
                        <span>Tags: </span>
                        {inputForumThread.thread.categories &&
                            inputForumThread.thread.categories.map(tagValue => (
                                config.getCategoriesSub(false, true).map((tag, index) => (
                                    tag.options.filter(item => item.value === tagValue).map((category, index) => (
                                        <Label as='a' key={index} onClick={() => this.handleDirectDeleteTag(category.value)}>
                                            {category.label}
                                            <Icon name="delete"/>
                                        </Label>
                                    ))
                                 ))
                            ))
                        }


                        <div className="add-tag" onClick={() => this.setState({ tagModelIsOpen: true })}>
                            <Image title="Tag hinzufügen" alt="Icon Circle Add" src={AddIcon} />
                        </div>
                    </div>

                </div>

                <CustomPopup
                    popupOpen={tagModelIsOpen}
                    onClose={() => this.setState({ tagModelIsOpen: false })}
                    //saveButton={this.showButton()}
                    showCloseIcon={false}
                    popupHeader="Tags hinzufügen"
                    headerBackgroundWhite
                    className="larger-popup"
                >
                    <ForumTags
                        onRequestClose={this.handleRevertTag}
                        onRequestSave={this.handleSaveTag}
                        handleTag={this.handleAddTag}
                        handleDeleteTag={this.handleDeleteTag}
                        inputTags={inputForumThread.thread.inputTag}
                        messageText={false}
                    />
                </CustomPopup>

                <Divider hidden/>

                {inputForumThread.postingMessage === 'FAILURE' &&
                        <Message negative>Beim Senden ist ein Fehler aufgetreten.</Message>
                    }

                    <Button
                        color="red"
                        basic
                        disabled={inputForumThread.isPosting}
                        onClick={() => {
                            dispatch(clearNewForumThread());
                        }}
                    >
                        Frage verwerfen
                    </Button>
                    <Button
                        color="red"
                        loading={inputForumThread.isPosting}
                        // TODO: Better validation
                        disabled={(!inputForumThread.message.text || !inputForumThread.thread.title) ? true : false}
                        onClick={this.submit}
                    >
                        Frage veröffentlichen
                    </Button>

                    <Divider hidden/>

                </>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { account, listByEntity, projectById, storyById } = state;

    const { item: accountItem, inputForumThread, token } = account || {
        item: {},
        inputForumThread: {}
    }

    const { list, isFetching: userProjectsIsFetching } = listByEntity[account.item.id+ '::userProjects'] || {
        list: {},
        userProjectsIsFetching: true
    }

    let stories = [];
    let projectId;

    if(inputForumThread && inputForumThread.thread.project){
        projectId = inputForumThread.thread.project
    }
    else {
        projectId = 0;
    }

    const { list: storiesList, isFetching: storiesIsFetching } = listByEntity[projectId + '::story'] || {
        storiesList: {},
        storiesIsFetching: true,
    }

    if (storiesList) {
        for (let value of Object.values(storiesList)) {
            if (storyById[value]) {
                stories.push(storyById[value].item);
            }
        }
    }

    let projects = [];

    if (list) {
        for (let value of Object.values(list)) {
            if (projectById[value]) {
                projects.push(projectById[value].item);
            }
        }
    }


    return {
        accountItem,
        inputForumThread,
        token,
        projects,
        userProjectsIsFetching,
        stories,
        storiesIsFetching
    };
}

export default connect(mapStateToProps)(ForumNew)
