import React, { Component } from 'react';
import { Container, Grid, Header, Image, Button, Divider, Card, Placeholder, Responsive, Message } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { handleContainer, handleHeaderItems, setRuntime } from '../../actions/global';
import { fetchUserVoucher, selectUserVoucher } from '../../actions/account';
import ImageWrapper from '../../components/helpers/image-wrapper';
import config from '../../config/config'
import ProgressiveImager from '../../components/helpers/progressive-imager';
import Moment from 'react-moment';
import 'moment/locale/de'
import 'moment-timezone';

import LordGeorgeLogo from '../../images/Lord_George_Logo_rgb-01-300x213.png';


class Voucher extends Component {

    componentDidMount = () => {
        const { dispatch } = this.props;
        dispatch(handleContainer("secondary", "Gutscheine", true));
        dispatch(handleHeaderItems("dashboard"));
        dispatch(setRuntime({page: 'be-vouchers'}));
        dispatch(fetchUserVoucher());
        window.scrollTo(0, 0);
    }

    componentWillUnmount = () => {
        const { dispatch } = this.props;
        dispatch(handleContainer("backend", "Dashboard", true));
    }

    render() {

        const { isFetching, partnersList, vouchersList, dispatch, requirements, voucherMessage, messagePosition } = this.props;

        if(!(partnersList && partnersList.length) && !(vouchersList && vouchersList.length) && isFetching) {

            return (
                <Container className="voucher-wrapper">

                    <Responsive {...Responsive.onlyMobile}>
                        <Header as="h2" className="text-placeholder">Verfügbare Gutscheine</Header>
                        <Divider hidden/>
                    </Responsive>

                    <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                        <Header as="h2" className="text-placeholder">Verfügbare Gutscheine</Header>
                        <Divider/>
                    </Responsive>

                    <Grid>
                        <Grid.Column mobile={16} tablet={8} computer={8}>
                            <Card className="voucher">
                                <Header as="h2" textAlign="center">-</Header>
                                <div className="voucher-image-placeholder">

                                </div>
                                <Divider/>
                                <div className="voucher-description">
                                   <Placeholder>
                                       <Placeholder.Line />
                                       <Placeholder.Line length="long"/>
                                       <Placeholder.Line length="medium"/>
                                   </Placeholder>
                                </div>
                                <Divider/>
                                <Button.Group className="voucher-buttons">
                                    <Button disabled color="red">Rabattcode anfordern</Button>
                                    <Button disabled color="red">Zum Anbieter</Button>
                                </Button.Group>
                            </Card>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={8} computer={8}>
                            <Card className="voucher">
                                <Header as="h2" textAlign="center">-</Header>
                                <div className="voucher-image-placeholder">
                                </div>
                                <Divider/>
                                <div className="voucher-description">
                                    <Placeholder>
                                       <Placeholder.Line />
                                       <Placeholder.Line length="long"/>
                                       <Placeholder.Line length="medium"/>
                                   </Placeholder>
                                </div>
                                <Divider/>
                                <Button.Group className="voucher-buttons">
                                    <Button disabled color="red">Rabattcode anfordern</Button>
                                    <Button disabled color="red">Zum Anbieter</Button>
                                </Button.Group>
                            </Card>
                        </Grid.Column>
                    </Grid>
                </Container>
            )
        }
        else  {
            return (
                <Container className="voucher-wrapper">

                    <Header as="h2" style={{fontFamily: 'Barlow', fontSize: '20px', textTransform: 'none'}}>Verfügbare Gutscheine</Header>
                    {partnersList.length == 0 && <div className="voucher-notfound">Keine verfügbare Gutscheine vorhanden</div>}

                    {!requirements &&
                        <Message color="teal">
                            Um den Rabattcode anzufordern, musst du zumindestens ein Fahrzeug und eine Story veröffentlicht haben.
                        </Message>
                    }

                    {partnersList.length > 0 &&
                    <Grid>
                        <Grid.Column mobile={16} tablet={8} computer={8}>
                            <Card className="voucher">
                                <Header as="h2" textAlign="center">Lord George</Header>
                                <div className="voucher-image lord-george">
                                    <Image src={LordGeorgeLogo} alt="Lord George Logo"/>
                                </div> 
                                <Divider/>

                                <div className="voucher-code">vetero2021</div>
                                <div className="voucher-description" style={{textAlign: "center"}}>
                                    Als Vetero Mitglied bekommst Du mit dem Code <strong>vetero2021</strong> einen Rabatt von 10 %.
                                </div>

                                <Divider/>
                                <div className="voucher-buttons">
                                    <a href="https://lord-george.de" target="_blank" rel="noopener noreferrer">
                                        <Button fluid color="red">
                                            Zum Anbieter
                                        </Button>
                                    </a>
                                </div>
        
                            </Card>
                        </Grid.Column>

                        {partnersList.map((voucher, index) =>
                    <>

                            <Grid.Column mobile={16} tablet={8} computer={8} key={index}>
                                <Card className="voucher">
                                    <Header as="h2" textAlign="center">{voucher.name}</Header>
                                    <div className="voucher-image">
                                        <ImageWrapper path={voucher.filePath}>
                                            <ProgressiveImager placeholder="transparent" src={config.getImageUrl('avatar', voucher.filePath)} />
                                        </ImageWrapper>
                                    </div>
                                    <Divider/>

                                    {vouchersList.filter(item => item.partner == voucher.id && Math.floor(Date.now() / 1000) < item.availableUntil).length > 0 &&
                                        vouchersList.map(item => (
                                            <Message color="teal">
                                                Du kannst den Rabattcode am <Moment add={{ days: 1 }} locale="de" format="DD.MM.YYYY" unix>{item.availableUntil }</Moment> anfordern.
                                            </Message>

                                        ))
                                    }

                                    <div className="voucher-description" dangerouslySetInnerHTML={{__html: voucher.description}}/>
                                    <Divider/>
                                    {messagePosition === voucher.id &&
                                        <>
                                            {voucherMessage === 'SUCCESS' &&
                                                <Message success>Der Gutschein steht dir nun zur Verfügung.</Message>
                                            }
                                            {voucherMessage === 'FAILURE' &&
                                                <Message negative>Beim Abrufen ist ein Fehler aufgetreten.</Message>
                                            }
                                            {voucherMessage === 'thisvoucherisnotavailable' &&
                                                <Message negative>Der gewünschte Gutschein ist leider zur Zeit nicht verfügbar.</Message>
                                            }
                                            {voucherMessage === 'alreadyhasone' &&
                                                <Message negative>Du hast bereits einen aktiven Gutschein. Ein Neuer kann erst nach Ablauf der Frist angefordert werden.</Message>
                                            }
                                            {voucherMessage === 'missingrequirements' &&
                                                <Message negative>Du erfüllst leider nicht die Voraussetzungen für diesen Gutschein.</Message>
                                            }
                                        </>
                                    }
                                    <Button.Group className="voucher-buttons">
                                        <Button
                                            color="red"
                                            onClick={requirements ? () => dispatch(selectUserVoucher(voucher.id)) : null}
                                            disabled={!requirements || vouchersList.length > 0 && vouchersList.filter(item => Math.floor(Date.now() / 1000) < item.availableUntil ).length ? true : false}
                                            loading={isFetching}
                                        >

                                            Rabattcode anfordern
                                        </Button>
                                        <Button color="red">
                                            <a href={voucher.url} target="_blank" rel="noopener noreferrer">Zum Anbieter</a>
                                        </Button>
                                    </Button.Group>
                                </Card>
                            </Grid.Column>
                        {/* Mobile view */}
                    </>
                    )}
                    </Grid>
                    }

    <Header as="h2" style={{fontFamily: 'Barlow', fontSize: '20px', textTransform: 'none'}}>Bereits abgerufene Gutscheine</Header>

    <Grid>

        {vouchersList.map((usedVouchers, index) => (
            <Grid.Column mobile={16} tablet={8} computer={8} key={index}>
                <Card className="voucher">
                    <Header as="h2" textAlign="center">{usedVouchers.partnerData.name}</Header>
                    <div className="voucher-image">
                        <ImageWrapper path={usedVouchers.partnerData.filePath}>
                            <ProgressiveImager placeholder="transparent" src={config.getImageUrl('avatar', usedVouchers.partnerData.filePath)} />
                        </ImageWrapper>
                    </div> 
                    <Divider/>

                    <div className="voucher-code">{usedVouchers.code}</div>
                        <div className="voucher-date">
                            <span>Neuer Gutschein verfügbar ab: </span>
                            <Moment local unix format="DD.MM.YYYY" add={{ days: 1 }}>{usedVouchers.availableUntil}</Moment>
                        </div>

                    <Divider/>
                    <Button.Group className="voucher-buttons">

                        <a href={usedVouchers.partnerData.url} target="_blank" rel="noopener noreferrer" style={{width: "100%"}}>
                            <Button color="red" fluid>
                                Zum Anbieter
                            </Button>
                        </a>

                </Button.Group>
            </Card>
        </Grid.Column>
    ))}
</Grid>

                </Container>
            )
        }
    }
}

function mapStateToProps(state, ownProps){
    const { account } = state

    const { isFetching, vouchers, partners, requirements, voucherMessage, messagePosition } = account['voucher']
    || {
        isFetching: true,
        vouchers: {},
        partners: {},
        requirements: false
    }

    let partnersList = [];
    let vouchersList = [];

    if (partners) {
        for (let [key, value] of Object.entries(partners)) {
            partnersList.push(value);
        }
    }

    if(vouchers){
        // for (let [key, value] of Object.entries(partners)) {
        //     vouchersList.push(value);
        // }
        for (let value of Object.values(vouchers)) {
            if (value) {
                var v = {...value};
                v['partnerData'] = partnersList.filter(partner => partner.id == v.partner)[0];
                //partnersList = partnersList.filter(partner => partner.id != v.partner); //TODO: Show Voucher even if user has already one
                vouchersList.push(v);
            }
        }
    }

    return {
        isFetching,
        partnersList,
        vouchersList,
        requirements,
        voucherMessage,
        messagePosition,
    }
}

export default connect(mapStateToProps)(Voucher)
