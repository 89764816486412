import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Grid, Container, Image, Header, Button, Divider, Responsive, Placeholder, Message } from 'semantic-ui-react';
import { Link, withRouter } from 'react-router-dom';
import { InfiniteScroll } from '../../../components/helpers/InfiniteScroll';
import NumericLabel from 'react-pretty-numbers'
import Truncate from 'react-truncate';
import Helmet from 'react-helmet';
import {fetchStoryListIfNeeded, fetchStoryList} from '../../../actions/story'
import config from '../../../config/config'
import ProgressiveImager from '../../../components/helpers/progressive-imager';
import 'moment/locale/de'
import 'moment-timezone';

/* ICONS */
import clockIcon from '../../../images/icons/frontend/icon-clock-circle.svg';
import heartIcon from '../../../images/icons/frontend/icon-heart.svg';
import videoIcon from '../../../images/icons/frontend/icon-video.svg';
import picturesIcon from '../../../images/icons/frontend/icon-picture-light.svg'


class ListItemStorys extends Component {

    constructor(props) {
        super(props);

        this.loadMore = this.loadMore.bind(this);
        this.loadMoreForce = this.loadMoreForce.bind(this);
    }

    componentDidMount = () => {
        const { dispatch, match } = this.props;
        dispatch(fetchStoryListIfNeeded(match.params.id, 8));
    }

    loadMore() {
        const { dispatch, page, isFailed, match } = this.props;

        if (!isFailed) {
            dispatch(fetchStoryList(match.params.id, (page ? page+1 : 1), 8));
        }
    }

    loadMoreForce() {
        const { dispatch, page, match } = this.props;

        dispatch(fetchStoryList(match.params.id));
    }

    render() {

        const { projectId, listitems, isFetching, isFailed, props, hasMore, project, td, vehicle, brand } = this.props;

        return (

            <>
            {isFetching && listitems.length == 0 &&

                this.placeholder()

            }

            {!isFetching && isFailed && listitems && listitems.length == 0 ?
                <Container>
                    <Helmet>
                        <meta name="description" content={"Leider gibt es zum Oldtimer "+brand.name+" "+vehicle.name+" ("+td.year+") von "+project.userData.name+" noch keine Storys. Es gibt aber viele weitere Oldtimer auf Vetero."} />
                    </Helmet>

                    <Message color="teal">
                        {props.item.userData.name} hat noch keine Story veröffentlicht.
                    </Message>
                </Container>

            :

            !isFetching && isFailed &&
                <Container>
                    <Message color="red">
                        Ein Fehler ist aufgetreten.
                    </Message>
                </Container>
            }

            {listitems && listitems.length > 0 &&

            <section className="project-stories">
                <Helmet>
                    <meta name="description" content={"Hier findest Du spannende Storys zur Restaurierung des Oldtimer "+brand.name+" "+vehicle.name+" ("+td.year+") von "+project.userData.name+". Hast Du auch einen Oldtimer? Dann ist Vetero genau das Richtige für Dich."} />
                </Helmet>

                <Grid columns="equal">
                    {listitems.filter(story => story.item.active === true).map((story, index) =>

                        <Grid.Row className="story-card-item">
                            <Grid.Column className="story-card-item-image" mobile={16} tablet={8} computer={8}>
                                <Link to={`/oldtimer/${props.item.urlslug}/${story.item.urlslug}/${story.item.id}`}>
                                    <div className="project-storys-img">
                                        <ProgressiveImager
                                            loadingClassName='story-card-item-loading-img'
                                            src={config.getImageUrl('main', story.item.mediamainPath)}
                                            alt={props.item.title+' – '+story.item.title}
                                            />
                                        <Responsive minWidth={Responsive.onlyTablet.minWidth} className="social-buttons">
                                            <Container>
                                                <Grid columns="equal">
                                                    <Grid.Column>
                                                        <span className="icons-social" >
                                                            <Image alt="Clock Icon" title="Arbeitszeiten" src={clockIcon} className="icon-social" />
                                                            {props.item.showWorkinghours ? 
                                                            
                                                                story.item.sumWorkinghours ?
                                                                <>
                                                                    <NumericLabel params={config.PRETTY_NUMERIC_CONFIG}>{story.item.sumWorkinghours}</NumericLabel>
                                                                    <span>h</span>
                                                                </>
                                                                    :
                                                                    '0h'
                                                                :
                                                                
                                                                '-'
                                                            }
                                                        </span>
                                                    </Grid.Column>
                                                    <Grid.Column>
                                                        <span className="icons-social" >
                                                            <Image alt="Likes Icon" title="Gefällt mir Angaben" src={heartIcon} className="icon-social" />
                                                            {story.item.countWelldone ?
                                                                <NumericLabel style={{display: "inline-block"}} params={config.PRETTY_CONFIG}>{story.item.countWelldone}</NumericLabel>
                                                                :
                                                                '–'
                                                            }
                                                        </span>
                                                    </Grid.Column>
                                                    <Grid.Column>
                                                        <span className="icons-social" >
                                                            <Image alt="Video Icon" title="Videos" src={videoIcon} className="icon-social" />
                                                            {story.item.vidCount ? 
                                                                <NumericLabel style={{display: "inline-block"}} params={config.PRETTY_CONFIG}>{story.item.vidCount}</NumericLabel>
                                                                :
                                                                '–'
                                                            }

                                                        </span>
                                                    </Grid.Column>
                                                    <Grid.Column>
                                                        <span className="icons-social" >
                                                            <Image alt="Images Icon" title="Bilder" src={picturesIcon} className="icon-social" />
                                                            {story.item.imgCount ? 
                                                                <NumericLabel style={{display: "inline-block"}} params={config.PRETTY_CONFIG}>{story.item.imgCount}</NumericLabel>
                                                                :
                                                                '–'
                                                            }
                                                        </span>
                                                    </Grid.Column>
                                                </Grid>
                                            </Container>
                                        </Responsive>
                                    </div>
                                </Link>
                            </Grid.Column>

                            <Grid.Column className="story-card-item-content" mobile={16} tablet={8} computer={8}>
                                {/* TODO: Add story update date */}
                                {/* <div className="story-card-item-meta">Stand: <Moment format="DD.MM.YYYY">{story.lastUpdated}</Moment></div> */}
                                <Header className="story-card-item-header" style={{textTransform: 'unset'}} as="h2">{story.item.title}</Header>
                                <div className="story-card-item-description">
                                    {story.item.description ? 
                                        <>
                                            <Responsive {...Responsive.onlyMobile} as={Truncate} lines={3}>
                                                {story.item.description}
                                            </Responsive>
                                            <Responsive {...Responsive.onlyTablet} as={Truncate} lines={4}>
                                                {story.item.description}
                                            </Responsive>
                                            <Responsive minWidth={Responsive.onlyComputer.minWidth} as={Truncate} lines={7}>
                                                {story.item.description}
                                            </Responsive>
                                        </>
                                        :
                                        `${props.item.userData.name} hat noch keine Beschreibung für diese Story preisgegeben.`
                                        
                                    }
                                </div>
                                <Divider hidden/>
                                <Grid verticalAlign='bottom' centered className="story-card-item-button">
                                    <Grid.Column width={16} textAlign="center">
                                        <Button color="red" as={Link} to={`/oldtimer/${props.item.urlslug}/${story.item.urlslug}/${story.item.id}`}>
                                            Projektstory anschauen
                                        </Button>
                                    </Grid.Column>
                                </Grid>

                                <Responsive {...Responsive.onlyMobile} className="social-buttons">
                                    <Container>
                                        <Grid columns="equal">
                                            <Grid.Column>
                                                <span className="icons-social" >
                                                    <Image alt="Clock Icon" title="Arbeitszeiten" src={clockIcon} className="icon-social" />
                                                    <span className="social-amount">{story.item.sumWorkinghours}</span>
                                                </span>
                                            </Grid.Column>
                                            <Grid.Column>
                                                <span className="icons-social" >
                                                    <Image alt="Likes Icon" title="Gefällt mir Angaben" src={heartIcon} className="icon-social" />
                                                    <span className="social-amount">{story.item.countWelldone}</span>
                                                </span>
                                            </Grid.Column>
                                            <Grid.Column>
                                                <span className="icons-social" >
                                                    <Image alt="Video Icon" title="Videos" src={videoIcon} className="icon-social" />
                                                    <span className="social-amount">{story.item.vidCount}</span>
                                                </span>
                                            </Grid.Column>
                                            <Grid.Column>
                                                <span className="icons-social" >
                                                    <Image alt="Images Icon" title="Bilder" src={picturesIcon} className="icon-social" />
                                                    <span className="social-amount">{story.item.imgCount}</span>
                                                </span>
                                            </Grid.Column>
                                        </Grid>
                                    </Container>
                                </Responsive>

                            </Grid.Column>
                        </Grid.Row>

                    )}
                </Grid>

            </section>
            }

            {isFetching && hasMore &&
                this.placeholder()
            }

            <InfiniteScroll
                onLoadMore={this.loadMore}
                loading={isFetching}
                hasNextPage={hasMore}
            />

            </>

        )
    }
    placeholder(){
        return(
            <section className="project-stories">

                <Grid columns="equal">
                    <Grid.Row className="story-card-item-placeholder">
                        <Grid.Column className="story-card-item-image" mobile={16} tablet={8} computer={8}>
                            <div className="project-storys-img">
                                <Placeholder>
                                    <Placeholder.Image/>
                                </Placeholder>
                            </div>
                        </Grid.Column>
                        <Grid.Column className="story-card-item-content" mobile={16} tablet={8} computer={8}>
                            <Placeholder className="story-card-item-plcaholder-header" >
                                <Placeholder.Line length="full" className="story-card-item-header"></Placeholder.Line>
                                <Placeholder.Line length="medium" className="story-card-item-header"></Placeholder.Line>
                            </Placeholder>
                            <Placeholder >
                                <Placeholder.Line length="full"></Placeholder.Line>
                                <Placeholder.Line length="full"></Placeholder.Line>
                                <Placeholder.Line length="medium"></Placeholder.Line>
                                <Responsive minWidth={Responsive.onlyTablet.minWidth} as={Placeholder.Line} length='long'></Responsive>
                                <Responsive minWidth={Responsive.onlyTablet.minWidth} as={Placeholder.Line} length='full'></Responsive>
                                <Responsive minWidth={Responsive.onlyComputer.minWidth} as={Placeholder.Line} length='medium'></Responsive>
                            </Placeholder>

                            <Grid verticalAlign='bottom' centered className="story-card-item-button">
                                <Grid.Column width={16} textAlign="center">
                                    <Button disabled>
                                        Projektstory anschauen
                                    </Button>
                                </Grid.Column>
                            </Grid>

                            <Responsive {...Responsive.onlyMobile} className="social-buttons">
                                <Container>
                                    <Grid columns="equal">
                                        <Grid.Column>
                                            <span className="icons-social" >
                                                <Image src={clockIcon} className="icon-social" />
                                                <span className="social-amount">-</span>
                                            </span>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <span className="icons-social" >
                                                <Image src={heartIcon} className="icon-social" />
                                                <span className="social-amount">-</span>
                                            </span>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <span className="icons-social" >
                                                <Image src={videoIcon} className="icon-social" />
                                                <span className="social-amount">-</span>
                                            </span>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <span className="icons-social" >
                                                <Image src={picturesIcon} className="icon-social" />
                                                    <span className="social-amount">-</span>
                                            </span>
                                        </Grid.Column>
                                    </Grid>
                                </Container>
                            </Responsive>

                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                </section>

        )
    }
}

function mapStateToProps(state, ownProps){
    const { projectById, storyById, listByEntity } = state;

    const { item: project, td, vehicle, brand } = projectById[
        ownProps.props.match.params.id
    ] || {
        td: {},
        vehicle: {},
        brand: {},
    }

    const { list, isFetching, isFailed, hasMore, page  } = listByEntity[
        ownProps.props.match.params.id+'::story'
    ] || {
        list: {},
        isFetching: true,
        isFailed: false,
    }

    var listitems = [];

    if (list) {
        for (let [key, value] of Object.entries(list)) {
            if (storyById[value] && !storyById[value].isDeleted) {
                var listitem = {...storyById[value]};
                var contents = [];
                for (var k in listitem.contents) {
                    contents.push(listitem.contents[k]);
                };
                listitem.contents = [].concat(contents).sort((a, b) => a.position > b.position ? 1 : -1);
                listitems.push(listitem);
            }
        }
        listitems = [].concat(listitems).sort((a, b) => a.item.position > b.item.position ? 1 : -1);
    }

    return {
        listitems,
        isFailed,
        isFetching,
        hasMore,
        page,
        project,
        td,
        vehicle,
        brand,
    }
}

export default connect(mapStateToProps)(withRouter(ListItemStorys));
