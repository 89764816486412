import fetch from 'cross-fetch' // TODO: cross-fetch still needed? – see api middleware
import config from '../config/config'
import { createAction } from 'redux-api-middleware';

export const GET_USER_INPUT = "GET_USER_INPUT";
export const GET_AUTOSUGGESTION_BRAND = "GET_AUTOSUGGESTION_BRAND";
export const GET_AUTOSUGGESTION_MODEL = "GET_AUTOSUGGESTION_MODEL";
export const GET_AUTOSUGGESTION_VARIANT = "GET_AUTOSUGGESTION_VARIANT";
export const SELECTED_BRAND = "SELECTED_BRAND";
export const SELECTED_MODEL = "SELECTED_MODEL";
export const SELECTED_VARIANT = "SELECTED_VARIANT";
export const CLEAR_SELECTED_FIELDS = "CLEAR_SELECTED_FIELDS";
export const TOGGLE_DROPDOWN = "TOGGLE_DROPDOWN";

export const API_AUTOCOMPLETE_REQUEST = "API_AUTOCOMPLETE_REQUEST";
export const API_AUTOCOMPLETE_SUCCESS = "API_AUTOCOMPLETE_SUCCESS";
export const API_AUTOCOMPLETE_FAILURE = "API_AUTOCOMPLETE_FAILURE";
export const RESET_AUTOCOMPLETE = "RESET_AUTOCOMPLETE";


export function resetAutosuggestion(key) {
    return {
        type: RESET_AUTOCOMPLETE,
        meta: {
            key,
        }
    }
}
export function autosuggestion(key, value) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/autocomplete/${key}/${value}`,
        method: 'GET',
        types: [
            {type: API_AUTOCOMPLETE_REQUEST, meta: {key}},
            {type: API_AUTOCOMPLETE_SUCCESS, meta: {key}},
            {type: API_AUTOCOMPLETE_FAILURE, meta: {key}},
        ]
    })
}

//TODO: Use api middleware

export function fetchAutosuggestionBrand(input) {
    return dispatch => {
        fetch(`${config.API_HOST}/v1/autocomplete/brand/` + input)
            .then(response => response.json())
            .then(json => {
                dispatch(getAutosuggestionBrand(json))
            })
    }
}

export function fetchAutosuggestionModel(input) {
    return dispatch => {
        fetch(`${config.API_HOST}/v1/autocomplete/vehicle/` + input)
            .then(response => response.json())
            .then(json => {
                dispatch(getAutosuggestionModel(json))
            })
    }
}

export function fetchAutosuggestionVariant(input) {
    return dispatch => {
        fetch(`${config.API_HOST}/v1/autocomplete/variant/` + input)
            .then(response => response.json())
            .then(json => {
                dispatch(getAutosuggestionVariant(json))
            })
    }
}

export function getAutosuggestionBrand(suggestion){
    return {
        type: GET_AUTOSUGGESTION_BRAND,
        component: "SearchModule",
        data: {
            brand: suggestion
        }
    }
}

export function getAutosuggestionModel(suggestion){
    return {
        type: GET_AUTOSUGGESTION_MODEL,
        component: "SearchModule",
        data: {
            model: suggestion
        }
    }
}

export function getAutosuggestionVariant(suggestion){
    return {
        type: GET_AUTOSUGGESTION_VARIANT,
        component: "SearchModule",
        data: {
            variant: suggestion
        }
    }
}

//Get user input value
export function handleUserInput(input){
    return {
        type: GET_USER_INPUT,
        component: "SearchModule",
        data: {
            searchModulUserInput: input
        }
    }
}

//Select option
export function selectBrand(option){
    return {
        type: SELECTED_BRAND,
        component: "SearchModule",
        data: {
            selectedBrand: option
        }
    }
}

export function selectModel(option){
    return {
        type: SELECTED_MODEL,
        component: "SearchModule",
        data: {
            selectedModel: option
        }
    }
}

export function selectVariant(option){
    return {
        type: SELECTED_VARIANT,
        component: "SearchModule",
        data: {
            selectedVariant: option
        }
    }
}

export function clearSelectedFields(){
    return {
        type: CLEAR_SELECTED_FIELDS,
        component: "SearchModule",
        data: {
            selectedModel: '',
            selectedVariant: '',
            selectedBrand: ''
        }
    }
}

//Handle dropdown menu
export function toggleDropdown(brand, model, variant){
    return {
        type: TOGGLE_DROPDOWN,
        component: "SearchModule",
        data: {
            brandMenu: brand,
            modelMenu: model,
            variantMenu: variant
        }
    }
}
