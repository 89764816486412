import React, { Component } from 'react';
import {
    Header,
    Button,
    Modal,
    Responsive,
    Grid,
    Divider,
    Image,
    Icon,
 } from 'semantic-ui-react';
import 'moment/locale/de'
import 'moment-timezone';
import * as moment from "moment";
import {changeProject, updateParent, fetchParent, CHANGE_PROJECT_NODIRTY} from '../../../actions/project'
import {changeStory, updateStory} from '../../../actions/story'
import {changePart} from '../../../actions/part'
import {changeBill} from '../../../actions/bill'
import {changeExpense} from '../../../actions/expense'
import {newProject, changeAccount} from '../../../actions/account'
import {newFeedpost} from '../../../actions/notification'
import {changeExpenditure} from '../../../actions/expenditure'
import {changeTodo} from '../../../actions/todo'
import {changeAssembly} from '../../../actions/assembly'
import {autosuggestion} from '../../../actions/autosuggestion'
import {newForumThread} from '../../../actions/forumthread'
import config from '../../../config/config'
import ProgressiveImager from '../../../components/helpers/progressive-imager';

import documentIcon from '../../../images/icons/icon-document.svg';
import { newProfessional } from '../../../actions/professional';
import { changeProfessional } from './../../../actions/professional';


export default class BeParent extends Component {

    constructor(props) {
        super(props)

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeInput = this.handleChangeInput.bind(this);
        this.handleChangeInputAc = this.handleChangeInputAc.bind(this);
        this.handleChangeInputAlt = this.handleChangeInputAlt.bind(this);
        this.handleChangeDateAutocomplete = this.handleChangeDateAutocomplete.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
        this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
        this.handleChangeNumericInput = this.handleChangeNumericInput.bind(this);
        this.submitChange = this.submitChange.bind(this);
        this.submitChangeStory = this.submitChangeStory.bind(this);
        this.revertChange = this.revertChange.bind(this);
        this.confirmModal = this.confirmModal.bind(this);
        this.mediaPreview = this.mediaPreview.bind(this);
        this.setDimensions = this.setDimensions.bind(this);
    }

    setDimensions = () => {
        // Set width of window
        this.setState({windowWidth: window.innerWidth});
    }

    handleChangeDate = (event, {name, value}) => {
        this.handleChange(name, moment(value, "YYYY-MM-DD").format('X'));
    }

    handleChangeNumericInput(e, element){
        this.handleChange(element.name, element.value.substring(0, e.target.maxLength));
    }

    allowOnlyNumbers(e){

        if(e.which == 190 || e.which == 188 || e.which == 189){
            e.preventDefault()
            e.stopPropagation();
        }
        else {
            return true;
        }
    }

    handleChangeDateAutocomplete(e){

        if(e.value.length === 4 ) {
            return false;
        }
        else if(e.value.length == 3){
            if(e.value >= 100 && e.value < 210){
                e.value = e.value + 0
            }
            else if(e.value < 100 || e.value > 210) {
                this.setState({
                    validationYear: {
                        error: true,
                        message: "Das eingegebene Datum ist falsch. Überprüfe deine Angaben."
                    }
                })
            }
            else {
                this.setState({
                    validationYear: {
                        error: false,
                        message: ""
                    }
                })
            }
        }
        else if(e.value.length == 2){
            e.value = 19 + e.value
        }
        else if(e.value.length == 1){
            e.value = 190 + e.value;
        }

        this.handleChange(e.name, e.value.slice(0, e.maxLength));
    }

    handleChangeInput(e, element) {
        this.handleChange(element.name, element.value);
    }

    handleChangeInputAc(e) {
        const element = e.currentTarget;
        var name = element.name.split('.');
        var key = name[name.length - 1];

        this.props.dispatch(autosuggestion(key, element.value));
        this.handleChange(element.name, element.value);
    }

    handleChangeInputAlt(e) {
        const element = e.currentTarget;
        this.handleChange(element.name, element.value);
    }

    handleChangeSelect(opt, element) {
        this.handleChange(element.name, opt.value);
    }

    handleChangeAutosuggestionSelect(name, opt) {
        this.handleChange(name, opt.value);
    }

    handleChangeCheckbox(e, element) {
        this.handleChange(element.name, element.checked);
    }

    handleChangeFloatNumber(e, element){
        let val = element.value && parseFloat(element.value.toString().replace(/,/g, '.'));
        this.handleChange(element.name, val);
    }

    handleChange(name, value) {
        name = name.split('.');
        var entity = name[0];

        const { dispatch, content, listitems, item, tdo, td, vehicle, brand, manufacturer, parts, bills, expenses, storyItem, inputProject, inputFeedpost, expenditures, todos, assemblys, inputForumThread, inputProfessional, professional } = this.props;

        if (entity === 'project') {
            var field = name[1];

            item[field] = value;
            dispatch(changeProject(item, tdo, td, vehicle, brand, manufacturer))
        }
        else if (entity === 'tdo') {
            var field = name[1];

            tdo.input[field] = value;
            dispatch(changeProject(item, tdo, td, vehicle, brand, manufacturer, CHANGE_PROJECT_NODIRTY))
        }
        else if (entity === 'td') {
            var field = name[1];

            td.input[field] = value;
            dispatch(changeProject(item, tdo, td, vehicle, brand, manufacturer, CHANGE_PROJECT_NODIRTY))
        }
        else if (entity === 'vehicle') {
            var field = name[1];

            vehicle.input[field] = value;
            dispatch(changeProject(item, tdo, td, vehicle, brand, manufacturer, CHANGE_PROJECT_NODIRTY))
        }
        else if (entity === 'brand') {
            var field = name[1];

            brand.input[field] = value;
            dispatch(changeProject(item, tdo, td, vehicle, brand, manufacturer, CHANGE_PROJECT_NODIRTY))
        }
        else if (entity === 'manufacturer') {
            var field = name[1];

            manufacturer.input[field] = value;
            dispatch(changeProject(item, tdo, td, vehicle, brand, manufacturer, CHANGE_PROJECT_NODIRTY))
        }
        else if (entity === 'story') {
            var id = name[1];
            var field = name[2];
            var listitem = null;
            let updatedContents = {}

            listitems.map((li) => {
                if (li.storyItem && li.storyItem.id === parseInt(id)) {
                    listitem = {...li};
                }
                else if (li.item && li.item.id === parseInt(id)) {
                    listitem = {...li};
                    listitem.storyItem = listitem.item;
                }
            });

            if (field === 'content') {
                var cid = name[3];
                var cfield = name[4];

                for (let [k, c] of Object.entries(listitem.contents)) {
                    if (c.id === parseInt(cid)) {

                        if (cfield === 'gallerymedia') {
                            var gmid = name[5];
                            var gmfield = name[6];

                            for (let [mk, mv] of Object.entries(listitem.contents[k].input['media'])) {
                                if (mv.id === parseInt(gmid)) {
                                    listitem.contents[k].input['media'][mk][gmfield] = value;
                                }
                            };
                        }
                        else {
                            listitem.contents[k].input[cfield] = value;
                        }
                        
                    }
                }; 
            }
            else {
                listitem.storyItem[field] = value;
            }
                        
            dispatch(changeStory(listitem.storyItem, listitem.contents));
        }
        else if (entity === 'part') {
            var id = name[1];
            var field = name[2];
            var part = null;

            parts.items.map((p) => {
                if (p.id === parseInt(id)) {
                    part = p;
                }
            });

            if (part) {
                part.input[field] = value;

                dispatch(changePart(part));
            }
        }
        else if (entity === 'bill') {
            var id = name[1];
            var field = name[2];
            var bill = null;

            bills.items.map((p) => {
                if (p.id === parseInt(id)) {
                    bill = p;
                }
            });

            if (bill) {
                bill.input[field] = value;

                dispatch(changeBill(bill));
            }
        }
        else if (entity === 'expense') {
            var id = name[1];
            var field = name[2];
            var expense = null;

            expenses.items.map((p) => {
                if (p.id === parseInt(id)) {
                    expense = p;
                }
            });

            if (expense) {
                expense.input[field] = value;

                dispatch(changeExpense(expense));
            }
        }
        else if (entity === 'expenditure') {
            var id = name[1];
            var field = name[2];
            var expenditure = null;

            expenditures.map((p) => {
                if (p.id === parseInt(id)) {
                    expenditure = p;
                }
            });

            if (expenditure) {
                expenditure.input[field] = value;

                dispatch(changeExpenditure(expenditure));
            }
        }
        else if (entity === 'professional') {
            var id = name[1];
            var field = name[3];
            var subfield = name[4];
            
            if(field === "scopes" || field === "types"){
                let arr = professional.input[field] ? professional.input[field] : [];                
                if(value){
                    let updatedArray = [];
                    updatedArray.push(subfield)
                    arr.map(item => updatedArray.push(item));
                    professional.input[field] = updatedArray;
                }
                else {
                    let updatedArray = arr.filter(item => item !== subfield)
                    professional.input[field] = updatedArray;
                }
                
                return dispatch(changeProfessional(id, professional.input));

            }
            
            professional['input'][field] = value
            dispatch(changeProfessional(id, professional.input));

        }
        else if (entity === 'inputProject') {
            var field = name[1];

            inputProject[field] = value;

            dispatch(newProject(inputProject));
        }
        else if (entity === 'inputFeedpost') {
            var field = name[1];

            inputFeedpost[field] = value;

            dispatch(newFeedpost(inputFeedpost));
        }
        else if (entity === 'account') {
            var field = name[1];

            item[field] = value;

            dispatch(changeAccount(item));
        }
        else if (entity === 'todo') {
            var id = name[1];
            var field = name[2];
            var todo = null;

            todos.map((p) => {
                if (p.id === parseInt(id)) {
                    todo = p;
                }
            });

            if (todo) {
                todo.input[field] = value;

                dispatch(changeTodo(todo));
            }
        }
        else if (entity === 'assembly') {
            var id = name[1];
            var field = name[2];
            var assembly = null;

            assemblys.map((p) => {
                if (p.id === parseInt(id)) {
                    assembly = p;
                }
            });

            if (assembly) {
                assembly.input[field] = value;

                dispatch(changeAssembly(assembly));
            }
        }
        else if (entity === 'inputForumThread') {
            var field = name[1];
            var subfield = name[2];
   
            var update = {
                [field]: {
                    ...inputForumThread[field],
                    [subfield]: value,
                },
            }

            dispatch(newForumThread(update));
            
        }

       

    }

    deleteStoryContent(value, keepDirty) {
        const { content, listitems, dispatch, storyItem, contentList } = this.props;

        dispatch(changeStory(storyItem, value, keepDirty));
    }

    submitChange(e, element) {
        const { item, dispatch } = this.props
        dispatch(updateParent(item.id));
    }

    submitChangeStory(id){
        const { dispatch } = this.props
        dispatch(updateStory(id));
    }

    revertChange(e, element) {
        const { item, dispatch } = this.props
        dispatch(fetchParent(item.id))
    }

    confirmModal(closeCallback){

        const { confirmModal } = this.state;

        return (
            <>
                <div id="modal-wrapper" style={{zIndex: 99999}} />
                <Modal
                    basic
                    open={confirmModal.isOpen}
                    size='small'
                    className="modal-confirm-leave"
                    mountNode={document.getElementsByClassName('slide-pane')[0] ? document.body : document.getElementById('modal-wrapper')}
                >
                    <Header as="h2">Bist du sicher?</Header>

                    <Modal.Content>
                        Willst du das Bearbeiten wirklich ohne Speichern verlassen? Deine Angaben werden verworfen.
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            color="red"
                            onClick={closeCallback}
                        >
                            Verwerfen
                        </Button>
                        <Button color="green" onClick={() => this.setState({confirmModal: { isOpen: false }})}>Zurück</Button>
                    </Modal.Actions>
                </Modal>
            </>
        )
    }

    mediaPreview(media) {
        if (media && media.length > 0) {
            var images = media.filter(m => (m.type === config.FILE_IMAGE || m.type === config.FILE_VIDEO)).sort((a, b) => (a.type > b.type || a.id < b.id) ? 1 : -1);
            var documents = media.filter(m => m.type === config.FILE_PDF);

            var base = (this.state.windowWidth <= Responsive.onlyTablet.maxWidth ? 3 : 5);
            var basecols = base + 1;

            if (images.length === base + 1) {
                base = base + 1;
            }

            if (documents.length > 0) {
                base = base - 1;
            }

            return (
                <Grid.Column style={{paddingTop: 0}} width={16} className="parts-gallery-preview-wrapper">
                    <Divider style={{marginTop: 0}} />
                    <Grid columns={basecols} className="parts-gallery-preview">
                        {images.slice(0, base).map((m) => (
                            <Grid.Column>
                                <ProgressiveImager className="content-galerie-wrapper" src={config.getImageUrl('preview', (m.previewPath ? m.previewPath : m.path))} style={{width: '100%'}} />
                                {m.type === config.FILE_VIDEO &&
                                    <Icon name="play circle outline" inverted/>
                                }
                            </Grid.Column>
                        ))}
                        {images.length > base &&
                            /* Show more images  */
                            <Grid.Column>
                                <div className="more-images">
                                    <span>+{media.length - base}</span>
                                </div>
                            </Grid.Column>
                        }
                        {documents.length > 0 &&
                            /* Show documents  */
                            <Grid.Column>
                                <div className="more-images documents">
                                    <Image
                                        src={documentIcon}
                                        className="custom-icon"
                                        style={{width: '50%', height: 'auto', margin: '7.5% auto'}}
                                    />
                                    <span>{documents.length}</span>
                                </div>
                            </Grid.Column>
                        }
                    </Grid>
                </Grid.Column>
            );
        }

        return null;
    }
}
