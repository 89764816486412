import React, { Component } from "react";
import { Container, Divider, Message, Placeholder, Header, Button, Pagination, Grid, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import ThreadList from '../../components/forum/threadList';
import {footerActiveItem, handleContainer, handleHeaderItems, setRuntime} from "../../actions/global";
import {fetchThreadListIfNeeded, fetchThreadList, resetThreadList, checkForUpdatesIfNeeded} from "../../actions/forumthread";
// import Moment from "react-moment";
// import "moment/locale/de";
// import "moment-timezone";
// import ImageWrapper from "../components/helpers/image-wrapper";
// import Truncate from "react-truncate";
import config from "../../config/config";
import { StructuredDataForumListing } from '../../components/StructuredData/StructuredData';
// import Helmet from "react-helmet";
// import ProgressiveImager from "../components/helpers/progressive-imager";
// import NumericLabel from "react-pretty-numbers";
// import { likeStory } from "../actions/story";
// import { likeProject } from "../actions/project"
// import { LivePostMeta } from "./livePost"
//
// /* ICONS */
// import heartIcon from "../images/icons/frontend/icon-heart.svg";
// import commentIcon from "../images/icons/frontend/icon-comment.svg";
// import heartIconActive from "../images/icons/frontend/icon-active-heart.svg";
import ErrorHandler from "../../components/ErrorHandler/ErrorHandler";
import ForumFilter from '../../components/FilterSearch/ForumFilter';
import Account from "../../components/account/Account";
import { InfiniteScroll } from "../../components/helpers/InfiniteScroll";
import MetaTag from "react-meta-tags";

const calendarStrings = {
    lastDay : 'DD.MM.YYYY, LT',
    sameDay : 'DD.MM.YYYY, LT',
    sameElse : 'L, LT',
    nextWeek: 'DD.MM.YYYY, LT',
    lastDay: 'DD.MM.YYYY, LT',
    lastWeek: 'DD.MM.YYYY, LT',
    sameElse : 'DD.MM.YYYY, LT',
}


class ForumOverview extends Component {
    state = {
        loginPopupVisible: false,
        activePage: 1
    };

    constructor(props) {
        super(props);

        this.loadMore = this.loadMore.bind(this);
        this.loadMoreForce = this.loadMoreForce.bind(this);
        this.reloadList = this.reloadList.bind(this);
    }

    componentDidMount() {
        const { dispatch, match } = this.props;
        dispatch(
            handleContainer("forum", "Oldtimer-Forum", null, null, null, null, {
                description:
                "n diesem Forum können Fragen zu allen Fahrzeugen (Zweiräder, Automobile und Nutzfahrzeuge) ab einem Alter von 25 Jahren eingestellt werden. Vom Fahrrad bis zum Sattelschlepper.",
                desctype: "fixed",
            })
        );
        dispatch(checkForUpdatesIfNeeded());
        dispatch(fetchThreadListIfNeeded());
        dispatch(handleHeaderItems("Forum"));
        
        //Set current page
        if(match.params.page){
            this.setState({ activePage: match.params.page })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { dispatch, hasUpdateLastFetch, isFetching, isFailed, threads, options } = this.props;
       
        if(!isFetching && !isFailed && prevProps.threads !== threads && !threads.length) {
            dispatch(fetchThreadList(options));
        }

        if (hasUpdateLastFetch && Date.now() - hasUpdateLastFetch > config.PING_TIMEOUT) {
            dispatch(checkForUpdatesIfNeeded());
        }

    }

    componentWillUnmount() {
        const { dispatch, location, match } = this.props;

        dispatch(
            setRuntime({
                backProject: {
                    page: "fe-forumthread-list",
                    path: this.props.location,
                    scroll: window.pageYOffset,
                },
                backStory: {
                    page: "fe-forumthread-list",
                    path: this.props.location,
                    scroll: window.pageYOffset,
                },
                backUser: {
                    page: "fe-forumthread-list",
                    path: this.props.location,
                    scroll: window.pageYOffset,
                },
                backForumThread: {
                    page: "fe-forumthread-list",
                    path: this.props.location,
                    scroll: window.pageYOffset,
                },
            })
        );
    };

    reloadList() {
        const { dispatch, options } = this.props;
        dispatch(resetThreadList());
        dispatch(fetchThreadList(options));
    };

    // handleFollowThread = (id, value) => {
    //   const { dispatch, isThreadFollowing } = this.props;
    //
    //   if (!isThreadFollowing) {
    //     dispatch(followThread(id, value));
    //   }
    // };

    handlePageChange = (e, {activePage}) => {
        const { dispatch, match, page, history } = this.props;
        this.setState({ activePage })

        dispatch(fetchThreadList([], activePage));

        //Handle URL 
        history.push(`/forum/page/${activePage}`)
    }

    loadMore = () => {
        const { dispatch, options, page, isFailed } = this.props;
        
        if (!isFailed) {
            dispatch(fetchThreadList(Object.values(options).length > 0 ? options : {}, (page ? page+1 : 1)));
        }
    }

    loadMoreForce = () => {
        const { dispatch, options, page } = this.props;

        dispatch(fetchThreadList(Object.values(options).length > 0 ? options : {}, (page ? page+1 : 1)));
    }

    render() {

        const { item } = this.props;
        const { loginPopupVisible } = this.state;

        return (
            <>
                <Container text className="forum-overview">
                    <Header as="h1">Oldtimer-Forum</Header>
                    <Divider/>
                    <div className="forum-description">
                        In diesem Forum können Fragen zu allen Fahrzeugen (Zweiräder, Automobile und Nutzfahrzeuge) ab einem Alter von 25 Jahren eingestellt werden. 
                        Vom Fahrrad bis zum Sattelschlepper.
                    </div>
                    {item && item.id ? 
                        <Button as={Link} color="red" to="/forum/new">
                            Frage im Forum stellen
                        </Button>
                         : 
                        <Button
                            color="red"
                            onClick={() => this.setState({ loginPopupVisible: true })}
                        >
                            Jetzt kostenlos anmelden
                        </Button>
                    }
                </Container>

                <Account
                    loginPopupVisible={loginPopupVisible}
                    loginPopupClose={() => this.setState({ loginPopupVisible: false })}
                />

                {/* {this.renderFilter()} */}
                {this.renderList()}
            </>
        );
    }

    renderFilter() {
        const { item } = this.props;

        return (
            <ForumFilter/>
        );
    }

    renderList() {
        const { isFetching, isFailed, threads, hasMore, hasUpdate, options } = this.props;
        const { activePage } = this.state;

        if (isFetching && (!threads || !threads.length)) {
            return (
                <Container text className="forum-threads">
                    <ThreadList isFetching/>
                </Container>
            );
        }
        else if (!threads || !threads.length) {
            return (
                <Container text>
                    <Divider hidden/>
                    <Message color="teal">
                        <Icon name="discussions" size="big"/>
                        Es wurde keine Forumsbeiträge gefunden.
                    </Message>
                </Container>
            );
        }

        else if (isFailed && (!threads || !threads.length)) {
            return (
                <Container text>
                    <ErrorHandler callbackFunction={this.reloadList} />
                </Container>
            );
        }

        return (<>
            <Container text className="forum-threads">

            {hasUpdate && (
                <Grid>
                    <Grid.Column width={16} textAlign="center">
                        <Button
                            onClick={this.reloadList}
                            color="red"
                            basic
                        >
                        Neue Nachrichten laden
                        </Button>
                        <Divider hidden />
                    </Grid.Column>
                </Grid>
            )}

                {isFailed &&
                    <Message negative>Beim Aktualisieren der Daten ist ein Fehler aufgetreten.</Message>
                }
                
                <ThreadList 
                    threads={threads}
                    isFetching={(isFetching && (!threads || !threads.length))}
                />
                
                {/* Insert Schema.org markup */}
                <MetaTag>
                    <script type="application/ld+json">
                        {StructuredDataForumListing(threads)}
                    </script>
                </MetaTag>

               
            </Container>

            <InfiniteScroll
                onLoadMore={this.loadMore}
                loading={isFetching}
                hasNextPage={hasMore}
                />   
        </>);
    }
}

function mapStateToProps(state, ownProps) {
    const { globalByComponent, listByEntity, forumthreadById, account } = state;

    const { item } = account || {
        item: {},
    };

    const { scope, brand, model, categories, forum } = globalByComponent[
        'ForumFilter'
    ] || {
        scope: '',
        brand: '',
        model: '',
        categories: [],
        inputTags: [],
        forum: ''
    }

    const {
        list,
        isFetching,
        lastFetch,
        isFailed,
        hasMore,
        page,
        isThreadFollowing,
        hasUpdate,
        hasUpdateLastFetch,
    } = listByEntity["forumthread"] || {
        list: {},
        isFetching: true,
        isThreadFollowing: false,
    };

   

    const { page: runtimepage, backProject, backStory, backUser } =
    globalByComponent["Runtimedata"] || {};

    var threads = [];
    if (list && Object.keys(list).length) {
        threads = Object.values(list).map(function(thread) {
            return forumthreadById[thread];
        });
    }

    var options = {};

    if(scope) {
        options['forumthread_filter[scope]='] = scope.value;
    }

    if (brand) {
        options['forumthread_filter[brand]'] = brand.value;
    }

    if (model) {
        options['forumthread_filter[vehicle]'] = model.value;
    }

    if(categories){
        if(categories.length > 0) {
            let arr = [];
            categories.map(item => arr.push(item.value));
            options['forumthread_filter[categories]'] = arr
        }

    }

    if (forum) {
        options['forumthread_filter[forum]'] = forum.value;
    }

    return {
        runtimepage,
        backProject,
        backStory,
        backUser,
        isFetching,
        lastFetch,
        isFailed,
        hasMore,
        page,
        threads,
        hasUpdate,
        hasUpdateLastFetch,
        item,
        isThreadFollowing,
        options,

    };
}

export default connect(mapStateToProps)(withRouter(ForumOverview));
