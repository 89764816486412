import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom';
import { Icon, Menu } from 'semantic-ui-react';
import { setRuntime } from '../../actions/global';


class GoBackBtn extends Component {

    constructor(props) {
        super(props);

        this.setListener = this.setListener.bind(this);
    }

    componentDidMount() {
        const { history, location } = this.props;

        history.push(location);
        this.setListener();
    }

    componentDidUpdate(prevProps) {
        const { page, history, location } = this.props;

        if (prevProps.page !== page) {
            this.listener();
            history.push(location);
            this.setListener();
        }
    }

    componentWillUnmount() {
        this.listener();
    }

    setListener() {
        const { dispatch, history } = this.props;
        var data = this.determinePath();

        // Handle browser back
        this.listener = history.listen((loc, action) => {
            if (action === 'POP') {
                dispatch(setRuntime({scrollTo: data.scroll}));
                history.push(data.path);
            }
        });
    }

    render() {
        const { dispatch, scope, className, mobile } = this.props;
        var data = this.determinePath();

        return (

            mobile ?

                <Menu.Item as={Link} className={className} position='left' to={data.path} onClick={() => {
                    dispatch(setRuntime({scrollTo: data.scroll}));
                }}>
                    <Icon name='angle left' size="large"/>
                </Menu.Item >

            : // Tablet / Desktop

                <Link className={className} to={data.path} onClick={() => {
                    dispatch(setRuntime({scrollTo: data.scroll}));
                }}>
                    <Icon name='angle left' size="large"/>
                </Link>
        )
    }

    determinePath() {
        const { scope, page, parent, backProfessionalUserList, backProject, backStory, backUser, backFeedPost, backFunctions, backBlog, backForumThread, backProfessional } = this.props;
        var path = null;
        var scroll = null;

        if (scope === 'backend') {
            // Backend
            // TODO: Implement backend
            console.log(scope);
            if (page.indexOf('professional-edit') === 0) {
                // Project
                if (backProfessionalUserList) {
                    path = this.wholePath(backProfessionalUserList.path);
                    scroll = backProfessionalUserList.scroll;
                }
                else {
                    path = '/backend/professional';
                }
            }
        }

        // Frontend
        else if (page.indexOf('fe-project-id') === 0) {
            // Project
            if (backProject) {
                path = this.wholePath(backProject.path);
                scroll = backProject.scroll;
            }
            else {
                path = '/oldtimer';
            }
        }
        else if (page.indexOf('fe-story-id') === 0) {
            // Story
            if (backStory) {
                path = this.wholePath(backStory.path);
                scroll = backStory.scroll;
            }
            else if (parent) {
                path = this.wholePath(parent.path);
            }
        }
        else if (page.indexOf('fe-user-id') === 0) {
            // User
            if (backUser) {
                path = this.wholePath(backUser.path);
                scroll = backUser.scroll;
            }
        }
        else if (page.indexOf('fe-feedpost-id') === 0) {
            // FeedPost
            if (backFeedPost) {
                path = this.wholePath(backFeedPost.path);
                scroll = backFeedPost.scroll;
            }
        }
        else if (page.indexOf('functions') === 0) {
            if(backFunctions) {
                path = "/functions"
                scroll = backFunctions.scroll
            }
        }
        else if (page.indexOf('blog') === 0) {
            if(backBlog) {
                path = "/oldtimer-blog"
                scroll = backBlog.scroll
            }
            else {
                path = '/oldtimer-blog';
            }
        }
        else if (page.indexOf('fe-forumthread-id') === 0) {
            // FeedPost
            if (backForumThread) {
                path = this.wholePath(backForumThread.path);
                scroll = backForumThread.scroll;
            }
        }
        else if (page.indexOf('fe-forumthread-new') === 0){
            if (backForumThread) {
                path = this.wholePath(backForumThread.path);
                scroll = backForumThread.scroll;
            }
        }
        else if (page.indexOf('professional-overview') === 0){
            if (backProfessional) {
                path = this.wholePath(backProfessional.path);
                scroll = backProfessional.scroll;
            }
        }
        else if (page.indexOf('professional-detail-id') === 0){
            if (backProfessional) {
                path = this.wholePath(backProfessional.path);
                scroll = backProfessional.scroll;
            }
        }
        else if (page.indexOf('professional-admin-detail') === 0){
            if (backProfessional) {
                path = this.wholePath(backProfessional.path);
                scroll = backProfessional.scroll;
            }
        }

        if (!path) {
            path = '/';
            if (scope === 'backend') {
                path = '/backend';
            }
        }

        return {path, scroll};
    }

    wholePath(path) {
        if (typeof path === 'object') {
            return path.pathname + path.search + path.hash;
        }
        return path;
    }
}

function mapStateToProps(state, ownProps) {
    const { globalByComponent } = state;
    const { page, parent, backProfessionalUserList, backProject, backStory, backUser, backFeedPost, backFunctions, backBlog, backForumThread, backProfessional } = globalByComponent[
        'Runtimedata'
    ] || {}

    return {
        page,
        parent,
        backProject,
        backStory,
        backUser,
        backFeedPost,
        backFunctions,
        backBlog,
        backForumThread,
        backProfessional,
        backProfessionalUserList
    }
}

export default connect(mapStateToProps)(withRouter(GoBackBtn));
