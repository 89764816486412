import React, { Component } from 'react'
import { Image, Grid, Divider, Container, Menu, Responsive } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import rektolLogo from '../../../images/logo-rektol-footer.svg';
import veterLogo from '../../../images/logo-vetero.svg';

class Footer extends Component {

    state = {
        footerHeight: null,
    }

    componentDidMount = () => {

        this.setHeightOfFooter();
        window.addEventListener('resize', this.setHeightOfFooter);
        
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.setHeightOfFooter);
        //Reset...
        // document.querySelector('#root').style.paddingBottom =  0;
        // document.querySelector('#root').style.marginBottom = 0;
    }

    setHeightOfFooter = () => {
        var scope = this;
        setTimeout(function(){
            if (document.querySelector('footer')) {
                let heightFooter = document.querySelector('footer').offsetHeight;
               
                scope.setState({
                    footerHeight: heightFooter,
                })

                //Check for mobile view...
                /*if(document.querySelector('.footer-mobile-menu')){
                    let footerMenu = document.querySelector('.footer-mobile-menu').offsetHeight;
                    document.querySelector('#root').style.paddingBottom = (heightFooter + 64) + "px";
                    //Set bottom position of footer element
                    document.querySelector('footer').style.bottom = footerMenu + "px";
                    // document.querySelector('#root').style.marginBottom = footerMenu + "px";
                }
                else {
                    document.querySelector('#root').style.paddingBottom = heightFooter + "px";
                    //Set default
                    document.querySelector('footer').style.bottom = 0;
                    // document.querySelector('#root').style.marginBottom = 0;
                }*/

            }
        }, 0);
    }

    render() {

        let date = new Date();
        let year = date.getFullYear();

        return (
            <footer>
                <Container>
                    <Grid verticalAlign="bottom">

                       <Grid.Column only="tablet computer" width={8}>
                            <div className="vetero-logo-footer">
                                <Image src={veterLogo} alt="Vetero"/>
                                <div className="logo-description">
                                    <p>Das soziale Netzwerk für Oldtimerprojekte!</p>
                                </div>
                            </div>
                            <Responsive as={Divider} style={{marginBottom: 0}} {...Responsive.onlyMobile}/>
                        </Grid.Column>

                        <Grid.Column width={16} only="mobile">
                            <Grid columns="equal" className="partners" verticalAlign="bottom" textAlign="center">
                                <Grid.Column textAlign="center" className="rektol-logo">
                                    <Link to="/partner">
                                        <Image src={rektolLogo} alt="Rektol"/>
                                    </Link>
                                    <span className="partner-text">REKTOL Klassik Öle in Originalrezeptur</span>
                                </Grid.Column>
                            </Grid>
                        </Grid.Column>

                        <Grid.Column width={8} only="tablet computer">
                            <Grid columns="equal" className="partners" verticalAlign="bottom" textAlign="center">
                                <Grid.Column textAlign="center" className="rektol-logo">
                                    <Link to="/partner">
                                        <Image src={rektolLogo} alt="Rektol"/>
                                    </Link>
                                </Grid.Column>
                            </Grid>
                        </Grid.Column>

                    </Grid>

                    <Responsive minWidth={Responsive.onlyTablet.minWidth} as={Divider}/>

                    <Responsive as={Grid} minWidth={Responsive.onlyTablet.minWidth}>
                        <Grid.Column className="footer-menu" tablet={8} computer={8}>
                            <Grid.Column width={16}>
                                <Menu text className="meta-nav">
                                    <Menu.Item
                                        name="Oldtimer-Blog"
                                        as={Link}
                                        to="/oldtimer-blog"
                                    />
                                    <Menu.Item
                                        name="Updates"
                                        as={Link}
                                        to="/updates"
                                    />
                                     <Menu.Item
                                        name="Marken"
                                        as={Link}
                                        to="/brand"
                                    />
                                    <Menu.Item
                                        name="Impressum"
                                        as={Link}
                                        to="/impressum"
                                    />
                                    <Menu.Item
                                        name="Datenschutz"
                                        as={Link}
                                        to="/privacy"
                                    />
                                    <Menu.Item
                                        name="AGB"
                                        as={Link}
                                        to="/conditions"
                                    />
                                    <Menu.Item>
                                        &copy; {year} Vetero OHG
                                    </Menu.Item>
                                </Menu>
                            </Grid.Column>

                        </Grid.Column>

                        <Grid.Column tablet={8} computer={8} textAlign="center" className="partners">
                            <div className="partner-text">REKTOL Klassik Öle in</div>
                            <div className="partner-text">Originalrezeptur</div>
                        </Grid.Column>

                    </Responsive>
                </Container>
            </footer>
        )
    }
}

export default Footer
