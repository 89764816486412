import {
    API_NOTIFICATION_LIST_REQUEST,
    API_NOTIFICATION_LIST_SUCCESS,
    API_NOTIFICATION_LIST_FAILURE,
} from '../actions/notification'
import {
    API_LIKE_STORY_REQUEST,
    API_LIKE_STORY_SUCCESS,
    API_LIKE_STORY_FAILED,
} from '../actions/story'
import {
    API_LIKE_PROJECT_REQUEST,
    API_LIKE_PROJECT_SUCCESS,
    API_LIKE_PROJECT_FAILURE,
} from '../actions/project'
import {
    API_LIKE_FEED_REQUEST,
    API_LIKE_FEED_SUCCESS,
    API_LIKE_FEED_FAILURE,
} from '../actions/notification'
import {
    RESET_STORE,
} from '../actions/account';
import {
    API_POST_COMMENT_SUCCESS
} from '../actions/comments';


export default function notificationById(state = {}, action) {

    try {

        if (action.type === RESET_STORE) {
            return {};
        }

        if(action.type === API_POST_COMMENT_SUCCESS){
            for (var [notificationId, data] of Object.entries(state)) {
                //Story 
                if(action.payload.comment.projectPart){
                    if(action.payload.comment.projectPart === data.item.projectPart){
                        return (
                            state = {
                                ...state,
                                [notificationId]: {
                                    ...state[notificationId],
                                    item: {
                                        ...state[notificationId].item,
                                        projectPartData: {
                                            ...state[notificationId].item.projectPartData,
                                            commentcount: action.payload.commentcount
                                        }
                                    },
                                }
                            }
                        )
                    }
                }
                //Project 
                else if(action.payload.comment.project){
                    if(action.payload.comment.project === data.item.project){
                        return (
                            state = {
                                ...state,
                                [notificationId]: {
                                    ...state[notificationId],
                                    item: {
                                        ...state[notificationId].item,
                                        projectData: {
                                            ...state[notificationId].item.projectData,
                                            commentcount: action.payload.commentcount
                                        }
                                    },
                                }
                            }
                        )
                    }
                }
                //Feed 
                else if(action.payload.comment.feedPost){
                    if(action.payload.comment.feedPost === data.item.feedPost){
                        return (
                            state = {
                                ...state,
                                [notificationId]: {
                                    ...state[notificationId],
                                    item: {
                                        ...state[notificationId].item,
                                        feedPostData: {
                                            ...state[notificationId].item.feedPostData,
                                            commentcount: action.payload.commentcount
                                        }
                                    },
                                }
                            }
                        )
                    }
                }
            }
        }

        if (action.type === API_NOTIFICATION_LIST_SUCCESS) {

            action.payload.list.map((pl) => {
                if (!state[pl.id]) {
                    state = {
                        ...state,
                        [pl.id]: {
                            item: {},
                        }
                    }
                }

                state = {
                    ...state,
                    [pl.id]: {
                        ...state[pl.id],
                        isFetching: false,
                        isFailed: false,
                        isLikingStory: pl.projectPartData ?  false : null,
                        isLikingProject: pl.projectData ?  false : null,
                        item: {
                            ...pl,
                        },
                        lastUpdated: Date.now(),
                    }
                }
            });
        }

        else if (action.type === API_LIKE_PROJECT_REQUEST) {

            for(var key in state){

                if(action.meta.id === state[key].item.project) {
                    let countLike = action.meta.count;

                    countLike = action.meta.like  ? parseInt(countLike) +1 : parseInt(countLike) - 1


                    state = {
                        ...state,
                        [key]: {
                            ...state[key],
                            item: {
                                ...state[key].item,
                                isProjectLiking: true,
                                projectData: {
                                    ...state[key].item.projectData,
                                    countLike,
                                    userLike: action.meta.like ? true : false
                                }
                            }
                        }
                    }

                }
            }

        }

        else if (action.type === API_LIKE_PROJECT_SUCCESS){
            for(var key in state){

                if(action.meta.id === state[key].item.project) {
                    let countLike = action.meta.count;

                    countLike = action.meta.like  ? parseInt(countLike) +1 : parseInt(countLike) - 1


                    state = {
                        ...state,
                        [key]: {
                            ...state[key],
                            item: {
                                ...state[key].item,
                                isProjectLiking: true,
                                projectData: {
                                    ...state[key].item.projectData,
                                    countLike,
                                    userLike: action.meta.like ? true : false
                                }
                            }
                        }
                    }

                }
            }
        }

        else if (action.type === API_LIKE_FEED_REQUEST) {

            for(var key in state){

                if(action.meta.id === state[key].item.feedPost) {
                    let countLike = action.meta.count;

                    countLike = action.meta.like  ? parseInt(countLike) +1 : parseInt(countLike) - 1


                    state = {
                        ...state,
                        [key]: {
                            ...state[key],
                            item: {
                                ...state[key].item,
                                isNewsfeedLiking: true,
                                feedPostData: {
                                    ...state[key].item.feedPostData,
                                    countLike,
                                    userLike: action.meta.like ? true : false
                                }
                            }
                        }
                    }

                }
            }

        }

        else if (action.type === API_LIKE_PROJECT_SUCCESS){
            for(var key in state){

                if(action.meta.id === state[key].item.feedPost) {
                    let countLike = action.meta.count;

                    countLike = action.meta.like  ? parseInt(countLike) +1 : parseInt(countLike) - 1


                    state = {
                        ...state,
                        [key]: {
                            ...state[key],
                            item: {
                                ...state[key].item,
                                isNewsfeedLiking: true,
                                feedPostData: {
                                    ...state[key].item.feedPostData,
                                    countLike,
                                    userLike: action.meta.like ? true : false
                                }
                            }
                        }
                    }

                }
            }
        }

        else if (action.type === API_LIKE_STORY_REQUEST){

            for(var key in state){

                if(action.meta.id === state[key].item.projectPart) {
                    let countWelldone = action.meta.count;

                    if (action.meta.like === action.payload) {
                        countWelldone = action.payload ? parseInt(countWelldone) +1 : parseInt(countWelldone) - 1
                    }
                    countWelldone = action.meta.like  ? parseInt(countWelldone) +1 : parseInt(countWelldone) - 1


                    state = {
                        ...state,
                        [key]: {
                            ...state[key],
                            item: {
                                ...state[key].item,
                                isStoryLiking: true,
                                projectPartData: {
                                    ...state[key].item.projectPartData,
                                    countWelldone,
                                    userWelldone: action.meta.like ? true : false
                                }
                            }
                        }
                    }

                }
            }
        }

        else if (action.type === API_LIKE_STORY_SUCCESS){

            for(var key in state){

                if(action.meta.id === state[key].item.projectPart) {
                    let countWelldone = action.meta.count;
                    if (action.meta.like === action.payload) {
                        countWelldone = action.payload ? parseInt(countWelldone) +1 : parseInt(countWelldone) - 1
                    }

                    state = {
                        ...state,
                        [key]: {
                            ...state[key],
                            item: {
                                ...state[key].item,
                                isStoryLiking: false,
                                projectPartData: {
                                    ...state[key].item.projectPartData,
                                    userWelldone: action.payload,
                                    countWelldone
                                }
                            }
                        }
                    }

                }
            }

            return state;

            // state = {
            //     ...state,
            //     [action.meta.id]: {
            //         ...state[action.meta.id],
            //         isFetching: false,
            //         isFailed: false,
            //         isLikingStory: false,
            //         isLikingProject: null,
            //         item: {
            //             ...state[action.meta.id].item,
            //             userWelldone: action.payload
            //         },
            //         lastUpdated: Date.now(),
            //     }
            // }

        }



        return state;

    } catch (e) {

        console.log(e);
        return {
            ...state,
            fatalError: true,
        };

    }
}
