import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    Button, Container, Grid, Icon, Divider } from "semantic-ui-react";
import {
    footerActiveItem,
    handleContainer,
    handleHeaderItems,
    setRuntime
} from "../../actions/global";
import {Icon as MarkerIcon} from 'leaflet'
import Helmet from 'react-helmet';
import PreviewGallerySecondary from "../../components/PreviewGallery/PreviewGallerySecondary";
import Gallery from '../../components/Gallery/Gallery';
import { ReactSVG } from 'react-svg'
import ProgressiveImager from '../../components/helpers/progressive-imager'
import ImageWrapper from '../../components/helpers/image-wrapper'
import config from '../../config/config';
import markerIconPng from "leaflet/dist/images/marker-icon.png"
// import { MapContainer, Marker, Popup } from 'react-leaflet'
import { MapContainer, TileLayer, Marker } from 'react-leaflet'
import '../Pages.scss';

//Images /
import HeaderImage from "../../components/HeaderImage/HeaderImage";

//ICONS
import webIcon from '../../images/icons/icon-web.svg';
import phoneIcon from '../../images/icons/icon-phone.svg';

import { fetchProfessional } from "../../actions/professional";
import { fetchProjectListIfNeeded } from './../../actions/project';
import DetailPagePlaceholder from './../../components/Placeholder/DetailPagePlaceholder';
const nl2br = require('react-nl2br');

class SpecialistsDetail extends Component {

    state = {
        storyGallery: null,
        storySlide: 0,
        videoLightboxIsOpen: false
    }

  componentDidMount = () => {
    const { dispatch, match } = this.props;
    dispatch(footerActiveItem('footermenu'))
    dispatch(
      handleContainer("secondary", "Spezialisten", null, null, null, null, {
        description: "Erfahre hier mit welchen Funktionen Vetero nicht nur Oldtimer Schrauber unterstützt. Von der Präsentation deiner Oldtimer, den Austausch mit anderen Oldtimer Begeisterten bis zur Auswertung der Arbeitszeit und der Kosten."
      })
      //TODO: Meta description and page title
    );
    dispatch(fetchProfessional(match.params.id));
    dispatch(handleHeaderItems("professional"));
    // Set page
    dispatch(setRuntime({
        page: 'professional-overview'
    }));
  };

  componentWillUnmount = () => {
    const { dispatch, match } = this.props;

    dispatch(setRuntime({
        backProfessional: {page: 'professional-id-'+match.params.id, path: this.props.location},
    }));
}

  render() {
    const { item, isFetching, isFailed } = this.props;
    const { videoLightboxIsOpen } = this.state;

    return (
    <div className="specialists-page">
        <Helmet>
          <title>Vetero - Spezialisten</title>
        </Helmet>

        {isFetching && 
        <>
            <DetailPagePlaceholder/>
        </>
        }

        {!isFetching && !isFailed && item && 
        <>
            <HeaderImage src={item.mediamainPath} overlay detail>
                <div className="detail-info-bar">
                    <Container>
                        <Grid>
                            <Grid.Row verticalAlign="middle">
                                {item.website && 
                                    <Grid.Column mobile={4} tablet={6} computer={6}>
                                        <div className="detail">
                                            {/* <Image src={clockIcon} alt="Icon Clock"/> */}
                                            <ReactSVG src={webIcon} />
                                            <span className="detail-sum">

                                                <a target="_blank" href={item.website.includes("https") ? item.website : "https://" + item.website}>Website</a>
                                            </span>
                                        </div>
                                    </Grid.Column>
                                }

                                {item.phone && 
                                    <Grid.Column mobile={item.website ? 4 : 8} tablet={6} computer={6}>
                                        <div className="detail">
                                            <ReactSVG src={phoneIcon}/>
                                            <span className="detail-sum">{item.phone}</span>
                                        </div>
                                    </Grid.Column>
                                }

                                {item.email && 
                                    <Grid.Column mobile={8} tablet={item.website ? 4 : 10} computer={item.website ? 4 : 10} textAlign="right">
                                        <Button as={'a'} href={`mailto:${item.email}`} color="red">Nachricht senden</Button>
                                    </Grid.Column>
                                }

                            </Grid.Row>
                        </Grid>
                    </Container>
                </div>
            </HeaderImage>

            <div className="specialists-detail-content pt-3 sm-pt-3">
                <Container>
                    <div className="specialist-title mb-1 sm-text-center">
                        <h1>{item.headline}</h1>
                    </div>
                    <div className="specialist-description pr-1">
                        <div className="specialist-text">
                            {nl2br(item.intro)}
                        </div>
                        {/* Logo */}
                        {item.medialogo && item.medialogoPath && 
                        <div className="specialist-logo pl-1 sm-pb-2">
                            <ImageWrapper path={item.medialogoPath}>
                                <ProgressiveImager alt={`${item.name} Logo`} src={config.getImageUrl('halfwidth', item.medialogoPath)}/>
                            </ImageWrapper>
                        </div>
                        }
                    </div>
                </Container>
            </div>

            {item.media && item.media.length > 0 &&
                <div className="specialists-gallery pt-3 sm-pt-3">
                    <Container>
                        <PreviewGallerySecondary images={item.media} altbase={item.name} />
                    </Container>
                </div>
            }

            {item.benefits && 
                <div className="specialists-detail-services pt-3 sm-pt-3">
                    <Container>
                        <h2>Unsere Leistungen</h2>
                        <div dangerouslySetInnerHTML={{__html: item.benefits}}></div>
                    </Container>
                </div>
            }

            {item.mediavideo &&
                <>
                    <div className="specialists-video pt-3 sm-pt-3">
                        <div className="bg-grey-light py-3 sm-py-3">
                            <Container>
                                <div className="video-preview" onClick={() => this.setState({ videoLightboxIsOpen: true })}>
                                    <ImageWrapper path={item.mediavideoPreviewPath}>
                                        <ProgressiveImager src={config.getImageUrl('main', item.mediavideoPreviewPath)}  alt={item.name + " - Video Vorschaubild" } />
                                    </ImageWrapper>
                                    <Icon name="play circle outline" inverted/>
                                </div>
                            </Container>
                        </div>
                    </div>

                    <Gallery
                        images={[{src: config.getDirectUrl(item.mediavideoPath)}]}
                        galleryOpen={videoLightboxIsOpen}
                        onClose={() => this.setState({ videoLightboxIsOpen: false })}
                        isVideo
                    />
                </>
            }

            <div className="specialists-contact py-3 sm-py-3">
                <Container>
                    <Grid>

                        <Grid.Column mobile={16} tablet={8} computer={8}>
                            <div className="contact-info">
                                <h2>Kontakt</h2>
                                <div className="contact-infodata">

                                    <div className="contact-company-data">
                                        <div>{item.name}</div>
                                        <div>{item.owner}</div>
                                        <div>{item.street}</div> 
                                        <div>{item.zip + " " + item.city}</div> 
                                    </div>

                                </div>
                            </div>
                        </Grid.Column>
                        
                        {item.latitude && item.longitude && 
                            <Grid.Column mobile={16} tablet={8} computer={8}>
                                <div className="contact-map">
                                    <MapContainer center={[item.latitude, item.longitude]} zoom={10} scrollWheelZoom={false}>
                                        <TileLayer
                                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                        />
                                        <Marker
                                            position={[item.latitude, item.longitude]}
                                            icon={new MarkerIcon({iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41]})} 
                                        >
                                        </Marker>
                                    </MapContainer>
                                </div>
                            </Grid.Column>
                        }
                        
                    </Grid>
                </Container>
            </div>
            </>
        }
        </div>

    );
  }
}

function mapStateToProps(state, ownProps) {
    const { professionalsById } = state;

    const { isFetching, isFailed, item } = professionalsById[ownProps.match.params.id] || {
        item: {},
        isFetching: true,
        isFailed: false
    }

    return {
        item: ownProps.match.params.preview ? item.input : item,
        isFailed,
        isFetching
    };
}

export default connect(mapStateToProps)(withRouter(SpecialistsDetail));
