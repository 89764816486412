import React, { Component } from 'react';
import { connect } from 'react-redux';
import config from './config/config';
import { fetchMetadata } from './actions/global';
import { fetchUserNotificationsIfNeeded } from './actions/account';

class Ping extends Component {

    state = {
        pinger: ""
    }

    componentDidMount() {

        const pinger = setInterval(this.ping, config.PING_TIMEOUT);
        this.setState({'pinger': pinger});

    }

    componentWillUnmount = () => {
        clearInterval(this.state.pinger);
    }

    ping = () => {
        const { dispatch, token, item } = this.props;

        // Check for new build
        //dispatch(fetchMetadata());

        // Fetch dashboard updates
        if (token) {
            dispatch(fetchUserNotificationsIfNeeded());
        }
    }

    render (){
        return (
            <></>
        )

    }

}

function mapStateToProps(state, ownProps) {
    const { globalByComponent, account } = state;

    const { item, token } = account || {
        item: {},
        token: ''
    }


    return {
        metadata: globalByComponent['Metadata'],
        item,
        token
    }
}

export default connect(mapStateToProps)(Ping);
