import React from 'react'
import { connect } from 'react-redux';
import { Form, Message, Icon, Image, Divider, Label, Transition, TextArea } from 'semantic-ui-react';
import BeParent from '../../../project/backend/be-parent';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/emoticons/js/emojis';
import 'tinymce/plugins/paste';
import { Editor } from '@tinymce/tinymce-react';
import '../../../../tinymce/langs/de';
import AddIcon from '../../../../images/icons/circle-add-element.svg';
import CustomPopup from '../../../../components/CustomPopup/CustomPopup';
import TextareaAutosize from 'react-textarea-autosize';
import config from '../../../../config/config';
import Tagging from '../../../../components/Tagging/Tagging';
import { showTooltip } from '../../../../actions/global';

class Description extends BeParent {

    state = {
        tagModelIsOpen: false
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);
    }

    handleEditorChange = (content) => {
        const { id } = this.props;

        this.handleChange(`professional.${id}.input.benefits`, content);
    }
    
    handleAddTag = (tag) => {
        const { dispatch, id, professional } = this.props;
        let arr = professional.input.inputTags ? professional.input.inputTags : [];
        arr.push(tag);
        this.handleChange(`professional.${id}.input.inputTags`, arr);
        // this.handleChange(`professional.${id}.input.categories`, professional.input.categories);
        // this.handleChange(`professional.${id}.input.categorizations`, professional.input.categorizations);
    }

    handleDeleteTag = (tag) => {
        const { dispatch, id, professional } = this.props;
        let arr = professional.input.inputTags ? professional.input.inputTags : [];

        arr = arr.filter(item => parseInt(item) !== tag)
        this.handleChange(`professional.${id}.input.inputTags`, arr);
    }

    handleDirectDeleteTag = (tag) => {
        const { dispatch, id, professional } = this.props;
        let arr = professional.input.categories ? professional.input.categories : [];
        let arr2 = professional.input.inputTags ? professional.input.inputTags : [];

        arr = arr.filter(item => parseInt(item) !== tag);
        this.handleChange(`professional.${id}.input.categories`, arr);
        this.handleChange(`professional.${id}.input.inputTags`, arr);
    }

    handleRevertTag = () => {
        const { professional, id } = this.props;
        let arr = [];
        professional.input.inputTags && professional.input.inputTags.map(item => (
            config.getCategoriesSub(false).map((tag, index) => (
                tag.options.filter(itemTag => itemTag.value === item).map((category) => (
                   category.value === item && arr.push(category.value)
                ))
             ))
        ))

        this.handleChange(`professional.${id}.input.inputTags`, professional.input.categories)
        // this.handleChange(`professional.${id}.input.categories`, arr)
        this.setState({ tagModelIsOpen: false })
    }

    handleSaveTag = () => {
        const { dispatch, professional, id } = this.props;
        let arr = professional.input.inputTags;

        this.handleChange(`professional.${id}.input.categories`, arr)
        this.handleChange(`professional.${id}.input.inputTags`, arr);
        this.handleChange(`professional.${id}.input.categorizations`, arr);
        console.log("action")
        this.setState({ tagModelIsOpen: false })
    }

    handleTooltip = (name) => {
        const { dispatch, toolTips } = this.props;
    
        dispatch(showTooltip("professional", name))
        
    }

    render() {

        const { professional, id, validation, toolTips } = this.props;
        const { tagModelIsOpen } = this.state;

         return (
            <>

                <div className="new-professional">
                    <Divider hidden/>

                    <Message color="teal">
                        Alle unten angegebene Inhalte werden im Inserat für alle Besucher angezeigt. 
                        Die Inhalte können hier jederzeit angepasst werden. Es kann allerdings ein paar Stunden dauern bis geänderte Inhalte im Inserat angezeigt werden.
                    </Message>

                    <Form>

                        <Form.Field error={validation.headline && validation.headline.error}>
                            <label>Seitenüberschrift</label>
                            <Form.Input
                                type="text"
                                name={`professional.${id}.input.headline`}
                                onChange={this.handleChangeInput}
                                value={professional.input.headline}
                            />
                        </Form.Field>
                        
                        <Form.Field error={validation.intro && validation.intro.error}>
                            <label>
                                Beschreibung des Unternehmens 
                                <Icon className="tooltip" color="grey" name="info circle" onClick={() => this.handleTooltip("intro")}/>
                            </label>

                            {toolTips.professional && toolTips.professional.name.includes("intro") &&     
                                <Message color="teal">Allgemeine Beschreibung des Unternehmens (Fließtext)</Message>
                            }

                            <TextareaAutosize
                                value={professional.input.intro}
                                name={`professional.${id}.input.intro`}
                                onChange={this.handleChangeInputAlt}
                                type="text"
                                minRows="5"
                                maxRows="15"
                                autoComplete="nope"
                                textContentType="oneTimeCode"
                            />
                                    
                        </Form.Field>
                       


                        <Form.Field error={validation.benefits && validation.benefits.error}>
                            <label>
                                Leistungen des Unternehmens
                                <Icon className="tooltip" color="grey" name="info circle" onClick={() => this.handleTooltip("benefits")}/>
                            </label>
                            
                            {toolTips.professional && toolTips.professional.name.includes("benefits") &&
                                <Message color="teal">
                                    Leistungen zeilenweise aufführen. Diese werden später automatisch als Liste angezeigt.
                                    <br/>
                                    <strong>Beispiel:</strong>
                                    Hochwertige Restauration aller Oldtimer {`&`} Youngtimer<br/>
                                    Rostschutzmaßnahmen: Hohlraumversieglung mit speziellem Wax, Unterbodenschutz<br/>
                                    Hochwertige Fahrzeugteil- und Fahrzeungganzlackierungen mit ausgesuchten Partnerfirmen
                                </Message>
                            }

                            <Editor
                                value={professional.input.benefits}
                                init={{...config.TINY_INIT_PROFESSIONALS}}
                                onEditorChange={this.handleEditorChange}
                                disabled={professional.isPosting ? true : false}
                            />
                        </Form.Field>

                        <h2 style={{color: (validation.scopes || validation.types ) ? "#9F3A38" : ""}}>Kategorie</h2>
                        <Form.Group>
                            <Form.Field width="8" error={validation.scopes && validation.scopes.error}>
                                <label>Kategorie wählen</label>
                                    {config.getScopes().map(scope => (
                                        <Form.Checkbox
                                            label={scope.label}
                                            index={scope.value}
                                            checked={professional.input.scopes && professional.input.scopes.includes(String(scope.value))}
                                            name={`professional.${id}.input.scopes.${scope.value}`}
                                            onChange={this.handleChangeCheckbox}
                                        />
                                    ))
                                    }
                            </Form.Field>
                            <Form.Field width="8" error={validation.types && validation.types.error}>
                                <label>Typ wählen</label>
                                {config.getProfessionalType().map((type, index) => (
                                    <Form.Checkbox
                                        label={type.label}
                                        index={type.value}
                                        key={index}
                                        checked={professional.input.types && professional.input.types.includes(String(type.value))}
                                        name={`professional.${id}.input.types.${type.value}`}
                                        onChange={this.handleChangeCheckbox}
                                    />
                                    ))
                                }
                            </Form.Field>
                        </Form.Group>

                    </Form>

                    <Divider hidden/>

                    {/* Tagging */}
                    <div className="tagging">
                        <h2>Tags</h2>
                        <p className="tagging-description">
                            Füge Tags hinzu um das Thema einzugrenzen und dadurch besser gefunden zu werden
                        </p>

                        <div className="tags"> 
                            <span>Tags:</span>
                            
                            {professional.input.categories && 
                                professional.input.categories.map(tagValue => (
                                    config.getCategoriesSub(false, true).map((tag) => (
                                        tag.options.filter(item => item.value === parseInt(tagValue)).map((category, index) => (
                                            <Label key={index} as='a' onClick={() => this.handleDirectDeleteTag(category.value)}>
                                                {category.label}
                                                <Icon name="delete"/>
                                            </Label>
                                        ))
                                    ))
                                ))
                            }

                            <div className="add-tag" onClick={() => this.setState({ tagModelIsOpen: true })}>
                                <Image title="Tag hinzufügen" alt="Icon Circle Add" src={AddIcon} />
                            </div>
                        </div>

                    </div>

                    <CustomPopup
                        popupOpen={tagModelIsOpen}
                        onClose={() => this.setState({ tagModelIsOpen: false })}
                        //saveButton={this.showButton()}
                        showCloseIcon={false}
                        popupHeader="Tags hinzufügen"
                        headerBackgroundWhite
                        className="larger-popup"
                    >
                        <Tagging
                            onRequestClose={this.handleRevertTag}
                            onRequestSave={this.handleSaveTag}
                            handleTag={this.handleAddTag}
                            handleDeleteTag={this.handleDeleteTag}
                            professionalTags={professional.input.inputTags}
                            messageText={false}
                        />
                    </CustomPopup>
                    
                    <Divider hidden/>

                </div>
            </>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { professionalsById, globalByComponent } = state;

    const { isFetching, isFailed, item: professional } = professionalsById[ownProps.id] || {
        isFetching: true,
        isFailed: false,
        professional: {}
    }

    const toolTips = globalByComponent['Tooltip'] || {};

    return {
        isFetching, 
        isFailed,
        professional,
        globalByComponent,
        toolTips
    };
}

export default connect(mapStateToProps)(Description);
