export const selectCustomStyles = {
    menu: (provided, state) => {
        const zIndex = '99999';
    
        return { ...provided, zIndex };
      },
}

//React select validation error
export const selectError = {
  control: (provided, state) => ({
      ...provided,
      border: '1px solid #e0b4b4',
      background: '#fff6f6',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color:  "#9f3a38" 
  })
}