import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { handleContainer } from '../actions/global';
import { Container, Icon, Header, Grid, Divider, Message, Loader, Button } from 'semantic-ui-react';
import { resetMessages, putActivation } from '../actions/account';
import './Pages.scss';

class Activation extends Component {

    constructor(props) {
        super(props);
        // this.redirectTimeout = null;
    }

    componentDidMount = () => {
        const { dispatch, history, match } = this.props;

        dispatch(handleContainer("newsfeed", ""));
        dispatch(resetMessages());
        dispatch(putActivation(match.params.user, match.params.code));

        //Redirect after 5 sec
        // this.redirectTimeout = setTimeout(() => {
        //     history.push('/')
        // }, 5000);
    }

    componentWillUnmount = () => {
        // clearTimeout(this.redirectTimeout);
    }

    render() {
        const { isActivating, activatingMessage } = this.props;

        return (

            <Container className="account-activation">
                <Divider hidden/>

                {activatingMessage === '' &&
                    <Grid>
                        <Grid.Column width={16} textAlign="center">

                            <Loader active inline='centered' size="massive" />
                            <Header as="h2">Aktivierung läuft</Header>
                            <p>Bitte warte einen kleinen Moment.</p>

                        </Grid.Column>
                    </Grid>
                }
                {activatingMessage === 'SUCCESS' &&
                    <Grid>
                        <Grid.Column width={16} textAlign="center">

                            <Icon name="check circle" size="massive" color="green"/>
                            <Header as="h2">Dieses Konto ist nun aktiviert</Header>
                            <p>Du kannst dich einloggen.</p>
                            <Divider hidden/>
                            <Button as="a" href="/">Zur Startseite</Button>

                        </Grid.Column>
                    </Grid>
                }
                {activatingMessage === 'FAILURE' &&
                    <Grid>
                        <Grid.Column width={16} textAlign="center">

                            <Icon name="warning circle" size="massive" color="red"/>
                            <Header as="h2">Ein Fehler ist aufgetreten</Header>
                            <p>Bitte versuch es später noch einmal.</p>
                            <Divider hidden/>
                            <Button as="a" href="/">Zur Startseite</Button>

                        </Grid.Column>
                    </Grid>
                }
                {activatingMessage === 'alreadyactive' &&
                    <Grid>
                       <Grid.Column width={16} textAlign="center">

                           <Icon name="smile" size="massive" color="green"/>
                           <Header as="h2">Dieses Konto wurde bereits aktiviert</Header>
                           <p>Du kannst dich einloggen.</p>
                           <Divider hidden/>
                           <Button as="a" href="/">Zur Startseite</Button>

                       </Grid.Column>
                   </Grid>
                }
                {activatingMessage === 'wrongcode' &&
                    <Grid>
                        <Grid.Column width={16} textAlign="center">

                            <Icon name="warning circle" size="massive" color="red"/>
                            <Header as="h2">Der Aktivierungscode stimmt nicht</Header>
                            <p>Bitte prüfe, ob der Link aus unserer E-Mail korrekt und vollständig aufgerufen wurde.</p>
                            <Divider hidden/>
                            <Button as="a" href="/">Zur Startseite</Button>

                        </Grid.Column>
                    </Grid>
                }

            </Container>

        )
    }
}

function mapStateToProps(state, ownProps) {
    const { account } = state;

    const { isActivating, activatingMessage } = account || {
        isActivating: false,
        activatingMessage: '',
    }

    return {
        isActivating,
        activatingMessage,
    }
}

export default connect(mapStateToProps)(withRouter(Activation));
