import React, { Component } from 'react'
import {
    Header,
    Container,
    Divider,
    Icon,
    Button,
    Input,
    Message,
    Form,
    Grid,
    Responsive
 } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Select from 'react-select';
import TextareaAutosize from 'react-textarea-autosize';
import { FileUpload } from 'ix-redux-file-upload';
import { handleContainer, setRuntime } from '../../../../actions/global';
import config from '../../../../config/config';
import ProgressiveImager from '../../../../components/helpers/progressive-imager';
import fetchProjectIfNeeded, {newStory, postStory, fetchProject} from '../../../../actions/project';
import ImageWrapper from '../../../../components/helpers/image-wrapper';


class NewStory extends Component {

    constructor(props) {
        super(props)

        this.state = {
            redirect: '',
            formValidation: {
                title: {
                    error: false,
                    message: ''
                },
                description: {
                    error: false,
                    message: ''
                },
                media: {
                    error: false,
                    message: ''
                }
            }
        }

        this.handleInput = this.handleInput.bind(this);
        this.handleInputAlt = this.handleInputAlt.bind(this);
        this.handleInputSelect = this.handleInputSelect.bind(this);
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        const { dispatch, match } = this.props
        dispatch(fetchProjectIfNeeded(match.params.id))
        dispatch(handleContainer("secondary", "Neue Story", false, "goback"));
        dispatch(setRuntime({page: 'be-story-new'}));
    }

    componentDidUpdate(prevProps) {
        const { dispatch, listitems, match } = this.props;

        if(listitems.length > prevProps.listitems.length) {
            var newest = null;
            listitems.map(function(item) {
                if (!newest || newest.id < item.id) {
                    newest = item;
                }
            })

            if (newest) {
                this.setState({ redirect: '/backend/project/'+match.params.id+'/stories/'+newest.id+'/edit_story' });
                // Update project
                dispatch(fetchProject(match.params.id));
            }
        }
    }

    handleInput(e, element) {
        const { dispatch, match } = this.props;

        dispatch(newStory(match.params.id, element.name, element.value));
    }

    handleInputAlt(e) {
        const { dispatch, match } = this.props;
        const element = e.currentTarget;

        dispatch(newStory(match.params.id, element.name, element.value));
    }

    handleInputSelect(opt, element) {
        const { dispatch, match } = this.props;

        dispatch(newStory(match.params.id, element.name, opt.value));
    }

    submit(e, element) {
        const { dispatch, match, inputTitle, inputDesc, inputCategory, inputMedia, isPosting } = this.props;

        if (isPosting) {
            return false;
        }

        this.setState({
            formValidation: {
                ...this.state.formValidation,
                title: config.validateTextField(inputTitle, true),
                description: config.validateTextField(inputDesc, true),
                media: config.validateMedia(inputMedia)
            }
        })

        if (!config.validateTextField(inputTitle, true).error && !config.validateMedia(inputMedia).error && !config.validateMedia(inputDesc).error) {
            dispatch(postStory(match.params.id, inputTitle, inputDesc, inputCategory, inputMedia))
        }

    }

    render() {
        const { account, item, isPosting, postingMessage, inputTitle, inputDesc, inputCategory, inputMediaPath } = this.props;
        const { formValidation } = this.state;

        if (this.state && this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }

        return (
                <Container className="new-story">
                    <Message color="teal">
                        Du kannst Deine Story mit Elementen wie Texten, Bildern oder Videos beschreiben und auch später nach belieben erweitern.
                    </Message>
                    <Form>
                            <Form.Field required  error={formValidation.title.error}>
                                <label>Titel der Story</label>
                                <Input
                                    value={inputTitle}
                                    name={'inputTitle'}
                                    onChange={this.handleInput}
                                    required
                                />
                            </Form.Field>

                            {formValidation.title.error &&
                                <Message color="red">
                                    Fülle den Titel aus.
                                </Message>
                            }

                            <Form.Field>
                                <label>Kategorie</label>
                                <Select menuPlacement="auto"
                                    options={config.getCategoriesSub()}
                                    name={'inputCategory'}
                                    onChange={this.handleInputSelect}
                                    value={config.getDefault(config.getCategoriesSub(), (inputCategory ? inputCategory : null))}
                                    classNamePrefix="custom-select"
                                    className="custom-select-wrapper"
                                />
                            </Form.Field>

                            <Form.Field required error={formValidation.media.error}>
                                <label>Hauptbild</label>
                                <FileUpload
                                    headers={{ 'Authorization': 'Bearer '+account.token }}
                                    allowedFileTypes={['jpg', 'jpeg']}
                                    dropzoneId={'newStoryForProject.' + item.id + '.inputMedia'}
                                    url={config.API_HOST + config.API_UPLOAD}
                                >
                                    {inputMediaPath ?

                                    <div className="image_container main_img">

                                        <ImageWrapper path={inputMediaPath} className="story-main-img">
                                            <ProgressiveImager src={config.getImageUrl('main', inputMediaPath)}/>
                                            <Icon name="pencil" circular inverted color="grey"/>
                                        </ImageWrapper>

                                    </div>

                                    :

                                        <Button color="red">
                                            Hauptbild hinzufügen
                                        </Button>

                                    }

                                </FileUpload>

                            </Form.Field>

                            {formValidation.media.error &&
                                <Message color="red">
                                    {formValidation.media.message}
                                </Message>
                            }


                            <Divider hidden/>

                            <Form.Field required error={formValidation.description.error}>
                                <label>Warum hast du das in der Story beschriebene gemacht?</label>
                                <TextareaAutosize
                                    icon="times circle"
                                    value={inputDesc}
                                    name={'inputDesc'}
                                    onChange={this.handleInputAlt}
                                    minRows="5"
                                    maxRows="15"
                                />
                            </Form.Field>

                            {formValidation.description.error &&
                                <Message color="red">
                                    {formValidation.description.message}
                                </Message>
                            }

                            <Divider hidden/>

                    </Form>

                    <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                        <Divider hidden/>
                    </Responsive>

                    {postingMessage === 'FAILURE' &&
                        <Message negative>Beim Senden ist ein Fehler aufgetreten.</Message>
                    }

                    <Grid>
                        <Grid.Column width={16} textAlign="center">
                            {/*<Button color="red" basic >Zurück</Button>*/}
                            <Button color="red" loading={isPosting} onClick={this.submit}>Story erstellen</Button>
                            <Divider hidden/>
                            <Divider hidden/>
                        </Grid.Column>
                    </Grid>

                    {/* Add working hours optionally */}

                    <div className="working-hours" style={{display: "none"}}>
                        <div className="content">
                            <Header as="h1">Arbeitszeit eintragen?</Header>
                            <p>Die Änderungen an Deiner Story wurden gesichert.</p>
                            <p>Ist auch Arbeitszeit angefallen, die Du jetzt gleich angeben möchtest?</p>
                            <Button.Group style={{width: "100%", marginTop: "1rem"}}>
                                <Button basic inverted>
                                Nein
                                </Button>
                                <Button color='green'>
                                Ja
                                </Button>
                            </Button.Group>
                        </div>
                    </div>

                    <Divider hidden/>

                </Container>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { account, projectById, listByEntity, storyById } = state;

    const { item, isPosting, postingMessage, inputTitle, inputDesc, inputCategory, inputMedia, inputMediaPath } = projectById[
        ownProps.match.params.id
    ] || {
        item: {},
        isPosting:false,
        postingMessage: '',
        inputTitle: '',
        inputDesc: '',
        inputCategory: null,
        inputMedia: null,
        inputMediaPath: '',
    }

    const { list } = listByEntity[
        ownProps.match.params.id+'::story'
    ] || {
        list: {},
    }

    var listitems = [];

    if (list) {
        for (let [key, value] of Object.entries(list)) {
            if (storyById[value] && !storyById[value].isDeleted) {
                listitems.push(storyById[value].item);
            }
        }
    }

    return {
        account,
        item,
        listitems,
        isPosting,
        postingMessage,
        inputTitle,
        inputDesc,
        inputCategory,
        inputMedia,
        inputMediaPath,
    }
}

export default connect(mapStateToProps)(NewStory);
