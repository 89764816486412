import React, { Component } from 'react';
import { Header, Container, Divider, List, Item, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { footerActiveItem, handleContainer, handleHeaderItems } from '../actions/global';
import ConsentBanner from '../components/ConsentBanner/ConsentBanner';
import config from '../config/config'
import { getPage } from '../actions/page';
import ErrorHandler from '../components/ErrorHandler/ErrorHandler';

class Privacy extends Component {

    componentDidMount = () => {
        const { dispatch } = this.props;
        dispatch(footerActiveItem('footermenu'))
        dispatch(handleHeaderItems("user"));
        dispatch(handleContainer("main", "Datenschutz", null, null, null, null, {description: 'Die Datenschutzerklärung von Vetero. Hast Du auch einen Oldtimer? Dann ist Vetero genau das Richtige für Dich.'}));
        window.scrollTo(0, 0);
        dispatch(getPage(3, 'dataProtection'))
    }

    fetchPageContent = () => {
        const { dispatch } = this.props;
        dispatch(getPage(3, 'dataProtection'))
    }

    render() {

        const { title, content, isFetching, isFailed } = this.props;

        return (
            <Container className="privacy-page">

                {isFetching && 
                    <Loader active/>
                }

                {isFailed && 
                    <ErrorHandler callbackFunction={this.fetchPageContent}/>
                }

                {!isFetching && title && content && 
                <>
                    <Header as="h2">{title.rendered}</Header>
                    <Divider/>

                    <div dangerouslySetInnerHTML={{__html: content.rendered}}/>

                    <section>
                        <Divider hidden/>
                        <Divider hidden/>
                        <Header as="h2">Datenschutzeinstellungen</Header>
                        <ConsentBanner inline={true} />
                    </section>

                </>
                }


            </Container>
        )
    }
}

function mapStateToProps(state, ownProps){
    const { pageByName } = state
    const { title, content, isFetching, isFailed } = pageByName['dataProtection']
    || {
        isFetching: true,
        isFailed: false,
        content: '',
        title: ''
    }
    
    return {
        content,
        title,
        isFailed,
        isFetching
    }
}

export default connect(mapStateToProps)(Privacy);
