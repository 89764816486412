import fetch from 'cross-fetch';
import config from '../config/config';
import { createAction } from 'redux-api-middleware';

export const REQUEST_ACCOUNT = 'REQUEST_ACCOUNT';
export const LOGIN_ACCOUNT = 'LOGIN_ACCOUNT';
export const LOGOUT_ACCOUNT = 'LOGOUT_ACCOUNT';
export const FAIL_ACCOUNT = 'FAIL_ACCOUNT';
export const RECEIVE_ACCOUNT = 'RECEIVE_ACCOUNT';
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN'
export const FETCH_USER_REQUEST = "FETCH_USER_REQUEST";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILED = "FETCH_USER_FAILED";
export const FETCH_USER_NOTIFICATIONS_REQUEST = "FETCH_USER_NOTIFICATIONS_REQUEST";
export const FETCH_USER_NOTIFICATIONS_SUCCESS = "FETCH_USER_NOTIFICATIONS_SUCCESS";
export const FETCH_USER_NOTIFICATIONS_FAILED = "FETCH_USER_NOTIFICATIONS_FAILED";
export const FETCH_USER_VOUCHER_REQUEST = "FETCH_USER_VOUCHER_REQUEST";
export const FETCH_USER_VOUCHER_SUCCESS = "FETCH_USER_VOUCHER_SUCCESS";
export const FETCH_USER_VOUCHER_FAILED = "FETCH_USER_VOUCHER_FAILED";
export const SELECT_USER_VOUCHER_REQUEST = "SELECT_USER_VOUCHER_REQUEST";
export const SELECT_USER_VOUCHER_SUCCESS = "SELECT_USER_VOUCHER_SUCCESS";
export const SELECT_USER_VOUCHER_FAILED = "SELECT_USER_VOUCHER_FAILED";
export const NEW_PROJECT = "NEW_PROJECT";
export const API_POST_PROJECT_REQUEST = "API_POST_PROJECT_REQUEST";
export const API_POST_PROJECT_SUCCESS = "API_POST_PROJECT_SUCCESS";
export const API_POST_PROJECT_FAILURE = "API_POST_PROJECT_FAILURE";
export const API_POST_ACCOUNT_REQUEST = "API_POST_ACCOUNT_REQUEST";
export const API_POST_ACCOUNT_SUCCESS = "API_POST_ACCOUNT_SUCCESS";
export const API_POST_ACCOUNT_FAILURE = "API_POST_ACCOUNT_FAILURE";
export const CLEAR_NEW_PROJECT_FIELDS = "CLEAR_NEW_PROJECT_FIELDS";
export const API_POST_ACTIVATION_REQUEST = "API_POST_ACTIVATION_REQUEST";
export const API_POST_ACTIVATION_SUCCESS = "API_POST_ACTIVATION_SUCCESS";
export const API_POST_ACTIVATION_FAILURE = "API_POST_ACTIVATION_FAILURE";
export const API_POST_REQUEST_REQUEST = "API_POST_REQUEST_REQUEST";
export const API_POST_REQUEST_SUCCESS = "API_POST_REQUEST_SUCCESS";
export const API_POST_REQUEST_FAILURE = "API_POST_REQUEST_FAILURE";
export const API_POST_RECOVER_REQUEST = "API_POST_RECOVER_REQUEST";
export const API_POST_RECOVER_SUCCESS = "API_POST_RECOVER_SUCCESS";
export const API_POST_RECOVER_FAILURE = "API_POST_RECOVER_FAILURE";
export const RESET_MESSAGES = "RESET_MESSAGES";
export const CHANGE_ACCOUNT = "CHANGE_ACCOUNT";
export const API_UPDATE_ACCOUNT_REQUEST = "API_UPDATE_ACCOUNT_REQUEST";
export const API_UPDATE_ACCOUNT_SUCCESS = "API_UPDATE_ACCOUNT_SUCCESS";
export const API_UPDATE_ACCOUNT_FAILURE = "API_UPDATE_ACCOUNT_FAILURE";
export const API_DELETE_ACCOUNT_REQUEST = "API_DELETE_ACCOUNT_REQUEST";
export const API_DELETE_ACCOUNT_SUCCESS = "API_DELETE_ACCOUNT_SUCCESS";
export const API_DELETE_ACCOUNT_FAILURE = "API_DELETE_ACCOUNT_FAILURE";
export const RESET_STORE = "RESET_STORE";
export const API_PING_REQUEST = "API_PING_REQUEST";
export const API_PING_SUCCESS = "API_PING_SUCCESS";
export const API_PING_FAILURE = "API_PING_FAILURE";
export const API_POST_EMAILCHANGE_REQUEST = "API_POST_EMAILCHANGE_REQUEST";
export const API_POST_EMAILCHANGE_SUCCESS = "API_POST_EMAILCHANGE_SUCCESS";
export const API_POST_EMAILCHANGE_FAILURE = "API_POST_EMAILCHANGE_FAILURE";


export function resetStore() {
    return {
        type: RESET_STORE,
    }
}

export function ping() {
    return createAction({
        endpoint: `${config.API_HOST}/v1/ping`,
        method: 'GET',
        types: [
            {type: API_PING_REQUEST},
            {type: API_PING_SUCCESS},
            {type: API_PING_FAILURE},
        ]
    })
}

export function putEmailChange(user, code) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/account/emailchange/${user}`,
        method: 'POST',
        body: JSON.stringify({
            code,
        }),
        types: [
            {type: API_POST_EMAILCHANGE_REQUEST},
            {type: API_POST_EMAILCHANGE_SUCCESS},
            {type: API_POST_EMAILCHANGE_FAILURE},
        ]
    })
}

export function deleteAccount(item, pw) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/user/${item.id}`,
        method: 'DELETE',
        body: JSON.stringify({
            plainPassword: pw,
        }),
        types: [
            {type: API_DELETE_ACCOUNT_REQUEST},
            {type: API_DELETE_ACCOUNT_SUCCESS},
            {type: API_DELETE_ACCOUNT_FAILURE},
        ]
    })
}

export function changeAccount(item) {
    return {
        type: CHANGE_ACCOUNT,
        payload: {
            ...item,
        },
    }
}

export function updateAccount(item) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/user/${item.id}`,
        method: 'PUT',
        body: JSON.stringify({
            user: {
                ...item,
                name: item.inputName,
                email: item.inputEmail,
            },
        }),
        types: [
            {type: API_UPDATE_ACCOUNT_REQUEST},
            {type: API_UPDATE_ACCOUNT_SUCCESS, meta: {inputEmail: item.inputEmail}},
            {type: API_UPDATE_ACCOUNT_FAILURE},
        ]
    })
}

export function resetMessages() {
    return {
        type: RESET_MESSAGES,
    }
}

export function postAccount(register) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/account/register`,
        method: 'POST',
        body: JSON.stringify({
            ...register,
        }),
        types: [
            {type: API_POST_ACCOUNT_REQUEST},
            {type: API_POST_ACCOUNT_SUCCESS},
            {type: API_POST_ACCOUNT_FAILURE},
        ]
    })
}

export function putActivation(user, code) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/account/activation/${user}`,
        method: 'POST',
        body: JSON.stringify({
            code,
        }),
        types: [
            {type: API_POST_ACTIVATION_REQUEST},
            {type: API_POST_ACTIVATION_SUCCESS},
            {type: API_POST_ACTIVATION_FAILURE},
        ]
    })
}

export function putRequest(recover) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/account/password/request`,
        method: 'PUT',
        body: JSON.stringify({
            ...recover,
        }),
        types: [
            {type: API_POST_REQUEST_REQUEST},
            {type: API_POST_REQUEST_SUCCESS},
            {type: API_POST_REQUEST_FAILURE},
        ]
    })
}

export function putRecover(recover, user, code) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/account/password/recover/${user}`,
        method: 'POST',
        body: JSON.stringify({
            code,
            ...recover,
        }),
        types: [
            {type: API_POST_RECOVER_REQUEST},
            {type: API_POST_RECOVER_SUCCESS},
            {type: API_POST_RECOVER_FAILURE},
        ]
    })
}

export function newProject(inputProject) {
    return {
        type: NEW_PROJECT,
        payload: {
            inputProject,
        }
    }
}

export function postProject(inputProject) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/project`,
        method: 'POST',
        body: JSON.stringify({
            brand: inputProject.brand,
            scope: inputProject.scope,
            vehicle: inputProject.model,
            variant: inputProject.variant,
            goal: inputProject.goal,
            technicaldata: {
                performance: inputProject.performance,
                displacement: inputProject.displacement,
                cylinder: inputProject.cylinder,
                layout: inputProject.layout,
                engine: inputProject.engine,
                gear: inputProject.gear,
                drive: inputProject.drive,
                gearno: inputProject.gearno,
                design: inputProject.design,
                fuel: inputProject.fuel,
                year: inputProject.year,
            },
            mediamain: inputProject.mediamain,
            mediapreview: inputProject.mediapreview,
        }),
        types: [
            {type: API_POST_PROJECT_REQUEST},
            {type: API_POST_PROJECT_SUCCESS},
            {type: API_POST_PROJECT_FAILURE},
        ]
    })
}

// Set token
export const setAccessToken = (payload) => ({
  type: SET_ACCESS_TOKEN,
  payload,
})

// Login
export function loginAccount(account) {
    return dispatch => {
        dispatch(requestAccount());
        return fetch(`${config.API_HOST}/v1/login_check`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(account)
        })
        .then(response => response.json())
        .then(json => {
            dispatch(receiveLogin(json));
            dispatch(fetchAccount(json.code, json.message));
        })
        // .catch(error => dispatch(failAccount()))
    }
}

// Get account data
// TODO: Use API middleware
export function fetchAccount(status, message) {
    return (dispatch, state) => {
        dispatch(requestAccount());
        return fetch(`${config.API_HOST}/v1/account`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+state().account.token,
            },
        })
        .then((response) => {
            if(response.ok) {
                return response.json();
            }
            else {
                dispatch(failAccount())
            }
        })
        .then(json => {
            dispatch(receiveAccount(json))
            dispatch(fetchUserNotifications())
        })
        .catch(error => dispatch(failAccount(status, message)))
    }
}

function requestAccount() {
    return {
        type: REQUEST_ACCOUNT,
        data: {
            isFetching: true,
            lastFetch: Date.now(),
        }
    }
}

export function receiveLogin(json) {
    return {
        type: LOGIN_ACCOUNT,
        data: {
            isDirty: false,
            isFetching: false,
            isFailed: false,
            token: json.token,
            lastUpdated: Date.now(),
            status: null
        }
    }
}

export function logoutAccount() {
    return {
        type: LOGOUT_ACCOUNT,
        data: {
            isDirty: false,
            isFetching: false,
            isFailed: false,
            item: {},
            token: null,
            lastUpdated: Date.now(),
        }
    }
}

export function receiveAccount(json) {
    return {
        type: RECEIVE_ACCOUNT,
        data: {
            isDirty: false,
            isFetching: false,
            isFailed: false,
            item: json.account,
            lastUpdated: Date.now(),
            status: json.code
        }
    }
}

function failAccount(code, message) {
    return {
        type: FAIL_ACCOUNT,
        data: {
            isFetching: false,
            isFailed: true,
            status: code,
            message
        }
    }
}

export function selectUserVoucher(id) {
    return createAction({
        endpoint: `${config.API_HOST}/v1/voucher/${id}`,
        method: 'PUT',
        types: [
            {type: SELECT_USER_VOUCHER_REQUEST, meta: {id}},
            {type: SELECT_USER_VOUCHER_SUCCESS, meta: {id}},
            {type: SELECT_USER_VOUCHER_FAILED, meta: {id}},
        ]
    })
}

export function fetchUserVoucher() {
    return createAction({
        endpoint: `${config.API_HOST}/v1/voucher`,
        method: 'GET',
        types: [
            {type: FETCH_USER_VOUCHER_REQUEST},
            {type: FETCH_USER_VOUCHER_SUCCESS},
            {type: FETCH_USER_VOUCHER_FAILED},
        ]
    })
}

function shouldFetchUserVoucher(state){
    const account = state.account
    if (
        !account.voucher ||
        ((account.voucher.isFetching || account.voucher.isFailed) && Date.now() - account.voucher.lastFetch > config.FETCH_TIMEOUT) ||
        (!account.voucher.isFetching && Date.now() - (account.voucher.lastUpdated ? account.voucher.lastUpdated : 0) > config.FETCH_AGAIN)
    ) {
        return true
    }
    return false
}

export function fetchUserVoucherIfNeeded() {
    return (dispatch, getState) => {
        if (shouldFetchUserVoucher(getState())) {
            return dispatch(fetchUserVoucher())
        }
    }
}

export function fetchUserNotifications() {
    return createAction({
        endpoint: `${config.API_HOST}/v1/dashboard`,
        method: 'GET',
        types: [
            {type: FETCH_USER_NOTIFICATIONS_REQUEST},
            {type: FETCH_USER_NOTIFICATIONS_SUCCESS},
            {type: FETCH_USER_NOTIFICATIONS_FAILED},
        ]
    })
}

function shouldFetchUserNotifications(state){
    const account = state.account
    if (
        !account.notifications ||
        ((account.notifications.isFetching || account.notifications.isFailed) && Date.now() - account.notifications.lastFetch > config.FETCH_TIMEOUT) ||
        (!account.notifications.isFetching && Date.now() - (account.notifications.lastUpdated ? account.notifications.lastUpdated : 0) > config.FETCH_AGAIN_SHORT)
    ) {
        return true
    }
    return false
}

export function fetchUserNotificationsIfNeeded(){
    return (dispatch, getState) => {
        if (shouldFetchUserNotifications(getState())) {
            return dispatch(fetchUserNotifications())
        }
    }
}
